import { createTypes, createAction } from "lib/action";

const CREATE_CALIBRATEFUEL = createTypes("calibratefuel", "create");
const CLEAR_RESPONSE = createTypes("response", "clear");



const createCalibratefuel = {
  request: (data, loading,token) =>
    createAction(CREATE_CALIBRATEFUEL.REQUEST, {
      data,
      loading,
      token,
    }),
  success: data => createAction(CREATE_CALIBRATEFUEL.SUCCESS, { data }),
  failure: () => createAction(CREATE_CALIBRATEFUEL.FAILURE)
};




const clearDataResponse = () => {
  return {
    type: CLEAR_RESPONSE
  };
};


export {
  
  CREATE_CALIBRATEFUEL,
  createCalibratefuel,
  CLEAR_RESPONSE,
  clearDataResponse,
};
