import { createTypes, createAction } from "lib/action";

const LOAD_GPSSTATUS = createTypes("gpsstatus", "load");

const loadGpsstatus = {
  request: (data, loading) =>
    createAction(LOAD_GPSSTATUS.REQUEST, { data, loading }),
  success: data => createAction(LOAD_GPSSTATUS.SUCCESS, { data }),
  failure: () => createAction(LOAD_GPSSTATUS.FAILURE)
};


export {
  LOAD_GPSSTATUS,
  loadGpsstatus,
};
