import React from "react";
import {
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import Create from "./Create";
import Edit from "./Edit";
import Index from "./Index";

export default () => (
  <Router>
    <Route exact path="/manage-otp" component={Index} />
    <Route
      exact
      path="/manage-otp/create"
      component={Create}
    />
    <Route
      exact
      path="/manage-otp/edit/:id"
      component={Edit}
    />
  </Router>
);
