import { createTypes, createAction } from "lib/action";

const LOAD_TRIPSUMBYVEH = createTypes("tripsumbyveh", "load");

const loadTripsumbyveh = {
  request: (data, loading) =>
    createAction(LOAD_TRIPSUMBYVEH.REQUEST, { data, loading }),
  success: data => createAction(LOAD_TRIPSUMBYVEH.SUCCESS, { data }),
  failure: () => createAction(LOAD_TRIPSUMBYVEH.FAILURE)
};



export {
  loadTripsumbyveh,
  LOAD_TRIPSUMBYVEH

};
