import React, { Component } from "react";
import { connect } from "react-redux";
import LangContext, { i18n } from "modules/shared/context/langContext";
import * as actions from "../actions";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "./../../../shared/components/Breadcrumb";
import { helper_convertDateformat } from "../../../../lib/helper";
import {
    Button,
    Table,
    Form,
    Row,
    Col,
    Input,
    Tag,
    Spin,
    Modal,
    Popconfirm,
    Badge,
    Icon,
    Tooltip,
    Drawer,
    Select,
    DatePicker,
    message,
} from "antd";
import moment from "moment";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import FilterHistory from "./FilterHistory";
import history from "../../../../lib/history";
const columnStyle = { fontSize: "12px", fontWeight: "400" };

class History extends Component {
    state = {
        visible: false,
        page: 1,
        pageSize: 10,
        searchName: "",
        orderBy: "id",
        orderType: "asc",
        filterObj: {
            vehicle_id:this.props.match.params.id,
            maintenance_status: "",
            date_start:"",
            date_end:""
        }
    };



    showDrawer = () => {
        this.setState({
            visible: true
        });
    };



    onCloseSearch = resetForm => {
        this.setState({
            visible: false
        });
    };

    filterHistoryservicerepairdevice = values => {
        const { vehicle_id, maintenance_status, eventdate } = values;
        const date_start = eventdate!=""? moment(eventdate[0]["_d"]).format("YYYY-MM-DD"):"";
        const date_end =  eventdate!=""? moment(eventdate[1]["_d"]).format("YYYY-MM-DD"):"";

        this.setState({
            filterObj: {
                vehicle_id:  vehicle_id,
                maintenance_status:maintenance_status,
                date_start:date_start,
                date_end: date_end
            }
        });
        this.props.loadHistoryservicerepairdevice(
            {
                page: this.state.page,
                pageSize: this.state.pageSize,
                orderBy: this.state.orderBy,
                orderType: this.state.orderType,
                filterObj: {
                    vehicle_id:  vehicle_id,
                    maintenance_status:maintenance_status,
                    date_start:date_start,
                    date_end: date_end
                }
            },
            true
        );
    };

    componentDidMount() {
        this.props.loadHistoryservicerepairdevice(this.state, true);
    }

   


    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            page: pagination.current
        });

        let orderBy = "";
        let orderType = "";

        if (Object.keys(sorter).length === 0 && sorter.constructor === Object) {
            orderBy = this.state.orderBy;
            orderType = this.state.orderType;
        } else {
            this.setState({
                orderBy: sorter.columnKey,
                orderType: sorter.order == "descend" ? "desc" : "asc"
            });
            orderBy = sorter.columnKey;
            orderType = sorter.order == "descend" ? "desc" : "asc";
        }

        this.props.loadHistoryservicerepairdevice(
            {
                page: pagination.current,
                pageSize: this.state.pageSize,
                searchName: "",
                orderBy: orderBy,
                orderType: orderType,
                filterObj: {
                    vehicle_id:  this.state.filterObj.vehicle_id,
                    maintenance_status:this.state.filterObj.maintenance_status,
                    date_start:this.state.filterObj.date_start,
                    date_end: this.state.filterObj.date_end

                }
            },
            true
        );
    };

    render() {

        const columns = [
            {
                title: "#",
                dataIndex: "row_number",
                width: "4%",
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle
                        },
                        children: <div>{text}</div>
                    };
                }
            },
            {
                title: <LangContext.Consumer>{i18n => i18n.p.plateNo}</LangContext.Consumer>,
                dataIndex: "plate_no",
                sorter: true,
                width: "8%",
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle
                        },
                        children: <div>{text}</div>
                    };
                }
            },
            {
                title: <LangContext.Consumer>{i18n => i18n.v.vehicleCode}</LangContext.Consumer>,
                dataIndex: "code",
                sorter: true,
                width: "8%",
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle
                        },
                        children: <div>{text}</div>
                    };
                }
            },   
            {
                title: <LangContext.Consumer>{i18n => i18n.m.maintenance_status}</LangContext.Consumer>,
                dataIndex: "status_name_th",
                sorter: false,
                render(text, record, index) {
                 

                    return {
                        props: {
                            style: columnStyle
                        },
                        children: < span style={{ color: "red" }}>{text}</span>
                        
                    };
                }
                ,
                width: "10%"
            },
            {
                title: <LangContext.Consumer>{i18n => i18n.m.maintenance_type}</LangContext.Consumer>,
                dataIndex: "type_name_th",
                sorter: true,
                width: "10%",
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle
                        },
                        children: <div>{text}</div>
                    };
                }
            },
            {
                title: <LangContext.Consumer>{i18n => i18n.u.use_date_time}</LangContext.Consumer>,
                dataIndex: "action_time",
                sorter: true,
                width: "12%",
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle
                        },
                        children: <div>{text}</div>
                    };
                }
            },
            {
                title: <LangContext.Consumer>{i18n => i18n.u.use_account_name}</LangContext.Consumer>,
                dataIndex: "account_name",
                sorter: true,
                width: "12%",
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle
                        },
                        children: <div>{text}</div>
                    };
                }
            }

        ];

        return (
            <div>
                <GeneralStyledContent>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Breadcrumb
                                match={this.props.match}
                                style={{ margin: "0px 0px 14px 10px" }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24} type="flex" justify="end" style={{ height: "38px" }}>
                        <Col span={22}>
                        </Col>

                        <Col span={2}>
                            <Button icon="filter" size="small" onClick={this.showDrawer}>
                                <LangContext.Consumer>{i18n => i18n.f.filter}</LangContext.Consumer>
                            </Button>
                        </Col>
                    </Row>
                    <Table
                        bordered
                        size="small"
                        columns={columns}
                        rowKey={record => record.id}
                        dataSource={this.props.servicerepairdevice.historyservicerepairdeviceLists}
                        pagination={{
                            pageSize: this.state.pageSize,
                            total: this.props.servicerepairdevice.historyservicerepairdeviceTotal,
                            current: this.state.page
                        }}
                        loading={this.props.servicerepairdevice.historyservicerepairdeviceLoading}
                        onChange={this.handleTableChange}
                    />

                    <div>
                        <LangContext.Consumer>
                            {
                                i18n => <Drawer
                                    title={i18n.s.search}
                                    width={350}
                                    onClose={this.onCloseSearch}
                                    visible={this.state.visible}
                                >
                                    <FilterHistory
                                        OnClose={this.onCloseSearch}
                                        onSubmit={this.filterHistoryservicerepairdevice}
                                        loadMaintenancestatus={this.props.loadMaintenancestatus}
                                        maintenancestatusLists={this.props.servicerepairdevice.maintenancestatusLists}
                                        vehicleLists={this.props.servicerepairdevice.vehicleLists}
                                        loadVehicle={this.props.loadVehicle}
                                        vehicle_id ={this.props.match.params.id}
                                    />
                                </Drawer>
                            }
                        </LangContext.Consumer>
                    </div>
                </GeneralStyledContent>
            </div>
        );
    }
}

const mapStateToProps = ({ servicerepairdevice, auth: { profile } }) => ({
    servicerepairdevice,
    profile
});

const mapDispatchToProps = {
    loadHistoryservicerepairdevice: actions.loadHistoryservicerepairdevice.request,
    loadMaintenancestatus: actions.loadMaintenancestatus.request,
    loadVehicle:actions.loadVehicle.request
  
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(History);
