import React from "react";

const AudioPlayer = ({ blobUrl }) => {
  return (
    <div>
      <audio
        controls
        style={{ height: "30px", paddingTop: 7 }}
        controlsList="noplaybackrate"
      >
        <source src={blobUrl} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default AudioPlayer;
