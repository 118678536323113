import React, { useEffect, useState } from "react";

const ControlledAudio = ({ key, src, style }) => {
  // const [audioKey, setAudioKey] = useState(key); // State to force re-mount/update of audio tag

  // useEffect(() => {
  //   console.log("test", src);
  //   setAudioKey(key); // Update audioKey state when key prop changes to force re-render
  // }, [key]);

  return (
    <audio
      key={src} // Key to force re-mount/update of audio tag
      controls
      style={style}
      controlsList="noplaybackrate"
    >
      <source src={src} type="audio/mpeg" />
    </audio>
  );
};

export default ControlledAudio;
