import { createTypes, createAction } from "lib/action";
import create from "antd/lib/icon/IconFont";
const LOAD_DEVICE_CONFIG = createTypes("loaddeviceconfig", "load");
const UPDATE_DEVICE_CONFIG = createTypes("updatedeviceconfig", "update");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
    return {
        type: REMOVE_LISTS
    };
}
const loadDeviceConfig = {
    request: (data, loading) => createAction(LOAD_DEVICE_CONFIG.REQUEST, { data, loading }),
    success: (data) => createAction(LOAD_DEVICE_CONFIG.SUCCESS, { data }),
    failure: () => createAction(LOAD_DEVICE_CONFIG.FAILURE)
};
const updateDeviceConfig = {
    request: (data, loading) => createAction(UPDATE_DEVICE_CONFIG.REQUEST, { data, loading }),
    success: res => createAction(UPDATE_DEVICE_CONFIG.SUCCESS, { res }),
    failure: () => createAction(UPDATE_DEVICE_CONFIG.FAILURE)
};

export {
    LOAD_DEVICE_CONFIG,
    loadDeviceConfig,
    UPDATE_DEVICE_CONFIG,
    updateDeviceConfig,
    REMOVE_LISTS,
    removeLists
};