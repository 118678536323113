import { createTypes, createAction } from "lib/action";

const LOAD_POLICY = createTypes("policymasterdata", "load");
const LOAD_POLICY_DETAIL = createTypes("policydetailmasterdata", "load");
const LOAD_POLICY_TEMPLATE_DETAIL = createTypes(
  "policytemplatedetailmasterdata",
  "load"
);

const CREATE_POLICY = createTypes("policymasterdata", "create");
const UPDATE_POLICY = createTypes("policymasterdata", "update");
const REMOVE_LISTS = `REMOVE_LISTS`;
const CREATE_TEMPLATE_POLICY = createTypes(
  "policymasterdata",
  "createtemplatepolicy"
);
const UPDATE_TEMPLATE_POLICY = createTypes(
  "policymasterdata",
  "updatetemplatepolicy"
);

function removeLists() {
  return {
    type: REMOVE_LISTS
  };
}
const loadPolicy = {
  request: (data, loading) =>
    createAction(LOAD_POLICY.REQUEST, { data, loading }),
  success: (lists, page) => createAction(LOAD_POLICY.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_POLICY.FAILURE)
};

const loadPolicyDetail = {
  request: (data, cb) => createAction(LOAD_POLICY_DETAIL.REQUEST, { data, cb }),
  success: policy => createAction(LOAD_POLICY_DETAIL.SUCCESS, { policy }),
  failure: () => createAction(LOAD_POLICY_DETAIL.FAILURE)
};

const loadPolicyTemplateDetail = {
  request: (data, cb) =>
    createAction(LOAD_POLICY_TEMPLATE_DETAIL.REQUEST, { data, cb }),
  success: policy =>
    createAction(LOAD_POLICY_TEMPLATE_DETAIL.SUCCESS, { policy }),
  failure: () => createAction(LOAD_POLICY_TEMPLATE_DETAIL.FAILURE)
};

const createPolicy = {
  request: (data, cb) =>
    createAction(CREATE_POLICY.REQUEST, {
      data,
      cb
    }),
  success: res => createAction(CREATE_POLICY.SUCCESS, { res }),
  failure: () => createAction(CREATE_POLICY.FAILURE)
};

const updatePolicy = {
  request: (data, cb) =>
    createAction(UPDATE_POLICY.REQUEST, {
      data,
      cb
    }),
  success: res => createAction(UPDATE_POLICY.SUCCESS, { res }),
  failure: () => createAction(UPDATE_POLICY.FAILURE)
};

const createTemplatePolicy = {
  request: (data, cb) =>
    createAction(CREATE_TEMPLATE_POLICY.REQUEST, {
      data,
      cb
    }),
  success: res => createAction(CREATE_TEMPLATE_POLICY.SUCCESS, { res }),
  failure: () => createAction(CREATE_TEMPLATE_POLICY.FAILURE)
};

const updateTemplatePolicy = {
  request: (data, cb) =>
    createAction(UPDATE_TEMPLATE_POLICY.REQUEST, {
      data,
      cb
    }),
  success: res => createAction(UPDATE_TEMPLATE_POLICY.SUCCESS, { res }),
  failure: () => createAction(UPDATE_TEMPLATE_POLICY.FAILURE)
};

export {
  CREATE_POLICY,
  createPolicy,
  loadPolicy,
  LOAD_POLICY,
  REMOVE_LISTS,
  removeLists,
  LOAD_POLICY_DETAIL,
  loadPolicyDetail,
  LOAD_POLICY_TEMPLATE_DETAIL,
  loadPolicyTemplateDetail,
  updatePolicy,
  UPDATE_POLICY,
  createTemplatePolicy,
  CREATE_TEMPLATE_POLICY,
  updateTemplatePolicy,
  UPDATE_TEMPLATE_POLICY
};
