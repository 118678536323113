import React, { useState, useEffect } from "react";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import {
  Button,
  Table,
  Row,
  Col,
  Spin,
  Tooltip,
  Popconfirm,
  Icon,
  Pagination,
} from "antd";
import Breadcrumb from "./../../../shared/components/Breadcrumb";
import { connect } from "react-redux";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { Link } from "react-router-dom";
import {
  loadVoiceManagement,
  deleteVoiceManagement,
} from "../fetchAPI/apiClient";
import IndexFilterDrawer from "./IndexFilterDrawer";

const columnStyle = { fontSize: "12px", fontWeight: "400" };

const Index = (props) => {
  const [isFilter, setIsFilter] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [dataTable, setDataTable] = useState([]);

  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);

  const [pageSize, setPageSize] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);

  const [orderBy, setOrderBy] = useState("id");

  const [orderType, setOrderType] = useState("desc");

  const [total, setTotal] = useState(0);

  const [filterObj, setFilterObj] = useState({
    name: "",
  });

  useEffect(() => {
    setLoading(true);
    loadVoiceManagement(
      {
        filterObj: {
          name: "",
        },
        page: 1,
        pageSize: 10,
        orderBy: orderBy,
        orderType: orderType,
        companyID: props.auth.profile.company_id,
        // companyID: 1,
      },
      (statusCode, response) => {
        setDataTable(response.data.data.data);
        setLoading(false);
        setTotal(response.data.data.total);
      }
    );
  }, []);

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      width: 50,
      align: "center",
      render(text, record, index) {
        return {
          props: {
            style: columnStyle,
          },
          children: <div>{index + 1 + (page - 1) * pageSize}</div>,
        };
      },
    },
    {
      title: (
        <LangContext.Consumer>{(i18n) => i18n.n.name}</LangContext.Consumer>
      ),
      dataIndex: "name",
      render(text, record, index) {
        return {
          props: {
            style: columnStyle,
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: (
        <LangContext.Consumer>{(i18n) => i18n.p.preview}</LangContext.Consumer>
      ),
      width: 400,
      dataIndex: "link_file",
      render(url, record, index) {
        return {
          props: {
            style: columnStyle,
          },
          children: (
            <div>
              {url && (
                <audio
                  controls
                  style={{ height: "30px", paddingTop: 7, width: "100%" }}
                  controlsList="noplaybackrate"
                >
                  <source src={url} type="audio/mpeg" />
                </audio>
              )}
            </div>
          ),
        };
      },
    },
    {
      title: (
        <LangContext.Consumer>
          {(i18n) => i18n.c.createdAt}
        </LangContext.Consumer>
      ),
      width: 150,
      dataIndex: "created_at",
      render(text, record, index) {
        return {
          props: {
            style: columnStyle,
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: (
        <LangContext.Consumer>{(i18n) => i18n.r.remark}</LangContext.Consumer>
      ),
      width: 300,
      dataIndex: "remark",
      render(text, record, index) {
        return {
          props: {
            style: columnStyle,
          },
          children: <div>{text}</div>,
        };
      },
    },

    {
      title: (
        <LangContext.Consumer>{(i18n) => i18n.a.action}</LangContext.Consumer>
      ),
      width: 100,
      key: "action_btn",
      align: "center",
      render: (text, record, index) => {
        return (
          <div>
            <Tooltip
              mouseEnterDelay={1.5}
              placement="left"
              title={
                <LangContext.Consumer>
                  {(i18n) => i18n.e.edit}
                </LangContext.Consumer>
              }
              arrowPointAtCenter
            >
              <Link
                style={{ padding: "0px 15px 0px 5px" }}
                to={`/voice-management/edit/${record.id}`}
              >
                <Icon type="edit" />
              </Link>
            </Tooltip>

            <Tooltip
              placement="left"
              title={
                <LangContext.Consumer>
                  {(i18n) => i18n.d.delete}
                </LangContext.Consumer>
              }
              arrowPointAtCenter
            >
              <Popconfirm
                title={
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.doYouWantToDeleteTheseItems}
                  </LangContext.Consumer>
                }
                onConfirm={() => {
                  deleteVoiceManagement(record.id, () => {
                    setLoading(true);
                    loadVoiceManagement(
                      {
                        filterObj: {
                          name: "",
                        },
                        page: 1,
                        pageSize: 10,
                        orderBy: orderBy,
                        orderType: orderType,
                        companyID: props.auth.profile.company_id,
                        // companyID: 1,
                      },
                      (statusCode, response) => {
                        setDataTable(response.data.data.data);
                        setLoading(false);
                        setTotal(response.data.data.total);
                      }
                    );
                  });
                }}
              >
                <a href="#">
                  <Icon type="delete" />
                </a>
              </Popconfirm>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const handlePageChange = (page, pageSize) => {
    setLoading(true);
    setPage(page);
    setCurrentPage(page);
  };

  const showFilterDrawer = () => {
    setIsFilter(true);
    setFilterVisible(true);
  };

  const onFilterDrawerClose = () => {
    setFilterVisible(false);
  };

  const onFilterButtonClick = (e) => {
    loadVoiceManagement(
      {
        filterObj,
        page: 1,
        pageSize: 10,
        orderBy: orderBy,
        orderType: orderType,
        companyID: props.auth.profile.company_id,
      },
      (statusCode, response) => {
        setDataTable(response.data.data.data);
        setLoading(false);
        setTotal(response.data.data.total);
        setFilterVisible(false);
      }
    );
  };

  const handleFilterObjName = ({ target: { value } }) => {
    setFilterObj((prev) => ({
      ...prev,
      name: value,
    }));
    setIsFilter(value != null && value != "");
  };

  return (
    <GeneralStyledContent>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Breadcrumb
            match={props.match}
            style={{ margin: "0px 0px 14px 10px" }}
          />
        </Col>
        <Col
          span={12}
          style={{
            textAlign: "end",
          }}
        >
          <Link to="/voice-management/create">
            <Button
              type="primary"
              icon="plus"
              size="small"
              style={{ marginRight: "8px" }}
            >
              <LangContext.Consumer>
                {(i18n) => i18n.a.add}
              </LangContext.Consumer>
            </Button>
          </Link>

          <Button
            icon="filter"
            size="small"
            align="right"
            onClick={showFilterDrawer}
          >
            <LangContext.Consumer>
              {(i18n) => i18n.f.filter}
            </LangContext.Consumer>
          </Button>
        </Col>
      </Row>
      <Row>
        <Spin spinning={loading}>
          <Table
            bordered
            columns={columns}
            dataSource={dataTable}
            size="small"
            pagination={false}
          />
        </Spin>
      </Row>
      <Row type="flex" justify="end" style={{ padding: "15px" }}>
        <Col pan={24} align="right">
          <IndexFilterDrawer
            onClose={onFilterDrawerClose}
            visible={filterVisible}
            onFilterButtonClick={onFilterButtonClick}
            filterObj={filterObj}
            handleFilterObjName={handleFilterObjName}
          />
          <Pagination
            defaultPageSize={pageSize}
            size="small"
            current={currentPage}
            total={total}
            onChange={handlePageChange}
          />
        </Col>
      </Row>
    </GeneralStyledContent>
  );
};

export default connect(
  ({ auth }) => ({
    auth,
  }),
  {}
)(Index);
