import { Checkbox, Col, Row } from "antd";
import React, { Component } from "react";

import LangContext from "modules/shared/context/langContext";

const checkbox = [
  {
    value: "fuel_1",
    word: (i18n) => i18n.f.fuel1,
    // label: "Fuel1",
  },
  {
    value: "fuel_2",
    word: (i18n) => i18n.f.fuel2,
    // label: "Fuel2",
  },
  {
    value: "temperature1",
    word: (i18n) => i18n.t.temperature1,
    // label: "Temperature1",
  },
  {
    value: "temperature2",
    word: (i18n) => i18n.t.temperature2,
    // label: "Temperature2",
  },
  {
    value: "gate_1",

    word: (i18n) => i18n.g.gate1,
    // label: "Gate1",
  },
  {
    value: "gate_2",
    word: (i18n) => i18n.g.gate2,
    // label: "Gate2",
  },
  {
    value: "engineStatus",
    word: (i18n) => i18n.e.engineStatus,
    // label: "Engine status",
  },
  {
    value: "pto",
    word: (i18n) => i18n.p.pto,
    // label: "PTO",
  },
  {
    value: "boom",
    word: (i18n) => i18n.b.BOOM,
    // label: "Boom",
  },
  {
    value: "turn_right",
    word: (i18n) => i18n.extraSetting.turn_right,
    // label: "Turn right",
  },
  {
    value: "turn_left",
    word: (i18n) => i18n.extraSetting.turn_left,
    // label: "Turn left",
  },
  {
    value: "high_headlight",
    word: (i18n) => i18n.extraSetting.highLight,
    // label: "High headlight",
  },
  {
    value: "low_headlight",
    word: (i18n) => i18n.extraSetting.lowLight,
    // label: "Low headlight",
  },
  {
    value: "horn",
    word: (i18n) => i18n.extraSetting.horn,
    // label: "Horn",
  },
  {
    value: "brake",
    word: (i18n) => i18n.b.brake,
    // label: "Brake",
  },
  {
    value: "windshield_viper",
    word: (i18n) => i18n.w.windshield_viper,
    // label: "Windshield viper",
  },
  {
    value: "reversing_light",
    word: (i18n) => i18n.r.reversing_light,
    // label: "Reversing light",
  },
  {
    value: "battery",
    word: (i18n) => i18n.b.battery_samrong,
    // label: "Battery",
  },
  {
    value: "fuel_consumption",
    word: (i18n) => i18n.f.fuel_consumption,
  },
  {
    value: "vol",
    word: (i18n) => i18n.b.battery_car,
  },
];

export default class Sensors extends Component {
  getTrueValue = () => {
    let arr = [];
    let keys = Object.keys(this.props.values.sensors);
    keys.map((name) => {
      if (this.props.values.sensors[name] === true) {
        arr.push(name);
      }
    });
    return arr;
  };

  render() {
    return (
      <Checkbox.Group
        // value={this.props.values.sensors}
        onChange={(value) => {
          let obj = this.props.values.sensors;
          Object.keys(this.props.values.sensors).map((name) => {
            if (value.includes(name)) {
              {
                console.log(obj, name, "obj , name");
              }
              return (obj[name] = true);
            }
            return (obj[name] = false);
          });

          this.props.setFieldValue("sensors", obj);
        }}
      >
        <Row type="flex" align="top">
          {checkbox.map((val, idx) => {
            return (
              <Col sm={12} xs={12} lg={8} key={idx}>
                <Checkbox
                  name={val.value}
                  value={val.value}
                  style={{
                    lineHeight: "32px",
                  }}
                >
                  <LangContext>{val.word}</LangContext>
                </Checkbox>
              </Col>
            );
          })}
        </Row>
      </Checkbox.Group>
    );
  }
}
