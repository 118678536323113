import React, { useState, useEffect } from "react";
import { Switch, Input, Row, Col, Typography, Button } from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  updateAutoCreateDriver,
  updateAutoChangeDriver,
  loadCompanySetting,
} from "../actions";

const HeaderText = styled.div`
  font-size: 22px;
  font-weight: bold;
`;

const Description = styled.div`
  font-size: 12px;
  font-weight: normal;
  margin-left: 52px;
`;

const { Text } = Typography;
const Licensesetting = ({
  licensesetting,
  auth,
  loadCompanySetting,
  updateAutoCreateDriver,
  updateAutoChangeDriver,
  accessToken,
}) => {
  useEffect(() => {
    if (
      licensesetting &&
      licensesetting.companysetting &&
      licensesetting.companysetting.length > 0
    ) {
      const companySetting = licensesetting.companysetting[0];
      setToggle1(!!companySetting.setting_is_auto_create_driver);
      setToggle2(!!companySetting.setting_is_auto_change_driver);
    }
  }, [licensesetting]);

  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(false);

  const handleToggle1 = async (checked) => {
    await updateAutoCreateDriver({
      companyID: auth.profile.company_id,
      setting_is_auto_create_driver: checked ? 1 : 0,
      accessToken: accessToken,
    }, handleReload);
  };

  const handleReload = () => {
    loadCompanySetting({
      companyID: auth.profile.company_id,
      accessToken: accessToken,
    });
  }

  const handleToggle2 = async (checked) => {
    await updateAutoChangeDriver({
      companyID: auth.profile.company_id,
      setting_is_auto_change_driver: checked ? 1 : 0,
      accessToken: accessToken,
    }, handleReload)
  };

  return (
    <div>
      <Row style={{ marginBottom: "20px" }}>
        <Col>
          <HeaderText>ตั้งค่าการใช้ข้อมูลจากการรูดใบขับขี่</HeaderText>
        </Col>
      </Row>
      <Row style={{ marginBottom: "20px" }}>
        <Col>
          <Text strong>
            <Switch
              checked={toggle1}
              onChange={handleToggle1}
              style={{ marginRight: "10px" }}
            />
            <LangContext.Consumer>
              {(i18n) =>
                i18n.a.automaticaddeditdriverinformationbyusingdriverlicense
              }
            </LangContext.Consumer>
            <Description>
              เหมาะสำหรับบริษัทที่ต้องการเก็บข้อมูลของพนักงานขับขี่ไว้ในระบบ
            </Description>
          </Text>
        </Col>
      </Row>

      <Row style={{ marginBottom: "20px" }}>
        <Col>
          <Text strong>
            <Switch
              checked={toggle2}
              onChange={handleToggle2}
              style={{ marginRight: "10px" }}
            />
            <LangContext.Consumer>
              {(i18n) => i18n.a.automaticeditvehiclebyusingdriverlicense}
            </LangContext.Consumer>
            <Description>
              เหมาะสำหรับบริษัทที่ต้องการทราบว่าพาหนะคันนั้นๆมีพนักงานขับขี่ท่านใดเป็นผู้ขับขี่อยู่{" "}
            </Description>
          </Text>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  accessToken: state.auth.accessToken,
});

export default connect(mapStateToProps, {
  updateAutoCreateDriver: updateAutoCreateDriver.request,
  updateAutoChangeDriver: updateAutoChangeDriver.request,
  loadCompanySetting: loadCompanySetting.request,
})(Licensesetting);
