// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import AuthorizeComponent from '../../../auth/components/AuthorizeComponent'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import { checkPermissions } from '../../../../lib/helper'
import moment from 'moment'
import { Row, Col, Spin, Tooltip, PageHeader, Badge, Button, Tag } from 'antd'

// Components
import FormSearch from './FormSearch'
import TableLog from './TableLog'

// API
import {
  cameraStatusDashboardTableLog,
  cameraStatusDashboardCompanyList,
  removeLists,
} from '../actions'

// Lib
import { prepareLogFormatArray, prepareDataForTable } from './MainFunction'

// CSS
import '../assets/css/index.css'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      // *เช็คว่ามีการ filter หรือไม่
      isFilter: false,
      visible_form_search: false,

      // *filterObj
      filterObj: {
        FilterCompanyID: '',
        // ย้อนหลัง 7 วัน
        FilterStartDate: moment()
          .subtract(7, 'days')
          .format('YYYY-MM-DD'),
        FilterEndDate: moment().format('YYYY-MM-DD'),
        FilterVehicleCode: '',
        FilterPlateNo: '',
        FilterDeviceCode: '',
        FilterDeviceType: '',
      },

      pagination: {
        page: 1,
        pageSize: 50,
      },

      // *ข้อมูลในตาราง table_log
      // เก็บ array ของวันที่และเดือนที่เลือก
      log_format_data: [],

      // columns
      table_columns: [],

      // ข้อมูล table log
      log_detail: [],
      total_log_detail: 0,
    }
  }

  componentDidMount() {
    let company_id = this.props.auth.profile.company_id
    if (
      checkPermissions(
        this.props.auth.profile.permissions,
        'cameraStatusDashboard',
        'can_special_1'
      )
    ) {
      this.fetchCompanyList()

      company_id = null
    }

    //*first search

    this.handleSearch({
      company_id: company_id,
      search_date: [moment().subtract(7, 'days'), moment()],
      vehicle_code: '',
      plate_no: '',
      device_code: '',
      device_type: '',
    })
  }

  componentWillUnmount() {
    this.onRemoveLists()
  }
  onRemoveLists = () => {
    this.props.removeLists()
  }

  //*เมื่อมีการเปลี่ยนแปลง state
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.cameraStatusDashboard.check_table_log !==
      nextProps.cameraStatusDashboard.check_table_log
    ) {
      if (Object.keys(nextProps.cameraStatusDashboard.table_log).length > 0) {
        let temp_data = nextProps.cameraStatusDashboard.table_log.data || []
        let temp_total = nextProps.cameraStatusDashboard.table_log.total || 0
        this.setState(
          {
            log_detail: prepareDataForTable(temp_data) || [],
            total_log_detail: temp_total,
          },
          () => {
            this.prepareColumns()
          }
        )
      }
    }
    return true
  }

  // *ดึงข้อมูล company
  fetchCompanyList = () => {
    this.props.cameraStatusDashboardCompanyList({
      accessToken: this.props.auth.accessToken,
    })
  }

  // *ดึงข้อมูล table log
  fetchTableLogData = async () => {
    const { filterObj, pagination } = this.state

    // *fetch data table log
    this.props.cameraStatusDashboardTableLog({
      ...pagination,
      filterObj,
      vehicleVisibility: this.props.auth.profile.vehicle_visibility,
      accessToken: this.props.auth.accessToken,
      canFilterCompany: checkPermissions(
        this.props.auth.profile.permissions,
        'cameraStatusDashboard',
        'can_special_1'
      ),
    })
  }

  // *ค้นหา
  handleSearch = (values) => {
    let isFilter = false
    if (values.vehicle_code || values.plate_no || values.device_code) {
      isFilter = true
    }

    if (
      checkPermissions(
        this.props.auth.profile.permissions,
        'cameraStatusDashboard',
        'can_special_1'
      )
    ) {
      if (values.company_id) {
        isFilter = true
      }
    }

    let start_date = moment(values.search_date[0]).format('YYYY-MM-DD')
    let end_date = moment(values.search_date[1]).format('YYYY-MM-DD')

    this.setState(
      {
        filterObj: {
          FilterCompanyID: values.company_id,
          FilterStartDate: start_date,
          FilterEndDate: end_date,
          FilterVehicleCode: values.vehicle_code,
          FilterPlateNo: values.plate_no,
          FilterDeviceCode: values.device_code,
          FilterDeviceType: values.device_type,
        },

        pagination: {
          ...this.state.pagination,
          page: 1,
        },
        isFilter,

        visible_form_search: false,

        // *เตรียมข้อมูลวันที่
        log_format_data:
          prepareLogFormatArray(
            this.props.cameraStatusDashboard.month_data,
            values.search_date[0],
            values.search_date[1]
          ) || [],
      },
      () => {
        // *fetch data table log
        this.fetchTableLogData()
      }
    )
  }

  // *เตรียม columns ของ table view
  prepareColumns = () => {
    const { log_format_data } = this.state
    let table_columns = []
    let all_columns = []

    let index_column = [
      {
        title: '#',
        dataIndex: 'key',
        key: 'key',
        width: 60,
        align: 'center',
        fixed: 'left',
        render: (text, record, index) => {
          if (record.visible) {
            const obj = {
              children: (
                <span>
                  {this.state.pagination.pageSize *
                    (this.state.pagination.page - 1) +
                    record.number_index}
                </span>
              ),
              props: {
                align: 'center',
                rowSpan: record.number_of_channel || 1,
              },
            }
            return obj
          } else {
            // สำหรับ channel อื่นๆ ไม่ต้องแสดงข้อมูลส่วนนี้เพราะ row แรก แสดงไปแล้ว
            const obj = {
              props: {
                align: 'center',
                rowSpan: 0,
              },
            }
            return obj
          }
        },
      },
      {
        title: i18n[this.props.language].v.vehicleData,
        dataIndex: 'vehicle_id',
        key: 'vehicles.code',
        width: 100,
        align: 'center',
        fixed: 'left',
        render: (text, record, index) => {
          if (record.visible) {
            const obj = {
              children: (
                <div>
                  <Tooltip
                    title={
                      <span>{`${
                        record.vehicle_code
                          ? '(' + record.vehicle_code + ')'
                          : ''
                      } ${record.plate_no}`}</span>
                    }
                    placement='top'
                  >
                    <span>{`${
                      record.vehicle_code ? '(' + record.vehicle_code + ')' : ''
                    } ${record.plate_no}`}</span>
                  </Tooltip>

                  <br />
                  {record.device_code && (
                    <Tooltip
                      title={
                        <span>{`${
                          i18n[this.props.language].d.deviceCode
                        } : ${record.device_code || ''}`}</span>
                      }
                      placement='top'
                    >
                      <span
                        style={{ fontSize: '11px' }}
                      >{`${record.device_code || ''}`}</span>
                    </Tooltip>
                  )}

                  <br />
                  {record.gps_active_at && (
                    <Tooltip
                      title={
                        <span>{`${
                          i18n[this.props.language].l.latestConnection
                        } : ${
                          record.gps_active_at
                            ? moment(record.gps_active_at).format(
                                'DD/MM/YYYY HH:mm:ss'
                              )
                            : ''
                        }`}</span>
                      }
                      placement='bottom'
                    >
                      <span style={{ fontSize: '11px' }}>{`${
                        record.gps_active_at
                          ? moment(record.gps_active_at).format(
                              'DD/MM/YYYY HH:mm:ss'
                            )
                          : ''
                      }`}</span>
                    </Tooltip>
                  )}
                </div>
              ),
              props: {
                align: 'center',
                rowSpan: record.number_of_channel || 1,
              },
            }
            return obj
          } else {
            // สำหรับ series อื่นๆ ไม่ต้องแสดงข้อมูลส่วนนี้เพราะ row แรก แสดงไปแล้ว
            const obj = {
              props: {
                align: 'center',
                rowSpan: 0,
              },
            }
            return obj
          }
        },
      },
      {
        title: i18n[this.props.language].l.lastmaintenanceAt,
        dataIndex: 'vehicle_id',
        key: 'vehicles.code',
        width: 120,
        align: 'center',
        fixed: 'left',
        render: (text, record, index) => {
          if (record.visible) {
            const obj = {
              children: (
                <div>
                  {record.maintenance_data && (
                    <Tooltip
                      title={
                        <>
                          <span>
                            {`${record.maintenance_data.type_name_th || '-'}`}
                          </span>
                          <br></br>
                          <span>{`${record.maintenance_data.status_name_th ||
                            '-'}`}</span>
                          <br></br>
                          <span>
                            {`${i18n[this.props.language].d.date} : ${record
                              .maintenance_data.date_display || '-'}`}
                          </span>
                          <br></br>
                          <span style={{ fontSize: '10px' }}>{`(${record
                            .maintenance_data.date_name || '-'})`}</span>
                        </>
                      }
                      placement='top'
                    >
                      <span>
                        {`${record.maintenance_data.type_name_th || '-'}`}
                      </span>
                      <br></br>
                      <span>{`${record.maintenance_data.status_name_th ||
                        '-'}`}</span>
                      <br></br>
                      <span>
                        {`${i18n[this.props.language].d.date} : ${record
                          .maintenance_data.date_display || '-'}`}
                      </span>
                      <br></br>
                      <span style={{ fontSize: '10px' }}>{`(${record
                        .maintenance_data.date_name || '-'})`}</span>
                    </Tooltip>
                  )}
                </div>
              ),
              props: {
                align: 'center',
                rowSpan: record.number_of_channel || 1,
              },
            }
            return obj
          } else {
            // สำหรับ series อื่นๆ ไม่ต้องแสดงข้อมูลส่วนนี้เพราะ row แรก แสดงไปแล้ว
            const obj = {
              props: {
                align: 'center',
                rowSpan: 0,
              },
            }
            return obj
          }
        },
      },
      {
        title: 'CH',
        dataIndex: 'channel_name',
        key: 'channel_name',
        width: 20,
        align: 'center',
        render: (text, record, index) => {
          const obj = {
            children: (
              <Tooltip title={text} placement='top'>
                <span>{text}</span>
              </Tooltip>
            ),
            props: {
              align: 'center',
              rowSpan: 1,
              colSpan: 1,
            },
          }

          return obj
        },
      },
    ]

    all_columns = index_column

    // วนลูปใน log_format_data['order_key']
    for (let i = 0; i < log_format_data['order_key'].length; i++) {
      let key = log_format_data['order_key'][i]
      let date_array = log_format_data[key].date_array
      let day_array = log_format_data[key].day_array

      // วนลูปใน date_array
      for (let j = 0; j < date_array.length; j++) {
        let date = date_array[j]
        let day = day_array[j]

        let column = {
          title: day,
          dataIndex: date,
          key: date,
          align: 'center',
          render: (text, record) => {
            return {
              props: {},
              children: (
                <div>
                  {text &&
                    record[text].map((item, index) => {
                      return record.type === 'camera' ? (
                        <>
                          {item['datetime'] ? (
                            <>
                              <div>
                                <Tooltip
                                  title={`${
                                    i18n[this.props.language].d.dateTimeSnapshot
                                  } : ${
                                    item['datetime']
                                      ? moment(item['datetime']).format(
                                          'DD/MM/YYYY HH:mm:ss'
                                        )
                                      : ''
                                  }`}
                                >
                                  <span>
                                    {item['datetime'] &&
                                      moment(item['datetime']).format(
                                        'DD/MM/YY'
                                      )}
                                  </span>
                                  <br></br>
                                  <span>
                                    {item['datetime'] &&
                                      moment(item['datetime']).format('HH:mm')}
                                  </span>
                                </Tooltip>
                              </div>
                              <div>
                                <Tooltip
                                  title={
                                    <div>
                                      <span>
                                        {`${
                                          i18n[this.props.language].p.picture
                                        }`}
                                      </span>
                                    </div>
                                  }
                                >
                                  <span>
                                    {item['path'] && (
                                      <Button
                                        type='link'
                                        icon='eye'
                                        onClick={() => {
                                          window.open(item['path'], '_blank')
                                        }}
                                      />
                                    )}
                                  </span>
                                </Tooltip>
                              </div>
                            </>
                          ) : (
                            <div>
                              <span>{`-`}</span>
                            </div>
                          )}

                          {item['engine_on_datetime'] && (
                            <div>
                              <Tag color='green'>
                                <span>
                                  {i18n[this.props.language].e.engineOn}
                                </span>
                              </Tag>
                            </div>
                          )}
                        </>
                      ) : null
                    })}
                </div>
              ),
            }
          },
        }

        all_columns.push(column)
      }
    }
    table_columns = all_columns

    this.setState(
      {
        table_columns,
      },
      () => {}
    )
  }

  // *เปิด form search
  handleOpenFormSearch = () => {
    this.setState({ visible_form_search: true })
  }

  // *ปิด form search
  handleCloseFormSearch = () => {
    this.setState({ visible_form_search: false })
  }

  handleChangePage = (page, pageSize) => {
    this.setState(
      {
        pagination: {
          page,
          pageSize,
        },
      },
      () => {
        this.fetchTableLogData()
      }
    )
  }

  render() {
    const {
      isFilter,
      visible_form_search,
      table_columns,
      pagination,

      log_detail,
      total_log_detail,
    } = this.state

    const { check_table_log } = this.props.cameraStatusDashboard

    return (
      <AuthorizeComponent {...this.props} matching_name='cameraStatusDashboard'>
        <GeneralStyledContent>
          <div className='camera-status-dashboard'>
            {/* header */}
            <PageHeader
              style={{ paddingLeft: '0px' }}
              title={
                <div className='header__text'>
                  {i18n[this.props.language].c.cameraStatusDashboard}
                </div>
              }
            />

            {/* ปุ่มค้นหาแถบบน */}
            <Row type='flex' style={{ marginBottom: '10px' }} justify='end'>
              <Col span={14} align='right'>
                <Badge dot={isFilter} align='right'>
                  <Button
                    icon='filter'
                    size='small'
                    align='right'
                    onClick={this.handleOpenFormSearch}
                  >
                    <span>{i18n[this.props.language].s.search}</span>
                  </Button>
                </Badge>
              </Col>
            </Row>

            {/* table log */}
            <Row>
              <Spin
                spinning={check_table_log}
                tip={`${i18n[this.props.language].l.loading}...`}
              >
                <TableLog
                  data={log_detail || []}
                  column={table_columns}
                  className='table-log'
                  pagination={pagination}
                  total={total_log_detail}
                  handleChangePage={this.handleChangePage}
                />
              </Spin>
            </Row>

            {/* form search */}
            <FormSearch
              handleSearch={this.handleSearch}
              visible={visible_form_search}
              handleClose={this.handleCloseFormSearch}
            />
          </div>
        </GeneralStyledContent>
      </AuthorizeComponent>
    )
  }
}

export default connect(
  ({ cameraStatusDashboard, ui: { language }, auth, auth: { profile } }) => ({
    cameraStatusDashboard,
    language,
    auth,
    profile,
  }),
  {
    cameraStatusDashboardTableLog: cameraStatusDashboardTableLog.request,
    cameraStatusDashboardCompanyList: cameraStatusDashboardCompanyList.request,
    removeLists,
  }
)(Index)
