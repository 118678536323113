import { createTypes, createAction } from "lib/action";
const LOAD_SERVICEMOVEDEVICE = createTypes(
  "loadcompany",
  "load"
);
const LOAD_SERVICEMOVEDEVICE_HISTORY = createTypes(
  "loadcompanyhistory",
  "load"
);
const LOAD_VEHICLETYPE = createTypes(
  "loadvehicletype",
  "load"
);
const LOAD_MAINTENANCETYPE = createTypes(
  "loadaintenancetype",
  "load"
);
const LOAD_MAINTENANCESTATUS = createTypes(
  "loadaintenancestatus",
  "load"
);
const LOAD_VEHICLELISTS = createTypes(
  "loadvehiclelist",
  "load"
);
const LOAD_VEHICLELISTSBYID = createTypes(
  "loadvehiclebyidlist",
  "load"
);
const LOAD_PROVINCE = createTypes("loadprovince", "load");
const LOAD_AMPHUR = createTypes("loadamphur", "load");
const CREATE = createTypes("create_move", "create");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
  return {
    type: REMOVE_LISTS,
  };
}

const loadServiceMoveDevice = {
  request: (data, loading) =>
    createAction(LOAD_SERVICEMOVEDEVICE.REQUEST, {
      data,
      loading,
    }),
  success: (lists, page) =>
    createAction(LOAD_SERVICEMOVEDEVICE.SUCCESS, {
      lists,
      page,
    }),
  failure: () =>
    createAction(LOAD_SERVICEMOVEDEVICE.FAITLURE),
};

const loadServiceMoveDeviceHistory = {
  request: (data, loading) =>
    createAction(LOAD_SERVICEMOVEDEVICE_HISTORY.REQUEST, {
      data,
      loading,
    }),
  success: (Historylists, page) =>
    createAction(LOAD_SERVICEMOVEDEVICE_HISTORY.SUCCESS, {
      Historylists,
      page,
    }),
  failure: () =>
    createAction(LOAD_SERVICEMOVEDEVICE_HISTORY.FAITLURE),
};

const loadVehicleType = {
  request: (data, loading) =>
    createAction(LOAD_VEHICLETYPE.REQUEST, {
      data,
      loading,
    }),
  success: (VehicleTyle) =>
    createAction(LOAD_VEHICLETYPE.SUCCESS, { VehicleTyle }),
  failure: () => createAction(LOAD_VEHICLETYPE.FAITLURE),
};

const loadMaintenanceType = {
  request: (data, loading) =>
    createAction(LOAD_MAINTENANCETYPE.REQUEST, {
      data,
      loading,
    }),
  success: (MaintenanceTyle) =>
    createAction(LOAD_MAINTENANCETYPE.SUCCESS, {
      MaintenanceTyle,
    }),
  failure: () =>
    createAction(LOAD_MAINTENANCETYPE.FAITLURE),
};

const loadMaintenanceStatus = {
  request: (data, loading) =>
    createAction(LOAD_MAINTENANCESTATUS.REQUEST, {
      data,
      loading,
    }),
  success: (MaintenanceStatus) =>
    createAction(LOAD_MAINTENANCESTATUS.SUCCESS, {
      MaintenanceStatus,
    }),
  failure: () =>
    createAction(LOAD_MAINTENANCESTATUS.FAITLURE),
};

const loadVehicle = {
  request: (data, loading) =>
    createAction(LOAD_VEHICLELISTS.REQUEST, {
      data,
      loading,
    }),
  success: (vehicleLists) =>
    createAction(LOAD_VEHICLELISTS.SUCCESS, {
      vehicleLists,
    }),
  failure: () => createAction(LOAD_VEHICLELISTS.FAITLURE),
};

const loadVehicleByID = {
  request: (data, loading) =>
    createAction(LOAD_VEHICLELISTSBYID.REQUEST, {
      data,
      loading,
    }),
  success: (vehicleByIDLists) =>
    createAction(LOAD_VEHICLELISTSBYID.SUCCESS, {
      vehicleByIDLists,
    }),
  failure: () =>
    createAction(LOAD_VEHICLELISTSBYID.FAITLURE),
};

const loadProvince = {
  request: (data, loading) =>
    createAction(LOAD_PROVINCE.REQUEST, { data, loading }),
  success: (ProvinceLists) =>
    createAction(LOAD_PROVINCE.SUCCESS, { ProvinceLists }),
  failure: () => createAction(LOAD_PROVINCE.FAITLURE),
};

const loadAmphur = {
  request: (data, loading) =>
    createAction(LOAD_AMPHUR.REQUEST, { data, loading }),
  success: (AmphurLists) =>
    createAction(LOAD_AMPHUR.SUCCESS, { AmphurLists }),
  failure: () => createAction(LOAD_AMPHUR.FAITLURE),
};

const create = {
  request: (data, loading) =>
    createAction(CREATE.REQUEST, { data, loading }),
  success: (res) => createAction(CREATE.SUCCESS, { res }),
  failure: () => createAction(CREATE.FAILURE),
};

export {
  LOAD_SERVICEMOVEDEVICE,
  loadServiceMoveDevice,
  LOAD_VEHICLETYPE,
  loadVehicleType,
  LOAD_MAINTENANCETYPE,
  loadMaintenanceType,
  LOAD_MAINTENANCESTATUS,
  loadMaintenanceStatus,
  LOAD_VEHICLELISTS,
  loadVehicle,
  LOAD_PROVINCE,
  loadProvince,
  LOAD_AMPHUR,
  loadAmphur,
  LOAD_VEHICLELISTSBYID,
  loadVehicleByID,
  CREATE,
  create,
  LOAD_SERVICEMOVEDEVICE_HISTORY,
  loadServiceMoveDeviceHistory,
  REMOVE_LISTS,
  removeLists,
};
