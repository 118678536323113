import { createTypes, createAction } from "lib/action";

const LOAD_DEPOSIT_FUEL_CREDIT_REPORT = createTypes(
  "load_deposit_fuel_credit_report",
  "load"
);

const loadDepositFuelCreditReport = {
  request: (obj) =>
    createAction(LOAD_DEPOSIT_FUEL_CREDIT_REPORT.REQUEST, { obj }),
  success: (data) =>
    createAction(LOAD_DEPOSIT_FUEL_CREDIT_REPORT.SUCCESS, { data }),
  failure: () => createAction(LOAD_DEPOSIT_FUEL_CREDIT_REPORT.FAILURE),
};

export { loadDepositFuelCreditReport, LOAD_DEPOSIT_FUEL_CREDIT_REPORT };
