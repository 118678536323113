import React from "react";
import Form from "./VoiceManagementForm";
import { connect } from "react-redux";
import { updateVoiceManagement } from "../fetchAPI/apiClient";
const Edit = (props) => {
  let url = window.location.pathname;
  let id = url.substring(url.lastIndexOf("/") + 1);
  const onSubmit = (values) => {
    updateVoiceManagement(
      id,
      {
        user_id: props.profile.id,
        name: values["name"],
        remark: values["remark"],
      },
      (statusCode, response) => {
        if (response.data.status === 200) {
          window.location.replace("/voice-management");
        }
      }
    );
  };
  return <Form type="edit" match={props.match} onSubmit={onSubmit} id={id} />;
};

export default connect(
  ({ auth, auth: { profile } }) => ({
    auth,
    profile,
  }),
  {}
)(Edit);
