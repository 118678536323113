import {
  LOAD_AUTOCOMPLETE,
  SELECTED_AUTOCOMPLETE,
  SELECTED_BOOKING,
  LOAD_BOOKING_VEHICLE,
  SELECTED_VEHICLE,
  UPDATE_BOOKING_DEVICE,
  CREATE_BOOKING,
  UPDATE_BOOKING,
  LOAD_BOOKING,
  REMOVE_LISTS,
  LOAD_BOOKING_DETAIL,
  REMOVE_VEHICLE_LISTS
} from "./actions";
import history from "./../../lib/history";

const initialState = {
  lists: [],
  total: 1,
  loading: false,
  vehicleLoading: false,
  autoComplete: [],
  booking: {
    title: "-",
    firstname: "",
    lastname: "",
    nationality: "-",
    phone_number: "",
    phone_number_2: "",
    passenger: "-",
    id: "",
    jobType: "meter",
    job_status: "",
    est_transit_duration: "",
    est_transit_distance: "",
    booking_id: null
  },
  draw: -1
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState;
    case REMOVE_VEHICLE_LISTS:
      return {
        ...state,
        vehicleList: []
      };
    case LOAD_BOOKING.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        loading: loading
      };
    case LOAD_BOOKING.SUCCESS:
      const { page } = action.payload;
      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1;
        if (page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber + (page - 1) * 10;
      }
      return {
        ...state,
        lists: action.payload.lists.data,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1)
      };

    // case LOAD_BOOKING.FAILURE:
    //   const { err } = action.payload;
    //   history.push("/");
    //   return {
    //     ...state
    //   };
    case LOAD_AUTOCOMPLETE.SUCCESS:
      return {
        ...state,
        autoComplete: action.payload
      };
    case SELECTED_AUTOCOMPLETE:
      const { id, lists, selectedName, cb } = action.payload;

      let selected = lists.filter(function(ele) {
        return ele.id == id;
      })[0];
      cb(selected);
      return { ...state, [selectedName]: selected, autoComplete: [] };
    case LOAD_BOOKING_DETAIL.REQUEST:
      return {
        ...state,
        loading: typeof action.payload.data.intervalLoading === "undefined"
      };
    case LOAD_BOOKING_DETAIL.SUCCESS:
      return {
        ...state,
        booking: action.payload.lists[0],
        loading: false
      };
    case SELECTED_BOOKING:
      return {
        ...state,
        booking: action.payload.booking
      };
    case CREATE_BOOKING.SUCCESS:
      const { res } = action.payload;

      return {
        ...state,
        booking: { ...state.booking, booking_id: res.job_id },
        jobId: res
      };
    // case UPDATE_BOOKING.SUCCESS:
    //   const { res } = action.payload;
    //   return {
    //     ...state,
    //     jobId: res
    //   };
    case SELECTED_VEHICLE:
      const { vehicle } = action.payload;
      const { booking } = { ...state };

      booking.vehicle_plate_no = vehicle.plate_no;
      booking.device_id = vehicle.device_id;

      return {
        ...state,
        booking
      };

    case LOAD_BOOKING_VEHICLE.REQUEST:
      return {
        ...state,
        vehicleList: [],
        vehicleLoading: true
      };
    case LOAD_BOOKING_VEHICLE.SUCCESS:
      return {
        ...state,
        vehicleList: action.payload.data.markers,
        vehicleLoading: false
      };
    default:
      return state;
  }
};
