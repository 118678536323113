import React, { Component, Fragment } from "react";

export class AuthorizeWidgetComponent extends Component {
  componentDidMount() {}

  //   render() {
  //     let display =
  //       typeof this.props.layout.find(ele => ele.i == this.props.id) !=
  //       "undefined";

  //     console.log(display, this.props.layout);
  //     if (display) return <div {...this.props}>{this.props.children}</div>;
  //     else return <div></div>;
  //   }

  // render() {
  //   return <div {...this.props}>{this.props.children}</div>;
  // }

  render() {
    const { permission } = this.props;
    let canAccess = permission.find(ele => {
      return ele.matching_name == this.props.matching_name && ele.can_view == 1;
    });

    if (this.props.matching_name == "") {
      canAccess = true;
    } else if (typeof canAccess == "undefined") {
      canAccess = false;
    } else {
      canAccess = true;
    }

    if (!canAccess) {
      return <div></div>;
    }
    return <div {...this.props}>{this.props.children}</div>;
  }
}
export default AuthorizeWidgetComponent;
