import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_LARAVEL_API_ENDPOINT,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("access-token")}`,
  },
});

// Generic function to handle API requests
const apiRequest = async (method, url, params, callback) => {
  try {
    const res = await axiosClient[method](url, params);
    callback(200, res);
  } catch (error) {
    // Handle error appropriately, e.g., logging, showing user-friendly message
    console.error("API Error:", error);
    // Example error handling:
    // if (error.response) {
    //   callback(error.response.status, error.response.data);
    // } else {
    //   callback(500, { message: "Internal Server Error" });
    // }
  }
};

// API functions using generic request function
export async function loadPolicy(params, callback) {
  await apiRequest("post", "/new-policy", params, callback);
}

export async function loadPolicyType(type, params, callback) {
  await apiRequest("get", `/policy-type${type}`, { params }, callback);
}

export async function deletePolicy(id, callback) {
  await apiRequest("delete", `/new-policy/${id}`, null, callback);
}

export async function loadVehicleGroup(params, callback) {
  await apiRequest(
    "post",
    "/vehicle-group/vehicle-group-by-company",
    params,
    callback
  );
}

export async function loadVehicle(params, callback) {
  await apiRequest("post", "/vehicle/vehicle-by-company", params, callback);
}

export async function loadVehicleByVehicleGroup(params, callback) {
  await apiRequest(
    "post",
    "/vehicle/vehicle-by-vehicle-group",
    params,
    callback
  );
}

export async function createPolicy(params, callback) {
  await apiRequest("post", "/new-policy/save", params, callback);
}

export async function loadZoneByCompony(params, callback) {
  await apiRequest("post", "/zone/zone-by-company", params, callback);
}

export async function loadPolicyById(id, callback) {
  await apiRequest("get", `/new-policy/${id}`, null, callback);
}

export async function updatePolicy(id, params, callback) {
  await apiRequest("put", `/new-policy/${id}`, params, callback);
}

export async function getAudioList(params, callback) {
  await apiRequest("post", "/policy-audio/list", params, callback);
}
