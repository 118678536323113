import * as firebase from "firebase/app";
import "firebase/messaging";

let messaging;
if ("PushManager" in window) {
  const initializedFirebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyBeStR8V0sfsDtS74JCy2DiPCRTHVnkkyE",
    projectId: "terminus-336ce",
    messagingSenderId: "874130675038",
    appId: "1:874130675038:web:db587390aa677ef7906bcf",
  });

  messaging = initializedFirebaseApp.messaging();

  messaging.usePublicVapidKey(
    "BKw2wtrbaY6Nm66rlRQaOvEqC2Ux48WhmvSpzE3bDez_ArTGie80uUO8Ba6oOIiJav27po3P4FwYoDbpV59a-RU"
  );
} else {
  messaging = null;
}

export { messaging };
