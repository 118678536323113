import {
  LOAD_ALARMALERTBYPLATENOONYEAR,
  LOAD_VEHICLE_ALARMALERTBYPLATENOONYEAR,
  LOAD_VEHICLE_TYPEONYEAR
} from "./actions";

const initialState = {
  alarmalertbyplatenoonyearUrl: "",
  vehicleLists: [],
  vehicleTypeLists: [],
  alarmalertbyplatenoonyearLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ALARMALERTBYPLATENOONYEAR.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        alarmalertbyplatenoonyearLoading: loading
      };
    case LOAD_ALARMALERTBYPLATENOONYEAR.SUCCESS:
      return {
        ...state,
        alarmalertbyplatenoonyearUrl: action.payload.data.data,
        alarmalertbyplatenoonyearLoading: false
      };
    case LOAD_VEHICLE_ALARMALERTBYPLATENOONYEAR.REQUEST:
      const { loading_vehicle } = action.payload;
      return {
        ...state,
        alarmalertbyplatenoonyearLoading: loading_vehicle
      };
    case LOAD_VEHICLE_ALARMALERTBYPLATENOONYEAR.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        alarmalertbyplatenoonyearLoading: false
        //  loading: false
      };
    case LOAD_VEHICLE_TYPEONYEAR.SUCCESS:
      // console.log(action.payload.data.data);
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data
      };
    default:
      return state;
  }
};
