import axios from "axios";
import { all, put, call, takeEvery } from "redux-saga/effects";

import * as actions from "./actions";

function* loadOutofcontact(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { data } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/outofcontact`,
      data,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(actions.loadOutofcontact.success(res.data));
  } catch (err) {
    yield put(actions.loadOutofcontact.failure());
  }
}

function* updateOutofcontact(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { data, callback } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/outofcontact/update`,
      data,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(actions.updateOutofcontact.success(res.data));
  } catch (err) {
    yield put(actions.updateOutofcontact.failure());
  }
  callback();
}

export default function* watchoutofcontact() {
  yield all([
    takeEvery(actions.LOAD_OUTOFCONTACT.REQUEST, loadOutofcontact),
    takeEvery(actions.UPDATE_OUTOFCONTACT.REQUEST, updateOutofcontact),
  ]);
}
export { loadOutofcontact, updateOutofcontact };
