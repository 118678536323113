const COLUMNS = [
  {
    title: "ระยะเวลาสถานะ",
    dataIndex: "duration_range",
  },
  {
    title: "ทะเบียน",
    dataIndex: "plate_no",
  },
  {
    title: "รหัสพาหนะ",
    dataIndex: "vehicle_code",
  },
  {
    title: "ความเร็ว(km/h)",
    dataIndex: "velocity",
  },
  // {
  //   title: "Meter Fare",
  //   dataIndex: "meter_fare",
  // },
  {
    title: "สถานะพาหนะ",
    dataIndex: "vehicle_status",
  },
  {
    title: "รูดบัตร",
    dataIndex: "driver_license_swiping_status",
  },
  {
    title: "อัพเดทตำแหน่ง",
    dataIndex: "latest_pos_update_at",
  },
  {
    title: "เชื่อมต่อสัญญาณ",
    dataIndex: "gps_active_at",
  },
  // {
  //   title: "เครื่องยนต์",
  //   dataIndex: "engine_status"
  // },
  {
    title: "SOS",
    dataIndex: "is_sos_should_alert",
  },
  {
    title: "สถานะ Meter",
    dataIndex: "meter_status",
  },
  {
    title: "ที่อยู่ปัจจุบัน",
    dataIndex: "current_location",
  },
  {
    title: "รหัสกล่อง",
    dataIndex: "device_code",
  },
  {
    title: "โม่",
    dataIndex: "drum_status",
  },
  {
    title: "น้ำมัน",
    dataIndex: "fuel_status",
  },
  {
    title: "อุณหภูมิ 1",
    dataIndex: "temperature1",
  },
  {
    title: "อุณหภูมิ 2",
    dataIndex: "temperature2",
  },
  {
    title: "PTO",
    dataIndex: "PTO",
  },
  {
    title: "ประตู 1",
    dataIndex: "door1",
  },
  {
    title: "ประตู 2",
    dataIndex: "door2",
  },
  {
    title: "ประตู 3",
    dataIndex: "door3",
  },
  {
    title: "Boom",
    dataIndex: "boom",
  },
  {
    title: "เบอร์โทรกล่อง",
    dataIndex: "vehicle_tel",
  },
  {
    title: "รูปภาพ",
    dataIndex: "img",
  },
  {
    title: "อัพเดตรูปภาพ",
    dataIndex: "gps_image_active_at",
  },
  {
    title: "สถานะการจัดส่ง",
    dataIndex: "LDT",
  },
  {
    title: "บริษัท",
    dataIndex: "company_name",
  },
  {
    title: "ความชื้น",
    dataIndex: "humidity",
  },
  {
    title: "ความชื้น 2",
    dataIndex: "humidity2",
  },
  {
    title: "RPM",
    dataIndex: "RPM",
  },
  {
    title: "OBD",
    dataIndex: "OBD",
  },
  {
    title: "สถานะทริป",
    dataIndex: "tripStatus",
  },
  {
    title: "Plant",
    dataIndex: "plant",
  },
  {
    title: "จำนวนผู้โดยสาร",
    dataIndex: "passengerCount",
  },
  {
    title: "รุ่นพาหนะ",
    dataIndex: "model",
  },
  {
    title: "Start Trip",
    dataIndex: "startTrip",
  },
  {
    title: "Event Alarm",
    dataIndex: "alarmMessage",
  },
  {
    title: "Travel Distance",
    dataIndex: "travelDistance",
  },
  {
    title: "สีพาหนะ",
    dataIndex: "color",
  },
  {
    title: "Video",
    dataIndex: "video",
  },
  {
    title: "คนขับล่าสุด",
    dataIndex: "lastest_driver",
  },
  {
    title: "Battery",
    dataIndex: "battery",
  },
  {
    title: "สถานะการชาร์จแบตเตอรี่",
    dataIndex: "is_charging",
  },
  {
    title: "ความจุ SD CARD",
    dataIndex: "sdcard_available1",
  },
  {
    title: "SD CARD STATUS",
    dataIndex: "sdcard_status",
  },
  {
    title: "การใช้ไฟ",
    dataIndex: "pw_supply",
  },
  {
    title: "จำนวนดาวเทียม",
    dataIndex: "satellite",
  },
  {
    title: "ความแรงสัญญาณ",
    dataIndex: "sim",
  },
];

const SELECTED_VEHICLE_OBJ = {
  engine: "",
  speed: "",
  plate_no: "",
  device_code: "",
  vehicle_code: "",
  vehicle_type_name: "",
  gps_active_at: "",
  latest_pos_update_at: "",
  images: ["/img/no-image.jpg", "/img/no-image.jpg"],
  lat: "",
  lng: "",
  driver_license_swiping_status: null,
  driver_license_info: null,
  driving_time: "",
  address: "",
  PTO: "",
  boom: "",
  current_location: "",
  door1: "",
  door2: "",
  door3: "",
  driver1: "",
  drum_status: "",
  is_sos_should_alert: "",
  meter_status: "",
  temperature1: "",
  temperature2: "",
  vehicle_tel: "",
  gps_image_active_at: "",
  RPM: "",
  OBD: "",
  trip_status: "",
  passenger_count: "",
  model: "",
  start_trip: "",
  travel_distance: "",
  color: "",
  video: "",
  battery: "",
  is_charging: "",
  track_3: "",
  vehicle_group: [],
  duration_range: "",
  vehicle_id: "",
  fuel_status: "",
  voltage: 0,
  pw_supply: null,
};

const DEFAULT_CONFIG_COLUMN = [
  "plate_no",
  "velocity",
  "vehicle_status",
  "driver_license_swiping_status",
  "latest_pos_update_at",
  "gps_active_at",
  "current_location",
];

const DEFAULT_FILTER_VALUE = {
  searchName: "",
  deviceType: [],
  vehicleStatus: [
    "running",
    "stop-with-engine-on",
    "stop-with-engine-off",
    "connection-error",
  ],
  speedRange: [0, 200],
  timeRange: [0, 240],
  masterDataSelectvalue: ["vehicle_type_all", "vehicle_group_all"],
};
export {
  COLUMNS,
  SELECTED_VEHICLE_OBJ,
  DEFAULT_CONFIG_COLUMN,
  DEFAULT_FILTER_VALUE,
};
