import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";
import { Button, Form, Input, Row, Col, Select, DatePicker, AutoComplete } from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
const FormItem = Form.Item;
const { Option } = Select;
const OptionAuto = AutoComplete.Option;

const { RangePicker } = DatePicker;

export default class FilterHistory extends Component {


    componentDidMount() {
        const { loadMaintenancestatus, loadVehicle } = this.props;
        loadMaintenancestatus();
        loadVehicle();

    }


    // componentWillReceiveProps = nextProps => {
    //     const { vehicleLists } = nextProps;

    //     const platenolists = [];
    //     vehicleLists.map(item =>
    //         platenolists.push({
    //             key: item.id,
    //             name: item.plate_no
    //         })
    //     );
    //     this.setState({ platenoList: platenolists });
    // };


    render() {
        const { OnClose, onSubmit, maintenancestatusLists, vehicleLists, vehicle_id } = this.props;

        const platenolists = vehicleLists
            .map((v) => (
                <OptionAuto key={v.id}>
                    {v.plate_no}
                </OptionAuto>
            ))
        return (
            <div>
                <Formik
                    initialValues={{
                        vehicle_id: vehicle_id,
                        eventdate: [],
                        maintenance_status: "",

                    }}
                    validationSchema={yup.object().shape({
                        // name: yup.string.required("กรุณาเลือกช่วงวันที่"),
                        // code: yup.string.required("กรุณาเลือกรถ")
                    })}
                    onSubmit={values => {
                        const loading = true;
                        onSubmit(values, loading);
                    }}
                    render={({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleFocus,
                        setFieldValue,
                        resetForm
                    }) => (
                            <Row>
                                <Form onSubmit={handleSubmit}>

                                    <FormItem

                                        label={<LangContext.Consumer>
                                            {i18n => i18n.p.plateNo}
                                        </LangContext.Consumer>}
                                        validateStatus={
                                            touched.vehicle_id && errors.vehicle_id && "error"
                                        }
                                        help={touched.vehicle_id && errors.vehicle_id}
                                    >
                                        <AutoComplete
                                            dataSource={platenolists}
                                            style={{ width: 200 }}
                                            optionLabelProp="children"
                                            filterOption={true}
                                            optionFilterProp="children"
                                            // placeholder="ค้นหาทะเบียน"
                                            value={values.vehicle_id}
                                            onChange={value => setFieldValue("vehicle_id", value)}
                                            onSelect={value => setFieldValue("vehicle_id", value)}

                                        />
                                    </FormItem>



                                    <FormItem
                                        label={
                                            <LangContext.Consumer>
                                                {i18n => i18n.m.maintenance_status}
                                            </LangContext.Consumer>
                                        }
                                        validateStatus={
                                            touched.maintenance_status &&
                                            errors.maintenance_status &&
                                            "error"
                                        }
                                        help={touched.maintenance_status && errors.maintenance_status}
                                    >
                                        <Select
                                            onChange={value => setFieldValue("maintenance_status", value)}
                                            value={values.maintenance_status}
                                        >
                                            <Option value=""><LangContext.Consumer>
                                                {i18n => i18n.a.all}
                                            </LangContext.Consumer></Option>
                                            {maintenancestatusLists.map(item => (
                                                <Option key={item.id} value={item.id}>
                                                    {item.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </FormItem>

                                    <Form.Item
                                        label={
                                            <LangContext.Consumer>
                                                {i18n => i18n.d.dateRange}
                                            </LangContext.Consumer>
                                        }
                                        validateStatus={
                                            touched.eventdate && errors.eventdate && "error"
                                        }
                                        help={touched.eventdate && errors.eventdate}
                                    >
                                        <RangePicker
                                            onChange={value => setFieldValue("eventdate", value)}
                                            format="DD/MM/YYYY"
                                        />
                                    </Form.Item>



                                    <FormItem>
                                        <Row gutter={24}>
                                            <Col span={6}>
                                                <Button
                                                    // onClick={OnClose.bind(null, resetForm)}
                                                    onClick={OnClose}
                                                    style={{ paddingLeft: 5 }}
                                                >
                                                    <LangContext.Consumer>
                                                        {i18n => i18n.c.cancel}
                                                    </LangContext.Consumer>
                                                </Button>
                                            </Col>
                                            <Col span={1} />
                                            <Col span={6}>
                                                <Button htmlType="submit" type="primary">
                                                    <LangContext.Consumer>
                                                        {i18n => i18n.a.apply}
                                                    </LangContext.Consumer>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </FormItem>
                                </Form>

                            </Row>
                        )}
                />
            </div>
        );
    }
}
