import { createTypes, createAction } from "lib/action";

const LOAD_NUMBEROFDAYSWORKED = createTypes("numberofdaysworked", "load");
const LOAD_VEHICLE_NUMBEROFDAYSWORKED = createTypes(
  "vehicle_numberofdaysworked",
  "load"
);
const LOAD_VEHICLE_TYPE_NUMBEROFDAYSWORKED = createTypes("vehicletype_numberofdaysworked", "load");

const loadNumberofdaysworked = {
  request: (data, loading) =>
    createAction(LOAD_NUMBEROFDAYSWORKED.REQUEST, { data, loading }),
  success: data => createAction(LOAD_NUMBEROFDAYSWORKED.SUCCESS, { data }),
  failure: () => createAction(LOAD_NUMBEROFDAYSWORKED.FAILURE)
};

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_NUMBEROFDAYSWORKED.REQUEST, {
      vehicletypeID,
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_VEHICLE_NUMBEROFDAYSWORKED.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_NUMBEROFDAYSWORKED.FAILURE)
};

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_NUMBEROFDAYSWORKED.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_TYPE_NUMBEROFDAYSWORKED.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_NUMBEROFDAYSWORKED.FAILURE)
};

export {
  loadNumberofdaysworked,
  LOAD_NUMBEROFDAYSWORKED,
  loadVehicle,
  LOAD_VEHICLE_NUMBEROFDAYSWORKED,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_NUMBEROFDAYSWORKED
};
