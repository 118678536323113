import {
    REMOVE_LISTS,
    LOAD_TEMPORARY_TRACKING,
    CREATE_TEMPORARY_TRACKING,
    UPDATE_TEMPORARY_TRACKING
} from "./actions";

const initialState = {
    lists: [],
    total: 1,
    loading: false,
    temporaryTrackingDetailloading: false,
    draw: -1,
    success: ""

  };

export default (state = initialState, action) => {
 
    switch (action.type) {

        case REMOVE_LISTS:
            return initialState;

        case LOAD_TEMPORARY_TRACKING.REQUEST:
            const { loading } = action.payload;

            return {
                ...state,
                loading: true
            };
        case LOAD_TEMPORARY_TRACKING.SUCCESS:
            const { page } = action.payload;
        
            for (let i = 0; i < action.payload.lists.data.length; i++) {
                action.payload.lists.data[i].rowNumber = i + 1;
                if (page > 1)
                  action.payload.lists.data[i].rowNumber =
                    action.payload.lists.data[i].rowNumber + (page - 1) * 10;
            }
            return {
                ...state,
                lists: action.payload.lists.data,
                total: 
                    action.payload.lists.total - 1 <= 0
                    ? 1
                    : action.payload.lists.total <= 0
                    ? 1
                    : action.payload.lists.total,
                loading: false,
                draw: Math.floor(Math.random() * 999 + 1)
            };
        case CREATE_TEMPORARY_TRACKING.SUCCESS:
            return {
                ...state,
                loading: true
            };
        case UPDATE_TEMPORARY_TRACKING.SUCCESS:
            return {
                ...state,
                loading: false
            };
        
        default:
            return state;
    }
};
