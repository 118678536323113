import {
  LOAD_FUELREMAINRATE,
  LOAD_VEHICLE_FUELREMAINRATE,
  LOAD_VEHICLE_TYPE_FUELREMAINRATE
} from "./actions";

const initialState = {
  alarmalertbyplatenoUrl: "",
  vehicleLists: [],
  vehicleTypeLists: [],
  fuelremainrateLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_FUELREMAINRATE.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        fuelremainrateLoading: loading
      };
    case LOAD_FUELREMAINRATE.SUCCESS:
      return {
        ...state,
        alarmalertbyplatenoUrl: action.payload.data.data,
        fuelremainrateLoading: false
      };
    case LOAD_VEHICLE_FUELREMAINRATE.REQUEST:
      const { loading_vehicle } = action.payload;
      return {
        ...state,
        fuelremainrateLoading: loading_vehicle
      };
    case LOAD_VEHICLE_FUELREMAINRATE.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        fuelremainrateLoading: false
        //  loading: false
      };
    case LOAD_VEHICLE_TYPE_FUELREMAINRATE.SUCCESS:
      // console.log(action.payload.data.data);
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data
      };
    default:
      return state;
  }
};
