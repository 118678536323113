import { LOAD_OUTOFCONTACT, UPDATE_OUTOFCONTACT } from "./actions";

const initialState = {
  outofcontactList: [],
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_OUTOFCONTACT.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_OUTOFCONTACT.SUCCESS:
      return {
        ...state,
        outofcontactList: action.payload.data.data,
        total: action.payload.data.total,
        loading: false,
      };
    case LOAD_OUTOFCONTACT.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_OUTOFCONTACT.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_OUTOFCONTACT.SUCCESS:
      return {
        ...state,
        msg: "Update Success",
        status: "success",
        loading: false,
      };
    case UPDATE_OUTOFCONTACT.FAILURE:
      return {
        ...state,
        msg: action.payload.msg,
        status: "fail",
        loading: false,
      };
    default:
      return state;
  }
};
