import { Breadcrumb, Col, message, Row, Spin } from "antd";
import * as actions from "../actions";

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  GeneralStyledContent,
  StyledSearchForm,
} from "../../../../styled/common-styled";
import Forms from "./Forms";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";

class Edit extends Component {
  handleOnSumit = (value) => {
    let formatCreatePhoneNo = {
      ...value,
      user_id: this.props.profile.id,
    };

    this.props.update(
      formatCreatePhoneNo,
      this.generateMsg
    );
  };

  generateMsg = () => {
    switch (this.props.manageotp.status) {
      case "success":
        message.success(this.props.manageotp.msg);
        this.props.history.goBack();
        break;
      case "fail":
        message.error(this.props.manageotp.msg);
        break;
      default:
        break;
    }
  };

  loadData = () => {
    this.props.loadAllVehicle(
      this.props.manageotp.phoneWtihVehicle.company_id
    );
    this.props.loadCompanyByID(
      this.props.manageotp.phoneWtihVehicle.company_id
    );
  };

  componentDidMount() {
    this.props.loadByPhoneNo(
      this.props.match.params.id,
      this.loadData
    );
  }

  render() {
    return (
      <AuthorizeComponent matching_name="manageotp">
        <GeneralStyledContent>
          <Spin spinning={this.props.manageotp.loading}>
            <Row gutter={24}>
              <Col span={24}>
                <Breadcrumb
                  match={this.props.match}
                  style={{ margin: "0px 0px 14px 10px" }}
                />
              </Col>
            </Row>
            <StyledSearchForm>
              <Row type="flex" justify="start">
                <Col span={24}>
                  <Forms
                    disablePhoneCompany={true}
                    initialValues={
                      this.props.manageotp.phoneWtihVehicle
                    }
                    loadCompany={this.props.loadCompany}
                    companyLists={
                      this.props.manageotp.companyLists
                    }
                    vehicleLists={
                      this.props.manageotp.vehicleLists
                    }
                    loadAllVehicle={
                      this.props.loadAllVehicle
                    }
                    onSubmit={this.handleOnSumit}
                  />
                </Col>
              </Row>
            </StyledSearchForm>
          </Spin>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}
const mapStateToProps = ({
  manageotp,
  auth,
  auth: { profile },
}) => ({
  manageotp,
  auth,
  profile,
});

const mapDispatchToProps = {
  loadCompany: actions.loadCompany.request,
  loadAllVehicle: actions.loadAllVehicle.request,
  loadByPhoneNo:
    actions.loadPhoneNoWithVehicleByPhoneNo.request,
  loadCompanyByID: actions.loadCompanyByID.request,
  update: actions.updateMatchPhoneNoWithVehicle.request,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Edit);
