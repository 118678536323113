import { createTypes, createAction } from "lib/action";

const LOAD_VEHICLEINFORMATIONDLT = createTypes("vehicleinformationdlt", "load");
const LOAD_VEHICLE_VEHICLEINFORMATIONDLT = createTypes(
  "vehicle_vehicleinformationdlt",
  "load"
);

const loadVehicleinformationdlt = {

  request: (data, loading) =>
    createAction(LOAD_VEHICLEINFORMATIONDLT.REQUEST, { data, loading }),
  success: data => createAction(LOAD_VEHICLEINFORMATIONDLT.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLEINFORMATIONDLT.FAILURE)
};

const loadVehicle = {
  request: (plate_no) =>
    createAction(LOAD_VEHICLE_VEHICLEINFORMATIONDLT.REQUEST, {
      plate_no
    }),
  success: data =>
    createAction(LOAD_VEHICLE_VEHICLEINFORMATIONDLT.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_VEHICLEINFORMATIONDLT.FAILURE)
};



export {
  loadVehicleinformationdlt,
  LOAD_VEHICLEINFORMATIONDLT,
  loadVehicle,
  LOAD_VEHICLE_VEHICLEINFORMATIONDLT,

};
