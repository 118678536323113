import React, { Component } from "react";
import { Input, Button, Card, List, Icon, Tag } from "antd";

class Reason extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.deposit_reason,
      newItemText: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.addItem = this.addItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
  }

  handleInputChange(event) {
    this.setState({ newItemText: event.target.value });
  }

  addItem(event) {
    event.preventDefault();
    if (this.state.newItemText.trim() !== "") {
      this.props.setFieldValue([
        ...this.props.deposit_reason,
        {
          id: -99,
          companies_id: 1,
          code: "0001",
          name: this.state.newItemText,
          status: "A",
        },
      ]);
      this.setState({ newItemText: "" });
    }
  }

  removeItem(index) {
    this.props.setFieldValue(
      this.props.deposit_reason.filter((item, i) => i !== index)
    );
  }

  render() {
    return (
      <div>
        <Input
          placeholder="เพิ่มเหตุผล"
          value={this.state.newItemText}
          onChange={this.handleInputChange}
          style={{ width: 200 }}
        />
        <Button
          type="primary"
          onClick={this.addItem}
          style={{ marginLeft: 10 }}
        >
          เพิ่ม
        </Button>
        <List
          style={{
            marginTop: 20,
            border: "none",
          }}
          grid={{ gutter: 10, column: 1 }}
          bordered
          dataSource={this.props.deposit_reason}
          renderItem={(item, index) => (
            <List.Item
              style={{
                padding: "0px",
                height: "30px",
              }}
            >
              <Card style={{ borderRadius: "5px", maxWidth: "280px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      padding: "0px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.name}
                  </div>
                  <Icon
                    type="close"
                    style={{
                      fontSize: "16px",
                      color: "#ff3333",
                    }}
                    onClick={() => this.removeItem(index)}
                  />
                </div>
              </Card>
            </List.Item>
          )}
        />
      </div>
    );
  }
}

export default Reason;
