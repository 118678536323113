import {
  CREATE_CALIBRATEFUEL,
  CLEAR_RESPONSE,
} from "./actions";

const initialState = {
  calibratefuelLoading: false,
  dataResponse: { status: "", msg: "", id: "", action_type: "" },
 
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CALIBRATEFUEL.REQUEST:
      const { loading: loading2 } = action.payload;
      return {
        ...state,
        calibratefuelLoading: loading2
      };
    case CREATE_CALIBRATEFUEL.SUCCESS:
      return {
        ...state,
        dataResponse: { status: action.payload.data.status, msg: action.payload.data.msg, action_type: action.payload.data.action_type },
        calibratefuelLoading: false
      };
   
    case CLEAR_RESPONSE:
      return {
        ...state,
        dataResponse: { status: "", msg: "", id: "", action_type: "" }
      };

    default:
      return state;
  }
};
