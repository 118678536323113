import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";
import { getlocalstorage } from "./../../../constants/local_storage";

function* loadServiceMoveDevice(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      accessToken,
      filterObj,
      vehicle_visibility,
      m_user_id,
      company_id
    }
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getServiceMoveDevice`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        filterObj,
        vehicle_visibility,
        m_user_id,
        company_id
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadServiceMoveDevice.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadServiceMoveDevice.failure());
  }
}

function* loadServiceMoveDeviceHistory(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      accessToken,
      filterObj,
      vehicle_visibility,
      m_user_id,
      company_id
    }
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/servicemovedevice/history`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        filterObj,
        vehicle_visibility,
        m_user_id,
        company_id
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadServiceMoveDeviceHistory.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadServiceMoveDeviceHistory.failure());
  }
}

function* loadVehicleType(action) {
  const {
    data: { accessToken }
  } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/servicemovedevice/getVehicleType`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadVehicleType.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadVehicleType.failure());
  }
}

function* loadMaintenanceType(action) {
  const {
    data: { accessToken }
  } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/servicemovedevice/getMaintenanceType`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadMaintenanceType.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadMaintenanceType.failure());
  }
}

function* loadMaintenanceStatus(action) {
  const {
    data: { accessToken }
  } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/servicemovedevice/getMaintenanceStatus`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadMaintenanceStatus.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadMaintenanceStatus.failure());
  }
}

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebygroupid`,
      {
        companyID: COMPANY_ID,
        vehicletypeID: "",
        allow_vehicles: vehicle_visibility
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadVehicle.success(res.data));
  } catch (err) {
    yield put(actions.loadVehicle.failure());
  }
}

function* loadVehicleByID(action) {
    const ACCESSTOKEN = localStorage.getItem("access-token");
    const vehicleID = action.payload.data;

    try {
        const res = yield call(
          axios.get,
          `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebyID/${vehicleID}`,
          { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
        );
        yield put(actions.loadVehicleByID.success(res.data));
      } catch (err) {
        yield put(actions.loadVehicleByID.failure());
      }
}
  
function* loadProvince(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getprovince`,
      {},
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadProvince.success(res.data));
  } catch (err) {
    yield put(actions.loadProvince.failure());
  }
}

function* loadAmphur(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const province_id = action.payload.data;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getamphur`,
      {
        province_id
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadAmphur.success(res.data));
  } catch (err) {
    yield put(actions.loadAmphur.failure());
  }
}

function* create(action) {
  const {
    data: {
      company_id,
      vehicles_id,
      body_no_intstall,
      contact_name,
      plate_no_install,
      contact_telno,
      submit_telno,
      submit_name,
      location_id,
      appointment_place,
      province,
      amphur,
      confirm_appointment_place,
      detail_problem,
      comment,
      m_user_id,
      accessToken
    },
    loading
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/servicemovedevice/create`,
      {
        company_id,
        vehicles_id,
        body_no_intstall,
        contact_name,
        plate_no_install,
        contact_telno,
        submit_telno,
        submit_name,
        location_id,
        appointment_place,
        province,
        amphur,
        confirm_appointment_place,
        detail_problem,
        comment,
        m_user_id
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    loading(res.data);
    yield put(actions.create.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.create.failure());
  }
}

export default function* watchServiceMoveDevice() {
    yield all([
        takeEvery(actions.LOAD_SERVICEMOVEDEVICE.REQUEST, loadServiceMoveDevice),
        takeEvery(actions.LOAD_VEHICLETYPE.REQUEST, loadVehicleType),
        takeEvery(actions.LOAD_MAINTENANCETYPE.REQUEST, loadMaintenanceType),
        takeEvery(actions.LOAD_MAINTENANCESTATUS.REQUEST, loadMaintenanceStatus),
        takeEvery(actions.LOAD_VEHICLELISTS.REQUEST, loadVehicle),
        takeEvery(actions.LOAD_PROVINCE.REQUEST, loadProvince),
        takeEvery(actions.LOAD_AMPHUR.REQUEST, loadAmphur),
        takeEvery(actions.CREATE.REQUEST, create),
        takeEvery(actions.LOAD_SERVICEMOVEDEVICE_HISTORY.REQUEST, loadServiceMoveDeviceHistory),
        takeEvery(actions.LOAD_VEHICLELISTSBYID.REQUEST, loadVehicleByID),
    ]);
}
export {
    loadServiceMoveDevice,
    loadVehicleType,
    loadMaintenanceType,
    loadMaintenanceStatus,
    loadVehicle,
    loadProvince,
    loadAmphur,
    loadServiceMoveDeviceHistory,
    loadVehicleByID,
    create
}
