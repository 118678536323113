import {
  LOAD_DELIVERYITEMDELETEHISTORY,
  REMOVE_LISTS,
  LOAD_DELIVERYITEMDELETEHISTORY_DETAIL
} from "./actions";

const initialState = {
  lists: [],
  total: 1,
  loading: false,
  detailLoading: false,
  draw: -1,
  selectedDeliveryItemDeleteHistory: {
    deliveryitem_id: null,
    device_id: null,
    hardware_id: "",
    firstname: "",
    lastname: "",
    phone_number: "",
    address: "",
    remark: ""
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState;
    case LOAD_DELIVERYITEMDELETEHISTORY.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        loading: loading
      };
    case LOAD_DELIVERYITEMDELETEHISTORY.SUCCESS:
      const { page } = action.payload;
      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1;
        if (page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber + (page - 1) * 10;
      }
      return {
        ...state,
        lists: action.payload.lists.data,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1)
      };
    case LOAD_DELIVERYITEMDELETEHISTORY_DETAIL.REQUEST:
      return {
        ...state,
        detailLoading: true
      };
    case LOAD_DELIVERYITEMDELETEHISTORY_DETAIL.SUCCESS:
      return {
        ...state,
        selectedDeliveryItem: action.payload.deliveryitem.data,
        detailLoading: false
      };

    default:
      return state;
  }
};
