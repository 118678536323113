import {
  LOAD_DELIVERY,
  LOAD_VEHICLE_DELIVERY,
  LOAD_VEHICLE_TYPE_DELIVERY
} from "./actions";

const initialState = {
  deliveryUrl: "",
  vehicleLists: [],
  vehicleTypeLists: [],
  deliveryLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DELIVERY.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        deliveryLoading: loading
      };
    case LOAD_DELIVERY.SUCCESS:

      return {
        ...state,
        deliveryUrl: action.payload.data.data,
        deliveryLoading: false
      };
    case LOAD_VEHICLE_DELIVERY.REQUEST:
      const { loading_vehicle } = action.payload;
      return {
        ...state,
        deliveryLoading: loading_vehicle
      };
    case LOAD_VEHICLE_DELIVERY.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        deliveryLoading: false
      };
    case LOAD_VEHICLE_TYPE_DELIVERY.SUCCESS:
      // console.log(action.payload.data.data);
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data
      };
    default:
      return state;
  }
};
