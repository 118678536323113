import axios from "axios";
import { all, put, call, takeEvery } from "redux-saga/effects";
import { getErrorMsg } from "lib/fetch";

import * as actions from "./actions";

function* loadDepositFuelCreditReport(action) {
  const { obj } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_GOLANG_MONGO}/reports/go/depositfuelcredit`,
      obj
    );

    yield put(actions.loadDepositFuelCreditReport.success(res.data));
    window.location = res.data.result;
  } catch (err) {
    yield put(actions.loadDepositFuelCreditReport.failure());
  }
}
export default function* watchreportdepositfuelcredit() {
  yield all([
    takeEvery(
      actions.LOAD_DEPOSIT_FUEL_CREDIT_REPORT.REQUEST,
      loadDepositFuelCreditReport
    ),
  ]);
}
export { loadDepositFuelCreditReport };
