const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const regularDrawerSize = document.body.clientWidth / 3;

const askForSoundPlayingText =
  "This page detect Re-loading according to media policy we need you to Enable sound playing.";

const speedRangeMarks = {
  0: "0",
  20: "20",
  40: "40",
  60: "60",
  80: "80",
  100: "100",
  120: "120",
  140: "140",
  160: "160",
  180: "180",
  200: "200+",
};

const notSafePassword = [
  "123456",
  "password",
  "123456789",
  "12345",
  "12345678",
  "qwerty",
  "1234567",
  "111111",
  "1234567890",
  "123123",
  "abc123",
  "1234",
  "password1",
  "iloveyou",
  "1q2w3e4r",
  "000000",
  "qwerty123",
  "zaq12wsx",
  "dragon",
  "sunshine",
  "princess",
  "letmein",
  "654321",
  "monkey",
  "27653",
  "1qaz2wsx",
  "123321",
  "qwertyuiop",
  "superman",
  "asdfghjkl",
];
const timeRangeMarks = {
  0: "0",
  20: "20",
  40: "40",
  60: "60",
  90: "90",
  120: "120",
  150: "150",
  180: "180",
  210: "210",
  240: "240+",
};

export {
  regularDrawerSize,
  phoneRegExp,
  speedRangeMarks,
  timeRangeMarks,
  askForSoundPlayingText,
  notSafePassword,
};
