import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import { Link, Redirect } from "react-router-dom";

import {
  Button,
  Form,
  Divider,
  Row,
  Col,
  Input,
  Checkbox,
  Transfer,
  Tag,
  Tabs,
  Spin,
  Modal,
  Popconfirm,
  message,
  Icon
} from "antd";

import LangContext, { i18n } from "modules/shared/context/langContext";

import axios from "axios";

import {
  autoGenKey,
  helper_convertDateformat
} from "../../../../../lib/helper";

const getNodeKey = ({ node: object, treeIndex: number }) => {
  return number;
};

const listStyle = {
  height: 300,
  marginLeft: "30px"
};

class VehicleForm extends Component {
  state = {
    allVehicleGroup: [],
    allVehicle: [],
    selectedVehicleGroup: [],
    selectedVehicle: []
  };

  loadDetailData = (path, stateKeyName) => {
    let self = this;
    axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/datavisibility/${path}/${this.props.auth.profile.company_id}`,
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` }
        }
      )
      .then(function(response) {
        self.setState({ [stateKeyName]: response.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  filterOption = (inputValue, option) => option.title.indexOf(inputValue) > -1;

  componentDidMount() {
    let self = this;
    new Promise(function(resolve, reject) {
      resolve(self.loadDetailData("getvehiclegroup", "allVehicleGroup"));
    }).then(function(result) {
      return new Promise(function(resolve, reject) {
        resolve(self.loadDetailData("getvehicle", "allVehicle"));
      });
    });
  }

  render() {
    console.log(this.props.initialValues);
    return (
      <Formik
        enableReinitialize={true}
        initialValues={this.props.initialValues}
        // validationSchema={yup.object().shape({
        //   critical_level: yup.string().required(),
        //   open_noti_id: yup.string().required(),
        //   close_noti_id: yup.string().nullable()
        // })}
        onSubmit={(values, actions) => {
          console.log(values);
          console.log(this.props.initialValues);
          this.props.next(this.props.current);
          // this.props.setInitValue({
          //   ...this.props.initialValues,
          //   policy_name: values.policy_name,
          //   policy_type_id: values.policy_type_id,
          //   policy_sub_type: values.policy_sub_type,
          //   category: values.category
          // });
        }}
        render={({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <LangContext.Consumer>
            {i18n => {
              if (
                this.props.action == "create" ||
                (this.props.action == "edit" && values.policy_id != null)
              )
                return (
                  <Form onSubmit={handleSubmit} layout="horizontal">
                    <Row gutter={24}>
                      <Divider orientation="left">
                        <LangContext.Consumer>
                          {i18n => i18n.v.vehicleGroup}
                        </LangContext.Consumer>
                      </Divider>
                      <Transfer
                        titles={[
                          <LangContext.Consumer>
                            {i18n => i18n.i.itemLeft}
                          </LangContext.Consumer>,
                          <LangContext.Consumer>
                            {i18n => i18n.i.itemSelected}
                          </LangContext.Consumer>
                        ]}
                        listStyle={listStyle}
                        style={{ margin: "0px 0px 0px 15px" }}
                        dataSource={this.state.allVehicleGroup}
                        showSearch
                        filterOption={this.filterOption}
                        targetKeys={
                          this.props.initialValues.selectedVehicleGroup
                        }
                        onChange={(targetKeys, direction, moveKeys) =>
                          this.props.setInitValue({
                            ...this.props.initialValues,
                            selectedVehicleGroup: targetKeys
                          })
                        }
                        render={item => item.title}
                      />

                      <Divider orientation="left">
                        <LangContext.Consumer>
                          {i18n => i18n.v.vehicle}
                        </LangContext.Consumer>
                      </Divider>

                      <Row>
                        <Col span={23} offset={1}>
                          <Checkbox
                            checked={this.props.initialValues.all_vehicle}
                            onChange={e =>
                              this.props.setInitValue({
                                ...this.props.initialValues,
                                all_vehicle: e.target.checked
                              })
                            }
                          >
                            <LangContext.Consumer>
                              {i18n => i18n.a.all}
                            </LangContext.Consumer>
                          </Checkbox>
                        </Col>
                      </Row>
                      {!this.props.initialValues.all_vehicle && (
                        <Transfer
                          titles={[
                            <LangContext.Consumer>
                              {i18n => i18n.i.itemLeft}
                            </LangContext.Consumer>,
                            <LangContext.Consumer>
                              {i18n => i18n.i.itemSelected}
                            </LangContext.Consumer>
                          ]}
                          style={{ margin: "25px 0px 0px 15px" }}
                          listStyle={listStyle}
                          dataSource={this.state.allVehicle}
                          showSearch
                          filterOption={this.filterOption}
                          targetKeys={this.props.initialValues.selectedVehicle}
                          onChange={(targetKeys, direction, moveKeys) =>
                            this.props.setInitValue({
                              ...this.props.initialValues,
                              selectedVehicle: targetKeys
                            })
                          }
                          render={item => item.title}
                        />
                      )}

                      <div style={{ margin: "40px 0px 0px 0px" }}>
                        <Button
                          style={{ marginLeft: 8 }}
                          onClick={() => {
                            // props.loadTripDetail({ id: 1778183 });
                            this.props.prev(this.props.current);
                          }}
                        >
                          <LangContext.Consumer>
                            {i18n => i18n.p.previous}
                          </LangContext.Consumer>
                        </Button>

                        <Button
                          htmlType="submit"
                          type="primary"
                          style={{ marginLeft: 8 }}
                          // onClick={() => this.props.next(this.props.current)}
                        >
                          <LangContext.Consumer>
                            {i18n => i18n.s.saveNext}
                          </LangContext.Consumer>
                        </Button>
                      </div>
                    </Row>
                  </Form>
                );
            }}
          </LangContext.Consumer>
        )}
      />
    );
  }
}
export default connect(
  ({ policyState, auth }) => ({ policyState, auth }),
  {}
)(VehicleForm);
