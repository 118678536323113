const PRODUCTION_ENDPOINT = "https://taxi.iammami.xyz";
const PRODUCTION_API_ENDPOINT = "https://taxi.iammami.xyz/api";
//const PRODUCTION_API_ENDPOINT = "http://api/api";
// const DEV_API_ENDPOINT = "http://booking-api/api";
const DEV_API_ENDPOINT = PRODUCTION_API_ENDPOINT;

// const NOTIFICATION_SERVER = "http://104.248.147.160:3000";
const NOTIFICATION_SERVER = "https://mqtt.iammami.xyz:3333";

export {
  PRODUCTION_ENDPOINT,
  PRODUCTION_API_ENDPOINT,
  DEV_API_ENDPOINT,
  NOTIFICATION_SERVER
};
