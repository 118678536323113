import { createTypes, createAction } from "lib/action";

const LOAD_DELIVERY = createTypes("delivery", "load");
const LOAD_VEHICLE_DELIVERY = createTypes(
  "vehicle_delivery",
  "load"
);
const LOAD_VEHICLE_TYPE_DELIVERY = createTypes("vehicletype_delivery", "load");

const loadDelivery = {
  request: (data, loading) =>
    createAction(LOAD_DELIVERY.REQUEST, { data, loading }),
  success: data => createAction(LOAD_DELIVERY.SUCCESS, { data }),
  failure: () => createAction(LOAD_DELIVERY.FAILURE)
};

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_DELIVERY.REQUEST, {
      vehicletypeID,
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_VEHICLE_DELIVERY.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_DELIVERY.FAILURE)
};

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_DELIVERY.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_TYPE_DELIVERY.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_DELIVERY.FAILURE)
};

export {
  loadDelivery,
  LOAD_DELIVERY,
  loadVehicle,
  LOAD_VEHICLE_DELIVERY,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_DELIVERY
};
