import {
    LOAD_MASTERDATA_LOCATIONGROUP,
    CREATE_MASTERDATA_LOCATIONGROUP,
    UPDATE_MASTERDATA_LOCATIONGROUP,
    REMOVE_LISTS
} from "./actions";

const intialState = {
  lists: [],
  total: 1,
  loading: false,
  draw: -1,
};

export default (state = intialState, action) => {
    switch (action.type) {
        case REMOVE_LISTS:
            return intialState;
            
        case LOAD_MASTERDATA_LOCATIONGROUP.REQUEST:
            const { loading } = action.payload;
            return {
                ...state,
                loading: loading
            }
            
        case LOAD_MASTERDATA_LOCATIONGROUP.SUCCESS:
            const { page } = action.payload;
            for (let i = 0; i < action.payload.lists.data.length; i++) {
                action.payload.lists.data[i].rowNumber = i + 1;
                if (page > 1)
                  action.payload.lists.data[i].rowNumber =
                    action.payload.lists.data[i].rowNumber + (page - 1) * 10;
            }

        return {
            ...state,
            lists: action.payload.lists.data,
            total: 
                action.payload.lists.total - 1 <= 0
                ? 1
                : action.payload.lists.total <= 0
                ? 1
                : action.payload.lists.total,
                loading: false,
            draw: Math.floor(Math.random() * 999 + 1)
        };

        case CREATE_MASTERDATA_LOCATIONGROUP.SUCCESS:
            return {
                ...state,
                loading: false
            };
        
        case UPDATE_MASTERDATA_LOCATIONGROUP.SUCCESS:
            return {
                ...state,
                loading: false
            }

        default:
            return state;
    }
}