import { Form, Input, Drawer, Button, AutoComplete } from "antd";
import LangContext from "modules/shared/context/langContext";

import React from "react";

const Filter = (props) => (
  <Drawer
    title={
      <LangContext.Consumer>
        {(i18n) => i18n.f.filterSearch}
      </LangContext.Consumer>
    }
    width={520}
    placement="right"
    onClose={props.onClose}
    maskClosable={true}
    visible={props.filterVisible}
    style={{
      overflow: "auto",
      paddingBottom: 53,
    }}
  >
    <Form>
      <Form.Item
        label={
          <LangContext.Consumer>
            {(i18n) => i18n.manageFuelRate.vehicleModel}
          </LangContext.Consumer>
        }
      >
        <AutoComplete
          value={props.filterBrand}
          placeholder={
            <LangContext.Consumer>
              {(i18n) => i18n.s.searchModel}
            </LangContext.Consumer>
          }
          optionFilterProp="children"
          optionLabelProp="children"
          allowClear
          onSearch={(v) => {
            props.handleStateChange("searchModel", v);
          }}
          onSelect={(v) => {
            props.handleStateChange("selectModel", v);
          }}
        >
          {props.vehicleBrandLists.map((v) => {
            return (
              <AutoComplete.Option
                key={v.vehicle_brands_name}
                value={v.vehicle_brands_name}
                data={v}
              >
                {v.vehicle_brands_name}
              </AutoComplete.Option>
            );
          })}
        </AutoComplete>
      </Form.Item>
      <Form.Item
        label={
          <LangContext.Consumer>
            {(i18n) => i18n.v.vehicleCode}
          </LangContext.Consumer>
        }
      >
        <Input
          value={props.filterCode}
          onChange={(e) => {
            props.handleStateChange("code", e.target.value);
          }}
        />
      </Form.Item>
      <Button
        style={{
          marginRight: 8,
        }}
        onClick={props.onClose}
      >
        <LangContext.Consumer>{(i18n) => i18n.c.cancel}</LangContext.Consumer>
      </Button>
      <Button type="primary" onClick={props.onFilterButtonClick}>
        <LangContext.Consumer>{(i18n) => i18n.a.apply}</LangContext.Consumer>
      </Button>
    </Form>
  </Drawer>
);

export default Filter;
