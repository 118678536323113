import {
  LOAD_DEPOSIT_FUEL_CREDIT_HISTORY,
  LOAD_VEHICLE_FUEL_CREDIT_HISTORY,
  LOAD_VEHICLE_INFO,
  UPDATE_VEHICLE_FUEL_CREDIT,
  LOAD_REASON,
} from "./actions";

const initialState = {
  historylist: [],
  vehicleList: [],
  vehicleInfo: {},
  reasonList: [],
  showForm: false,
  msg: "",
  status: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DEPOSIT_FUEL_CREDIT_HISTORY.REQUEST:
      return {
        ...state,
        vehicleInfo: {},
        loading: true,
        showForm: false,
      };
    case LOAD_VEHICLE_FUEL_CREDIT_HISTORY.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_VEHICLE_INFO.REQUEST:
      return {
        ...state,
        vehicleInfo: {},
        loading: true,
        showForm: false,
      };
    case LOAD_REASON.REQUEST:
      return {
        ...state,
        reasonList: [],
        loading: true,
        showForm: false,
      };
    case UPDATE_VEHICLE_FUEL_CREDIT.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case LOAD_DEPOSIT_FUEL_CREDIT_HISTORY.SUCCESS:
      return {
        ...state,
        historylist: action.payload.data,
        loading: false,
      };
    case LOAD_VEHICLE_FUEL_CREDIT_HISTORY.SUCCESS:
      return {
        ...state,
        vehicleList: action.payload.data,
        loading: false,
      };
    case LOAD_VEHICLE_INFO.SUCCESS:
      let check = false;
      if (action.payload.data !== {}) {
        check = true;
      }
      return {
        ...state,
        vehicleInfo: action.payload.data,
        loading: false,
        showForm: check,
      };
    case UPDATE_VEHICLE_FUEL_CREDIT.SUCCESS:
      return {
        ...state,
        msg: "Update Success",
        status: "success",
        loading: false,
        showForm: false,
      };
    case LOAD_REASON.SUCCESS:
      return {
        ...state,
        reasonList: action.payload.data,
        loading: false,
      };
    case LOAD_DEPOSIT_FUEL_CREDIT_HISTORY.FAILURE:
    case LOAD_VEHICLE_FUEL_CREDIT_HISTORY.FAILURE:
    case LOAD_VEHICLE_INFO.FAILURE:
    case LOAD_REASON.FAILURE:
    case UPDATE_VEHICLE_FUEL_CREDIT.FAILURE:
      return {
        ...state,
        msg: action.payload.err,
        loading: false,
        status: "fail",
        shouldReload: false,
      };
    default:
      return state;
  }
};
