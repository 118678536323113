import {
  Form,
  Col,
  Row,
  Input,
  Button,
  DatePicker,
  AutoComplete,
  Checkbox,
  message,
  Select,
} from "antd";

import React, { Component, PureComponent, Fragment } from "react";

import * as yup from "yup";

import { Formik } from "formik";

import Sensors from "./Sensors";
import LangContext from "modules/shared/context/langContext";
import moment from "moment";
import Axios from "axios";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 18,
      offset: 0,
    },
  },
};
const { RangePicker } = DatePicker;

export default class DriverSearch extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      driverList: [],
    };
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleDateFormat = (value) => {
    const new_format = moment(value["_d"]).format("YYYY-MM-DD HH:mm:ss");
    return new_format;
  };

  handleFilterValues = (val, fliterArr) => {
    let keysArr = Object.entries(val);
    let fliteredValue = Object.fromEntries(
      keysArr.filter(([key, value]) => {
        return !fliterArr.includes(key);
      })
    );

    return fliteredValue;
  };

  getDrive = async () => {
    const res = await Axios.post(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdriver`,
      {
        allow_vehicles: this.props.auth.profile.vehicle_visibility,
        company_id: this.props.auth.profile.company_id,
      },
      {
        headers: {
          Authorization: `Bearer ${this.props.auth.accessToken}`,
        },
      }
    );

    let temp = res.data.data.map((value) => {
      return {
        label: `${value.firstname} ${value.lastname}`,
        value: value.id,
      };
    });
    this.setState({ driverList: temp });
  };

  componentDidMount() {
    this.getDrive();
    // console.log("fetch Complete");
  }

  changeDriverCode = (value, setFieldValue) => {
    this.setState({ driver_code: value });
    setFieldValue("driver_code", value);
  };

  render() {
    const namelistArr = [];
    this.state.driverList.map((v) => {
      namelistArr.push(`${v.firstname} ${v.lastname}`);
    });

    return (
      <Fragment>
        {/* <Row type="flex" align="top">
          <Col> */}
        <Formik
          enableReinitialize={true}
          initialValues={{
            type: "driver",
            driver_id: "",
            driver_name: "",
            starting_time: moment()
              .hours(0)
              .minutes(0)
              .seconds(0),
            finishing_time: moment()
              .hours(23)
              .minutes(59)
              .seconds(0),
            engine_on: false,
            cut_fuel_engine_on_off: false,
            cut_fuel_min: "3",
            sensors: {
              fuel_1: false,
              fuel_2: false,
              temperature1: false,
              temperature2: false,
              gate_1: false,
              gate_2: false,
              engineStatus: false,
              pto: false,
              boom: false,
              turn_right: false,
              turn_left: false,
              high_headlight: false,
              low_headlight: false,
              horn: false,
              brake: false,
              windshield_viper: false,
              reversing_light: false,
              battery: false,
              vol: false,
            },
          }}
          validationSchema={yup.object().shape({
            starting_time: yup
              .date()
              .nullable()
              .required(
                <LangContext>{(i18n) => i18n.v.valueIsRequired}</LangContext>
              ),
            finishing_time: yup
              .date()
              .min(yup.ref("starting_time"), "เวลาสิ้นสุดต้องมากกว่าเวลาเริ่ม")
              .nullable()
              .required(
                <LangContext>{(i18n) => i18n.v.valueIsRequired}</LangContext>
              ),
          })}
          onSubmit={(values) => {
            values.date_start = this.handleDateFormat(values.starting_time);
            values.date_end = this.handleDateFormat(values.finishing_time);

            const fliered_value = this.handleFilterValues(values, [
              "starting_time",
              "finishing_time",
            ]);
            this.props.onSubmit(fliered_value);
            // console.log("values", values);
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleFocus,
            setFieldValue,
            isValidating,
          }) => {
            return (
              <Form {...formItemLayout} onSubmit={handleSubmit}>
                <Form.Item
                  label={<LangContext>{(i18n) => i18n.d.driver}</LangContext>}
                >
                  <AutoComplete
                    style={{ width: 200 }}
                    placeholder={
                      <LangContext>
                        {(i18n) => i18n.s.search_driver}
                      </LangContext>
                    }
                    optionLabelProp="children"
                    filterOption={true}
                    optionFilterProp="children"
                    onSelect={(value, op) => {
                      setFieldValue("driver_id", value);
                      setFieldValue("driver_name", op.props.children);
                    }}
                  >
                    {this.state.driverList.map((val) => {
                      return (
                        <AutoComplete.Option
                          value={val.value.toString()}
                          data={val}
                        >
                          {val.label}
                        </AutoComplete.Option>
                      );
                    })}
                  </AutoComplete>
                </Form.Item>

                <Form.Item
                  label={
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.dateRange}
                    </LangContext.Consumer>
                  }
                  required
                  validateStatus={
                    touched.starting_time && errors.starting_time && "warning"
                  }
                  help={touched.starting_time && errors.starting_time}
                >
                  <RangePicker
                    showTime={{
                      defaultValue: moment("00:00:00", "HH:mm"),
                      format: "HH:mm",
                    }}
                    format="DD/MM/YYYY HH:mm"
                    defaultValue={[
                      moment()
                        .hours(0)
                        .minutes(0),
                      moment()
                        .hours(23)
                        .minutes(59),
                    ]}
                    onChange={(value) => {
                      setFieldValue("starting_time", value[0]);
                      setFieldValue("finishing_time", value[1]);
                    }}
                  ></RangePicker>
                </Form.Item>
                <Form.Item
                  label={
                    <LangContext.Consumer>
                      {(i18n) => i18n.e.engine_on_only}
                    </LangContext.Consumer>
                  }
                >
                  <Checkbox
                    checked={values.engine_on}
                    onChange={(e) => {
                      setFieldValue("engine_on", e.target.checked);
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <LangContext.Consumer>
                      {(i18n) => i18n.c.cut_fuel_engine_on_off}
                    </LangContext.Consumer>
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      checked={values.cut_fuel_engine_on_off}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setFieldValue("cut_fuel_engine_on_off", checked);

                        const defaultCutFuelMin = checked ? "3" : "0";
                        setFieldValue("cut_fuel_min", defaultCutFuelMin);
                      }}
                    />
                    {values.cut_fuel_engine_on_off && (
                      <Select
                        style={{ width: "100px", marginLeft: "20px" }}
                        value={values.cut_fuel_min || "3"}
                        onChange={(value) => {
                          setFieldValue("cut_fuel_min", value);
                        }}
                      >
                        <Select.Option value="1">1 นาที</Select.Option>
                        <Select.Option value="3">3 นาที</Select.Option>
                        <Select.Option value="5">5 นาที</Select.Option>
                      </Select>
                    )}
                  </div>
                </Form.Item>

                <Form.Item
                  label={
                    <LangContext.Consumer>
                      {(i18n) => i18n.variable.sensor}
                    </LangContext.Consumer>
                  }
                >
                  <Sensors values={values} setFieldValue={setFieldValue} />
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                  <Row type="flex" justify="end">
                    <Button type="primary" htmlType="submit">
                      <LangContext>{(i18n) => i18n.r.report}</LangContext>
                    </Button>
                  </Row>
                </Form.Item>
              </Form>
            );
          }}
        />
        {/* </Col>
        </Row> */}
      </Fragment>
    );
  }
}
