const fix_minutes = [
  {
    start: "00",
    end: "01",
  },
  {
    start: "01",
    end: "02",
  },
  {
    start: "02",
    end: "03",
  },
  {
    start: "03",
    end: "04",
  },
  {
    start: "04",
    end: "05",
  },
  {
    start: "05",
    end: "06",
  },
  {
    start: "06",
    end: "07",
  },
  {
    start: "07",
    end: "08",
  },
  {
    start: "08",
    end: "09",
  },
  {
    start: "09",
    end: "10",
  },
  {
    start: "10",
    end: "11",
  },
  {
    start: "11",
    end: "12",
  },
  {
    start: "12",
    end: "13",
  },
  {
    start: "13",
    end: "14",
  },
  {
    start: "14",
    end: "15",
  },
  {
    start: "15",
    end: "16",
  },
  {
    start: "16",
    end: "17",
  },
  {
    start: "17",
    end: "18",
  },
  {
    start: "18",
    end: "19",
  },
  {
    start: "19",
    end: "20",
  },
  {
    start: "20",
    end: "21",
  },
  {
    start: "21",
    end: "22",
  },
  {
    start: "22",
    end: "23",
  },
  {
    start: "23",
    end: "24",
  },
  {
    start: "24",
    end: "25",
  },
  {
    start: "25",
    end: "26",
  },
  {
    start: "26",
    end: "27",
  },
  {
    start: "27",
    end: "28",
  },
  {
    start: "28",
    end: "29",
  },
  {
    start: "29",
    end: "30",
  },
  {
    start: "30",
    end: "31",
  },
  {
    start: "31",
    end: "32",
  },
  {
    start: "32",
    end: "33",
  },
  {
    start: "33",
    end: "34",
  },
  {
    start: "34",
    end: "35",
  },
  {
    start: "35",
    end: "36",
  },
  {
    start: "36",
    end: "37",
  },
  {
    start: "37",
    end: "38",
  },
  {
    start: "38",
    end: "39",
  },
  {
    start: "39",
    end: "40",
  },
  {
    start: "40",
    end: "41",
  },
  {
    start: "41",
    end: "42",
  },
  {
    start: "42",
    end: "43",
  },
  {
    start: "43",
    end: "44",
  },
  {
    start: "44",
    end: "45",
  },
  {
    start: "45",
    end: "46",
  },
  {
    start: "46",
    end: "47",
  },
  {
    start: "47",
    end: "48",
  },
  {
    start: "48",
    end: "49",
  },
  {
    start: "49",
    end: "50",
  },
  {
    start: "50",
    end: "51",
  },
  {
    start: "51",
    end: "52",
  },
  {
    start: "52",
    end: "53",
  },
  {
    start: "53",
    end: "54",
  },
  {
    start: "54",
    end: "55",
  },
  {
    start: "55",
    end: "56",
  },
  {
    start: "56",
    end: "57",
  },
  {
    start: "57",
    end: "58",
  },
  {
    start: "58",
    end: "59",
  },
  {
    start: "59",
    end: "00",
  },
];

const fix_hours = [
  { start_hour: "00", end_hour: "01" },
  { start_hour: "01", end_hour: "02" },
  { start_hour: "02", end_hour: "03" },
  { start_hour: "03", end_hour: "04" },
  { start_hour: "04", end_hour: "05" },
  { start_hour: "05", end_hour: "06" },
  { start_hour: "06", end_hour: "07" },
  { start_hour: "07", end_hour: "08" },
  { start_hour: "08", end_hour: "09" },
  { start_hour: "09", end_hour: "10" },
  { start_hour: "10", end_hour: "11" },
  { start_hour: "11", end_hour: "12" },
  { start_hour: "12", end_hour: "13" },
  { start_hour: "13", end_hour: "14" },
  { start_hour: "14", end_hour: "15" },
  { start_hour: "15", end_hour: "16" },
  { start_hour: "16", end_hour: "17" },
  { start_hour: "17", end_hour: "18" },
  { start_hour: "18", end_hour: "19" },
  { start_hour: "19", end_hour: "20" },
  { start_hour: "20", end_hour: "21" },
  { start_hour: "21", end_hour: "22" },
  { start_hour: "22", end_hour: "23" },
  { start_hour: "23", end_hour: "00" },
];

export { fix_minutes, fix_hours };
