import {
  LOAD_INSURANCE,
  LOAD_VEHICLE_INSURANCE,
  LOAD_VEHICLE_TYPE_INSURANCE
} from "./actions";

const initialState = {
  insuranceUrl: "",
  vehicleLists: [],
  vehicleTypeLists: [],
  insuranceLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_INSURANCE.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        insuranceLoading: loading
      };
    case LOAD_INSURANCE.SUCCESS:

      return {
        ...state,
        insuranceUrl: action.payload.data.data,
        insuranceLoading: false
      };
    case LOAD_VEHICLE_INSURANCE.REQUEST:
      const { loading_vehicle } = action.payload;
      return {
        ...state,
        insuranceLoading: loading_vehicle
      };
    case LOAD_VEHICLE_INSURANCE.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        insuranceLoading: false
      };
    case LOAD_VEHICLE_TYPE_INSURANCE.SUCCESS:
      // console.log(action.payload.data.data);
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data
      };
    default:
      return state;
  }
};
