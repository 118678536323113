import { createTypes, createAction } from "lib/action";

const LOAD_FUELFULL = createTypes("fuelfull", "load");

const loadFuelFull = {
  request: (data, loading) =>
    createAction(LOAD_FUELFULL.REQUEST, { data, loading }),
  success: data => createAction(LOAD_FUELFULL.SUCCESS, { data }),
  failure: () => createAction(LOAD_FUELFULL.FAILURE)
};


export {
  loadFuelFull,
  LOAD_FUELFULL,
};
