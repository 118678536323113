import { createTypes, createAction } from "lib/action";

const LOAD_TEMPERATURE = createTypes("temperature", "load");
const LOAD_VEHICLE_TEMPERATURE = createTypes(
  "vehicle_temperature",
  "load"
);
const LOAD_VEHICLE_TYPE_TEMPERATURE = createTypes("vehicletype_temperature", "load");

const loadTemperature = {
  request: (data, loading) =>
    createAction(LOAD_TEMPERATURE.REQUEST, { data, loading }),
  success: data => createAction(LOAD_TEMPERATURE.SUCCESS, { data }),
  failure: () => createAction(LOAD_TEMPERATURE.FAILURE)
};

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_TEMPERATURE.REQUEST, {
      vehicletypeID,
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_VEHICLE_TEMPERATURE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TEMPERATURE.FAILURE)
};

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_TEMPERATURE.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_TYPE_TEMPERATURE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_TEMPERATURE.FAILURE)
};

export {
  loadTemperature,
  LOAD_TEMPERATURE,
  loadVehicle,
  LOAD_VEHICLE_TEMPERATURE,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_TEMPERATURE
};
