import { createTypes, createAction } from "lib/action";

const LOAD_DRIVINGLOGSPECIAL = createTypes("drivinglogspecial", "load");
const LOAD_VEHICLE_DRIVINGLOGSPECIAL = createTypes(
  "vehicle_drivinglogspecial",
  "load"
);
const LOAD_VEHICLE_TYPE_DRIVINGLOGSPECIAL = createTypes("vehicletype_drivinglogspecial", "load");
const LOAD_DRIVER_DRIVINGLOGSPECIAL = createTypes("driver_drivinglogspecial", "load");

const loadDrivinglog = {

  request: (data, loading) =>
    createAction(LOAD_DRIVINGLOGSPECIAL.REQUEST, { data, loading }),
  success: data => createAction(LOAD_DRIVINGLOGSPECIAL.SUCCESS, { data }),
  failure: () => createAction(LOAD_DRIVINGLOGSPECIAL.FAILURE)
};

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_DRIVINGLOGSPECIAL.REQUEST, {
      vehicletypeID,
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_VEHICLE_DRIVINGLOGSPECIAL.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_DRIVINGLOGSPECIAL.FAILURE)
};

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_DRIVINGLOGSPECIAL.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_TYPE_DRIVINGLOGSPECIAL.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_DRIVINGLOGSPECIAL.FAILURE)
};

const loadDriver = {
  request: () => createAction(LOAD_DRIVER_DRIVINGLOGSPECIAL.REQUEST, {}),
  success: data => createAction(LOAD_DRIVER_DRIVINGLOGSPECIAL.SUCCESS, { data }),
  failure: () => createAction(LOAD_DRIVER_DRIVINGLOGSPECIAL.FAILURE)
};

export {
  loadDrivinglog,
  LOAD_DRIVINGLOGSPECIAL,
  loadVehicle,
  LOAD_VEHICLE_DRIVINGLOGSPECIAL,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_DRIVINGLOGSPECIAL,
  LOAD_DRIVER_DRIVINGLOGSPECIAL,
  loadDriver
};
