import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb as AntBreadcrumb, Icon } from "antd";

const General = (match) => {
  return match.path
    .replace("_", " ")
    .replace("/", "")
    .split("/")
    .filter((val) => val.indexOf(":") <= -1)
    .map((ele, i) => {
      let isLastest =
        i + 1 !=
        match.path
          .replace("/", "")
          .split("/")
          .filter((val) => val != "")
          .filter((val) => val.indexOf(":") <= -1).length;

      return isLastest ? (
        <AntBreadcrumb.Item key={`breadcrumb-${ele}`}>
          <Link to={`/${ele.replace(" ", "_")}`}>{ele.replace("_", " ")}</Link>
        </AntBreadcrumb.Item>
      ) : (
        <AntBreadcrumb.Item key={`breadcrumb-${ele}`}>
          {ele.replace("_", " ")}
        </AntBreadcrumb.Item>
      );
    });
};

const Customeurl = (customeurl, match) => {
  return match.path
    .replace("_", " ")
    .replace("/", "")
    .split("/")
    .filter((val) => val.indexOf(":") <= -1)
    .map((ele, i) => {
      let isLastest =
        i + 1 !=
        match.path
          .replace("/", "")
          .split("/")
          .filter((val) => val != "")
          .filter((val) => val.indexOf(":") <= -1).length;

      let url = customeurl[i]

      return isLastest && url !== null? (
        <AntBreadcrumb.Item key={`breadcrumb-${ele}`}>
          <Link to={`${url}`}>{ele.replace("_", " ")}</Link>
        </AntBreadcrumb.Item>
      ) : (
        <AntBreadcrumb.Item key={`breadcrumb-${ele}`}>
          {ele.replace("_", " ")}
        </AntBreadcrumb.Item>
      );
    });
};

const Breadcrumb = ({ match, ...props }) => (
  <AntBreadcrumb {...props}>
    <AntBreadcrumb.Item key="breadcrumb-home">
      <Link to={`/`}>
        <Icon type="home" />
      </Link>
    </AntBreadcrumb.Item>
    {typeof props.customeurl !== "undefined"
      ? Customeurl(props.customeurl, match)
      : General(match)}
  </AntBreadcrumb>
);
export default Breadcrumb;
