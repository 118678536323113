import axios from "axios";
import { all, put, call, takeEvery } from "redux-saga/effects";
import { getErrorMsg } from "lib/fetch";

import * as actions from "./actions";

function* loadWithdrawFuelCreditHistory(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { filterobj } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/withdrawfuelcredit`,
      filterobj,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    // console.log("companyLists", res.data.data);
    yield put(actions.loadWithdrawFuelCreditHistory.success(res.data));
  } catch (err) {
    yield put(actions.loadWithdrawFuelCreditHistory.failure());
  }
}
function* loadVehicleCreditInfo(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { obj } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/withdrawfuelcredit/getvehicleinfo`,
      obj,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    // console.log("companyLists", res.data.data);
    yield put(actions.loadVehicleCreditInfo.success(res.data));
  } catch (err) {
    yield put(actions.loadVehicleCreditInfo.failure());
  }
}
function* loadWithdrawInfo(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { obj } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/withdrawfuelcredit/getwithdrawdetail`,
      obj,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    // console.log("companyLists", res.data.data);
    yield put(actions.loadWithdrawInfo.success(res.data));
  } catch (err) {
    yield put(actions.loadWithdrawInfo.failure());
  }
}
function* loadWithdrawPath(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { obj } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/withdrawfuelcredit/getwithdrawpath`,
      obj,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    // console.log("companyLists", res.data.data);
    yield put(actions.loadWithdrawPath.success(res.data));
  } catch (err) {
    yield put(actions.loadWithdrawPath.failure());
  }
}
function* updateWithdrawFuelCredit(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { obj, generateMsg } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/withdrawfuelcredit/updatefuelcredit`,
      obj,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(actions.updateWithdrawFuelCredit.success(res.data));
  } catch (err) {
    yield put(actions.updateWithdrawFuelCredit.failure(getErrorMsg(err)));
  }
  generateMsg();
}
function* updateWithdrawPath(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { obj, generateMsg } = action.payload;
  let data = new FormData();
  data.append("withdraw_id", obj.withdraw_id);
  data.append("files", obj.file);
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/withdrawfuelcredit/uploadwithdrawsheet`,
      data,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(actions.updateWithdrawPath.success(res.data));
  } catch (err) {
    yield put(actions.updateWithdrawPath.failure(getErrorMsg(err)));
  }
  generateMsg();
}

export default function* watchWithdrawfuelcredit() {
  yield all([
    takeEvery(
      actions.LOAD_WITHDRAW_FUEL_CREDIT_HISTORY.REQUEST,
      loadWithdrawFuelCreditHistory
    ),
    takeEvery(actions.LOAD_VEHICLE_CREDIT_INFO.REQUEST, loadVehicleCreditInfo),
    takeEvery(actions.LOAD_WITHDRAW_INFO.REQUEST, loadWithdrawInfo),
    takeEvery(actions.LOAD_WITHDRAW_PATH.REQUEST, loadWithdrawPath),
    takeEvery(
      actions.UPDATE_WITHDRAW_VEHICLE_FUEL_CREDIT.REQUEST,
      updateWithdrawFuelCredit
    ),
    takeEvery(actions.UPDATE_WITHDRAW_PATH.REQUEST, updateWithdrawPath),
  ]);
}
export {
  loadWithdrawFuelCreditHistory,
  loadVehicleCreditInfo,
  loadWithdrawInfo,
  loadWithdrawPath,
  updateWithdrawFuelCredit,
  updateWithdrawPath,
};
