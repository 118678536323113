import { createTypes, createAction } from "lib/action";
const LOAD_ZONE = createTypes("loadzone", "load");
const UPDATE_ZONE = createTypes("detailzone", "update");
const LOAD_DETAIL_ZONE = createTypes("loaddetailzone", "load");
const LOAD_DETAIL_ZONE_EDIT = createTypes("loaddetailzoneedit", "load");
const LOAD_AUTOCOMPLETE = createTypes("autocomplete", "load");
const SELECTED_AUTOCOMPLETE = createTypes("autocomplete", "selected");
const CREATE_MASTERDATA_ZONE = createTypes("masterdatazone", "create");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
    return {
        type: REMOVE_LISTS
    };
}

const loadZone = {
    request: (data, loading) =>
        createAction(LOAD_ZONE.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(LOAD_ZONE.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_ZONE.FAITLURE)  
};

const loadDetail = {
    request: (data, loading) =>
    createAction(LOAD_DETAIL_ZONE.REQUEST, { data, loading}),
success: (lists, page) =>
    createAction(LOAD_DETAIL_ZONE.SUCCESS, {lists, page}),
failure: () => createAction(LOAD_DETAIL_ZONE.FAITLURE) 
};

const loadDetaiEdit = {
    request: (data, loading) =>
        createAction(LOAD_DETAIL_ZONE_EDIT.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(LOAD_DETAIL_ZONE_EDIT.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_DETAIL_ZONE_EDIT.FAITLURE) 
};

const loadAutocompleteLocation = {
    request: (url, companyID, accessToken, queryString) =>
      createAction(LOAD_AUTOCOMPLETE.REQUEST, {
        url,
        companyID,
        accessToken,
        queryString
      }),
    success: lists => createAction(LOAD_AUTOCOMPLETE.SUCCESS, { lists }),
    failure: () => createAction(LOAD_AUTOCOMPLETE.FAILURE)
};

function selectedAutocompleteLocation(id, lists, selectedName, cb) {
    return {
      type: SELECTED_AUTOCOMPLETE,
      payload: { id, lists, selectedName, cb }
    };
}

const createZone = {
    request: (data, loading) =>
        createAction(CREATE_MASTERDATA_ZONE.REQUEST, { data, loading}),
    success: res => createAction(CREATE_MASTERDATA_ZONE.SUCCESS, { res }),
        failure: () => createAction(CREATE_MASTERDATA_ZONE.FAILURE)
};

const updatezone = {
    request: (data, loading) =>
        createAction(UPDATE_ZONE.REQUEST, { data, loading}),
    success: res => createAction(UPDATE_ZONE.SUCCESS, { res }),
        failure: () => createAction(UPDATE_ZONE.FAILURE)
}

export {
    LOAD_ZONE, loadZone,
    LOAD_DETAIL_ZONE, loadDetail,
    LOAD_DETAIL_ZONE_EDIT, loadDetaiEdit,
    UPDATE_ZONE, updatezone,
    LOAD_AUTOCOMPLETE, loadAutocompleteLocation,
    SELECTED_AUTOCOMPLETE, selectedAutocompleteLocation,
    CREATE_MASTERDATA_ZONE, createZone,
    REMOVE_LISTS, removeLists
};