import { createTypes, createAction } from "lib/action";

const LOAD_GRAPHSENSORS = createTypes("graphsensor", "load");

const loadGraphsensors = {
    request: (data, accessToken) => createAction(LOAD_GRAPHSENSORS.REQUEST, { data, accessToken }),
    success: (data) =>
        createAction(LOAD_GRAPHSENSORS.SUCCESS, { data }),
    failure: (error) => createAction(LOAD_GRAPHSENSORS.FAILURE, { error }),
}

export {
    LOAD_GRAPHSENSORS,
    loadGraphsensors
}