import { MATCH_USER } from "./actions";

const initialState = {
  userV1: "",
  userV2: "",
  manageRepairDeviceLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MATCH_USER.SUCCESS:
      return {
        ...state,
        manageRepairDeviceLoading: false,
      };

    default:
      return state;
  }
};
