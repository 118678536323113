import { createTypes, createAction } from "lib/action";

const LOAD_DISTFROMLASTMAINTENANCE = createTypes("distfromlastmaintenance", "load");
const LOAD_VEHICLE_DISTFROMLASTMAINTENANCE = createTypes(
  "vehicle_distfromlastmaintenance",
  "load"
);
const LOAD_VEHICLE_TYPE_DISTFROMLASTMAINTENANCE = createTypes("vehicletype_distfromlastmaintenance", "load");
const LOAD_PART_TYPE = createTypes("parttype", "load");

const loadDistfromlastmaintenance = {
  request: (data, loading) =>
    createAction(LOAD_DISTFROMLASTMAINTENANCE.REQUEST, { data, loading }),
  success: data => createAction(LOAD_DISTFROMLASTMAINTENANCE.SUCCESS, { data }),
  failure: () => createAction(LOAD_DISTFROMLASTMAINTENANCE.FAILURE)
};

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_DISTFROMLASTMAINTENANCE.REQUEST, {
      vehicletypeID,
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_VEHICLE_DISTFROMLASTMAINTENANCE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_DISTFROMLASTMAINTENANCE.FAILURE)
};

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_DISTFROMLASTMAINTENANCE.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_TYPE_DISTFROMLASTMAINTENANCE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_DISTFROMLASTMAINTENANCE.FAILURE)
};

const loadPartType = {
  request: () => createAction(LOAD_PART_TYPE.REQUEST, {}),
  success: data => createAction(LOAD_PART_TYPE.SUCCESS, { data }),
  failure: () => createAction(LOAD_PART_TYPE.FAILURE)
};

export {
  loadDistfromlastmaintenance,
  LOAD_DISTFROMLASTMAINTENANCE,
  loadVehicle,
  LOAD_VEHICLE_DISTFROMLASTMAINTENANCE,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_DISTFROMLASTMAINTENANCE,
  loadPartType,
  LOAD_PART_TYPE
};
