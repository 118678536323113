import React from "react";
import { Button, message, Col, Form, Input, Row, Divider } from "antd";
import LangContext from "modules/shared/context/langContext";

const { Item } = Form;
const FormItem = Item;

const SpeedLimitOfTheEvent = ({
  setFieldValue,
  values,
  touched,
  errors,
  current,
  steps,
  prev,
}) => (
  <>
    <FormItem>
      <label style={{ fontSize: 20, fontWeight: "bold" }}>เงื่อนไข</label>
      <Divider style={{ margin: "10px 0px", width: "100%" }} />
    </FormItem>

    {values.dataType === "SEVT" && (
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <FormItem
            label={
              <LangContext.Consumer>
                {(i18n) => i18n.policy.pto_gate1_on}
              </LangContext.Consumer>
            }
            validateStatus={touched.pto_on && errors.pto_on ? "error" : ""}
            help={touched.pto_on && errors.pto_on}
          >
            <Input
              style={{ width: 150 }}
              name="pto_on"
              onChange={(e) => {
                const { value } = e.target;
                const reg = /^-?[0-9]*(\.[0-9]*)?$/;
                if ((!isNaN(value) && reg.test(value)) || value === "") {
                  setFieldValue("pto_on", value);
                }
              }}
              value={values.pto_on}
              maxLength={25}
            />
            {" กม./ชั่วโมง"}
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem
            label={
              <LangContext.Consumer>
                {(i18n) => i18n.policy.boom_gate2_on}
              </LangContext.Consumer>
            }
            validateStatus={touched.boom_on && errors.boom_on ? "error" : ""}
            help={touched.boom_on && errors.boom_on}
          >
            <Input
              style={{ width: 150 }}
              name="boom_on"
              onChange={(e) => {
                const { value } = e.target;
                const reg = /^-?[0-9]*(\.[0-9]*)?$/;
                if ((!isNaN(value) && reg.test(value)) || value === "") {
                  setFieldValue("boom_on", value);
                }
              }}
              value={values.boom_on}
              maxLength={25}
            />
            {" กม./ชั่วโมง"}
          </FormItem>
        </Col>
      </Row>
    )}

    <FormItem>
      {current < steps.length - 1 && (
        <Button type="primary" htmlType="submit">
          <LangContext.Consumer>{(i18n) => i18n.n.next}</LangContext.Consumer>
        </Button>
      )}
      {current === steps.length - 1 && (
        <Button
          type="primary"
          onClick={() => message.success("Processing complete!")}
        >
          Done
        </Button>
      )}
      {current > 0 && (
        <Button style={{ marginLeft: 8 }} onClick={prev}>
          Previous
        </Button>
      )}
    </FormItem>
  </>
);

export default SpeedLimitOfTheEvent;
