import { createTypes, createAction } from "lib/action";


const LOAD_VEHICLE_HONEYTOASTDOWNLOADFILE = createTypes(
  "vehicle_honeytoastdownloadfile",
  "load"
);


const loadVehicle = {
  request: (allimei) => createAction(LOAD_VEHICLE_HONEYTOASTDOWNLOADFILE.REQUEST, { allimei }),
  success: data => createAction(LOAD_VEHICLE_HONEYTOASTDOWNLOADFILE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_HONEYTOASTDOWNLOADFILE.FAILURE)
};

export {
  loadVehicle,
  LOAD_VEHICLE_HONEYTOASTDOWNLOADFILE
};
