import {
    LOAD_LOCATIONSHARING_TO,
    LOAD_LOCATIONSHARING_FROM,
    CREATE_LOCATIONSHARING,
    REMOVE_LISTS
} from "./actions";

const initialState = {
    sharedToLists: [],
    sharedFromLists: [],
    sharedToTotal: 1,
    sharedFromTotal: 1,
    loading: false,
    detailLoading: false,
    draw: -1
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REMOVE_LISTS:
            return initialState;

        case LOAD_LOCATIONSHARING_TO.REQUEST:
            const { loading } = action.payload;
            return {
                ...state,
                loading: loading
            };

        case LOAD_LOCATIONSHARING_TO.SUCCESS:
            const { page } = action.payload;
            for (let i = 0; i < action.payload.lists.data.length; i++) {
                action.payload.lists.data[i].rowNumber = i + 1;
                if (page > 1)
                    action.payload.lists.data[i].rowNumber =
                      action.payload.lists.data[i].rowNumber + (page - 1) * 10;
            }
            console.log(action.payload.lists.data)
            console.log(action.payload.lists)
            return {
                ...state,
                sharedToLists: action.payload.lists.data,
                sharedToTotal:
                    action.payload.lists.total - 1 <= 0
                      ? 1
                      : action.payload.lists.total <= 0
                      ? 1
                      : action.payload.lists.total,
                loading: false,
                draw: Math.floor(Math.random() * 999 + 1)
            };

        case LOAD_LOCATIONSHARING_FROM.REQUEST:
            return {
                ...state,
                loading:  action.payload.loading
            };

        
        case LOAD_LOCATIONSHARING_FROM.SUCCESS:
            for (let i = 0; i < action.payload.lists.data.length; i++) {
                action.payload.lists.data[i].rowNumber = i + 1;
                if (action.payload.page > 1)
                    action.payload.lists.data[i].rowNumber =
                        action.payload.lists.data[i].rowNumber + (action.payload.page - 1) * 10;
            }
            console.log(action.payload.lists)
            console.log(action.payload.lists)
            return {
                ...state,
                sharedFromLists: action.payload.lists.data,
                sharedFromTotal:
                    action.payload.lists.total - 1 <= 0
                        ? 1
                        : action.payload.lists.total <= 0
                        ? 1
                        : action.payload.lists.total,
                    loading: false,
                draw: Math.floor(Math.random() * 999 + 1)
            };

        case CREATE_LOCATIONSHARING.REQUEST:
            return {
                ...state,
                loading:  true
            };

        case CREATE_LOCATIONSHARING.SUCCESS:
            return {
                ...state,
                loading:  false
            };
            
        default:
            return state;
    }
}