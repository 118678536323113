import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Badge,
  Button,
  Col,
  Icon,
  message,
  Pagination,
  Popconfirm,
  Row,
  Spin,
  Table,
  Tooltip,
  Input,
  InputNumber,
} from "antd";
import Filter from "./Filter";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import LangContext from "modules/shared/context/langContext";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import { debounce } from "debounce";
import * as actions from "../actions";

const columnStyle = { fontSize: "12px", fontWeight: "400" };

const initialFilter = {
  filterBrand: "",
  filterCode: "",
  page: 1,
  pageSize: 10,
};

class Index extends Component {
  state = {
    ...initialFilter,
    filterVisible: false,
    loading: false,
    editingIndex: -1,
    editedData: {},
    editingSubIndex: -1,
    editedSubData: {},
    companyID: this.props.auth.profile.company_id,
    vehicle_visibility: this.props.auth.profile.vehicle_visibility,
  };

  componentDidMount() {
    this.props.loadVehicleBrand({
      ...initialFilter,
      companyID: this.props.auth.profile.company_id,
      vehicle_visibility: this.props.auth.profile.vehicle_visibility,
    });
    this.props.loadVehicleBrands({
      filterBrand: "",
      companyID: this.props.auth.profile.company_id,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.managefuelrate.vehicleLists !==
      this.props.managefuelrate.vehicleLists
    ) {
      this.setState({ data: this.props.managefuelrate.vehicleLists.data });
    }
  }

  handlePageChange = (page) => {
    const { filterBrand, filterCode, pageSize } = this.state;
    this.setState({ page, loading: true });
    this.props.loadVehicleBrand({
      companyID: this.props.auth.profile.company_id,
      vehicle_visibility: this.props.auth.profile.vehicle_visibility,
      filterBrand,
      filterCode,
      page,
      pageSize,
    });
  };

  handleStateChange = (type, value) => {
    switch (type) {
      case "code":
        this.setState({
          filterCode: value,
        });
        break;
      case "searchModel":
        this.setState({ filterBrand: value });
        debounce(
          this.props.loadVehicleBrands({
            filterBrand: value,
            companyID: this.props.auth.profile.company_id,
          }),
          1500
        );
        break;
      case "selectModel":
        this.setState({ filterBrand: value });
        break;
      default:
        break;
    }
  };

  handleEdit = (index, dataIndex, value) => {
    console.log("handleEdit:", value);
    if (/^[0-9.]*$/.test(value) || value === "") {
      const { editedData } = this.state;
      const newData = { ...editedData };
      if (!newData[index]) newData[index] = {};
      newData[index][dataIndex] = value;
      this.setState({ editedData: newData });
    }
  };

  handleEditSub = (index, dataIndex, value) => {
    console.log("handleEditSub :", value);
    if (/^[0-9.]*$/.test(value) || value === "") {
      const { editedSubData } = this.state;
      const newData = { ...editedSubData };
      if (!newData[index]) newData[index] = {};
      newData[index][dataIndex] = value;
      this.setState({ editedSubData: newData });
    }
  };

  handleEditClick = (record) => {
    const { editingIndex } = this.state;
    if (editingIndex === record.vehicle_brand_id) {
      this.handleSaveSub();
    } else {
      let editData = this.state.editedData;
      editData[record.vehicle_brand_id] = {
        driving_rate: record.driving_rate,
        parking_rate: record.parking_rate,
      };
      this.setState({
        editingIndex: record.vehicle_brand_id,
        editedData: editData,
      });
    }
  };

  handleEditClickSub = (record) => {
    const { editingSubIndex } = this.state;
    if (editingSubIndex === record.id) {
      this.handleSaveSub();
    } else {
      let editSubData = this.state.editedSubData;
      editSubData[record.id] = {
        vehicle_driving_rate: record.vehicle_driving_rate,
        vehicle_parking_rate: record.vehicle_parking_rate,
      };
      this.setState({ editingSubIndex: record.id, editedSubData: editSubData });
    }
  };

  handleSave = () => {
    const { editedData, editingIndex } = this.state;
    if (editingIndex !== -1) {
      const newData = [...this.state.data];
      const index = newData.findIndex(
        (item) => editingIndex === item.vehicle_brand_id
      );
      if (index > -1) {
        const item = newData[index];
        if (editedData[editingIndex]) {
          newData.splice(index, 1, {
            ...item,
            ...editedData[editingIndex],
          });
          this.setState({ data: newData, editingIndex: -1, editedData: {} });
          const editedItem = newData[index];
          const payload = {
            companyID: this.props.auth.profile.company_id,
            vehicle_brand_id: editedItem.vehicle_brand_id,
            vehicle_brand_name: editedItem.name,
            driving_rate: parseFloat(editedItem.driving_rate),
            parking_rate: parseFloat(editedItem.parking_rate),
          };
          this.props.updateVehicleBrand(payload, this.generateMsg);
        }
      }
    }
  };

  handleSaveSub = () => {
    const { editedSubData, editingSubIndex } = this.state;
    if (editingSubIndex !== -1) {
      const newData = this.state.data.find((item) =>
        item.vehicles.find((vehicle) => vehicle.id == editingSubIndex)
      ).vehicles;
      const index = this.state.data
        .find((item) =>
          item.vehicles.find((vehicle) => vehicle.id == editingSubIndex)
        )
        .vehicles.findIndex((vehicle) => vehicle.id == editingSubIndex);

      if (index > -1) {
        const item = newData[index];

        if (editedSubData[editingSubIndex]) {
          newData.splice(index, 1, {
            ...item,
            ...editedSubData[editingSubIndex],
          });
          this.setState({
            data: newData,
            editingSubIndex: -1,
            editedSubData: {},
          });
          const editedItem = newData[index];
          const payload = {
            companyID: this.props.auth.profile.company_id,
            vehicle_id: editedItem.id,
            vehicle_driving_rate: parseFloat(editedItem.vehicle_driving_rate),
            vehicle_parking_rate: parseFloat(editedItem.vehicle_parking_rate),
          };
          this.props.updateVehicleBrand(payload, this.generateMsg);
        }
      }
    }
  };

  renderEditableCell = (record, dataIndex) => {
    const { editingIndex, editedData } = this.state;
    const isEditing = editingIndex === record.vehicle_brand_id;
    const editableColumns = ["driving_rate", "parking_rate"];

    return editableColumns.includes(dataIndex) ? (
      isEditing ? (
        <InputNumber
          min={0}
          value={
            editedData[record.vehicle_brand_id]
              ? editedData[record.vehicle_brand_id][dataIndex]
              : record[dataIndex]
          }
          onChange={(e) =>
            this.handleEdit(record.vehicle_brand_id, dataIndex, e)
          }
        />
      ) : (
        <div>{record[dataIndex]}</div>
      )
    ) : (
      <div>{record[dataIndex]}</div>
    );
  };

  renderEditableSubCell = (record, dataIndex) => {
    const { editingSubIndex, editedSubData } = this.state;
    const isSubEditing = editingSubIndex === record.id;
    const editableSubColumns = ["vehicle_driving_rate", "vehicle_parking_rate"];

    return editableSubColumns.includes(dataIndex) ? (
      isSubEditing ? (
        <InputNumber
          min={0}
          value={
            editedSubData[record.id]
              ? editedSubData[record.id][dataIndex]
              : record[dataIndex]
          }
          onChange={(e) => this.handleEditSub(record.id, dataIndex, e)}
        />
      ) : (
        <div>{record[dataIndex]}</div>
      )
    ) : (
      <div>{record[dataIndex]}</div>
    );
  };

  generateMsg = () => {
    switch (this.props.managefuelrate.status) {
      case "success":
        message.success(this.props.managefuelrate.msg);
        this.props.loadVehicleBrand({
          companyID: this.props.auth.profile.company_id,
          vehicle_visibility: this.props.auth.profile.vehicle_visibility,
          filterBrand: this.state.filterBrand,
          filterCode: this.state.filterCode,
          page: this.state.page,
          pageSize: this.state.pageSize,
        });
        break;
      case "fail":
        message.error(this.props.managefuelrate.msg);
        break;
      default:
        break;
    }
  };

  render() {
    const columns = [
      {
        title: "#",
        dataIndex: "rowNumber",
        width: 60,
        render: (text, record, index) => {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{index + 1 + (this.state.page - 1) * 10}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.manageFuelRate.vehicleGroupNumber}
          </LangContext.Consumer>
        ),
        dataIndex: "vehicle_brand_id",
        key: "vehicle_brand_id",
        width: 90,
        render: (text, record, index) => {
          return {
            props: {
              style: columnStyle,
            },
            children: this.renderEditableCell(
              record,
              "vehicle_brand_id",
              "text"
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.manageFuelRate.vehicleModel}
          </LangContext.Consumer>
        ),
        dataIndex: "name",
        key: "name",
        width: 250,
        render: (text, record, index) => {
          return {
            props: {
              style: columnStyle,
            },
            children: this.renderEditableCell(record, "name", "text"),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.manageFuelRate.consumptionWhileRunning}
          </LangContext.Consumer>
        ),
        dataIndex: "driving_rate",
        key: "driving_rate",
        width: 300,
        render: (text, record, index) => {
          return {
            props: {
              style: columnStyle,
            },
            children: this.renderEditableCell(record, "driving_rate", "text"),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.manageFuelRate.consumptionWhileParking}
          </LangContext.Consumer>
        ),
        dataIndex: "parking_rate",
        key: "parking_rate",
        width: 300,
        render: (text, record, index) => {
          return {
            props: {
              style: columnStyle,
            },
            children: this.renderEditableCell(record, "parking_rate", "text"),
          };
        },
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: 90,
        render: (text, record) => {
          const { editingIndex } = this.state;
          const isEditing = editingIndex === record.vehicle_brand_id;
          const isDisabledRow = record.vehicle_brand_id === 0;

          return isDisabledRow ? null : (
            <span>
              {isEditing ? (
                <React.Fragment>
                  <Popconfirm
                    title="Sure to Save?"
                    onConfirm={() => {
                      this.handleSave();
                    }}
                  >
                    <a style={{ margin: "4px" }}>Save</a>
                  </Popconfirm>

                  <Popconfirm
                    title="Sure to cancel?"
                    onConfirm={() => this.setState({ editingIndex: -1 })}
                  >
                    <a>Cancel</a>
                  </Popconfirm>
                </React.Fragment>
              ) : (
                <a
                  disabled={editingIndex !== -1}
                  onClick={() => this.handleEditClick(record)}
                >
                  <Tooltip
                    placement="left"
                    title={
                      <LangContext.Consumer>
                        {(i18n) => i18n.e.edit}
                      </LangContext.Consumer>
                    }
                    arrowPointAtCenter
                    style={{
                      padding: "0px 1px 0px 0px",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  >
                    <Icon type="edit" />
                  </Tooltip>
                </a>
              )}
            </span>
          );
        },
      },
    ];

    const sub_columns = [
      {
        title: "#",
        dataIndex: "rowNumber",
        width: 60,
        render: (text, record, index) => {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{index + 1}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.v.vehicleCode}
          </LangContext.Consumer>
        ),
        dataIndex: "code",
        key: "code",
        render: (text, record, index) => {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.manageFuelRate.consumptionWhileRunning}
          </LangContext.Consumer>
        ),
        dataIndex: "vehicle_driving_rate",
        key: "vehicle_driving_rate",
        render: (text, record, index) => {
          return {
            props: {
              style: columnStyle,
            },
            children: this.renderEditableSubCell(
              record,
              "vehicle_driving_rate"
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.manageFuelRate.consumptionWhileParking}
          </LangContext.Consumer>
        ),
        dataIndex: "vehicle_parking_rate",
        key: "vehicle_parking_rate",
        render: (text, record, index) => {
          return {
            props: {
              style: columnStyle,
            },
            children: this.renderEditableSubCell(
              record,
              "vehicle_parking_rate"
            ),
          };
        },
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: 120,
        render: (_, record) => {
          const { editingSubIndex } = this.state;
          const isEditing = editingSubIndex === record.id;
          return isEditing ? (
            <span>
              <Popconfirm
                title="Sure to Save?"
                onConfirm={() => {
                  this.handleSaveSub();
                }}
              >
                <a style={{ margin: "4px" }}>Save</a>
              </Popconfirm>

              <Popconfirm
                title="Sure to cancel?"
                onConfirm={() => this.setState({ editingSubIndex: -1 })}
              >
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <a
              disabled={editingSubIndex !== -1}
              onClick={() => this.handleEditClickSub(record)}
            >
              <Tooltip
                placement="left"
                title={
                  <LangContext.Consumer>
                    {(i18n) => i18n.e.edit}
                  </LangContext.Consumer>
                }
                arrowPointAtCenter
                style={{
                  padding: "0px 1px 0px 0px",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                <Icon type="edit" />
              </Tooltip>
            </a>
          );
        },
      },
    ];

    return (
      <AuthorizeComponent matching_name="managefuelrate">
        <Spin spinning={this.props.managefuelrate.loading}>
          <GeneralStyledContent>
            <Row gutter={24}>
              <Col span={24}>
                <Breadcrumb
                  customeurl={["/manage-fuelrate", null, null]}
                  match={this.props.match}
                  style={{ margin: "0px 0px 14px 10px" }}
                />
              </Col>
            </Row>
            <Row gutter={24} style={{ marginBottom: "15px" }}>
              <Col span={1} offset={22}>
                <Badge dot={this.props.isFilter}>
                  <Button
                    icon="filter"
                    size="small"
                    onClick={(e) => {
                      this.setState({
                        filterVisible: true,
                      });
                    }}
                  >
                    <LangContext.Consumer>
                      {(i18n) => i18n.f.filter}
                    </LangContext.Consumer>
                  </Button>
                </Badge>
                <Filter
                  filterVisible={this.state.filterVisible}
                  filterBrand={this.state.filterBrand}
                  vehicleBrandLists={
                    this.props.managefuelrate.vehicleBrandLists
                  }
                  filterCode={this.state.filterCode}
                  vehicleLists={this.props.managefuelrate.vehicleLists}
                  onClose={() => this.setState({ filterVisible: false })}
                  handleStateChange={this.handleStateChange}
                  onFilterButtonClick={() => {
                    this.props.loadVehicleBrand({
                      vehicle_visibility: this.state.vehicle_visibility,
                      filterBrand: this.state.filterBrand,
                      filterCode: this.state.filterCode,
                      companyID: this.state.companyID,
                      page: this.state.page,
                      pageSize: this.state.pageSize,
                    });
                    this.setState({ page: 1 });
                  }}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Table
                  id="main-table"
                  bordered
                  columns={columns.map((column) => {
                    if (!column.editable) {
                      return column;
                    }
                    return {
                      ...column,
                      onCell: (record) => ({
                        record,
                        dataIndex: column.dataIndex,
                        inputType: "text",
                        editing:
                          this.state.editingIndex === record.vehicle_brand_id,
                      }),
                    };
                  })}
                  dataSource={
                    this.props.managefuelrate.vehicleLists.data
                      ? this.props.managefuelrate.vehicleLists.data.map(
                          (record, index) => ({
                            ...record,
                            key: index,
                          })
                        )
                      : []
                  }
                  pagination={false}
                  rowKey="id"
                  expandedRowRender={(record) => {
                    return (
                      <Table
                        id="sub-table"
                        className="sub-table"
                        showSorterTooltip={false}
                        bordered
                        columns={sub_columns}
                        size="small"
                        dataSource={record.vehicles}
                        pagination={false}
                        style={{
                          margin: "5px 0px 10px 0px",
                        }}
                      />
                    );
                  }}
                />
              </Col>
            </Row>

            <Row type="flex" justify="end" style={{ padding: "15px" }}>
              <Col span={24} align="right">
                <Pagination
                  defaultPageSize={10}
                  size="small"
                  current={this.state.page}
                  total={this.props.managefuelrate.vehicleLists.totalCount}
                  onChange={this.handlePageChange}
                />
              </Col>
            </Row>
          </GeneralStyledContent>
        </Spin>
      </AuthorizeComponent>
    );
  }
}

const mapStateToProps = ({ auth, auth: { profile }, managefuelrate }) => ({
  auth,
  profile,
  managefuelrate,
});

const mapDispatchToProps = {
  loadVehicleBrand: actions.loadVehicleBrand.request,
  loadVehicleBrands: actions.loadVehicleBrands.request,
  updateVehicleBrand: actions.updateVehicleBrand.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
