import { createTypes, createAction } from "lib/action";

const LOAD_OUTOFCONTACT = createTypes("loadoutofcontact", "load");
const UPDATE_OUTOFCONTACT = createTypes("updateoutofcontact", "update");

const loadOutofcontact = {
  request: (data) => createAction(LOAD_OUTOFCONTACT.REQUEST, { data }),
  success: (data) => createAction(LOAD_OUTOFCONTACT.SUCCESS, { data }),
  failure: (err) => createAction(LOAD_OUTOFCONTACT.FAILURE, { err }),
};

const updateOutofcontact = {
  request: (data, callback) =>
    createAction(UPDATE_OUTOFCONTACT.REQUEST, { data, callback }),
  success: (data) => createAction(UPDATE_OUTOFCONTACT.SUCCESS, { data }),
  failure: (err) => createAction(UPDATE_OUTOFCONTACT.FAILURE, { err }),
};

export {
  loadOutofcontact,
  LOAD_OUTOFCONTACT,
  updateOutofcontact,
  UPDATE_OUTOFCONTACT,
};
