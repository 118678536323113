import { createTypes, createAction } from "lib/action";


const LOAD_DOWNLOADCERTIFICATE = createTypes("downloadcertificate", "load");

const loadDownloadcertificate = {
  request: (data, loading) =>
    createAction(LOAD_DOWNLOADCERTIFICATE.REQUEST, { data, loading }),
  success: data => createAction(LOAD_DOWNLOADCERTIFICATE.SUCCESS, { data }),
  failure: () => createAction(LOAD_DOWNLOADCERTIFICATE.FAILURE)
};



export {
  loadDownloadcertificate,
  LOAD_DOWNLOADCERTIFICATE,

};
