import { all, put, call, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";
import { trackingAdapter } from "./adapter";
import { findLatLngDiff } from "./../../lib/helper";
import { getlocalstorage } from "../../constants/local_storage";

function* loadListVehicleMdvr(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");

  const {
    data: { company_id, vehicle_visibility, list_vehicle_id },
    loading,
    callback,
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlistvehiclemdvr`,
      {
        vehicle_visibility,
        list_vehicle_id,
        company_id,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    callback(res.data);
    yield put(actions.loadListVehicleMdvr.success(res.data));
  } catch (err) {
    yield put(actions.loadListVehicleMdvr.failure());
  }
}

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclemdvr`,
      {
        vehicle_visibility,
        company_id: COMPANY_ID,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadVehicle.success(res.data));
  } catch (err) {
    yield put(actions.loadVehicle.failure());
  }
}

function* loadDashcamera(action) {
  const {
    data: {
      token,
      page,
      pageSize,
      searchName,
      meterStatus,
      engineStatus,
      emergencyStatus,
      speedRange,
      companyID,
      vehicleVisibility,
      cb,
    },
  } = action.payload;
  // const company_id =
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclefordashcam`,
      {
        searchName,
        page,
        pageSize,
        meterStatus,
        engineStatus,
        emergencyStatus,
        speedRange,
        companyID,
        vehicleVisibility,
        cb,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    cb();
    yield put(actions.loadDashcamera.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadDashcamera.failure());
  }
}

function* loadEmergencyCase(action) {
  const {
    data: { companyID, token, vehicleVisibility },
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getemergencycase`,
      {
        companyID,
        vehicleVisibility,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    yield put(actions.loadEmergencyCase.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadEmergencyCase.failure());
  }
}

function* loadEarningWidget(action) {
  const {
    data: { companyID, token, start_at, end_at },
  } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getearningwidgetinfo?company_id=${companyID}&start_at=${start_at}&end_at=${end_at}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    yield put(actions.loadEarningWidget.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadEarningWidget.failure());
  }
}

function* loadAccEarning(action) {
  const {
    data: { companyID, token, start_at, end_at },
  } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getaccumulateearninginfo?company_id=${companyID}&start_at=${start_at}&end_at=${end_at}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    yield put(actions.loadAccEarning.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadAccEarning.failure());
  }
}

function* loadBookingWidget(action) {
  const {
    data: { companyID, token, start_at, end_at },
  } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getbookingwidgetinfo?company_id=${companyID}&start_at=${start_at}&end_at=${end_at}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    yield put(actions.loadBookingWidget.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadBookingWidget.failure());
  }
}

function* loadAccBooking(action) {
  const {
    data: { companyID, token, start_at, end_at },
  } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getaccumulatebookinginfo?company_id=${companyID}&start_at=${start_at}&end_at=${end_at}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    yield put(actions.loadAccBooking.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadAccBooking.failure());
  }
}

function* loadTopBookingData(action) {
  const {
    data: { companyID, token, start_at, end_at },
  } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/gettopinterestingbooking?company_id=${companyID}&start_at=${start_at}&end_at=${end_at}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    yield put(actions.loadTopBookingData.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadTopBookingData.failure());
  }
}

function* loadTrackingInitData(action) {
  const {
    searchName,
    selectedTrackingColumns,
    vehicleStatus,
    engineStatus,
    deviceType,
    speedRange,
    timeRange,
    dataTreeFilter,
    companyID,
    vehicle_visibility,
    token,
    cb,
    previousState,
    cbFailure,
  } = action.payload.data;

  try {
    let vehicle_type = [];
    let vehicle_group = [];
    if (typeof dataTreeFilter != "undefined" && dataTreeFilter != null) {
      dataTreeFilter.map((ele) => {
        if (ele.includes("vehicle_group_all")) vehicle_group = [];
        if (ele.includes("vehicle_type_all")) vehicle_type = [];
        if (ele.includes("vehicle_group_id_")) {
          let strArr = ele.split("vehicle_group_id_");
          vehicle_group.push(strArr[1]);
        }
        if (ele.includes("vehicle_type_id_")) {
          let strArr = ele.split("vehicle_type_id_");
          vehicle_type.push(strArr[1]);
        }
      });
    }

    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_GO_ENDPOINT}/api_tracking/api/getvehiclefortrackingmap`,
      {
        company_id: companyID,
        vehicle_visibility: vehicle_visibility.split`,`.map((x) => +x),
        search_name: searchName,
        selected_tracking_columns: selectedTrackingColumns,
        vehicle_status: vehicleStatus,
        device_type: deviceType,
        speed_range: speedRange,
        time_range: timeRange,
        vehicle_type: vehicle_type,
        vehicle_group: vehicle_group,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    res.data.markers = trackingAdapter(res.data.markers);

    cb(res.data.markers);
    yield put(
      actions.loadTrackingInitData.success({
        markers: res.data.markers.map((ele) => {
          let diff = 1;

          if (ele.current_location != null)
            diff = ele.current_location.length > 0 ? 0 : 1;
          return { ...ele, accLatLngDiff: diff };
        }),
      })
    );
  } catch (err) {
    console.log(err.message);
    // console.log(err.stack);
    cbFailure(searchName);
    yield put(actions.loadTrackingInitData.failure());
  }
}

// function* loadTrackingInitData(action) {
//   const {
//     searchName,
//     selectedTrackingColumns,
//     vehicleStatus,
//     engineStatus,
//     speedRange,
//     timeRange,
//     dataTreeFilter,
//     companyID,
//     vehicle_visibility,
//     token,
//     cb,
//     previousState,
//   } = action.payload.data;

//   try {
//     const res = yield call(
//       axios.post,
//       `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclefortrackingmap`,
//       {
//         company_id: companyID,
//         vehicle_visibility,
//         searchName,
//         selectedTrackingColumns,
//         vehicleStatus,
//         engineStatus,
//         speedRange,
//         timeRange,
//         dataTreeFilter,
//       },
//       { headers: { Authorization: `Bearer ${token}` } }
//     );

//     cb(res.data.markers);
//     yield put(
//       actions.loadTrackingInitData.success({
//         markers: res.data.markers.map((ele) => {
//           let diff = 1;

//           if (ele.current_location != null)
//             diff = ele.current_location.length > 0 ? 0 : 1;
//           return { ...ele, accLatLngDiff: diff };
//         }),
//       })
//     );
//   } catch (err) {
//     console.log(err.message);
//     // console.log(err.stack);
//     yield put(actions.loadTrackingInitData.failure());
//   }
// }

function* loadTracking(action) {
  const diffthreshold = 0.09;
  const {
    searchName,
    selectedTrackingColumns,
    vehicleStatus,
    engineStatus,
    speedRange,
    deviceType,
    timeRange,
    dataTreeFilter,
    companyID,
    vehicle_visibility,
    token,
    cb,
    previousState,
  } = action.payload.data;

  try {
    let vehicle_type = [];
    let vehicle_group = [];
    if (typeof dataTreeFilter != "undefined" && dataTreeFilter != null) {
      dataTreeFilter.map((ele) => {
        if (ele.includes("vehicle_group_all")) vehicle_group = [];
        if (ele.includes("vehicle_type_all")) vehicle_type = [];
        if (ele.includes("vehicle_group_id_")) {
          let strArr = ele.split("vehicle_group_id_");
          vehicle_group.push(strArr[1]);
        }
        if (ele.includes("vehicle_type_id_")) {
          let strArr = ele.split("vehicle_type_id_");
          vehicle_type.push(strArr[1]);
        }
      });
    }
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_GO_ENDPOINT}/api_tracking/api/getvehiclefortrackingmap`,
      {
        company_id: companyID,
        vehicle_visibility: vehicle_visibility.split`,`.map((x) => +x),
        search_name: searchName,
        selected_tracking_columns: selectedTrackingColumns,
        vehicle_status: vehicleStatus,
        device_type: deviceType,
        speed_range: speedRange,
        time_range: timeRange,
        vehicle_type: vehicle_type,
        vehicle_group: vehicle_group,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    ///////////////////////////////////////////////////////////////////

    let responseData = trackingAdapter(res.data.markers);

    let haveCurrentLocationColumn = selectedTrackingColumns.find(
      (ele) => ele == "current_location"
    );

    if (typeof haveCurrentLocationColumn != "undefined") {
      let location = yield call(
        axios.post,
        // `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlocationfromthirdparty?lat=${val.latest_lat}&lng=${val.latest_lng}`,
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlocationfromthirdparty`,
        {
          companyID: companyID,
          vehicleList: responseData
            .filter((ele) => {
              let previousVal = previousState.find(
                (ele2) => ele2.device_id == ele.device_id
              );

              if (typeof previousVal != "undefined") {
                if (previousVal.accLatLngDiff < diffthreshold) return false;
              } else {
                return ele.current_location.length == 0;
              }
              return ele.current_location.length == 0;
            })
            .map((ele) => {
              return {
                device_id: ele.device_id,
                lat: ele.latest_lat,
                lng: ele.latest_lng,
              };
            }),
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      for (let index = 0; index < responseData.length; index++) {
        let val = responseData[index];
        let previousVal = previousState.find(
          (ele2) => ele2.device_id == val.device_id
        );

        if (val.current_location.length > 0) {
          responseData[index] = {
            ...val,
            current_location: val.current_location,
            accLatLngDiff: 0,
          };
        } else {
          if (typeof previousVal != "undefined") {
            let diffVal = findLatLngDiff(
              previousVal.latest_lat,
              previousVal.latest_lng,
              val.latest_lat,
              val.latest_lng
            );
            let findVal = location.data.find(
              (ele) => ele.device_id == val.device_id
            );
            if (previousVal.accLatLngDiff < diffthreshold) {
              if (typeof findVal != "undefined") {
                responseData[index] = {
                  ...val,
                  current_location: findVal.current_location,
                  accLatLngDiff: previousVal.accLatLngDiff + diffVal,
                };
              } else {
                if (val.current_location.length == 0) {
                  if (previousVal.current_location.length > 0) {
                    if (
                      typeof previousVal.current_location[0].base_color_code ==
                      "undefined"
                    ) {
                      responseData[index] = {
                        ...val,
                        current_location: previousVal.current_location,
                        accLatLngDiff: previousVal.accLatLngDiff + diffVal,
                      };
                    } else {
                      responseData[index] = {
                        ...val,
                        current_location: val.current_location,
                        accLatLngDiff: 1,
                      };
                    }
                  } else {
                    responseData[index] = {
                      ...val,
                      current_location: previousVal.current_location,
                      accLatLngDiff: previousVal.accLatLngDiff + diffVal,
                    };
                  }
                } else {
                  responseData[index] = {
                    ...val,
                    current_location: previousVal.current_location,
                    accLatLngDiff: previousVal.accLatLngDiff + diffVal,
                  };
                }
              }
            } else {
              if (typeof findVal != "undefined") {
                responseData[index] = {
                  ...val,
                  current_location: findVal.current_location,
                  accLatLngDiff: 0,
                };
              } else {
                // responseData[index] = {
                //   ...val,
                //   current_location: previousVal.current_location,
                //   accLatLngDiff: previousVal.accLatLngDiff + diffVal,
                // };

                if (val.current_location.length == 0) {
                  if (previousVal.current_location.length > 0) {
                    if (
                      typeof previousVal.current_location[0].base_color_code ==
                      "undefined"
                    ) {
                      responseData[index] = {
                        ...val,
                        current_location: previousVal.current_location,
                        accLatLngDiff: previousVal.accLatLngDiff + diffVal,
                      };
                    } else {
                      responseData[index] = {
                        ...val,
                        current_location: val.current_location,
                        accLatLngDiff: 1,
                      };
                    }
                  } else {
                    responseData[index] = {
                      ...val,
                      current_location: val.current_location,
                      accLatLngDiff: previousVal.accLatLngDiff + diffVal,
                    };
                  }
                } else {
                  responseData[index] = {
                    ...val,
                    current_location: previousVal.current_location,
                    accLatLngDiff: previousVal.accLatLngDiff + diffVal,
                  };
                }
              }
            }
          } else {
            if (val.current_location.length == 0) {
              let findVal = location.data.find(
                (ele) => ele.device_id == val.device_id
              );
              if (typeof findVal != "undefined")
                responseData[index] = {
                  ...val,
                  current_location: findVal.current_location,
                  accLatLngDiff: 0,
                };
              else
                responseData[index] = {
                  ...val,
                  current_location: [],
                  accLatLngDiff: 1,
                };
            }
          }
        }
      }
    }

    ///////////////////////////////////////////////////////////////////
    cb(responseData);
    yield put(actions.loadTracking.success({ markers: responseData }));
  } catch (err) {
    console.log(err.message);
    console.log(err.stack);
    yield put(actions.loadTracking.failure());
  }
}

// function* loadTracking(action) {
//   const diffthreshold = 0.09;
//   const {
//     searchName,
//     selectedTrackingColumns,
//     vehicleStatus,
//     engineStatus,
//     speedRange,
//     timeRange,
//     dataTreeFilter,
//     companyID,
//     vehicle_visibility,
//     token,
//     cb,
//     previousState,
//   } = action.payload.data;

//   try {
//     const res = yield call(
//       axios.post,
//       `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclefortrackingmap`,
//       {
//         company_id: companyID,
//         vehicle_visibility,
//         searchName,
//         selectedTrackingColumns,
//         vehicleStatus,
//         engineStatus,
//         speedRange,
//         timeRange,
//         dataTreeFilter,
//       },
//       { headers: { Authorization: `Bearer ${token}` } }
//     );

//     ///////////////////////////////////////////////////////////////////

//     let responseData = res.data.markers;

//     let haveCurrentLocationColumn = selectedTrackingColumns.find(
//       (ele) => ele == "current_location"
//     );

//     if (typeof haveCurrentLocationColumn != "undefined") {
//       let location = yield call(
//         axios.post,
//         // `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlocationfromthirdparty?lat=${val.latest_lat}&lng=${val.latest_lng}`,
//         `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlocationfromthirdparty`,
//         {
//           companyID: companyID,
//           vehicleList: responseData
//             .filter((ele) => {
//               let previousVal = previousState.find(
//                 (ele2) => ele2.device_id == ele.device_id
//               );

//               if (typeof previousVal != "undefined") {
//                 if (previousVal.accLatLngDiff < diffthreshold) return false;
//               } else {
//                 return ele.current_location.length == 0;
//               }
//               return ele.current_location.length == 0;
//             })
//             .map((ele) => {
//               return {
//                 device_id: ele.device_id,
//                 lat: ele.latest_lat,
//                 lng: ele.latest_lng,
//               };
//             }),
//         },
//         { headers: { Authorization: `Bearer ${token}` } }
//       );

//       for (let index = 0; index < responseData.length; index++) {
//         let val = responseData[index];
//         let previousVal = previousState.find(
//           (ele2) => ele2.device_id == val.device_id
//         );
//         if (val.current_location.length > 0) {
//           responseData[index] = {
//             ...val,
//             current_location: val.current_location,
//             accLatLngDiff: 0,
//           };
//         } else {
//           if (typeof previousVal != "undefined") {
//             let diffVal = findLatLngDiff(
//               previousVal.latest_lat,
//               previousVal.latest_lng,
//               val.latest_lat,
//               val.latest_lng
//             );
//             let findVal = location.data.find(
//               (ele) => ele.device_id == val.device_id
//             );
//             if (previousVal.accLatLngDiff < diffthreshold) {
//               if (typeof findVal != "undefined")
//                 responseData[index] = {
//                   ...val,
//                   current_location: findVal.current_location,
//                   accLatLngDiff: previousVal.accLatLngDiff + diffVal,
//                 };
//               else
//                 responseData[index] = {
//                   ...val,
//                   current_location: previousVal.current_location,
//                   accLatLngDiff: previousVal.accLatLngDiff + diffVal,
//                 };
//             } else {
//               if (typeof findVal != "undefined")
//                 responseData[index] = {
//                   ...val,
//                   current_location: findVal.current_location,
//                   accLatLngDiff: 0,
//                 };
//               else
//                 responseData[index] = {
//                   ...val,
//                   current_location: previousVal.current_location,
//                   accLatLngDiff: previousVal.accLatLngDiff + diffVal,
//                 };
//             }
//           } else {
//             if (val.current_location.length == 0) {
//               let findVal = location.data.find(
//                 (ele) => ele.device_id == val.device_id
//               );
//               if (typeof findVal.current_location != "undefined")
//                 responseData[index] = {
//                   ...val,
//                   current_location: findVal.current_location,
//                   accLatLngDiff: 0,
//                 };
//               else
//                 responseData[index] = {
//                   ...val,
//                   current_location: [],
//                   accLatLngDiff: 1,
//                 };
//             }
//           }
//         }
//       }
//     }

//     ///////////////////////////////////////////////////////////////////
//     cb(responseData);
//     yield put(actions.loadTracking.success({ markers: responseData }));
//   } catch (err) {
//     console.log(err.message);
//     console.log(err.stack);
//     yield put(actions.loadTracking.failure());
//   }
// }

function* loadVehicleTypeMasterData(action) {
  const { companyID, vehicle_visibility, token } = action.payload.data;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehicletype?vehicle_visibility=[${vehicle_visibility}]`,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    yield put(actions.loadVehicleTypeMasterData.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadVehicleTypeMasterData.failure());
  }
}

function* loadVehicleGroupMasterData(action) {
  const { companyID, vehicle_visibility, token } = action.payload.data;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclegroup?company_id=[${companyID}]&vehicle_visibility=[${vehicle_visibility}]`,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    yield put(actions.loadVehicleGroupMasterData.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadVehicleGroupMasterData.failure());
  }
}

function* loadVehicleMasterData(action) {
  const { companyID, vehicle_visibility, token } = action.payload.data;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehicle?company_id=[${companyID}]&vehicle_visibility=[${vehicle_visibility}]`,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    yield put(actions.loadVehicleMasterData.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadVehicleMasterData.failure());
  }
}

function* loadAlertAlarm(action) {
  const {
    searchName,
    warningType,
    dataTreeFilter,
    companyID,
    vehicle_visibility,
    token,
    isSearch,
    cb,
  } = action.payload.data;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclealertalarm`,
      {
        company_id: companyID,
        vehicle_visibility,
        searchName,
        warningType,
        dataTreeFilter,
        cb,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    cb();
    yield put(actions.loadAlertAlarm.success(res.data, isSearch));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadAlertAlarm.failure());
  }
}

function* loadHeatMap(action) {
  const {
    searchName,
    warningType,
    eventType,
    startDateTime,
    endDateTime,
    dataTreeFilter,
    companyID,
    vehicle_visibility,
    token,
  } = action.payload.data;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehicleheatmap`,
      {
        company_id: companyID,
        vehicle_visibility,
        searchName,
        warningType,
        eventType,
        startDateTime,
        endDateTime,
        dataTreeFilter,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json",
        },
      }
    );

    yield put(actions.loadHeatMap.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadHeatMap.failure());
  }
}

function* loadListVehicleHoneytoast(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  // const COMPANY_ID = getlocalstorage(
  //   localStorage.getItem("profile"),
  //   "company_id"
  // );

  // const vehicle_visibility = getlocalstorage(
  //   localStorage.getItem("profile"),
  //   "vehicle_visibility"
  // );

  const {
    data: {
      list_vehicle_id,
      vehicle_status,
      orderBy,
      orderType,
      company_id,
      vehicle_visibility,
    },
  } = action.payload;
  // console.log('payload',action.payload);
  // const {
  //   data: { company_id, vehicle_visibility, list_vehicle_id },
  //   loading,
  //   callback,
  // } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlistvehiclehoneytoast`,
      {
        vehicle_visibility,
        list_vehicle_id,
        vehicle_status,
        orderBy,
        orderType,
        company_id,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadListVehicleHoneytoast.success(res.data));
  } catch (err) {
    yield put(actions.loadListVehicleHoneytoast.failure());
  }
}

export default function* watcMonitoringState() {
  yield all([
    takeEvery(
      actions.LOAD_LISTVEHICLE_HONEYTOASTSTREAM.REQUEST,
      loadListVehicleHoneytoast
    ),
    takeEvery(actions.LOAD_LISTVEHICLEMDVR.REQUEST, loadListVehicleMdvr),
    takeEvery(actions.LOAD_VEHICLE_VIDEO.REQUEST, loadVehicle),
    takeLatest(actions.LOAD_DASHCAMERA.REQUEST, loadDashcamera),
    takeLatest(actions.LOAD_EMERGENCYCASE.REQUEST, loadEmergencyCase),
    takeEvery(actions.LOAD_EARNINGWIDGET.REQUEST, loadEarningWidget),
    takeEvery(actions.LOAD_ACCEARNING.REQUEST, loadAccEarning),
    takeEvery(actions.LOAD_BOOKINGWIDGET.REQUEST, loadBookingWidget),
    takeEvery(actions.LOAD_ACCBOOKING.REQUEST, loadAccBooking),
    takeEvery(actions.LOAD_TOPBOOKINGDATA.REQUEST, loadTopBookingData),
    takeEvery(actions.LOAD_TRACKING_INIT_DATA.REQUEST, loadTrackingInitData),
    takeEvery(actions.LOAD_TRACKING.REQUEST, loadTracking),
    takeEvery(actions.LOAD_ALERT_ALARM.REQUEST, loadAlertAlarm),
    takeLatest(
      actions.LOAD_VEHICLE_TYPE_MASTER_DATA_FILTER.REQUEST,
      loadVehicleTypeMasterData
    ),
    takeLatest(
      actions.LOAD_VEHICLE_GROUP_MASTER_DATA_FILTER.REQUEST,
      loadVehicleGroupMasterData
    ),
    takeLatest(
      actions.LOAD_VEHICLE_MASTER_DATA_FILTER.REQUEST,
      loadVehicleMasterData
    ),
    takeEvery(actions.LOAD_HEAT_MAP.REQUEST, loadHeatMap),
  ]);
}

export {
  loadDashcamera,
  loadEmergencyCase,
  loadEarningWidget,
  loadAccEarning,
  loadBookingWidget,
  loadAccBooking,
  loadTopBookingData,
  loadTracking,
  loadTrackingInitData,
  loadVehicleTypeMasterData,
  loadVehicleGroupMasterData,
  loadAlertAlarm,
  loadVehicleMasterData,
  loadHeatMap,
  loadListVehicleHoneytoast,
  loadListVehicleMdvr,
};
