import {
  LOAD_VEHICLEINFORMATIONDLT,
  LOAD_VEHICLE_VEHICLEINFORMATIONDLT
} from "./actions";

const initialState = {
  vehicleinformationdltUrl: "",
  vehicleLists: [],
  vehicleinformationdltLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_VEHICLEINFORMATIONDLT.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        vehicleinformationdltLoading: loading
      };
    case LOAD_VEHICLEINFORMATIONDLT.SUCCESS:

      return {
        ...state,
        vehicleinformationdltUrl: action.payload.data.data,
        vehicleinformationdltLoading: false
      };
    case LOAD_VEHICLE_VEHICLEINFORMATIONDLT.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data
      };
    default:
      return state;
  }
};
