import moment from "moment";
import { calcCrowDistance } from "../../lib/helper";
let trackingAdapter = (data) => {
  data = data.filter((ele) => ele.plate_no != "");
  return data.map((ele) => {
    let directionDegree = Math.abs(ele.direction_degree - 359);

    if (directionDegree + 90 > 359)
      directionDegree = directionDegree + 90 - 359;
    else directionDegree = directionDegree + 90;

    if (typeof ele.battery != "undefined" && ele.battery == null) {
      ele.battery = "-";
    }

    if (ele.battery != "-" && ele.battery != "") {
      if (ele.battery >= 4.13) ele.battery = 100;
      else if (ele.battery >= 4.08) ele.battery = 90;
      else if (ele.battery >= 4) ele.battery = 80;
      else if (ele.battery >= 3.91) ele.battery = 70;
      else if (ele.battery >= 3.87) ele.battery = 60;
      else if (ele.battery >= 3.81) ele.battery = 50;
      else if (ele.battery >= 3.78) ele.battery = 40;
      else if (ele.battery >= 3.75) ele.battery = 30;
      else if (ele.battery >= 3.73) ele.battery = 20;
      else if (ele.battery >= 3.7) ele.battery = 10;
      else if (ele.battery >= 3.6) ele.battery = 5;
      else if (ele.battery >= 3.55) ele.battery = 0;
    }

    if (ele.latest_pos_update_at != "-" && ele.latest_pos_update_at != "")
      ele.latest_pos_update_at =
        moment
          .utc()
          .diff(
            moment.utc(ele.latest_pos_update_at, "YYYY-MM-DD HH:mm:ss"),
            "minutes"
          ) <= 15
          ? "now"
          : moment(ele.latest_pos_update_at)
            // .add(7, "hours")
            .format("YYYY-MM-DD HH:mm:ss");

    if (ele.gps_active_at != "-" && ele.gps_active_at != "")
      ele.gps_active_at =
        moment
          .utc()
          .diff(
            moment.utc(ele.gps_active_at, "YYYY-MM-DD HH:mm:ss"),
            "minutes"
          ) <= 15
          ? "now"
          : moment(ele.gps_active_at)
            // .add(7, "hours")
            .format("YYYY-MM-DD HH:mm:ss");
    if (ele.gps_image_active_at != "-" && ele.gps_image_active_at != "")
      ele.gps_image_active_at =
        moment
          .utc()
          .diff(
            moment.utc(ele.gps_image_active_at, "YYYY-MM-DD HH:mm:ss"),
            "minutes"
          ) <= 10
          ? "now"
          : moment(ele.gps_image_active_at)
            // .add(7, "hours")
            .format("YYYY-MM-DD HH:mm:ss");

    return {
      ...ele,
      lastest_driver: ele.driver1,
      type: "device",
      vehicle_tel: "",
      engine: ele.engine ? "0" : "1",
      engine_status: ele.engine ? "on" : "off",
      lat: ele.lat,
      lng: ele.lng,
      latest_lat: ele.lat,
      latest_lng: ele.lng,
      directionDegree: Math.round(directionDegree),
      has_vtg: "-",
      vtg_x: "-",
      vtg_y: "-",
      velocity: ele.speed == "-" ? 0 : Math.round(ele.speed),
      latest_pos_update_at: ele.latest_pos_update_at,
      gps_active_at: ele.gps_active_at,
      gps_image_active_at: ele.gps_image_active_at,
    };
  });
};
let replayAdapter = (data, data_type) => {

  let totalDistance = 0;
  data = data
    .filter((ele) => ele.lat != 0 && ele.lng != 0)
    .filter((ele) => ele.speed < 120);


  let items = []
  for (let i = 0; i <= data.length - 1; i++) {

    let ele = data[i]
    if (ele.created_at != "-" && ele.created_at != "") {
      ele.created_at = moment(ele.created_at)
        .add(7, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
    }

    if (typeof ele.event_type !== "undefined") {
      items.push({
        ...ele
      })
      continue
    }

    let directionDegree = Math.abs(ele.directionDegree - 359);

    if (directionDegree + 90 > 359) {
      directionDegree = directionDegree + 90 - 359;
    } else {
      directionDegree = directionDegree + 90;
    }

    items.push({
      ...ele,
      created_at: ele.created_at,
      directionDegree: Math.ceil(directionDegree),
    });
  }

  return items;
};

let localTimeAdapter = (data) => {
  return data.map((ele) => {
    return {
      ...ele,
      created_at: moment(ele.created_at)
        .add(7, "hours")
        .format("YYYY-MM-DD HH:mm:ss"),
    };
  });
};

let parkingAdapter = (data) => {
  return data.map((ele) => {
    if (ele.startAt != "") {
      ele.startAt =
        moment
          .utc()
          .diff(moment.utc(ele.startAt, "YYYY-MM-DD HH:mm:ss"), "minutes") <= 10
          ? "now"
          : moment(ele.startAt)
            .add(7, "hours")
            .format("YYYY-MM-DD HH:mm:ss");
    }

    return {
      ...ele,
      startAt: ele.startAt,
    };
  });
};

let concreteDashboardPendingJobAdapter = (data) => {
  let soList = [];
  let pendingList = [];
  let pendingSoList = [];
  let pendingTickets = [];
  Object.entries(data).map((ele, index) => {
    let salesOrder = ele[1].sales_orders;
    soList = Object.entries(salesOrder).map((ele, index) => ele);

    pendingTickets = soList.map((ele6, index) => {
      return Object.entries(ele6[1].tickets)
        .map((ele2, index) => {
          return {
            ...ele2[1],
            card_name: ele[1].card_name,
            ticket_code: ele[1].code,
          };
        })
        .filter((ele3, index) => ele3.state == "finnish");
    });

    pendingList = pendingTickets.filter((ele4, index) => ele4.length > 0);

    pendingList.map((ele5, index) => pendingSoList.push(...ele5));
  });

  // console.log(pendingSoList);
  return pendingSoList;
};
export {
  trackingAdapter,
  replayAdapter,
  concreteDashboardPendingJobAdapter,
  parkingAdapter,
  localTimeAdapter,
};
