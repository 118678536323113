import { createTypes, createAction } from "lib/action";

const LOAD_VEHICLE_BRAND = createTypes("load_vehicle_brand", "load");
const UPDATE_VEHICLE_BRAND = createTypes("update_vehicle_brand", "update");
const LOAD_VEHICLE_BRANDS = createTypes("load_vehicle_brands", "load");

const loadVehicleBrands = {
  request: (text) => createAction(LOAD_VEHICLE_BRANDS.REQUEST, { text }),
  success: (data) => createAction(LOAD_VEHICLE_BRANDS.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_BRANDS.FAILURE),
};

const loadVehicleBrand = {
  request: (data) => createAction(LOAD_VEHICLE_BRAND.REQUEST, { data }),
  success: (data, page, pageSize, total) =>
    createAction(LOAD_VEHICLE_BRAND.SUCCESS, { data, page, pageSize, total }),
  failure: (err) => createAction(LOAD_VEHICLE_BRAND.FAILURE, { err }),
};

const updateVehicleBrand = {
  request: (data, generateMsg) =>
    createAction(UPDATE_VEHICLE_BRAND.REQUEST, { data, generateMsg }),
  success: (data) => createAction(UPDATE_VEHICLE_BRAND.SUCCESS, { data }),
  failure: (err) => createAction(UPDATE_VEHICLE_BRAND.FAILURE, { err }),
};

export {
  loadVehicleBrand,
  LOAD_VEHICLE_BRAND,
  loadVehicleBrands,
  LOAD_VEHICLE_BRANDS,
  updateVehicleBrand,
  UPDATE_VEHICLE_BRAND,
};
