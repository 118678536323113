import { Table, Input, Button, Popconfirm, InputNumber, Form, Select } from 'antd';
import React, { Component, Fragment } from "react";
import LangContext, { i18n } from "modules/shared/context/langContext";

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false,
  };


  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      // this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  getInput = () => {
    const { Option } = Select;
    if (this.props.inputType === 'number') {
      if (this.props.dataIndex === "quality") {
        return <InputNumber type="number" step={1} min={1} />;
      }else{
        return <InputNumber type="number" step={0.01} min={1} />;
      }
    } else if (this.props.inputType === 'select') {
      if (this.props.dataIndex === "uom_name") {
        return <Select style={{ width: "100%" }}>
          <Option value="">
            <LangContext.Consumer>
              {(i18n) => i18n.p.pleaseSelect}
            </LangContext.Consumer>,
        </Option>
          {this.props.uom.map((v) => (
            <Option key={v.id} value={v.id}>
              {v.name}
            </Option>
          ))}
        </Select>;
      } else if (this.props.dataIndex === "from_uom_name") {
        return <Select style={{ width: "100%" }}>
          <Option value="">
            <LangContext.Consumer>
              {(i18n) => i18n.p.pleaseSelect}
            </LangContext.Consumer>,
        </Option>
          {this.props.skuuom.map((v) => (
            <Option key={v.id} value={v.id}>
              {v.name}
            </Option>
          ))}
        </Select>;
      } else {
        return <Select style={{ width: "100%" }}>
          <Option value="">
            <LangContext.Consumer>
              {(i18n) => i18n.p.pleaseSelect}
            </LangContext.Consumer>,
        </Option>
          {this.props.weightuom.map((v) => (
            <Option key={v.id} value={v.id}>
              {v.name}
            </Option>
          ))}
        </Select>;
      }

    }
    return <Input />;
  };

  getInitialValue = (record, dataIndex) => {
    let value = record[dataIndex];

    if (dataIndex === "uom_name") {
      value = record.uom_id === null ? "" : record.uom_id;
    } else if (dataIndex === "from_uom_name") {
      value = record.from_uom_id === null ? "" : record.from_uom_id;
    } else if (dataIndex === "weight_uom") {

      value = record.weight_uom_id === null ? "" : record.weight_uom_id;
    }

    return value;
  }


  renderCellEdit = ({ getFieldDecorator }) => {
    const {
      editable,
      editing,
      dataIndex,
      require,
      title,
      inputType,
      record,
      required,
      index,
      children,
      dict,
      dictValue,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing && editable ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: require,
                  message: <LangContext.Consumer>
                    {(i18n) => i18n[dict][dictValue]}
                  </LangContext.Consumer>,
                },
              ],
              initialValue: this.getInitialValue(record, dataIndex),
            })(this.getInput())}
          </Form.Item>
        ) : (
            children
          )}
      </td>
    );
  };

  render() {
    const {
      editing,
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <Fragment>
        {editing ? (<EditableContext.Consumer>{this.renderCellEdit}</EditableContext.Consumer>) : (<td {...restProps}>
          {
            children
          }
        </td>)}

      </Fragment>

    );
  }
}

export { EditableCell, EditableFormRow, EditableContext };
