import { createTypes, createAction } from "lib/action";

const LOAD_DRIVINGHOUR = createTypes("drivinghour", "load");
const LOAD_VEHICLE_DRIVINGHOUR = createTypes(
  "vehicle_drivinghour",
  "load"
);

const loadDrivinghour = {
  request: (data, loading) =>
    createAction(LOAD_DRIVINGHOUR.REQUEST, { data, loading }),
  success: data => createAction(LOAD_DRIVINGHOUR.SUCCESS, { data }),
  failure: () => createAction(LOAD_DRIVINGHOUR.FAILURE)
};

const loadVehicle = {
  request: () => createAction(LOAD_VEHICLE_DRIVINGHOUR.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_DRIVINGHOUR.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_DRIVINGHOUR.FAILURE)
};

export {
  loadDrivinghour,
  LOAD_DRIVINGHOUR,
  loadVehicle,
  LOAD_VEHICLE_DRIVINGHOUR
};
