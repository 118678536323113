import {
  LOAD_VEHICLE_BRAND,
  UPDATE_VEHICLE_BRAND,
  LOAD_VEHICLE_BRANDS,
} from "./actions";

const initialState = {
  vehicleLists: [],
  vehicleBrandLists: [],
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_VEHICLE_BRAND.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_VEHICLE_BRAND.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data,
        loading: false,
      };
    case UPDATE_VEHICLE_BRAND.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_VEHICLE_BRAND.SUCCESS:
      return {
        ...state,
        msg: "Update Success",
        status: "success",
        loading: false,
      };
    case LOAD_VEHICLE_BRANDS.REQUEST:
      return {
        ...state,
        vehicleBrandLists: [],
      };
    case LOAD_VEHICLE_BRANDS.SUCCESS:
      return {
        ...state,
        vehicleBrandLists: action.payload.data,
      };
    case UPDATE_VEHICLE_BRAND.FAILURE:
    case LOAD_VEHICLE_BRANDS.FAILURE:
    case LOAD_VEHICLE_BRAND.FAILURE:
      return {
        ...state,
        msg: action.payload.err,
        loading: false,
        status: "fail",
        shouldReload: false,
      };
    default:
      return state;
  }
};
