import axios from "axios";
import { all, put, call, takeEvery } from "redux-saga/effects";
import { getErrorMsg } from "lib/fetch";

import * as actions from "./actions";

function* loadVehicleByPlant(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { obj } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclefromplant`,
      obj,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    // console.log("companyLists", res.data.data);
    yield put(actions.loadVehicleByPlant.success(res.data.data));
  } catch (err) {
    yield put(actions.loadVehicleByPlant.failure());
  }
}
function* loadPlantByKeyword(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { obj } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getplantfromkeyword`,
      obj,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(actions.loadPlantByKeyword.success(res.data.data));
  } catch (err) {
    yield put(actions.loadPlantByKeyword.failure());
  }
}

function* loadWithdrawFuelCreditReport(action) {
  const { obj } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_GOLANG_MONGO}/reports/go/withdrawfuelcredit`,
      obj
    );

    yield put(actions.loadWithdrawFuelCreditReport.success(res.data));
    window.location = res.data.result;
  } catch (err) {
    yield put(actions.loadWithdrawFuelCreditReport.failure());
  }
}
export default function* watchreportwithdrawfuelcredit() {
  yield all([
    takeEvery(
      actions.LOAD_WITHDRAW_FUEL_CREDIT_REPORT.REQUEST,
      loadWithdrawFuelCreditReport
    ),
    takeEvery(actions.LOAD_VEHICLE_BY_PLANT.REQUEST, loadVehicleByPlant),
    takeEvery(actions.LOAD_PLANT_BY_KEYWORD.REQUEST, loadPlantByKeyword),
  ]);
}
export { loadVehicleByPlant, loadPlantByKeyword, loadWithdrawFuelCreditReport };
