import { createTypes, createAction } from "lib/action";

const LOAD_DEPOSIT_FUEL_CREDIT_HISTORY = createTypes(
  "load_deposit_fuel_credit",
  "load"
);

const LOAD_VEHICLE_FUEL_CREDIT_HISTORY = createTypes(
  "load_vehicle_fuel_credit",
  "load"
);

const LOAD_VEHICLE_INFO = createTypes("load_vehicle_info", "load");
const LOAD_REASON = createTypes("load_deposit_reason", "load");
const UPDATE_VEHICLE_FUEL_CREDIT = createTypes(
  "update_vehicle_fuel_credit",
  "update"
);

const loadDepositFuelCreditHistory = {
  request: (filterobj) =>
    createAction(LOAD_DEPOSIT_FUEL_CREDIT_HISTORY.REQUEST, { filterobj }),
  success: (data) =>
    createAction(LOAD_DEPOSIT_FUEL_CREDIT_HISTORY.SUCCESS, { data }),
  failure: () => createAction(LOAD_DEPOSIT_FUEL_CREDIT_HISTORY.FAILURE),
};

const loadVehicleFuelCrediHistory = {
  request: (obj) =>
    createAction(LOAD_VEHICLE_FUEL_CREDIT_HISTORY.REQUEST, { obj }),
  success: (data) =>
    createAction(LOAD_VEHICLE_FUEL_CREDIT_HISTORY.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_FUEL_CREDIT_HISTORY.FAILURE),
};

const loadVehicleInfo = {
  request: (obj) => createAction(LOAD_VEHICLE_INFO.REQUEST, { obj }),
  success: (data) => createAction(LOAD_VEHICLE_INFO.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_INFO.FAILURE),
};
const loadDepositReason = {
  request: (obj) => createAction(LOAD_REASON.REQUEST, { obj }),
  success: (data) => createAction(LOAD_REASON.SUCCESS, { data }),
  failure: () => createAction(LOAD_REASON.FAILURE),
};

const updateVehicleFuelCredit = {
  request: (obj, generateMsg) =>
    createAction(UPDATE_VEHICLE_FUEL_CREDIT.REQUEST, { obj, generateMsg }),
  success: (data) => createAction(UPDATE_VEHICLE_FUEL_CREDIT.SUCCESS, { data }),
  failure: () => createAction(UPDATE_VEHICLE_FUEL_CREDIT.FAILURE),
};

export {
  LOAD_DEPOSIT_FUEL_CREDIT_HISTORY,
  loadDepositFuelCreditHistory,
  LOAD_VEHICLE_FUEL_CREDIT_HISTORY,
  loadVehicleFuelCrediHistory,
  LOAD_VEHICLE_INFO,
  loadVehicleInfo,
  UPDATE_VEHICLE_FUEL_CREDIT,
  updateVehicleFuelCredit,
  LOAD_REASON,
  loadDepositReason,
};
