import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Badge,
  Button,
  Table,
  Form,
  Row,
  Col,
  Input,
  Pagination,
  Spin,
  Popconfirm,
  Tooltip,
  Icon,
} from "antd";
import axios from "axios";
import moment from "moment";

import LangContext, { i18n } from "modules/shared/context/langContext";
import { helper_convertDateformat } from "../../../../lib/helper";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import * as actions from "../actions";
import history from "../../../../lib/history";
import FormDetail from "./FormDetail";
import FormProduct from "./FormProduct";

const columnStyle = { fontSize: "12px", fontWeight: "400" };
class TableDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10,
      orderBy: "seq",
      orderType: "asc",
      trip_id: "",
      visible: false,
      visible_product: false,
      deliverLists: [],
      deliverTotal: 0,
      deliver_id: 0,
      edit_popup: false,
      product_popup: false,
      producttableList: [],
    };

    this.columns = [
      {
        title: "#",
        dataIndex: "rowNumber",
        width: 60,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.i.invoice_number}
          </LangContext.Consumer>
        ),
        dataIndex: "invoice_number",
        sorter: true,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.d.destinationLocation}
          </LangContext.Consumer>
        ),
        dataIndex: "location",
        sorter: true,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: (
              <div>
                {text}
                <br />
                {record.address}
              </div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.t.trip_duration}
          </LangContext.Consumer>
        ),
        dataIndex: "deliver_duration",
        sorter: true,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.d.deliver_status}
          </LangContext.Consumer>
        ),
        dataIndex: "",
        sorter: true,
        render(text, record, index) {
          let message = "";
          if (record.confirm_deliver_at != "") {
            message = (
              <LangContext.Consumer>
                {(i18n) => i18n.d.Delivered}
              </LangContext.Consumer>
            );
          }
          return {
            props: {
              style: columnStyle,
            },
            children: (
              <span style={{ color: "green" }}>
                {message}
                <br />
                {record.confirm_deliver_at}
              </span>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.a.action}</LangContext.Consumer>
        ),
        key: "action_btn",
        render: (text, record, index) => {
          return (
            <div>
              <Tooltip
                placement="left"
                title={
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.delete}
                  </LangContext.Consumer>
                }
                arrowPointAtCenter
              >
                <Popconfirm
                  title={
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.doYouWantToDeleteTheseItems}
                    </LangContext.Consumer>
                  }
                  onConfirm={() => {
                    let self = this;
                    self.deleteDeliver(record.id);
                  }}
                >
                  <a href="#" style={{ padding: "0px 15px 0px 5px" }}>
                    <Icon type="close" />
                  </a>
                </Popconfirm>
              </Tooltip>

              <Tooltip
                placement="left"
                title={
                  <LangContext.Consumer>
                    {(i18n) => i18n.e.edit}
                  </LangContext.Consumer>
                }
                arrowPointAtCenter
              >
                <Button
                  type="link"
                  size="large"
                  onClick={() => this.showModalEdit(record.id)}
                >
                  <Icon type="edit" />
                </Button>
              </Tooltip>

              <Tooltip
                placement="left"
                title={
                  <LangContext.Consumer>
                    {(i18n) => i18n.m.manage_product}
                  </LangContext.Consumer>
                }
                arrowPointAtCenter
              >
                <Button
                  type="link"
                  size="large"
                  onClick={() => this.showModalProduct(record.id)}
                >
                  <Icon type="plus" />
                </Button>
              </Tooltip>
            </div>
          );
        },
      },
    ];
  }

  componentWillUnmount() {
    this.setState({
      deliverLists: [],
      deliver_id: 0,
      producttableList: [],
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.deliverytripState.check_deliver !=
      nextProps.deliverytripState.check_deliver
    ) {
      this.setState({
        deliverLists: nextProps.deliverytripState.deliverLists,
      });
    }

    if (
      this.props.deliverytripState.check_product_deliver !=
      nextProps.deliverytripState.check_product_deliver
    ) {
      this.setState({
        producttableList: nextProps.deliverytripState.productdeliverLists,
      });
    }
    return true;
  }

  deleteDeliver = (id) => {
    if (this.props.trip_id != "") {
      let self = this.props;
      axios
        .get(
          `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/deliverytrip/deliverdelete/${id}`,
          {
            headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
          }
        )
        .then(function(response) {
          self.loadDeliverorder(self.trip_id, true);
        })
        .catch(function(error) {
          console.log(error);
        });
    } else {
      let new_deliverList = this.state.deliverLists;

      const new_list = this.state.deliverLists.filter((item) => {
        return item.id != id;
      });

      new_list.forEach(function(part, index) {
        new_list[index].rowNumber = index + 1;
        new_list[index].id = index + 1;
      });

      this.setState(
        {
          deliverLists: [...new_list],
        },
        () => this.props.setDataDetail(this.state.deliverLists)
      );
    }
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  showModalEdit = (id) => {
    if (this.props.trip_id != "") {
      this.props.loadDeliverDetail(id);
    }
    this.setState({
      visible: true,
      deliver_id: id,
      edit_popup: true,
    });
  };

  setPopupEdit = () => {
    this.setState({
      edit_popup: false,
    });
  };

  showModalProduct = (id) => {
    if (this.props.trip_id != "") {
      this.props.loadProductDeliverorder(id);
      this.setState({
        visible_product: true,
        deliver_id: id,
        product_popup: true,
      });
    } else {
      const list_select = this.state.deliverLists.find((item) => {
        return item.id == id;
      });
      this.setState({
        visible_product: true,
        deliver_id: id,
        product_popup: true,
        producttableList: list_select.products,
      });
    }
  };

  // handleProductOk = (values, deliver_id) => {

  //   if (values.length) {
  //     let new_deliverList = this.state.deliverLists;
  //     const index_edit = this.state.deliverLists.findIndex(item => item.id == deliver_id)
  //     new_deliverList[index_edit].products = values;

  //     this.setState({
  //       visible_product: false,
  //       deliver_id: 0,
  //       deliverLists: new_deliverList
  //     });
  //   }

  // }

  deleteProduct = (id) => {
    if (this.props.trip_id != "") {
      let deliver_id = this.state.deliver_id;
      let self = this.props;
      axios
        .get(
          `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/deliverytrip/productdeliverdelete/${id}`,
          {
            headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
          }
        )
        .then(function(response) {
          self.loadProductDeliverorder(deliver_id, true);
        })
        .catch(function(error) {
          console.log(error);
        });
    } else {
      let new_productList = this.state.producttableList;

      const new_list = this.state.producttableList.filter((item) => {
        return item.id != id;
      });

      new_list.forEach(function(part, index) {
        new_list[index].rowNumber = index + 1;
        new_list[index].id = index + 1;
      });

      this.setState({
        producttableList: [...new_list],
      });

      let new_deliverList = this.state.deliverLists;
      const index_edit = this.state.deliverLists.findIndex(
        (item) => item.id == this.state.deliver_id
      );
      new_deliverList[index_edit].products = [...new_list];

      this.setState(
        {
          deliverLists: new_deliverList,
        },
        () => this.props.setDataDetail(this.state.deliverLists)
      );
    }
  };

  saveTableProductList = (value) => {
    if (this.props.trip_id != "") {
      this.props.createProductDeliver(value, this.state.deliver_id, true);
    } else {
      let new_deliverList = this.state.deliverLists;
      const index_edit = this.state.deliverLists.findIndex(
        (item) => item.id == this.state.deliver_id
      );
      new_deliverList[index_edit].products = [
        ...this.state.producttableList,
        {
          id: this.state.producttableList.length + 1,
          rowNumber: this.state.producttableList.length + 1,
          product_name: value.product_name,
          product: value.product,
          quality: value.quality,
          deliver_id: this.state.deliver_id,
        },
      ];

      this.setState(
        {
          deliverLists: new_deliverList,
          producttableList: [
            ...this.state.producttableList,
            {
              id: this.state.producttableList.length + 1,
              rowNumber: this.state.producttableList.length + 1,
              product_name: value.product_name,
              product: value.product,
              quality: value.quality,
              deliver_id: this.state.deliver_id,
            },
          ],
        },
        () => this.props.setDataDetail(this.state.deliverLists)
      );
    }
  };

  handleOk = (values, loading) => {
    if (this.props.trip_id != "") {
      if (this.state.deliver_id == 0) {
        this.props.createDeliver(values, this.props.trip_id, loading);
      } else {
        this.props.updateDeliver(
          values,
          this.state.deliver_id,
          this.props.trip_id,
          loading
        );
      }

      this.setState({
        visible: false,
      });
    } else {
      const date_start = moment(values.eventdate[0]["_d"]).format(
        "DD/MM/YYYY HH:mm"
      );
      const date_end = moment(values.eventdate[1]["_d"]).format(
        "DD/MM/YYYY HH:mm"
      );
      const invoice_date = moment(values.invoice_date["_d"]).format(
        "DD/MM/YYYY"
      );

      if (values.deliver_id != 0) {
        //edit
        let new_deliverList = this.state.deliverLists;
        const index_edit = this.state.deliverLists.findIndex(
          (item) => item.id == values.deliver_id
        );
        const select_deliver_list = new_deliverList[index_edit];
        // console.log("edit", select_deliver_list)
        new_deliverList[index_edit] = {
          id: values.deliver_id,
          rowNumber: values.deliver_id,
          invoice_number: values.invoice_number,
          location: values.location_name,
          deliver_duration: date_start + " - " + date_end,
          confirm_deliver_at: "",
          cv_code: values.cv_code,
          invoice_date: invoice_date,
          confirm_deliver_type: values.confirm_deliver_type,
          date_start: date_start,
          date_end: date_end,
          location_code: values.location_code,
          dest_location: values.dest_location,
          products: select_deliver_list.products,
        };

        this.setState(
          {
            visible: false,
            deliverLists: [...new_deliverList],
            deliver_id: 0,
          },
          () => this.props.setDataDetail(this.state.deliverLists)
        );
      } else {
        //create
        this.setState(
          {
            visible: false,
            deliverLists: [
              ...this.state.deliverLists,
              {
                id: this.state.deliverLists.length + 1,
                rowNumber: this.state.deliverLists.length + 1,
                invoice_number: values.invoice_number,
                location: values.location_name,
                deliver_duration: date_start + " - " + date_end,
                confirm_deliver_at: "",
                cv_code: values.cv_code,
                invoice_date: invoice_date,
                confirm_deliver_type: values.confirm_deliver_type,
                date_start: date_start,
                date_end: date_end,
                location_code: values.location_code,
                dest_location: values.dest_location,
                products: [],
              },
            ],
            deliver_id: 0,
          },
          () => this.props.setDataDetail(this.state.deliverLists)
        );
      }
    }
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      deliver_id: 0,
    });
  };

  handleProductCancel = (e) => {
    this.setState({
      visible_product: false,
      deliver_id: 0,
    });
  };

  render() {
    const columns = this.columns.map((col) => col);

    return (
      <GeneralStyledContent>
        <Row gutter={24} type="flex" justify="end" style={{ height: "38px" }}>
          <Col span={18}>
            <Button size="small" onClick={this.showModal}>
              <Icon type="plus" style={{ marginRight: "5px" }} />
              <LangContext.Consumer>
                {(i18n) => i18n.a.add}
              </LangContext.Consumer>
            </Button>
          </Col>
          <Col span={4} />
          <Col span={2}></Col>
        </Row>

        <Row>
          <Table
            bordered
            size="small"
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={this.state.deliverLists}
            // pagination={{
            //   pageSize: this.state.pageSize,
            //   total: this.state.deliverTotal,
            //   current: this.state.page
            // }}
            pagination={false}
            loading={this.props.deliverytripState.deliverLoading}
            // onChange={this.handleTableChange}
          />
        </Row>

        <FormDetail
          visible={this.state.visible}
          onOk={this.handleOk}
          loadLocation={this.props.loadLocation}
          loadLocationcode={this.props.loadLocationcode}
          locationLists={this.props.deliverytripState.locationLists}
          locationcodeLists={this.props.deliverytripState.locationcodeLists}
          onCancel={this.handleCancel}
          deliver_id={this.state.deliver_id}
          deliverLists={this.state.deliverLists}
          edit_popup={this.state.edit_popup}
          setPopupEdit={this.setPopupEdit}
          trip_id={this.props.trip_id}
          deliverDetails={this.props.deliverytripState.deliverDetails}
          check_deliver_detail={
            this.props.deliverytripState.check_deliver_detail
          }
        />

        <FormProduct
          visible={this.state.visible_product}
          //onOk={this.handleProductOk}
          loadProduct={this.props.loadProduct}
          productLists={this.props.deliverytripState.productLists}
          onCancel={this.handleProductCancel}
          deliver_id={this.state.deliver_id}
          producttableList={this.state.producttableList}
          deleteProduct={this.deleteProduct}
          saveTableProductList={this.saveTableProductList}
          trip_id={this.props.deliverytripState.trip_id}
          loading={this.props.deliverytripState.productdeliverLoading}
        />
      </GeneralStyledContent>
    );
  }
}

const mapStateToProps = ({ deliverytripState, auth }) => ({
  deliverytripState,
  auth,
});

const mapDispatchToProps = {
  loadTrip: actions.loadTrip.request,
  loadDeliverorder: actions.loadDeliverorder.request,
  loadVehicle: actions.loadVehicle.request,
  loadLocation: actions.loadLocation.request,
  loadLocationcode: actions.loadLocationcode.request,
  loadProduct: actions.loadProduct.request,
  createDeliver: actions.createDeliver.request,
  updateDeliver: actions.updateDeliver.request,
  loadDeliverDetail: actions.loadDeliverDetail.request,
  loadProductDeliverorder: actions.loadProductDeliverorder.request,
  createProductDeliver: actions.createProductDeliver.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableDetail);
