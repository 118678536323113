import React, { Component, Fragment, PureComponent } from "react";
import {
  Form,
  Col,
  Row,
  Input,
  Button,
  DatePicker,
  Checkbox,
  AutoComplete,
  Select,
} from "antd";

import * as yup from "yup";
import { debounce } from "debounce";

import { Formik } from "formik";
import Sensors from "./Sensors";

import LangContext from "modules/shared/context/langContext";
import Axios from "axios";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 18,
      offset: 0,
    },
  },
};

export default class JobOrderNameSearch extends PureComponent {
  state = {
    tripcodeList: [],
  };

  getTripcode = async (keyword) => {
    const res = await Axios.post(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/gettripcode`,
      {
        allow_vehicles: this.props.auth.profile.vehicle_visibility,
        company_id: this.props.auth.profile.company_id,
        keyword: keyword,
      },
      {
        headers: {
          Authorization: `Bearer ${this.props.auth.accessToken}`,
        },
      }
    );
    let temp = res.data.map((value) => {
      return {
        label: value.trip_code,
        value: value.id,
      };
    });
    // console.log("temp", temp);

    this.setState({
      tripcodeList: temp,
    });
  };

  componentDidMount() {
    this.getTripcode();
  }

  render() {
    return (
      <Fragment>
        {/* <Row type="flex" align="top">
          <Col> */}
        <Formik
          enableReinitialize={true}
          initialValues={{
            type: "trip",
            trip_id: "",
            trip_code: "",
            engine_on: false,
            cut_fuel_engine_on_off: false,
            cut_fuel_min: "3",
            sensors: {
              fuel_1: false,
              fuel_2: false,
              temperature1: false,
              temperature2: false,
              gate_1: false,
              gate_2: false,
              engineStatus: false,
              pto: false,
              boom: false,
              turn_right: false,
              turn_left: false,
              high_headlight: false,
              low_headlight: false,
              horn: false,
              brake: false,
              windshield_viper: false,
              reversing_light: false,
              battery: false,
              vol: false,
            },
          }}
          validationSchema={yup.object().shape({})}
          onSubmit={(values) => {
            this.props.onSubmit(values);
            // console.log("values", values);
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleFocus,
            setFieldValue,
            isValidating,
          }) => {
            return (
              <Form {...formItemLayout} onSubmit={handleSubmit}>
                <Form.Item
                  label={<LangContext>{(i18n) => i18n.t.tip_code}</LangContext>}
                >
                  <AutoComplete
                    style={{ width: 200 }}
                    optionLabelProp="children"
                    // filterOption={true}
                    placeholder={
                      <LangContext>{(i18n) => i18n.s.search_trip}</LangContext>
                    }
                    optionFilterProp="children"
                    onSelect={(value, op) => {
                      setFieldValue("trip_id", value);
                      setFieldValue("trip_code", op.props.children);
                    }}
                    loading
                    onSearch={(value) => {
                      // console.log("val", value);
                      this.setState({
                        tripcodeList: [
                          {
                            value: "",
                            label: (
                              <LangContext>
                                {(i18n) => i18n.l.loading}
                              </LangContext>
                            ),
                          },
                        ],
                      });
                      debounce(this.getTripcode(value), 1500);
                    }}
                  >
                    {this.state.tripcodeList.map((val) => {
                      if (val.value === "") {
                        return (
                          <AutoComplete.Option
                            value={val.value.toString()}
                            data={val}
                            disabled
                          >
                            {val.label}
                          </AutoComplete.Option>
                        );
                      } else {
                        return (
                          <AutoComplete.Option
                            value={val.value.toString()}
                            data={val}
                          >
                            {val.label}
                          </AutoComplete.Option>
                        );
                      }
                    })}
                  </AutoComplete>
                  {/* <Input
                        name="trip_id"
                        value={values.trip_id}
                        onChange={(e) => {
                          setFieldValue(
                            "trip_id",
                            e.target.value
                          );
                        }}
                        style={{
                          width: 195,
                        }}
                      ></Input> */}
                </Form.Item>
                <Form.Item
                  label={
                    <LangContext>{(i18n) => i18n.e.engine_on_only}</LangContext>
                  }
                >
                  <Checkbox
                    checked={values.engine_on}
                    onChange={(e) => {
                      setFieldValue("engine_on", e.target.checked);
                    }}
                  ></Checkbox>
                </Form.Item>

                <Form.Item
                  label={
                    <LangContext.Consumer>
                      {(i18n) => i18n.c.cut_fuel_engine_on_off}
                    </LangContext.Consumer>
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      checked={values.cut_fuel_engine_on_off}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setFieldValue("cut_fuel_engine_on_off", checked);

                        const defaultCutFuelMin = checked ? "3" : "0";
                        setFieldValue("cut_fuel_min", defaultCutFuelMin);
                      }}
                    />
                    {values.cut_fuel_engine_on_off && (
                      <Select
                        style={{ width: "100px", marginLeft: "20px" }}
                        value={values.cut_fuel_min || "3"}
                        onChange={(value) => {
                          setFieldValue("cut_fuel_min", value);
                        }}
                      >
                        <Select.Option value="1">1 นาที</Select.Option>
                        <Select.Option value="3">3 นาที</Select.Option>
                        <Select.Option value="5">5 นาที</Select.Option>
                      </Select>
                    )}
                  </div>
                </Form.Item>

                <Form.Item
                  label={
                    <LangContext>{(i18n) => i18n.variable.sensor}</LangContext>
                  }
                >
                  <Sensors
                    values={values}
                    setFieldValue={setFieldValue}
                  ></Sensors>
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                  <Row type="flex" justify="end">
                    <Button type="primary" htmlType="submit">
                      <LangContext>{(i18n) => i18n.r.report}</LangContext>
                    </Button>
                  </Row>
                </Form.Item>
              </Form>
            );
          }}
        />
        {/* </Col>
        </Row> */}
      </Fragment>
    );
  }
}
