import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadLocationType(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      companyID,
      accessToken,
      filterObj,
      lang,
    },
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/location-type`,
      {
        page,
        pageSize,
        companyID,
        orderBy,
        orderType,
        filterObj,
        lang,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.loadLocationType.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadLocationType.failure());
  }
}

function* createLocationType(action) {
  const {
    data: { company_id, name, accessToken },
    loading,
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/location-type/create`,
      {
        company_id,
        name,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    loading(res.data);
    yield put(actions.createLocationType.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.createLocationType.failure());
  }
}

function* updateLocationType(action) {
  const {
    data: { company_id, name, accessToken, id },
    loading,
  } = action.payload;

  try {
    const res = yield call(
      axios.put,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/location-type/${id}`,
      {
        name,
        company_id,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    loading(res.data);
    yield put(actions.updateLocationType.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.updateLocationType.failure());
  }
}

export default function* watchLocationType() {
  yield all([
    takeEvery(actions.LOAD_LOCATION_TYPE.REQUEST, loadLocationType),
    takeEvery(actions.CREATE_LOCATION_TYPE.REQUEST, createLocationType),
    takeEvery(actions.UPDATE_LOCATION_TYPE.REQUEST, updateLocationType),
  ]);
}
export { loadLocationType, createLocationType, updateLocationType };
