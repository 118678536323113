import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import LangContext from "modules/shared/context/langContext";
import { Form, Spin, Row, Input, Button } from "antd";

const FormItem = Form.Item;

export default class Forms extends Component {
  render() {
    const { manageRepairDeviceLoading, onSubmit } = this.props;

    return (
      <div>
        <h3>
          <LangContext.Consumer>
            {(i18n) => i18n.m.manageRepairDevice}
          </LangContext.Consumer>
        </h3>

        <Spin spinning={manageRepairDeviceLoading}>
          <Formik
            initialValues={{
              userV1: "",
              userV2: "",
            }}
            validationSchema={yup.object().shape({
              userV1: yup.string().required("กรุณากรอกชื่อผู้ใช้ V1"),
              userV2: yup.string().required("กรุณากรอกชื่อผู้ใช้ V2"),
            })}
            onSubmit={(values) => {
              onSubmit(values, true);
            }}
            render={({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
            }) => (
              <Row type="flex" justify="center">
                <Form onSubmit={handleSubmit}>
                  <FormItem
                    required={true}
                    label={
                      <LangContext.Consumer>
                        {(i18n) => i18n.u.userV1}
                      </LangContext.Consumer>
                    }
                    validateStatus={
                      touched.userV1 && errors.userV1 && "warning"
                    }
                    help={touched.userV1 && errors.userV1}
                  >
                    <Input
                      type="text"
                      name="userV1"
                      placeholder="username V1"
                      values={values.userV1}
                      onChange={handleChange}
                    />
                  </FormItem>

                  <FormItem
                    required={true}
                    label={
                      <LangContext.Consumer>
                        {(i18n) => i18n.u.userV2}
                      </LangContext.Consumer>
                    }
                    validateStatus={
                      touched.userV2 && errors.userV2 && "warning"
                    }
                    help={touched.userV2 && errors.userV2}
                  >
                    <Input
                      type="text"
                      name="userV2"
                      placeholder="username V2"
                      values={values.userV2}
                      onChange={handleChange}
                    />
                  </FormItem>

                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginTop: "15px", width: "170px" }}
                  >
                    <LangContext.Consumer>
                      {(i18n) => i18n.s.save}
                    </LangContext.Consumer>
                  </Button>
                </Form>
              </Row>
            )}
          />
        </Spin>
      </div>
    );
  }
}
