import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Row, Col, Tag, Collapse, Icon, Tooltip } from "antd";

const { Panel } = Collapse;
class VehicleList extends Component {
  render() {
    let { data, color_status } = this.props;
    /* 
        0 = รถที่ไม่ได้วิ่งงาน
        1 = รถที่วิ่งงานแล้ว แต่ตอนนี้ไม่ได้ทำงาน
        2 = รถที่วิ่งงานอยู่
        3 = รถที่เข้าอู่
        4 = รถที่ขาดการติดต่อ
        5 = รถที่อุปกรณ์ติดตามชำรุด
    */

    return (
      <Fragment>
        <Collapse
          defaultActiveKey={data.plant_id}
          expandIcon={({ isActive }) => (
            <Icon
              type="caret-right"
              rotate={isActive ? 90 : 0}
              style={{ color: "white" }}
            />
          )}
        >
          <Panel
            header={
              <span className="panel-font-style">
                {`${data.plant_name} ( ${data.count_vehicle} )`}
              </span>
            }
            key={data.plant_id}
            className="panel-style"
          >
            {data.vehicle_company.length > 0 ? (
              <Row className="div-company-style">
                {data.vehicle_company.map((item, index) => {
                  return (
                    <Col span={3} className="col-panel-style">
                      <Tooltip
                        title={color_status[item.status].text}
                        placement="bottom"
                      >
                        <Tag
                          color={color_status[item.status].color}
                          className="panel-tag-style"
                          style={{
                            border: color_status[item.status].border,
                          }}
                        >
                          <span
                            style={{
                              color: color_status[item.status].font_color,
                            }}
                          >
                            {item.code}
                          </span>
                        </Tag>
                      </Tooltip>
                    </Col>
                  );
                })}
              </Row>
            ) : null}

            {data.vehicle_transfer.length > 0 ? (
              <Row className="div-transfer-style">
                {data.vehicle_transfer.map((item, index) => {
                  return (
                    <Col span={3} className="col-panel-style">
                      <Tag
                        color={color_status[item.status].color}
                        className="panel-tag-style"
                        style={{
                          border: item.status == 0 ? "1px solid #CCCCCC" : "",
                        }}
                      >
                        <span
                          style={{
                            color: item.status == 0 ? "black" : "white",
                          }}
                        >
                          {item.code}
                        </span>
                      </Tag>
                    </Col>
                  );
                })}
              </Row>
            ) : null}
          </Panel>
        </Collapse>
      </Fragment>
    );
  }
}

export default connect(
  ({ ui: { language } }) => ({
    language,
  }),
  {}
)(VehicleList);
