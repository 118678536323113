import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { debounce } from "debounce";
import { Row, Col, Form, Input, Select, Modal, Switch, Button } from "antd";

import { FaMapMarkerAlt, FaMap } from "react-icons/fa";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { autoGenKey } from "../../../../../../lib/helper";
import FormDivider from "../../../../../shared/components/FormDivider";

const InputGroup = Input.Group;
const { Item } = Form;
const FormItem = Item;
const { Option, OptGroup } = Select;

class SpeedLimitArea extends Component {
  state = {
    zoneList: [],
    isAdvanceMode: false,
    modalAdvancedSetting: false,
    toggleSpeed: {
      acceleration: false,
      brake: false,
      lat: false,
      w: false,
      shock: false,
      highRPM: false,
    },
    tempToggleSpeed: {
      acceleration: false,
      brake: false,
      lat: false,
      w: false,
      shock: false,
      highRPM: false,
    },
  };

  componentDidMount() {
    let self = this;
    fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/dropdownlist/getzone`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.auth.accessToken}`,
        },
        body: JSON.stringify({
          company_id: this.props.auth.profile.company_id,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        self.setState({ zoneList: data });
      });

    let toggleSpeed = {};
    if (this.props.values.acc_yel || this.props.values.acc_red) {
      toggleSpeed = { ...toggleSpeed, acceleration: true };
    }

    if (this.props.values.brake_yel || this.props.values.brake_red) {
      toggleSpeed = { ...toggleSpeed, brake: true };
    }

    if (this.props.values.lat_yel || this.props.values.lat_red) {
      toggleSpeed = { ...toggleSpeed, lat: true };
    }

    if (this.props.values.w_yel || this.props.values.w_red) {
      toggleSpeed = { ...toggleSpeed, w: true };
    }

    if (this.props.values.shock_yel || this.props.values.shock_red) {
      toggleSpeed = { ...toggleSpeed, shock: true };
    }

    if (this.props.values.high_rpm__yel || this.props.values.high_rpm__red) {
      toggleSpeed = { ...toggleSpeed, highRPM: true };
    }

    this.setState({
      toggleSpeed: { ...this.state.toggleSpeed, ...toggleSpeed },
      tempToggleSpeed: { ...this.state.tempToggleSpeed, ...toggleSpeed },
    });
  }

  render() {
    return (
      <Fragment>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={18} md={13} lg={13} xl={10}>
            <FormItem
              label={
                <>
                  <LangContext.Consumer>
                    {(i18n) => i18n.s.speed}
                  </LangContext.Consumer>
                  {` (`}
                  <LangContext.Consumer>
                    {(i18n) => i18n.k.kmH}
                  </LangContext.Consumer>
                  {`)`}
                </>
              }
              required={true}
              validateStatus={
                this.props.touched.value_yellow &&
                this.props.errors.value_yellow
                  ? "warning"
                  : undefined
              }
              help={
                this.props.touched.value_yellow &&
                this.props.errors.value_yellow
                  ? this.props.errors.value_yellow.substr(
                      this.props.errors.value_yellow.indexOf(" ") + 1
                    )
                  : undefined
              }
            >
              <InputGroup compact>
                <Input
                  className={"policy-alarm-input"}
                  style={{ width: "100%" }}
                  name="value_yellow"
                  defaultValue={this.props.values.value_yellow}
                  onChange={(e) => {
                    this.props.setFieldValue("value_yellow", e.target.value);
                  }}
                  onBlur={this.props.handleBlur}
                  type="number"
                />
              </InputGroup>
            </FormItem>
          </Col>

          <Col xs={24} sm={18} md={13} lg={13} xl={10}>
            <FormItem
              label={
                <>
                  <LangContext.Consumer>
                    {(i18n) => i18n.s.speed}
                  </LangContext.Consumer>
                  {` (`}
                  <LangContext.Consumer>
                    {(i18n) => i18n.k.kmH}
                  </LangContext.Consumer>
                  {`)`}
                </>
              }
              required={true}
              validateStatus={
                this.props.touched.value_red && this.props.errors.value_red
                  ? "warning"
                  : undefined
              }
              help={
                this.props.touched.value_red && this.props.errors.value_red
                  ? this.props.errors.value_red.substr(
                      this.props.errors.value_red.indexOf(" ") + 1
                    )
                  : undefined
              }
            >
              <InputGroup compact>
                <Input
                  className={"policy-alert-input"}
                  style={{ width: "100%" }}
                  name="value_red"
                  defaultValue={this.props.values.value_red}
                  onChange={(e) => {
                    this.props.setFieldValue("value_red", e.target.value);
                  }}
                  onBlur={this.props.handleBlur}
                  type="number"
                />
              </InputGroup>
            </FormItem>
          </Col>

          <Col xs={24} sm={18} md={13} lg={13} xl={4}>
            <FormItem style={{ marginTop: "28px" }}>
              <Button
                type="primary"
                icon="setting"
                onClick={(e) => this.setState({ modalAdvancedSetting: true })}
              >
                {" "}
                <LangContext.Consumer>
                  {(i18n) => i18n.a.advancedSetting}
                </LangContext.Consumer>
              </Button>
            </FormItem>
          </Col>
        </Row>

        {/* Start Acceleration */}
        {this.state.toggleSpeed.acceleration && (
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={18} md={13} lg={13} xl={10}>
              <Form.Item
                label={
                  <>
                    <LangContext.Consumer>
                      {(i18n) => i18n.a.acceleration}
                    </LangContext.Consumer>
                    {` (`}
                    <LangContext.Consumer>
                      {(i18n) => i18n.k.kmHrS}
                    </LangContext.Consumer>
                    {`)`}
                  </>
                }
                required={true}
                validateStatus={
                  this.props.touched.acc_yel && this.props.errors.acc_yel
                    ? "warning"
                    : undefined
                }
                help={
                  this.props.touched.acc_yel && this.props.errors.acc_yel
                    ? this.props.errors.acc_yel.substr(
                        this.props.errors.acc_yel.indexOf(" ") + 1
                      )
                    : undefined
                }
              >
                <Input
                  className={"policy-alarm-input"}
                  style={{ width: "100%" }}
                  name="value_yel"
                  defaultValue={this.props.values.acc_yel}
                  onChange={(e) => {
                    this.props.setFieldValue("acc_yel", e.target.value);
                  }}
                  onBlur={this.props.handleBlur}
                  type="number"
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={18} md={13} lg={13} xl={10}>
              <Form.Item
                label={
                  <>
                    <LangContext.Consumer>
                      {(i18n) => i18n.a.acceleration}
                    </LangContext.Consumer>
                    {` (`}
                    <LangContext.Consumer>
                      {(i18n) => i18n.k.kmHrS}
                    </LangContext.Consumer>
                    {` )`}
                  </>
                }
                required={true}
                validateStatus={
                  this.props.touched.acc_red && this.props.errors.acc_red
                    ? "warning"
                    : undefined
                }
                help={
                  this.props.touched.acc_red && this.props.errors.acc_red
                    ? this.props.errors.acc_red.substr(
                        this.props.errors.acc_red.indexOf(" ") + 1
                      )
                    : undefined
                }
              >
                <Input
                  className={"policy-alert-input"}
                  style={{ width: "100%" }}
                  name="value_red"
                  defaultValue={this.props.values.acc_red}
                  onChange={(e) => {
                    this.props.setFieldValue("acc_red", e.target.value);
                  }}
                  onBlur={this.props.handleBlur}
                  type="number"
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {/* End Acceleration */}

        {/* Start Brake */}
        {this.state.toggleSpeed.brake && (
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={18} md={13} lg={13} xl={10}>
              <Form.Item
                label={
                  <>
                    <LangContext.Consumer>
                      {(i18n) => i18n.b.brake}
                    </LangContext.Consumer>
                    {` (`}
                    <LangContext.Consumer>
                      {(i18n) => i18n.k.kmHrS}
                    </LangContext.Consumer>
                    {` )`}
                  </>
                }
                required={true}
                validateStatus={
                  this.props.touched.brake_yel && this.props.errors.brake_yel
                    ? "warning"
                    : undefined
                }
                help={
                  this.props.touched.brake_yel && this.props.errors.brake_yel
                    ? this.props.errors.brake_yel.substr(
                        this.props.errors.brake_yel.indexOf(" ") + 1
                      )
                    : undefined
                }
              >
                <Input
                  className={"policy-alarm-input"}
                  style={{ width: "100%" }}
                  name="value_yel"
                  defaultValue={this.props.values.brake_yel}
                  onChange={(e) => {
                    this.props.setFieldValue("brake_yel", e.target.value);
                  }}
                  onBlur={this.props.handleBlur}
                  type="number"
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={18} md={13} lg={13} xl={10}>
              <Form.Item
                label={
                  <>
                    <LangContext.Consumer>
                      {(i18n) => i18n.b.brake}
                    </LangContext.Consumer>
                    {` (`}
                    <LangContext.Consumer>
                      {(i18n) => i18n.k.kmHrS}
                    </LangContext.Consumer>
                    {`)`}
                  </>
                }
                required={true}
                validateStatus={
                  this.props.touched.brake_red && this.props.errors.brake_red
                    ? "warning"
                    : undefined
                }
                help={
                  this.props.touched.brake_red && this.props.errors.brake_red
                    ? this.props.errors.brake_red.substr(
                        this.props.errors.brake_red.indexOf(" ") + 1
                      )
                    : undefined
                }
              >
                <InputGroup compact>
                  <Input
                    className={"policy-alert-input"}
                    style={{ width: "100%" }}
                    name="value_red"
                    defaultValue={this.props.values.brake_red}
                    onChange={(e) => {
                      this.props.setFieldValue("brake_red", e.target.value);
                    }}
                    onBlur={this.props.handleBlur}
                    type="number"
                  />
                </InputGroup>
              </Form.Item>
            </Col>
          </Row>
        )}
        {/* End Brake */}

        {/* Start Lat */}
        {this.state.toggleSpeed.lat && (
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={18} md={13} lg={13} xl={10}>
              <Form.Item
                label={
                  <>
                    <LangContext.Consumer>
                      {(i18n) => i18n.l.lat}
                    </LangContext.Consumer>
                    {` (`}
                    <LangContext.Consumer>
                      {(i18n) => i18n.m.ms2}
                    </LangContext.Consumer>
                    {`)`}
                  </>
                }
                required={true}
                validateStatus={
                  this.props.touched.lat_yel && this.props.errors.lat_yel
                    ? "warning"
                    : undefined
                }
                help={
                  this.props.touched.lat_yel && this.props.errors.lat_yel
                    ? this.props.errors.lat_yel.substr(
                        this.props.errors.lat_yel.indexOf(" ") + 1
                      )
                    : undefined
                }
              >
                <Input
                  className={"policy-alarm-input"}
                  style={{ width: "100%" }}
                  name="value_yel"
                  defaultValue={this.props.values.lat_yel}
                  onChange={(e) => {
                    this.props.setFieldValue("lat_yel", e.target.value);
                  }}
                  onBlur={this.props.handleBlur}
                  type="number"
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={18} md={13} lg={13} xl={10}>
              <Form.Item
                label={
                  <>
                    <LangContext.Consumer>
                      {(i18n) => i18n.l.lat}
                    </LangContext.Consumer>
                    {` (`}
                    <LangContext.Consumer>
                      {(i18n) => i18n.m.ms2}
                    </LangContext.Consumer>
                    {`)`}
                  </>
                }
                required={true}
                validateStatus={
                  this.props.touched.lat_red && this.props.errors.lat_red
                    ? "warning"
                    : undefined
                }
                help={
                  this.props.touched.lat_red && this.props.errors.lat_red
                    ? this.props.errors.lat_red.substr(
                        this.props.errors.lat_red.indexOf(" ") + 1
                      )
                    : undefined
                }
              >
                <Input
                  className={"policy-alert-input"}
                  style={{ width: "100%" }}
                  name="value_red"
                  defaultValue={this.props.values.lat_red}
                  onChange={(e) => {
                    this.props.setFieldValue("lat_red", e.target.value);
                  }}
                  onBlur={this.props.handleBlur}
                  type="number"
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {/* End Lat */}

        {/* Start W */}
        {this.state.toggleSpeed.w && (
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={18} md={13} lg={13} xl={10}>
              <Form.Item
                label={
                  <>
                    <LangContext.Consumer>
                      {(i18n) => i18n.w.w}
                    </LangContext.Consumer>
                    {` (`}
                    <LangContext.Consumer>
                      {(i18n) => i18n.r.radS}
                    </LangContext.Consumer>
                    {`)`}
                  </>
                }
                required={true}
                validateStatus={
                  this.props.touched.w_yel && this.props.errors.w_yel
                    ? "warning"
                    : undefined
                }
                help={
                  this.props.touched.w_yel && this.props.errors.w_yel
                    ? this.props.errors.w_yel.substr(
                        this.props.errors.w_yel.indexOf(" ") + 1
                      )
                    : undefined
                }
              >
                <Input
                  className={"policy-alarm-input"}
                  style={{ width: "100%" }}
                  name="value_yel"
                  defaultValue={this.props.values.w_yel}
                  onChange={(e) => {
                    this.props.setFieldValue("w_yel", e.target.value);
                  }}
                  onBlur={this.props.handleBlur}
                  type="number"
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={18} md={13} lg={13} xl={10}>
              <Form.Item
                label={
                  <>
                    <LangContext.Consumer>
                      {(i18n) => i18n.w.w}
                    </LangContext.Consumer>
                    {` (`}
                    <LangContext.Consumer>
                      {(i18n) => i18n.r.radS}
                    </LangContext.Consumer>
                    {`)`}
                  </>
                }
                required={true}
                validateStatus={
                  this.props.touched.w_red && this.props.errors.w_red
                    ? "warning"
                    : undefined
                }
                help={
                  this.props.touched.w_red && this.props.errors.w_red
                    ? this.props.errors.w_red.substr(
                        this.props.errors.w_red.indexOf(" ") + 1
                      )
                    : undefined
                }
              >
                <Input
                  className={"policy-alert-input"}
                  style={{ width: "100%" }}
                  name="value_red"
                  defaultValue={this.props.values.w_red}
                  onChange={(e) => {
                    this.props.setFieldValue("w_red", e.target.value);
                  }}
                  onBlur={this.props.handleBlur}
                  type="number"
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {/* End W */}

        {/* Start Shock */}
        {this.state.toggleSpeed.shock && (
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={18} md={13} lg={13} xl={10}>
              <Form.Item
                label={
                  <>
                    <LangContext.Consumer>
                      {(i18n) => i18n.s.shock}
                    </LangContext.Consumer>
                    {` (`}
                    <LangContext.Consumer>
                      {(i18n) => i18n.m.ms2}
                    </LangContext.Consumer>
                    {`)`}
                  </>
                }
                required={true}
                validateStatus={
                  this.props.touched.shock_yel && this.props.errors.shock_yel
                    ? "warning"
                    : undefined
                }
                help={
                  this.props.touched.shock_yel && this.props.errors.shock_yel
                    ? this.props.errors.shock_yel.substr(
                        this.props.errors.shock_yel.indexOf(" ") + 1
                      )
                    : undefined
                }
              >
                <Input
                  className={"policy-alarm-input"}
                  style={{ width: "100%" }}
                  name="value_yel"
                  defaultValue={this.props.values.shock_yel}
                  onChange={(e) => {
                    this.props.setFieldValue("shock_yel", e.target.value);
                  }}
                  onBlur={this.props.handleBlur}
                  type="number"
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={18} md={13} lg={13} xl={10}>
              <Form.Item
                label={
                  <>
                    <LangContext.Consumer>
                      {(i18n) => i18n.s.shock}
                    </LangContext.Consumer>
                    {` (`}
                    <LangContext.Consumer>
                      {(i18n) => i18n.m.ms2}
                    </LangContext.Consumer>
                    {`)`}
                  </>
                }
                required={true}
                validateStatus={
                  this.props.touched.shock_red && this.props.errors.shock_red
                    ? "warning"
                    : undefined
                }
                help={
                  this.props.touched.shock_red && this.props.errors.shock_red
                    ? this.props.errors.shock_red.substr(
                        this.props.errors.shock_red.indexOf(" ") + 1
                      )
                    : undefined
                }
              >
                <Input
                  className={"policy-alert-input"}
                  style={{ width: "100%" }}
                  name="value_red"
                  defaultValue={this.props.values.shock_red}
                  onChange={(e) => {
                    this.props.setFieldValue("shock_red", e.target.value);
                  }}
                  onBlur={this.props.handleBlur}
                  type="number"
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {/* End Shock */}

        {/* Start High RPM */}
        {this.state.toggleSpeed.highRPM && (
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={18} md={13} lg={13} xl={10}>
              <Form.Item
                label={
                  <>
                    <LangContext.Consumer>
                      {(i18n) => i18n.h.highRPM}
                    </LangContext.Consumer>
                    {` (`}
                    <LangContext.Consumer>
                      {(i18n) => i18n.m.ms2}
                    </LangContext.Consumer>
                    {`)`}
                  </>
                }
                required={true}
                validateStatus={
                  this.props.touched.high_rpm_yel &&
                  this.props.errors.high_rpm_yel
                    ? "warning"
                    : undefined
                }
                help={
                  this.props.touched.high_rpm_yel &&
                  this.props.errors.high_rpm_yel
                    ? this.props.errors.high_rpm_yel.substr(
                        this.props.errors.high_rpm_yel.indexOf(" ") + 1
                      )
                    : undefined
                }
              >
                <Input
                  className={"policy-alarm-input"}
                  style={{ width: "100%" }}
                  name="value_yel"
                  defaultValue={this.props.values.high_rpm_yel}
                  onChange={(e) => {
                    this.props.setFieldValue("high_rpm_yel", e.target.value);
                  }}
                  onBlur={this.props.handleBlur}
                  type="number"
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={18} md={13} lg={13} xl={10}>
              <Form.Item
                label={
                  <>
                    <LangContext.Consumer>
                      {(i18n) => i18n.h.highRPM}
                    </LangContext.Consumer>
                    {` (`}
                    <LangContext.Consumer>
                      {(i18n) => i18n.m.ms2}
                    </LangContext.Consumer>
                    {`)`}
                  </>
                }
                required={true}
                validateStatus={
                  this.props.touched.high_rpm_red &&
                  this.props.errors.high_rpm_red
                    ? "warning"
                    : undefined
                }
                help={
                  this.props.touched.high_rpm_red &&
                  this.props.errors.high_rpm_red
                    ? this.props.errors.high_rpm_red.substr(
                        this.props.errors.high_rpm_red.indexOf(" ") + 1
                      )
                    : undefined
                }
              >
                <Input
                  className={"policy-alert-input"}
                  style={{ width: "100%" }}
                  name="value_red"
                  defaultValue={this.props.values.high_rpm_red}
                  onChange={(e) => {
                    this.props.setFieldValue("high_rpm_red", e.target.value);
                  }}
                  onBlur={this.props.handleBlur}
                  type="number"
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {/* End High RPM */}

        <Row>
          <Col xs={24} sm={18} md={13} lg={13} xl={10}>
            <Form.Item label="ระยะเวลา">
              <Select
                key={autoGenKey("condition-select")}
                name="duration"
                defaultValue={this.props.values.duration}
                onChange={(e) => {
                  this.props.setFieldValue("duration", e);
                }}
              >
                <Option key={0} value={0}>
                  <LangContext.Consumer>
                    {(i18n) => "ทันทีที่ฝ่าฝืน"}
                  </LangContext.Consumer>
                </Option>
                <Option key={5} value={5}>
                  <LangContext.Consumer>
                    {(i18n) => "> 5 วินาที"}
                  </LangContext.Consumer>
                </Option>
                <Option key={10} value={10}>
                  <LangContext.Consumer>
                    {(i18n) => "> 10 วินาที"}
                  </LangContext.Consumer>
                </Option>
                <Option key={15} value={15}>
                  <LangContext.Consumer>
                    {(i18n) => "> 15 วินาที"}
                  </LangContext.Consumer>
                </Option>
                <Option key={30} value={30}>
                  <LangContext.Consumer>
                    {(i18n) => "> 30 วินาที"}
                  </LangContext.Consumer>
                </Option>
                <Option key={60} value={60}>
                  <LangContext.Consumer>
                    {(i18n) => "> 60 วินาที"}
                  </LangContext.Consumer>
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={18} md={13} lg={13} xl={10}>
            <Form.Item
              label="พื้นที่"
              required={true}
              validateStatus={
                this.props.touched.area && this.props.errors.area
                  ? "warning"
                  : undefined
              }
              help={
                this.props.touched.area && this.props.errors.area
                  ? this.props.errors.area.substr(
                      this.props.errors.area.indexOf(" ") + 1
                    )
                  : undefined
              }
            >
              <Select
                // mode="multiple"
                name="area"
                placeholder="Please select"
                defaultValue={this.props.values.area}
                onChange={(e) => {
                  this.props.setFieldValue("area", e);
                }}
                maxTagCount={10}
              >
                {/* <OptGroup label="ชนิดสถานที่">
                  <Option key={"Plant"}>
                    <FaMapMarkerAlt /> จุดโหลดของ
                  </Option>
                  <Option key={"Site"}>
                    <FaMapMarkerAlt /> จุดส่งของ
                  </Option>
                  <Option key={"ParkingLot"}>
                    <FaMapMarkerAlt /> ลานจอดรถ
                  </Option>
                  <Option key={"RestArea"}>
                    <FaMapMarkerAlt /> จุดพักรถ
                  </Option>
                  <Option key={"GasStation"}>
                    <FaMapMarkerAlt /> ปั๊มน้ำมัน
                  </Option>
                  <Option key={"CarService"}>
                    <FaMapMarkerAlt /> ศูนย์บริการ
                  </Option>
                  <Option key={"BS"}>
                    <FaMapMarkerAlt /> Bus Stop Point
                  </Option>

                  <Option key={"BS1"}>
                    <FaMapMarkerAlt /> สถานที่ชั่วคราว
                  </Option>
                  <Option key={"NoParking"}>
                    <FaMapMarkerAlt /> จุดห้ามจอด
                  </Option>
                </OptGroup> */}
                <OptGroup label="พื้นที่">
                  {this.state.zoneList.map((ele) => (
                    <Option key={ele.id}>
                      <FaMap style={{ marginRight: "10px" }} />
                      {ele.name}
                    </Option>
                  ))}
                </OptGroup>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Modal
          title={
            <LangContext.Consumer>
              {(i18n) => i18n.a.advancedSetting}
            </LangContext.Consumer>
          }
          visible={this.state.modalAdvancedSetting}
          onOk={(e) =>
            this.setState({
              toggleSpeed: this.state.tempToggleSpeed,
              modalAdvancedSetting: false,
            })
          }
          onCancel={(e) => this.setState({ modalAdvancedSetting: false })}
        >
          <FormDivider>
            <Switch
              checked={Boolean(this.state.tempToggleSpeed.acceleration)}
              checkedChildren={
                <LangContext.Consumer>
                  {(i18n) => i18n.o.open}
                </LangContext.Consumer>
              }
              unCheckedChildren={
                <LangContext.Consumer>
                  {(i18n) => i18n.c.close}
                </LangContext.Consumer>
              }
              style={{ marginRight: "10px" }}
              onChange={(e) =>
                this.setState({
                  tempToggleSpeed: {
                    ...this.state.tempToggleSpeed,
                    acceleration: e,
                  },
                })
              }
            />
            <LangContext.Consumer>
              {(i18n) => i18n.a.acceleration}
            </LangContext.Consumer>
          </FormDivider>

          <FormDivider>
            <Switch
              checked={Boolean(this.state.tempToggleSpeed.brake)}
              checkedChildren={
                <LangContext.Consumer>
                  {(i18n) => i18n.o.open}
                </LangContext.Consumer>
              }
              unCheckedChildren={
                <LangContext.Consumer>
                  {(i18n) => i18n.c.close}
                </LangContext.Consumer>
              }
              style={{ marginRight: "10px" }}
              onChange={(e) =>
                this.setState({
                  tempToggleSpeed: {
                    ...this.state.tempToggleSpeed,
                    brake: e,
                  },
                })
              }
            />
            <LangContext.Consumer>
              {(i18n) => i18n.b.brake}
            </LangContext.Consumer>
          </FormDivider>

          <FormDivider>
            <Switch
              checked={Boolean(this.state.tempToggleSpeed.lat)}
              checkedChildren={
                <LangContext.Consumer>
                  {(i18n) => i18n.o.open}
                </LangContext.Consumer>
              }
              unCheckedChildren={
                <LangContext.Consumer>
                  {(i18n) => i18n.c.close}
                </LangContext.Consumer>
              }
              style={{ marginRight: "10px" }}
              onChange={(e) =>
                this.setState({
                  tempToggleSpeed: {
                    ...this.state.tempToggleSpeed,
                    lat: e,
                  },
                })
              }
            />
            <LangContext.Consumer>{(i18n) => i18n.l.lat}</LangContext.Consumer>
          </FormDivider>

          <FormDivider>
            <Switch
              checked={Boolean(this.state.tempToggleSpeed.w)}
              checkedChildren={
                <LangContext.Consumer>
                  {(i18n) => i18n.o.open}
                </LangContext.Consumer>
              }
              unCheckedChildren={
                <LangContext.Consumer>
                  {(i18n) => i18n.c.close}
                </LangContext.Consumer>
              }
              style={{ marginRight: "10px" }}
              onChange={(e) =>
                this.setState({
                  tempToggleSpeed: {
                    ...this.state.tempToggleSpeed,
                    w: e,
                  },
                })
              }
            />
            <LangContext.Consumer>{(i18n) => i18n.w.w}</LangContext.Consumer>
          </FormDivider>

          <FormDivider>
            <Switch
              checked={Boolean(this.state.tempToggleSpeed.shock)}
              checkedChildren={
                <LangContext.Consumer>
                  {(i18n) => i18n.o.open}
                </LangContext.Consumer>
              }
              unCheckedChildren={
                <LangContext.Consumer>
                  {(i18n) => i18n.c.close}
                </LangContext.Consumer>
              }
              style={{ marginRight: "10px" }}
              onChange={(e) =>
                this.setState({
                  tempToggleSpeed: {
                    ...this.state.tempToggleSpeed,
                    shock: e,
                  },
                })
              }
            />
            <LangContext.Consumer>
              {(i18n) => i18n.s.shock}
            </LangContext.Consumer>
          </FormDivider>

          <FormDivider>
            <Switch
              checked={Boolean(this.state.tempToggleSpeed.highRPM)}
              checkedChildren={
                <LangContext.Consumer>
                  {(i18n) => i18n.o.open}
                </LangContext.Consumer>
              }
              unCheckedChildren={
                <LangContext.Consumer>
                  {(i18n) => i18n.c.close}
                </LangContext.Consumer>
              }
              style={{ marginRight: "10px" }}
              onChange={(e) =>
                this.setState({
                  tempToggleSpeed: {
                    ...this.state.tempToggleSpeed,
                    highRPM: e,
                  },
                })
              }
            />
            <LangContext.Consumer>
              {(i18n) => i18n.h.highRPM}
            </LangContext.Consumer>
          </FormDivider>
        </Modal>
      </Fragment>
    );
  }
}
export default connect(
  ({ policyState, auth }) => ({ policyState, auth }),
  {}
)(SpeedLimitArea);
