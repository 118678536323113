import styled from "styled-components";
import { Layout, Row, Col } from "antd";
const { Sider } = Layout;

const StyledImageHeader = styled.h2`
  padding: 7px 0px 0px 12px;
  height: 45px;
  width: 270px;
  background-color: white;
  line-height: 15px;
`;

const StyledHeader1 = styled.p`
  margin-top: 0;
  margin-bottom: 0em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 150;
  display: block;
  font-size: 3em;
`;

const StyledHeader2 = styled.p`
  margin-top: 0;
  margin-bottom: 0em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 150;
  display: block;
  font-size: 2em;
`;

const StyledButton = styled.button`
  color: red;
  background-color: white;
`;

const StyledSearchForm = styled.div`
  margin: 0px 0px 24px 0px;
  padding: 10px 10px 20px 20px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
`;

const StyledSearchFormMark = styled.div`
  margin: 0px 0px 24px 0px;
  padding: 24px;
  border-radius: 6px;
`;

const StyledSearchForm3 = styled.div`
  margin: 0px 0px 0px 0px;
  padding: 16px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
`;

const StyledSearchForm2 = styled.div`
  margin: 15px 15px 15px 15px;
  padding: 15px;
  border: 1px solid #000000;
  border-radius: 6px;
`;

const StyledWidget = styled.div`
  margin: 0px 0px 0px 0px;
  padding: 20px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  height: 100%;
  width: 100%;
  box-shadow: 0 0.46875rem 2.1875rem rgba(58, 196, 125, 0.03),
    0 0.9375rem 1.40625rem rgba(58, 196, 125, 0.03),
    0 0.25rem 0.53125rem rgba(58, 196, 125, 0.05),
    0 0.125rem 0.1875rem rgba(58, 196, 125, 0.03);
`;

const StyledWidgetShadowOnly = styled.div`
  box-shadow: 0 0.46875rem 2.1875rem rgba(58, 196, 125, 0.03),
    0 0.9375rem 1.40625rem rgba(58, 196, 125, 0.03),
    0 0.25rem 0.53125rem rgba(58, 196, 125, 0.05),
    0 0.125rem 0.1875rem rgba(58, 196, 125, 0.03);
`;

const GeneralStyledContent = styled(Layout.Content)`
  background: white;
  margin: 5px 5px 5px 5px;
  padding: 20px 20px 10px 20px;
  min-height: ${window.innerHeight - 55}px;
`;

const GeneralStyledContentBoat = styled(Layout.Content)`
  background: white;
  margin: 5px 5px 5px 5px;
  padding: 100px 20px 10px 20px;
  min-height: ${window.innerHeight - 55}px;
`;

const PureBackgroundStyledContent = styled(Layout.Content)`
  margin: 5px 5px 5px 5px;
  padding: 20px 20px 10px 20px;
  min-height: ${window.innerHeight - 55}px;
`;

const TrackingStyledContent = styled(Layout.Content)`
  background: white;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  min-height: ${window.innerHeight - 55}px;
`;

const ReplayStyledContent = styled(Layout.Content)`
  background: white;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
`;

const StyledSider = styled(Sider)`
  padding: 0;
  max-width: 270;
  min-width: 270;
  width: 270;
`;
const StyledHeader = styled.h2`
  padding: 13px 0px 0px 14px;
  height: 45px;
  width: 270px;
  background-color: white;
  line-height: 15px;
`;

const StyledLogo = styled.img`
  margin-left: 22%;
  width: 170px !important;
  max-height: 100% !important;
  margin-bottom: 20px;
`;

const StyledLogoVerifyEmail = styled.img`
  width: 270px !important;
  max-height: 100% !important;
`;

const StyledDiv = styled.div`
  height: 100%;
  background-image: url("/img/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
`;

const StyledRow = styled(Row)``;

const StyledCol = styled(Col)`
  padding-top: 200px;
`;

export {
  StyledLogoVerifyEmail,
  StyledLogo,
  StyledDiv,
  StyledRow,
  StyledCol,
  StyledButton,
  StyledSearchForm,
  StyledSearchForm2,
  StyledSearchForm3,
  StyledSearchFormMark,
  StyledHeader1,
  StyledHeader2,
  GeneralStyledContent,
  PureBackgroundStyledContent,
  TrackingStyledContent,
  ReplayStyledContent,
  StyledSider,
  StyledHeader,
  StyledImageHeader,
  StyledWidget,
  StyledWidgetShadowOnly,
  GeneralStyledContentBoat,
};
