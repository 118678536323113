import { createTypes, createAction } from "lib/action";
const LOAD_LOCATION_TYPE = createTypes("masterdatalocationtype", "load");
const CREATE_LOCATION_TYPE = createTypes("masterdatalocationtype", "create");
const UPDATE_LOCATION_TYPE = createTypes("masterdatalocationtype", "update");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
  return {
    type: REMOVE_LISTS,
  };
}

const loadLocationType = {
  request: (data, loading) =>
    createAction(LOAD_LOCATION_TYPE.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_LOCATION_TYPE.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_LOCATION_TYPE.FAITLURE),
};

const createLocationType = {
  request: (data, loading) =>
    createAction(CREATE_LOCATION_TYPE.REQUEST, { data, loading }),
  success: (res) => createAction(CREATE_LOCATION_TYPE.SUCCESS, { res }),
  failure: () => createAction(CREATE_LOCATION_TYPE.FAILURE),
};

const updateLocationType = {
  request: (data, loading) =>
    createAction(UPDATE_LOCATION_TYPE.REQUEST, { data, loading }),
  success: (res) => createAction(UPDATE_LOCATION_TYPE.SUCCESS, { res }),
  failure: () => createAction(UPDATE_LOCATION_TYPE.FAILURE),
};
export {
  LOAD_LOCATION_TYPE,
  loadLocationType,
  CREATE_LOCATION_TYPE,
  createLocationType,
  UPDATE_LOCATION_TYPE,
  updateLocationType,
  REMOVE_LISTS,
  removeLists,
};
