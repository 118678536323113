import {
  LOAD_SUMMARYLINENOTIFICATION,
} from "./actions";

const initialState = {
  alarmalertbyplatenoUrl: "",
  summaryLineNotificationLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SUMMARYLINENOTIFICATION.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        summaryLineNotificationLoading: loading
      };
    case LOAD_SUMMARYLINENOTIFICATION.SUCCESS:
      return {
        ...state,
        alarmalertbyplatenoUrl: action.payload.data.data,
        summaryLineNotificationLoading: false
      };
    default:
      return state;
  }
};
