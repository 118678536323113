import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import LangContext, { i18n } from 'modules/shared/context/langContext';
import { Typography, Row, Col, Popover, Table, Icon } from 'antd';
import { Link, withRouter } from 'react-router-dom';

import AuthorizeComponent from './../../../auth/components/AuthorizeComponent';
import { GeneralStyledContent } from '../../../../styled/common-styled';
import { loadDashcamera, removeLists, loadEmergencyCase } from '../../actions';
import '../../../../styled/old-web/alert-icon.css';

const { Text } = Typography;
const iconStyle = { fontSize: '16px' };
const columnStyle = { fontSize: '12px', fontWeight: '400' };
const unloadTimeStatus = [
	{
		title: <LangContext.Consumer>{(i18n) => i18n.unloadTimeStatus.unloadStartTime}</LangContext.Consumer>,
		dataIndex: 'date',
		width: '50%',
	},
	{
		title: <LangContext.Consumer>{(i18n) => i18n.unloadTimeStatus.duration}</LangContext.Consumer>,
		dataIndex: 'duration',
		align: 'right',
	},
	{
		title: <LangContext.Consumer>{(i18n) => i18n.unloadTimeStatus.quantity}</LangContext.Consumer>,
		dataIndex: 'qty',
		align: 'right',
	},
];

export class Index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dataActive: [],
			dataWaiting: [],
			dataLogoff: [],
			currentPage: 1,
			page: 1,
			size: 4,
		};

		this.colActive = [
			{
				title: <LangContext.Consumer>{(i18n) => i18n.t.ticket}</LangContext.Consumer>,
				dataIndex: 'trip_code',
				render: (text, record, index) => (
					<div>
						<a
							href="javascript:;"
							onClick={() => {
								let self = this;
								self.handleOpenTrip(record.trips_id);
							}}
							style={{ padding: '0px 0px 0px 0px' }}
						>
							{text}
						</a>
					</div>
				),
			},
			{
				title: <LangContext.Consumer>{(i18n) => i18n.v.vehicleCode}</LangContext.Consumer>,
				dataIndex: 'code',
				width: '8%',
				ellipsis: true,
				render: (text, record, index) => (
					<div>
						<a
							href="javascript:;"
							onClick={() => {
								let self = this;
								self.handleOpenTrackingTrip(record.trips_id);
							}}
							style={{ padding: '0px 0px 0px 0px' }}
						>
							{text}
						</a>
					</div>
				),
			},
			{
				title: <LangContext.Consumer>{(i18n) => i18n.p.plant}</LangContext.Consumer>,
				dataIndex: 'plant_code',
				width: '5%',
				ellipsis: true,
				render: (text, record, index) => (
					<div>
						<a
							href="javascript:;"
							onClick={() => {
								let self = this;
								self.handleOpenLocation(record.plant_id);
							}}
							style={{ padding: '0px 0px 0px 0px' }}
						>
							{text}
						</a>
					</div>
				),
			},
			{
				title: <LangContext.Consumer>{(i18n) => i18n.s.shipTo}</LangContext.Consumer>,
				dataIndex: 'site_name',
				width: '17%',
				ellipsis: true,
				render: (text, record, index) => (
					<div>
						<a
							href="javascript:;"
							onClick={() => {
								let self = this;
								self.handleOpenLocation(record.site_id);
							}}
							style={{ padding: '0px 0px 0px 0px' }}
						>
							{text}
						</a>
					</div>
				),
			},
			{
				title: <LangContext.Consumer>{(i18n) => i18n.d.duration}</LangContext.Consumer>,
				dataIndex: 'trip_duration',
				className: 'column-text-center',
				render(text, record, index) {
					let st = {};
					let title = '';
					let sub_title_1 = '';
					if (record.trip_duration_style !== undefined) {
						st = record.trip_duration_style;
					}
					if (text != '') {
						let array_of_text = text.split(' ');
						if (array_of_text.length == 3) {
							title = array_of_text[0] + ' ' + array_of_text[1];
							sub_title_1 = array_of_text[2];
						} else if (array_of_text.length == 2) {
							title = array_of_text[0] + ' ' + array_of_text[1];
						} else if (array_of_text.length == 1) {
							title = array_of_text[0];
						} else {
							title = text;
						}
					} else {
						title = text;
					}

					return {
						props: {
							style: { ...columnStyle, ...st, textAlign: 'center' },
						},
						children: (
							<Row type="flex" justify="center">
								<Col span={24} style={{ textAlign: 'center' }}>
									{sub_title_1 != '' ? (
										<>
											<p
												style={{
													marginTop: '8px',
													marginBottom: '0px',
												}}
											>
												{title}
											</p>
											<p
												style={{
													marginBottom: '8px',
												}}
											>
												{sub_title_1}
											</p>
										</>
									) : (
										<p
											style={{
												fontWeight: 600,
												marginTop: '8px',
												marginBottom: '0px',
											}}
										>
											{title}
										</p>
									)}
								</Col>
							</Row>
						),
					};
				},
			},
			{
				title: <LangContext.Consumer>{(i18n) => i18n.s.status}</LangContext.Consumer>,
				dataIndex: 'status_duration',
				className: 'column-text-center',
				render(text, record, index) {
					let st = {};
					if (record.status_duration_data !== undefined && record.status_duration_data.style !== undefined) {
						st = record.status_duration_data.style;
					}
					return {
						props: {
							style: { ...columnStyle, ...st, textAlign: 'center' },
						},
						children: (
							<Row type="flex" justify="center">
								{text == 'Lost Connection' ? (
									<>
										<Col span={4} style={{ marginTop: '12px' }}>
											<img
												src="img/device_gps_loss.png"
												style={{ width: '22px', height: '22px' }}
											/>
										</Col>
										<Col span={12} style={{ textAlign: 'center' }}>
											<>
												<p
													style={{
														color: 'red',
														fontWeight: 600,
														marginTop: '8px',
														marginBottom: '0px',
													}}
												>
													<LangContext.Consumer>
														{(i18n) => i18n.concreteDeliveryStatus.connectionLost}
													</LangContext.Consumer>
												</p>
												<p style={{ marginBottom: '8px' }}>{record.status_duration_time}</p>
											</>
										</Col>
									</>
								) : record.status_duration_data !== undefined &&
								  record.status_duration_data.main_status == 'distance' ? ( // ระยะทาง
									<Col span={24} style={{ textAlign: 'center' }}>
										<>
											<p
												style={{
													fontWeight: 600,
													marginTop: '8px',
													marginBottom: '0px',
												}}
											>
												<LangContext.Consumer>
													{(i18n) =>
														i18n.concreteDeliveryStatus.distance +
														' : ' +
														record.status_duration_data.duration +
														' ' +
														i18n.k.km
													}
												</LangContext.Consumer>
											</p>
											<p
												style={{
													marginBottom: '8px',
												}}
											>
												{record.status_duration_data.distance +
													' / ' +
													record.status_duration_data.act_duration}
											</p>
										</>
									</Col>
								) : record.status_duration_data !== undefined &&
								  record.status_duration_data.main_status == 'unloadDuration' ? ( // ระยะเวลาเทปูน
									<Col span={24} style={{ textAlign: 'center' }}>
										<>
											<p
												style={{
													fontWeight: 600,
													marginTop: '8px',
													marginBottom: '0px',
												}}
											>
												<LangContext.Consumer>
													{(i18n) => i18n.concreteDeliveryStatus.unloadDuration}
												</LangContext.Consumer>
											</p>
											<p
												style={{
													marginBottom: '8px',
												}}
											>
												{record.status_duration_data.duration}
											</p>
										</>
									</Col>
								) : record.status_duration_data !== undefined &&
								  record.status_duration_data.main_status == 'siteArrivalTime' ? ( // เวลาออกจากไซต์งาน
									<Col span={24} style={{ textAlign: 'center' }}>
										<p
											style={{
												fontWeight: 600,
												marginTop: '8px',
												marginBottom: '0px',
											}}
										>
											<LangContext.Consumer>
												{(i18n) => i18n.concreteDeliveryStatus.siteArrivalTime}
											</LangContext.Consumer>
										</p>
									</Col>
								) : record.status_duration_data !== undefined &&
								  record.status_duration_data.main_status == 'loadDuration' ? ( // ระยะเวลาโหลดปูน
									<Col span={24} style={{ textAlign: 'center' }}>
										<p
											style={{
												fontWeight: 600,
												marginTop: '8px',
												marginBottom: '0px',
											}}
										>
											<LangContext.Consumer>
												{(i18n) => i18n.concreteDeliveryStatus.loadDuration}
											</LangContext.Consumer>
										</p>
										<p
											style={{
												marginBottom: '8px',
											}}
										>
											{record.status_duration_data.duration}
										</p>
									</Col>
								) : record.status_duration_data !== undefined &&
								  record.status_duration_data.main_status == 'waitingforLoading' ? ( // กำลังรอโหลด
									<Col span={24} style={{ textAlign: 'center' }}>
										<p
											style={{
												fontWeight: 600,
												marginTop: '8px',
												marginBottom: '0px',
											}}
										>
											<LangContext.Consumer>
												{(i18n) => i18n.concreteDeliveryStatus.waitingForLoading}
											</LangContext.Consumer>
										</p>
										<p
											style={{
												marginBottom: '8px',
											}}
										>
											{record.status_duration_data.duration}
										</p>
									</Col>
								) : record.status_duration_data !== undefined &&
								  record.status_duration_data.main_status == 'waitDurationBeforeUnload' ? ( // ระยะเวลาก่อนเริ่มเทปูน
									<Col span={24} style={{ textAlign: 'center' }}>
										<p
											style={{
												fontWeight: 600,
												marginTop: '8px',
												marginBottom: '0px',
											}}
										>
											<LangContext.Consumer>
												{(i18n) => i18n.concreteDeliveryStatus.waitDurationBeforeUnload}
											</LangContext.Consumer>
										</p>
										<p
											style={{
												marginBottom: '8px',
											}}
										>
											{record.status_duration_data.duration}
										</p>
									</Col>
								) : record.status_duration_data !== undefined &&
								  record.status_duration_data.main_status == 'loadFinishTime' ? ( // จบโหลดปูน
									<Col span={24} style={{ textAlign: 'center' }}>
										<p
											style={{
												fontWeight: 600,
												marginTop: '8px',
												marginBottom: '0px',
											}}
										>
											<LangContext.Consumer>
												{(i18n) => i18n.concreteDeliveryStatus.loadFinishTime}
											</LangContext.Consumer>
										</p>
									</Col>
								) : record.status_duration_data !== undefined &&
								  record.status_duration_data.main_status == 'truckWashTime' ? ( // เวลาล้างรถ
									<Col span={24} style={{ textAlign: 'center' }}>
										<p
											style={{
												fontWeight: 600,
												marginTop: '8px',
												marginBottom: '0px',
											}}
										>
											<LangContext.Consumer>
												{(i18n) => i18n.concreteDeliveryStatus.truckWashTime}
											</LangContext.Consumer>
										</p>
									</Col>
								) : record.status_duration_data !== undefined &&
								  record.status_duration_data.main_status == 'plantDepartureTime' ? ( // เวลาออกจากแพลนต์
									<Col span={24} style={{ textAlign: 'center' }}>
										<p
											style={{
												fontWeight: 600,
												marginTop: '8px',
												marginBottom: '0px',
											}}
										>
											<LangContext.Consumer>
												{(i18n) => i18n.concreteDeliveryStatus.plantDepartureTime}
											</LangContext.Consumer>
										</p>
									</Col>
								) : record.status_duration_data !== undefined &&
								  record.status_duration_data.main_status == 'siteDepartureTime' ? ( // จบเทปูน
									<Col span={24} style={{ textAlign: 'center' }}>
										<p
											style={{
												fontWeight: 600,
												marginTop: '8px',
												marginBottom: '0px',
											}}
										>
											<LangContext.Consumer>
												{(i18n) => i18n.concreteDeliveryStatus.siteDepartureTime}
											</LangContext.Consumer>
										</p>
									</Col>
								) : record.status_duration_data !== undefined &&
								  record.status_duration_data.main_status == 'noSiteEntry' ? ( // ไม่เข้าไซต์ลูกค้า
									<Col span={24} style={{ textAlign: 'center' }}>
										<p
											style={{
												fontWeight: 600,
												marginTop: '8px',
												marginBottom: '0px',
											}}
										>
											<LangContext.Consumer>
												{(i18n) => i18n.concreteDeliveryStatus.noSiteEntry}
											</LangContext.Consumer>
										</p>
									</Col>
								) : record.status_duration_data !== undefined &&
								  record.status_duration_data.main_status == 'dischargeNotDetected' ? ( // ไม่พบการเทปูนออก
									<Col span={24} style={{ textAlign: 'center' }}>
										<p
											style={{
												fontWeight: 600,
												marginTop: '8px',
												marginBottom: '0px',
											}}
										>
											<LangContext.Consumer>
												{(i18n) => i18n.concreteDeliveryStatus.dischargeNotDetected}
											</LangContext.Consumer>
										</p>
									</Col>
								) : record.status_duration_data !== undefined &&
								  record.status_duration_data.main_status == 'noSiteDeparture' ? ( // ไม่ออกจากไซต์ลูกค้า
									<Col span={24} style={{ textAlign: 'center' }}>
										<p
											style={{
												fontWeight: 600,
												marginTop: '8px',
												marginBottom: '0px',
											}}
										>
											<LangContext.Consumer>
												{(i18n) => i18n.concreteDeliveryStatus.noSiteDeparture}
											</LangContext.Consumer>
										</p>
									</Col>
								) : record.status_duration_data !== undefined &&
								  record.status_duration_data.main_status == 'noReturnTrip' ? ( // ไม่กลับเข้าแพล้นต์
									<Col span={24} style={{ textAlign: 'center' }}>
										<p
											style={{
												fontWeight: 600,
												marginTop: '8px',
												marginBottom: '0px',
											}}
										>
											<LangContext.Consumer>
												{(i18n) => i18n.concreteDeliveryStatus.noReturnTrip}
											</LangContext.Consumer>
										</p>
									</Col>
								) : (
									<Col span={24} style={{ marginTop: '12px' }}>
										{text}
									</Col>
								)}
							</Row>
						),
					};
				},
			},
			{
				title: '',
				dataIndex: 'status_image_1',
				width: '6%',
				render(text, record, index) {
					return {
						children:
							text['desc_1'] != '' && text['desc_2'] != '' ? (
								<Popover
									placement="left"
									content={
										<LangContext.Consumer>
											{(i18n) => (
												<div>
													<p>
														{i18n.loadTimeStatus[text['desc_1']] +
															' : ' +
															text['desc_msg_1']}
													</p>
													<p>
														{i18n.loadTimeStatus[text['desc_2']] +
															' : ' +
															text['desc_msg_2']}
													</p>
													<p>
														{text['desc_3'] != ''
															? i18n.loadTimeStatus[text['desc_3']] +
															  ' : ' +
															  text['desc_msg_3']
															: ''}
													</p>
												</div>
											)}
										</LangContext.Consumer>
									}
								>
									<div className={text['icon']}></div>
								</Popover>
							) : (
								<div className={text['icon']}></div>
							),
					};
				},
			},
			{
				title: '',
				dataIndex: 'status_image_2',
				width: '6%',
				render(text, record, index) {
					return {
						children:
							text['desc_1'] != '' ? (
								<Popover
									placement="left"
									content={
										<LangContext.Consumer>
											{(i18n) => (
												<div>
													<p>
														{text['desc_1'] != ''
															? i18n.plantMoveOutStatus[text['desc_1']] +
															  ' : ' +
															  text['desc_msg_1']
															: ''}
													</p>
												</div>
											)}
										</LangContext.Consumer>
									}
								>
									<div className={text['icon']}></div>
								</Popover>
							) : (
								<div className={text['icon']}></div>
							),
					};
				},
			},
			{
				title: '',
				dataIndex: 'status_image_3',
				width: '6%',
				render(text, record, index) {
					return {
						children:
							text['desc_1'] != '' && text['desc_1'] != '' ? (
								<Popover
									placement="left"
									content={
										<LangContext.Consumer>
											{(i18n) => (
												<div>
													<p>
														{text['desc_1'] != ''
															? i18n.onTheWayStatus[text['desc_1']] + ' : ' + text['msg']
															: ''}
													</p>
												</div>
											)}
										</LangContext.Consumer>
									}
								>
									<div className={text['icon']}></div>
								</Popover>
							) : (
								<div className={text['icon']}></div>
							),
					};
				},
			},
			{
				title: '',
				dataIndex: 'status_image_4',
				width: '6%',
				render(text, record, index) {
					return {
						children:
							text['desc_1'] != '' && text['desc_2'] != '' ? (
								<Popover
									placement="left"
									content={
										<LangContext.Consumer>
											{(i18n) => (
												<div>
													<p>
														{text['desc_1'] != ''
															? i18n.custMoveInStatus[text['desc_1']] +
															  ' : ' +
															  text['desc_msg_1']
															: ''}
													</p>
													<p>
														{text['desc_2'] != ''
															? i18n.custMoveInStatus[text['desc_2']] +
															  ' : ' +
															  text['desc_msg_2']
															: ''}
													</p>
												</div>
											)}
										</LangContext.Consumer>
									}
								>
									<div className={text['icon']}></div>
								</Popover>
							) : (
								<div className={text['icon']}></div>
							),
					};
				},
			},
			{
				title: '',
				dataIndex: 'status_image_5',
				width: '6%',
				render(text, record, index) {
					let sdrId = '';
					if (text.load_out_side) {
						sdrId = text.unload_info.sdrId;
					}
					return {
						children:
							text['desc_1'] != '' ? (
								text.load_out_side ? (
									<Link to={`/concrete_delivery/activity_outside_area/${sdrId}`}>
										{' '}
										<Popover
											placement="leftTop"
											content={
												<div>
													{typeof text['desc_1'] == 'object' ? (
														<Table
															size="small"
															className="tracking-table"
															dataSource={text['desc_2']}
															columns={unloadTimeStatus}
															pagination={false}
															style={{ width: `280px` }}
															scroll={{ y: 380 }}
														></Table>
													) : (
														<div>
															<p>
																{text['desc_1'] != '' ? (
																	<LangContext.Consumer>
																		{(i18n) =>
																			i18n.unloadTimeStatus[text['desc_1']] +
																			' : ' +
																			text['desc_msg_1']
																		}
																	</LangContext.Consumer>
																) : (
																	''
																)}
															</p>
															<p>
																{text['desc_2'] != '' ? (
																	<LangContext.Consumer>
																		{(i18n) =>
																			i18n.unloadTimeStatus[text['desc_2']] +
																			' : ' +
																			text['desc_msg_2']
																		}
																	</LangContext.Consumer>
																) : (
																	''
																)}
															</p>
														</div>
													)}

													<p>
														{text['desc_3'] != '' ? (
															<LangContext.Consumer>
																{(i18n) =>
																	i18n.unloadTimeStatus[text['desc_3']] +
																	' : ' +
																	text['desc_msg_3']
																}
															</LangContext.Consumer>
														) : (
															''
														)}
													</p>
													<Text type="danger">
														{text['desc_4'] != '' ? (
															<LangContext.Consumer>
																{(i18n) => i18n.unloadTimeStatus[text['desc_4']]}
															</LangContext.Consumer>
														) : (
															''
														)}
													</Text>
												</div>
											}
										>
											<div className="unload-progress">
												<div className={text['icon']}></div>
												{typeof text['load_out_side'] != 'undefined' && text['load_out_side'] && (
													<div className="alerticon">
														<img
															src="/img/sign/device_gps_loss.png"
															height="15"
															width="16"
															alt="!"
														/>
													</div>
												)}
												{typeof text['percentage'] != 'undefined' && (
													<div className="progressbar">
														<div
															className="percentage"
															style={{ width: text['percentage'] + '%' }}
														/>
													</div>
												)}
											</div>
										</Popover>
									</Link>
								) : (
									<Popover
										placement="leftTop"
										content={
											<div>
												{typeof text['desc_1'] == 'object' ? (
													<Table
														size="small"
														className="tracking-table"
														dataSource={text['desc_2']}
														columns={unloadTimeStatus}
														pagination={false}
														style={{ width: `280px` }}
														scroll={{ y: 380 }}
													></Table>
												) : (
													<div>
														<p>
															{text['desc_1'] != '' ? (
																<LangContext.Consumer>
																	{(i18n) =>
																		i18n.unloadTimeStatus[text['desc_1']] +
																		' : ' +
																		text['desc_msg_1']
																	}
																</LangContext.Consumer>
															) : (
																''
															)}
														</p>
														<p>
															{text['desc_2'] != '' ? (
																<LangContext.Consumer>
																	{(i18n) =>
																		i18n.unloadTimeStatus[text['desc_2']] +
																		' : ' +
																		text['desc_msg_2']
																	}
																</LangContext.Consumer>
															) : (
																''
															)}
														</p>
													</div>
												)}

												<p>
													{text['desc_3'] != '' ? (
														<LangContext.Consumer>
															{(i18n) =>
																i18n.unloadTimeStatus[text['desc_3']] +
																' : ' +
																text['desc_msg_3']
															}
														</LangContext.Consumer>
													) : (
														''
													)}
												</p>
												<Text type="danger">
													{text['desc_4'] != '' ? (
														<LangContext.Consumer>
															{(i18n) => i18n.unloadTimeStatus[text['desc_4']]}
														</LangContext.Consumer>
													) : (
														''
													)}
												</Text>
											</div>
										}
									>
										<div className="unload-progress">
											<div className={text['icon']}></div>
											{typeof text['load_out_side'] != 'undefined' && text['load_out_side'] && (
												<div className="alerticon">
													<img
														src="/img/sign/device_gps_loss.png"
														height="15"
														width="16"
														alt="!"
													/>
												</div>
											)}
											{typeof text['percentage'] != 'undefined' && (
												<div className="progressbar">
													<div
														className="percentage"
														style={{ width: text['percentage'] + '%' }}
													/>
												</div>
											)}
										</div>
									</Popover>
								)
							) : (
								<Link to={`/concrete_delivery/activity_outside_area/${sdrId}`}>
									<div className={text['icon']}></div>
								</Link>
							),
					};
				},
			},
			{
				title: '',
				dataIndex: 'status_image_6',
				width: '6%',
				render(text, record, index) {
					return {
						children:
							text['desc_1'] != '' && text['desc_2'] != '' ? (
								<Popover
									placement="left"
									content={
										<div>
											<p>
												{text['desc_1'] != '' ? (
													<LangContext.Consumer>
														{(i18n) =>
															i18n.custMoveOutStatus[text['desc_1']] +
															' : ' +
															text['desc_msg_1']
														}
													</LangContext.Consumer>
												) : (
													''
												)}
											</p>
											<p>
												{text['desc_2'] != '' ? (
													<LangContext.Consumer>
														{(i18n) =>
															i18n.custMoveOutStatus[text['desc_2']] +
															' : ' +
															text['desc_msg_2']
														}
													</LangContext.Consumer>
												) : (
													''
												)}
											</p>
										</div>
									}
								>
									<div className={text['icon']}></div>
								</Popover>
							) : (
								<div className={text['icon']}></div>
							),
					};
				},
			},
		];

		this.colLogoff = [
			{
				title: <LangContext.Consumer>{(i18n) => i18n.e.engine}</LangContext.Consumer>,
				dataIndex: 'engine_on',
				className: 'column-text-center',
				render(text, record, index) {
					return {
						children: text ? (
							<Icon style={iconStyle} type="car" theme="twoTone" twoToneColor={'#00BF06'} />
						) : (
							<Icon style={iconStyle} type="car" theme="twoTone" twoToneColor={'#c91c1c'} />
						),
					};
				},
			},
			{
				title: <LangContext.Consumer>{(i18n) => i18n.v.vehicleCode}</LangContext.Consumer>,
				dataIndex: 'code',
			},
			{
				title: <LangContext.Consumer>{(i18n) => i18n.p.plant}</LangContext.Consumer>,
				dataIndex: 'plant',
			},
			{
				title: <LangContext.Consumer>{(i18n) => i18n.j.jobStartAt}</LangContext.Consumer>,
				dataIndex: 'last_logoff',
			},
			{
				title: <LangContext.Consumer>{(i18n) => i18n.p.plantArrivingTime}</LangContext.Consumer>,
				dataIndex: 'plant_move_in_at',
			},
			{
				title: '',
				dataIndex: 'status_image',
				render(text, record, index) {
					return {
						children: <div className={text}></div>,
					};
				},
			},
		];

		this.colWaiting = [
			{
				title: <LangContext.Consumer>{(i18n) => i18n.e.engine}</LangContext.Consumer>,
				dataIndex: 'engine',
				className: 'column-text-center',
				render(text, record, index) {
					return {
						children: text ? (
							<Icon style={iconStyle} type="car" theme="twoTone" twoToneColor={'#00BF06'} />
						) : (
							<Icon style={iconStyle} type="car" theme="twoTone" twoToneColor={'#c91c1c'} />
						),
					};
				},
			},
			{
				title: <LangContext.Consumer>{(i18n) => i18n.v.vehicleCode}</LangContext.Consumer>,
				dataIndex: 'code',
			},
			{
				title: <LangContext.Consumer>{(i18n) => i18n.p.plant}</LangContext.Consumer>,
				dataIndex: 'plant',
			},
			{
				title: <LangContext.Consumer>{(i18n) => i18n.j.jobStartAt}</LangContext.Consumer>,
				dataIndex: 'last_logoff',
			},
			{
				title: <LangContext.Consumer>{(i18n) => i18n.p.plantArrivingTime}</LangContext.Consumer>,
				dataIndex: 'plant_move_in_at',
			},
			{
				title: '',
				dataIndex: 'status_image',
				render(text, record, index) {
					return {
						children: <div className={text}></div>,
					};
				},
			},
		];
	}

	loadData = () => {
		let self = this;

		//fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getconcretefeed`, {
		fetch(`http://login.terminusfleet.com/rmc/feed/getfeed`, {
			method: 'POST',
			mode: 'cors',
			headers: {
				// Accept: "application/json",
				'Content-Type': 'application/json',
				Authorization: `Bearer ${self.props.auth.accessToken}`,
			},
			body: JSON.stringify({
				ms: self.props.profile.id,
				plant: self.props.profile.company_id,
				vehicle_type: self.props.profile.vehicle_visibility,
				location_group_id: self.props.profile.location_visibility,
				status: 'active',
				sortby: 'sdr.sdr_trip_code DESC',
				page: 1,
				start_date: '04/03/2019',
				end_date: '04/03/2019',
				shipto_selected: '',
				filter_date_type_select: 'Today',
				itemid: '163',
			}),
			// body: JSON.stringify({
			//   user_id: self.props.profile.id,
			//   company_id: self.props.profile.company_id,
			//   vehicle_visibility: self.props.profile.vehicle_visibility,
			//   location_visibility: self.props.profile.location_visibility,
			//   status: "active",
			//   vehicle_type: "All",
			//   plant_name: "All",
			//   location_group_id: "All",
			// }),
		})
			.then((response) => response.json())
			.then((res) => {
				self.setState({
					dataActive: JSON.parse(res.active),
					dataWaiting: JSON.parse(res.waiting),
					dataLogoff: JSON.parse(res.logoff),
				});
			})
			.catch(function() {});
	};

	loadDataNew = () => {
		let self = this;

		axios
			.post(
				`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getconcretefeed`,
				{
					// for prd
					user_id: self.props.profile.id,
					company_id: self.props.profile.company_id,
					vehicle_visibility: self.props.profile.vehicle_visibility,
					location_visibility: self.props.profile.location_visibility,
					status: 'active',
					vehicle_type: 'All',
					plant_name: 'All',
					location_group_id: 'All',

					// for test
					// user_id: 1935,
					// company_id: 518,
					// vehicle_visibility:
					// 	'22068,22069,22070,22071,23357,23358,23379,23400,23401,23402,23403,23404,23405,23406,23408,23409,23410,23411,23413,23414,23415,23416,23417,23418,23419,23420,23421,23422,23434,23435,24283,24304,24305,24306,24307,24308,24312,24313,24468,24469,24470,24471,24472,24474,24475,24476,24477,24478,24950,24952,24953,24954,24955,24956,24958,24959,24960,24961,24962,24963,24964,24965,24966,24994,25013,25014,25016,25018,25019,25021,25023,25024,25091,25092,25093,25094,25095,25096,25097,25098,25099,25100,25114,25115,25116,25117,25344,25347,25348,25349,25350,25351,25352,25353,25354,25355,25358,26310,26311,26312,26322,26323,28420,28421,28422,37592,37715,37716,37717,37718,38296,38297,38450,38451,39421,39422,40294,40295,40296,40411,40412,40413,40414,40415,40416,40417,40418,40419,40441,40442,40443,40444,40446,40447,42573,42579,42580,42604,42605,42606,42607,43548,44042,44043,44711,45030,45031,45032,45058,45059,45153,45515,45685,45686,45965,45967,45968,45969,45992,46032,46055,46058,46194,46311,46312,46313,46586,46689',
					// location_visibility:
					// 	'78227,442937,442938,442939,442941,442942,442943,442944,442945,442946,442947,442949,442950,451093,451155,451158,451163,451164,451173,451177,451196,451197,451555,451556,451557,451558,451559,451560,451562,451563,451564,451565,451566,451567,451568,451569,451570,451577,451579,451580,451596,451603,452403,452420,452425,452489,452500,452517,452518,452519,452520,452521,452522,452523,452524,452525,452526,452531,452850,452857,500268,500269,500270,500271,500272,500273,500274,500473,502020,502046,502048,502069,502070,502071,502072,502073,502074,502098,502102,502105,502107,502113,502151,502155,502164,502208,502209,502215,502220,502222,502227,502336,502339,502343,502344,502846,502863,502870,502878,502898,502914,502916,502936,502938,502998,503012,503031,503056,503080,503082,503139,503145,503168,503193,503207,503212,503216,503323,503389,503401,503499,503509,503514,503517,503520,503540,503589,503604,503619,503625,503635,503637,503638,503719,503720,503777,503788,503927,504292,504295,504502,504522,504530,504567,504585,504773,504',
					// status: 'active',
					// vehicle_type: 'All',
					// plant_name: 'All',
					// location_group_id: 'All',
				},
				{
					headers: { Authorization: `Bearer ${self.props.auth.accessToken}` },
				}
			)
			.then(function(response) {
				self.setState({
					dataActive: response.data.active,
					dataWaiting: response.data.waiting,
					dataLogoff: response.data.logoff,
				});
			})
			.catch(function(error) {
				console.log(error);
			});
	};

	componentDidMount() {
		// this.loadData();
		this.loadDataNew();
		// this.loadConcreteData = setInterval(this.loadData, 1000 * 30);
	}

	componentWillUnmount() {
		clearInterval(this.loadConcreteData);
	}

	handleOpenTrackingTrip = (trip_id) => {
		window.open(`/tracking/trips/${trip_id}`);
	};

	handleOpenTrip = (trip_id) => {
		window.open(`/trip/detail/${trip_id}`);
	};

	handleOpenLocation = (location_id) => {
		window.open(`/location/edit/${location_id}`);
	};

	render() {
		return (
			<AuthorizeComponent matching_name="concrete_dashboard">
				<GeneralStyledContent>
					<Row>
						<Col span={24} style={{ marginBottom: '20px' }}>
							<h2>
								{' '}
								<LangContext.Consumer>{(i18n) => i18n.o.onProcess}</LangContext.Consumer>
							</h2>
							<Table
								rowKey={(record, i) => record.trips_id + '_' + i}
								className="tracking-table"
								columns={this.colActive}
								dataSource={this.state.dataActive}
								size="small"
								pagination={false}
							/>
						</Col>
						<Col span={12}>
							<h2>
								{' '}
								<LangContext.Consumer>{(i18n) => i18n.w.waitingForJob}</LangContext.Consumer>
							</h2>
							<Table
								rowKey={(record, i) => record.code + '_' + i}
								className="tracking-table"
								columns={this.colWaiting}
								dataSource={this.state.dataWaiting}
								size="small"
								pagination={false}
								scroll={{ y: 400 }}
							/>
						</Col>
						<Col span={12}>
							<h2>
								<LangContext.Consumer>{(i18n) => i18n.n.notReadyForWork}</LangContext.Consumer>
							</h2>
							<Table
								rowKey={(record, i) => record.code + '_' + i}
								className="tracking-table"
								columns={this.colLogoff}
								dataSource={this.state.dataLogoff}
								size="small"
								pagination={false}
								scroll={{ y: 400 }}
							/>
						</Col>
					</Row>
				</GeneralStyledContent>
			</AuthorizeComponent>
		);
	}
}

export default withRouter(
	connect(
		({ monitoringState, auth, auth: { profile } }) => ({
			monitoringState,
			auth,
			profile,
		}),
		{
			loadDashcamera: loadDashcamera.request,
			removeLists,
			loadEmergencyCase: loadEmergencyCase.request,
		}
	)(Index)
);
