import { createTypes, createAction } from "lib/action";

const LOAD_DRIVERINFORMATION = createTypes("driverinformation", "load");
const LOAD_DRIVER_DRIVERINFORMATION = createTypes("driver_driverinformation", "load");

const loadDriverinformation = {
  request: (data, loading) =>
    createAction(LOAD_DRIVERINFORMATION.REQUEST, { data, loading }),
  success: data => createAction(LOAD_DRIVERINFORMATION.SUCCESS, { data }),
  failure: () => createAction(LOAD_DRIVERINFORMATION.FAILURE)
};

const loadDriver = {
  request: () => createAction(LOAD_DRIVER_DRIVERINFORMATION.REQUEST, {}),
  success: data => createAction(LOAD_DRIVER_DRIVERINFORMATION.SUCCESS, { data }),
  failure: () => createAction(LOAD_DRIVER_DRIVERINFORMATION.FAILURE)
};

export {
  loadDriverinformation,
  LOAD_DRIVERINFORMATION,
  loadDriver,
  LOAD_DRIVER_DRIVERINFORMATION
};
