import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import history from "../../../../../lib/history";
import { Formik } from "formik";
import * as yup from "yup";
import * as moment from "moment";
import { debounce } from "debounce";
import {
  Row,
  Col,
  Avatar,
  Icon,
  Button,
  Divider,
  DatePicker,
  Checkbox,
  Form,
  Input,
  Select,
  Spin,
  Switch,
  Tooltip,
  Skeleton,
  Slider,
  message,
  TimePicker
} from "antd";
import { Link, BrowserRouter as Router, withRouter } from "react-router-dom";

import LangContext, { i18n } from "modules/shared/context/langContext";
import { autoGenKey } from "../../../../../lib/helper";
import ConditionForm from "./ConditionForm";
import FormDivider from "../../../../shared/components/FormDivider";

const durationMarks = {
  // 20: "20 min",
  30: "30m",
  60: "1hr",
  120: "2hr",
  180: "3hr",
  240: "4hr",
  300: "5hr",
  360: "6hr",
  420: "7hr",
  480: "8hr"
};

const durationZoomMarks = {
  // 20: "20 min",
  1: "1 sec",
  15: "15 sec",
  30: "30 sec",
  45: "45 sec",
  55: "55 sec"
};

const InputGroup = Input.Group;
const { Item } = Form;
const FormItem = Item;
const Option = Select.Option;

class Step1Form extends Component {
  state = {
    conditionList: [],
    zoneList: []
  };

  componentDidMount() {
    let self = this;
    fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/dropdownlist/getzone`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.auth.accessToken}`
        },
        body: JSON.stringify({
          company_id: this.props.auth.profile.company_id
        })
      }
    )
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        self.setState({ zoneList: data });
      });
  }

  render() {
    // console.log(this.props.initialValues);

    const { conditionList } = this.props.initialValues;
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{ ...this.props.initialValues }}
        validationSchema={yup.lazy(value => {
          switch (value.policy_type) {
            case "maintenance":
              return yup.object().shape({
                policy_name: yup.string().required()
                // policy_type: yup.string().required()
                // policy_sub_type: yup.string().required(),
                // category: yup.string().required()
              });
              break;

            default:
              return yup.object().shape({
                policy_name: yup.string().required()
                // policy_type: yup.string().required(),
                // policy_sub_type: yup.string().required(),
                // category: yup.string().required()
              });
              break;
          }
        })}
        onSubmit={(values, actions) => {
          // console.log(values, this.state.conditionList);

          this.props.setInitValue({
            ...this.props.initialValues,
            ...values,
            policy_name: values.policy_name,
            conditionList: conditionList.map(ele => ({
              ...ele.initialValues
            }))
          });

          this.props.next(this.props.current);
        }}
        render={({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <LangContext.Consumer>
            {i18n => {
              if (
                this.props.action == "create" ||
                (this.props.action == "edit" &&
                  values.policy_id != null &&
                  typeof this.props.initialValues != "undefined")
              )
                return (
                  <Form onSubmit={handleSubmit}>
                    <FormDivider>
                      <LangContext.Consumer>
                        {i18n => i18n.p.policy}
                      </LangContext.Consumer>
                    </FormDivider>

                    <Row>
                      <Col xs={24} sm={18} md={13} lg={13} xl={10}>
                        <FormItem
                          label={
                            <LangContext.Consumer>
                              {i18n => i18n.n.name}
                            </LangContext.Consumer>
                          }
                          required={true}
                          validateStatus={
                            touched.policy_name && errors.policy_name
                              ? "warning"
                              : undefined
                          }
                          help={
                            touched.policy_name && errors.policy_name
                              ? errors.policy_name.substr(
                                  errors.policy_name.indexOf(" ") + 1
                                )
                              : undefined
                          }
                        >
                          <InputGroup compact>
                            <Input
                              style={{ width: "100%" }}
                              name="policy_name"
                              value={values.policy_name}
                              onChange={e => {
                                setFieldValue("policy_name", e.target.value);
                              }}
                              onBlur={handleBlur}
                            />
                          </InputGroup>
                        </FormItem>
                      </Col>
                    </Row>
                    <FormDivider>
                      <LangContext.Consumer>
                        {i18n => i18n.c.criteria}
                      </LangContext.Consumer>
                    </FormDivider>

                    <Row>
                      <Spin spinning={this.props.conditionLoading}>
                        {conditionList.map(ele => (
                          <ConditionForm
                            {...ele}
                            formId={
                              typeof ele.key == "undefined"
                                ? autoGenKey("condition")
                                : ele.key
                            }
                            jsonDetailSkeleton={this.props.jsonDetailSkeleton}
                            handlePropsChange={this.props.handlePropsChange}
                            handlePolicyTypeChange={
                              this.props.handlePolicyTypeChange
                            }
                            handleInputChange={this.props.handleInputChange}
                            removeCondition={this.props.removeCondition}
                            conditionList={conditionList}
                            has_duration_type={this.props.has_duration_type}
                            has_speed_limit_type={
                              this.props.has_speed_limit_type
                            }
                            has_day_time_type={this.props.has_day_time_type}
                            zoneList={this.state.zoneList}
                          />
                        ))}
                      </Spin>
                    </Row>
                    <Row>
                      <Col
                        span={20}
                        align="center"
                        style={{ margin: "40px 0px 0px 0px" }}
                      >
                        <Button
                          type="primary"
                          shape="round"
                          icon="plus"
                          onClick={this.props.handleAddCondition}
                        >
                          Add Condition
                        </Button>
                      </Col>
                    </Row>
                    {conditionList.length > 0 && (
                      <Fragment>
                        <FormDivider>
                          <LangContext.Consumer>
                            {i18n => "ระยะเวลา"}
                          </LangContext.Consumer>
                        </FormDivider>

                        <Row style={{ margin: "40px 0px 0px 0px" }}>
                          <Col span={4}>
                            <Form.Item label={"ทริกเกอร์เมื่อ"}>
                              <Switch
                                name="duration_trigger_inside_range"
                                checkedChildren="อยู่ในช่วง"
                                unCheckedChildren="อยู่นอกช่วง"
                                defaultChecked
                                value={values.duration_trigger_inside_range}
                                onChange={e => {
                                  // this.setState({ duration_trigger_inside_range: e })
                                  setFieldValue(
                                    "duration_trigger_inside_range",
                                    e
                                  );
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={14} offset={1}>
                            <Form.Item
                              label={
                                <Fragment>
                                  <Button
                                    size="small"
                                    shape="circle"
                                    icon={
                                      values.duration_unit == "minute"
                                        ? "arrows-alt"
                                        : "shrink"
                                    }
                                    onClick={e => {
                                      // this.setState({
                                      //   duration_unit:
                                      //     this.state.duration_unit == "minute"
                                      //       ? "second"
                                      //       : "minute"
                                      // });
                                      setFieldValue(
                                        "duration_unit",
                                        values.duration_unit == "minute"
                                          ? "second"
                                          : "minute"
                                      );
                                    }}
                                  />
                                  {"  "}
                                  ระยะเวลา
                                </Fragment>
                              }
                            >
                              <Slider
                                marks={
                                  values.duration_unit == "minute"
                                    ? durationMarks
                                    : durationZoomMarks
                                }
                                range={true}
                                onChange={e => {
                                  values.duration_unit == "minute"
                                    ? setFieldValue("duration_minute_range", e)
                                    : setFieldValue("duration_second_range", e);
                                }}
                                defaultValue={
                                  values.duration_unit == "minute"
                                    ? values.duration_minute_range
                                    : values.duration_second_range
                                }
                                value={
                                  values.duration_unit == "minute"
                                    ? values.duration_minute_range
                                    : values.duration_second_range
                                }
                                max={
                                  values.duration_unit == "minute" ? 540 : 59
                                }
                                min={1}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item label="จะเริ่มนับระยะเวลาใหม่เมื่อเกิดเหตุการณ์">
                              <Select
                                key={autoGenKey("condition-select")}
                                name="recount_duration"
                                defaultValue={"-"}
                                value={values.recount_duration.toString()}
                                onChange={e => {
                                  setFieldValue("recount_duration", e);
                                }}
                                disabled={values.duration_unit == "second"}
                              >
                                <Option key="-">
                                  <LangContext.Consumer>
                                    {i18n => "-"}
                                  </LangContext.Consumer>
                                </Option>
                                <Option
                                  key="rest-15-min"
                                  disabled={
                                    values.duration_unit == "second" ||
                                    values.duration_minute_range[1] <= 15
                                  }
                                >
                                  <LangContext.Consumer>
                                    {i18n => "จอดพาหนะมากกว่า 15 นาที"}
                                  </LangContext.Consumer>
                                </Option>
                                <Option
                                  key="rest-30-min"
                                  disabled={
                                    values.duration_unit == "second" ||
                                    values.duration_minute_range[1] <= 30
                                  }
                                >
                                  <LangContext.Consumer>
                                    {i18n => "จอดพาหนะมากกว่า 30 นาที"}
                                  </LangContext.Consumer>
                                </Option>
                                <Option
                                  key="rest-1-hour"
                                  disabled={
                                    values.duration_unit == "second" ||
                                    values.duration_minute_range[1] <= 60
                                  }
                                >
                                  <LangContext.Consumer>
                                    {i18n => "จอดพาหนะมากกว่า 1 ชั่วโมง"}
                                  </LangContext.Consumer>
                                </Option>
                                <Option
                                  key="rest-2-hour"
                                  disabled={
                                    values.duration_unit == "second" ||
                                    values.duration_minute_range[1] <= 120
                                  }
                                >
                                  <LangContext.Consumer>
                                    {i18n => "จอดพาหนะมากกว่า 2 ชั่วโมง"}
                                  </LangContext.Consumer>
                                </Option>
                                <Option
                                  key="rest-4-hour"
                                  disabled={
                                    values.duration_unit == "second" ||
                                    values.duration_minute_range[1] <= 120
                                  }
                                >
                                  <LangContext.Consumer>
                                    {i18n => "จอดพาหนะมากกว่า 4 ชั่วโมง"}
                                  </LangContext.Consumer>
                                </Option>
                                <Option
                                  key="rest-8-hour"
                                  disabled={
                                    values.duration_unit == "second" ||
                                    values.duration_minute_range[1] <= 120
                                  }
                                >
                                  <LangContext.Consumer>
                                    {i18n => "จอดพาหนะมากกว่า 8 ชั่วโมง"}
                                  </LangContext.Consumer>
                                </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>

                        <FormDivider>
                          <LangContext.Consumer>
                            {i18n => "วัน/เวลา"}
                          </LangContext.Consumer>
                        </FormDivider>

                        <Row style={{ margin: "40px 0px 0px 0px" }}>
                          <Col span={24}>
                            <Form.Item
                              label={
                                <LangContext.Consumer>
                                  {i18n => "วันที่อนุญาต"}
                                </LangContext.Consumer>
                              }
                            >
                              <Checkbox
                                name="allow_monday"
                                defaultChecked={values.allow_monday}
                                // checked={values.plant}
                                onChange={e => {
                                  setFieldValue(
                                    "allow_monday",
                                    e.target.checked
                                  );
                                  // setFieldValue("plant", e.target.checked);
                                  // this.props.setInitValue({
                                  //   ...this.props.initialValues,
                                  //   plant: e.target.checked
                                  // });
                                }}
                              >
                                จันทร์
                              </Checkbox>

                              <Checkbox
                                name="allow_tuesday"
                                defaultChecked={values.allow_tuesday}
                                // checked={values.site}
                                onChange={e => {
                                  setFieldValue(
                                    "allow_tuesday",
                                    e.target.checked
                                  );
                                }}
                              >
                                อังคาร
                              </Checkbox>

                              <Checkbox
                                name="allow_wendnesday"
                                defaultChecked={values.allow_wendnesday}
                                // checked={values.parkingLot}
                                onChange={e => {
                                  setFieldValue(
                                    "allow_wendnesday",
                                    e.target.checked
                                  );
                                }}
                              >
                                พุธ
                              </Checkbox>

                              <Checkbox
                                name="allow_thursday"
                                defaultChecked={values.allow_thursday}
                                // checked={values.restArea}
                                onChange={e => {
                                  setFieldValue(
                                    "allow_thursday",
                                    e.target.checked
                                  );
                                }}
                              >
                                พฤหัสบดี
                              </Checkbox>

                              <Checkbox
                                name="allow_friday"
                                defaultChecked={values.allow_friday}
                                // checked={values.gasStation}
                                onChange={e => {
                                  setFieldValue(
                                    "allow_friday",
                                    e.target.checked
                                  );
                                }}
                              >
                                ศุกร์
                              </Checkbox>

                              <Checkbox
                                name="allow_saturday"
                                defaultChecked={values.allow_saturday}
                                // checked={values.carService}
                                onChange={e => {
                                  setFieldValue(
                                    "allow_saturday",
                                    e.target.checked
                                  );
                                }}
                              >
                                เสาร์
                              </Checkbox>

                              <Checkbox
                                name="allow_sunday"
                                defaultChecked={values.allow_sunday}
                                // checked={values.carService}
                                onChange={e => {
                                  setFieldValue(
                                    "allow_sunday",
                                    e.target.checked
                                  );
                                }}
                              >
                                อาทิตย์
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="ทริกเกอร์เมื่อ">
                              <Switch
                                name="time_trigger_inside_range"
                                checkedChildren="อยู่ในช่วง"
                                unCheckedChildren="อยู่นอกช่วง"
                                defaultChecked
                                onChange={e => {
                                  setFieldValue("time_trigger_inside_range", e);
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={3} offset={1}>
                            <Form.Item
                              label={
                                <LangContext.Consumer>
                                  {i18n => "เวลาเริ่ม"}
                                </LangContext.Consumer>
                              }
                            >
                              <TimePicker
                                defaultValue={moment(
                                  values.time_start_at,
                                  "HH:mm:ss"
                                )}
                                defaultOpenValue={moment(
                                  values.time_start_at,
                                  "HH:mm:ss"
                                )}
                                onChange={(e, timeStr) =>
                                  setFieldValue("time_start_at", timeStr)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item
                              label={
                                <LangContext.Consumer>
                                  {i18n => "เวลาสิ้นสุด"}
                                </LangContext.Consumer>
                              }
                            >
                              <TimePicker
                                defaultValue={moment(
                                  values.time_end_at,
                                  "HH:mm:ss"
                                )}
                                defaultOpenValue={moment(
                                  values.time_end_at,
                                  "HH:mm:ss"
                                )}
                                onChange={(e, timeStr) =>
                                  setFieldValue("time_end_at", timeStr)
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Fragment>
                    )}

                    <div style={{ margin: "40px 0px 0px 0px" }}>
                      <Button
                        htmlType="submit"
                        type="primary"
                        // onClick={() => this.props.next(this.props.current)}
                      >
                        <LangContext.Consumer>
                          {i18n => i18n.s.saveNext}
                        </LangContext.Consumer>
                      </Button>
                    </div>
                  </Form>
                );
              else return <Skeleton active paragraph={{ rows: 14 }} />;
            }}
          </LangContext.Consumer>
        )}
      />
    );
  }
}
export default connect(
  ({ policyState, auth }) => ({ policyState, auth }),
  {}
)(Step1Form);
