import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import AuthorizeComponent from '../../../auth/components/AuthorizeComponent'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import moment from 'moment'
import { Link } from 'react-router-dom'
import {
  Row,
  Col,
  Spin,
  PageHeader,
  Tooltip,
  Button,
  Tag,
  Empty,
  Table,
} from 'antd'

// Components
import TimeChart from './TimeChart'
import TableLog from './TableLog'

// API
import { monitorSDCardChartLog, removeLists } from '../actions'

// Lib
import {
  prepareDataForTable,
  prepareLogFormatArray,
  genBackgroundColor,
} from './MainFunction'

// CSS
import '../assets/css/index.css'

class ViewDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //*ข้อมูล table
      // เก็บ array ของวันที่และเดือนที่เลือก
      log_format_data: [],
      // columns
      table_columns: [],
      // ข้อมูล table log
      log_detail: [],

      //*ข้อมูลกราฟ
      title_page: '',
      chart_data: [],
      chart_ticks: [],
      chart_color: [
        {
          category: 'CH1',
          color: '#FFCE56',
        },
        {
          category: 'CH2',
          color: '#36A2EB',
        },
        {
          category: 'CH3',
          color: '#9966FF',
        },
        {
          category: 'CH4',
          color: '#ff7d00',
        },
        {
          category: 'GPS',
          color: '#00D090',
        },
      ],

      listVDO: [],
      video_type: {
        '1': 'front',
        '2': 'back',
        '3': 'left',
        '4': 'right',
        front: 'front',
        back: 'back',
      },
      columnsListVdo: [],
    }
  }

  componentDidMount() {
    const { vehicle_id, hardware_id, active_date } = this.props.match.params

    if (vehicle_id && hardware_id && active_date) {
      this.handleShowChartDetail(vehicle_id, hardware_id, active_date)
    } else {
      this.redirectBack()
    }
  }

  componentWillUnmount() {
    this.onRemoveLists()
  }
  onRemoveLists = () => {
    this.props.removeLists()
  }

  redirectBack = () => {
    this.props.history.push('/monitor-sdcard')
  }

  //*เมื่อมีการเปลี่ยนแปลง state
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.monitorSDCard.check_chart_log !==
      nextProps.monitorSDCard.check_chart_log
    ) {
      let temp = nextProps.monitorSDCard.chart_log
      if (Object.keys(temp).length > 0) {
        const { active_date } = this.props.match.params
        let chart_data = temp.data || []
        let chart_ticks = temp.ticks || []
        let vehicle_data = temp.vehicle_data || []
        let table_log = temp.table_log || []
        let listVDO = temp.list_vdo || []
        let title_page = ''

        //TODO:จัดรูปแบบ title
        if (vehicle_data.length > 0) {
          let vehicle_code = vehicle_data[0].vehicle_code || '-'
          let plate_no = vehicle_data[0].plate_no || '-'

          title_page = `${i18n[nextProps.language].d.detail} :
            (${vehicle_code}) ${plate_no} ${
            i18n[nextProps.language].d.date
          } ${moment(active_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}`
        }

        this.setState({ chart_data, chart_ticks, title_page, listVDO }, () => {
          this.prepareColumnsListVdo()
        })

        //TODO:จัดรูปแบบข้อมูล table
        if (table_log.length > 0) {
          //จัดรูปแบบข้อมูล table
          this.setState(
            {
              log_detail: prepareDataForTable(table_log) || [],
              log_format_data:
                prepareLogFormatArray(
                  this.props.monitorSDCard.month_data,
                  moment(active_date, 'YYYY-MM-DD'),
                  moment(active_date, 'YYYY-MM-DD')
                ) || [],
            },
            () => {
              // *เตรียม columns ของ table view
              this.prepareColumns()
            }
          )
        }
      }
    }

    return true
  }

  // *แสดงรายละเอียดกราฟ
  handleShowChartDetail = (vehicle_id, hardware_id, active_date) => {
    this.props.monitorSDCardChartLog({
      vehicle_id: vehicle_id,
      hardware_id: hardware_id,
      date: active_date,
      accessToken: this.props.auth.accessToken,
    })
  }

  // *เตรียม columns ของ table view
  prepareColumns = () => {
    //TODO: สร้าง columns ของ table log
    const { log_format_data } = this.state
    let table_columns = []
    let all_columns = []

    let index_column = [
      {
        title: i18n[this.props.language].n.noReport,
        dataIndex: 'key',
        key: 'key',
        width: 50,
        align: 'center',
        fixed: 'left',
        render: (text, record, index) => {
          if (record.visible) {
            const obj = {
              children: <span>{record.number_index}</span>,
              props: {
                align: 'center',
                rowSpan: record.number_of_channel || 1,
              },
            }
            return obj
          } else {
            // สำหรับ channel อื่นๆ ไม่ต้องแสดงข้อมูลส่วนนี้เพราะ row แรก แสดงไปแล้ว
            const obj = {
              props: {
                align: 'center',
                rowSpan: 0,
              },
            }
            return obj
          }
        },
      },
      {
        title: i18n[this.props.language].v.vehicleData,
        dataIndex: 'vehicle_id',
        key: 'vehicles.code',
        width: 130,
        align: 'center',
        fixed: 'left',
        // sorter: true,
        render: (text, record, index) => {
          if (record.visible) {
            const obj = {
              children: (
                <div>
                  <Tooltip
                    title={
                      <span>{`(${record.vehicle_code}) ${record.plate_no}`}</span>
                    }
                    placement='topLeft'
                  >
                    <span>{`(${record.vehicle_code}) ${record.plate_no}`}</span>
                  </Tooltip>

                  <br />
                  {record.device_code && (
                    <Tooltip
                      title={
                        <span>{`${
                          i18n[this.props.language].d.deviceCode
                        } : ${record.device_code || ''}`}</span>
                      }
                      placement='topLeft'
                    >
                      <span>{`${record.device_code || ''}`}</span>
                    </Tooltip>
                  )}
                </div>
              ),
              props: {
                align: 'center',
                rowSpan: record.number_of_channel || 1,
              },
            }
            return obj
          } else {
            // สำหรับ series อื่นๆ ไม่ต้องแสดงข้อมูลส่วนนี้เพราะ row แรก แสดงไปแล้ว
            const obj = {
              props: {
                align: 'center',
                rowSpan: 0,
              },
            }
            return obj
          }
        },
      },
      {
        title: i18n[this.props.language].c.channel,
        dataIndex: 'channel_name',
        key: 'channel_name',
        width: 70,
        align: 'center',
        render: (text, record, index) => {
          const obj = {
            children: (
              <Tooltip title={text} placement='topLeft'>
                <span>{text}</span>
              </Tooltip>
            ),
            props: {
              align: 'center',
              rowSpan: 1,
              colSpan: 1,
            },
          }

          return obj
        },
      },
    ]

    all_columns = index_column

    // วนลูปใน log_format_data['order_key']
    for (let i = 0; i < log_format_data['order_key'].length; i++) {
      let key = log_format_data['order_key'][i]
      let date_array = log_format_data[key].date_array
      let day_array = log_format_data[key].day_array

      // วนลูปใน date_array
      for (let j = 0; j < date_array.length; j++) {
        let date = date_array[j]
        let day = day_array[j]

        let column = {
          title: day,
          dataIndex: date,
          key: date,
          align: 'center',
          render: (text, record) => {
            return {
              props: {
                style: {
                  background:
                    record.type == 'camera' && text
                      ? record[text].map((item, index) => {
                          return genBackgroundColor(
                            item['video_record_percent']
                          )
                        })
                      : record.type == 'online' && text
                      ? record[text].map((item, index) => {
                          return genBackgroundColor(item['online_percent'])
                        })
                      : '#FFF',
                },
              },
              children: (
                <div>
                  {text &&
                    record[text].map((item, index) => {
                      return record.type === 'camera' ? (
                        <>
                          <div>
                            <Tooltip
                              title={
                                <>
                                  <div>
                                    <span>
                                      {`${
                                        i18n[this.props.language].p
                                          .percentageOfTotalVideoRecorded
                                      } : ${
                                        item['engine_on_minute'] === 0 // ไม่ได้ติดเครื่องเลย
                                          ? '-'
                                          : item['video_record_percent'] + '%'
                                      }`}
                                    </span>
                                  </div>

                                  {/* จำนวนนาทีของวิดีโอ/จำนวนนาทีที่ติดเครื่อง */}
                                  <div>
                                    <span>
                                      {`${
                                        i18n[this.props.language].t
                                          .totalMinutesOfVideoRecorded
                                      }/${
                                        i18n[this.props.language].t
                                          .totalMinutesEngineRunning
                                      } : ${
                                        item['engine_on_minute'] == 0
                                          ? '-'
                                          : `${item['online_minute']}/${item['engine_on_minute']}`
                                      }`}
                                    </span>
                                  </div>

                                  {/* ขนาดไฟล์ */}
                                  <div>
                                    <span>
                                      {`${
                                        i18n[this.props.language].f.fileSize
                                      } : ${item['file_size']} GB.`}
                                    </span>
                                  </div>
                                </>
                              }
                            >
                              <Link
                                to={`/monitor-sdcard/view-detail/${record.vehicle_id}/${record.hardware_id}/${item['date']}`}
                                target='_blank'
                              >
                                <Button type='link' size='small'>
                                  <span
                                    style={{
                                      fontSize: '11px',
                                      color: 'black',
                                    }}
                                  >
                                    {item['engine_on_minute'] == 0
                                      ? '-'
                                      : `${item['video_record_percent']}%`}
                                  </span>
                                </Button>
                              </Link>
                            </Tooltip>
                          </div>
                        </>
                      ) : record.type === 'online' ? (
                        <>
                          <div>
                            <Tooltip
                              title={
                                <div>
                                  <span>
                                    {`${
                                      i18n[this.props.language].n
                                        .numberOfTimesDeviceOnline
                                    }/${
                                      i18n[this.props.language].n
                                        .numberOfTimesEngineStarted
                                    }`}
                                  </span>
                                </div>
                              }
                            >
                              <span style={{ fontSize: '13px' }}>
                                {`${item['online_count']}/${item['active_total']}`}
                              </span>
                            </Tooltip>
                          </div>
                        </>
                      ) : null
                    })}
                </div>
              ),
            }
          },
        }

        all_columns.push(column)
      }
    }
    table_columns = all_columns

    this.setState(
      {
        table_columns,
      },
      () => {}
    )
  }

  prepareColumnsListVdo = () => {
    //TODO:คอลัมน์ของ table รายละเอียด vdo
    let columnsListVdo = [
      {
        title: i18n[this.props.language].n.noReport,
        dataIndex: 'key',
        key: 'key',
        width: 30,
        align: 'center',
        render: (text, record, index) => {
          return <span>{index + 1}</span>
        },
      },
      {
        title: i18n[this.props.language].t.timePeriod,
        dataIndex: 'get_list_vdo.id',
        key: 'get_list_vdo.id',
        width: 120,
        align: 'center',
        render: (text, record, index) => {
          return (
            <div>
              <span>{`${moment(record.start_at, 'YYYY-MM-DD HH:mm:ss').format(
                'DD/MM/YYYY HH:mm:ss'
              )} - ${moment(record.end_at, 'YYYY-MM-DD HH:mm:ss').format(
                'DD/MM/YYYY HH:mm:ss'
              )}`}</span>
            </div>
          )
        },
      },
      {
        title: i18n[this.props.language].c.cameraPosition,
        dataIndex: 'video_type',
        key: 'get_list_vdo.video_type',
        width: 50,
        align: 'center',
        render: (text, record, index) => {
          return (
            <div>
              {record.log_type === 'ISUVDO' ? (
                <span>{`CH${record.video_type}`}</span>
              ) : (
                <span>
                  {
                    i18n[this.props.language].cameraPosition[
                      this.state.video_type[text]
                    ]
                  }
                </span>
              )}
            </div>
          )
        },
      },
      {
        title: i18n[this.props.language].manageDashboardMonitoring.Channel,
        dataIndex: 'channel',
        key: 'get_list_vdo.channel',
        width: 30,
        align: 'center',
        render: (text, record, index) => {
          return (
            <div>
              <span>{record.channel}</span>
            </div>
          )
        },
      },
      {
        title: i18n[this.props.language].f.fileName,
        dataIndex: 'file_location',
        key: 'get_list_vdo.file_location',
        width: 120,
        align: 'center',
        render: (text, record, index) => {
          return (
            <div>
              <span>{record.file_location}</span>
            </div>
          )
        },
      },
      {
        title: i18n[this.props.language].f.fileSize,
        dataIndex: 'file_size',
        key: 'get_list_vdo.file_size',
        align: 'center',
        width: 50,
        render: (text, record, index) => {
          return (
            <div>
              <span>{record.file_size}</span>
            </div>
          )
        },
      },
    ]

    this.setState(
      {
        columnsListVdo,
      },
      () => {}
    )
  }

  render() {
    const { check_chart_log, color_status } = this.props.monitorSDCard

    const {
      title_page,
      chart_data,
      chart_color,
      chart_ticks,
      log_detail,
      table_columns,

      listVDO,
      columnsListVdo,
    } = this.state

    return (
      <AuthorizeComponent {...this.props} matching_name='monitorSDCard'>
        <GeneralStyledContent>
          <div className='monitor-sdcard'>
            {/* header */}
            <PageHeader
              style={{ paddingLeft: '0px' }}
              title={
                <div className='header__text'>
                  {i18n[this.props.language].m.monitorSDCard}
                </div>
              }
            />

            {/* title */}
            <div>
              <span className='title-page'>
                <b>{title_page}</b>
              </span>
            </div>

            <div style={{ marginTop: '10px' }}>
              {color_status.map((item, index) => {
                return (
                  <>
                    <Tag
                      color={item.color}
                      key={index}
                      style={{
                        width: '20px',
                        height: '20px',
                        borderRadius: '50%',
                      }}
                    >
                      &nbsp;
                    </Tag>
                    <span className='font-tag-style'>{item.title}</span>
                    &nbsp;
                  </>
                )
              })}
            </div>

            {/* table log */}

            <div style={{ marginTop: '10px' }}>
              <Spin
                spinning={check_chart_log}
                tip={`${i18n[this.props.language].l.loading}...`}
              >
                {log_detail.length > 0 ? (
                  <>
                    <Row>
                      <Col span={24}>
                        {log_detail.length > 0 && (
                          <TableLog
                            data={log_detail || []}
                            column={table_columns}
                            className={'table-vdo'}
                          />
                        )}
                      </Col>
                    </Row>

                    {/* แสดงกราฟ */}
                    <Row
                      style={{ marginTop: '20px' }}
                      type='flex'
                      align='middle'
                    >
                      <Col span={24}>
                        {chart_data && chart_data.length > 0 && (
                          <TimeChart
                            chart_data={chart_data}
                            chart_color={chart_color}
                            chart_ticks={chart_ticks}
                          />
                        )}
                      </Col>
                    </Row>

                    {/* แสดงผล table รายละเอียด vdo */}
                    <Row
                      style={{ marginTop: '20px' }}
                      type='flex'
                      align='middle'
                    >
                      <Col span={24}>
                        {listVDO.length > 0 && (
                          <Table
                            size='small'
                            columns={columnsListVdo}
                            dataSource={listVDO || []}
                            scroll={{ x: 1000, y: 1000 }}
                            bordered
                            className='table-row'
                            pagination={false}
                          ></Table>
                        )}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </>
                )}
              </Spin>
            </div>
          </div>
        </GeneralStyledContent>
      </AuthorizeComponent>
    )
  }
}

export default connect(
  ({ monitorSDCard, ui: { language }, auth, auth: { profile } }) => ({
    monitorSDCard,
    language,
    auth,
    profile,
  }),
  {
    monitorSDCardChartLog: monitorSDCardChartLog.request,
    removeLists,
  }
)(ViewDetail)
