import { all, put, call, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { getlocalstorage } from "./../../../constants/local_storage";
//import { push } from "connected-react-router";

import * as actions from "./actions";
import moment from "moment";

function* loadSummarykpi(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );
  const USER_ID = getlocalstorage(localStorage.getItem("profile"), "id");

  const {
    data: {
      list_vehicle_id,
      eventdate,
      report,
      type,
      type_file,
      trip_delay_rule,
      point_trip_delay,
      minute_trip_delay,
    },
  } = action.payload;
  const date_start = moment(eventdate[0]["_d"]).format("DD/MM/YYYY");
  const date_end = moment(eventdate[1]["_d"]).format("DD/MM/YYYY");
  const start_time = moment(eventdate[0]["_d"]).format("HH:mm");
  const end_time = moment(eventdate[1]["_d"]).format("HH:mm");

  var report_type = type;
  if (report == "date-range") {
    report_type = report;
  }

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_LOGIN}/service/report/summarytrip`,
      //`http://terminus-fleet/service/report/summarytrip`,
      {
        date_start,
        date_end,
        start_time,
        end_time,
        list_vehicle_id,
        company_id: COMPANY_ID,
        user_id: USER_ID,
        type_file,
        report_type,
        trip_rule: `${trip_delay_rule},${point_trip_delay},${minute_trip_delay}`,
      }
      //  { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadSummarykpi.success(res.data));
    //console.log(res.data.data);
    window.location = res.data.data;
  } catch (err) {
    yield put(actions.loadSummarykpi.failure());
  }
}

function* loadSummarykpipreview(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );
  const USER_ID = getlocalstorage(localStorage.getItem("profile"), "id");

  const {
    data: {
      list_vehicle_id,
      eventdate,
      report,
      type,
      trip_delay_rule,
      point_trip_delay,
      minute_trip_delay,
    },
  } = action.payload;
  const date_start = moment(eventdate[0]["_d"]).format("DD/MM/YYYY");
  const date_end = moment(eventdate[1]["_d"]).format("DD/MM/YYYY");
  const time_start = moment(eventdate[0]["_d"]).format("HH:mm");
  const time_end = moment(eventdate[1]["_d"]).format("HH:mm");

  var report_type = type;
  if (report == "date-range") {
    report_type = report;
  }

  try {
    const res = yield call(
      axios.get,
      `https://login.terminusfleet.com/test/request.php?req=getSummaryTripTableData&vehicle=${list_vehicle_id}&report_type=${report_type}&start_at=${date_start}&start_time=${time_start}&end_at=${date_end}&end_time=${time_end}&c_id=${COMPANY_ID}` +
        `&trip_rule=${trip_delay_rule},${point_trip_delay},${minute_trip_delay}`
      //`http://localhost:3002/test/request.php?req=getSummaryTripTableData&vehicle=${list_vehicle_id}&report_type=${report_type}&start_at=${date_start}&start_time=${time_start}&end_at=${date_end}&end_time=${time_end}&c_id=${COMPANY_ID}` +
      //`&trip_rule=${trip_delay_rule},${point_trip_delay},${minute_trip_delay}`
    );
    yield put(actions.loadSummarykpipreview.success(res.data));
  } catch (err) {
    yield put(actions.loadSummarykpipreview.failure());
  }
}

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );
  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehicles`,
      {
        companyID: COMPANY_ID,
        vehicle_under_control: vehicle_visibility,
        plate_no: "",
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadVehicle.success(res.data));
  } catch (err) {
    yield put(actions.loadVehicle.failure());
  }
}

function* loadVehicleType(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getgroupvehicle`,
      {
        company_id: COMPANY_ID,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(actions.loadVehicleType.success(res.data));
  } catch (err) {
    yield put(actions.loadVehicleType.failure());
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(actions.LOAD_SUMMARYKPI.REQUEST, loadSummarykpi),
    takeLatest(actions.LOAD_SUMMARYKPI_PREVIEW.REQUEST, loadSummarykpipreview),
    takeEvery(actions.LOAD_VEHICLE_SUMMARYKPI.REQUEST, loadVehicle),
    takeEvery(actions.LOAD_VEHICLE_TYPE_SUMMARYKPI.REQUEST, loadVehicleType),
  ]);
}

export { loadSummarykpi, loadSummarykpipreview, loadVehicle, loadVehicleType };
