import {
  LOAD_DATAVISIBILITY_SELECTED_VEHICLEGROUP,
  LOAD_DATAVISIBILITY_SELECTED_VEHICLE,
  CHANGE_SELECTEDVEHICLEGROUP,
  CHANGE_SELECTEDVEHICLE,
  LOAD_DATAVISIBILITY_SELECTED_LOCATIONGROUP,
  CHANGE_SELECTEDLOCATIONGROUP,
  LOAD_DATAVISIBILITY_SELECTED_LOCATION,
  CHANGE_SELECTEDLOCATION,
  LOAD_DATAVISIBILITY_SELECTED_USER,
  CHANGE_SELECTEDUSER
} from "./actions";

const initialState = {
  lists: [],
  total: 1,
  loading: false,
  detailLoading: false,
  draw: -1,
  selectedVehicleGroup: [],
  selectedVehicle: [],
  selectedLocationGroup: [],
  selectedLocation: [],
  selectedUser: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    // case REMOVE_LISTS:
    //   return initialState;

    case CHANGE_SELECTEDVEHICLEGROUP:
      return {
        ...state,
        selectedVehicleGroup: action.payload
      };
    case CHANGE_SELECTEDVEHICLE:
      return {
        ...state,
        selectedVehicle: action.payload
      };
    case CHANGE_SELECTEDLOCATIONGROUP:
      return {
        ...state,
        selectedLocationGroup: action.payload
      };
    case CHANGE_SELECTEDLOCATION:
      return {
        ...state,
        selectedLocation: action.payload
      };
    case CHANGE_SELECTEDUSER:
      return {
        ...state,
        selectedUser: action.payload
      };
    case LOAD_DATAVISIBILITY_SELECTED_VEHICLEGROUP.REQUEST:
      return {
        ...state,
        loading: true
      };
    case LOAD_DATAVISIBILITY_SELECTED_VEHICLEGROUP.SUCCESS:
      return {
        ...state,
        loading: false,
        selectedVehicleGroup: action.payload.res
      };
    case LOAD_DATAVISIBILITY_SELECTED_VEHICLE.REQUEST:
      return {
        ...state,
        loading: true
      };
    case LOAD_DATAVISIBILITY_SELECTED_VEHICLE.SUCCESS:
      return {
        ...state,
        loading: false,
        selectedVehicle: action.payload.res
      };
    case LOAD_DATAVISIBILITY_SELECTED_LOCATIONGROUP.REQUEST:
      return {
        ...state,
        loading: true
      };
    case LOAD_DATAVISIBILITY_SELECTED_LOCATIONGROUP.SUCCESS:
      return {
        ...state,
        loading: false,
        selectedLocationGroup: action.payload.res
      };
    case LOAD_DATAVISIBILITY_SELECTED_LOCATION.REQUEST:
      return {
        ...state,
        loading: true
      };
    case LOAD_DATAVISIBILITY_SELECTED_LOCATION.SUCCESS:
      return {
        ...state,
        loading: false,
        selectedLocation: action.payload.res
      };
    case LOAD_DATAVISIBILITY_SELECTED_USER.REQUEST:
      return {
        ...state,
        loading: true
      };
    case LOAD_DATAVISIBILITY_SELECTED_USER.SUCCESS:
      return {
        ...state,
        loading: false,
        selectedUser: action.payload.res
      };
    default:
      return state;
  }
};
