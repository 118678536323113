import {
  LOAD_TRIPSUMBYLOCATION,
  LOAD_LOCATION_TRIPSUMBYLOCATION,

} from "./actions";

const initialState = {
  tripsumbylocationUrl: "",
  locationLists: [],
  tripsumbylocationLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TRIPSUMBYLOCATION.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        tripsumbylocationLoading: loading
      };
    case LOAD_TRIPSUMBYLOCATION.SUCCESS:

      return {
        ...state,
        tripsumbylocationUrl: action.payload.data.data,
        tripsumbylocationLoading: false
      };
    case LOAD_LOCATION_TRIPSUMBYLOCATION.SUCCESS:
      return {
        ...state,
        locationLists: action.payload.data.data,
        tripsumbylocationLoading: false
        //  loading: false
      };
    default:
      return state;
  }
};
