import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as moment from "moment";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Button,
  Row,
  Col,
  Modal,
  DatePicker,
  Tag,
  Input,
  Form,
  Select,
  Alert,
  Spin,
} from "antd";
import { notSafePassword } from "../../../../constants/constants";
import { helper_convertDateformat } from "../../../../lib/helper";
import LangContext, { i18n } from "modules/shared/context/langContext";

const InputGroup = Input.Group;
const { confirm } = Modal;
const { MonthPicker, RangePicker } = DatePicker;
const { Option } = Select;
const { Item } = Form;
const FormItem = Item;
class Index extends Component {
  state = {
    data: { oldPassword: "", password: "", confirmPassword: "" },
    ModalText: "Content of the modal",
    visible: false,
    confirmLoading: false,
    alertMessage: "",
    alertType: "",
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (values, actions) => {
    let self = this;
    this.setState({
      confirmLoading: true,
    });
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/change-password`, {
      // fetch(`http://booking-api-88:88/api/change-password`, {
      method: "POST",
      mode: "cors",
      headers: {
        // Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${self.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        old_password: values.oldPassword,
        new_password: values.password,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        self.setState({
          //   visible: false,
          confirmLoading: false,
          alertType: res.status,
          alertMessage: res.msg,
        });
        actions.resetForm({});
      })
      .catch(function() {});
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    let dataVisibilityNode = this.props.auth.profile.data_visibility_node;
    dataVisibilityNode = JSON.parse(dataVisibilityNode);

    if (dataVisibilityNode.length == 0)
      dataVisibilityNode = [{ name: this.props.auth.profile.company_name }];
    return (
      <Fragment>
        <Row>
          <Col xs={24} sm={24} md={7} lg={7} xl={7}>
            <Form.Item
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.c.company}
                </LangContext.Consumer>
              }
              style={{ marginBottom: "0px" }}
            >
              <Input
                type="Text"
                name="company"
                value={this.props.auth.profile.company_name}
                readOnly={true}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={7} lg={7} xl={7}>
            <Form.Item
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.u.userName}
                </LangContext.Consumer>
              }
              style={{ marginBottom: "0px" }}
            >
              <Input
                type="Text"
                name="username"
                value={this.props.auth.profile.username}
                readOnly={true}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={5} lg={5} xl={5}>
            <FormItem
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.e.employeeID}
                </LangContext.Consumer>
              }
              style={{ marginBottom: "0px" }}
            >
              <Input
                type="Text"
                name="employee_ID"
                autoComplete="off"
                value={this.props.auth.profile.employee_code}
                readOnly={true}
              />
            </FormItem>
          </Col>

          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
          <Col xs={24} sm={24} md={5} lg={5} xl={5}>
            <Form.Item
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.f.firstName}
                </LangContext.Consumer>
              }
              style={{ marginBottom: "0px" }}
            >
              <Input
                type="Text"
                name="firstname"
                autoComplete="off"
                value={this.props.auth.profile.firstname}
                readOnly={true}
              />
            </Form.Item>
          </Col>

          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
          <Col xs={24} sm={24} md={5} lg={5} xl={5}>
            <Form.Item
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.l.lastName}
                </LangContext.Consumer>
              }
              style={{ marginBottom: "0px" }}
            >
              <Input
                type="Text"
                name="lastname"
                autoComplete="off"
                value={this.props.auth.profile.lastname}
                readOnly={true}
              />
            </Form.Item>
          </Col>

          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={5} lg={5} xl={5}>
            <FormItem
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.p.phoneNumber}
                </LangContext.Consumer>
              }
              style={{ marginBottom: "0px" }}
            >
              <Input
                type="Text"
                name="tel"
                value={this.props.auth.profile.phone}
                readOnly={true}
              />
            </FormItem>
          </Col>

          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>

          <Col xs={24} sm={24} md={5} lg={5} xl={5}>
            <FormItem
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.e.email}
                </LangContext.Consumer>
              }
              style={{ marginBottom: "0px" }}
            >
              <Input
                type="Text"
                name="email"
                autoComplete="off"
                value={this.props.auth.profile.email}
                readOnly={true}
              />
            </FormItem>
          </Col>
          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
          <Col xs={24} sm={24} md={5} lg={5} xl={5}>
            <Form.Item
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.u.userExpireAt}
                </LangContext.Consumer>
              }
            >
              <Input
                value={helper_convertDateformat(
                  this.props.auth.profile.expired_at,
                  "YYYY-MM-DD HH:mm:ss",
                  "DD/MM/YYYY HH:mm"
                )}
                readOnly={true}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={5} lg={5} xl={5}>
            <Form.Item
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.d.dataVisibility}
                </LangContext.Consumer>
              }
            >
              {dataVisibilityNode.map((ele) => (
                <Tag>{ele.name}</Tag>
              ))}
            </Form.Item>
          </Col>
        </Row>

        <br />
        <Row>
          <Col sm={24} md={5} lg={5} xl={5}>
            <Form.Item>
              <Button type="primary" onClick={this.showModal}>
                <LangContext.Consumer>
                  {(i18n) => i18n.c.changePassword}
                </LangContext.Consumer>
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <LangContext.Consumer>
          {(i18n) => (
            <Formik
              enableReinitialize={true}
              initialValues={this.state.data}
              validationSchema={yup.object().shape({
                oldPassword: yup.string().required(" " + i18n.r.require),
                password: yup
                  .string()
                  .min(6, " " + i18n.p.passwordMustBeAtLeast6)
                  .required(" " + i18n.r.require)
                  .test(
                    "password-not-safe",
                    i18n.p.passwordNotSafeValidateText,
                    (value) => {
                      if (
                        typeof notSafePassword.find((ele) => ele == value) !==
                        "undefined"
                      )
                        return false;
                      return true;
                    }
                  ),
                confirmPassword: yup
                  .string()
                  .oneOf(
                    [yup.ref("password"), null],
                    " " + i18n.p.passwordMustMatch
                  )
                  .required(" " + i18n.r.require),
              })}
              onSubmit={(values, actions) => {
                this.handleOk(values, actions);
              }}
              render={({
                values,
                errors,
                status,
                handleBlur,
                touched,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form>
                  <Modal
                    title={
                      <LangContext.Consumer>
                        {(i18n) => i18n.c.changePassword}
                      </LangContext.Consumer>
                    }
                    maskClosable={false}
                    visible={this.state.visible}
                    onOk={handleSubmit}
                    okText={
                      <LangContext.Consumer>
                        {(i18n) => i18n.s.submit}
                      </LangContext.Consumer>
                    }
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                    cancelText={
                      <LangContext.Consumer>
                        {(i18n) => i18n.c.cancel}
                      </LangContext.Consumer>
                    }
                  >
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        {this.state.alertType != "" && (
                          <Alert
                            message={
                              this.state.alertType != "error" ? (
                                <LangContext.Consumer>
                                  {(i18n) =>
                                    i18n.variable[this.state.alertMessage]
                                  }
                                </LangContext.Consumer>
                              ) : (
                                this.state.alertMessage
                              )
                            }
                            type={this.state.alertType}
                            closable
                            //   afterClose={this.handleClose}
                          />
                        )}
                        <Form.Item
                          label={
                            <LangContext.Consumer>
                              {(i18n) => i18n.p.password}
                            </LangContext.Consumer>
                          }
                          style={{ marginBottom: "0px" }}
                          required={true}
                          validateStatus={
                            touched.oldPassword && errors.oldPassword
                              ? "warning"
                              : undefined
                          }
                          help={
                            touched.oldPassword && errors.oldPassword
                              ? errors.oldPassword.substr(
                                  errors.oldPassword.indexOf(" ") + 1
                                )
                              : undefined
                          }
                        >
                          <Input.Password
                            name="oldPassword"
                            value={values.oldPassword}
                            onChange={(e) => {
                              setFieldValue("oldPassword", e.target.value);
                            }}
                            onBlur={handleBlur}
                            autoComplete="off"
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item
                          label={
                            <LangContext.Consumer>
                              {(i18n) => i18n.n.newPassword}
                            </LangContext.Consumer>
                          }
                          style={{ marginBottom: "0px" }}
                          required={true}
                          validateStatus={
                            touched.password && errors.password
                              ? "warning"
                              : undefined
                          }
                          help={
                            touched.password && errors.password
                              ? errors.password.substr(
                                  errors.password.indexOf(" ") + 1
                                )
                              : undefined
                          }
                        >
                          <Input.Password
                            name="password"
                            value={values.password}
                            onChange={(e) => {
                              setFieldValue("password", e.target.value);
                            }}
                            onBlur={handleBlur}
                            autoComplete="off"
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item
                          label={
                            <LangContext.Consumer>
                              {(i18n) => i18n.c.confirmNewPassword}
                            </LangContext.Consumer>
                          }
                          style={{ marginBottom: "0px" }}
                          required={true}
                          validateStatus={
                            touched.confirmPassword && errors.confirmPassword
                              ? "warning"
                              : undefined
                          }
                          help={
                            touched.confirmPassword && errors.confirmPassword
                              ? errors.confirmPassword.substr(
                                  errors.confirmPassword.indexOf(" ") + 1
                                )
                              : undefined
                          }
                        >
                          <Input.Password
                            type="hidden"
                            name="confirmPassword"
                            value={values.confirmPassword}
                            onChange={(e) => {
                              setFieldValue("confirmPassword", e.target.value);
                            }}
                            onBlur={handleBlur}
                            autoComplete="off"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Modal>
                </Form>
              )}
            />
          )}
        </LangContext.Consumer>
      </Fragment>
    );
  }
}

export default connect(
  ({ auth }) => ({
    auth,
  }),
  {}
)(Index);
