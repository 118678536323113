import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";
import moment from "moment";

function* exportPerformanceLogReport(action) {
  const {
    data: { company_id, logtype_id, eventdate, type_file },
  } = action.payload;

  const date_start = moment(eventdate[0]["_d"]).format("YYYY-MM-DD HH:mm:ss");
  const date_end = moment(eventdate[1]["_d"]).format("YYYY-MM-DD HH:mm:ss");
  const compArr = [];
  company_id.map((item) => {
    compArr.push(parseInt(item));
  });

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/performancelog`,
      {
        date_start,
        date_end,
        company_id: compArr,
        logtype_id,
        type_file,
      },
      { headers: { Authorization: `Bearer ${action.payload.accessToken}` } }
    );
    yield put(actions.exportPerformanceLogReport.success(res.data));
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`
    );
  } catch (err) {
    yield put(actions.exportPerformanceLogReport.failure());
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(
      actions.EXPORT_PERFORMANCELOG.REQUEST,
      exportPerformanceLogReport
    ),
  ]);
}

export { exportPerformanceLogReport };
