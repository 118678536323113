import React from "react";
import { Switch, Route } from "react-router-dom";
import Honeytoastdownloadfile from "./Index";

export default () => (
  <Switch>
    <Route
      exact
      path="/honey-toast-download-file-webpage"
      component={Honeytoastdownloadfile}
    />
  </Switch>
);
