import React, { Component } from "react";
import { connect } from "react-redux";
import LangContext, { i18n } from "modules/shared/context/langContext";
import * as actions from "../actions";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "./../../../shared/components/Breadcrumb";
import { helper_convertDateformat } from "../../../../lib/helper";
import { checkPermissions } from "../../../../lib/helper";
import { getlocalstorage } from "./../../../../constants/local_storage";
import {
  Button,
  Table,
  Row,
  Col,
  Popconfirm,
  Icon,
  Tooltip,
  Drawer,
  message,
} from "antd";
import moment from "moment";
import axios from "axios";
import { Link } from "react-router-dom";
import Filter from "./Filter";
const columnStyle = { fontSize: "12px", fontWeight: "400" };

class Index extends Component {
  state = {
    visible: false,
    page: 1,
    pageSize: 10,
    orderBy: "s.updated_at",
    orderType: "desc",
    filterObj: {
      list_report_schedules_id: "",
    },
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onCloseSearch = (resetForm) => {
    this.setState({
      visible: false,
    });
  };

  filterSchedulereport = (values) => {
    const { list_report_schedules_id } = values;

    this.setState({
      filterObj: {
        list_report_schedules_id,
      },
    });
    this.props.loadSchedulereport(
      {
        page: this.state.page,
        pageSize: this.state.pageSize,
        orderBy: this.state.orderBy,
        orderType: this.state.orderType,
        filterObj: {
          list_report_schedules_id,
        },
      },
      true
    );
  };

  componentDidMount() {
    this.props.loadSchedulereport(this.state, true);
    this.props.loadReportSchedulereport();
  }

  componentDidUpdate(prevProps, prevState) {
    const { dataResponse } = this.props.schedulereport;

    if (
      dataResponse.action_type == "create" ||
      dataResponse.action_type == "update"
    ) {
      if (dataResponse.status == "success") {
        message.info("สำเร็จ");
      } else if (dataResponse.status == "fail") {
        message.info(dataResponse.msg);
      }
      this.props.clearDataResponse();
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      page: pagination.current,
    });

    let orderBy = "";
    let orderType = "";

    if (Object.keys(sorter).length === 0 && sorter.constructor === Object) {
      orderBy = this.state.orderBy;
      orderType = this.state.orderType;
    } else {
      this.setState({
        orderBy: sorter.columnKey,
        orderType: sorter.order == "descend" ? "desc" : "asc",
      });
      orderBy = sorter.columnKey;
      orderType = sorter.order == "descend" ? "desc" : "asc";
    }

    this.props.loadSchedulereport(
      {
        page: pagination.current,
        pageSize: this.state.pageSize,
        searchName: "",
        orderBy: orderBy,
        orderType: orderType,
        filterObj: {
          list_report_schedules_id: this.state.filterObj
            .list_report_schedules_id,
        },
      },
      true
    );
  };

  deleteSchedulereport = (id) => {
    let self = this;

    axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/schedulereport/delete/${id}`,
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        }
      )
      .then(function(response) {
        self.props.loadSchedulereport(
          {
            page: 1,
            pageSize: self.state.pageSize,
            orderBy: self.state.orderBy,
            orderType: self.state.orderType,
            filterObj: {
              list_report_schedules_id:
                self.state.filterObj.list_report_schedules_id,
            },
          },
          true
        );
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  render() {
    const columns = [
      {
        title: "#",
        dataIndex: "row_number",
        width: 15,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.r.report_name}
          </LangContext.Consumer>
        ),
        dataIndex: "report_name",
        sorter: true,
        width: "25%",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: (
              <div>
                <LangContext.Consumer>
                  {(i18n) => i18n.scheduleReport[record.matching_name]}
                </LangContext.Consumer>
              </div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.e.email_subject}
          </LangContext.Consumer>
        ),
        dataIndex: "email_subject",
        sorter: true,
        width: "15%",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.s.schedule_type}
          </LangContext.Consumer>
        ),
        dataIndex: "schedule_type",
        sorter: true,
        width: "10%",
        render(text, record, index) {
          let type = (
            <LangContext.Consumer>
              {(i18n) => i18n.r.report_daily}
            </LangContext.Consumer>
          );
          if (record.schedule_type == "weekly") {
            type = (
              <LangContext.Consumer>
                {(i18n) => i18n.r.report_weekly}
              </LangContext.Consumer>
            );
          }
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{type}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.t.time}</LangContext.Consumer>
        ),
        dataIndex: "time",
        sorter: true,
        width: "8%",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.t.to_email}
          </LangContext.Consumer>
        ),
        dataIndex: "to_email",
        sorter: true,
        width: "15%",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.c.cc_email}
          </LangContext.Consumer>
        ),
        dataIndex: "cc_email",
        sorter: false,
        width: "15%",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.a.action}</LangContext.Consumer>
        ),
        dataIndex: "action",
        key: "action",
        sorter: false,
        width: "8%",
        render: (text, record, index) => {
          return (
            <div>
              {checkPermissions(
                this.props.auth.profile.permissions,
                "user",
                "can_view"
              ) == true ? (
                <Tooltip
                  placement="left"
                  title={
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.detail}
                    </LangContext.Consumer>
                  }
                  arrowPointAtCenter
                  style={{
                    padding: "0px 1px 0px 0px",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  <Link
                    to={`/schedulereport/detail/${record.id}`}
                    style={{ padding: "0px 5px 0px 5px" }}
                  >
                    <Icon type="file-text" />
                  </Link>
                </Tooltip>
              ) : (
                ""
              )}

              {checkPermissions(
                this.props.auth.profile.permissions,
                "schedulereport",
                "can_edit"
              ) === true ? (
                <Tooltip
                  placement="left"
                  title={
                    <LangContext.Consumer>
                      {(i18n) => i18n.e.edit}
                    </LangContext.Consumer>
                  }
                  arrowPointAtCenter
                >
                  <Link to={`/schedulereport/edit/${record.id}`}>
                    <Icon type="edit" />
                  </Link>
                </Tooltip>
              ) : (
                ""
              )}

              {checkPermissions(
                this.props.auth.profile.permissions,
                "schedulereport",
                "can_delete"
              ) === true ? (
                <LangContext.Consumer>
                  {(i18n) => (
                    <Tooltip
                      placement="left"
                      title={i18n.d.delete}
                      arrowPointAtCenter
                    >
                      <Popconfirm
                        title={i18n.c.confirmToDeleteThisItem}
                        onConfirm={() => {
                          let self = this;
                          self.deleteSchedulereport(record.id);
                        }}
                        cancelText={i18n.c.cancel}
                        okText={i18n.o.ok}
                      >
                        <a href="#" style={{ padding: "0px 15px 0px 5px" }}>
                          <Icon type="delete" />
                        </a>
                      </Popconfirm>
                    </Tooltip>
                  )}
                </LangContext.Consumer>
              ) : (
                ""
              )}
            </div>
          );
        },
        // width: "5%"
      },
    ];

    return (
      <div>
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <Row gutter={24} type="flex" justify="end" style={{ height: "38px" }}>
            <Col span={22}>
              {/* {checkPermissions(
                this.props.auth.profile.permissions,
                "schedulereport",
                "can_add"
              ) === true ? ( */}
              <Link to="/schedulereport/create/">
                <Button type="primary" icon="plus" size="small">
                  <span></span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.n.new}
                  </LangContext.Consumer>
                </Button>
              </Link>
              {/* ) : ""} */}
            </Col>

            <Col span={2}>
              <Button icon="filter" size="small" onClick={this.showDrawer}>
                <LangContext.Consumer>
                  {(i18n) => i18n.f.filter}
                </LangContext.Consumer>
              </Button>
            </Col>
          </Row>
          <Table
            bordered
            size="small"
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={this.props.schedulereport.schedulereportLists}
            pagination={{
              pageSize: this.state.pageSize,
              total: this.props.schedulereport.schedulereportTotal,
              current: this.state.page,
            }}
            loading={this.props.schedulereport.schedulereportLoading}
            onChange={this.handleTableChange}
          />

          <div>
            <LangContext.Consumer>
              {(i18n) => (
                <Drawer
                  title={i18n.s.search}
                  width={450}
                  onClose={this.onCloseSearch}
                  visible={this.state.visible}
                >
                  <Filter
                    OnClose={this.onCloseSearch}
                    onSubmit={this.filterSchedulereport}
                    reportLists={this.props.schedulereport.reportLists}
                  />
                </Drawer>
              )}
            </LangContext.Consumer>
          </div>
        </GeneralStyledContent>
      </div>
    );
  }
}

const mapStateToProps = ({ schedulereport, auth }) => ({
  schedulereport,
  auth,
});

const mapDispatchToProps = {
  loadSchedulereport: actions.loadSchedulereport.request,
  clearDataResponse: actions.clearDataResponse,
  loadReportSchedulereport: actions.loadReportSchedulereport.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
