import React from "react";
import { Switch, Route } from "react-router-dom";
import Routemaster from "./Index";
import Add from "./Add";
import Edit from "./Edit";
export default () => (
  <Switch>
    <Route exact path="/routemaster" component={Routemaster} />
    <Route path="/routemaster/create" component={Add} />
    <Route path="/routemaster/edit/:id" component={Edit} />
  </Switch>
);
