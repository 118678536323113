import { Card, Col, Row } from "antd";
import React from "react";

const CompareGraph = ({ selectGraph }) => {
  return (
    <Card
      width="100%"
      style={{
        backgroundColor: "rgb(247, 247, 247)",
        marginTop: 15,
        border: 0,
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        padding: 15,
      }}
    >
      <div style={{ marginBottom: 15, fontSize: 18 }}>ตรวจสอบกราฟ</div>
      <p style={{ color: "red" }}>โปรดเลือกภาพด้านล่างที่เหมือนกับกราฟที่สุด</p>
      <div style={{ margin: "40px 0px" }}>
        <Row
          gutter={[16, 16]}
          type="flex"
          justify="space-around"
          align="middle"
        >
          <Col
            span={6}
            align="center"
            className="select-compare-graph-calibrate-fuel"
            onClick={() => selectGraph(1)}
          >
            <div>
              <img src="/img/calibrate1.png" style={{ height: 100 }} />
            </div>
          </Col>
          <Col
            span={6}
            align="center"
            className="select-compare-graph-calibrate-fuel"
            onClick={() => selectGraph(2)}
          >
            <div>
              <img src="/img/calibrate2.png" style={{ height: 100 }} />
            </div>
          </Col>
          <Col
            span={6}
            align="center"
            className="select-compare-graph-calibrate-fuel"
            onClick={() => selectGraph(3)}
          >
            <div>
              <img src="/img/calibrate3.png" style={{ height: 100 }} />
            </div>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default CompareGraph;
