import {
  LOAD_VEHICLE_DATA,
  UPDATE_VEHICLE_TAX_ACT_INSURANCE,
  IMPORT_DOCUMENT,
  LOAD_VEHICLE_DATA_EXPORT,
} from "./actions";

const msg = {
  success_msg: "",
  err_msg: "",
};

const initialState = {
  vehicleLists: [],
  loading: false,
  importloading: false,
  shouldReload: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_VEHICLE_DATA.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_VEHICLE_DATA.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data,
        loading: false,
      };
    case LOAD_VEHICLE_DATA_EXPORT.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_VEHICLE_DATA_EXPORT.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_VEHICLE_TAX_ACT_INSURANCE.REQUEST:
    case UPDATE_VEHICLE_TAX_ACT_INSURANCE.SUCCESS:
      return {
        ...state,
        msg: "Update Success",
        status: "success",
        loading: false,
      };
    case UPDATE_VEHICLE_TAX_ACT_INSURANCE.FAILURE:
    case LOAD_VEHICLE_DATA.FAILURE:
    case LOAD_VEHICLE_DATA_EXPORT.FAILURE:
    case IMPORT_DOCUMENT.REQUEST:
      return {
        ...state,
        ...msg,
        importloading: true,
        shouldReload: false,
      };
    case IMPORT_DOCUMENT.SUCCESS:
      return {
        ...state,
        success_msg: "import_success",
        importloading: false,
        shouldReload: true,
      };
    case IMPORT_DOCUMENT.FAILURE:
      return {
        ...state,
        err_msg: action.payload.err,
        importloading: false,
        shouldReload: false,
      };
    default:
      return state;
  }
};
