import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Badge,
  Button,
  Table,
  Form,
  Row,
  Col,
  Input,
  Pagination,
  Spin,
  Popconfirm,
  Tooltip,
  Icon,
} from "antd";
import axios from "axios";
import moment from "moment";

import LangContext, { i18n } from "modules/shared/context/langContext";
import { helper_convertDateformat } from "../../../../lib/helper";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import * as actions from "../actions";
import history from "../../../../lib/history";
import FormDetail from "./FormDetail";

const columnStyle = { fontSize: "12px", fontWeight: "400" };
const format = "HH:mm";
class TableDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10,
      orderBy: "seq",
      orderType: "asc",
      visible: false,
      detailLists: [],
      detailTotal: 0,
      route_master_detail_id: 0,
    };

    this.columns = [
      {
        title: "#",
        dataIndex: "row_number",
        width: 60,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.d.destinationLocation}
          </LangContext.Consumer>
        ),
        dataIndex: "location_name",
        sorter: false,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: (
              <div>
                {text}
                <br />
                {record.address}
              </div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.d.date_entry}
          </LangContext.Consumer>
        ),
        dataIndex: "date_entry",
        sorter: false,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.t.time_entry}
          </LangContext.Consumer>
        ),
        dataIndex: "time_entry",
        sorter: false,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.t.time_use_in_location}
          </LangContext.Consumer>
        ),
        dataIndex: "time_use_in_location",
        sorter: false,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.d.date_out}
          </LangContext.Consumer>
        ),
        dataIndex: "date_out",
        sorter: false,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.t.time_out}
          </LangContext.Consumer>
        ),
        dataIndex: "time_out",
        sorter: false,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.t.time_travel}
          </LangContext.Consumer>
        ),
        dataIndex: "time_travel",
        sorter: false,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.a.action}</LangContext.Consumer>
        ),
        key: "action_btn",
        render: (text, record, index) => {
          return (
            <div>
              <Tooltip
                placement="left"
                title={
                  <LangContext.Consumer>
                    {(i18n) => i18n.e.edit}
                  </LangContext.Consumer>
                }
                arrowPointAtCenter
                style={{
                  padding: "0px 1px 0px 0px",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                <Button
                  type="link"
                  size="small"
                  onClick={() => this.showModalEdit(record.id)}
                  style={{ padding: "0px 5px 0px 5px" }}
                >
                  <Icon type="edit" />
                </Button>
              </Tooltip>

              <Tooltip
                placement="left"
                title={
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.delete}
                  </LangContext.Consumer>
                }
                arrowPointAtCenter
                style={{
                  padding: "0px 1px 0px 0px",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                <Popconfirm
                  title={
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.doYouWantToDeleteTheseItems}
                    </LangContext.Consumer>
                  }
                  onConfirm={() => {
                    let self = this;
                    self.deleteDetail(record.id);
                  }}
                >
                  <a href="#" style={{ padding: "0px 5px 0px 5px" }}>
                    <Icon type="delete" />
                  </a>
                </Popconfirm>
              </Tooltip>
            </div>
          );
        },
      },
    ];
  }

  componentDidMount() {
    this.props.loadRoutemasterdetail(this.props.route_master_id, true);
  }

  deleteDetail = (id) => {
    let self = this.props;
    axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/routemaster/detail/delete/${id}`,
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        }
      )
      .then(function(response) {
        self.loadRoutemasterdetail(self.route_master_id, true);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  showModalEdit = (id) => {
    if (this.props.route_master_id != "") {
      this.props.getRoutemasterdetailByID(id, true);
    }
    this.setState({
      visible: true,
      route_master_detail_id: id,
      edit_popup: true,
    });
  };

  handleOk = (values, loading) => {
    if (this.state.route_master_detail_id == 0) {
      this.props.createRoutemasterdetail(values, this.props.route_master_id);
    } else {
      this.props.updateRoutemasterdetail(
        values,
        this.state.route_master_detail_id,
        this.props.route_master_id
      );
    }

    this.setState({
      visible: false,
      route_master_detail_id: 0,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      route_master_detail_id: 0,
    });
  };

  render() {
    const columns = this.columns.map((col) => col);

    return (
      <GeneralStyledContent>
        <Row gutter={24} type="flex" justify="end" style={{ height: "38px" }}>
          <Col span={18}>
            <Button size="small" onClick={this.showModal}>
              <Icon type="plus" style={{ marginRight: "5px" }} />
              <LangContext.Consumer>
                {(i18n) => i18n.a.add}
              </LangContext.Consumer>
            </Button>
          </Col>
          <Col span={4} />
          <Col span={2}></Col>
        </Row>

        <Row>
          <Table
            bordered
            size="small"
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={this.props.routemaster.routemasterdetailLists}
            // pagination={{
            //   pageSize: this.state.pageSize,
            //   total: this.state.deliverTotal,
            //   current: this.state.page
            // }}
            pagination={false}
            loading={this.props.routemaster.routemasterdetailLoading}
            // onChange={this.handleTableChange}
          />
        </Row>

        <FormDetail
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          loadLocation={this.props.loadLocation}
          loadLocationcode={this.props.loadLocationcode}
          locationLists={this.props.routemaster.locationLists}
          locationcodeLists={this.props.routemaster.locationcodeLists}
          route_master_detail_id={this.state.route_master_detail_id}
          route_master_id={this.props.route_master_id}
          routemasterDetails={this.props.routemaster.routemasterDetails}
          check_routemasterdetail={
            this.props.routemaster.check_routemasterdetail
          }
          loadingLocationCode={this.props.routemaster.loadingLocationCode}
          loadingLocationName={this.props.routemaster.loadingLocationName}
          loadingEditDetail={this.props.routemaster.loadingEditDetail}
          auth={this.props.auth}
        />
      </GeneralStyledContent>
    );
  }
}

export default TableDetail;
