import React from "react";
import { Switch, Route } from "react-router-dom";
import Servicerepairdevice from "./Index";
import Add from "./Add";
import Historys from "./History";
export default () => (
  <Switch>
    <Route exact path="/servicerepairdevice" component={Servicerepairdevice} />
    <Route path="/servicerepairdevice/create/:id/:company_id" component={Add} />
    <Route path="/servicerepairdevice/historys/:id" component={Historys} />
  </Switch>
);
