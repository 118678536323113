import { createTypes, createAction } from "lib/action";

const LOAD_TRUCKENGINEOFF = createTypes("truckengineoff", "load");
const LOAD_VEHICLE_TRUCKENGINEOFF = createTypes(
  "vehicle_truckengineoff",
  "load"
);
const LOAD_VEHICLE_TYPE_TRUCKENGINEOFF = createTypes("vehicletype_truckengineoff", "load");

const loadTruckengineoff = {
  request: (data, loading) =>
    createAction(LOAD_TRUCKENGINEOFF.REQUEST, { data, loading }),
  success: data => createAction(LOAD_TRUCKENGINEOFF.SUCCESS, { data }),
  failure: () => createAction(LOAD_TRUCKENGINEOFF.FAILURE)
};

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_TRUCKENGINEOFF.REQUEST, {
      vehicletypeID,
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_VEHICLE_TRUCKENGINEOFF.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TRUCKENGINEOFF.FAILURE)
};

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_TRUCKENGINEOFF.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_TYPE_TRUCKENGINEOFF.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_TRUCKENGINEOFF.FAILURE)
};

export {
  loadTruckengineoff,
  LOAD_TRUCKENGINEOFF,
  loadVehicle,
  LOAD_VEHICLE_TRUCKENGINEOFF,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_TRUCKENGINEOFF
};
