const initialValuesPolicy = {
  policyType: "SZ",
  dataType: "D",
  name: "",
  value_yellow: "",
  value_red: "",
  value_red2: "",
  timepicker: "00:00:00",
  timepicker_red: "00:00:00",
  period: "1",
  vehicleGroup: "all",
  vehicle: "",
  vehicleSelected: [],
  startTimePickerNight: "19:00",
  endTimePickerNight: "06:00",
  zone: null,
  stop_point: "-",
  locationType: "INSIDE",
  typeLocation: [],
  day_duration: 0,
  day_duration_red: 0,
  BREAK_WARNING_TIME: "2",
  INTERVAL_WARNING: "2",
  INTERVAL_POLICY: "2",
  ringToneYellow: "",
  ringToneRed: "",
  ringToneRed2: "",
  ringToneYellowVCD: [
    {
      audio: "",
      time: "5",
    },
  ],
  min_temp: "0",
  max_temp: "10",
  wigp_start_time: "00:00:00",
  wigp_end_time: "00:00:00",
  wltl_dur: "10",
  wltd_dur: "1",
  wltd_distance: "10",
  pto_on: "0",
  boom_on: "0",
  parkingTime: "00:30:00",
  approachRange: "",
  drivingSpeed: "5",
  drivingTimePolicy: "7",
  warningAudioForParkingTimeComplete: "",
  warningAudioForNotStoppingInTime: "",
  geofence: null,
};

const checkPayload = (dataForm, LineToken, company_id) => {
  let defaultKey = {
    type: dataForm.policyType,
    policy_type: dataForm.dataType,
    name: dataForm.name,
    vehicles: dataForm.vehicleSelected,
    line_token: LineToken.setting,
    company_id,
  };

  switch (dataForm.policyType) {
    case "SZ":
      let speedDefault = {
        alert_speed_duration: dataForm.timepicker,
        break_warning_time: dataForm.BREAK_WARNING_TIME,
        period: dataForm.period,
      };

      let alarmKey = {
        "vel-yel": dataForm.value_yellow,
        "vel-red": dataForm.value_red,
        "vel-interval-warning": dataForm.INTERVAL_WARNING,
        "vel-interval-policy": dataForm.INTERVAL_POLICY,
        "vel-audio-warning": dataForm.ringToneYellow,
        "vel-audio-policy": dataForm.ringToneRed,
      };

      if (["D", "R", "DH", "UH"].includes(dataForm.dataType)) {
        return {
          ...defaultKey,
          ...speedDefault,
          ...alarmKey,
        };
      } else if (dataForm.dataType == "N") {
        return {
          ...defaultKey,
          ...speedDefault,
          ...alarmKey,
          start_time: dataForm.startTimePickerNight,
          end_time: dataForm.endTimePickerNight,
        };
      } else if (dataForm.dataType == "AB") {
        return {
          ...defaultKey,
          alarm_acc: { acc: dataForm.value_red, audioId: dataForm.ringToneRed },
          alarm_brake: {
            brake: dataForm.value_red2,
            audioId: dataForm.ringToneRed2,
          },
        };
      } else if (dataForm.dataType == "RZ" || dataForm.dataType == "GZ") {
        let obj = {
          ...defaultKey,
          ...speedDefault,
          alert_speed_duration: dataForm.timepicker,
          startTimePickerNight: dataForm.startTimePickerNight,
          endTimePickerNight: dataForm.endTimePickerNight,
          geofence: dataForm.zone,
          geoF: dataForm.geofence,
        };
        let temp = obj.geoF;
        obj[`polygon-${dataForm.zone}`] = temp;
        delete obj.geoF;
        return obj;
      } else if (dataForm.dataType == "SZ") {
        let obj = {
          ...defaultKey,
          ...speedDefault,
          ...alarmKey,
          geofence: dataForm.zone,
          start_time: dataForm.wigp_start_time,
          end_time: dataForm.wigp_end_time,
          extend_radius: dataForm.approachRange,
          extend_radius_audio: dataForm.ringToneRed2,
        };
        let temp = obj.geoF;
        obj[`polygon-${dataForm.zone}`] = temp;
        delete obj.geoF;
        return obj;
      } else {
        return {
          ...defaultKey,
          ...speedDefault,
          ...alarmKey,
          geofence: dataForm.zone,
        };
      }

    case "EA":
      if (["AI", "APON", "AFUA", "A_WDLR"].includes(dataForm.dataType)) {
        return {
          ...defaultKey,
          stop_point: dataForm.stop_point,
          location: dataForm.typeLocation,
          day_duration: dataForm.day_duration,
          duration: dataForm.timepicker,
        };
      } else if (dataForm.dataType == "AS") {
        return {
          ...defaultKey,
          stop_point: dataForm.stop_point,
          location: dataForm.typeLocation,
          // day_duration: dataForm.day_duration,
          // duration: dataForm.timepicker,
          start_time: dataForm.wigp_start_time,
          end_time: dataForm.wigp_end_time,
          extend_radius: dataForm.approachRange,
          extend_radius_audio: dataForm.warningAudioForNotStoppingInTime,
          duration_yel: dataForm.timepicker,
          day_duration_yel: dataForm.day_duration,
          duration_red: dataForm.timepicker_red,
          day_duration_red: dataForm.day_duration_red,
          interval_warning: dataForm.INTERVAL_WARNING,
          interval_policy: dataForm.INTERVAL_POLICY,
          audio_warning: dataForm.ringToneRed,
          audio_policy: dataForm.warningAudioForParkingTimeComplete,
        };
      } else if (dataForm.dataType == "ATEMP") {
        return {
          ...defaultKey,
          min_temp: dataForm.min_temp,
          max_temp: dataForm.max_temp,
        };
      } else if (dataForm.dataType == "A_WIGP") {
        return {
          ...defaultKey,
          wigp_start_time: dataForm.wigp_start_time,
          wigp_end_time: dataForm.wigp_end_time,
        };
      } else if (dataForm.dataType == "A_WLTL") {
        return {
          ...defaultKey,
          wltl_dur: dataForm.wltl_dur,
        };
      } else if (dataForm.dataType == "A_WLTD") {
        return {
          ...defaultKey,
          wltd_dur: dataForm.wltd_dur,
          wltd_distance: dataForm.wltd_distance,
        };
      } else if (dataForm.dataType == "A_VMOFLT") {
        return {
          ...defaultKey,
          wltd_dur: dataForm.wltd_dur,
        };
      } else if (dataForm.dataType == "A_VCD") {
        return {
          ...defaultKey,
          driving_speed: dataForm.drivingSpeed,
          driving_time_policy: {
            time: dataForm.drivingTimePolicy,
            audio: dataForm.ringToneRed,
          },
          driving_time_warning: dataForm.ringToneYellowVCD,
          parking_time: dataForm.parkingTime,
          parking_end_audio: dataForm.warningAudioForParkingTimeComplete,
          parking_not_end_audio: dataForm.warningAudioForNotStoppingInTime,
        };
      } else if (dataForm.dataType == "A_VDC") {
        return {
          ...defaultKey,
          duration: dataForm.parkingTime,
        };
      } else if (
        ["A_NO_PARKING_AREA", "A_RISK_AREA"].includes(dataForm.dataType)
      ) {
        return {
          ...defaultKey,
          stop_point: dataForm.stop_point,
          location: dataForm.typeLocation,
          day_duration: dataForm.day_duration,
          duration: dataForm.timepicker,
        };
      }
      break;
    case "SE":
      if (dataForm.dataType == "SEVT") {
        return {
          ...defaultKey,
          input1_vel: dataForm.pto_on,
          input2_vel: dataForm.boom_on,
        };
      }
      break;
    case "TR":
      break;
    case "MT":
      break;

    default:
      break;
  }
};

const positionList = [
  {
    value: "-",
    name: "notSpecified",
  },
  {
    value: "INSIDE",
    name: "insidePolicy",
  },
  {
    value: "OUTSIDE",
    name: "outsidePolicy",
  },
];

const hours = Array.from({ length: 8 }, (_, index) => index + 1);

const availableOptions = [
  { value: "5", label: "5 นาที" },
  { value: "10", label: "10 นาที" },
  { value: "15", label: "15 นาที" },
  { value: "20", label: "20 นาที" },
  { value: "25", label: "25 นาที" },
  { value: "30", label: "30 นาที" },
  { value: "35", label: "35 นาที" },
  { value: "40", label: "40 นาที" },
  { value: "45", label: "45 นาที" },
  { value: "50", label: "50 นาที" },
  { value: "55", label: "55 นาที" },
  { value: "60", label: "60 นาที" },
];

export {
  initialValuesPolicy,
  checkPayload,
  positionList,
  hours,
  availableOptions,
};
