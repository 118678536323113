import React, { Component } from "react";

import { Col, message, Row, Spin } from "antd";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import * as actions from "../actions";
import * as manageotpActions from "../../manage-otp/actions";
import { connect } from "react-redux";
import Forms from "./Forms";
const initialValues = {
  display_name: "",
  companies_id: 0,
  camera_layout_type_id: "",
  user_id: [],
  group_list: [{ group_name: "", vehicle_list: [] }],
  all_vehicle_list: [],
  demoBox: {},
  demoGroup: false,
  channel: "",
};
class Create extends Component {
  state = {
    ...initialValues,
    user_id: [this.props.profile.id],
  };
  handleOnSubmit = (value) => {
    this.props.create(
      { ...value, companies_id: this.props.profile.company_id },
      this.generateMsg
    );
  };
  componentDidMount() {
    this.props.loadVehicles(this.props.profile.company_id);
    this.props.loadUser(this.props.profile.company_id);
    this.props.loadLayout();
  }
  generateMsg = () => {
    switch (this.props.managedashboard.status) {
      case "success":
        console.log("first", this.props.managedashboard.duplicateVehicle);
        message.success(this.props.managedashboard.msg);
        window.location.assign(
          "/manage-dashboard-monitoring/edit/" +
            this.props.managedashboard.duplicateVehicle.display_id
        );
        break;
      case "fail":
        message.error(this.props.managedashboard.msg);
        break;
      default:
        break;
    }
  };
  render() {
    return (
      <AuthorizeComponent matching_name="manage-dashboard-monitoring">
        <Spin spinning={this.props.managedashboard.loading}>
          <GeneralStyledContent>
            <Row gutter={24}>
              <Col span={24}>
                <Breadcrumb
                  customeurl={["/manage-dashboard-monitoring", null, null]}
                  match={this.props.match}
                  style={{ margin: "0px 0px 14px 10px" }}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Forms
                  initialValues={this.state}
                  userList={this.props.managedashboard.userList}
                  vehicleList={this.props.manageotp.vehicleLists}
                  layoutList={this.props.managedashboard.layout}
                  handleOnSubmit={this.handleOnSubmit}
                  duplicateVehicle={[]}
                />
              </Col>
            </Row>
            <Row type="flex" justify="end" style={{ padding: "15px" }}>
              <Col pan={24} align="right"></Col>
            </Row>
          </GeneralStyledContent>
        </Spin>
      </AuthorizeComponent>
    );
  }
}
const mapStateToProps = ({
  auth,
  auth: { profile },
  managedashboard,
  manageotp,
}) => ({
  auth,
  profile,
  managedashboard,
  manageotp,
});

const mapDispatchToProps = {
  loadVehicles: manageotpActions.loadAllVehicle.request,
  loadUser: actions.loadManageDashboardUser.request,
  loadLayout: actions.loadLayoutDisplay.request,
  create: actions.createManageDashboard.request,
};
export default connect(mapStateToProps, mapDispatchToProps)(Create);
