import { createTypes, createAction } from "lib/action";
import create from "antd/lib/icon/IconFont";
const LOAD_COMPANY = createTypes("loadcompany", "load");
const LOAD_COMPANY_DETAIL = createTypes("loadcompanydetail", "load");
const CREATE_COMPANY = createTypes("createcompany", "create");
const UPDATE_COMPANY = createTypes("updatecompany", "update");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
    return {
        type: REMOVE_LISTS
    };
}

const loadCompany = {
    request: (data, loading) =>
        createAction(LOAD_COMPANY.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(LOAD_COMPANY.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_COMPANY.FAITLURE)  
};

const loadCompanyDetail = {
    request: (data, loading) =>
        createAction(LOAD_COMPANY_DETAIL.REQUEST, { data, loading}),
    success: (lists) =>
        createAction(LOAD_COMPANY_DETAIL.SUCCESS, {lists}),
    failure: () => createAction(LOAD_COMPANY_DETAIL.FAILURE)
};

const createCompany = {
    request: (data, loading) =>
        createAction(CREATE_COMPANY.REQUEST, { data, loading}),
    success: res => createAction(CREATE_COMPANY.SUCCESS, { res }),
        failure: () => createAction(CREATE_COMPANY.FAILURE)
};

const updateCompany = {
    request: (data, loading) =>
        createAction(UPDATE_COMPANY.REQUEST, {data, loading}),
    success: res => createAction(UPDATE_COMPANY.SUCCESS, { res }),
        failure: () => createAction(UPDATE_COMPANY.FAILURE)
};

export {
    LOAD_COMPANY, loadCompany,
    LOAD_COMPANY_DETAIL, loadCompanyDetail,
    CREATE_COMPANY, createCompany,
    UPDATE_COMPANY, updateCompany,
    REMOVE_LISTS, removeLists
};