import { LOAD_GRAPHSENSORS } from "./actions";

const initialState = {
  loading: false,
  msg: "",
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_GRAPHSENSORS.REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case LOAD_GRAPHSENSORS.SUCCESS:
      return {
        loading: false,
        msg: "success",
        data: action.payload.data,
      };
    case LOAD_GRAPHSENSORS.FAILURE: {
      return {
        loading: false,
        data: [],
        msg: action.payload.error,
      };
    }
    default: {
      return state;
    }
  }
};
