import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";
import { Button, Form, Input, Row, Col, DatePicker } from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
const FormItem = Form.Item;
const { RangePicker } = DatePicker;

export default class Filter extends Component {
  render() {
    const { OnClose, onSubmit } = this.props;

    return (
      <div>
        <Formik
          initialValues={{
            plate_no: "",
            code: ""
          }}
          validationSchema={yup.object().shape({
            // name: yup.string.required("กรุณาเลือกช่วงวันที่"),
            // code: yup.string.required("กรุณาเลือกรถ")
          })}
          onSubmit={values => {
            const loading = true;
            onSubmit(values, loading);
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleFocus,
            setFieldValue,
            resetForm
          }) => (
              <Row type="flex">
                <LangContext.Consumer>
                  {
                    i18n => <Form onSubmit={handleSubmit}>
                      <FormItem
                        label={i18n.p.plateNo}
                        validateStatus={
                          touched.plate_no && errors.plate_no && "error"
                        }
                        help={touched.plate_no && errors.plate_no}
                      >
                        <Input
                          placeholder={i18n.p.plateNo}
                          name="plate_no"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.plate_no}
                        />
                      </FormItem>

                      <Form.Item
                        label={i18n.v.vehicleCode}
                        validateStatus={
                          touched.code &&
                          errors.code &&
                          "error"
                        }
                        help={
                          touched.code &&
                          errors.code
                        }
                      >
                        <Input
                          placeholder={i18n.v.vehicleCode}
                          name="code"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.code}
                        />
                      </Form.Item>

                      <FormItem>
                        <Row gutter={24}>
                          <Col span={6}>
                            <Button
                              onClick={OnClose}
                              style={{ paddingLeft: 5 }}
                            >
                              {i18n.c.cancel}
                            </Button>
                          </Col>
                          <Col span={2} />
                          <Col span={6}>
                            <Button htmlType="submit" type="primary">
                              {i18n.a.apply}
                            </Button>
                          </Col>
                        </Row>
                      </FormItem>
                    </Form>
                  }
                </LangContext.Consumer>
              </Row>
            )}
        />
      </div>
    );
  }
}
