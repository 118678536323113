import { LOAD_ALERTDRIVERLICENSE, LOAD_DRIVER_ALERTDRIVERLICENSE } from "./actions";

const initialState = {
  alertdriverlicenseUrl: "",
  driverLists: [],
  alertdriverlicenseLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ALERTDRIVERLICENSE.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        alertdriverlicenseLoading: loading
      };
    case LOAD_ALERTDRIVERLICENSE.SUCCESS:
      return {
        ...state,
        alertdriverlicenseUrl: action.payload.data.data,
        alertdriverlicenseLoading: false
      };
    case LOAD_DRIVER_ALERTDRIVERLICENSE.SUCCESS:
      return {
        ...state,
        driverLists: action.payload.data.data
      };
    default:
      return state;
  }
};
