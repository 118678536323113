import { createTypes, createAction } from "lib/action";

const LOAD_TAXISOS = createTypes("taxisos", "load");
const LOAD_VEHICLE_TAXISOS = createTypes(
  "vehicle_taxisos",
  "load"
);
const LOAD_VEHICLE_TYPE_TAXISOS = createTypes(
  "vehicletype_taxisos",
  "load"
);

const loadTaxiSos = {
  request: (data, loading) =>
    createAction(LOAD_TAXISOS.REQUEST, { data, loading }),
  success: data => createAction(LOAD_TAXISOS.SUCCESS, { data }),
  failure: () => createAction(LOAD_TAXISOS.FAILURE)
};

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_TAXISOS.REQUEST, {
      vehicletypeID,
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_VEHICLE_TAXISOS.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TAXISOS.FAILURE)
};

const loadVehicleType = {
  request: () =>
    createAction(LOAD_VEHICLE_TYPE_TAXISOS.REQUEST, {}),
  success: data =>
    createAction(LOAD_VEHICLE_TYPE_TAXISOS.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_TAXISOS.FAILURE)
};

export {
  loadTaxiSos,
  LOAD_TAXISOS,
  loadVehicle,
  LOAD_VEHICLE_TAXISOS,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_TAXISOS
};
