import {
    LOAD_ASSIGNFIRMWARE,
    ASSIGN_FIRMWARE,
    REMOVE_LISTS
} from "./actions";

const initialState = {
    lists: [],
    total: 1,
    listsDetail: [],
    loading: false,
    assignfirmwareLoading: false,
    check: true,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REMOVE_LISTS:
            return initialState;
        
        case LOAD_ASSIGNFIRMWARE.REQUEST:
            const { loading } = action.payload.data;
            return {
                ...state,
                loading: loading,
            }

        case LOAD_ASSIGNFIRMWARE.SUCCESS:
            const { page } = action.payload;
               
            for (let i = 0; i < action.payload.lists.data.length; i++) {
                action.payload.lists.data[i].rowNumber = i + 1;
                    if (page > 1)
                        action.payload.lists.data[i].rowNumber =
                            action.payload.lists.data[i].rowNumber + (page - 1) * 10;
            }
            console.log(action.payload.lists.data)
            return {
                ...state,
                lists: action.payload.lists.data,
                total: 
                    action.payload.lists.total - 1 <= 0
                    ? 1
                    : action.payload.lists.total <= 0
                    ? 1
                    : action.payload.lists.total,
                    loading: false,
                draw: Math.floor(Math.random() * 999 + 1)
            };

        case ASSIGN_FIRMWARE.REQUEST:
            return {
                ...state,
                assignfirmwareLoading: true,
            }
        
        case ASSIGN_FIRMWARE.SUCCESS:
            return {
                ...state,
                assignfirmwareLoading: false,
            }

        default:
            return state;
    }
}