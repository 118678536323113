import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { debounce } from "debounce";
import { Row, Col, Form, Radio, Select } from "antd";

import { FaMapMarkerAlt, FaMap } from "react-icons/fa";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { autoGenKey } from "../../../../../../lib/helper";

const { Option, OptGroup } = Select;

class Stop extends Component {
  state = {
    zoneList: [],
  };

  componentDidMount() {
    let self = this;
    fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/dropdownlist/getzone`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.auth.accessToken}`,
        },
        body: JSON.stringify({
          company_id: this.props.auth.profile.company_id,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        self.setState({ zoneList: data });
      });
  }

  render() {
    return (
      <Fragment>
        {this.props.withArea && (
          <div>
            <Row>
              <Col xs={24} sm={18} md={13} lg={13} xl={10}>
                <Form.Item label="ตำแหน่ง">
                  <Radio.Group
                    name="apply_area"
                    defaultValue={
                      typeof this.props.values.apply_area == "undefined" ||
                      this.props.values.apply_area == "-"
                        ? "INSIDE"
                        : this.props.values.apply_area
                    }
                    onChange={(e) => {
                      this.props.setFieldValue("apply_area", e.target.value);
                    }}
                  >
                    <Radio value={"INSIDE"}>ข้างใน</Radio>
                    <Radio value={"OUTSIDE"}>ข้างนอก</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} sm={18} md={13} lg={13} xl={10}>
                <Form.Item
                  label="พื้นที่"
                  required={true}
                  validateStatus={
                    this.props.touched.area && this.props.errors.area
                      ? "warning"
                      : undefined
                  }
                  help={
                    this.props.touched.area && this.props.errors.area
                      ? this.props.errors.area.substr(
                          this.props.errors.area.indexOf(" ") + 1
                        )
                      : undefined
                  }
                >
                  <Select
                    mode="multiple"
                    name="area"
                    placeholder="Please select"
                    defaultValue={this.props.values.area}
                    onChange={(e) => {
                      this.props.setFieldValue("area", e);
                    }}
                    maxTagCount={10}
                  >
                    <OptGroup label="ชนิดสถานที่">
                      <Option key={"Plant"}>
                        <FaMapMarkerAlt /> จุดโหลดของ
                      </Option>
                      <Option key={"Site"}>
                        <FaMapMarkerAlt /> จุดส่งของ
                      </Option>
                      <Option key={"ParkingLot"}>
                        <FaMapMarkerAlt /> ลานจอดรถ
                      </Option>
                      <Option key={"RestArea"}>
                        <FaMapMarkerAlt /> จุดพักรถ
                      </Option>
                      <Option key={"GasStation"}>
                        <FaMapMarkerAlt /> ปั๊มน้ำมัน
                      </Option>
                      <Option key={"CarService"}>
                        <FaMapMarkerAlt /> ศูนย์บริการ
                      </Option>
                      <Option key={"BS"}>
                        <FaMapMarkerAlt /> Bus Stop Point
                      </Option>

                      <Option key={"BS1"}>
                        <FaMapMarkerAlt /> สถานที่ชั่วคราว
                      </Option>
                      <Option key={"NoParking"}>
                        <FaMapMarkerAlt /> จุดห้ามจอด
                      </Option>
                    </OptGroup>
                    {/* <OptGroup label="พื้นที่">
                  {this.state.zoneList.map(ele => (
                    <Option key={ele.id}>
                      <FaMap style={{ marginRight: "10px" }} />
                      {ele.name}
                    </Option>
                  ))}
                </OptGroup> */}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}
        <Row>
          <Col xs={24} sm={18} md={13} lg={13} xl={10}>
            <Form.Item label="ระยะเวลา">
              <Select
                key={autoGenKey("condition-select")}
                name="duration"
                defaultValue={this.props.values.duration}
                onChange={(e) => {
                  this.props.setFieldValue("duration", e);
                }}
              >
                <Option key={0} value={0}>
                  <LangContext.Consumer>
                    {(i18n) => "ทันทีที่ฝ่าฝืน"}
                  </LangContext.Consumer>
                </Option>
                <Option key={60} value={60}>
                  <LangContext.Consumer>
                    {(i18n) => "> 1 นาที"}
                  </LangContext.Consumer>
                </Option>
                <Option key={300} value={300}>
                  <LangContext.Consumer>
                    {(i18n) => "> 5 นาที"}
                  </LangContext.Consumer>
                </Option>
                <Option key={600} value={600}>
                  <LangContext.Consumer>
                    {(i18n) => "> 10 นาที"}
                  </LangContext.Consumer>
                </Option>
                <Option key={900} value={900}>
                  <LangContext.Consumer>
                    {(i18n) => "> 15 นาที"}
                  </LangContext.Consumer>
                </Option>
                <Option key={1800} value={1800}>
                  <LangContext.Consumer>
                    {(i18n) => "> 30 นาที"}
                  </LangContext.Consumer>
                </Option>
                <Option key={3600} value={3600}>
                  <LangContext.Consumer>
                    {(i18n) => "> 1 ชั่วโมง"}
                  </LangContext.Consumer>
                </Option>

                <Option key={5400} value={5400}>
                  <LangContext.Consumer>
                    {(i18n) => "> 1 ชั่วโมง 30 นาที"}
                  </LangContext.Consumer>
                </Option>

                <Option key={7200} value={7200}>
                  <LangContext.Consumer>
                    {(i18n) => "> 2 ชั่วโมง"}
                  </LangContext.Consumer>
                </Option>

                <Option key={14400} value={14400}>
                  <LangContext.Consumer>
                    {(i18n) => "> 4 ชั่วโมง"}
                  </LangContext.Consumer>
                </Option>

                <Option key={86340} value={86340}>
                  <LangContext.Consumer>
                    {(i18n) => "> 1 วัน"}
                  </LangContext.Consumer>
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
export default connect(
  ({ policyState, auth }) => ({ policyState, auth }),
  {}
)(Stop);
