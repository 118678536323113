import { createTypes, createAction } from "lib/action";

const MATCH_USER = createTypes("manage_repairdevice", "update");

const matchUser = {
  request: (data, loading) =>
    createAction(MATCH_USER.REQUEST, { data, loading }),
  success: (res) => createAction(MATCH_USER.SUCCESS, { res }),
  failure: () => createAction(MATCH_USER.FAILURE),
};

export { MATCH_USER, matchUser };
