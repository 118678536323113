import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getlocalstorage } from "../../../constants/local_storage";
import * as actions from "./actions";
import moment from "moment";

function* loadDrivinglicenseexpire(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const {
    data: { eventRange, type_file },
  } = action.payload;

  const Date = moment(eventRange).format("MM-YYYY");

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_GOLANG_MONGO}/reports/go/drivinglicenseexpire`,
      {
        company_id: COMPANY_ID,
        eventRange: Date,
        type_file,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadDrivinglicenseexpire.success(res.data));
    window.location = res.data.result;
  } catch (err) {
    yield put(actions.loadDrivinglicenseexpire.failure());
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(
      actions.LOAD_DRIVINGLICENSEEXPIRE.REQUEST,
      loadDrivinglicenseexpire
    ),
  ]);
}

export { loadDrivinglicenseexpire };
