import {
  LOAD_VEHICLE_BY_PLANT,
  LOAD_PLANT_BY_KEYWORD,
  LOAD_WITHDRAW_FUEL_CREDIT_REPORT,
} from "./actions";

const initialState = {
  vehicleLists: [],
  plantList: [],
  report: "",
  msg: "",
  status: "",
  loadingSelect: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_WITHDRAW_FUEL_CREDIT_REPORT.REQUEST:
      return {
        ...state,
        report: "",
        loading: true,
      };
    case LOAD_VEHICLE_BY_PLANT.REQUEST:
      return {
        ...state,
        vehicleLists: [],
        loading: true,
      };
    case LOAD_PLANT_BY_KEYWORD.REQUEST:
      return {
        ...state,
        plantList: [],
        loading: true,
      };
    case LOAD_WITHDRAW_FUEL_CREDIT_REPORT.REQUEST:
      return {
        ...state,
        report: action.payload.data,
        loading: false,
      };
    case LOAD_VEHICLE_BY_PLANT.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data,
        loading: false,
      };
    case LOAD_PLANT_BY_KEYWORD.SUCCESS:
      return {
        ...state,
        plantList: action.payload.data,
        loading: false,
      };
    case LOAD_WITHDRAW_FUEL_CREDIT_REPORT.SUCCESS:
      return {
        ...state,
        report: action.payload.data,
        loading: false,
      };
    case LOAD_WITHDRAW_FUEL_CREDIT_REPORT.FAILURE:
    case LOAD_VEHICLE_BY_PLANT.FAILURE:
    case LOAD_PLANT_BY_KEYWORD.FAILURE:
      return {
        ...state,
        msg: action.payload.err,
        loading: false,
        status: "fail",
      };
    default:
      return state;
  }
};
