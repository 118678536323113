import {
  LOAD_TEMPERATURE,
  LOAD_VEHICLE_TEMPERATURE,
  LOAD_VEHICLE_TYPE_TEMPERATURE
} from "./actions";

const initialState = {
  temperatureUrl: "",
  vehicleLists: [],
  vehicleTypeLists: [],
  temperatureLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TEMPERATURE.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        temperatureLoading: loading
      };
    case LOAD_TEMPERATURE.SUCCESS:
      return {
        ...state,
        // temperatureUrl: action.payload.data.result,
        temperatureLoading: false
      };
    case LOAD_VEHICLE_TEMPERATURE.REQUEST:
      const { loading_vehicle } = action.payload;
      return {
        ...state,
        temperatureLoading: loading_vehicle
      };
    case LOAD_VEHICLE_TEMPERATURE.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        temperatureLoading: false
      };
    case LOAD_VEHICLE_TYPE_TEMPERATURE.SUCCESS:
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data
      };
    default:
      return state;
  }
};
