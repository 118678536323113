import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { debounce } from "debounce";
import { Row, Col, Form, Input, Select } from "antd";

import { FaMap } from "react-icons/fa";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { autoGenKey } from "../../../../../../lib/helper";

const { Option, OptGroup } = Select;

class NoEntry extends Component {
  state = {
    zoneList: []
  };

  componentDidMount() {
    let self = this;
    fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/dropdownlist/getzone`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.auth.accessToken}`
        },
        body: JSON.stringify({
          company_id: this.props.auth.profile.company_id
        })
      }
    )
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        self.setState({ zoneList: data });
      });
  }

  render() {
    return (
      <Fragment>
        <Row>
          <Col xs={24} sm={18} md={13} lg={13} xl={10}>
            <Form.Item
              label="พื้นที่"
              required={true}
              validateStatus={
                this.props.touched.area && this.props.errors.area
                  ? "warning"
                  : undefined
              }
              help={
                this.props.touched.area && this.props.errors.area
                  ? this.props.errors.area.substr(
                      this.props.errors.area.indexOf(" ") + 1
                    )
                  : undefined
              }
            >
              <Select
                name="area"
                placeholder="Please select"
                defaultValue={this.props.values.area}
                onChange={e => {
                  this.props.setFieldValue("area", e);
                }}
                maxTagCount={10}
              >
                <OptGroup label="พื้นที่">
                  {this.state.zoneList.map(ele => (
                    <Option key={ele.id}>
                      <FaMap style={{ marginRight: "10px" }} />
                      {ele.name}
                    </Option>
                  ))}
                </OptGroup>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={18} md={13} lg={13} xl={10}>
            <Form.Item label="ระยะเวลา">
              <Select
                key={autoGenKey("condition-select")}
                name="duration"
                defaultValue={this.props.values.duration}
                onChange={e => {
                  this.props.setFieldValue("duration", e);
                }}
              >
                <Option key={0} value={0}>
                  <LangContext.Consumer>
                    {i18n => "ทันทีที่ฝ่าฝืน"}
                  </LangContext.Consumer>
                </Option>
                <Option key={5} value={5}>
                  <LangContext.Consumer>
                    {i18n => "> 5 วินาที"}
                  </LangContext.Consumer>
                </Option>
                <Option key={10} value={10}>
                  <LangContext.Consumer>
                    {i18n => "> 10 วินาที"}
                  </LangContext.Consumer>
                </Option>
                <Option key={15} value={15}>
                  <LangContext.Consumer>
                    {i18n => "> 15 วินาที"}
                  </LangContext.Consumer>
                </Option>
                <Option key={30} value={30}>
                  <LangContext.Consumer>
                    {i18n => "> 30 วินาที"}
                  </LangContext.Consumer>
                </Option>
                <Option key={60} value={60}>
                  <LangContext.Consumer>
                    {i18n => "> 60 วินาที"}
                  </LangContext.Consumer>
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
export default connect(
  ({ policyState, auth }) => ({ policyState, auth }),
  {}
)(NoEntry);
