import { createTypes, createAction } from "lib/action";

const LOAD_TRIP_DELIVERY = createTypes("tripmasterdata_delivery", "load");
const LOAD_TRIP_DETAIL_DELIVERY = createTypes("tripdetailmasterdata_delivery", "load");
const CREATE_TRIP_DELIVERY = createTypes("tripmasterdata_delivery", "create");
const CREATE_DELIVER_DELIVERY = createTypes("delivery", "create");
const UPDATE_TRIP_DELIVERY = createTypes("tripmasterdata_delivery", "update");
const CLEAR_RESPONSE_DELIVERY = createTypes("response_delivery", "clear");
const LOAD_VEHICLE_DELIVERY = createTypes("vehicle_delivery", "load");

const LOAD_PRODUCTTYPE_DELIVERY = createTypes("producttype_delivery", "load");
const LOAD_PRODUCT_DELIVERY = createTypes("product_delivery", "load");

const LOAD_DELIVER_DELIVERY = createTypes("deliver_delivery", "load");
const LOAD_LOCATION_DELIVERY = createTypes("location_delivery", "load");
const LOAD_LOCATIONCODE_DELIVERY = createTypes("locationcode_delivery", "load");
const LOAD_DRIVER_DELIVERY = createTypes("driver_delivery", "load");

const LOAD_DELIVER_DETAIL_DELIVERY = createTypes("detail_delivery", "load");
const UPDATE_DELIVER_DELIVERY = createTypes("delivery", "update");
const LOAD_PRODUCTDELIVER_DELIVERY = createTypes("productdeliver_delivery", "load");
const CREATE_PRODUCTDELIVER_DELIVERY = createTypes("productdelivery", "create");

const CLEAR_LIST_DELIVERY = createTypes("list_delivery", "clear");


const loadTrip = {
  request: (data, loading) =>
    createAction(LOAD_TRIP_DELIVERY.REQUEST, { data, loading }),
  success: (lists, page) => createAction(LOAD_TRIP_DELIVERY.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_TRIP_DELIVERY.FAILURE)
};

const loadDeliverorder = {
  request: (data, loading) =>
    createAction(LOAD_DELIVER_DELIVERY.REQUEST, { data, loading }),
  success: (data) => createAction(LOAD_DELIVER_DELIVERY.SUCCESS, { data }),
  failure: () => createAction(LOAD_DELIVER_DELIVERY.FAILURE)
};

const loadProductDeliverorder = {
  request: (data, loading) =>
    createAction(LOAD_PRODUCTDELIVER_DELIVERY.REQUEST, { data, loading }),
  success: (data) => createAction(LOAD_PRODUCTDELIVER_DELIVERY.SUCCESS, { data }),
  failure: () => createAction(LOAD_PRODUCTDELIVER_DELIVERY.FAILURE)
};

const loadTripDetail = {
  request: (id) => createAction(LOAD_TRIP_DETAIL_DELIVERY.REQUEST, { id }),
  success: trip => createAction(LOAD_TRIP_DETAIL_DELIVERY.SUCCESS, { trip }),
  failure: () => createAction(LOAD_TRIP_DETAIL_DELIVERY.FAILURE)
};

const loadDeliverDetail = {
  request: (id) => createAction(LOAD_DELIVER_DETAIL_DELIVERY.REQUEST, { id }),
  success: data => createAction(LOAD_DELIVER_DETAIL_DELIVERY.SUCCESS, { data }),
  failure: () => createAction(LOAD_DELIVER_DETAIL_DELIVERY.FAILURE)
};

const createTrip = {
  request: (data, loading, history) =>
    createAction(CREATE_TRIP_DELIVERY.REQUEST, {
      data, loading, history
    }),
  success: res => createAction(CREATE_TRIP_DELIVERY.SUCCESS, { res }),
  failure: () => createAction(CREATE_TRIP_DELIVERY.FAILURE)
};


const createDeliver = {
  request: (data, trip_id, loading) =>
    createAction(CREATE_DELIVER_DELIVERY.REQUEST, {
      data, trip_id, loading
    }),
  success: data => createAction(CREATE_DELIVER_DELIVERY.SUCCESS, { data }),
  failure: () => createAction(CREATE_DELIVER_DELIVERY.FAILURE)
};

const createProductDeliver = {
  request: (data, deliver_id, loading) =>
    createAction(CREATE_PRODUCTDELIVER_DELIVERY.REQUEST, {
      data, deliver_id, loading
    }),
  success: data => createAction(CREATE_PRODUCTDELIVER_DELIVERY.SUCCESS, { data }),
  failure: () => createAction(CREATE_PRODUCTDELIVER_DELIVERY.FAILURE)
};

const updateTrip = {
  request: (data, trip_id, loading, history) =>
    createAction(UPDATE_TRIP_DELIVERY.REQUEST, {
      data, trip_id, loading, history
    }),
  success: res => createAction(UPDATE_TRIP_DELIVERY.SUCCESS, { res }),
  failure: () => createAction(UPDATE_TRIP_DELIVERY.FAILURE)
};

const updateDeliver = {
  request: (data, deliver_id, trip_id, loading) =>
    createAction(UPDATE_DELIVER_DELIVERY.REQUEST, {
      data, deliver_id, trip_id, loading
    }),
  success: data => createAction(UPDATE_DELIVER_DELIVERY.SUCCESS, { data }),
  failure: () => createAction(UPDATE_DELIVER_DELIVERY.FAILURE)
};


const loadDriver = {
  request: () => createAction(LOAD_DRIVER_DELIVERY.REQUEST, {}),
  success: data => createAction(LOAD_DRIVER_DELIVERY.SUCCESS, { data }),
  failure: () => createAction(LOAD_DRIVER_DELIVERY.FAILURE)
};

const loadVehicle = {
  request: () => createAction(LOAD_VEHICLE_DELIVERY.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_DELIVERY.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_DELIVERY.FAILURE)
};

const loadProducttype = {
  request: () => createAction(LOAD_PRODUCTTYPE_DELIVERY.REQUEST, {}),
  success: data => createAction(LOAD_PRODUCTTYPE_DELIVERY.SUCCESS, { data }),
  failure: () => createAction(LOAD_PRODUCTTYPE_DELIVERY.FAILURE)
};

const loadProduct = {
  request: (data) => createAction(LOAD_PRODUCT_DELIVERY.REQUEST, { data }),
  success: data => createAction(LOAD_PRODUCT_DELIVERY.SUCCESS, { data }),
  failure: () => createAction(LOAD_PRODUCT_DELIVERY.FAILURE)
};

const loadLocation = {
  request: data => createAction(LOAD_LOCATION_DELIVERY.REQUEST, { data }),
  success: data => createAction(LOAD_LOCATION_DELIVERY.SUCCESS, { data }),
  failure: () => createAction(LOAD_LOCATION_DELIVERY.FAILURE)
};

const loadLocationcode = {
  request: data => createAction(LOAD_LOCATIONCODE_DELIVERY.REQUEST, { data }),
  success: data => createAction(LOAD_LOCATIONCODE_DELIVERY.SUCCESS, { data }),
  failure: () => createAction(LOAD_LOCATIONCODE_DELIVERY.FAILURE)
};
const clearDataResponse = () => {
  return {
    type: CLEAR_RESPONSE_DELIVERY
  };
};

const clearList = () => {
  return {
    type: CLEAR_LIST_DELIVERY
  };
};


export {
  CREATE_TRIP_DELIVERY,
  createTrip,
  loadTrip,
  LOAD_TRIP_DELIVERY,
  LOAD_TRIP_DETAIL_DELIVERY,
  loadTripDetail,
  updateTrip,
  UPDATE_TRIP_DELIVERY,
  CLEAR_RESPONSE_DELIVERY,
  clearDataResponse,
  LOAD_VEHICLE_DELIVERY,
  loadVehicle,
  LOAD_PRODUCTTYPE_DELIVERY,
  loadProducttype,
  LOAD_DELIVER_DELIVERY,
  loadDeliverorder,
  LOAD_LOCATION_DELIVERY,
  loadLocation,
  LOAD_LOCATIONCODE_DELIVERY,
  loadLocationcode,
  LOAD_PRODUCT_DELIVERY,
  loadProduct,
  LOAD_DRIVER_DELIVERY,
  loadDriver,
  CREATE_DELIVER_DELIVERY,
  createDeliver,
  LOAD_DELIVER_DETAIL_DELIVERY,
  loadDeliverDetail,
  UPDATE_DELIVER_DELIVERY,
  updateDeliver,
  LOAD_PRODUCTDELIVER_DELIVERY,
  loadProductDeliverorder,
  CREATE_PRODUCTDELIVER_DELIVERY,
  createProductDeliver,
  CLEAR_LIST_DELIVERY,
  clearList
};
