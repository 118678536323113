import { createTypes, createAction } from "lib/action";

const LOAD_ALARMALERTBYPLATENOONYEAR = createTypes(
  "alarmalertbyplatenoonyear",
  "load"
);
const LOAD_VEHICLE_ALARMALERTBYPLATENOONYEAR = createTypes(
  "vehicle_alarmalertbyplatenoonyear",
  "load"
);
const LOAD_VEHICLE_TYPEONYEAR = createTypes("vehicletypeonyear", "load");

const loadAlarmAlertByPlatenoOnyear = {
  request: (data, loading) =>
    createAction(LOAD_ALARMALERTBYPLATENOONYEAR.REQUEST, { data, loading }),
  success: data =>
    createAction(LOAD_ALARMALERTBYPLATENOONYEAR.SUCCESS, { data }),
  failure: () => createAction(LOAD_ALARMALERTBYPLATENOONYEAR.FAILURE)
};

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_ALARMALERTBYPLATENOONYEAR.REQUEST, {
      vehicletypeID,
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_VEHICLE_ALARMALERTBYPLATENOONYEAR.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_ALARMALERTBYPLATENOONYEAR.FAILURE)
};

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPEONYEAR.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_TYPEONYEAR.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPEONYEAR.FAILURE)
};

export {
  loadAlarmAlertByPlatenoOnyear,
  LOAD_ALARMALERTBYPLATENOONYEAR,
  loadVehicle,
  LOAD_VEHICLE_ALARMALERTBYPLATENOONYEAR,
  loadVehicleType,
  LOAD_VEHICLE_TYPEONYEAR
};
