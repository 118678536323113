import React from "react";
import * as moment from "moment";
import {
  Button,
  Select,
  Form,
  Row,
  Col,
  Input,
  DatePicker,
  Radio,
  Drawer,
  Divider,
  Slider
} from "antd";

import { helper_convertDateformat } from "../../../../lib/helper";
import LangContext, { i18n } from "modules/shared/context/langContext";
const { RangePicker } = DatePicker;
const IndexFilterDrawer = props => (
  <Drawer
    title={<LangContext.Consumer>{i18n => i18n.f.filter}</LangContext.Consumer>}
    width={520}
    placement="right"
    onClose={props.onClose}
    maskClosable={true}
    visible={props.visible}
    style={{
      height: "100%",
      overflow: "auto",
      paddingBottom: 53
    }}
  >
    <Form>
      <Form.Item
        label={
          <LangContext.Consumer>{i18n => i18n.t.tripCode}</LangContext.Consumer>
        }
      >
        <Input onChange={props.handleFilterObjTripCode} />
      </Form.Item>

      <Form.Item
        label={
          <LangContext.Consumer>{i18n => i18n.i.invoice_number}</LangContext.Consumer>
        }
      >
        <Input onChange={props.handleFilterObjInvoice} />
      </Form.Item>

      <Form.Item
        label={
          <LangContext.Consumer>
            {i18n => i18n.d.driverName}
          </LangContext.Consumer>
        }
      >
        <Input onChange={props.handleFilterObjDriver} />
      </Form.Item>

      <Form.Item
        label={
          <LangContext.Consumer>
            {i18n => i18n.v.vehicleCode}
          </LangContext.Consumer>
        }
      >
        <Input onChange={props.handleFilterObjVehicleCode} />
      </Form.Item>

      <Form.Item
        label={
          <LangContext.Consumer>{i18n => i18n.p.plateNo}</LangContext.Consumer>
        }
      >
        <Input onChange={props.handleFilterObjPlateNo} />
      </Form.Item>

      <Form.Item
        label={
          <LangContext.Consumer>{i18n => i18n.l.location}</LangContext.Consumer>
        }
      >
        <Input onChange={props.handleFilterObjLocation} />
      </Form.Item>

      <Form.Item
        label={
          <LangContext.Consumer>
            {i18n => i18n.t.trip_date}
          </LangContext.Consumer>
        }
      >
        <RangePicker
          style={{ width: "100%" }}
          showTime={false}
          format="DD/MM/YYYY"
          //placeholder=""
          onChange={(e, i) => {
            //console.log(e, i);
            const date_start = helper_convertDateformat(i[0], "DD/MM/YYYY", "YYYY-MM-DD");
            const date_end = helper_convertDateformat(i[1], "DD/MM/YYYY", "YYYY-MM-DD");
            props.handleFilterObjTripDate(
              date_start, date_end
            );
          }}
        />

      </Form.Item>

      <Button
        style={{
          marginRight: 8
        }}
        onClick={props.onClose}
      >
        <LangContext.Consumer>{i18n => i18n.c.cancel}</LangContext.Consumer>
      </Button>

      <Button type="primary" onClick={props.onFilterButtonClick}>
        <LangContext.Consumer>{i18n => i18n.a.apply}</LangContext.Consumer>
      </Button>
    </Form>
  </Drawer>
);
export default IndexFilterDrawer;
