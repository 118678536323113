import {
  LOAD_TAXIFARE,
} from "./actions";

const initialState = {
  taxifareUrl: "",
  taxifareLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TAXIFARE.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        taxifareLoading: loading
      };
    case LOAD_TAXIFARE.SUCCESS:
      return {
        ...state,
        taxifareUrl: action.payload.data.data,
        taxifareLoading: false
      };
    default:
      return state;
  }
};
