import {
    LOAD_DEVICE_USER, 
    CREATE_DEVICE_USER,
    LOAD_DEVICE_USER_DETAIL,
    LOAD_DRIVEING_LICENSEL,
    UPDATE_DEVICE_USER,
    LOAD_DRIVEING_TRAINING,
    LOAD_AUTOCOMPLETE,
    REMOVE_LISTS
} from "./actions";

const intialState = {
    lists: [],
    total: 1,
    drivingTraniningtotal: 1,
    loading: false,
    datadrivingloading: false,
    drivingTranininglists: [],
    datadrivingtraniningloading: false,
    check: true,
    autoComplete: [],
};

export default (state = intialState, action) => {
    switch (action.type) {
        case REMOVE_LISTS:
            return intialState;

        case LOAD_DEVICE_USER.REQUEST:
            const { loading } = action.payload.data;
            return {
                ...state,
                loading: loading,
            }
            
        case LOAD_DEVICE_USER.SUCCESS:
            const { page } = action.payload

            for (let i = 0; i < action.payload.lists.data.length; i++) {
                action.payload.lists.data[i].rowNumber = i + 1;
                if (page > 1)
                  action.payload.lists.data[i].rowNumber =
                    action.payload.lists.data[i].rowNumber + (page - 1) * 10;
            }

            return {
                ...state,
                lists: action.payload.lists.data,
                total: 
                    action.payload.lists.total - 1 <= 0
                    ? 1
                    : action.payload.lists.total <= 0
                    ? 1
                    : action.payload.lists.total,
                    loading: false,
                draw: Math.floor(Math.random() * 999 + 1)
            };

        case CREATE_DEVICE_USER.SUCCESS:
            return {
                ...state,
                loading: false
            };

        case LOAD_DEVICE_USER_DETAIL.REQUEST:
            return {
                ...state,
                loading: action.payload.data.loading,
                check: true,
            }

        case LOAD_DEVICE_USER_DETAIL.SUCCESS:
            return {
                ...state,
                lists: action.payload.lists.data,
                check: false,
            };

        case LOAD_DRIVEING_LICENSEL.REQUEST:
            const { datadrivingloading } = action.payload.data;
            return {
                ...state,
                datadrivingloading: datadrivingloading,
            }

        case LOAD_DRIVEING_LICENSEL.SUCCESS:
            const page1  = action.payload.page
            for (let i = 0; i < action.payload.lists.data.length; i++) {
                    action.payload.lists.data[i].rowNumber = i + 1;
                    if (page1 > 1)
                      action.payload.lists.data[i].rowNumber =
                        action.payload.lists.data[i].rowNumber + (page1 - 1) * 10;
            }
    
            return {
                    ...state,
                    lists: action.payload.lists.data,
                    total: 
                        action.payload.lists.total - 1 <= 0
                        ? 1
                        : action.payload.lists.total <= 0
                        ? 1
                        : action.payload.lists.total,
                        datadrivingloading: false,
                    draw: Math.floor(Math.random() * 999 + 1)
            };

        case LOAD_DRIVEING_TRAINING.REQUEST:
            const { datadrivingtraniningloading } = action.payload;
            return {
                ...state,
                datadrivingtraniningloading: datadrivingtraniningloading,
            }

        case LOAD_DRIVEING_TRAINING.SUCCESS:
            const page2  = action.payload.page
            for (let i = 0; i < action.payload.drivingtrainininglists.data.length; i++) {
                    action.payload.drivingtrainininglists.data[i].rowNumber = i + 1;
                    if (page2 > 1)
                      action.payload.drivingtrainininglists.data[i].rowNumber =
                        action.payload.drivingtrainininglists.data[i].rowNumber + (page2 - 1) * 10;
            }
    
            return {
                    ...state,
                    drivingTranininglists: action.payload.drivingtrainininglists.data,
                 drivingTraniningtotal: 
                        action.payload.drivingtrainininglists.total - 1 <= 0
                         ? 1
                        : action.payload.drivingtrainininglists.total <= 0
                         ? 1
                        : action.payload.drivingtrainininglists.total,
                        loadDatadrivingtranining: false,
                    draw: Math.floor(Math.random() * 999 + 1)
            };

        case UPDATE_DEVICE_USER.SUCCESS:
            return {
                ...state,
                loading: false
            };

        case LOAD_AUTOCOMPLETE.SUCCESS:
            console.log()
            return {
                ...state,
                autoComplete: action.payload
            };


        default:
            return state;
    }
}