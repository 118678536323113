import { createTypes, createAction } from "lib/action";

const LOAD_TRACKINGDATA = createTypes("trackingdata", "load");
const LOAD_VEHICLE_TRACKINGDATA = createTypes(
  "vehicle_trackingdata",
  "load"
);
const LOAD_VEHICLE_TYPE_TRACKINGDATA = createTypes("vehicletype_trackingdata", "load");

const loadTrackingdata = {
  request: (data, loading) =>
    createAction(LOAD_TRACKINGDATA.REQUEST, { data, loading }),
  success: data => createAction(LOAD_TRACKINGDATA.SUCCESS, { data }),
  failure: () => createAction(LOAD_TRACKINGDATA.FAILURE)
};

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_TRACKINGDATA.REQUEST, {
      vehicletypeID,
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_VEHICLE_TRACKINGDATA.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TRACKINGDATA.FAILURE)
};

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_TRACKINGDATA.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_TYPE_TRACKINGDATA.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_TRACKINGDATA.FAILURE)
};

export {
  loadTrackingdata,
  LOAD_TRACKINGDATA,
  loadVehicle,
  LOAD_VEHICLE_TRACKINGDATA,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_TRACKINGDATA
};
