import { createTypes, createAction } from 'lib/action'

const PICKUP_EXPORT_REPORT = createTypes('pickupexportreport', 'load')
const LOAD_VEHICLE_PICKUP_DROPOFF = createTypes(
  'vehicle_pickup_dropoff',
  'load'
)
const LOAD_VEHICLE_TYPE_PICKUP_DROPOFF = createTypes(
  'vehicletype_pickup_dropoff',
  'load'
)

const exportReport = {
  request: (data, loading) =>
    createAction(PICKUP_EXPORT_REPORT.REQUEST, { data, loading }),
  success: (data) => createAction(PICKUP_EXPORT_REPORT.SUCCESS, { data }),
  failure: () => createAction(PICKUP_EXPORT_REPORT.FAILURE),
}

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_PICKUP_DROPOFF.REQUEST, {
      vehicletypeID,
      loading_vehicle,
    }),
  success: (data) =>
    createAction(LOAD_VEHICLE_PICKUP_DROPOFF.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_PICKUP_DROPOFF.FAILURE),
}

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_PICKUP_DROPOFF.REQUEST, {}),
  success: (data) =>
    createAction(LOAD_VEHICLE_TYPE_PICKUP_DROPOFF.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_PICKUP_DROPOFF.FAILURE),
}

export {
  exportReport,
  PICKUP_EXPORT_REPORT,
  loadVehicle,
  LOAD_VEHICLE_PICKUP_DROPOFF,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_PICKUP_DROPOFF,
}
