import { combineReducers } from "redux";

// etc
import auth from "modules/auth/reducer";
import ui from "modules/ui/reducer";

// delivery management
import tripState from "modules/delivery-management/trip/reducer";
import deliverytripState from "modules/delivery-management/delivery-trip/reducer";
import routemaster from "modules/delivery-management/route-master/reducer";

// setting
import policyState from "modules/setting/policy/reducer";
import calibratefuel from "modules/setting/calibratefuel/reducer";

// master data
import customerMasterDataState from "modules/master-data/customer/reducer";
import usergroupMasterDataState from "modules/master-data/usergroup/reducer";
import pullVehicleSink from "modules/master-data/pullvehiclesink/reducer";
import permissionMasterDataState from "modules/master-data/permission/reducer";
import datavisibilityMasterDataState from "modules/master-data/datavisibility/reducer";
import placeMasterData from "modules/master-data/facility/reducer";
import userMaster from "modules/master-data/user/reducer";
import dataVehicleGroup from "modules/master-data/vehicleGroup/reducer";
import temporaryTracking from "modules/master-data/temporaryTracking/reducer";
import routeMasterData from "modules/master-data/route/reducer";
import vehicle from "modules/master-data/vehicle/reducer";
import vehicleSharingMasterDataState from "modules/master-data/vehicle-sharing/reducer";
import verification from "modules/master-data/vehicle/reducer";
import deviceUser from "modules/master-data/deviceuser/reducer";
// import permissionMasterDataState from "modules/master-data/permission/reducer";
import locationgroup from "modules/master-data/locationgroup/reducer";
import locationType from "modules/master-data/location-type/reducer";
import nearbyLocation from "modules/master-data/nearbylocation/reducer";
import zone from "modules/master-data/zone/reducer";
import refuel from "modules/master-data/refuel/reducer";
import partner from "modules/master-data/partner/reducer";
import locationSharing from "modules/master-data/locations-sharing/reducer";
import parttype from "modules/master-data/parttype/reducer";
import partgroup from "modules/master-data/partgroup/reducer";
import managementMaintenance from "modules/master-data/maintenance/reducer";
import expanseInsurance from "modules/master-data/insurance/reducers";
import company from "modules/master-data/company/reducer";
import deviceConfig from "modules/master-data/deviceconfig/reducer";
import gallery from "modules/master-data/gallery/reducer";
import uom from "modules/master-data/uom/reducer";
import product from "modules/master-data/product/reducer";
import managedocument from "modules/master-data/manage-document/reducer";

// operation job
import deliveryitemMasterDataState from "modules/operate-job/delivery-item/reducer";
import deliveryitemDeleteHistoryMasterDataState from "modules/operate-job/delivery-item-delete-history/reducer";
import gpsCertificationRequestState from "modules/operate-job/gps-certification/reducer";

// booking
import bookingPoolState from "modules/booking-pool/reducer";

//help
import servicerepairdevice from "modules/help/servicerepairdevice/reducer";
import servicemovedevice from "modules/help/servicemovedevice/reducer";
import serviceinstalldevice from "modules/help/serviceeinstalldevice/reducer";
import manageRepairDevice from "modules/help/manage-repairdevice/reducer";
import monitorSDCard from "modules/help/monitor-sdcard/reducer";

// dashboard
import monitoringState from "modules/monitoring/reducer";
import videoMonitoring from "modules/monitoring/video/reducer";
import honeytoaststream from "modules/monitoring/honeytoaststream2/reducer";
import cashierState from "modules/cashier/reducer";
import mdvrdownloadfile from "modules/monitoring/mdvrdownloadfile/reducer";
import honeytoastdownloadfile from "modules/monitoring/honeytoastdownloadfile/reducer";
import honeytoastplayback from "modules/monitoring/honeytoastplayback/reducer";
import cpfDashboard from "modules/monitoring/cpf-dashboard/reducer";

//management
import downloadcertificate from "modules/management/downloadcertificate/reducer";
import contactmanagement from "modules/management/contactmanagement/reducer";
import frimware from "modules/management/create-frimware/reducer";
import assignfirmware from "modules/management/assign-firmware/reducer";
import outofcontact from "modules/management/outofcontact/reducer";

// report
import alarmAlertByPlateno from "modules/reports/alarmalertbyplateno/reducer";
import alarmAlertByPlatenoOnyear from "modules/reports/alarmalertbyplatenoonyear/reducer";
import alarmAlertByDriver from "modules/reports/alarmalertbydriver/reducer";
import alarmAlertFrequencyByDriver from "modules/reports/alarmalertfrequencybydriver/reducer";
import alarmAlertByDurationtime from "modules/reports/alarmalertbydurationtime/reducer";
import drivingBehavior from "modules/reports/drivingbehavior/reducer";
import fuelRemainRate from "modules/reports/fuelremainrate/reducer";
import gpsLostContact from "modules/reports/gpslostcontact/reducer";
import taxiSos from "modules/reports/taxisos/reducer";
import taxiFare from "modules/reports/taxifare/reducer";
import summaryLineNotification from "modules/reports/summarylinenotification/reducer";
import speedOverLimit from "modules/reports/speedoverlimit/reducer";
import drivingLicensescanBehavior from "modules/reports/drivinglicensescanbehavior/reducer";
import separateVehicleStatus from "modules/reports/separatevehiclestatus/reducer";
import compareVehicleScco from "modules/reports/comparevehiclescco/reducer";
import alertDriverLicense from "modules/reports/alertdriverlicense/reducer";
import parkingAreaOutside from "modules/reports/parkingareaoutside/reducer";
//import truckusage from "modules/reports/MoveReport/Truckusage/reducer";
//import drivingdistance from "modules/reports/MoveReport/drivingdistance/reducer";
import parking from "modules/reports/parking/reducer";
import drivinglicensescanlog from "modules/reports/drivinglicensescanlog/reducer";
import speedoverlimitsummary from "modules/reports/speedoverlimitsummary/reducer";
import drivinglog from "modules/reports/drivinglog/reducer";
import drivinglogspecial from "modules/reports/drivinglogspecial/reducer";
import event from "modules/reports/event/reducer";
import summarykpi from "modules/reports/summarykpi/reducer";
import summarykpiByDriver from "modules/reports/summarykpibydriver/reducer";
import loginhistory from "modules/reports/loginhistory/reducer";
import gpsstatus from "modules/reports/gpsstatus/reducer";
import trackingdata from "modules/reports/trackingdata/reducer";
import truckusage from "modules/reports/truckusage/reducer";
import drivingdistance from "modules/reports/drivingdistance/reducer";
import temperature from "modules/reports/temperature/reducer";
import maintenance from "modules/reports/maintenance/reducer";
import distfromlastmaintenance from "modules/reports/distfromlastmaintenance/reducer";
import truckcurrentlocation from "modules/reports/truckcurrentlocation/reducer";
import driverinformation from "modules/reports/driverinformation/reducer";
import numberofdaysworked from "modules/reports/numberofdaysworked/reducer";
import dozeoffrisk from "modules/reports/dozeoffrisk/reducer";
import delivery from "modules/reports/delivery/reducer";
import triptemperature from "modules/reports/triptemperature/reducer";
import tripsumbyveh from "modules/reports/tripsumbyveh/reducer";
import daily from "modules/reports/daily/reducer";
import velocitysummary from "modules/reports/velocitysummary/reducer";
import truckengineon from "modules/reports/truckengineon/reducer";
import truckengineoff from "modules/reports/truckengineoff/reducer";
import trucknotuse from "modules/reports/trucknotuse/reducer";
import drivinghour from "modules/reports/drivinghour/reducer";
import tripsummary from "modules/reports/tripsummary/reducer";
import drivingoverlimit from "modules/reports/drivingoverlimit/reducer";
import tripsumbylocation from "modules/reports/tripsumbylocation/reducer";
import insurance from "modules/reports/insurance/reducer";
import vehicleperformanceconclude from "modules/reports/vehicleperformanceconclude/reducer";
import reportrefuel from "modules/reports/refuel/reducer";
import riskbehaviour from "modules/reports/riskbehaviour/reducer";
import vehicleinarea from "modules/reports/vehicleinarea/reducer";
import maintenancedeviceconclude from "modules/reports/maintenancedeviceconclude/reducer";
import vehicleinformationdlt from "modules/reports/vehicleinformationdlt/reducer";
import tripbyengineoffon from "modules/reports/tripbyengineoffon/reducer";
import schedulereport from "modules/reports/schedulereport/reducer";
import boatDeliveryReport from "modules/reports/boatdelivery/reducer";
import drivingcontinuous from "modules/reports/drivingcontinuous/reducer";
import notinotswipecard from "modules/reports/notinotswipecard/reducer";
import driverincome from "modules/reports/driverincome/reducer";
import inoutlocation from "modules/reports/inoutlocation/reducer";

import replay from "modules/monitoring/replaynew/reducer";

import fuelfull from "modules/reports/fuelfull/reducer";
import sjcMonthlytruckusage from "modules/reports/sjcmonthlytruckusages/reducer";
import graphsensor from "modules/monitoring/graph-sensors/reducer";

import performacelogReport from "modules/reports/performanceLog/reducer";
import manageotp from "modules/management/manage-otp/reducer.js";
import managedashboard from "modules/management/manage-dashboard-monitoring/reducer.js";
import dashboardmonitoring from "modules/monitoring/dashboard-monitoring/reducer.js";

import managefuelrate from "modules/management/manage-fuelrate/reducer.js";
import depositfuelcredit from "modules/management/deposit-fuel-credit/reducer.js";
import withdrawfuelcredit from "modules/management/withdraw-fuel-credit/reducer.js";
import inputactivity from "modules/reports/inputactivity/reducer";
import withdrawfuelcreditreport from "modules/reports/withdrawfuelcreditreport/reducer";
import depositfuelcreditreport from "modules/reports/depositfuelcreditreport/reducer";
import flowreport from "modules/reports/flowreport/reducer";
import summaryDrivingLog from "modules/reports/summarydrivinglog/reducer";
import companysetting from "modules/setting/companysetting/reducer";
import drivinglicenseexpire from "modules/reports/drivinglicenseexpire/reducer";
import PickupAndDropoffEmployee from "modules/reports/pickupanddropoffemployee/reducer";

import cameraStatusDashboard from "modules/monitoring/camera-status-dashboard/reducer";

export default combineReducers({
  auth,
  ui,
  bookingPoolState,
  monitoringState,
  cashierState,
  customerMasterDataState,
  alarmAlertByPlateno,
  gpsCertificationRequestState,
  alarmAlertByDriver,
  alarmAlertByDurationtime,
  drivingBehavior,
  fuelRemainRate,
  gpsLostContact,
  taxiSos,
  taxiFare,
  summaryLineNotification,
  speedOverLimit,
  drivingLicensescanBehavior,
  alarmAlertByPlatenoOnyear,
  separateVehicleStatus,
  compareVehicleScco,
  alertDriverLicense,
  parkingAreaOutside,
  usergroupMasterDataState,
  deliveryitemMasterDataState,
  deliveryitemDeleteHistoryMasterDataState,
  pullVehicleSink,
  parking,
  summarykpi,
  datavisibilityMasterDataState,
  placeMasterData,
  userMaster,
  dataVehicleGroup,
  routeMasterData,
  vehicle,
  loginhistory,
  gpsstatus,
  trackingdata,
  vehicleSharingMasterDataState,
  videoMonitoring,
  drivingdistance,
  drivinglicensescanlog,
  speedoverlimitsummary,
  drivinglog,
  event,
  permissionMasterDataState,
  alarmAlertFrequencyByDriver,
  verification,
  deviceUser,
  locationgroup,
  locationType,
  nearbyLocation,
  zone,
  refuel,
  truckusage,
  temperature,
  maintenance,
  distfromlastmaintenance,
  truckcurrentlocation,
  driverinformation,
  numberofdaysworked,
  dozeoffrisk,
  delivery,
  triptemperature,
  tripsumbyveh,
  daily,
  velocitysummary,
  truckengineon,
  truckengineoff,
  inputactivity,
  trucknotuse,
  drivinghour,
  tripState,
  tripsummary,
  drivingoverlimit,
  tripsumbylocation,
  insurance,
  vehicleperformanceconclude,
  reportrefuel,
  riskbehaviour,
  partner,
  locationSharing,
  vehicleinarea,
  maintenancedeviceconclude,
  downloadcertificate,
  parttype,
  partgroup,
  policyState,
  managementMaintenance,
  // policyState
  contactmanagement,
  mdvrdownloadfile,
  servicerepairdevice,
  deliverytripState,
  expanseInsurance,
  vehicleinformationdlt,
  company,
  deviceConfig,
  servicemovedevice,
  honeytoaststream,
  serviceinstalldevice,
  honeytoastdownloadfile,
  honeytoastplayback,
  drivinglogspecial,
  tripbyengineoffon,
  gallery,
  schedulereport,
  routemaster,
  boatDeliveryReport,
  summarykpiByDriver,
  frimware,
  assignfirmware,
  fuelfull,
  temporaryTracking,
  uom,
  product,
  calibratefuel,
  sjcMonthlytruckusage,
  drivingcontinuous,
  notinotswipecard,
  replay,
  driverincome,
  manageRepairDevice,
  graphsensor,
  performacelogReport,
  manageotp,
  managefuelrate,
  cpfDashboard,
  outofcontact,
  inoutlocation,
  depositfuelcredit,
  withdrawfuelcredit,
  withdrawfuelcreditreport,
  depositfuelcreditreport,
  flowreport,
  managedashboard,
  dashboardmonitoring,
  summaryDrivingLog,
  companysetting,
  drivinglicenseexpire,
  PickupAndDropoffEmployee,
  managedocument,
  monitorSDCard,
  cameraStatusDashboard,
});
