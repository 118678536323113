import React, { useState, useEffect } from "react";
import { GeneralStyledContent } from "../../../styled/common-styled";
import moment from "moment";
import CollapseSearch from "./components/CollapseSearch";
import CompareGraph from "./components/CompareGraph";
import GraphSection from "./components/GraphSection";
import SettingCalibrateFuel from "./components/SettingCalibrateFuel";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { message } from "antd";
import AuthorizeComponent from "../../auth/components/AuthorizeComponent";

const Index = ({ profile }) => {
  const [formSearch, setFormSearch] = useState({
    vehiclePlateNo: "",
    vehicleId: "",
    date: "",
    durationWeek: "7",
  });
  const [activeKey, setActiveKey] = useState(["1"]);
  const [showCompareGraph, setShowCompareGraph] = useState(false);
  const [showGraph, setShowGraph] = useState(false);
  const [dataSourceGraph, setDataSourceGraph] = useState([]);
  const [loadingGraph, setLoadingGraph] = useState(false);
  const [settingValue, setSettingValue] = useState({
    maxFuel: "",
    minFuel: "",
    totalFuel: "",
    fuelTankSize: "",
    fuelBeforeFullLiter: "",
  });
  const [collapseLoading, setCollapseLoading] = useState(false);
  const [showSettingCalibrate, setShowSettingCalibrate] = useState(false);
  const [dateRange, setDateRange] = useState({
    endAt: "",
    start_at: "",
  });
  const [graphNumber, setGraphNumber] = useState(1);
  const { id } = useParams();

  useEffect(() => {
    setShowCompareGraph(false);
    setShowGraph(false);
    setShowSettingCalibrate(false);
  }, [formSearch]);

  useEffect(() => {
    fetchCalibrateFuel();
  }, []);

  const fetchCalibrateFuel = async () => {
    setCollapseLoading(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      id,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/calibate-fuel`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setFormSearch({
          vehiclePlateNo: result.plate_no,
          vehicleId: result.vehicle_code,
          date: !!result.fuel_date_time
            ? moment(result.fuel_date_time, "YYYY-MM-DD")
            : "",
          durationWeek: "7",
        });
        setSettingValue({
          maxFuel: result.fuel_full == null ? "" : String(result.fuel_full),
          minFuel:
            result.fuel_before_full == null
              ? ""
              : String(result.fuel_before_full),
          totalFuel:
            result.fuel_full_liter == null
              ? ""
              : String(result.fuel_full_liter),
          fuelTankSize:
            result.refuel_liter == null ? "" : String(result.refuel_liter),
          fuelBeforeFullLiter:
            result.fuel_before_full_liter == null
              ? ""
              : String(result.fuel_before_full_liter),
        });
        if (!!result.fuel_date_time) {
          handleSearchFillDate({
            id,
            start_at: moment(result.fuel_date_time).format("YYYY-MM-DD"),
            fuel_data_rang: 7,
            company_id: profile.company_id,
            type_click: "",
            fuel_full_liter:
              result.fuel_full_liter == null
                ? ""
                : String(result.fuel_full_liter),
            refuel_liter:
              result.refuel_liter == null ? "" : String(result.refuel_liter),
            fuel_full: result.fuel_full == null ? "" : String(result.fuel_full),
            fuel_before_full:
              result.fuel_before_full == null
                ? ""
                : String(result.fuel_before_full),
            fuel_before_full_liter:
              result.fuel_before_full_liter == null
                ? ""
                : String(result.fuel_before_full_liter),
            graph_fuel: "",
          });
        }
        setCollapseLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  const fetchCalibrateFuelCreate = async (type_click, graphNumber) => {
    setLoadingGraph(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let raw = JSON.stringify({
      id,
      start_at: moment(formSearch.date).format("YYYY-MM-DD"),
      fuel_data_rang: formSearch.durationWeek,
      company_id: profile.company_id,
      type_click,
      fuel_full_liter: settingValue.totalFuel,
      refuel_liter: settingValue.fuelTankSize,
      fuel_full: settingValue.maxFuel,
      fuel_before_full: settingValue.minFuel,
      graph_fuel: graphNumber,
      fuel_before_full_liter: settingValue.fuelBeforeFullLiter,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/calibate-fuel/create`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setGraphNumber(result.graph_fuel);
        setSettingValue({
          maxFuel: result.fuel_full == null ? "" : String(result.fuel_full),
          minFuel:
            result.fuel_before_full == null
              ? ""
              : String(result.fuel_before_full),
          totalFuel:
            result.fuel_full_liter == null
              ? ""
              : String(result.fuel_full_liter),
          fuelTankSize:
            result.refuel_liter == null ? "" : String(result.refuel_liter),
          fuelBeforeFullLiter:
            type_click == ""
              ? settingValue.fuelBeforeFullLiter
              : String(result.fuel_before_full_liter),
        });
        setDateRange(result.date_range);
        setLoadingGraph(false);
        setDataSourceGraph(result.dataArr);
        if (type_click == "setting") {
          success("ตั้งค่าสำเร็จ");
        }
      })
      .catch((error) => {
        if (type_click == "setting") {
          error(`Something went wrong ${error}!!! `);
        }
      });
  };

  const fetchCalibrateFuelCreateAfterDateIsValid = async (obj) => {
    setLoadingGraph(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let raw = JSON.stringify(obj);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/calibate-fuel/create`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setGraphNumber(result.graph_fuel);
        setSettingValue({
          maxFuel: result.fuel_full == null ? "" : String(result.fuel_full),
          minFuel:
            result.fuel_before_full == null
              ? ""
              : String(result.fuel_before_full),
          totalFuel:
            result.fuel_full_liter == null
              ? ""
              : String(result.fuel_full_liter),
          fuelTankSize:
            result.refuel_liter == null ? "" : String(result.refuel_liter),
          fuelBeforeFullLiter: String(result.fuel_before_full_liter),
        });
        setDateRange(result.date_range);
        setLoadingGraph(false);
        setDataSourceGraph(result.dataArr);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectGraph = (graph) => {
    if (graph == 3) {
      window.open(`/servicerepairdevice/create/${id}/${profile.company_id}`);
    } else {
      setGraphNumber(graph);
      fetchCalibrateFuelUpdate(graph);
      setShowSettingCalibrate(true);
      setShowCompareGraph(false);
    }
  };

  const fetchCalibrateFuelUpdate = async (graph) => {
    setLoadingGraph(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id,
      graph,
      fuel_data_rang: formSearch.durationWeek,
      company_id: profile.company_id,
      start_at: moment(formSearch.date).format("YYYY-MM-DD"),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/calibate-fuel/update`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoadingGraph(false);
        setDataSourceGraph(result.data.dataArr);
      })
      .catch((error) => console.log("error", error));
  };

  const onChangeSettingForm = (val) => {
    setSettingValue(val);
  };

  const handleSearch = () => {
    setActiveKey([]);
    setShowCompareGraph(true);
    setShowSettingCalibrate(false);
    setShowGraph(true);
    fetchCalibrateFuelCreate("", 1);
  };

  const handleSearchFillDate = (obj) => {
    setActiveKey([]);
    setShowCompareGraph(false);
    setShowSettingCalibrate(true);
    setShowGraph(true);
    fetchCalibrateFuelCreateAfterDateIsValid(obj);
  };

  const onSubmitSettingCalibrate = () => {
    //Submit
    if (graphNumber == 1) {
      if (parseInt(settingValue.maxFuel) > parseInt(settingValue.minFuel)) {
        fetchCalibrateFuelCreate("setting", graphNumber);
      } else {
        error(
          "ค่าที่จุดสูงสุด ช่วงเติมน้ำมัน ต้องมีค่ามากกว่า ค่าที่จุดต่ำสุด ช่วงเติมน้ำมัน"
        );
      }
    } else {
      if (parseInt(settingValue.maxFuel) < parseInt(settingValue.minFuel)) {
        fetchCalibrateFuelCreate("setting", graphNumber);
      } else {
        error(
          "ค่าที่จุดสูงสุด ช่วงเติมน้ำมัน ต้องมีค่าน้อยกว่า ค่าที่จุดต่ำสุด ช่วงเติมน้ำมัน"
        );
      }
    }
  };

  const error = (text) => {
    message.error(text);
  };
  const success = (text) => {
    message.success(text);
  };

  return (
    // <AuthorizeComponent matching_name="vahicleCalibrateFuel">

    <GeneralStyledContent>
      {/* {JSON.stringify(settingValue)}
      {JSON.stringify(graphNumber)} */}
      <CollapseSearch
        activeKey={activeKey}
        setFormSearch={setFormSearch}
        formSearch={formSearch}
        setActiveKey={setActiveKey}
        handleSearch={handleSearch}
        loading={collapseLoading}
      />
      {showCompareGraph ? <CompareGraph selectGraph={selectGraph} /> : null}

      {showSettingCalibrate ? (
        <SettingCalibrateFuel
          settingValue={settingValue}
          onChangeSettingForm={onChangeSettingForm}
          onSubmitSettingCalibrate={onSubmitSettingCalibrate}
        />
      ) : null}

      {showGraph ? (
        <GraphSection
          dataSourceGraph={dataSourceGraph}
          loading={loadingGraph}
          dateRange={dateRange}
          formSearch={formSearch}
          settingValue={settingValue}
          graphNumber={graphNumber}
        />
      ) : null}
    </GeneralStyledContent>
    // </AuthorizeComponent>
  );
};

export default withRouter(
  connect(
    ({ auth, auth: { profile } }) => ({
      auth,
      profile,
    }),
    {}
  )(Index)
);
