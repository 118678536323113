import { createTypes, createAction } from "lib/action";

const CHECK_VERIFY_EMAIL = createTypes("verifyemail", "load");
const CREATE_USER_VERIFICATION = createTypes("verification", "cerate");

const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
  return {
    type: REMOVE_LISTS
  };
}

const verifyUser = {
    request: (data, loading) =>
        createAction(CREATE_USER_VERIFICATION.REQUEST, { data, loading}),
    success: res => createAction(CREATE_USER_VERIFICATION.SUCCESS, { res }),
        failure: () => createAction(CREATE_USER_VERIFICATION.FAILURE)
};

const checkverifyemail = {
  request: (data, loading) =>
      createAction(CHECK_VERIFY_EMAIL.REQUEST, { data, loading}),
  success: (lists) =>
      createAction(CHECK_VERIFY_EMAIL.SUCCESS, {lists}),
  failure: () => createAction(CHECK_VERIFY_EMAIL.FAITLURE)  
};

export{
    verifyUser,
    CREATE_USER_VERIFICATION,
    checkverifyemail,
    CHECK_VERIFY_EMAIL,
    REMOVE_LISTS,
    removeLists
};