import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";
import { getErrorMsg } from "lib/fetch";

function* loadGraphsendors(action) {
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/graphsensor`,
      {
        ...action.payload.data,
        cut_fuel_min: parseInt(action.payload.data.cut_fuel_min),
      },
      { headers: { Authorization: `Bearer ${action.payload.accessToken}` } }
    );
    yield put(actions.loadGraphsensors.success(res.data));
  } catch (err) {
    yield put(actions.loadGraphsensors.failure(getErrorMsg(err)));
  }
}

export default function* watchGraphSensors() {
  yield all([takeEvery(actions.LOAD_GRAPHSENSORS.REQUEST, loadGraphsendors)]);
}
