import React, { Component } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Table,
  Transfer,
  Icon,
  Tooltip,
} from "antd";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import LangContext from "modules/shared/context/langContext";

import * as yup from "yup";
import FormDivider from "../../../shared/components/FormDivider";
import Group from "./Group";
import Demo from "./Demo";

const columnStyle = {
  fontSize: "12px",
  fontWeight: "400",
  background: "white",
};

export default class Forms extends Component {
  state = {
    demoBox: {},
    demoGroup: false,
  };

  getAllVehicleList = (value) => {
    let arr_temp = [];
    value.map((v) => {
      arr_temp = [...arr_temp, ...v.vehicle_list];
    });
    return arr_temp;
  };
  formatUserList = (data) => {
    let newArr = data.map((item) => {
      return {
        key: item.id,
        label: item.username,
      };
    });
    return newArr;
  };

  render() {
    const columns = [
      {
        title: "ทะเบียน (เบอร์รถ)",
        dataIndex: "plate_no",
        key: "plate_no",
        width: 40,
        render: (text, record, index) => {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "ชื่อ Channel",
        dataIndex: "display_name",
        key: "display_name",
        width: 40,
        render: (text, record, index) => {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.a.action}</LangContext.Consumer>
        ),
        dataIndex: "action",
        key: "action",
        width: 20,
        // fixed: "right",
        render: (text, record, index) => {
          // console.log("record", record);
          return (
            <Tooltip
              placement="left"
              title={
                <LangContext.Consumer>
                  {(i18n) => i18n.e.edit}
                </LangContext.Consumer>
              }
              arrowPointAtCenter
              style={{
                padding: "0px 1px 0px 0px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              <Link
                to={`/manage-dashboard-monitoring/edit/${record.display_id}`}
                style={{ padding: "0px 5px 0px 5px" }}
              >
                <Icon type="edit" />
              </Link>
            </Tooltip>
          );
        },
      },
    ];
    return (
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={this.props.initialValues}
          validationSchema={yup.object().shape({
            display_name: yup
              .string()
              .required(
                <LangContext.Consumer>
                  {(i18n) => i18n.p.pleaseSelectVehicleRegistration}
                </LangContext.Consumer>
              )
              .nullable(),
            camera_layout_type_id: yup
              .number()
              .required(
                <LangContext.Consumer>
                  {(i18n) => i18n.p.pleaseSelectVehicleRegistration}
                </LangContext.Consumer>
              )
              .nullable(),
            channel: yup
              .string()
              .required(
                <LangContext.Consumer>
                  {(i18n) => i18n.p.pleaseSelectVehicleRegistration}
                </LangContext.Consumer>
              )
              .nullable(),
            camera: yup
              .string()
              .required(
                <LangContext.Consumer>
                  {(i18n) => i18n.p.pleaseSelectVehicleRegistration}
                </LangContext.Consumer>
              )
              .nullable(),
            demoBox: yup.object(),
            all_vehicle_list: yup.array().max(yup.ref("demoBox.xy")),
            // all_vehicle_list: yup.array().max(
            //   yup.ref("demoBox.x") * yup.ref("demoBox.y"),
            //   // values.demoBox.x * values.demoBox.y,
            //   // this.state.demoBox.x * this.state.demoBox.y,
            //   "You can enter more than " +
            //     yup.ref("demoBox.x") * yup.ref("demoBox.y") +
            //     " addresses"
            // ),
          })}
          onSubmit={(values) => {
            this.props.handleOnSubmit(values);
          }}
          render={({
            values,
            errors,
            status,
            handleBlur,
            touched,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form
              onSubmit={handleSubmit}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 20 }}
            >
              <FormDivider>Monitoring Video Realtime </FormDivider>
              <Row>
                {/* //left col */}
                <Col span={16}>
                  <Row span={12}>
                    <Form.Item
                      label="ชื่อ channel"
                      required
                      validateStatus={
                        touched.display_name && errors.display_name && "warning"
                      }
                      help={touched.display_name && errors.display_name}
                    >
                      <Input
                        value={values.display_name}
                        maxLength={40}
                        style={{ width: 150 }}
                        onChange={(v) => {
                          setFieldValue("display_name", v.target.value);
                        }}
                      ></Input>
                    </Form.Item>
                  </Row>
                  <Row span={12}>
                    <Col span={8}>
                      <Form.Item
                        label="ประเภทการแสดงผล"
                        required
                        validateStatus={
                          touched.channel && errors.channel && "warning"
                        }
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 20 }}
                        help={touched.channel && errors.channel}
                      >
                        <Select
                          // defaultValue={["-"]}
                          // style={{ width: 100 }}
                          value={values.channel}
                          onChange={(v) => {
                            setFieldValue("channel", v);
                          }}
                        >
                          <Select.Option key="0" value="App Android">
                            App Android
                          </Select.Option>
                          <Select.Option key="1" value="Web">
                            Web
                          </Select.Option>
                          <Select.Option key="1" value="App&Website">
                            App & Website
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="จำนวนการแสดงผล"
                        required
                        validateStatus={
                          touched.camera_layout_type_id &&
                          errors.camera_layout_type_id &&
                          "warning"
                        }
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 20 }}
                        help={
                          touched.camera_layout_type_id &&
                          errors.camera_layout_type_id
                        }
                      >
                        <Select
                          // defaultValue={["-"]}
                          // style={{ width: 100 }}
                          value={values.camera_layout_type_id}
                          onChange={(v, option) => {
                            setFieldValue("camera_layout_type_id", v);
                            setFieldValue("demoBox", {
                              x: option.props.data.layout_x,
                              y: option.props.data.layout_y,
                              xy:
                                option.props.data.layout_x *
                                option.props.data.layout_y,
                            });

                            setFieldValue("demoGroup", false);

                            // this.setState({
                            //   demoBox: {
                            //     x: option.props.data.layout_x,
                            //     y: option.props.data.layout_y,
                            //   },
                            //   demoGroup: false,
                            // });
                          }}
                        >
                          {this.props.layoutList.map((v) => {
                            if (
                              values.channel !== "App Android" &&
                              v.id === 3
                            ) {
                              return (
                                <Select.Option
                                  key={v.id.toString()}
                                  value={v.id}
                                  data={v}
                                  disabled
                                >
                                  {v.name +
                                    " (สามารถใช้ได้ใน App Android เท่านั่น)"}
                                </Select.Option>
                              );
                            } else {
                              return (
                                <Select.Option
                                  key={v.id.toString()}
                                  value={v.id}
                                  data={v}
                                >
                                  {v.name}
                                </Select.Option>
                              );
                            }
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="มุมมองในการแสดง"
                        required
                        validateStatus={
                          touched.camera && errors.camera && "warning"
                        }
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 20 }}
                        help={touched.camera && errors.camera}
                      >
                        <Select
                          // defaultValue={["-"]}
                          // style={{ width: 100 }}
                          value={values.camera}
                          onChange={(v) => {
                            setFieldValue("camera", v);
                          }}
                        >
                          <Select.Option key="0" value="Out">
                            กล้องนอก
                          </Select.Option>
                          <Select.Option key="1" value="In">
                            กล้องใน
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <Row>
                    <div>
                      ****************************************************************************************************
                    </div>
                  </Row> */}
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        required
                        validateStatus={
                          ((touched.group_list && errors.group_list) ||
                            (touched.all_vehicle_list &&
                              errors.all_vehicle_list)) &&
                          "warning"
                        }
                        labelCol={{ span: 0 }}
                        wrapperCol={{ span: 24 }}
                        help={
                          (touched.group_list && errors.group_list) ||
                          (touched.all_vehicle_list && errors.all_vehicle_list)
                        }
                      >
                        <Group
                          groupList={values.group_list}
                          vehicleList={this.props.vehicleList}
                          selectedVehicleList={values.all_vehicle_list}
                          onChangeGroup={(v, index, name) => {
                            setFieldValue(
                              "group_list." + [index] + "." + name + "",
                              v
                            );
                            if (name === "vehicle_list") {
                              let temp = values.group_list;
                              temp[index].vehicle_list = v;
                              setFieldValue(
                                "all_vehicle_list",
                                this.getAllVehicleList(temp)
                              );
                            }
                          }}
                          addGroup={() => {
                            setFieldValue("group_list", [
                              ...values.group_list,
                              {
                                group_name: "",
                                vehicle_list: [],
                              },
                            ]);
                          }}
                          deleteGroup={(index) => {
                            let temp = values.group_list.splice(index, 1);
                            setFieldValue(
                              "all_vehicle_list",
                              this.getAllVehicleList(values.group_list)
                            );
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Item label="กลุ่มผู้ใช้งาน">
                        <Transfer
                          titles={["Source", "Target"]}
                          // name="vehicle"
                          style={{
                            margin: "10px 0px 0px 0px",
                          }}
                          listStyle={{
                            width: 220,
                            height: 300,
                          }}
                          dataSource={this.formatUserList(this.props.userList)}
                          showSearch
                          targetKeys={values.user_id}
                          onChange={(targetKeys) => {
                            setFieldValue("user_id", targetKeys);
                          }}
                          render={(item) => item.label}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                {/* //right col */}
                <Col span={8}>
                  <Row gutter={[8, 8]} span={24}>
                    <Demo
                      vehicleList={this.props.vehicleList}
                      demoBox={values.demoBox}
                      demoGroup={values.demoGroup}
                    />
                  </Row>
                  <Row>
                    <Col span={24} justify="center" align="center">
                      <Button
                        type="primary"
                        shape="round"
                        style={{ margin: "15px" }}
                        onClick={() => {
                          setFieldValue("demoGroup", values.group_list);
                        }}
                      >
                        แสดงตัวอย่าง
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Table
                      id="main-table"
                      // showSorterTooltip={false}
                      bordered
                      columns={columns}
                      dataSource={this.props.duplicateVehicle}
                      pagination={false}
                      rowKey="group_id"
                      // scroll={{ y: 500 }}
                    />
                  </Row>
                </Col>
              </Row>

              <Row type="flex" justify="end">
                <Col span={3}>
                  <Link to="/manage-dashboard-monitoring">
                    <Button
                      type="default"
                      style={{
                        margin: "0px 8px 0px 0px",
                      }}
                    >
                      <LangContext.Consumer>
                        {(i18n) => i18n.b.back}
                      </LangContext.Consumer>
                    </Button>
                  </Link>
                  <Button type="primary" htmlType="submit">
                    <LangContext.Consumer>
                      {(i18n) => i18n.s.save}
                    </LangContext.Consumer>
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        />
      </div>
    );
  }
}
