import { createTypes, createAction } from "lib/action";

const LOAD_INOUTLOCATION = createTypes("inoutlocation", "load");
const LOAD_LOCATION_INOUTLOCATION = createTypes(
  "location_inoutlocation",
  "load"
);

const loadInoutlocation = {
  request: (data, loading) =>
    createAction(LOAD_INOUTLOCATION.REQUEST, { data, loading }),
  success: (data) => createAction(LOAD_INOUTLOCATION.SUCCESS, { data }),
  failure: () => createAction(LOAD_INOUTLOCATION.FAILURE),
};

const loadLocation = {
  request: () => createAction(LOAD_LOCATION_INOUTLOCATION.REQUEST, {}),
  success: (data) =>
    createAction(LOAD_LOCATION_INOUTLOCATION.SUCCESS, { data }),
  failure: () => createAction(LOAD_LOCATION_INOUTLOCATION.FAILURE),
};

export {
  loadInoutlocation,
  LOAD_INOUTLOCATION,
  loadLocation,
  LOAD_LOCATION_INOUTLOCATION,
};
