import React, { Component } from "react";
import { Col } from "antd";

const DemoBox = (props) => (
  <div
    className={`height-${props.value}`}
    style={{
      height: `${props.value}` + "px",
      border: "1px solid #d9d9d9",
      padding: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "0.8rem",
    }}
  >
    {props.children}
  </div>
);
// const colorCode = ["#f4d35e", "#ee964b", "#f95738", "#ff0054", "#ad2831"];
const colorCode = ["#91FFDE", "#E291FF", "#91BDFF", "#91DEFF", "#ADFF91"];

export default class Demo extends Component {
  getRandomColor = () => {
    // Generate random values for red, green, and blue components
    const red = Math.floor(256 - Math.random() * 101);
    const green = Math.floor(256 - Math.random() * 101);
    const blue = Math.floor(256 - Math.random() * 101);

    // Combine the components into a CSS color string
    const color = `rgb(${red}, ${green}, ${blue})`;
    return color;
  };
  generateDemo = (x, y) => {
    const result = [];
    for (let index = 0; index < x * y; index++) {
      result.push(
        <Col span={Math.floor(24 / x)}>
          <div
            style={{
              border: "1px solid #d9d9d9",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderBottom: "none",
            }}
          >
            Group
          </div>
          <DemoBox value={100}>Empty</DemoBox>
        </Col>
      );
    }
    // console.log(result);
    return result;
  };
  generateDemoGroup = (demoGroup, x, y) => {
    const result = [];
    const count_temp = x * y;
    demoGroup.map((v, index) => {
      const color = colorCode[index];
      v.vehicle_list.map((sub_v) => {
        result.push(
          <Col span={Math.floor(24 / x)}>
            <div
              style={{
                backgroundColor: `${color}`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {v.group_name}
            </div>
            <DemoBox value={100}>{this.getPlateNoFromVid(sub_v)}</DemoBox>
          </Col>
        );
      });
    });
    const result_len = result.length;

    for (let index = 0; index < count_temp - result_len; index++) {
      result.push(
        <Col span={Math.floor(24 / x)}>
          <div
            style={{
              border: "1px solid #d9d9d9",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderBottom: "none",
            }}
          >
            Group
          </div>
          <DemoBox value={100}>Empty</DemoBox>
        </Col>
      );
    }
    // console.log(result.length);
    return result;
  };
  getPlateNoFromVid = (v_id) => {
    const resultObject = this.props.vehicleList.find((obj) => obj.id === v_id);
    return resultObject ? resultObject.plate_no : null;
  };

  render() {
    // console.log(this.props);
    return (
      <div>
        {this.props.demoGroup && this.props.demoBox.x > 0
          ? this.generateDemoGroup(
              this.props.demoGroup,
              this.props.demoBox.x,
              this.props.demoBox.y
            )
          : this.generateDemo(this.props.demoBox.x, this.props.demoBox.y)}
      </div>
    );
  }
}
