import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as moment from "moment";
import {
  Button,
  message,
  Form,
  Row,
  Col,
  Input,
  TreeSelect,
  Tabs,
  Select,
} from "antd";
import axios from "axios";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";
import {
  GeneralStyledContent,
  StyledSearchForm,
} from "../../../../styled/common-styled";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import LangContext, { i18n } from "modules/shared/context/langContext";
import ProfileTab from "./ProfileTab";
const { TabPane } = Tabs;

class Index extends Component {
  state = {};

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <AuthorizeComponent matching_name="">
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                match={this.props.match}
                style={{ margin: "0px 0px 30px 10px" }}
              />
            </Col>
          </Row>

          <Row>
            <StyledSearchForm>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Tabs defaultActiveKey="1" onChange={() => false}>
                    <TabPane
                      tab={
                        <LangContext.Consumer>
                          {(i18n) => i18n.p.profile}
                        </LangContext.Consumer>
                      }
                      key="1"
                    >
                      <ProfileTab />
                    </TabPane>
                    {/* <TabPane
                      tab={
                        <LangContext.Consumer>
                          {(i18n) => i18n.p.permission}
                        </LangContext.Consumer>
                      }
                      key="2"
                    >
                      Content of Tab Pane 2
                    </TabPane>
                    <TabPane
                      tab={
                        <LangContext.Consumer>
                          {(i18n) => i18n.d.dataVisibility}
                        </LangContext.Consumer>
                      }
                      key="3"
                    >
                      Content of Tab Pane 3
                    </TabPane> */}
                  </Tabs>
                </Col>
              </Row>
            </StyledSearchForm>
          </Row>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

export default connect(
  ({ auth }) => ({
    auth,
  }),
  {}
)(Index);
