import { createTypes, createAction } from "lib/action";

const LOAD_BOOKING = createTypes("booking", "load");
const LOAD_BOOKING_DETAIL = createTypes("bookingdetail", "load");
const LOAD_AUTOCOMPLETE = createTypes("autocomplete", "load");
const LOAD_BOOKING_VEHICLE = createTypes("bookingvehicle", "load");
const SELECTED_AUTOCOMPLETE = createTypes("autocomplete", "selected");
const CREATE_BOOKING = createTypes("booking", "create");
const UPDATE_BOOKING = createTypes("booking", "update");
const UPDATE_BOOKING_STATUS = createTypes("bookingDevice", "update");
const SELECTED_BOOKING = "SELECTED_BOOKING";
const SELECTED_VEHICLE = createTypes("bookingvehicle", "selected");
const REMOVE_LISTS = `REMOVE_LISTS`;
const REMOVE_VEHICLE_LISTS = `REMOVE_VEHICLE_LISTS`;

function removeLists() {
  return {
    type: REMOVE_LISTS
  };
}
function removeVehicleList() {
  return {
    type: REMOVE_VEHICLE_LISTS
  };
}

const loadBooking = {
  request: (data, loading) =>
    createAction(LOAD_BOOKING.REQUEST, { data, loading }),
  success: (lists, page) => createAction(LOAD_BOOKING.SUCCESS, { lists, page }),
  failure: err => createAction(LOAD_BOOKING.FAILURE, { err })
};

const loadBookingDetail = {
  request: (data, cb) =>
    createAction(LOAD_BOOKING_DETAIL.REQUEST, { data, cb }),
  success: (lists, total) =>
    createAction(LOAD_BOOKING_DETAIL.SUCCESS, { lists, total }),
  failure: () => createAction(LOAD_BOOKING_DETAIL.FAILURE)
};

const loadAutocomplete = {
  request: (url, companyID, accessToken, queryString) =>
    createAction(LOAD_AUTOCOMPLETE.REQUEST, {
      url,
      companyID,
      accessToken,
      queryString
    }),
  success: lists => createAction(LOAD_AUTOCOMPLETE.SUCCESS, { lists }),
  failure: () => createAction(LOAD_AUTOCOMPLETE.FAILURE)
};

const createBooking = {
  request: (data, history, handleActionChange, handleJobIdChange) =>
    createAction(CREATE_BOOKING.REQUEST, {
      data,
      history,
      handleActionChange,
      handleJobIdChange
    }),
  success: res => createAction(CREATE_BOOKING.SUCCESS, { res }),
  failure: () => createAction(CREATE_BOOKING.FAILURE)
};

const updateBooking = {
  request: (data, history, handleActionChange, handleJobIdChange) =>
    createAction(UPDATE_BOOKING.REQUEST, {
      data,
      history,
      handleActionChange,
      handleJobIdChange
    }),
  success: res => createAction(UPDATE_BOOKING.SUCCESS, { res }),
  failure: () => createAction(UPDATE_BOOKING.FAILURE)
};

const updateBookingStatus = {
  request: data => createAction(UPDATE_BOOKING_STATUS.REQUEST, { data }),
  success: res => createAction(UPDATE_BOOKING_STATUS.SUCCESS, { res }),
  failure: () => createAction(UPDATE_BOOKING_STATUS.FAILURE)
};

const loadBookingVehicle = {
  request: (
    lat,
    lng,
    searchName,
    meterStatus,
    engineStatus,
    company_id,
    vehicle_visibility
  ) =>
    createAction(LOAD_BOOKING_VEHICLE.REQUEST, {
      lat,
      lng,
      searchName,
      meterStatus,
      engineStatus,
      company_id,
      vehicle_visibility
    }),
  success: data => createAction(LOAD_BOOKING_VEHICLE.SUCCESS, { data }),
  failure: () => createAction(LOAD_BOOKING_VEHICLE.FAILURE)
};

function selectedAutocomplete(id, lists, selectedName, cb) {
  return {
    type: SELECTED_AUTOCOMPLETE,
    payload: { id, lists, selectedName, cb }
  };
}

function selectedBooking(booking) {
  return {
    type: SELECTED_BOOKING,
    payload: { booking }
  };
}

function selectedVehicle(vehicle) {
  return {
    type: SELECTED_VEHICLE,
    payload: { vehicle }
  };
}

export {
  LOAD_AUTOCOMPLETE,
  SELECTED_AUTOCOMPLETE,
  loadAutocomplete,
  selectedAutocomplete,
  CREATE_BOOKING,
  createBooking,
  selectedBooking,
  SELECTED_BOOKING,
  loadBookingVehicle,
  LOAD_BOOKING_VEHICLE,
  selectedVehicle,
  SELECTED_VEHICLE,
  updateBookingStatus,
  UPDATE_BOOKING_STATUS,
  loadBooking,
  LOAD_BOOKING,
  REMOVE_LISTS,
  removeLists,
  LOAD_BOOKING_DETAIL,
  loadBookingDetail,
  updateBooking,
  UPDATE_BOOKING,
  removeVehicleList,
  REMOVE_VEHICLE_LISTS
};
