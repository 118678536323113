import { createTypes, createAction } from "lib/action";

const LOAD_SPEEDOVERLIMIT = createTypes("speedoverlimit", "load");
const LOAD_VEHICLE_SPEEDOVERLIMIT = createTypes(
  "vehicle_speedoverlimit",
  "load"
);
const LOAD_VEHICLE_TYPE_SPEEDOVERLIMIT = createTypes(
  "vehicletype_speedoverlimit",
  "load"
);

const loadSpeedOverLimit = {
  request: (data, loading) =>
    createAction(LOAD_SPEEDOVERLIMIT.REQUEST, { data, loading }),
  success: data => createAction(LOAD_SPEEDOVERLIMIT.SUCCESS, { data }),
  failure: () => createAction(LOAD_SPEEDOVERLIMIT.FAILURE)
};

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_SPEEDOVERLIMIT.REQUEST, {
      vehicletypeID,
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_VEHICLE_SPEEDOVERLIMIT.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_SPEEDOVERLIMIT.FAILURE)
};

const loadVehicleType = {
  request: () =>
    createAction(LOAD_VEHICLE_TYPE_SPEEDOVERLIMIT.REQUEST, {}),
  success: data =>
    createAction(LOAD_VEHICLE_TYPE_SPEEDOVERLIMIT.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_SPEEDOVERLIMIT.FAILURE)
};

export {
  loadSpeedOverLimit,
  LOAD_SPEEDOVERLIMIT,
  loadVehicle,
  LOAD_VEHICLE_SPEEDOVERLIMIT,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_SPEEDOVERLIMIT
};
