import { createTypes, createAction } from "lib/action";


const LOAD_CONTACTMANAGEMENT = createTypes("contactmanagement", "load");
const CREATE_CONTACTMANAGEMENT = createTypes("contactmanagement", "create");
const CLEAR_RESPONSE_CONTACTMANAGEMENT = createTypes("response_contactmanagement", "clear");

const loadContactmanagement = {
  request: (data, loading) =>
    createAction(LOAD_CONTACTMANAGEMENT.REQUEST, { data, loading }),
  success: data => createAction(LOAD_CONTACTMANAGEMENT.SUCCESS, { data }),
  failure: () => createAction(LOAD_CONTACTMANAGEMENT.FAILURE)
};


const createContactmanagement = {
  request: (data, loading) =>
    createAction(CREATE_CONTACTMANAGEMENT.REQUEST, { data, loading }),
  success: data => createAction(CREATE_CONTACTMANAGEMENT.SUCCESS, { data }),
  failure: () => createAction(CREATE_CONTACTMANAGEMENT.FAILURE)
};

const clearDataResponse = () => {
  return {
    type: CLEAR_RESPONSE_CONTACTMANAGEMENT
  };
};

export {
  loadContactmanagement,
  LOAD_CONTACTMANAGEMENT,
  createContactmanagement,
  CREATE_CONTACTMANAGEMENT,
  clearDataResponse,
  CLEAR_RESPONSE_CONTACTMANAGEMENT

};
