import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getlocalstorage } from "./../../../constants/local_storage";
//import { push } from "connected-react-router";

import * as actions from "./actions";
import moment from "moment";

function* loadNotinotswipecard(action) {
  //const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );
  const USER_ID = getlocalstorage(localStorage.getItem("profile"), "id");

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  const {
    data: {
      list_vehicle_id,
      //vehicle_type_id,
      eventdate,
      type_file
    }
  } = action.payload;
  const date_start = moment(eventdate[0]["_d"]).format("YYYY-MM-DD") + " 00:00:00";
  const date_end = moment(eventdate[1]["_d"]).format("YYYY-MM-DD") + " 23:59:00";

 
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_GOLANG_MONGO}/reports/go/notinotswipecard`,
      {
        date_start,
        date_end,
        list_vehicle_id,
        company_id: COMPANY_ID,
        //vehicle_type_id,
        user_id: USER_ID,
        type_file,
        vehicle_visibility,
      }
    );

    yield put(actions.loadNotinotswipecard.success(res.data));
    window.location = res.data.result;
  } catch (err) {
    yield put(actions.loadNotinotswipecard.failure());
  }
}

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  const { vehicletypeID } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebygroupid`,
      {
        companyID: COMPANY_ID,
        vehicletypeID: vehicletypeID,
        allow_vehicles: vehicle_visibility
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadVehicle.success(res.data));
  } catch (err) {
    yield put(actions.loadVehicle.failure());
  }
}

function* loadVehicleType(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getgroupvehicle`,
      {
        company_id: COMPANY_ID
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` }
      }
    );
    yield put(actions.loadVehicleType.success(res.data));
  } catch (err) {
    yield put(actions.loadVehicleType.failure());
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(actions.LOAD_NOTINOTSWIPECARD.REQUEST, loadNotinotswipecard),
    takeEvery(actions.LOAD_VEHICLE_NOTINOTSWIPECARD.REQUEST, loadVehicle),
    takeEvery(actions.LOAD_VEHICLE_TYPE_NOTINOTSWIPECARD.REQUEST, loadVehicleType)
  ]);
}

export { loadNotinotswipecard, loadVehicle, loadVehicleType };
