import { createTypes, createAction } from "lib/action";

const LOAD_TRIP = createTypes("tripmasterdata", "load");
const LOAD_TRIP_DETAIL = createTypes("tripdetailmasterdata", "load");
const CREATE_TRIP = createTypes("tripmasterdata", "create");
const UPDATE_TRIP = createTypes("tripmasterdata", "update");
const REMOVE_LISTS = `REMOVE_LISTS`;
const IMPORT_TRIP = createTypes("tripmasterdata", "import");

function removeLists() {
  return {
    type: REMOVE_LISTS,
  };
}

const importTrip = {
  request: (file, company_id,user_id, token,vehicle_visibility,is_super,cb) => createAction(IMPORT_TRIP.REQUEST, { file, company_id,user_id, token, vehicle_visibility,is_super,cb }),
  success: data => createAction(IMPORT_TRIP.SUCCESS, { data }),
  failure: err => createAction(IMPORT_TRIP.FAILURE, { err }),
}


const loadTrip = {
  request: (data, loading) =>
    createAction(LOAD_TRIP.REQUEST, { data, loading }),
  success: (lists, page) => createAction(LOAD_TRIP.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_TRIP.FAILURE),
};

const loadTripDetail = {
  request: (data, cb) => createAction(LOAD_TRIP_DETAIL.REQUEST, { data, cb }),
  success: (trip) => createAction(LOAD_TRIP_DETAIL.SUCCESS, { trip }),
  failure: () => createAction(LOAD_TRIP_DETAIL.FAILURE),
};

const createTrip = {
  request: (data, cb) =>
    createAction(CREATE_TRIP.REQUEST, {
      data,
      cb,
    }),
  success: (res) => createAction(CREATE_TRIP.SUCCESS, { res }),
  failure: () => createAction(CREATE_TRIP.FAILURE),
};

const updateTrip = {
  request: (data, cb) =>
    createAction(UPDATE_TRIP.REQUEST, {
      data,
      cb,
    }),
  success: (res) => createAction(UPDATE_TRIP.SUCCESS, { res }),
  failure: () => createAction(UPDATE_TRIP.FAILURE),
};

export {
  CREATE_TRIP,
  createTrip,
  loadTrip,
  importTrip,
  LOAD_TRIP,
  REMOVE_LISTS,
  removeLists,
  LOAD_TRIP_DETAIL,
  loadTripDetail,
  updateTrip,
  UPDATE_TRIP,
  IMPORT_TRIP,
};
