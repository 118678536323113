import {
  LOAD_MANAGE_DASHBOARD,
  LOAD_MANAGE_DASHBOARD_USER,
  LOAD_LAYOUT_DISPLAY,
  CREATE_MANAGE_DASHBOARD,
  LOAD_DISPLAY_DETAIL,
  UPDATE_MANAGE_DASHBOARD,
  DELETE_MANAGE_DASHBOARD,
} from "./actions";

const initialState = {
  displayDetail: {
    display_name: "",
    companies_id: 0,
    camera_layout_type_id: "",
    user_id: [],
    group_list: [{ group_name: "", vehicle_list: [] }],
    all_vehicle_list: [],
    demoBox: {
      x: 0,
      y: 0,
      xy: 0,
    },
    demoGroup: false,
  },
  dashboardData: [],
  userList: [],
  duplicateVehicle: [],
  layout: [],
  msg: "",
  status: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_MANAGE_DASHBOARD.REQUEST:
      return {
        ...state,
        dashboardData: [],
        duplicateVehicle: [],
        loading: true,
      };
    case LOAD_MANAGE_DASHBOARD_USER.REQUEST:
      return {
        ...state,
        userList: [],
        loading: true,
      };
    case LOAD_LAYOUT_DISPLAY.REQUEST:
      return {
        ...state,
        layout: [],
        loading: true,
      };
    case CREATE_MANAGE_DASHBOARD.REQUEST:
      return {
        ...state,
        duplicateVehicle: [],
        loading: true,
      };
    case UPDATE_MANAGE_DASHBOARD.REQUEST:
      return {
        ...state,
        duplicateVehicle: [],
        loading: true,
      };
    case DELETE_MANAGE_DASHBOARD.REQUEST:
      return {
        ...state,
        duplicateVehicle: [],
        loading: true,
      };
    case LOAD_DISPLAY_DETAIL.REQUEST:
      return {
        ...state,
        duplicateVehicle: [],
        displayDetail: {
          display_name: "",
          companies_id: 0,
          camera_layout_type_id: "",
          user_id: [],
          group_list: [{ group_name: "", vehicle_list: [] }],
          all_vehicle_list: [],
          demoBox: {
            x: 0,
            y: 0,
            xy: 0,
          },
          demoGroup: false,
        },
        loading: true,
      };
    case LOAD_MANAGE_DASHBOARD.SUCCESS:
      return {
        ...state,
        dashboardData: action.payload.data,
        loading: false,
      };
    case LOAD_MANAGE_DASHBOARD_USER.SUCCESS:
      return {
        ...state,
        userList: action.payload.data,
        loading: false,
      };
    case LOAD_LAYOUT_DISPLAY.SUCCESS:
      return {
        ...state,
        layout: action.payload.data,
        loading: false,
      };
    case CREATE_MANAGE_DASHBOARD.SUCCESS:
      return {
        ...state,
        msg: action.payload.data.message,
        status: action.payload.data.status,
        duplicateVehicle: action.payload.data,
        loading: false,
      };

    case UPDATE_MANAGE_DASHBOARD.SUCCESS:
      return {
        ...state,
        msg: action.payload.data.message,
        status: action.payload.data.status,
        loading: false,
        duplicateVehicle: action.payload.data,
      };
    case DELETE_MANAGE_DASHBOARD.SUCCESS:
      return {
        ...state,
        msg: "Delete Success",
        status: "success",
        loading: false,
      };
    case LOAD_DISPLAY_DETAIL.SUCCESS:
      return {
        ...state,
        displayDetail: action.payload.data.data_display,
        duplicateVehicle: action.payload.data,
        loading: false,
      };
    case LOAD_MANAGE_DASHBOARD.FAILURE:
    case LOAD_MANAGE_DASHBOARD_USER.FAILURE:
    case LOAD_LAYOUT_DISPLAY.FAILURE:
    case CREATE_MANAGE_DASHBOARD.FAILURE:
    case UPDATE_MANAGE_DASHBOARD.FAILURE:
    case DELETE_MANAGE_DASHBOARD.FAILURE:
    case LOAD_DISPLAY_DETAIL.FAILURE:
      console.log(action.payload);
      return {
        ...state,
        msg: action.payload.err,
        loading: false,
        status: "fail",
        shouldReload: false,
      };
    default:
      return state;
  }
};
