import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";
import moment from "moment";


function* createCalibratefuel(action) {

  const {
    data: {
      vehicle_group_id,
      company_id,
      fueldate,
      list_vehicle_id,
      fueltank,
      fueladd 
    },token
  } = action.payload;
  const date_start = moment(fueldate[0]["_d"]).format("YYYY-MM-DD");
  const date_end = moment(fueldate[1]["_d"]).format("YYYY-MM-DD");
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/calibratefuel/create`,
      {
        date_start,date_end,vehicle_group_id,list_vehicle_id,fueladd,fueltank, company_id
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    yield put(actions.createCalibratefuel.success(res.data));
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`
    );

  } catch (err) {
    yield put(actions.createCalibratefuel.failure());
  }
}




export default function* watchReportPoolState() {
  yield all([
    takeEvery(actions.CREATE_CALIBRATEFUEL.REQUEST, createCalibratefuel),
  ]);
}

export {
  createCalibratefuel,
};
