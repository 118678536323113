import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* verifyUser(action) {
  const { company_id, user_id, email, accessToken } = action.payload.data;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/postMail`,
      {
        company_id,
        user_id,
        email
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.verifyUser.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.verifyUser.failure());
  }
}

function* checkverifyemail(action) {
  const { token, user_id, verify_id, accessToken } = action.payload.data;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/check/verify`,
      {
        token,
        user_id,
        verify_id
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.checkverifyemail.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.checkverifyemail.failure());
  }
}

export default function* watchVerify() {
  yield all([
    takeEvery(actions.CREATE_USER_VERIFICATION.REQUEST, verifyUser),
    takeEvery(actions.CHECK_VERIFY_EMAIL.REQUEST, checkverifyemail)
  ]);
}

export { verifyUser, checkverifyemail };
