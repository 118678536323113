import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";


function* loadProduct(action) {

  const {
    data: { page, pageSize, orderBy, orderType, filterObj, token,company_id }
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/product`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        filterObj,
        company_id
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    yield put(actions.loadProduct.success(res.data));
  } catch (err) {
    yield put(actions.loadProduct.failure());
  }
}

function* createProduct(action) {

  const {
    data: {
      name,code, description,uom_id, token, company_id },
    history
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/product/create`,
      {
        name,code, description,uom_id, company_id
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    yield put(actions.createProduct.success(res.data));
    history.push("/product/edit/"+res.data.id);
    console.log("success")

  } catch (err) {
    yield put(actions.createProduct.failure());
  }
}

function* updateProduct(action) {

  const {
    data: {
      name,code, description,uom_id, token, company_id },
    id,
    history
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/product/update`,
      {
        name,code, description,uom_id, company_id,
        id
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    yield put(actions.updateProduct.success(res.data));
    history.push("/product/edit/"+id);
  } catch (err) {
    yield put(actions.updateProduct.failure());
  }
}

function* getProductByID(action) {

  const { id, token } = action.payload;
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/product/${id}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    yield put(actions.getProductByID.success(res.data));
  } catch (err) {
    yield put(actions.getProductByID.failure());
  }
}




export default function* watchReportPoolState() {
  yield all([
    takeEvery(actions.LOAD_PRODUCT.REQUEST, loadProduct),
    takeEvery(actions.CREATE_PRODUCT.REQUEST, createProduct),
    takeEvery(actions.UPDATE_PRODUCT.REQUEST, updateProduct),
    takeEvery(actions.GET_PRODUCT.REQUEST, getProductByID),
  ]);
}

export {
  loadProduct,
  createProduct,
  updateProduct,
  getProductByID,
};
