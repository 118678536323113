import { createTypes, createAction } from "lib/action";

const LOAD_SCHEDULEREPORT = createTypes("schedulereport", "load");
const CREATE_SCHEDULEREPORT = createTypes("schedulereport", "create");
const UPDATE_SCHEDULEREPORT = createTypes("schedulereport", "delete");
const GET_SCHEDULEREPORT = createTypes("schedulereport", "get");
const CLEAR_SCHEDULEREPORT = createTypes("schedulereport", "clear");
const LOAD_REPORT_SCHEDULEREPORT = createTypes(
  "report_schedulereport",
  "load"
);
const CLEAR_RESPONSE = createTypes("response", "clear");

const loadReportSchedulereport = {
  request: () =>
    createAction(LOAD_REPORT_SCHEDULEREPORT.REQUEST, {}),
  success: data => createAction(LOAD_REPORT_SCHEDULEREPORT.SUCCESS, { data }),
  failure: () => createAction(LOAD_REPORT_SCHEDULEREPORT.FAILURE)
};

const loadSchedulereport = {
  request: (data, loading) =>
    createAction(LOAD_SCHEDULEREPORT.REQUEST, { data, loading }),
  success: data => createAction(LOAD_SCHEDULEREPORT.SUCCESS, { data }),
  failure: () => createAction(LOAD_SCHEDULEREPORT.FAILURE)
};

const createSchedulereport = {
  request: (data, loading, history) =>
    createAction(CREATE_SCHEDULEREPORT.REQUEST, {
      data,
      loading,
      history
    }),
  success: data => createAction(CREATE_SCHEDULEREPORT.SUCCESS, { data }),
  failure: () => createAction(CREATE_SCHEDULEREPORT.FAILURE)
};

const updateSchedulereport = {
  request: (data, id, history, loading) =>
    createAction(UPDATE_SCHEDULEREPORT.REQUEST, { data, id, history, loading }),
  success: data => createAction(UPDATE_SCHEDULEREPORT.SUCCESS, { data }),
  failure: () => createAction(UPDATE_SCHEDULEREPORT.FAILURE)
};


const getSchedulereportByID = {
  request: id => createAction(GET_SCHEDULEREPORT.REQUEST, { id }),
  success: data => createAction(GET_SCHEDULEREPORT.SUCCESS, { data }),
  failure: () => createAction(GET_SCHEDULEREPORT.FAILURE)
};





const clearDataResponse = () => {
  return {
    type: CLEAR_RESPONSE
  };
};

const clearSchedulereport = () => {
  return {
    type: CLEAR_SCHEDULEREPORT
  };
};

export {
  LOAD_SCHEDULEREPORT,
  CREATE_SCHEDULEREPORT,
  UPDATE_SCHEDULEREPORT,
  GET_SCHEDULEREPORT,
  loadSchedulereport,
  createSchedulereport,
  updateSchedulereport,
  getSchedulereportByID,
  loadReportSchedulereport,
  LOAD_REPORT_SCHEDULEREPORT,
  CLEAR_RESPONSE,
  clearDataResponse,
  clearSchedulereport,
  CLEAR_SCHEDULEREPORT
};
