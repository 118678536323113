import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
  PRODUCTION_API_ENDPOINT,
  DEV_API_ENDPOINT
} from "../../../constants/url_endpoint";
import { ACCESSTOKEN, COMPANY_ID } from "../../../constants/local_storage";
import * as actions from "./actions";

function* loadGPSCertHistory(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      vehicleVisibility,
      job_status,
      orderBy,
      orderType,
      filterObj,
      cb
    }
  } = action.payload;
  // const company_id =
  try {
    const res = yield call(
      axios.post,
      //`http://booking-api/api/getgpscertificationrequesthistory`,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getgpscertificationrequesthistory`,
      {
        searchName,
        page,
        pageSize,
        companyID,
        vehicleVisibility,
        job_status,
        orderBy,
        orderType,
        filterObj,
        cb
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    cb();
    yield put(actions.loadGPSCertHistory.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadGPSCertHistory.failure());
  }
}

function* loadGPSCert(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      vehicleVisibility,
      orderBy,
      orderType
    }
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      //`http://booking-api/api/getgpscertificationrequest`,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getgpscertificationrequest`,
      {
        searchName,
        page,
        pageSize,
        companyID,
        vehicleVisibility,
        orderBy,
        orderType
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadGPSCert.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadGPSCert.failure());
  }
}

function* loadGPSCertDetail(action) {
  const {
    data: { vehicleID },
    cb
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      //`http://booking-api/api/getgpscertificationrequestdetail`,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getgpscertificationrequestdetail`,
      {
        vehicleID
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    //cb(res.data[0]);

    yield put(actions.loadGPSCertDetail.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadGPSCertDetail.failure());
  }
}

export default function* watchGPSCertificationRequestState() {
  yield all([
    takeEvery(actions.LOAD_GPS_CERT.REQUEST, loadGPSCert),
    takeEvery(actions.LOAD_GPS_CERT_DETAIL.REQUEST, loadGPSCertDetail),
    takeEvery(actions.LOAD_GPS_CERT_HISTORY.REQUEST, loadGPSCertHistory)
  ]);
}

export { loadGPSCert, loadGPSCertDetail, loadGPSCertHistory };
