import { createTypes, createAction } from "lib/action";

const LOAD_NOTINOTSWIPECARD = createTypes("notinotswipecard", "load");
const LOAD_VEHICLE_NOTINOTSWIPECARD = createTypes(
  "vehicle_notinotswipecard",
  "load"
);
const LOAD_VEHICLE_TYPE_NOTINOTSWIPECARD = createTypes("vehicletype_notinotswipecard", "load");

const loadNotinotswipecard = {
  request: (data, loading) =>
    createAction(LOAD_NOTINOTSWIPECARD.REQUEST, { data, loading }),
  success: data => createAction(LOAD_NOTINOTSWIPECARD.SUCCESS, { data }),
  failure: () => createAction(LOAD_NOTINOTSWIPECARD.FAILURE)
};

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_NOTINOTSWIPECARD.REQUEST, {
      vehicletypeID,
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_VEHICLE_NOTINOTSWIPECARD.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_NOTINOTSWIPECARD.FAILURE)
};

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_NOTINOTSWIPECARD.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_TYPE_NOTINOTSWIPECARD.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_NOTINOTSWIPECARD.FAILURE)
};

export {
  loadNotinotswipecard,
  LOAD_NOTINOTSWIPECARD,
  loadVehicle,
  LOAD_VEHICLE_NOTINOTSWIPECARD,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_NOTINOTSWIPECARD
};
