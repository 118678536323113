import React, { Component } from "react";
import { Button, Col, Form, Input, Transfer, Select, Row, Table } from "antd";

export default class Group extends Component {
  state = { value: [], indexCount: 1 };

  onClearTransfer = (index) => {
    this.props.onChangeGroup([], index, "vehicle_list");
  };
  generateActionButton = (index) => {
    const lastIndex = this.props.groupList.length - 1;

    return index === lastIndex && index !== 4 ? (
      lastIndex === 0 ? (
        <Col span={3}>
          <Button
            onClick={this.props.addGroup}
            type="primary"
            shape="circle"
            icon="plus"
            size="small"
          />
        </Col>
      ) : (
        <Col span={4}>
          <Button
            onClick={this.props.addGroup}
            type="primary"
            shape="circle"
            icon="plus"
            size="small"
            style={{ marginRight: "5px" }}
          />
          <Button
            onClick={() => this.props.deleteGroup(index)}
            type="danger"
            shape="circle"
            icon="delete"
            ghost
            size="small"
          />
        </Col>
      )
    ) : (
      <Col span={3}>
        <Button
          onClick={() => this.props.deleteGroup(index)}
          type="danger"
          shape="circle"
          icon="delete"
          ghost
          size="small"
        />
      </Col>
    );
  };

  formatVehicleList = (data) => {
    let newArr = data.map((item) => {
      return {
        key: item.id,
        label: item.plate_no,
        duplicate: this.props.selectedVehicleList.includes(item.id)
          ? true
          : false,
      };
    });
    return newArr;
  };
  getPlateNoFromVid = (v_id_arr) => {
    let result = "";
    v_id_arr.map((v_id) => {
      let resultObject = this.props.vehicleList.find((obj) => obj.id === v_id);
      if (result === "") {
        result += resultObject.plate_no;
      } else {
        result += "," + resultObject.plate_no;
      }
    });
    return result;
  };
  render() {
    return (
      <div>
        <Row gutter={[0, 16]}>
          {this.props.groupList
            ? this.props.groupList.map((value, index) => (
                <Col span={11}>
                  <Row>
                    <div style={{ display: "inline" }}>ชื่อกลุ่มพาหนะ</div>
                    <div style={{ display: "inline", color: "red" }}>
                      {index === 4 ? " (จำกัด5กลุ่มพาหนะ)" : ""}
                    </div>
                  </Row>
                  <Row type="flex" justify="space-between">
                    <Col>
                      <Input
                        value={value.group_name}
                        style={{ width: 150 }}
                        maxLength={40}
                        onChange={(v) => {
                          this.props.onChangeGroup(
                            v.target.value,
                            index,
                            "group_name"
                          );
                        }}
                        required
                      ></Input>
                    </Col>
                    {this.generateActionButton(index)}
                  </Row>

                  <Row>
                    <Transfer
                      titles={[
                        "Source",
                        <div
                          style={{ cursor: "pointer", color: "#1890ff" }}
                          onClick={() => this.onClearTransfer(index)}
                        >
                          Clear
                        </div>,
                      ]}
                      // name="vehicle"
                      style={{
                        margin: "10px 0px 0px 0px",
                      }}
                      listStyle={(v) => ({
                        width: window.innerWidth / 8.5,
                        height: 300,
                      })}
                      dataSource={this.formatVehicleList(
                        this.props.vehicleList
                      )}
                      showSearch
                      filterOption={(inputValue, item) =>
                        item.label.indexOf(inputValue) !== -1
                      }
                      targetKeys={value.vehicle_list}
                      onChange={(targetKeys, direction, moveKeys) => {
                        const result = this.props.selectedVehicleList.filter(
                          (value) => moveKeys.includes(value)
                        );
                        if (result.length !== 0 && direction === "right") {
                          alert(
                            "ไม่สามารถเลือกรถซ้ำได้ " +
                              this.getPlateNoFromVid(result)
                          );
                        } else {
                          this.props.onChangeGroup(
                            targetKeys,
                            index,
                            "vehicle_list"
                          );
                        }
                      }}
                      render={(item) =>
                        item.duplicate === true ? (
                          <div
                            style={{
                              color: "#d9d9d9",
                              display: "inline",
                            }}
                          >
                            {item.label}
                          </div>
                        ) : (
                          item.label
                        )
                      }
                    ></Transfer>
                  </Row>
                </Col>
              ))
            : ""}
          {/* <Col span={2}>
            <Button
              onClick={this.props.addGroup}
              type="link"
              style={{ marginTop: "55px", padding: "0" }}
            >
              + เพิ่มกลุ่ม
            </Button>
          </Col> */}
        </Row>
      </div>
    );
  }
}
