const SET_PARKING_MARKER = `SET_PARKING_MARKER`;
const REMOVE_PARKING_MARKER = `REMOVE_PARKING_MARKER`;

const SET_IMAGE_VEHICLE_MARKER = `SET_IMAGE_VEHICLE_MARKER`;
const REMOVE_IMAGE_VEHICLE_MARKER = `REMOVE_IMAGE_VEHICLE_MARKER`;

function setParkingMarker(data, index) {
  return {
    type: SET_PARKING_MARKER,
    payload: {
      data,
      index,
    },
  };
}

function removeParkingMarker() {
  return {
    type: REMOVE_PARKING_MARKER,
    payload: {},
  };
}

function setVehicleImageMarker(data, index) {
  return {
    type: SET_IMAGE_VEHICLE_MARKER,
    payload: {
      data,
      index,
    },
  };
}

function removeVehicleImageMarker() {
  return {
    type: REMOVE_IMAGE_VEHICLE_MARKER,
    payload: {},
  };
}

export {
  setParkingMarker,
  SET_PARKING_MARKER,
  removeParkingMarker,
  REMOVE_PARKING_MARKER,
  setVehicleImageMarker,
  REMOVE_IMAGE_VEHICLE_MARKER,
  removeVehicleImageMarker,
  SET_IMAGE_VEHICLE_MARKER
};
