import {
  LOAD_DRIVERINFORMATION,
  LOAD_DRIVER_DRIVERINFORMATION,

} from "./actions";

const initialState = {
  driverinformationUrl: "",
  driverLists: [],
  driverinformationLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DRIVERINFORMATION.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        driverinformationLoading: loading
      };
    case LOAD_DRIVERINFORMATION.SUCCESS:

      return {
        ...state,
        driverinformationUrl: action.payload.data.data,
        driverinformationLoading: false
      };
    case LOAD_DRIVER_DRIVERINFORMATION.SUCCESS:
      return {
        ...state,
        driverLists: action.payload.data.data
      };
    default:
      return state;
  }
};
