import React, { Component, Fragment } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";
import {
  Button,
  Form,
  DatePicker,
  AutoComplete,
  Select,
  Badge,
  Modal,
} from "antd";
import LangContext from "modules/shared/context/langContext";
import { genDeviceProcessDetail } from "modules/monitoring/honeytoastdownloadfile/components/deviceProcessFunc";

const FormItem = Form.Item;
const { Option, OptGroup } = AutoComplete;
export default class Filter extends Component {
  changeVehiclePlateno = (value, option, setFieldValue) => {
    setFieldValue("plate_no", option.props.children);
    setFieldValue("imei", option.props.label);
  };

  handleOpenDeviceProcessModal = () => {
    let html = genDeviceProcessDetail(this.props.deviceProcess.userList);

    Modal.warning({
      title: "อุปกรณ์นี้ กำลังมีการเชื่อมต่อการใช้งานวีดีโอดังนี้",
      content: <div dangerouslySetInnerHTML={{ __html: html }}></div>,
      okText: "ปิด",
    });
  };

  render() {
    const { OnClose, onSubmit } = this.props;

    return (
      <Formik
        initialValues={{
          plate_no: "",
          date: moment(),
          camera_position: "front",
        }}
        validationSchema={yup.object().shape({})}
        onSubmit={(values) => {
          const loading = true;
          onSubmit(values, loading);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          handleFocus,
          setFieldValue,
          resetForm,
        }) => {
          return (
            <Fragment>
              <Form onSubmit={handleSubmit} layout={"inline"}>
                <FormItem>
                  <Button
                    className="blinking"
                    type="danger"
                    disabled={this.props.deviceProcess === null}
                    icon="eye"
                    size="small"
                    onClick={this.handleOpenDeviceProcessModal}
                  />
                </FormItem>

                <Form.Item
                  required={true}
                  label={
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.date}
                    </LangContext.Consumer>
                  }
                  validateStatus={touched.date && errors.date && "error"}
                  help={touched.date && errors.date}
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={(value) => setFieldValue("date", value)}
                    value={values.date}
                    style={{ width: "100%", marginBottom: 10 }}
                    className="filter-video-playback"
                    size="large"
                  />
                </Form.Item>

                <FormItem
                  label={
                    <LangContext.Consumer>
                      {(i18n) => i18n.c.camera_position}
                    </LangContext.Consumer>
                  }
                  validateStatus={
                    touched.camera_position && errors.camera_position && "error"
                  }
                  help={touched.camera_position && errors.camera_position}
                >
                  <Select
                    //showSearch
                    style={{ width: 200 }}
                    onChange={(value) =>
                      setFieldValue("camera_position", value)
                    }
                    value={values.camera_position}
                    size="large"
                  >
                    <Option value="front">
                      <LangContext.Consumer>
                        {(i18n) => i18n.f.front}
                      </LangContext.Consumer>
                    </Option>
                    <Option value="back">
                      <LangContext.Consumer>
                        {(i18n) => i18n.c.camera_back}
                      </LangContext.Consumer>
                    </Option>

                    <Option value="extra">
                      <LangContext.Consumer>
                        {(i18n) => i18n.c.camera_extra}
                      </LangContext.Consumer>
                    </Option>
                  </Select>
                </FormItem>

                <FormItem>
                  <Button
                    htmlType="submit"
                    type="primary"
                    style={{ height: 40 }}
                  >
                    <LangContext.Consumer>
                      {(i18n) => i18n.a.apply}
                    </LangContext.Consumer>
                  </Button>
                </FormItem>
              </Form>
            </Fragment>
          );
        }}
      />
    );
  }
}
