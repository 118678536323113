import {
  LOAD_TRIPSUMBYVEH,

} from "./actions";

const initialState = {
  tripsumbyvehUrl: "",
  tripsumbyvehLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TRIPSUMBYVEH.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        tripsumbyvehLoading: loading
      };
    case LOAD_TRIPSUMBYVEH.SUCCESS:

      return {
        ...state,
        tripsumbyvehkUrl: action.payload.data.data,
        tripsumbyvehLoading: false
      };
    default:
      return state;
  }
};
