import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Spin, Modal, Select, Icon } from "antd";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Iframe from "react-iframe";
import Fullscreen from "react-full-screen";
import Filter from "./Filter";
import ListVehicle from "./ListVehicle";
import VehicleDropdown from "./VehicleDropdown";

import * as actions from "../actions";

import LangContext from "modules/shared/context/langContext";
import axios from "axios";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import { genDeviceProcessDetail } from "modules/monitoring/honeytoastdownloadfile/components/deviceProcessFunc";

import { DEFAULT_FILTER_VALUE } from "../../tracking/constants/tracking";
const clearEmptyString = (data) => {
  return data.filter((item) => item !== "");
};
class Index extends Component {
  state = {
    isFull: false,
    visible: false,
    imei: "",
    vehicle_select_detail: [],
    render_frame: [],
    imei_select: [],
    windowNum: 1,
    first_icing_index: [],
    url: process.env.REACT_APP_API_MDVR_TERMINUS,
    iFrameHeight: window.innerHeight - 150,
    deviceProcess: null,
    filter: {
      list_vehicle_id: [],
      vehicle_status: DEFAULT_FILTER_VALUE.vehicleStatus,
      device_type: ["J40", "HNT", "ISU"],
      orderBy: "plate_no",
      orderType: "asc",
    },
  };
  goFull = () => {
    this.setState({
      isFull: true,
      iFrameHeight: window.innerHeight + 110,
    });
  };

  onchangeFullscreen = (isFull) => {
    if (isFull) {
      this.setState({
        isFull,
      });
    } else {
      this.setState({
        isFull,
        iFrameHeight: window.innerHeight - 150,
      });
    }
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (values, loading) => {
    this.loadListVehicleHoneytoast(values, loading);
    this.setState({
      visible: false,
      filter: values,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  loadListVehicleHoneytoast = (values, loading) => {
    if (values === null) {
      values = this.state.filter;
    }
    this.props.loadListVehicleHoneytoast(values, loading);
  };

  loadVehicle = () => {
    this.props.loadVehicle();
  };

  loadVehicleSelect = (
    imei,
    cameraId,
    shouldUpdateDetail = true,
    fromISUDropdown = false
  ) => {
    let renderFrame = [...this.state.render_frame];
    let renderImei =
      typeof cameraId !== "undefined" ? imei + "_" + cameraId.toString() : imei;
    let firstIcingIdx = [...this.state.first_icing_index];

    //case select from renderframe
    if (!renderFrame.includes(renderImei)) {
      //find index empty frame in vehicles
      let emptyFrame = renderFrame.findIndex((x) => x === "");
      //no empty frame
      if (emptyFrame === -1) {
        if (this.state.windowNum === renderFrame.length) {
          renderFrame[renderFrame.length - 1] = renderImei;
        } else {
          renderFrame.push(renderImei);
        }
      }
      //have empty frame
      else {
        renderFrame[emptyFrame] = renderImei;
      }
      //find 1st icing index
      let res = firstIcingIdx.find((x) => x.imei === imei);
      if (typeof res === "undefined" && typeof cameraId !== "undefined") {
        firstIcingIdx.push({ imei: imei, camera_idx: cameraId });
      }
    }
    //case deselect from renderframe
    else {
      //case deselect from not isu dropdown
      if (!fromISUDropdown) {
        renderFrame = renderFrame.map((x) => {
          return x.split("_")[0] === renderImei.split("_")[0] ? "" : x;
        });
      }
      //case deselect from isu dropdown
      else {
        renderFrame = renderFrame.map((x) => {
          return x === renderImei ? "" : x;
        });
      }

      firstIcingIdx = firstIcingIdx.filter((x) => x.imei !== imei);

      for (var i = 0; i < renderFrame.length; i++) {
        let pure = renderFrame[i].split("_");
        if (pure[0] === imei && pure.length > 1) {
          if (
            typeof firstIcingIdx.find((x) => x.imei === imei) === "undefined"
          ) {
            firstIcingIdx.push({ imei: imei, camera_idx: pure[1] });
          }
        }
      }
    }

    let allImei = [];
    let detail = [...this.state.vehicle_select_detail];

    if (renderFrame.length > 0) {
      allImei = renderFrame.filter((x) => x != "").map((x) => x.split("_")[0]);
      if (shouldUpdateDetail) {
        detail = this.props.honeytoaststream.vehicleListHoneytoast.filter(
          (x) => {
            return allImei.includes(x.hardware_id);
          }
        );
      }
    }

    detail = this.customSort(allImei, detail);
    this.setState(
      {
        imei: imei,
        deviceProcess: null,
        render_frame: renderFrame,
        vehicle_select_detail: detail,
        imei_select: allImei,
        first_icing_index: firstIcingIdx,
      },
      this.handleCheckProcess
    );
  };
  customSort(order, array) {
    // Create a map to store the order of each id
    const orderMap = new Map();
    order.forEach((value, index) => orderMap.set(value, index));

    // Sort the array based on the order in the orderMap
    array.sort((a, b) => {
      const aOrder = orderMap.has(a.hardware_id)
        ? orderMap.get(a.hardware_id)
        : order.length;
      const bOrder = orderMap.has(b.hardware_id)
        ? orderMap.get(b.hardware_id)
        : order.length;
      return aOrder - bOrder;
    });

    return array;
  }
  setWindowNum = (window_nums, e) => {
    if (this.state.windowNum === window_nums) {
      return;
    }

    let vehicle_select_detail = [...this.state.vehicle_select_detail];
    let renderFrame = [...this.state.render_frame];
    let allImei = [...this.state.imei_select];
    let firstIcingIdx = [...this.state.first_icing_index];

    let new_select = [];
    let new_render_frame = [];
    let new_all_imei = [];
    let new_first_icing_indx = [];

    if (renderFrame.length >= window_nums) {
      let remainImei = [];
      for (let i = 0; i < window_nums; i++) {
        remainImei.push(renderFrame[i].split("_")[0]);
        new_render_frame[i] = renderFrame[i];
      }
      new_select = vehicle_select_detail.filter((x) =>
        remainImei.includes(x.hardware_id)
      );
      new_all_imei = allImei.filter((x) => remainImei.includes(x));
      new_render_frame.map((x) => {
        let arr = x.split("_");
        if (
          arr.length > 1 &&
          typeof new_first_icing_indx.find((x) => x.imei === arr[0]) ===
            "undefined"
        ) {
          new_first_icing_indx.push({ imei: arr[0], camera_idx: arr[1] });
        }
        return;
      });
    } else {
      new_select = vehicle_select_detail;
      new_render_frame = renderFrame;
      new_all_imei = allImei;
      new_first_icing_indx = firstIcingIdx;
    }

    this.setState({
      windowNum: window_nums,
      vehicle_select_detail: new_select,
      render_frame: new_render_frame,
      imei_select: new_all_imei,
      first_icing_index: new_first_icing_indx,
    });
  };

  getDeviceData = (imei) => {
    if (typeof imei === "undefined" || imei === "") {
      return { target_link: "honeytoast.php", device_type: "" };
    }

    let rec = this.props.honeytoaststream.vehicleListHoneytoast.find(
      (x) => x.hardware_id === imei
    );

    if (typeof rec !== "undefined") {
      return {
        target_link: rec.target_link,
        device_type: rec.device_types_id,
        plate_no: rec.plate_no,
        camera_channel: rec.camera_channel,
      };
    }
    return {
      target_link: "honeytoast.php",
      device_type: "",
      plate_no: "",
      camera_channel: [],
    };
  };

  handleCheckProcess = async () => {
    if (this.state.imei === "") {
      return;
    }

    if (this.state.windowNum === 1) {
      this.handleGetSingleImei();
    } else {
      this.handleGetMultiImei();
    }
  };

  handleGetSingleImei = async () => {
    try {
      const allconnect = await axios.get(
        `https://${process.env.REACT_APP_WEBSOCKET}/getDeviceProcessByImei?imei=${this.state.imei}`
      );
      const { cntDownload, cntLivestream, cntPlayback } = allconnect.data;
      let total = cntDownload + cntLivestream + cntPlayback;
      let result = allconnect.data;
      if (total === 0) {
        result = null;
      }
      this.setState({
        deviceProcess: result,
      });
    } catch (error) {}
  };

  handleGetMultiImei = async () => {
    try {
      const allconnect = await axios.get(
        `https://${process.env.REACT_APP_WEBSOCKET}/getDeviceProcesses`
      );

      let result = allconnect.data
        .map((x) => {
          if (this.state.imei_select.includes(x.imei)) {
            const { cntDownload, cntLivestream, cntPlayback } = x;
            let total = cntDownload + cntLivestream + cntPlayback;
            if (total !== 0) {
              let p = this.props.honeytoaststream.vehicleListHoneytoast.find(
                (a) => a.hardware_id === x.imei
              );
              x.plate_no = typeof p !== "undefined" ? p.plate_no : "";
              return x;
            }
          }
        })
        .filter((x) => typeof x !== "undefined");

      if (result.length === 0) {
        result = null;
      }

      this.setState({
        deviceProcess: result,
      });
    } catch (error) {}
  };

  handleOpenDeviceProcessModal = () => {
    if (this.state.windowNum === 1) {
      let html = genDeviceProcessDetail(this.state.deviceProcess.userList);

      Modal.warning({
        title: "อุปกรณ์นี้ กำลังมีการเชื่อมต่อการใช้งานวีดีโอดังนี้",
        content: <div dangerouslySetInnerHTML={{ __html: html }}></div>,
        okText: "ปิด",
      });
    } else {
      let lastIdx = this.state.deviceProcess.length - 1;
      let html = "";
      this.state.deviceProcess.map((x, idx) => {
        let useFooter = idx === lastIdx ? true : false;
        let body = genDeviceProcessDetail(x.userList, useFooter);
        html += "<b>" + x.plate_no + "</b>" + "<br/>" + body;
        return;
      });

      Modal.warning({
        title: "กำลังมีการเชื่อมต่อการใช้งานวีดีโอดังนี้",
        content: <div dangerouslySetInnerHTML={{ __html: html }}></div>,
        okText: "ปิด",
      });
    }
  };

  handleResetVehicleList = () => {
    let tempRendorVideoList = [];
    let tempDropdownvideoList = [];
    let tempImeiSelected = [];
    tempRendorVideoList = clearEmptyString(this.state.render_frame).sort();
    tempDropdownvideoList = clearEmptyString(
      this.state.vehicle_select_detail
    ).sort();
    tempImeiSelected = clearEmptyString(this.state.imei_select).sort();
    this.setState({
      imei_select: tempImeiSelected,
      vehicle_select_detail: tempDropdownvideoList,
      render_frame: tempRendorVideoList,
    });
  };

  handleClearVehicleList = () => {
    this.setState({
      isFull: false,
      visible: false,
      imei: "",
      render_frame: [],
      windowNum: 4,
      first_icing_index: [],
      url: process.env.REACT_APP_API_MDVR_TERMINUS,
      iFrameHeight: window.innerHeight - 150,
      deviceProcess: null,
      ...this.state.filter,
    });
  };
  hadleSelectAllIsingCamera = (camera, vehicleId, hwid) => {
    let updateSelectCamera = {
      imei: hwid,
      render_frame: clearEmptyString(this.state.render_frame),
      vehicle_select_detail: clearEmptyString(this.state.vehicle_select_detail),
      imei_select: clearEmptyString(this.state.imei_select),
      first_icing_index: [],
    };
    let len = this.state.windowNum;
    if (this.state.windowNum < 9) {
      len = 4;
      this.handleClearVehicleList();
      updateSelectCamera = {
        imei: "",
        render_frame: [],
        vehicle_select_detail: [],
        imei_select: [],
        first_icing_index: [],
      };
    }
    updateSelectCamera.imei = hwid;
    for (let index = 1; index <= camera.length; index++) {
      let check_in_array = !updateSelectCamera.render_frame.includes(
        hwid + "_" + String(index)
      );
      let check_length = updateSelectCamera.render_frame.length < len;
      if (check_in_array && check_length) {
        updateSelectCamera.render_frame.push(hwid + "_" + String(index));
        updateSelectCamera.imei_select.push(hwid);
      }
    }

    updateSelectCamera.render_frame.map((x) => {
      let arr = x.split("_");
      if (
        arr.length > 1 &&
        typeof updateSelectCamera.first_icing_index.find(
          (x) => x.imei === arr[0]
        ) === "undefined"
      ) {
        updateSelectCamera.first_icing_index.push({
          imei: arr[0],
          camera_idx: arr[1],
        });
      }
      return;
    });
    updateSelectCamera.vehicle_select_detail = this.state.vehicle_select_detail.filter(
      (x) => {
        return updateSelectCamera.imei_select.includes(x.hardware_id);
      }
    );

    // updateSelectCamera.vehicle_select_detail = this.customSort(
    //   updateSelectCamera.imei_select,
    //   vehicle_select_detail
    // );
    this.setState(
      {
        ...updateSelectCamera,
      },
      this.handleCheckProcess
    );
  };
  componentDidMount() {
    this.interval = setInterval(() => {
      this.handleCheckProcess();
    }, 1000 * 15);

    let url = new URL(window.location.href);
    let imei = url.searchParams.get("imei");
    let vhID = url.searchParams.get("vehicle_id");
    if (imei === null) {
      return;
    }

    this.handleOk(
      {
        list_vehicle_id: [parseInt(vhID)],
        orderBy: "plate_no",
        orderType: "asc",
        vehicle_status: [
          "running",
          "stop-with-engine-on",
          "stop-with-engine-off",
          "connection-error",
        ],
        device_type: ["J40", "HNT", "ISU"],
      },
      true
    );

    this.loadVehicleSelect(imei);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  videoFrame = (imei, idx, loading) => {
    if (typeof imei === "undefined" || imei === "") {
      return this.emptyFrame(idx);
    }
    let arr = imei.split("_");
    let pureImei = arr[0];
    let data = this.getDeviceData(pureImei);
    let endpoint = data.target_link;
    let uid = this.props.profile.id.toString();
    let plate_no = data.plate_no;

    let url = `${this.state.url}${endpoint}?imei=${pureImei}&plate_no=${plate_no}&user_id=${uid}&frame_idx=${idx}`;
    if (data.device_type == 40) {
      let cmIdx = typeof arr[1] === "undefined" ? "0" : arr[1];

      let cameraName = "ch0";
      let c = data.camera_channel.find((x) => x.index == cmIdx);
      if (typeof c !== "undefined") {
        cameraName = c.name;
      }

      let firstRec = this.state.first_icing_index.find(
        (x) => x.imei === pureImei
      );
      url = `${this.state.url}${endpoint}?imei=${arr[0]}&plate_no=${plate_no}&camera_btn=hide&camera_idx=${cmIdx}&camera_name=${cameraName}`;
      if (typeof firstRec !== "undefined") {
        // ถ้ามี params user_id จะส่งไปสร้าง log กรณี icing จะส่งไปสร้าง log แค่กล้องเดียวเท่านั้น
        if (parseInt(firstRec.camera_idx) === parseInt(cmIdx)) {
          url += `&user_id=${uid}`;
        }
      }
    }
    return this.iFrame(url, idx);
  };

  iFrame = (url, idx) => {
    let height = this.state.iFrameHeight / Math.sqrt(this.state.windowNum);
    return (
      <Iframe url={url} width="100%" height={height + "px"} scrolling="no" />
    );
  };

  emptyFrame = (idx) => {
    let endpoint = this.getDeviceData("");
    let height = this.state.iFrameHeight / Math.sqrt(this.state.windowNum);

    return (
      <Iframe
        url={
          this.state.url +
          endpoint.target_link +
          "?imei=&plate_no=&user_id=&frame_idx="
        }
        width="100%"
        height={height + "px"}
      />
    );
  };

  getFrameComponent = () => {
    let vehList = this.state.render_frame;
    let iframe = [];
    let i;

    for (let index = 0; index < this.state.windowNum; index++) {
      if (vehList[index]) {
        iframe.push(
          <Col span={24 / Math.sqrt(this.state.windowNum)}>
            {this.videoFrame(vehList[index], index)}
          </Col>
        );
      } else {
        iframe.push(
          <Col span={24 / Math.sqrt(this.state.windowNum)}>
            {this.emptyFrame()}
          </Col>
        );
      }
    }
    return iframe;
  };
  generateSelectWindowNumOption = () => {
    let windowList = [1, 4, 9, 16];
    let option = windowList.map((x) => {
      return (
        <Select.Option key={x} value={x}>
          {x}
        </Select.Option>
      );
    });
    return option;
  };
  render() {
    const {
      vehicleLists,
      vehicleListHoneytoast,
      videoLoading,
    } = this.props.honeytoaststream;

    let iframe = this.getFrameComponent();
    let pureFrame = this.state.render_frame.filter((x) => x !== "");

    return (
      <AuthorizeComponent {...this.props} matching_name="honeytoast_livestream">
        <GeneralStyledContent>
          <div>
            <Row style={{ margin: "0px 0px 5px 0px" }}>
              <Col span={12}>
                <h4>
                  <LangContext.Consumer>
                    {(i18n) => i18n.l.liveStream}
                  </LangContext.Consumer>
                </h4>
              </Col>
            </Row>
            <Row style={{ margin: "0px 0px 5px 0px" }}>
              <Col span={6}>
                <Button icon="filter" size="small" onClick={this.showModal}>
                  <LangContext.Consumer>
                    {(i18n) => i18n.f.filter}
                  </LangContext.Consumer>
                </Button>
              </Col>
              <Col span={18}>
                <Row
                  gutter={[12, 12]}
                  justify="space-between"
                  type="flex"
                  align="middle"
                >
                  <Col
                    span={17}
                    style={{
                      display: "flex",
                      // width: "100%",
                      overflowX: "auto",
                    }}
                  >
                    <VehicleDropdown
                      vehicles={this.state.vehicle_select_detail}
                      renderFrame={pureFrame}
                      remainFrame={this.state.windowNum - pureFrame.length}
                      handleSelectCamera={this.loadVehicleSelect}
                      windowNum={this.state.windowNum}
                      hadleSelectAllIsingCamera={this.hadleSelectAllIsingCamera}
                    />
                  </Col>

                  <Col
                    span={7}
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <Button
                      className="blinking"
                      type="danger"
                      disabled={this.state.deviceProcess === null}
                      icon="eye"
                      size="small"
                      onClick={this.handleOpenDeviceProcessModal}
                    />
                    <Button
                      icon="reload"
                      size="small"
                      style={{ marginLeft: "5px" }}
                      onClick={this.handleResetVehicleList}
                    />

                    <Select
                      size="small"
                      style={{ marginLeft: "5px", width: "50px" }}
                      value={this.state.windowNum}
                      onChange={(num, evt) => this.setWindowNum(num, evt)}
                    >
                      {this.generateSelectWindowNumOption()}
                    </Select>
                    <Button
                      icon="fullscreen"
                      size="small"
                      onClick={this.goFull}
                      style={{ marginLeft: "5px" }}
                    >
                      เต็ม
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={4}>
              <Col
                span={6}
                style={{
                  margin: "0px 0px 0px 0px",
                  maxHeight: `${window.innerHeight - 150}px`,
                  overflowY: "auto",
                }}
              >
                <Row>
                  <Col span={24}>
                    <ListVehicle
                      loadListVehicleHoneytoast={this.loadListVehicleHoneytoast}
                      vehicleListHoneytoast={vehicleListHoneytoast}
                      videoLoading={videoLoading}
                      loadVehicleSelect={this.loadVehicleSelect}
                      vehicle_select={this.state.imei_select}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={18}>
                <Fullscreen
                  enabled={this.state.isFull}
                  onChange={(isFull) => this.onchangeFullscreen(isFull)}
                >
                  <Row>{iframe}</Row>
                </Fullscreen>
              </Col>
            </Row>
          </div>
          <Filter
            visible={this.state.visible}
            onOk={this.handleOk}
            loadVehicle={this.loadVehicle}
            vehicleLists={vehicleLists}
            onCancel={this.handleCancel}
            filter={this.state.filter}
          />
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

const mapStateToProps = ({ honeytoaststream, auth: { profile } }) => ({
  honeytoaststream,
  profile,
});

const mapDispatchToProps = {
  loadListVehicleHoneytoast: actions.loadListVehicleHoneytoast.request,
  loadVehicle: actions.loadVehicle.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
