import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link, withRouter } from 'react-router-dom';
import LangContext, { i18n } from 'modules/shared/context/langContext';
import { CustomMap } from './CustomMap';
import { TrackingStyledContent } from '../../../../styled/common-styled';
import { Row, Col, Menu, Spin, Dropdown, Button, Icon, Tooltip } from 'antd';

const ButtonGroup = Button.Group;

export class TrackingTrips extends Component {
	constructor(props) {
		super(props);
		this.state = {
			trips_id: '',
			mapOptions: {
				center: {
					lat: 13.729852,
					lng: 100.559484,
				},
				zoom: 5, //14
			},
			map: null,
			maps: null,
			mapAPI: { trafficLayer: null, locationLayer: null },
			loading: false,
			isMenuMapTypeClick: '',
			isShowTrafficClick: false,
			showTrafficLayer: false,
		};
	}

	componentDidMount() {
		const trips_id = this.props.match.params.id;
		this.setState({ trips_id: trips_id });
	}

	componentWillUnmount() {
		this.onRemoveLists();
	}

	onRemoveLists = () => {
		// this.props.removeLists();
	};

	// Handle Map
	handleApiLoaded = (map, maps) => {
		if (typeof maps == 'undefined' || maps == null) window.location.reload();

		this.setState({
			map: map,
			maps: maps,
			mapAPI: { ...this.state.mapAPI, trafficLayer: new maps.TrafficLayer() },
		});
	};

	handleMapChange = ({ center, zoom, bounds }) => {
		this.setState({
			mapOptions: {
				center,
				zoom,
				bounds,
			},
		});
	};

	handleMapTypeSatellite = (e) => {
		this.setState({
			isMenuMapTypeClick: 'satellite',
		});
		this.state.map.setMapTypeId('satellite');
	};

	handleMapTypeTerrain = (e) => {
		this.setState({
			isMenuMapTypeClick: 'terrain',
		});
		this.state.map.setMapTypeId('terrain');
	};

	handleShowTrafficLayer = (e) => {
		this.setState({
			isShowTrafficClick: !this.state.isShowTrafficClick,
		});
		if (this.state.showTrafficLayer) {
			this.state.mapAPI.trafficLayer.setMap(null);
			this.setState({
				showTrafficLayer: false,
			});
		} else {
			this.state.mapAPI.trafficLayer.setMap(this.state.map);
			this.setState({
				showTrafficLayer: true,
			});
		}
	};

	handleZoomFocusIncrease = (e) => {
		let zoomLevel = this.state.map.getZoom();
		zoomLevel++;
		this.state.map && this.state.map.setZoom(zoomLevel);
	};

	handleZoomFocusDecrease = (e) => {
		let zoomLevel = this.state.map.getZoom();
		zoomLevel--;
		this.state.map && this.state.map.setZoom(zoomLevel);
	};

	render() {
		const mapType = (
			<Menu>
				<Menu.Item
					data-cy="btn-toggle-map-satellite"
					key="1"
					onClick={this.handleMapTypeSatellite}
					style={{
						color: this.state.isMenuMapTypeClick === 'satellite' ? 'rgb(22, 146, 255)' : '',
					}}
				>
					<LangContext.Consumer>{(i18n) => i18n.s.satellite}</LangContext.Consumer>
				</Menu.Item>
				<Menu.Item
					data-cy="btn-toggle-map-terrain"
					key="2"
					onClick={this.handleMapTypeTerrain}
					style={{
						color: this.state.isMenuMapTypeClick === 'terrain' ? 'rgb(22, 146, 255)' : '',
					}}
				>
					<LangContext.Consumer>{(i18n) => i18n.m.map}</LangContext.Consumer>
				</Menu.Item>
			</Menu>
		);

		return (
			<TrackingStyledContent>
				<Spin spinning={this.state.loading} size="large">
					<Row gutter={24}>
						<Col span={24}>
							<div
								style={{
									position: 'absolute',
									marginTop: '4px',
									top: '0px',
									right: '1px',
									zIndex: '12',
								}}
							>
								<ButtonGroup
									style={{
										marginLeft: '4px',
										marginRight: '0px',
									}}
								>
									<Dropdown className="edge-element" overlay={mapType}>
										<Button>
											<LangContext.Consumer>{(i18n) => i18n.m.mapView}</LangContext.Consumer>
											<Icon type="down" />
										</Button>
									</Dropdown>
								</ButtonGroup>
								<div
									className="edge-btn-group"
									style={{
										position: 'absolute',
										marginTop: '20%',
										right: '1px',
										zIndex: '12',
									}}
								>
									<Tooltip
										placement="left"
										title={
											<LangContext.Consumer>{(i18n) => i18n.s.showTraffic}</LangContext.Consumer>
										}
									>
										<button
											data-cy="btn-toggle-map-show-traffic"
											onClick={this.handleShowTrafficLayer}
											style={{
												backgroundColor: this.state.isShowTrafficClick
													? 'rgb(22, 146, 255)'
													: '',
												color: this.state.isShowTrafficClick ? 'rgb(255, 255, 255)' : '',
											}}
										>
											<Icon type="swap" />
										</button>
									</Tooltip>
									<Tooltip
										placement="left"
										title={<LangContext.Consumer>{(i18n) => i18n.z.zoomIn}</LangContext.Consumer>}
									>
										<button data-cy="btn-toggle-map-zoom-in" onClick={this.handleZoomFocusIncrease}>
											<Icon type="zoom-in" />
										</button>
									</Tooltip>
									<Tooltip
										placement="left"
										title={<LangContext.Consumer>{(i18n) => i18n.z.zoomOut}</LangContext.Consumer>}
									>
										<button
											data-cy="btn-toggle-map-zoom-out"
											onClick={this.handleZoomFocusDecrease}
										>
											<Icon type="zoom-out" />
										</button>
									</Tooltip>
								</div>
							</div>

							<CustomMap
								data-cy="map"
								loadedDateTime={this.props.monitoringState.loadedDateTime}
								bootstrapURLKeys={{
									// key: this.props.auth.profile.google_map_key,
									key: '',
									libraries: process.env.REACT_APP_GOOGLE_MAP_USING_LIBRARIES.split(','),
									region: 'thailand',
									language: 'th',
								}}
								defaultCenter={this.state.mapOptions.center}
								defaultZoom={this.state.mapOptions.zoom}
								style={{
									width: '100%',
									minHeight: `${window.innerHeight - 55}px`,
								}}
								yesIWantToUseGoogleMapApiInternals
								onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
								options={{
									mapTypeControl: false,
									fullscreenControl: false,
									zoomControl: false,
									maxZoom: 19,
								}}
								onChange={this.handleMapChange}
							/>
						</Col>
					</Row>
				</Spin>
			</TrackingStyledContent>
		);
	}
}
export default withRouter(
	connect(
		({ monitoringState, auth, auth: { profile } }) => ({
			monitoringState,
			auth,
			profile,
		}),
		{
			// loadDashcamera: loadDashcamera.request,
			// removeLists,
			// loadEmergencyCase: loadEmergencyCase.request,
		}
	)(TrackingTrips)
);
