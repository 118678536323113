import {
    LOAD_GALLERY, 
    CREATE_IMAGE,
    LOAD_GALLERY_DETAIL,
    UPDATE_GALLERY_DETAIL,
    REMOVE_LISTS
} from "./actions";

const intialState = {
    lists: [],
    listsDetail: [],
    total: 1,
    loading: false,
    check: true,
};

export default (state = intialState, action) => {
    switch (action.type) {
        case REMOVE_LISTS:
            return intialState;

        case LOAD_GALLERY.REQUEST:
            const { loading } = action.payload.data;
            return {
                ...state,
                loading: loading,
            }

        case LOAD_GALLERY.SUCCESS:
            const { page } = action.payload

            for (let i = 0; i < action.payload.lists.data.length; i++) {
                action.payload.lists.data[i].rowNumber = i + 1;
                if (page > 1)
                  action.payload.lists.data[i].rowNumber =
                    action.payload.lists.data[i].rowNumber + (page - 1) * 10;
            }

            return {
                ...state,
                lists: action.payload.lists.data,
                total: 
                    action.payload.lists.total - 1 <= 0
                    ? 1
                    : action.payload.lists.total <= 0
                    ? 1
                    : action.payload.lists.total,
                    loading: false,
                draw: Math.floor(Math.random() * 999 + 1)
            };

        case CREATE_IMAGE.SUCCESS:
            return {
                ...state,
                loading: false
            };

        case LOAD_GALLERY_DETAIL.REQUEST:
            return {
                ...state,
                loading: action.payload.data.loading,
                check: true,
            }

        case LOAD_GALLERY_DETAIL.SUCCESS:
            return {
                ...state,
                listsDetail: action.payload.lists.data,
                check: false,
            };

        case UPDATE_GALLERY_DETAIL.SUCCESS:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}