import React, { Component } from "react";

import {
  Badge,
  Button,
  Col,
  Icon,
  message,
  Pagination,
  Popconfirm,
  Row,
  Spin,
  Table,
  Tooltip,
} from "antd";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import { Link } from "react-router-dom";
import LangContext from "modules/shared/context/langContext";
import * as actions from "../actions";
import * as manageotpActions from "../../manage-otp/actions";
import { connect } from "react-redux";
import Filter from "./Filter";
import FormDivider from "../../../shared/components/FormDivider";
import Forms from "./Forms";
const initialValues = {
  display_name: "",
  companies_id: 0,
  camera_layout_type_id: 1,
  user_id: [],
  group_list: [{ group_name: "", vehicle_list: [] }],
  all_vehicle_list: [],
};
class Edit extends Component {
  componentDidMount() {
    // console.log("this.props.params.id", this.props.match.params.id);
    this.props.load({
      display_id: this.props.match.params.id,
      user_id: this.props.profile.id,
    });
    this.props.loadVehicles(this.props.profile.company_id);
    this.props.loadUser(this.props.profile.company_id);
    this.props.loadLayout();
  }
  componentDidUpdate(prev, current) {
    if (prev.match.params.id != this.props.match.params.id) {
      this.props.load({
        display_id: this.props.match.params.id,
        user_id: this.props.profile.id,
      });
      this.props.loadVehicles(this.props.profile.company_id);
      this.props.loadUser(this.props.profile.company_id);
      this.props.loadLayout();
    }
  }
  handleOnSubmit = (value) => {
    this.props.update(
      {
        ...value,
        companies_id: this.props.profile.company_id,
        own_user_id: this.props.profile.id,
      },
      this.generateMsg
    );
  };
  generateMsg = () => {
    switch (this.props.managedashboard.status) {
      case "success":
        message.success(this.props.managedashboard.msg);
        break;
      case "fail":
        message.error(this.props.managedashboard.msg);
        break;
      default:
        break;
    }
  };
  render() {
    return (
      <AuthorizeComponent matching_name="manage-dashboard-monitoring">
        <Spin spinning={this.props.managedashboard.loading}>
          <GeneralStyledContent>
            <Row gutter={24}>
              <Col span={24}>
                <Breadcrumb
                  customeurl={["/manage-dashboard-monitoring", null, null]}
                  match={this.props.match}
                  style={{ margin: "0px 0px 14px 10px" }}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Forms
                  initialValues={this.props.managedashboard.displayDetail}
                  userList={this.props.managedashboard.userList}
                  vehicleList={this.props.manageotp.vehicleLists}
                  layoutList={this.props.managedashboard.layout}
                  handleOnSubmit={this.handleOnSubmit}
                  duplicateVehicle={
                    this.props.managedashboard.duplicateVehicle
                      .vehicle_all_display
                  }
                />
              </Col>
            </Row>
            <Row type="flex" justify="end" style={{ padding: "15px" }}>
              <Col pan={24} align="right"></Col>
            </Row>
          </GeneralStyledContent>
        </Spin>
      </AuthorizeComponent>
    );
  }
}

const mapStateToProps = ({
  auth,
  auth: { profile },
  managedashboard,
  manageotp,
}) => ({
  auth,
  profile,
  managedashboard,
  manageotp,
});

const mapDispatchToProps = {
  load: actions.loadDisplayDetail.request,
  loadVehicles: manageotpActions.loadAllVehicle.request,
  loadUser: actions.loadManageDashboardUser.request,
  loadLayout: actions.loadLayoutDisplay.request,
  update: actions.updateManageDashboard.request,
};
export default connect(mapStateToProps, mapDispatchToProps)(Edit);
