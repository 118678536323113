import { createTypes, createAction } from "lib/action";

const LOAD_DOZEOFFRISK = createTypes("dozeoffrisk", "load");
const LOAD_VEHICLE_DOZEOFFRISK = createTypes(
  "vehicle_dozeoffrisk",
  "load"
);
const LOAD_VEHICLE_TYPE_DOZEOFFRISK = createTypes("vehicletype_dozeoffrisk", "load");

const loadDozeoffrisk = {
  request: (data, loading) =>
    createAction(LOAD_DOZEOFFRISK.REQUEST, { data, loading }),
  success: data => createAction(LOAD_DOZEOFFRISK.SUCCESS, { data }),
  failure: () => createAction(LOAD_DOZEOFFRISK.FAILURE)
};

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_DOZEOFFRISK.REQUEST, {
      vehicletypeID,
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_VEHICLE_DOZEOFFRISK.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_DOZEOFFRISK.FAILURE)
};

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_DOZEOFFRISK.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_TYPE_DOZEOFFRISK.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_DOZEOFFRISK.FAILURE)
};

export {
  loadDozeoffrisk,
  LOAD_DOZEOFFRISK,
  loadVehicle,
  LOAD_VEHICLE_DOZEOFFRISK,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_DOZEOFFRISK
};
