import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";

import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  message,
  Spin,
  DatePicker,
  Transfer,
  Checkbox,
  Dropdown,
  Menu,
  Icon
} from "antd";

import LangContext, { i18n } from "modules/shared/context/langContext";

const { RangePicker } = DatePicker;
const FormItem = Form.Item;
const Option = Select.Option;

export default class Forms extends Component {
  state = {
    event_date_start: "",
    event_date_end: "",
    vehicleList: [],
    vehicleSelect: [],
    selectedKeys: [],
    driverList: [],
    driverSelect: [],
    filter_by: "vehicle"
  };

  componentDidMount() {
    const { loadVehicle, loadVehicleType, loadDriver } = this.props;

    loadDriver();
    loadVehicle("", false);
    loadVehicleType();
  }

  onVehicleTypeChange = (value, setFieldValue) => {
    const { loadVehicle } = this.props;
    setFieldValue("vehicle_type_id", value);
    loadVehicle(value, true);
    this.setState({ vehicleSelect: [] });
    this.setState({ selectedKeys: [] });
    setFieldValue("list_vehicle_id", []);
  };

  componentWillReceiveProps = nextProps => {
    const { vehicleLists, driverLists } = nextProps;
    const vehlist = [];
    vehicleLists.map(item =>
      vehlist.push({
        key: item.id,
        name: item.plate_no
      })
    );

    const drilist = [];
    driverLists.map(item =>
      drilist.push({
        key: item.id,
        name: item.firstname + " " + item.lastname
      })
    );

    this.setState({ vehicleList: vehlist, driverList: drilist });
  };

  tranferChange = (targetKeys, setFieldValue) => {
    setFieldValue("list_vehicle_id", targetKeys);
    this.setState({ vehicleSelect: targetKeys });
  };

  tranferDriverChange = (targetKeys, setFieldValue) => {
    setFieldValue("list_driver_id", targetKeys);
    this.setState({ driverSelect: targetKeys });
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys]
    });
  };

  changeFilter = (value, setFieldValue) => {
    setFieldValue("filter_by", value);
    this.setState({
      filter_by: value
    });
  }

  handleMenuClick = (e, setFieldValue, handleSubmit) => {
    if (e.key == "excel") {
      setFieldValue("type_file", "excel", false);
    } else if (e.key == "pdf") {
      setFieldValue("type_file", "pdf", false);
    }
    handleSubmit();
  };

  render() {
    const {
      vehicleLists,
      vehicleTypeLists,
      drivinglogLoading,
      onSubmit
    } = this.props;

    return (
      <div>
        <h3><LangContext.Consumer>
          {i18n => i18n.d.DrivinglogspecialReport}
        </LangContext.Consumer></h3>
        <Spin spinning={drivinglogLoading}>
          <Formik
            initialValues={{
              vehicle_type_id: "",
              vehicle_status: "all",
              eventdate: [moment("00:00", "HH:mm"), moment("23:59", "HH:mm")],
              list_vehicle_id: [],
              list_driver_id: [],
              group_data: "",
              filter_by: "vehicle"
            }}
            validate={values => {
              let errors = {};
              if (values.eventdate.length > 0) {
                const date_start = moment(values.eventdate[0]["_d"]);
                const date_end = moment(values.eventdate[1]["_d"]);
                let day_diff = date_end.diff(date_start, "days") + 1;
                if (day_diff > 7) {
                  errors.eventdate = "ช่วงวันที่ที่เลือกต้องไม่เกิน 7 วัน";
                }
              }

              if (values.filter_by === "vehicle") {
                if (values.list_vehicle_id.length === 0) {
                  errors.list_vehicle_id = "กรุณาเลือกพาหนะ";
                }

              } else {
                if (values.list_driver_id.length === 0) {
                  errors.list_driver_id = "กรุณาเลือกพนักงานขับขี่";
                }

              }

              return errors;
            }}
            validationSchema={yup.object().shape({
              eventdate: yup.array().required("กรุณาเลือกช่วงวันที่"),
              // list_vehicle_id: yup.array().required("กรุณาเลือกรถ")
            })}
            onSubmit={values => {
              console.log(values);
              const loading = true;
              onSubmit(values, loading);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleFocus,
              setFieldValue,
              isValidating
            }) => (
                <Row type="flex" justify="center">
                  <Form onSubmit={handleSubmit}>

                    <FormItem
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.f.filter_by}
                        </LangContext.Consumer>
                      }
                      validateStatus={
                        touched.filter_by && errors.filter_by && "warning"
                      }
                      help={touched.filter_by && errors.filter_by}
                    >
                      <Select
                        onChange={value =>
                          this.changeFilter(value, setFieldValue)
                        }

                        value={values.filter_by}
                      >
                        <Option value="vehicle"><LangContext.Consumer>{i18n => i18n.v.vehicle}</LangContext.Consumer></Option>
                        <Option value="driver"><LangContext.Consumer>{i18n => i18n.c.chauffeur}</LangContext.Consumer></Option>

                      </Select>
                    </FormItem>
                    {this.state.filter_by === "driver" ?
                      <FormItem
                        required={true}
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.c.chauffeur}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.list_driver_id && errors.list_driver_id && "warning"
                        }
                        help={touched.list_driver_id && errors.list_driver_id}
                      >
                        <Transfer titles={[
                          <LangContext.Consumer>
                            {i18n => i18n.i.itemLeft}
                          </LangContext.Consumer>,
                          <LangContext.Consumer>
                            {i18n => i18n.i.itemSelected}
                          </LangContext.Consumer>
                        ]}
                          dataSource={this.state.driverList}
                          showSearch
                          listStyle={{
                            width: 250,
                            height: 300
                          }}
                          operations={[">>", "<<"]}
                          targetKeys={this.state.driverSelect}
                          onChange={value =>
                            this.tranferDriverChange(value, setFieldValue)
                          }
                          //onChange={this.tranferChange}
                          render={item => `${item.name}`}
                          footer={this.renderFooter}
                        />
                      </FormItem> : ""
                    }

                    {this.state.filter_by === "vehicle" ?
                      <div>
                        <FormItem
                          label={
                            <LangContext.Consumer>
                              {i18n => i18n.v.vehicleGroup}
                            </LangContext.Consumer>
                          }
                          validateStatus={
                            touched.vehicle_type_id &&
                            errors.vehicle_type_id &&
                            "warning"
                          }
                          help={touched.vehicle_type_id && errors.vehicle_type_id}
                        >
                          <Select
                            //onChange={value => setFieldValue("vehicle_type_id", value)}
                            onChange={value =>
                              this.onVehicleTypeChange(value, setFieldValue)
                            }
                            value={values.vehicle_type_id}
                          >
                            <Option value=""> <LangContext.Consumer>
                              {i18n => i18n.a.all}
                            </LangContext.Consumer></Option>
                            {vehicleTypeLists.map(item => (
                              <Option key={item.id} value={item.id}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </FormItem>
                        <FormItem
                          label={
                            <LangContext.Consumer>
                              {i18n => i18n.v.vehicle}
                            </LangContext.Consumer>
                          }
                          validateStatus={
                            touched.list_vehicle_id &&
                            errors.list_vehicle_id &&
                            "warning"
                          }
                          help={touched.list_vehicle_id && errors.list_vehicle_id}
                          required={true}
                        >
                          <Transfer titles={[
                            <LangContext.Consumer>
                              {i18n => i18n.i.itemLeft}
                            </LangContext.Consumer>,
                            <LangContext.Consumer>
                              {i18n => i18n.i.itemSelected}
                            </LangContext.Consumer>
                          ]}
                            dataSource={this.state.vehicleList}
                            showSearch
                            listStyle={{
                              width: 250,
                              height: 300
                            }}
                            selectedKeys={this.state.selectedKeys}
                            operations={[">>", "<<"]}
                            targetKeys={this.state.vehicleSelect}
                            onSelectChange={this.handleSelectChange}
                            onChange={value =>
                              this.tranferChange(value, setFieldValue)
                            }
                            //onChange={this.tranferChange}
                            render={item => `${item.name}`}
                            footer={this.renderFooter}
                          />
                        </FormItem>
                      </div> : ""
                    }
                    <FormItem
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.s.status_vehicle}
                        </LangContext.Consumer>
                      }
                      validateStatus={
                        touched.vehicle_status && errors.vehicle_status && "warning"
                      }
                      help={touched.vehicle_status && errors.vehicle_status}
                    >
                      <Select
                        onChange={value => setFieldValue("vehicle_status", value)}
                        value={values.vehicle_status}
                      >
                        <Option value="all"><LangContext.Consumer>{i18n => i18n.a.all}</LangContext.Consumer></Option>
                        <Option value="moving"><LangContext.Consumer>{i18n => i18n.s.status_moving}</LangContext.Consumer></Option>
                        <Option value="parked"><LangContext.Consumer>{i18n => i18n.s.status_parked}</LangContext.Consumer></Option>
                        <Option value="engine off"><LangContext.Consumer>{i18n => i18n.s.status_engineoff}</LangContext.Consumer></Option>
                      </Select>
                    </FormItem>

                    <FormItem
                      // label="สถานที่ที่มีอยู่ในระบบ"
                      validateStatus={
                        touched.group_data &&
                        errors.group_data &&
                        "warning"
                      }
                      help={touched.group_data && errors.group_data}
                    >
                      <Checkbox
                        onChange={value =>
                          setFieldValue("group_data", value)
                        }
                      >
                        <LangContext.Consumer>
                          {i18n => i18n.g.group_data}
                        </LangContext.Consumer>
                      </Checkbox>
                    </FormItem>

                    <Form.Item
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.d.dateRange}
                        </LangContext.Consumer>
                      }
                      required={true}
                      validateStatus={
                        touched.eventdate && errors.eventdate && "warning"
                      }
                      help={touched.eventdate && errors.eventdate}
                    >
                      <RangePicker
                        // showTime={{ format: "HH:mm" }}
                        onChange={value => setFieldValue("eventdate", value)}
                        format="DD/MM/YYYY"
                        placeholder={["Start", "End"]}
                        defaultValue={[
                          moment("00:00", "HH:mm"),
                          moment("23:59", "HH:mm")
                        ]}
                      />
                    </Form.Item>

                    <FormItem>
                      <Row gutter={24}>
                        <Col span={8}>
                          <Button type="defualt" block>
                            <Link to="/">
                              <LangContext.Consumer>
                                {i18n => i18n.c.cancel}
                              </LangContext.Consumer>
                            </Link>
                          </Button>
                        </Col>
                        <Col span={8}>
                          <Dropdown
                              overlay={
                                <Menu
                                  onClick={value =>
                                    this.handleMenuClick(
                                      value,
                                      setFieldValue,
                                      handleSubmit
                                    )
                                  }
                                > 
                                <Menu.Item key="excel">
                                  <Icon type="file-excel" /> <LangContext.Consumer>
                                    {i18n => i18n.e.excel}
                                  </LangContext.Consumer>
                                </Menu.Item>
                                <Menu.Item key="pdf">
                                  <Icon type="file-pdf" /> <LangContext.Consumer>
                                    {i18n => i18n.p.pdf}
                                  </LangContext.Consumer>
                                </Menu.Item>
                              </Menu>
                            }
                          >
                            <Button type="primary" block>
                              <LangContext.Consumer>
                                {i18n => i18n.r.report}
                              </LangContext.Consumer> <Icon type="down" />
                            </Button>
                          </Dropdown>
                        </Col>
                      </Row>
                    </FormItem>
                  </Form>
                </Row>
              )}
          />
        </Spin>
      </div>
    );
  }
}
