import {
  LOAD_COMPANY,
  LOAD_ALL_VEHICLE,
  CREATE_MATCH_PHONENO_WITH_VEHICLE,
  LOAD_PHONENO_WITH_VEHICLE,
  LOAD_PHONENO_WITH_VEHICLE_BY_PHONENO,
  LOAD_COMPANYBYID,
  UPDATE_MATCH_PHONENO_WITH_VEHICLE,
  DELETE_MATCH_PHONENO_WITH_VEHICLE,
} from "./actions";

const initialState = {
  companyLists: [],
  vehicleLists: [],
  phoneWtihVehicleLists: [],
  phoneWtihVehicle: {},
  msg: "",
  status: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_COMPANY.REQUEST:
      return {
        ...state,
        vehicleLists: [],
        companyLists: [],
        loading: true,
      };
    case LOAD_COMPANY.SUCCESS:
      return {
        ...state,
        companyLists: action.payload.data,
        loading: false,
      };
    case LOAD_COMPANYBYID.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_COMPANYBYID.SUCCESS:
      return {
        ...state,
        companyLists: action.payload.data,
        loading: false,
      };
    case LOAD_ALL_VEHICLE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_ALL_VEHICLE.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data,
        loading: false,
      };

    case LOAD_PHONENO_WITH_VEHICLE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_PHONENO_WITH_VEHICLE.SUCCESS:
      return {
        ...state,
        phoneWtihVehicleLists: action.payload.data,
        loading: false,
      };
    case LOAD_PHONENO_WITH_VEHICLE_BY_PHONENO.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_PHONENO_WITH_VEHICLE_BY_PHONENO.SUCCESS:
      return {
        ...state,
        phoneWtihVehicle: action.payload.data,
        loading: false,
      };
    case CREATE_MATCH_PHONENO_WITH_VEHICLE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_MATCH_PHONENO_WITH_VEHICLE.SUCCESS:
      return {
        ...state,
        msg: "Insert Success",
        status: "success",
        loading: false,
      };
    case UPDATE_MATCH_PHONENO_WITH_VEHICLE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_MATCH_PHONENO_WITH_VEHICLE.SUCCESS:
      return {
        ...state,
        msg: "Update Success",
        status: "success",
        loading: false,
      };
    case DELETE_MATCH_PHONENO_WITH_VEHICLE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_MATCH_PHONENO_WITH_VEHICLE.SUCCESS:
      return {
        ...state,
        msg: "Delete Success",
        status: "success",
        loading: false,
      };
    case LOAD_ALL_VEHICLE.FAILURE:
    case LOAD_COMPANY.FAILURE:
    case CREATE_MATCH_PHONENO_WITH_VEHICLE.FAILURE:
    case UPDATE_MATCH_PHONENO_WITH_VEHICLE.FAILURE:
    case DELETE_MATCH_PHONENO_WITH_VEHICLE.FAILURE:
      return {
        ...state,
        msg: action.payload.err,
        loading: false,
        status: "fail",
        shouldReload: false,
      };
    default:
      return state;
  }
};
