import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";

import * as actions from "../actions";
import * as driverinformationActions from "../../driverinformation/actions";
import * as distfromlastmaintenanceActions from "../../distfromlastmaintenance/actions";
import * as eventActions from "../../event/actions";
import Form from "./Forms";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "./../../../shared/components/Breadcrumb";

class Edit extends Component {
  componentDidMount() {
    this.props.getSchedulereport(this.props.match.params.id);
  }

  edit = (values, loading) => {
    this.props.updateSchedulereport(
      values,
      this.props.match.params.id,
      this.props.history,
      loading
    );
  };

  render() {
    return (
      <GeneralStyledContent>
        <Row gutter={24}>
          <Col span={24}>
            <Breadcrumb
              match={this.props.match}
              style={{ margin: "0px 0px 14px 10px" }}
            />
          </Col>
        </Row>
        <Form
          onSubmit={this.edit}
          schedulereport={this.props.schedulereport}
          loadReportSchedulereport={this.props.loadReportSchedulereport}
          id={this.props.match.params.id}
          auth={this.props.auth}
          vehicleLists={this.props.event.vehicleLists}
          driverLists={this.props.driverinformation.driverLists}
          loadDriver={this.props.loadDriver}
          loadPartType={this.props.loadPartType}
          partTypeLists={this.props.distfromlastmaintenance.partTypeLists}
          policyLists={this.props.event.policyLists}
          eventLists={this.props.event.eventLists}
          vehiclebygroupLists={this.props.event.vehiclebygroupLists}
          vehiclebypolicyLists={this.props.event.vehiclebypolicyLists}
          loadPolicy={this.props.loadPolicy}
          loadEvents={this.props.loadEvents}
          loadVehiclebypolicy={this.props.loadVehiclebypolicy}
          loadVehiclebygroupid={this.props.loadVehiclebygroupid}
          loadVehicle={this.props.loadVehicle}
          eventLoading={this.props.event.eventLoading}
        />
      </GeneralStyledContent>
    );
  }
}

const mapStateToProps = ({
  schedulereport,
  auth,
  driverinformation,
  distfromlastmaintenance,
  event,
}) => ({
  schedulereport,
  auth,
  driverinformation,
  distfromlastmaintenance,
  event,
});

const mapDispatchToProps = {
  updateSchedulereport: actions.updateSchedulereport.request,
  getSchedulereport: actions.getSchedulereportByID.request,
  loadReportSchedulereport: actions.loadReportSchedulereport.request,
  loadDriver: driverinformationActions.loadDriver.request,
  loadPartType: distfromlastmaintenanceActions.loadPartType.request,
  loadPolicy: eventActions.loadPolicy.request,
  loadEvents: eventActions.loadEvents.request,
  loadVehiclebypolicy: eventActions.loadVehiclebypolicy.request,
  loadVehiclebygroupid: eventActions.loadVehiclebygroupid.request,
  loadVehicle: eventActions.loadVehicle.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
