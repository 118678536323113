import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'antd'

import * as actions from '../actions'
import Forms from './Forms'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import Breadcrumb from './../../../shared/components/Breadcrumb'

class Add extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data_for_edit: {
        name: '',
        route_master_type_id: '',
      },
    }
  }

  componentDidMount() {
    this.props.clearRoutemaster()

    // โหลดข้อมูล Route Master Type
    this.props.loadRouteMasterType()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.routemaster.checkRouteMasterType !==
      nextProps.routemaster.checkRouteMasterType
    ) {
      if (nextProps.routemaster.routeMasterType.length > 0) {
        this.setState({
          data_for_edit: {
            ...this.state.data_for_edit,
            route_master_type_id: nextProps.routemaster.routeMasterType[0].id,
          },
        })
      }
    }

    return true
  }

  create = (values, loading) => {
    this.props.createRoutemaster(values, loading, this.props.history)
  }

  componentWillUnmount() {
    this.props.clearRoutemaster()
  }

  render() {
    return (
      <GeneralStyledContent>
        <Row gutter={24}>
          <Col span={24}>
            <Breadcrumb
              match={this.props.match}
              style={{ margin: '0px 0px 14px 10px' }}
            />
          </Col>
        </Row>
        <Forms
          action_type='create'
          onSubmit={this.create}
          routemaster={this.props.routemaster}
          id={''}
          auth={this.props.auth}
          data_for_edit={this.state.data_for_edit}
        />
      </GeneralStyledContent>
    )
  }
}

const mapStateToProps = ({ routemaster, auth }) => ({
  routemaster,
  auth,
})

const mapDispatchToProps = {
  createRoutemaster: actions.createRoutemaster.request,
  clearRoutemaster: actions.clearRoutemaster,
  loadRouteMasterType: actions.loadRouteMasterType.request,
}

export default connect(mapStateToProps, mapDispatchToProps)(Add)
