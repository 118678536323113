import { createTypes, createAction } from "lib/action";

const LOAD_COMPANY = createTypes("load_company", "load");
const LOAD_COMPANYBYID = createTypes(
  "load_company_by_id",
  "load"
);

const LOAD_ALL_VEHICLE = createTypes(
  "load_vehicle",
  "load"
);

const LOAD_PHONENO_WITH_VEHICLE = createTypes(
  "load_matchphonewithvehicle",
  "load"
);

const LOAD_PHONENO_WITH_VEHICLE_BY_PHONENO = createTypes(
  "load_matchphonewithvehicleByPhoneNo",
  "load"
);

const CREATE_MATCH_PHONENO_WITH_VEHICLE = createTypes(
  "matchphonewithvehicle",
  "create"
);

const UPDATE_MATCH_PHONENO_WITH_VEHICLE = createTypes(
  "matchphonewithvehicle",
  "update"
);

const DELETE_MATCH_PHONENO_WITH_VEHICLE = createTypes(
  "matchphonewithvehicle",
  "delete"
);

const loadCompany = {
  request: (text) =>
    createAction(LOAD_COMPANY.REQUEST, { text }),
  success: (data) =>
    createAction(LOAD_COMPANY.SUCCESS, { data }),
  failure: () => createAction(LOAD_COMPANY.FAILURE),
};

const loadCompanyByID = {
  request: (id) =>
    createAction(LOAD_COMPANYBYID.REQUEST, { id }),
  success: (data) =>
    createAction(LOAD_COMPANYBYID.SUCCESS, { data }),
  failure: () => createAction(LOAD_COMPANYBYID.FAILURE),
};

const loadAllVehicle = {
  request: (company_id) =>
    createAction(LOAD_ALL_VEHICLE.REQUEST, { company_id }),
  success: (data) =>
    createAction(LOAD_ALL_VEHICLE.SUCCESS, { data }),
  failure: () => createAction(LOAD_ALL_VEHICLE.FAILURE),
};

const loadPhoneNoWithVehicle = {
  request: (data) =>
    createAction(LOAD_PHONENO_WITH_VEHICLE.REQUEST, {
      data,
    }),
  success: (data) =>
    createAction(LOAD_PHONENO_WITH_VEHICLE.SUCCESS, {
      data,
    }),
  failure: () =>
    createAction(LOAD_PHONENO_WITH_VEHICLE.FAILURE),
};

const loadPhoneNoWithVehicleByPhoneNo = {
  request: (phone_no, loadSuccess) =>
    createAction(
      LOAD_PHONENO_WITH_VEHICLE_BY_PHONENO.REQUEST,
      {
        phone_no,
        loadSuccess,
      }
    ),
  success: (data) =>
    createAction(
      LOAD_PHONENO_WITH_VEHICLE_BY_PHONENO.SUCCESS,
      {
        data,
      }
    ),
  failure: () =>
    createAction(
      LOAD_PHONENO_WITH_VEHICLE_BY_PHONENO.FAILURE
    ),
};
const createMatchPhoneNoWithVehicle = {
  request: (data, generateMsg) =>
    createAction(
      CREATE_MATCH_PHONENO_WITH_VEHICLE.REQUEST,
      { data, generateMsg }
    ),
  success: (data) =>
    createAction(
      CREATE_MATCH_PHONENO_WITH_VEHICLE.SUCCESS,
      { data }
    ),
  failure: (err) =>
    createAction(
      CREATE_MATCH_PHONENO_WITH_VEHICLE.FAILURE,
      { err }
    ),
};

const updateMatchPhoneNoWithVehicle = {
  request: (data, generateMsg) =>
    createAction(
      UPDATE_MATCH_PHONENO_WITH_VEHICLE.REQUEST,
      { data, generateMsg }
    ),
  success: (data) =>
    createAction(
      UPDATE_MATCH_PHONENO_WITH_VEHICLE.SUCCESS,
      { data }
    ),
  failure: (err) =>
    createAction(
      UPDATE_MATCH_PHONENO_WITH_VEHICLE.FAILURE,
      { err }
    ),
};
const deleteMatchPhoneNoWithVehicle = {
  request: (data, generateMsg) =>
    createAction(
      DELETE_MATCH_PHONENO_WITH_VEHICLE.REQUEST,
      { data, generateMsg }
    ),
  success: (data) =>
    createAction(
      DELETE_MATCH_PHONENO_WITH_VEHICLE.SUCCESS,
      { data }
    ),
  failure: (err) =>
    createAction(
      DELETE_MATCH_PHONENO_WITH_VEHICLE.FAILURE,
      { err }
    ),
};

export {
  loadCompany,
  LOAD_COMPANY,
  loadAllVehicle,
  LOAD_ALL_VEHICLE,
  loadPhoneNoWithVehicle,
  LOAD_PHONENO_WITH_VEHICLE,
  loadPhoneNoWithVehicleByPhoneNo,
  LOAD_PHONENO_WITH_VEHICLE_BY_PHONENO,
  loadCompanyByID,
  LOAD_COMPANYBYID,
  createMatchPhoneNoWithVehicle,
  CREATE_MATCH_PHONENO_WITH_VEHICLE,
  updateMatchPhoneNoWithVehicle,
  UPDATE_MATCH_PHONENO_WITH_VEHICLE,
  deleteMatchPhoneNoWithVehicle,
  DELETE_MATCH_PHONENO_WITH_VEHICLE,
};
