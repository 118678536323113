import {
    LOAD_AUTOCOMPLETE,
    SELECTED_AUTOCOMPLETE,
    REMOVE_LISTS
} from "./actions";

const initialState = {
    autoComplete: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REMOVE_LISTS:
            return initialState;

        case LOAD_AUTOCOMPLETE.SUCCESS:
            return {
                ...state,
                autoComplete: action.payload
            };

        case SELECTED_AUTOCOMPLETE:
                const { id, lists, selectedName, cb } = action.payload;
    
                let selected = lists.filter(function(ele) {
                    return ele.id == id;
                })[0];
                cb(selected);
                return { ...state, [selectedName]: selected, autoComplete: [] };
    
        default:
            return state;
    }
}