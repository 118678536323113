import { createTypes, createAction } from "lib/action";

const EXPORT_PERFORMANCELOG = createTypes("exportperformancelog", "load");

const exportPerformanceLogReport = {
  request: (data, accessToken) =>
    createAction(EXPORT_PERFORMANCELOG.REQUEST, { data, accessToken }),
  success: (data) => createAction(EXPORT_PERFORMANCELOG.SUCCESS, { data }),
  failure: (err) =>
    createAction(EXPORT_PERFORMANCELOG.FAILURE, { errorMsg: err }),
};

export { EXPORT_PERFORMANCELOG, exportPerformanceLogReport };
