import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'

import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  message,
  Spin,
  Alert,
  Checkbox,
} from 'antd'
//import { GeneralStyledContent } from "../../../../styled/common-styled";
import LangContext, { i18n } from 'modules/shared/context/langContext'
import { getlocalstorage } from './../../../../constants/local_storage'
const FormItem = Form.Item
const Option = Select.Option
const { TextArea } = Input
export default class Forms extends Component {
  state = {
    visible_detail_problem: true,
  }

  componentDidMount() {
    this.props.loadVehicle()
    this.props.loadMaintenancetype()
    this.props.loadProvince()
    this.props.loadProblem()
    this.props.loadVehicleData(this.props.vehicle_id)
  }

  onProvinceChange = (value, setFieldValue) => {
    const { loadAmphur } = this.props
    setFieldValue('province', value)
    loadAmphur(value, true)
  }

  onTypeProblemChange = (value, setFieldValue) => {
    setFieldValue('type_problem', value)
    if (value == 6) {
      this.setState({ visible_detail_problem: false })
    } else {
      this.setState({ visible_detail_problem: true })
    }
  }

  handleChangeVehicle = (value) => {
    this.props.loadVehicleData(value)
  }

  onClickLocationOfVehicle = (location_id) => {
    window.open(`/location/detail/${location_id}`, '_blank')
  }

  render() {
    const { servicerepairdevice, onSubmit } = this.props
    const {
      servicerepairdeviceLoading,
      maintenancetypeLists,
      vehicleLists,
      provinceLists,
      amphurLists,
      problemLists,
      vehicle_data,
    } = servicerepairdevice
    const firstname = getlocalstorage(
      localStorage.getItem('profile'),
      'firstname'
    )

    const lastname = getlocalstorage(
      localStorage.getItem('profile'),
      'lastname'
    )

    const phone = getlocalstorage(localStorage.getItem('profile'), 'phone')
    return (
      <div>
        <Spin spinning={servicerepairdeviceLoading}>
          <LangContext.Consumer>
            {(i18n) => (
              <Formik
                //enableReinitialize={true}
                initialValues={{
                  type: 1,
                  vehicles_id: parseInt(this.props.vehicle_id),
                  contact_name: '',
                  contact_telno: '',
                  submit_name: firstname + ' ' + lastname,
                  submit_telno: phone,
                  appointment_place: '',
                  province: '',
                  amphur: '',
                  confirm_appointment_place: '',
                  type_problem: '',
                  comment: '',
                  detail_problem: '',
                }}
                validationSchema={yup.object().shape({
                  contact_name: yup
                    .string()
                    .required('กรุณากรอกชื่อบุคคลติดต่อ'),
                  contact_telno: yup.string().required('กรุณากรอกเบอร์ติดต่อ'),
                  submit_name: yup
                    .string()
                    .required('กรุณากรอกชื่อผู้ยื่นเรื่อง'),
                  submit_telno: yup
                    .string()
                    .required('กรุณากรอกเบอร์ติดต่อผู้ยื่นเรื่อง'),
                  province: yup.string().required('กรุณาเลือกจังหวัด'),
                  amphur: yup.string().required('กรุณาเลือกอำเภอ'),
                  type_problem: yup
                    .string()
                    .required('กรุณาเลือกอาการของปัญหา'),
                })}
                onSubmit={(values, { resetForm }) => {
                  const loading = true
                  onSubmit(values, loading)
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleFocus,
                  setFieldValue,
                  resetForm,
                }) => (
                  <Row type='flex' justify='center'>
                    <Col span={10}>
                      <Form onSubmit={handleSubmit}>
                        <FormItem
                          label={
                            <LangContext.Consumer>
                              {(i18n) => i18n.m.maintenance_type}
                            </LangContext.Consumer>
                          }
                          required={true}
                          validateStatus={
                            touched.type && errors.type && 'error'
                          }
                          help={touched.type && errors.type}
                        >
                          <Select
                            onChange={(value) => setFieldValue('type', value)}
                            value={parseInt(values.type)}
                          >
                            {maintenancetypeLists.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </FormItem>

                        <FormItem
                          label={
                            <LangContext.Consumer>
                              {(i18n) => i18n.v.vehicle}
                            </LangContext.Consumer>
                          }
                          required={true}
                          validateStatus={
                            touched.vehicles_id && errors.vehicles_id && 'error'
                          }
                          help={touched.vehicles_id && errors.vehicles_id}
                        >
                          <Select
                            onChange={(value) => {
                              setFieldValue('vehicles_id', value)

                              this.handleChangeVehicle(value)
                            }}
                            value={values.vehicles_id}
                          >
                            {vehicleLists.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.plate_no}
                              </Option>
                            ))}
                          </Select>
                        </FormItem>

                        <FormItem
                          label={
                            <LangContext.Consumer>
                              {(i18n) => i18n.c.contact_name}
                            </LangContext.Consumer>
                          }
                          required={true}
                          validateStatus={
                            touched.contact_name &&
                            errors.contact_name &&
                            'error'
                          }
                          help={touched.contact_name && errors.contact_name}
                        >
                          <Input
                            name='contact_name'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.contact_name}
                          />
                        </FormItem>

                        <FormItem
                          label={
                            <LangContext.Consumer>
                              {(i18n) => i18n.c.contact_telno}
                            </LangContext.Consumer>
                          }
                          validateStatus={
                            touched.contact_telno &&
                            errors.contact_telno &&
                            'error'
                          }
                          help={ touched.contact_telno && errors.contact_telno }
                          required={true}
                        >
                          <Input
                            name='contact_telno'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.contact_telno}
                          />
                        </FormItem>

                        <FormItem
                          label={
                            <LangContext.Consumer>
                              {(i18n) => i18n.s.submit_name}
                            </LangContext.Consumer>
                          }
                          required={true}
                          validateStatus={
                            touched.submit_name && errors.submit_name && 'error'
                          }
                          help={touched.submit_name && errors.submit_name}
                        >
                          <Input
                            name='submit_name'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.submit_name}
                          />
                        </FormItem>

                        <FormItem
                          label={
                            <LangContext.Consumer>
                              {(i18n) => i18n.s.submit_telno}
                            </LangContext.Consumer>
                          }
                          required={true}
                          validateStatus={
                            touched.submit_telno &&
                            errors.submit_telno &&
                            'error'
                          }
                          help={touched.submit_telno && errors.submit_telno}
                        >
                          <Input
                            name='submit_telno'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.submit_telno}
                          />
                        </FormItem>

                        {/* ข้อมูลของรถ */}
                        {vehicle_data && (
                          <div
                            style={{ marginTop: '20px', marginBottom: '20px' }}
                          >
                            {/* ชื่อสถานที่ต้นสังกัด */}
                            <div>
                              <span
                                style={{ fontWeight: 'bold' }}
                              >{`${i18n.l.LocationOfVehicle} : `}</span>
                              {vehicle_data.primary_locations_id ? (
                                <Button
                                  type='link'
                                  onClick={() => {
                                    if (vehicle_data.primary_locations_id) {
                                      this.onClickLocationOfVehicle(
                                        vehicle_data.primary_locations_id
                                      )
                                    }
                                  }}
                                  style={{ padding: '0px' }}
                                >
                                  <span>{`(${vehicle_data.location_code}) ${vehicle_data.location_name}`}</span>
                                </Button>
                              ) : (
                                <span>{`-`}</span>
                              )}
                            </div>
                            {/* ที่อยู่สถานที่ */}
                            <div>
                              <span
                                style={{ fontWeight: 'bold' }}
                              >{`${i18n.a.address} : `}</span>
                              <span>{`${vehicle_data.address || '-'}`}</span>
                            </div>
                            {/* geo code */}
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {`GEO Code : `}
                              </span>
                              <span>
                                {`${vehicle_data.display_name || '-'}`}
                              </span>
                            </div>
                          </div>
                        )}

                        <FormItem
                          label={
                            <LangContext.Consumer>
                              {(i18n) => i18n.a.appointment_place}
                            </LangContext.Consumer>
                          }
                          validateStatus={
                            touched.appointment_place &&
                            errors.appointment_place &&
                            'error'
                          }
                          help={
                            touched.appointment_place &&
                            errors.appointment_place
                          }
                        >
                          <Input
                            name='appointment_place'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.appointment_place}
                          />
                        </FormItem>

                        <FormItem
                          label={
                            <LangContext.Consumer>
                              {(i18n) => i18n.p.province}
                            </LangContext.Consumer>
                          }
                          required={true}
                          validateStatus={
                            touched.province && errors.province && 'error'
                          }
                          help={touched.province && errors.province}
                        >
                          <Select
                            onChange={(value) =>
                              this.onProvinceChange(value, setFieldValue)
                            }
                            value={values.province}
                          >
                            <Option value=''>
                              <LangContext.Consumer>
                                {(i18n) => i18n.p.pleaseSelect}
                              </LangContext.Consumer>
                            </Option>
                            {provinceLists.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </FormItem>

                        <FormItem
                          label={
                            <LangContext.Consumer>
                              {(i18n) => i18n.a.amphur}
                            </LangContext.Consumer>
                          }
                          required={true}
                          validateStatus={
                            touched.amphur && errors.amphur && 'error'
                          }
                          help={touched.amphur && errors.amphur}
                        >
                          <Select
                            onChange={(value) => setFieldValue('amphur', value)}
                            value={values.amphur}
                          >
                            <Option value=''>
                              <LangContext.Consumer>
                                {(i18n) => i18n.p.pleaseSelect}
                              </LangContext.Consumer>
                            </Option>
                            {amphurLists.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </FormItem>

                        <FormItem
                          validateStatus={
                            touched.confirm_appointment_place &&
                            errors.confirm_appointment_place &&
                            'error'
                          }
                          help={
                            touched.confirm_appointment_place &&
                            errors.confirm_appointment_place
                          }
                        >
                          <Checkbox
                            onChange={(value) =>
                              setFieldValue('confirm_appointment_place', value)
                            }
                          >
                            <LangContext.Consumer>
                              {(i18n) => i18n.c.confirm_appointment_place}
                            </LangContext.Consumer>
                          </Checkbox>
                        </FormItem>

                        <FormItem
                          label={
                            <LangContext.Consumer>
                              {(i18n) => i18n.t.type_problem}
                            </LangContext.Consumer>
                          }
                          required={true}
                          validateStatus={
                            touched.type_problem &&
                            errors.type_problem &&
                            'error'
                          }
                          help={touched.type_problem && errors.type_problem}
                        >
                          <Select
                            onChange={(value) =>
                              this.onTypeProblemChange(value, setFieldValue)
                            }
                            value={values.type_problem}
                          >
                            <Option value=''>
                              <LangContext.Consumer>
                                {(i18n) => i18n.p.pleaseSelect}
                              </LangContext.Consumer>
                            </Option>
                            {problemLists.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </FormItem>
                        {this.state.visible_detail_problem == false ? (
                          <FormItem
                            label={
                              <LangContext.Consumer>
                                {(i18n) => i18n.d.detail_problem}
                              </LangContext.Consumer>
                            }
                            validateStatus={
                              touched.detail_problem &&
                              errors.detail_problem &&
                              'error'
                            }
                            help={
                              touched.detail_problem && errors.detail_problem
                            }
                          >
                            <TextArea
                              rows={6}
                              onChange={(value) =>
                                setFieldValue(
                                  'detail_problem',
                                  value.target.value
                                )
                              }
                              value={values.detail_problem}
                            />
                          </FormItem>
                        ) : (
                          ''
                        )}

                        <FormItem
                          label={
                            <LangContext.Consumer>
                              {(i18n) => i18n.c.comment}
                            </LangContext.Consumer>
                          }
                          validateStatus={
                            touched.comment && errors.comment && 'error'
                          }
                          help={touched.comment && errors.comment}
                        >
                          <TextArea
                            rows={6}
                            onChange={(value) =>
                              setFieldValue('comment', value.target.value)
                            }
                            value={values.comment}
                          />
                        </FormItem>

                        <FormItem>
                          <Row gutter={24}>
                            <Col span={12}>
                              <Button type='defualt' block>
                                <Link to='/servicerepairdevice'>
                                  <LangContext.Consumer>
                                    {(i18n) => i18n.b.back}
                                  </LangContext.Consumer>
                                </Link>
                              </Button>
                            </Col>
                            <Col span={12}>
                              <Button type='primary' htmlType='submit' block>
                                <LangContext.Consumer>
                                  {(i18n) => i18n.s.save}
                                </LangContext.Consumer>
                              </Button>
                            </Col>
                          </Row>
                        </FormItem>
                      </Form>
                    </Col>
                  </Row>
                )}
              />
            )}
          </LangContext.Consumer>
        </Spin>
      </div>
    )
  }
}
