import { createTypes, createAction } from "lib/action";
const LOAD_REFUEL = createTypes("loadrefuel", "load");
const LOAD_AUTOCOMPLETE = createTypes("autocomplete", "load");
const LOAD_AUTOCOMPLETE_VEHICLE_CODE = createTypes("autocompletevehiclecode", "load");
const CREATE_REFUEl = createTypes("createrefuel", "create");
const UPDATE_REFUEL = createTypes("updaterefuel", "update");
const LOAD_REFUEL_DETAIL = createTypes("loadrefueldetail", "load");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
    return {
        type: REMOVE_LISTS
    };
}

const loadRefuel = {
    request: (data, loading) =>
        createAction(LOAD_REFUEL.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(LOAD_REFUEL.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_REFUEL.FAITLURE)  
};

const loadRefuelDetail = {
    request: (data, loading) => 
        createAction(LOAD_REFUEL_DETAIL.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(LOAD_REFUEL_DETAIL.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_REFUEL_DETAIL.FAILURE)
};

const loadAutocomplete = {
    request: (url, companyID, accessToken, queryString) =>
      createAction(LOAD_AUTOCOMPLETE.REQUEST, {
        url,
        companyID,
        accessToken,
        queryString
      }),
    success: lists => createAction(LOAD_AUTOCOMPLETE.SUCCESS, { lists }),
    failure: () => createAction(LOAD_AUTOCOMPLETE.FAILURE)
};

const loadAutocompleteVehicleCode = {
    request: (url, companyID, accessToken, queryString) =>
      createAction(LOAD_AUTOCOMPLETE_VEHICLE_CODE.REQUEST, {
        url,
        companyID,
        accessToken,
        queryString
      }),
    success: lists => createAction(LOAD_AUTOCOMPLETE_VEHICLE_CODE.SUCCESS, { lists }),
    failure: () => createAction(LOAD_AUTOCOMPLETE_VEHICLE_CODE.FAILURE)
};

const createRefuel = {
    request: (data, loading) =>
        createAction(CREATE_REFUEl.REQUEST, { data, loading}),
    success: res => createAction(CREATE_REFUEl.SUCCESS, { res }),
        failure: () => createAction(CREATE_REFUEl.FAILURE)
};

const updateRefuel = {
    request: (data, loading) =>
        createAction(UPDATE_REFUEL.REQUEST, { data, loading}),
    success: res => createAction(UPDATE_REFUEL.SUCCESS, { res }),
        failure: () => createAction(UPDATE_REFUEL.FAILURE)
};

export {
    LOAD_REFUEL, loadRefuel,
    LOAD_REFUEL_DETAIL, loadRefuelDetail,
    LOAD_AUTOCOMPLETE, loadAutocomplete,
    LOAD_AUTOCOMPLETE_VEHICLE_CODE, loadAutocompleteVehicleCode,
    CREATE_REFUEl, createRefuel,
    UPDATE_REFUEL, updateRefuel,
    REMOVE_LISTS, removeLists
};