import React from "react";
import {
  Collapse,
  Icon,
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Spin,
} from "antd";

const { Panel } = Collapse;
const { Option } = Select;

const CollapseSearch = ({
  activeKey,
  setFormSearch,
  formSearch,
  setActiveKey,
  handleSearch,
  loading,
}) => {
  const customPanelStyle = {
    background: "#f7f7f7",
    borderRadius: 4,
    padding: 15,
    border: 0,
    overflow: "hidden",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 12 },
      sm: { span: 12 },
    },
  };
  return (
    <Spin tip="Loading" size="small" spinning={loading}>
      <Collapse
        bordered={false}
        activeKey={activeKey}
        expandIcon={({ isActive }) => (
          <Icon type="caret-right" rotate={isActive ? 90 : 0} />
        )}
        onChange={() => {
          setActiveKey(activeKey.length > 0 ? [] : ["1"]);
        }}
      >
        <Panel header="ค้นหา" key="1" style={customPanelStyle}>
          <div style={{ padding: 20 }}>
            <Form {...formItemLayout}>
              <Form.Item
                label="ทะเบียนพาหนะ"
                hasFeedback
                validateStatus="success"
              >
                <Input
                  value={formSearch.vehiclePlateNo}
                  disabled
                  id="success"
                  style={{ width: 275 }}
                />
              </Form.Item>

              <Form.Item label="รหัสพาหนะ" hasFeedback validateStatus="success">
                <Input
                  value={formSearch.vehicleId}
                  disabled
                  id="success"
                  style={{ width: 275 }}
                />
              </Form.Item>

              <Form.Item label="วันที่เติมน้ำมัน" required>
                <Form.Item
                  validateStatus={!!formSearch.date ? "" : "error"}
                  help={!!formSearch.date ? "" : "Please select date"}
                >
                  <DatePicker
                    style={{ width: 275 }}
                    value={formSearch.date}
                    onChange={(e) => {
                      setFormSearch((prev) => {
                        return {
                          ...prev,
                          date: e,
                        };
                      });
                    }}
                  />
                </Form.Item>
              </Form.Item>

              <Form.Item label="ช่วงเวลาดูข้อมูล" required>
                <Select
                  defaultValue={formSearch.durationWeek}
                  style={{ width: 275 }}
                  onChange={(e) => {
                    setFormSearch((prev) => {
                      return {
                        ...prev,
                        durationWeek: e,
                      };
                    });
                  }}
                >
                  <Option value="7">1 สัปดาห์</Option>
                  <Option value="14">2 สัปดาห์</Option>
                  <Option value="21">3 สัปดาห์</Option>
                  <Option value="28">4 สัปดาห์</Option>
                </Select>
              </Form.Item>
              <Form.Item wrapperCol={{ span: 12, offset: 5 }}>
                <Button
                  type="primary"
                  disabled={!!formSearch.date ? false : true}
                  onClick={handleSearch}
                >
                  ค้นหา
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Panel>
      </Collapse>
    </Spin>
  );
};

export default CollapseSearch;
