import React from "react";
import { Row, Col } from "antd";

const EventMarker = ({
  img,
  title,
  lat,
  lng,
  onClick
}) => {
  
  return (
    <Row>
    <Col span={24}>
        <div
        style={{
            height: "25px",
            width: "25px",
            marginLeft: "-10px",
            marginTop: "-7px",
            position: "absolute",
            // background: imgUrl
        }}
        >
        <img src={img} onClick={onClick} />
        </div>
        
    </Col>
    </Row>
  );
};

export default EventMarker;
