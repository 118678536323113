import { Col, Row, Select, Button, Checkbox } from "antd";

import React, { Component, Fragment } from "react";
import DriverSearch from "./DriverSearch";
import JobOrderNameSearch from "./JobOrderNameSearch";
import VehicleSearch from "./VehicleSearch";
import LangContext from "modules/shared/context/langContext";
import SensorChart from "./SensorChart";

const Option = Select.Option;

const options = [
  {
    value: "vehicle",
    label: "Vehicle",
    word: (i18n) => i18n.v.vehicle,
  },
  {
    value: "driver",
    label: "Driver",
    word: (i18n) => i18n.d.driver,
  },
  {
    value: "jobordername",
    label: "Job Order Name",
    word: (i18n) => i18n.j.job_order_name,
  },
];

export default class Form extends Component {
  state = {
    searchBy: "vehicle",
  };

  handleChangeSearchBy = (value, otp) => {
    this.setState({ searchBy: value });
  };

  RenderComponent = () => {
    if (this.state.searchBy === "vehicle") {
      return (
        <VehicleSearch
          onSubmit={this.props.onSubmit}
          auth={this.props.auth}
        />
      );
    }
    if (this.state.searchBy === "driver") {
      return (
        <DriverSearch
          onSubmit={this.props.onSubmit}
          auth={this.props.auth}
        />
      );
    }
    return (
      <JobOrderNameSearch
        onSubmit={this.props.onSubmit}
        auth={this.props.auth}
      />
    );
  };

  render() {
    return (
      <LangContext.Consumer>
        {(i18n) => (
          <Fragment>
            <h1>
              <LangContext>
                {(i18n) => i18n.g.graphsonsors}
              </LangContext>
            </h1>
            <Row type="flex" align="top">
              <Col
                xs={{ span: 4 }}
                sm={{ span: 6 }}
                type="flex"
                align="right"
                style={{
                  lineHeight: "28px",
                  paddingRight: "10px",
                }}
              >
                <LangContext.Consumer>
                  {(i18n) => i18n.s.search_by}
                </LangContext.Consumer>
                :
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 18 }}
                style={{ lineHeight: "32px" }}
              >
                <Select
                  value={this.state.searchBy}
                  style={{
                    width: 195,
                    paddingBottom: "10px",
                  }}
                  onChange={this.handleChangeSearchBy}
                >
                  {options.map((val, idx) => {
                    return (
                      <Option key={idx} value={val.value}>
                        <LangContext>
                          {val.word}
                        </LangContext>
                      </Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
            <Row type="flex" align="top">
              <Col span={24}>{this.RenderComponent()}</Col>
            </Row>
          </Fragment>
        )}
      </LangContext.Consumer>
    );
  }
}
