import {
  LOAD_LISTVEHICLEMDVR,
  LOAD_VEHICLE_VIDEO
} from "./actions";

const initialState = {
  vehicleLists: [],
  vehicleListMdvr: [],
  videoLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_LISTVEHICLEMDVR.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        videoLoading: loading
      };
    case LOAD_LISTVEHICLEMDVR.SUCCESS:
      return {
        ...state,
        vehicleListMdvr: action.payload.data.data,
        videoLoading: false
      };
    case LOAD_VEHICLE_VIDEO.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
      };
    default:
      return state;
  }
};
