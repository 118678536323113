import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";
import { getlocalstorage } from "./../../../constants/local_storage";

function* loadRefuel(action) {
  const {
    data: {
      page,
      pageSize,
      datavisibility_vehicle,
      orderBy,
      orderType,
      accessToken,
      filterObj
    }
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getrefuel`,
      {
        page,
        pageSize,
        datavisibility_vehicle,
        orderBy,
        orderType,
        filterObj
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadRefuel.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadRefuel.failure());
  }
}

function* loadRefuelDetail(action) {
  const {
    data: { refuel_id, accessToken }
  } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getrefuel/detail/${refuel_id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadRefuelDetail.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadRefuelDetail.failure());
  }
}

function* loadAutocomplete(action) {
  const { url, companyID, accessToken, queryString } = action.payload;
  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  try {
    if (queryString == "" || queryString.length <= 1) {
      yield put(actions.loadAutocomplete.success([]));
    } else {
      const res = yield call(
        axios.get,
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/${url}/${companyID}/${queryString}/${vehicle_visibility}`,

        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      yield put(actions.loadAutocomplete.success(res.data));
    }
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadAutocomplete.failure());
  }
}

function* loadAutocompleteVehicleCode(action) {
  const { url, companyID, accessToken, queryString } = action.payload;
  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  try {
    if (queryString == "" || queryString.length <= 1) {
      yield put(actions.loadAutocompleteVehicleCode.success([]));
    } else {
      const res = yield call(
        axios.get,
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/${url}/${companyID}/${queryString}/${vehicle_visibility}`,

        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      yield put(actions.loadAutocompleteVehicleCode.success(res.data));
    }
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadAutocompleteVehicleCode.failure());
  }
}

function* createRefuel(action) {
  const {
    data: {
      company_id,
      accounts_id,
      plate_no,
      vehicle_code,
      vehicle_id,
      quantity,
      total_price,
      start_refuel,
      full,
      accessToken
    },
    loading
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/createrefuel`,
      {
        company_id,
        accounts_id,
        plate_no,
        vehicle_code,
        vehicle_id,
        quantity,
        total_price,
        start_refuel,
        full
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    loading(res.data);
    yield put(actions.createRefuel.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.createRefuel.failure());
  }
}

function* updateRefuel(action) {
  const {
    data: {
      company_id,
      refuel_id,
      accounts_id,
      plate_no,
      vehicle_code,
      vehicle_id,
      quantity,
      total_price,
      start_refuel,
      finish_refuel,
      full,
      accessToken
    },
    loading
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/updaterefuel`,
      {
        company_id,
        refuel_id,
        accounts_id,
        plate_no,
        vehicle_code,
        vehicle_id,
        quantity,
        total_price,
        start_refuel,
        finish_refuel,
        full
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    loading(res.data);
    yield put(actions.updateRefuel.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.updateRefuel.failure());
  }
}

export default function* watchRefuel() {
  yield all([
    takeEvery(actions.LOAD_REFUEL.REQUEST, loadRefuel),
    takeEvery(actions.LOAD_AUTOCOMPLETE.REQUEST, loadAutocomplete),
    takeEvery(
      actions.LOAD_AUTOCOMPLETE_VEHICLE_CODE.REQUEST,
      loadAutocompleteVehicleCode
    ),
    takeEvery(actions.CREATE_REFUEl.REQUEST, createRefuel),
    takeEvery(actions.LOAD_REFUEL_DETAIL.REQUEST, loadRefuelDetail),
    takeEvery(actions.UPDATE_REFUEL.REQUEST, updateRefuel)
  ]);
}

export {
  loadRefuel,
  loadAutocomplete,
  loadAutocompleteVehicleCode,
  loadRefuelDetail,
  createRefuel,
  updateRefuel
};
