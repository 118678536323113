import { createTypes, createAction } from "lib/action";

const LOAD_PULLVEHICLESINK = createTypes("pullvehiclesink", "load");
const CREATE_PULLVEHICLESINK = createTypes("pullvehiclesink", "create");
const UPDATE_PULLVEHICLESINK = createTypes("pullvehiclesink", "update");
const DELETE_PULLVEHICLESINK = createTypes("pullvehiclesink", "delete");
const GET_PULLVEHICLESINK = createTypes("pullvehiclesink", "get");
const CLEAR_PULLVEHICLESINK = createTypes("pullvehiclesink", "clear");
const LOAD_VEHICLE_PULLVEHICLESINK = createTypes(
  "vehicle_pullvehiclesink",
  "load"
);
const CLEAR_RESPONSE = createTypes("response", "clear");

const loadVehiclePullvehiclesink = {
  request: plate_no =>
    createAction(LOAD_VEHICLE_PULLVEHICLESINK.REQUEST, { plate_no }),
  success: data => createAction(LOAD_VEHICLE_PULLVEHICLESINK.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_PULLVEHICLESINK.FAILURE)
};

const loadPullvehiclesink = {
  request: (data, loading) =>
    createAction(LOAD_PULLVEHICLESINK.REQUEST, { data, loading }),
  success: data => createAction(LOAD_PULLVEHICLESINK.SUCCESS, { data }),
  failure: () => createAction(LOAD_PULLVEHICLESINK.FAILURE)
};

const createPullvehiclesink = {
  request: (data, loading, history) =>
    createAction(CREATE_PULLVEHICLESINK.REQUEST, {
      data,
      loading,
      history
    }),
  success: data => createAction(CREATE_PULLVEHICLESINK.SUCCESS, { data }),
  failure: () => createAction(CREATE_PULLVEHICLESINK.FAILURE)
};

const updatePullvehiclesink = {
  request: (data, id, loading) =>
    createAction(UPDATE_PULLVEHICLESINK.REQUEST, { data, id, loading }),
  success: data => createAction(UPDATE_PULLVEHICLESINK.SUCCESS, { data }),
  failure: () => createAction(UPDATE_PULLVEHICLESINK.FAILURE)
};

const deletePullvehiclesink = {
  request: id => createAction(DELETE_PULLVEHICLESINK.REQUEST, { id }),
  success: data => createAction(DELETE_PULLVEHICLESINK.SUCCESS, { data }),
  failure: () => createAction(DELETE_PULLVEHICLESINK.FAILURE)
};

const getPullvehiclesinkByID = {
  request: id => createAction(GET_PULLVEHICLESINK.REQUEST, { id }),
  success: data => createAction(GET_PULLVEHICLESINK.SUCCESS, { data }),
  failure: () => createAction(GET_PULLVEHICLESINK.FAILURE)
};

const clearDataResponse = () => {
  return {
    type: CLEAR_RESPONSE
  };
};

const clearPullvehiclesink = () => {
  return {
    type: CLEAR_PULLVEHICLESINK
  };
};

export {
  LOAD_PULLVEHICLESINK,
  CREATE_PULLVEHICLESINK,
  UPDATE_PULLVEHICLESINK,
  GET_PULLVEHICLESINK,
  loadPullvehiclesink,
  createPullvehiclesink,
  updatePullvehiclesink,
  getPullvehiclesinkByID,
  loadVehiclePullvehiclesink,
  LOAD_VEHICLE_PULLVEHICLESINK,
  deletePullvehiclesink,
  DELETE_PULLVEHICLESINK,
  CLEAR_RESPONSE,
  clearDataResponse,
  clearPullvehiclesink,
  CLEAR_PULLVEHICLESINK
};
