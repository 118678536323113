import {
  LOAD_ROUTEMASTER,
  CREATE_ROUTEMASTER,
  UPDATE_ROUTEMASTER,
  CLEAR_ROUTEMASTER,
  GET_ROUTEMASTER,
  CLEAR_RESPONSE,
  LOAD_ROUTEMASTERDETAIL,
  CREATE_ROUTEMASTERDETAIL,
  UPDATE_ROUTEMASTERDETAIL,
  GET_ROUTEMASTERDETAIL,
  LOAD_LOCATION_ROUTEMASTER,
  LOAD_LOCATIONCODE_ROUTEMASTER,
  LOAD_VEHICLE_ROUTEMASTER,
  LOAD_ROUTE_MASTER_TYPE
} from "./actions";

const initialState = {
  vehicleLists: [],
  routemasterLists: [],
  routemasterLoading: false,
  routemasterTotal: 0,
  dataResponse: { status: "", msg: "", id: "", action_type: "" },
  check_routemaster: false,
  dataRoutemaster: {
    id: "",
    name: "",
    route_master_type_id: "",
  },
  routemasterdetailLists: [],
  routemasterdetailLoading: false,
  locationLists: [],
  locationcodeLists: [],
  routemasterDetails: {
    route_master_id: "",
    location_id: "",
    location_code: "",
    date_entry: "",
    date_out: "",
    time_entry: "",
    time_out: "",
  },
  check_routemasterdetail: true,
  loadingLocationName: false,
  loadingLocationCode: false,
  loadingEditDetail: false,

  checkRouteMasterType: false,
  routeMasterType: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ROUTEMASTER.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        routemasterLoading: loading
      };
    case LOAD_ROUTEMASTER.SUCCESS:
      return {
        ...state,
        routemasterLists: action.payload.data.data,
        routemasterLoading: false,
        routemasterTotal: action.payload.data.total
      };
    case CREATE_ROUTEMASTER.REQUEST:
      const { loading: loading2 } = action.payload;
      return {
        ...state,
        routemasterLoading: loading2
      };
    case CREATE_ROUTEMASTER.SUCCESS:
      return {
        ...state,
        dataResponse: { status: action.payload.data.status, msg: action.payload.data.msg, action_type: action.payload.data.action_type },
        routemasterLoading: false
      };
    case UPDATE_ROUTEMASTER.REQUEST:
      const { loading: loading3 } = action.payload;
      return {
        ...state,
        routemasterLoading: loading3
      };
    case UPDATE_ROUTEMASTER.SUCCESS:
      return {
        ...state,
        dataResponse: { status: action.payload.data.status, msg: action.payload.data.msg, action_type: action.payload.data.action_type },
        routemasterLoading: false
      };

    case GET_ROUTEMASTER.REQUEST:
      return {
        ...state,
        check_routemaster: true,
      };
    case GET_ROUTEMASTER.SUCCESS:
      return {
        ...state,
        dataRoutemaster: action.payload.data.data,
        routemasterLoading: false,
        check_routemaster: false,
      };

    case CLEAR_RESPONSE:
      return {
        ...state,
        dataResponse: { status: "", msg: "", id: "", action_type: "" }
      };
    case CLEAR_ROUTEMASTER:
      return {
        ...state,
        dataRoutemaster: {
          id: "",
          name: "",
          route_master_type_id: "",
        }
      };
    case LOAD_LOCATION_ROUTEMASTER.REQUEST:
      return {
        ...state,
        loadingLocationName: true
      };
    case LOAD_LOCATIONCODE_ROUTEMASTER.REQUEST:
      return {
        ...state,
        loadingLocationCode: true
      };

    case LOAD_LOCATION_ROUTEMASTER.SUCCESS:

      return {
        ...state,
        locationLists: action.payload.data.data,
        loadingLocationName: false
      };
    case LOAD_LOCATIONCODE_ROUTEMASTER.SUCCESS:

      return {
        ...state,
        locationcodeLists: action.payload.data.data,
        loadingLocationCode: false
      };

    case LOAD_ROUTEMASTERDETAIL.REQUEST:
      const { loading: loadingdetail } = action.payload;
      return {
        ...state,
        routemasterdetailLoading: loadingdetail
      };
    case LOAD_ROUTEMASTERDETAIL.SUCCESS:
      return {
        ...state,
        routemasterdetailLists: action.payload.data.data,
        routemasterdetailLoading: false,
      };
    case CREATE_ROUTEMASTERDETAIL.REQUEST:
      return {
        ...state,
        routemasterdetailLoading: true
      };
    case CREATE_ROUTEMASTERDETAIL.SUCCESS:
      return {
        ...state,
        //dataResponse: { status: action.payload.data.status, msg: action.payload.data.msg, action_type: action.payload.data.action_type, routemasterdetailLists: action.payload.data.detailLists.data },
        routemasterdetailLoading: false,
        routemasterdetailLists: action.payload.data.detailLists.data
      };
    case UPDATE_ROUTEMASTERDETAIL.REQUEST:
      return {
        ...state,
        routemasterdetailLoading: true

      };
    case UPDATE_ROUTEMASTERDETAIL.SUCCESS:
      return {
        ...state,
        // dataResponse: { status: action.payload.data.status, msg: action.payload.data.msg, action_type: action.payload.data.action_type, routemasterdetailLists: action.payload.data.detailLists.data },
        routemasterdetailLoading: false,
        routemasterdetailLists: action.payload.data.detailLists.data
      };

    case GET_ROUTEMASTERDETAIL.REQUEST:

      return {
        ...state,
        check_routemasterdetail: true,
        loadingEditDetail: action.payload.loading

      };
    case GET_ROUTEMASTERDETAIL.SUCCESS:
      return {
        ...state,
        routemasterDetails: action.payload.data.data,
        routemasterdetailLoading: false,
        check_routemasterdetail: false,
        loadingEditDetail: false
      };
    case LOAD_VEHICLE_ROUTEMASTER.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        //  drivinghourLoading: false
      };
    
    case LOAD_ROUTE_MASTER_TYPE.REQUEST:
      return {
        ...state,
        routeMasterType: [],
        checkRouteMasterType: true,
      };
    case LOAD_ROUTE_MASTER_TYPE.SUCCESS:
      return {
        ...state,
        routeMasterType: action.payload.data.data || [],
        checkRouteMasterType: false
      };
    
    default:
      return state;
  }
};
