import React from "react";
import { Button, Select, Form, Input, Drawer } from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";

const { Option } = Select;

const IndexFilterDrawer = (props) => (
  <Drawer
    title={
      <LangContext.Consumer>{(i18n) => i18n.f.filter}</LangContext.Consumer>
    }
    width={520}
    placement="right"
    onClose={props.onClose}
    maskClosable={true}
    visible={props.visible}
    style={{
      height: "100%",
      overflow: "auto",
      paddingBottom: 53,
    }}
  >
    <Form>
      <Form.Item
        label={
          <LangContext.Consumer>{(i18n) => i18n.n.name}</LangContext.Consumer>
        }
      >
        <Input onChange={props.handleFilterObjName} />
      </Form.Item>

      <Form.Item
        label={
          <LangContext.Consumer>
            {(i18n) => i18n.p.plateNo}
          </LangContext.Consumer>
        }
      >
        <Input onChange={props.handleFilterObjPlateNo} />
      </Form.Item>

      <Form.Item
        label={
          <LangContext.Consumer>
            {(i18n) => i18n.v.vehicleCode}
          </LangContext.Consumer>
        }
      >
        <Input onChange={props.handleFilterObjVehicleCode} />
      </Form.Item>

      <Form.Item>
        <Button style={{ marginRight: 8 }} onClick={props.onClose}>
          <LangContext.Consumer>{(i18n) => i18n.c.cancel}</LangContext.Consumer>
        </Button>

        <Button type="primary" onClick={props.onFilterButtonClick}>
          <LangContext.Consumer>{(i18n) => i18n.a.apply}</LangContext.Consumer>
        </Button>
      </Form.Item>
    </Form>
  </Drawer>
);

export default IndexFilterDrawer;
