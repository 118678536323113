import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Table, Row, Col, Spin, Modal, Icon, message } from "antd";

import Filter from "./Filter";
import LangContext from "modules/shared/context/langContext";
import * as actions from "../actions";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import axios from "axios";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";

const columnStyle = { fontSize: "12px", fontWeight: "400" };
const brokenStyle = {
  fontSize: "12px",
  fontWeight: "400",
  background: "#ffeced",
};

class Index extends Component {
  state = {
    visible: false,
    listfiles: [],
    downloading: false,
    filedownloading: false,
    idprocess: "web" + new Date().getTime(),
    plate_no: "",
    progress: "",
    check_load_vihicle: false,
    filter_file_size: "large",
    vehicleLoading: false,
    vehicleFilterList: [],
    deviceProcess: null,
    idconnect: "",
    getVehicleInfo: [],
  };

  ws = new WebSocket(
    `wss://${process.env.REACT_APP_WEBSOCKET}/ws?idprocess=` +
      this.state.idprocess
  );

  getVehicleList = async () => {
    const url =
      `https://${process.env.REACT_APP_WEBSOCKET}/getAllConnection?idprocess=` +
      this.state.idprocess;
    const urlParam = new URL(window.location.href);

    await axios.get(url).then((res) => {
      if (res.data.message !== "") {
        // this.loadVehicle(
        //   res.data.message.split(","),
        //   urlParam.searchParams.get("id")
        // );
      }
      if (
        !res.data.message.split(",").includes(urlParam.searchParams.get("id"))
      ) {
        message.error("พาหนะออฟไลน์");
      }
    });
  };

  componentDidMount() {
    const urlParam = new URL(window.location.href);

    this.ws.onopen = () => {
      // on connecting, do nothing but log it to the console
      // console.log("connected");
      this.getVehicleList();
      this.loadPlateNoFromImei(urlParam.searchParams.get("id"));
    };

    this.ws.onmessage = (evt) => {
      // listen to data sent from the websocket server
      // const message = JSON.parse(evt.data)

      // console.log("response message:", evt.data);
      var messages = evt.data.split("\n");

      for (var i = 0; i < messages.length; i++) {
        let obj = JSON.parse(messages[i]);

        if (obj.Idprocess == "" && obj.Idconnect == "") {
          //console.log("allconneciton", obj.Allconnection)
          // if (this.state.check_load_vihicle === false) {
          //   this.loadVehicle(obj.Allconnection);
          //   this.setState({ check_load_vihicle: true })
          // }
        } else {
          //console.log("data:", JSON.parse(obj.Data))
          let data = JSON.parse(obj.Data);
          if (data.type == "get_list_video") {
            let newlistFiles = [];
            let d = data.files;
            for (let i = 0; i < d.length; i++) {
              let size = d[i].size / 1024 / 1024;
              let date_time = moment(d[i].create_date).format(
                "DD/MM/YYYY HH:mm:ss"
              );
              var camera = "";
              // console.log("camera:", d[i].camera_source)
              if (d[i].camera_source == "front") {
                camera = (
                  <LangContext.Consumer>
                    {(i18n) => i18n.f.front}
                  </LangContext.Consumer>
                );
              } else if (d[i].camera_source == "back") {
                camera = (
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.camera_back}
                  </LangContext.Consumer>
                );
              } else if (d[i].camera_source == "extra") {
                camera = (
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.camera_extra}
                  </LangContext.Consumer>
                );
              }
              newlistFiles.push({
                id: i + 1,
                row_number: i + 1,
                plate_no: this.state.plate_no,
                time: date_time,
                size: size.toFixed(2) + " MB",
                file_name: d[i].name,
                imei: obj.Idprocess,
                camera_source: camera,
                camera_source_txt: d[i].camera_source,
              });
            }

            this.setState(
              {
                downloading: false,
                listfiles: newlistFiles,
              },
              this.handleCheckProcess
            );
          } else if (data.type == "download_video") {
            if (data.status == "PROGRESS") {
              this.setState({ progress: data.progress + "%" });
            } else if (data.status == "COMPLETE") {
              this.setState({ filedownloading: false, progress: "" });
              //console.log("path", data.path_download)
              let pathFile = data.path_download.split(
                "hntdevice.terminusfleet.com/"
              );
              // console.log(
              //   `https://${process.env.REACT_APP_WEBSOCKET}/downloadvideo?path_file=` +
              //     `http://localhost/` +
              //     pathFile[1]
              // );

              window.open(
                `https://${process.env.REACT_APP_WEBSOCKET}/downloadvideo?path_file=` +
                  `http://localhost/` +
                  pathFile[1]
              );
            } else if (data.status == "NOT READY") {
              this.setState({ filedownloading: false, progress: "" });
              this.fileNotReady();
            }
          }
        }
      }
    };

    this.ws.onclose = () => {
      // automatically try to reconnect on connection loss
      // console.log("disconnected");
    };

    this.interval = setInterval(() => {
      this.handleCheckProcess();
    }, 1000 * 15);
  }

  componentWillUnmount() {
    this.ws.onclose = (e) => {};
    this.ws.close();
    clearInterval(this.interval);
  }

  handleCheckProcess = async () => {
    if (this.state.idconnect === "") {
      return;
    }

    try {
      const allconnect = await axios.get(
        `https://${process.env.REACT_APP_WEBSOCKET}/getDeviceProcessByImei?imei=${this.state.idconnect}`
      );
      const { cntDownload, cntLivestream, cntPlayback } = allconnect.data;
      let total = cntDownload + cntLivestream + cntPlayback;
      let result = allconnect.data;
      if (total === 0) {
        result = null;
      }
      this.setState({
        deviceProcess: result,
      });
    } catch (error) {}
  };

  fileNotReady = () => {
    Modal.warning({
      content: (
        <LangContext.Consumer>
          {(i18n) => i18n.f.filenotreadydownload}
        </LangContext.Consumer>
      ),
    });
  };

  showDrawer = () => {
    this.setState(
      {
        visible: true,
        vehicleLoading: true,
      },
      this.getVehicleList
    );
  };

  onCloseSearch = (resetForm) => {
    this.setState({
      visible: false,
    });
  };

  filterHoneytoastdownloadfile = (values) => {
    const url = new URL(window.location.href);
    const { imei, eventdate, plate_no, camera_position, file_size } = values;
    const date_start = moment(eventdate[0]["_d"]).format("YYYY-MM-DD HH:mm");
    const date_end = moment(eventdate[1]["_d"]).format("YYYY-MM-DD HH:mm");

    this.setState({
      downloading: true,
      listfile: [],
      filter_file_size: file_size,
      idconnect: url.searchParams.get("id"),
    });
    const data = JSON.stringify({
      type: "get_list_video",
      date_start: date_start,
      date_end: date_end,
      camera_source: camera_position,
      file_size: file_size,
    });

    const message = {
      Idconnect: url.searchParams.get("id"),
      Idprocess: this.state.idprocess,
      Data: data,
    };

    this.ws.send(JSON.stringify(message));
  };

  checkFileExist = (imei, file_name, file_size) => {
    const data = {
      idprocess: this.state.idprocess,
      imei,
      file_name,
      file_size,
    };

    return axios.post(
      `https://${process.env.REACT_APP_WEBSOCKET}/checkfile`,
      data
    );
  };

  loadVideo = async (e, file_name, imei, source, size) => {
    e.preventDefault();

    const result = await this.checkFileExist(imei, file_name, parseFloat(size));

    if (result.data.success) {
      window.location.assign(result.data.message);
      return true;
    }

    this.setState({ filedownloading: true, progress: "0%" });
    const data = JSON.stringify({
      type: "download_video",
      name: file_name,
      file_size: this.state.filter_file_size,
      camera_source: source,
    });

    const message = {
      Idconnect: imei,
      Idprocess: this.state.idprocess,
      Data: data,
    };
    this.ws.send(JSON.stringify(message));
  };

  loadVehicle = (allimei, imei) => {
    const { loadVehicle } = this.props;
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehicleinwebsocket`,
        {
          vehicle_visibility: this.props.profile.vehicle_visibility,
          company_id: this.props.profile.company_id,
          allimei,
        },
        { headers: { Authorization: `Bearer ${this.props.accessToken}` } }
      )
      .then((res) => {
        this.setState({
          vehicleLoading: false,
          vehicleFilterList: res.data.data,
        });
      });
  };

  loadPlateNoFromImei = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/video-playback/vehicle/${id}`,
        {}
      )
      .then((res) => {
        console.log("loadPlateNoFromImei", res);
        this.setState({
          plate_no: res.data.data.plate_no,
          getVehicleInfo: {
            id: res.data.data.id,
            imei: res.data.data.imei,
            plate_no: res.data.data.plate_no,
            has_file_opt: res.data.data.has_file_opt,
            device_types_id: res.data.data.device.device_types_id,
          },
        });
      });
  };

  getRowStyle = (record) => {
    if (parseFloat(record.size) <= 0) {
      return brokenStyle;
    }
    return columnStyle;
  };

  render() {
    const columns = [
      {
        title: <div className="regular-bold-14">#</div>,
        dataIndex: "row_number",
        width: "4%",
        className: "column-text-center",
        render: (text, record, index) => {
          return {
            props: {
              style: this.getRowStyle(record),
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {(i18n) => i18n.p.plateNo}
            </LangContext.Consumer>
          </div>
        ),
        dataIndex: "plate_no",
        sorter: false,
        width: "20%",
        render: (text, record, index) => {
          return {
            props: {
              style: this.getRowStyle(record),
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {(i18n) => i18n.d.date_time}
            </LangContext.Consumer>
          </div>
        ),
        dataIndex: "time",
        sorter: false,
        render: (text, record, index) => {
          return {
            props: {
              style: this.getRowStyle(record),
            },
            children: <div>{text}</div>,
          };
        },
        className: "column-text-center",
        width: "15%",
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {(i18n) => i18n.c.camera_position}
            </LangContext.Consumer>
          </div>
        ),
        className: "column-text-center",
        dataIndex: "camera_source",
        sorter: false,
        width: "15%",
        render: (text, record, index) => {
          return {
            props: {
              style: this.getRowStyle(record),
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {(i18n) => i18n.f.file_size}
            </LangContext.Consumer>
          </div>
        ),
        className: "column-text-center",
        dataIndex: "size",
        sorter: false,
        render: (text, record, index) => {
          return {
            props: {
              style: this.getRowStyle(record),
            },
            children: <div>{text}</div>,
          };
        },
        width: "10%",
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {(i18n) => i18n.d.download}
            </LangContext.Consumer>
          </div>
        ),
        className: "column-text-center",
        dataIndex: "",
        sorter: false,
        render: (text, record, index) => {
          if (parseFloat(record.size) <= 0) {
            return {
              props: {
                style: this.getRowStyle(record),
              },
              children: (
                <div
                  className="regular-bold-14"
                  style={{ padding: "0px 15px 0px 5px" }}
                >
                  <LangContext.Consumer>
                    {(i18n) => i18n.b.brokenFile}
                  </LangContext.Consumer>
                </div>
              ),
            };
          }

          return {
            props: {
              style: this.getRowStyle(record),
            },
            children: (
              <div>
                <a
                  href="#"
                  onClick={(e) => {
                    this.loadVideo(
                      e,
                      record.file_name,
                      record.imei,
                      record.camera_source_txt,
                      record.file_size
                    );
                  }}
                  style={{ padding: "0px 15px 0px 5px" }}
                >
                  <Icon type="download" />
                </a>
              </div>
            ),
          };
        },
        width: "10%",
      },
    ];

    return (
      // <AuthorizeComponent
      //   {...this.props}
      //   matching_name="download_video_honeytoast"
      // >
      <GeneralStyledContent>
        <Row gutter={24}>
          <Col span={24}>
            <div className="semi-bold-16" style={{ color: "black" }}>
              <LangContext.Consumer>
                {(i18n) => i18n.h.honeytoastdownloadfile}
              </LangContext.Consumer>
            </div>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Filter
              OnClose={this.onCloseSearch}
              vehicleLoading={this.state.vehicleLoading}
              downloading={this.state.downloading}
              onSubmit={this.filterHoneytoastdownloadfile}
              vehicleLists={this.state.vehicleFilterList}
              getVehicleList={this.getVehicleList}
              deviceProcess={this.state.deviceProcess}
              disabled={this.state.filedownloading}
              getVehicleInfo={this.state.getVehicleInfo}
            />
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Spin
              tip={this.state.progress}
              style={{ fontSize: "28px" }}
              spinning={this.state.filedownloading}
            >
              <Table
                bordered
                size="small"
                columns={columns}
                rowKey={(record) => record.row_number}
                dataSource={this.state.listfiles}
                pagination={false}
                loading={this.state.downloading}
              />
            </Spin>
          </Col>
        </Row>
      </GeneralStyledContent>
      // </AuthorizeComponent>
    );
  }
}

const mapStateToProps = ({
  honeytoastdownloadfile,
  auth: { accessToken, profile },
}) => ({
  honeytoastdownloadfile,
  profile,
  accessToken,
});

const mapDispatchToProps = {
  loadVehicle: actions.loadVehicle.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
