import {
  AutoComplete,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
} from "antd";
import CheckboxGroup from "antd/lib/checkbox/Group";
import { Formik } from "formik";
import moment from "moment";
import React, { PureComponent, Fragment } from "react";

import * as yup from "yup";
import Sensors from "./Sensors";

import LangContext from "modules/shared/context/langContext";
import Axios from "axios";
import { getlocalstorage } from "../../../../constants/local_storage";
const { RangePicker } = DatePicker;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 18,
      offset: 0,
    },
  },
};

export default class VehicleSearch extends PureComponent {
  state = {
    // vehicle_id: "",
    // vehicle_code: "",
    // starting_time: "",
    // ending_time: "",
    vehicleList: [],
  };

  getVehicle = async () => {
    const res = await Axios.post(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebygroupid`,
      {
        allow_vehicles: this.props.auth.profile.vehicle_visibility,
        companyID: this.props.auth.profile.company_id,
        vehicletypeID: "",
      },
      {
        headers: {
          Authorization: `Bearer ${this.props.auth.accessToken}`,
        },
      }
    );
    const item = res.data.data.map((value) => {
      return {
        label: value.plate_no,
        value: value.id,
      };
    });

    this.setState({ vehicleList: item });
  };

  componentDidMount() {
    this.getVehicle();
  }

  handleDateFormat = (value) => {
    const new_format = moment(value["_d"]).format("YYYY-MM-DD HH:mm:ss");
    return new_format;
  };

  handleFilterValues = (val, fliterArr) => {
    let keysArr = Object.entries(val);
    let fliteredValue = Object.fromEntries(
      keysArr.filter(([key, value]) => {
        return !fliterArr.includes(key);
      })
    );

    return fliteredValue;
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    return (
      <Fragment>
        <LangContext>
          {(i18n) => (
            // <Row type="flex" align="top">
            //   <Col>
            <Formik
              enableReinitialize={true}
              initialValues={{
                type: "vehicle",
                vehicle_id: "",
                vehicle_code: "",
                starting_time: moment()
                  .hours(0)
                  .minutes(0)
                  .seconds(0),
                finishing_time: moment()
                  .hours(23)
                  .minutes(59)
                  .seconds(0),
                engine_on: false,
                cut_fuel_engine_on_off: false,
                cut_fuel_min: "3",
                sensors: {
                  fuel_1: false,
                  fuel_2: false,
                  temperature1: false,
                  temperature2: false,
                  gate_1: false,
                  gate_2: false,
                  engineStatus: false,
                  pto: false,
                  boom: false,
                  turn_right: false,
                  turn_left: false,
                  high_headlight: false,
                  low_headlight: false,
                  horn: false,
                  brake: false,
                  windshield_viper: false,
                  reversing_light: false,
                  battery: false,
                  fuel_consumption: false,
                  vol: false,
                },
              }}
              validationSchema={yup.object().shape({
                vehicle_id: yup
                  .string()
                  .nullable()
                  .required(
                    <LangContext>
                      {(i18n) => i18n.v.valueIsRequired}
                    </LangContext>
                  ),
                starting_time: yup
                  .date()
                  .nullable()
                  .required(
                    <LangContext>
                      {(i18n) => i18n.v.valueIsRequired}
                    </LangContext>
                  ),
                finishing_time: yup
                  .date()
                  .min(
                    yup.ref("starting_time"),
                    "เวลาสิ้นสุดต้องมากกว่าเวลาเริ่ม"
                  )
                  .nullable()
                  .required(
                    <LangContext>
                      {(i18n) => i18n.v.valueIsRequired}
                    </LangContext>
                  ),
              })}
              onSubmit={(values, { resetForm }) => {
                values.date_start = this.handleDateFormat(values.starting_time);
                values.date_end = this.handleDateFormat(values.finishing_time);
                const fliered_value = this.handleFilterValues(values, [
                  "finishing_time",
                  "starting_time",
                ]);
                this.props.onSubmit(fliered_value);
                // console.log("values", values);
                //resetForm();
              }}
              render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                handleFocus,
                setFieldValue,
                isValidating,
              }) => {
                return (
                  <Form {...formItemLayout} onSubmit={handleSubmit}>
                    <Form.Item
                      label={
                        <LangContext.Consumer>
                          {(i18n) => i18n.p.plateNo}
                        </LangContext.Consumer>
                      }
                      required
                      validateStatus={
                        touched.vehicle_id && errors.vehicle_id && "warning"
                      }
                      help={touched.vehicle_id && errors.vehicle_id}
                    >
                      <AutoComplete
                        value={values.vehicle_id}
                        style={{ width: 200 }}
                        optionLabelProp="children"
                        filterOption={true}
                        optionFilterProp="children"
                        placeholder={
                          <LangContext.Consumer>
                            {(i18n) => i18n.s.search_plate_no}
                          </LangContext.Consumer>
                        }
                        onSelect={(value, op) => {
                          setFieldValue("vehicle_id", value);
                          setFieldValue("vehicle_code", op.props.children);
                        }}
                        onChange={(value) => {
                          setFieldValue("vehicle_id", value);
                        }}
                      >
                        {this.state.vehicleList.map((val) => {
                          return (
                            <AutoComplete.Option value={val.value.toString()}>
                              {val.label}
                            </AutoComplete.Option>
                          );
                        })}
                      </AutoComplete>
                    </Form.Item>
                    <Form.Item
                      label={
                        <LangContext.Consumer>
                          {(i18n) => i18n.d.dateRange}
                        </LangContext.Consumer>
                      }
                      required
                      validateStatus={
                        touched.starting_time &&
                        errors.starting_time &&
                        "warning"
                      }
                      help={touched.starting_time && errors.starting_time}
                    >
                      <RangePicker
                        showTime={{
                          defaultValue: moment("00:00:00", "HH:mm"),
                          format: "HH:mm",
                        }}
                        format="DD/MM/YYYY HH:mm"
                        defaultValue={[
                          moment()
                            .hours(0)
                            .minutes(0),
                          moment()
                            .hours(23)
                            .minutes(59),
                        ]}
                        onChange={(value) => {
                          setFieldValue("starting_time", value[0]);
                          setFieldValue("finishing_time", value[1]);
                        }}
                      ></RangePicker>
                    </Form.Item>
                    <Form.Item
                      label={
                        <LangContext.Consumer>
                          {(i18n) => i18n.e.engine_on_only}
                        </LangContext.Consumer>
                      }
                    >
                      <Checkbox
                        checked={values.engine_on}
                        onChange={(e) => {
                          setFieldValue("engine_on", e.target.checked);
                        }}
                      ></Checkbox>
                    </Form.Item>

                    <Form.Item
                      label={
                        <LangContext.Consumer>
                          {(i18n) => i18n.c.cut_fuel_engine_on_off}
                        </LangContext.Consumer>
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          checked={values.cut_fuel_engine_on_off}
                          onChange={(e) => {
                            const checked = e.target.checked;
                            setFieldValue("cut_fuel_engine_on_off", checked);

                            const defaultCutFuelMin = checked ? "3" : "0";
                            setFieldValue("cut_fuel_min", defaultCutFuelMin);
                          }}
                        />
                        {values.cut_fuel_engine_on_off && (
                          <Select
                            style={{ width: "100px", marginLeft: "20px" }}
                            value={values.cut_fuel_min || "3"}
                            onChange={(value) => {
                              setFieldValue("cut_fuel_min", value);
                            }}
                          >
                            <Select.Option value="1">1 นาที</Select.Option>
                            <Select.Option value="3">3 นาที</Select.Option>
                            <Select.Option value="5">5 นาที</Select.Option>
                          </Select>
                        )}
                      </div>
                    </Form.Item>

                    <Form.Item
                      label={
                        <LangContext.Consumer>
                          {(i18n) => i18n.variable.sensor}
                        </LangContext.Consumer>
                      }
                    >
                      <Sensors
                        values={values}
                        setFieldValue={setFieldValue}
                      ></Sensors>
                    </Form.Item>

                    <Form.Item {...tailFormItemLayout}>
                      <Row type="flex" justify="end">
                        <Button type="primary" htmlType="submit">
                          <LangContext>{(i18n) => i18n.r.report}</LangContext>
                        </Button>
                      </Row>
                    </Form.Item>
                  </Form>
                );
              }}
            />
            //   </Col>
            // </Row>
          )}
        </LangContext>
      </Fragment>
    );
  }
}
