import { LOAD_DEVICE_CONFIG, UPDATE_DEVICE_CONFIG } from './actions'
const initialState = {
  lists: [],
  loading: true,
  check: false,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DEVICE_CONFIG.REQUEST:
      const { loading } = action.payload.data
      return {
        ...state,
        loading: loading,
      }
    case LOAD_DEVICE_CONFIG.SUCCESS:
      return {
        ...state,
        lists: action.payload.data.data,
        check: true,
        loading: false,
      }

    case UPDATE_DEVICE_CONFIG.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_DEVICE_CONFIG.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
