import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Form, Input, Alert, Icon, Col, Row } from "antd";

export default ({ auth, onSubmit: authFormSubmit }) => (
  <Formik
    initialValues={{ username: "", password: "" }}
    validationSchema={yup.object().shape({
      username: yup.string().required(),
      password: yup.string().required(),
    })}
    onSubmit={(values) => authFormSubmit(values)}
    render={({
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
    }) => (
      <Form onSubmit={handleSubmit}>
        {auth.status == "" && auth.msg != "" && (
          <Alert
            message={auth.msg}
            type="warning"
            showIcon
            closable
            style={{ margin: "25px 0px 15px 0px" }}
          />
        )}

        <Form.Item
          validateStatus={
            auth.status != ""
              ? auth.status == "error"
                ? auth.status
                : null
              : touched.username && errors.username
              ? "warning"
              : null
          }
          help={
            auth.status != ""
              ? auth.status == "error"
                ? ""
                : null
              : touched.username && errors.username
              ? "Require field"
              : null
          }
        >
          <label>
            <Input
              type="text"
              name="username"
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Username"
              values={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={
                touched.username && errors.username ? "invalid" : undefined
              }
              style={{ margin: "0px 0px 5px 0px" }}
            />
          </label>
        </Form.Item>

        <Form.Item
          validateStatus={
            auth.status != ""
              ? auth.status == "error"
                ? auth.status
                : null
              : touched.password && errors.password
              ? "warning"
              : null
          }
          help={
            auth.status != ""
              ? auth.status == "error"
                ? auth.msg
                : null
              : touched.password && errors.password
              ? "Require field"
              : null
          }
        >
          <label>
            <Input
              type="password"
              name="password"
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Password"
              autoComplete="auth-password"
              values={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={
                touched.password && errors.password ? "invalid" : undefined
              }
              style={{ margin: "0px 0px 5px 0px" }}
            />
          </label>
        </Form.Item>

        <Button htmlType="submit" style={{ width: "100%" }}>
          Login
        </Button>
      </Form>
    )}
  />
);
