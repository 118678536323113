import { createTypes, createAction } from "lib/action";
const LOAD_AUTOCOMPLETE = createTypes("autocomplete", "load");
const SELECTED_AUTOCOMPLETE = createTypes("autocomplete", "selected");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
    return {
        type: REMOVE_LISTS
    };
}

const loadAutocompleteLocation = {
    request: (url, companyID, accessToken, queryString) =>
      createAction(LOAD_AUTOCOMPLETE.REQUEST, {
        url,
        companyID,
        accessToken,
        queryString
      }),
    success: lists => createAction(LOAD_AUTOCOMPLETE.SUCCESS, { lists }),
    failure: () => createAction(LOAD_AUTOCOMPLETE.FAILURE)
};

function selectedAutocompleteLocation(id, lists, selectedName, cb) {
    return {
      type: SELECTED_AUTOCOMPLETE,
      payload: { id, lists, selectedName, cb }
    };
}

export {
    LOAD_AUTOCOMPLETE, loadAutocompleteLocation,
    SELECTED_AUTOCOMPLETE, selectedAutocompleteLocation,
    REMOVE_LISTS, removeLists
};
