import { createTypes, createAction } from "lib/action";

const LOAD_VELOCITYSUMMARY = createTypes("velocitysummary", "load");
const LOAD_VEHICLE_VELOCITYSUMMARY = createTypes(
  "vehicle_velocitysummary",
  "load"
);
const LOAD_VEHICLE_TYPE_VELOCITYSUMMARY = createTypes("vehicletype_velocitysummary", "load");

const loadVelocitysummary = {
  request: (data, loading) =>
    createAction(LOAD_VELOCITYSUMMARY.REQUEST, { data, loading }),
  success: data => createAction(LOAD_VELOCITYSUMMARY.SUCCESS, { data }),
  failure: () => createAction(LOAD_VELOCITYSUMMARY.FAILURE)
};

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_VELOCITYSUMMARY.REQUEST, {
      vehicletypeID,
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_VEHICLE_VELOCITYSUMMARY.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_VELOCITYSUMMARY.FAILURE)
};

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_VELOCITYSUMMARY.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_TYPE_VELOCITYSUMMARY.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_VELOCITYSUMMARY.FAILURE)
};

export {
  loadVelocitysummary,
  LOAD_VELOCITYSUMMARY,
  loadVehicle,
  LOAD_VEHICLE_VELOCITYSUMMARY,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_VELOCITYSUMMARY
};
