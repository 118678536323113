import { createTypes, createAction } from "lib/action";

const LOAD_DATA_CPF_DASHBOARD = createTypes("loaddatacpfdashboard", "load");

const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
  return {
    type: REMOVE_LISTS,
  };
}

const loadDataCPFDashboard = {
  request: (data) => createAction(LOAD_DATA_CPF_DASHBOARD.REQUEST, { data }),
  success: (lists) => createAction(LOAD_DATA_CPF_DASHBOARD.SUCCESS, { lists }),
  failure: () => createAction(LOAD_DATA_CPF_DASHBOARD.FAITLURE),
};

export {
  LOAD_DATA_CPF_DASHBOARD,
  loadDataCPFDashboard,
  REMOVE_LISTS,
  removeLists,
};
