import React, { Component } from "react";

import { Row, Col, Spin, Typography, Table } from "antd";
const columnStyle = { fontSize: "12px", fontWeight: "400" };

export default class Sheet extends Component {
  render() {
    const columns = [
      {
        title: "#",
        dataIndex: "rowNumber",
        width: 60,
        render: (text, record, index) => {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{index + 1}</div>,
          };
        },
      },
      {
        title: "เลข D/O ที่จ่ายน้ำมัน",
        dataIndex: "sdr_trip_code",
        key: "sdr_trip_code",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "วันที่ D/O",
        dataIndex: "formatted_created_at",
        key: "formatted_created_at",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "จำนวนคิว",
        dataIndex: "load_amount",
        key: "load_amount",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
    ];
    const total_credit =
      Number(this.props.withdrawInfo.withdraw_info.current_credit) +
      Number(this.props.withdrawInfo.withdraw_info.credit_balance);

    return (
      <Spin spinning={this.props.loading}>
        <Row type="flex" justify="center">
          <Col>
            <Typography.Title level={4}>ใบเบิกน้ำมัน</Typography.Title>
          </Col>
        </Row>
        <Row type="flex" justify="end">
          <Col>
            <Typography.Text strong>เลขที่ใบจ่ายน้ำมัน&nbsp;</Typography.Text>
          </Col>
          <Col>
            <div>{this.props.withdrawInfo.withdraw_info.code}</div>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={7}>
            <Typography.Text strong>วันที่</Typography.Text>
          </Col>
          <Col span={9}>
            <Typography.Text strong>แพล้นท์</Typography.Text>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={7}>
            <div>
              {this.props.withdrawInfo.withdraw_info.formatted_created_at}
            </div>
          </Col>
          <Col span={9}>
            <div>{this.props.withdrawInfo.withdraw_info.location_name}</div>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={7}>
            <Typography.Text strong>เบอร์รถ</Typography.Text>
          </Col>
          <Col span={9}>
            <Typography.Text strong>ชื่อผู้ขับขี่</Typography.Text>
          </Col>
        </Row>
        <Row type="flex" justify="start" style={{ marginBottom: 8 }}>
          <Col span={7}>
            <div>{this.props.withdrawInfo.withdraw_info.vehicle_code}</div>
          </Col>
          <Col span={9}>
            <div>{this.props.withdrawInfo.withdraw_info.full_name}</div>
          </Col>
        </Row>
        <Row gutter={24} style={{ marginBottom: 8 }}>
          <Col span={24}>
            <Table
              id="main-table"
              // showSorterTooltip={false}
              bordered
              columns={columns}
              dataSource={this.props.withdrawInfo.deliver_data}
              pagination={false}
              rowKey="phone_number"
              // scroll={{ y: 500 }}
            />
          </Col>
        </Row>
        <Row type="flex" justify="end">
          <Col span={5}>
            <Typography.Text strong>อัตราใช้น้ำมันเมื่อจอด</Typography.Text>
          </Col>
          <Col span={5}>
            {this.props.withdrawInfo.withdraw_info.current_parking_rate}
            &nbsp;ลิตร ต่อ ชม.
          </Col>
          <Col span={5}>
            <Typography.Text strong>อัตราใช้น้ำมันเมื่อขับ</Typography.Text>
          </Col>
          <Col span={4}>
            {this.props.withdrawInfo.withdraw_info.current_driving_rate}
            &nbsp;กม. ต่อ ลิตร
          </Col>
        </Row>
        <Row type="flex" justify="end" style={{ marginBottom: 15 }}>
          <Col span={5}>
            <Typography.Text strong>ระยะเวลาที่จอด</Typography.Text>
          </Col>
          <Col span={5}>
            {this.props.withdrawInfo.withdraw_info.parking_duration}
            &nbsp;ชม.:นาที
          </Col>
          <Col span={5}>
            <Typography.Text strong>ระยะทางวิ่ง</Typography.Text>
          </Col>
          <Col span={4}>
            {this.props.withdrawInfo.withdraw_info.distance}
            &nbsp;กม.
          </Col>
        </Row>
        <Row type="flex" justify="end">
          <Col span={5}>เครดิตที่ยกมา</Col>
          <Col span={3}>
            {this.props.withdrawInfo.withdraw_info.credit_balance}
          </Col>
          <Col span={1}>ลิตร</Col>
        </Row>
        <Row type="flex" justify="end">
          <Col span={5}>เครดิตจากการวิ่งปัจจุบัน</Col>
          <Col span={3}>
            {this.props.withdrawInfo.withdraw_info.current_credit}
          </Col>
          <Col span={1}>ลิตร</Col>
        </Row>
        <Row type="flex" justify="end">
          <Col span={5}>รวมเครดิตน้ำมันทั้งหมด</Col>
          <Col span={3}>{total_credit.toFixed(2)}</Col>
          <Col span={1}>ลิตร</Col>
        </Row>
        <Row
          type="flex"
          justify="center"
          style={{ fontSize: "16px", fontWeight: "800", margin: "15px 0px" }}
        >
          <Col span={6} align="right">
            <Typography.Text>รวมน้ำมันที่จ่ายทั้งหมด</Typography.Text>
          </Col>
          <Col span={2} style={{ margin: "0px 15px" }}>
            {this.props.withdrawInfo.withdraw_info.withdraw_credit}
          </Col>
          <Col span={1}>ลิตร</Col>
        </Row>
        <Row type="flex" justify="start" style={{ marginBottom: 12 }}>
          <Col span={24}>รายละเอียดเพิ่มเติม</Col>
        </Row>
        <Row type="flex" justify="start" style={{ marginBottom: 8 }}>
          <Col span={12} style={{ textAlign: "center" }}>
            ลงชื่อ(พขร.)..................................................(ตัวบรรจง)
          </Col>
          <Col span={12} style={{ textAlign: "center" }}>
            ผู้ออกใบจ่าย(ผลิต)........................................(ตัวบรรจง)
          </Col>
        </Row>
        <Row type="flex" justify="end">
          <Col span={12} style={{ textAlign: "center" }}>
            ลงชื่อตัวบรรจง พร้อมตราประทับแพล้นท์
          </Col>
        </Row>
        <Row type="flex" justify="start" style={{ marginBottom: 50 }}>
          <Col span={12} style={{ textAlign: "center" }}>
            ผู้ออกใบจ่าย(ผรม.ขนส่ง)..................................(ตัวบรรจง)
          </Col>
        </Row>
        <Row type="flex" justify="start" style={{ marginBottom: 8 }}>
          <Col span={24} style={{ textAlign: "start", fontWeight: "800" }}>
            *ห้ามเติมน้ำมันเกินจำนวนจากใบจ่ายน้ำมันอย่างเด็ดขาด
            หากผ่าผืนถือว่าเป็นการทุจริต
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={24} style={{ textAlign: "start", fontWeight: "800" }}>
            *ไม่อนุญาตให้ พขร. เติมน้ำมันนอกถังน้ำมันรถโม่เป็นอันขาด
            หากผ่าผืนถือว่าเป็นการทุจริต
          </Col>
        </Row>
      </Spin>
    );
  }
}
