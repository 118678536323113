import {
    LOAD_MAINTENANCE,
    LOAD_AUTOCOMPLETE_PLATENO,
    LOAD_AUTOCOMPLETE_CODE,
    CREATE_MAINTENANCE,
    CREATE_MAINTENANCE_DETAIL,
    LOAD_MAINTENANCE_DETAIL,
    REMOVE_LISTS
} from "./actions";

const initialState = {
    lists: [],
    listsdetail: [],
    total: 1,
    loading: false,
    autoComplete: [],
    autoCompletePlateNo: [],
    autoCompleteCode: [],
    check: true,
    detail_lists: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REMOVE_LISTS:
            return initialState;

        case LOAD_MAINTENANCE.REQUEST:
            const { loading } = action.payload.data;
            return {
                ...state,
                loading: loading,
            }

        case LOAD_MAINTENANCE.SUCCESS:
            const { page } = action.payload;
                       
            for (let i = 0; i < action.payload.lists.data.length; i++) {
                action.payload.lists.data[i].rowNumber = i + 1;
                    if (page > 1)
                        action.payload.lists.data[i].rowNumber =
                            action.payload.lists.data[i].rowNumber + (page - 1) * 10;
            }
            
            return {
                ...state,
                lists: action.payload.lists.data,
                total: 
                    action.payload.lists.total - 1 <= 0
                    ? 1
                    : action.payload.lists.total <= 0
                    ? 1
                    : action.payload.lists.total,
                    loading: false,
                draw: Math.floor(Math.random() * 999 + 1)
            };

        case LOAD_AUTOCOMPLETE_PLATENO.SUCCESS:
            return {
                ...state,
                autoCompletePlateNo: action.payload
            };
            
        case LOAD_AUTOCOMPLETE_CODE.SUCCESS:
            return {
                ...state,
                autoCompleteCode: action.payload
            };
              
        case CREATE_MAINTENANCE.SUCCESS:
            return {
                ...state,
                loading: false
           };  
        
        case CREATE_MAINTENANCE_DETAIL.REQUEST:
            return {
                ...state,
                loading: true,
            }

        case CREATE_MAINTENANCE_DETAIL.REQUEST:
            return {
                ...state,
                loading: false,
            }

        case LOAD_MAINTENANCE_DETAIL.REQUEST:
            return {
                ...state,
                loading: action.payload.data.loading,
            }

        case LOAD_MAINTENANCE_DETAIL.SUCCESS:
                           
            for (let i = 0; i < action.payload.lists.data.length; i++) {
                action.payload.lists.data[i].rowNumber = i + 1;
                    if (action.payload.page > 1)
                        action.payload.lists.data[i].rowNumber =
                            action.payload.lists.data[i].rowNumber + (action.payload.page - 1) * 10;
            }
                console.log(action.payload)
            return {
                ...state,
                detail_lists: action.payload.lists.data,
                total: 
                    action.payload.lists.total - 1 <= 0
                    ? 1
                    : action.payload.lists.total <= 0
                    ? 1
                    : action.payload.lists.total,
                    loading: false,
                draw: Math.floor(Math.random() * 999 + 1)
                };

        default:
            return state;
    }
}
