import React from "react";
import { Row, Col, Tooltip } from "antd";

const IconMarker = ({
  title,
  lat,
  lng,
  zoomLevel,
  image,
  isshowetitle,
  alwaysShowTooltip = false,
}) => (
  <Row>
    <Col span={24}>
      {isshowetitle ? (
        <Tooltip
          title={title}
          placement="top"
          arrowPointAtCenter
          mouseEnterDelay={0.5}
        >
          <div
            style={{
              position: "relative",
              alignItems: "center",
              zIndex: 1,
              display: "inline-flex",
              transform: "translate(-50%, -100%)",
            }}
          >
            <img
              src={image}
              style={{
                zIndex: 1,
              }}
            />
          </div>
        </Tooltip>
      ) : (
        <div
          style={{
            position: "relative",
            alignItems: "center",
            zIndex: 1,
            display: "inline-flex",
            transform: "translate(-50%, -100%)",
          }}
        >
          <img
            src={image}
            style={{
              zIndex: 1,
            }}
          />
        </div>
      )}
    </Col>
  </Row>
);

export default IconMarker;
