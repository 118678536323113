import { createTypes, createAction } from "lib/action";

const LOAD_VEHICLE_DATA = createTypes("load_vehicle_data", "load");
const LOAD_VEHICLE_DATA_EXPORT = createTypes(
  "load_vehicle_data_export",
  "load"
);
const UPDATE_VEHICLE_TAX_ACT_INSURANCE = createTypes(
  "update_vehicle_tax_act_insurance",
  "update"
);

const IMPORT_DOCUMENT = createTypes("documentmasterdata", "import");

const importDocument = {
  request: (
    file,
    company_id,
    user_id,
    token,
    vehicle_visibility,
    is_super,
    cb
  ) =>
    createAction(IMPORT_DOCUMENT.REQUEST, {
      file,
      company_id,
      user_id,
      token,
      vehicle_visibility,
      is_super,
      cb,
    }),
  success: (data) => createAction(IMPORT_DOCUMENT.SUCCESS, { data }),
  failure: (err) => createAction(IMPORT_DOCUMENT.FAILURE, { err }),
};

const loadVehicleData = {
  request: (data) => createAction(LOAD_VEHICLE_DATA.REQUEST, { data }),
  success: (data, page, pageSize, total) =>
    createAction(LOAD_VEHICLE_DATA.SUCCESS, { data, page, pageSize, total }),
  failure: (err) => createAction(LOAD_VEHICLE_DATA.FAILURE, { err }),
};

const loadVehicleDataExport = {
  request: (data) => createAction(LOAD_VEHICLE_DATA_EXPORT.REQUEST, { data }),
  success: (data, page, pageSize, total) =>
    createAction(LOAD_VEHICLE_DATA_EXPORT.SUCCESS, {}),
  failure: (err) => createAction(LOAD_VEHICLE_DATA_EXPORT.FAILURE, { err }),
};

const updateVehicleTaxActInsurance = {
  request: (data, generateMsg) =>
    createAction(UPDATE_VEHICLE_TAX_ACT_INSURANCE.REQUEST, {
      data,
      generateMsg,
    }),
  success: (data) =>
    createAction(UPDATE_VEHICLE_TAX_ACT_INSURANCE.SUCCESS, { data }),
  failure: (err) =>
    createAction(UPDATE_VEHICLE_TAX_ACT_INSURANCE.FAILURE, { err }),
};

export {
  importDocument,
  IMPORT_DOCUMENT,
  loadVehicleData,
  LOAD_VEHICLE_DATA,
  updateVehicleTaxActInsurance,
  UPDATE_VEHICLE_TAX_ACT_INSURANCE,
  loadVehicleDataExport,
  LOAD_VEHICLE_DATA_EXPORT,
};
