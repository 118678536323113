import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getlocalstorage } from "./../../../constants/local_storage";

import * as actions from "./actions";
import moment from "moment";

function* loadInoutlocation(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );
  const USER_ID = getlocalstorage(localStorage.getItem("profile"), "id");

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  const {
    data: { list_location_id, eventdate, type_file },
  } = action.payload;
  const date_start = moment(eventdate[0]["_d"]).format("YYYY-MM-DD HH:mm");
  const date_end = moment(eventdate[1]["_d"]).format("YYYY-MM-DD HH:mm");

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_GOLANG_MONGO}/reports/go/inoutlocation`,
      {
        date_start,
        date_end,
        list_location_id,
        company_id: COMPANY_ID,
        user_id: USER_ID,
        type_file,
        vehicle_visibility,
      }
    );

    yield put(actions.loadInoutlocation.success(res.data));
    window.location = res.data.result;
  } catch (err) {
    yield put(actions.loadInoutlocation.failure());
  }
}

function* loadLocation(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const location_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "location_visibility"
  );

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlocation`,
      {
        company_id: COMPANY_ID,
        location_visibility,
        name: "",
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadLocation.success(res.data));
  } catch (err) {
    yield put(actions.loadLocation.failure());
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(actions.LOAD_INOUTLOCATION.REQUEST, loadInoutlocation),
    takeEvery(actions.LOAD_LOCATION_INOUTLOCATION.REQUEST, loadLocation),
  ]);
}

export { loadInoutlocation, loadLocation };
