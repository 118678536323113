import { createTypes, createAction } from "lib/action";

const LOAD_DRIVINGLOG = createTypes("drivinglog", "load");
const LOAD_VEHICLE_DRIVINGLOG = createTypes(
  "vehicle_drivinglog",
  "load"
);
const LOAD_VEHICLE_TYPE_DRIVINGLOG = createTypes("vehicletype_drivinglog", "load");

const loadDrivinglog = {

  request: (data, loading) =>
    createAction(LOAD_DRIVINGLOG.REQUEST, { data, loading }),
  success: data => createAction(LOAD_DRIVINGLOG.SUCCESS, { data }),
  failure: () => createAction(LOAD_DRIVINGLOG.FAILURE)
};

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_DRIVINGLOG.REQUEST, {
      vehicletypeID,
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_VEHICLE_DRIVINGLOG.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_DRIVINGLOG.FAILURE)
};

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_DRIVINGLOG.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_TYPE_DRIVINGLOG.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_DRIVINGLOG.FAILURE)
};

export {
  loadDrivinglog,
  LOAD_DRIVINGLOG,
  loadVehicle,
  LOAD_VEHICLE_DRIVINGLOG,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_DRIVINGLOG
};
