import {
  LOAD_TRUCKUSAGE_SJC_MONTHLY,
  LOAD_VEHICLE_TRUCKUSAGE_SJC_MONTHLY,
  LOAD_VEHICLE_TYPE_TRUCKUSAGE_SJC_MONTHLY,
} from "./actions";

const initialState = {
  truckusageUrl: "",
  vehicleLists: [],
  vehicleTypeLists: [],
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TRUCKUSAGE_SJC_MONTHLY.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        loading: loading,
      };
    case LOAD_TRUCKUSAGE_SJC_MONTHLY.SUCCESS:
      return {
        ...state,
        truckusageUrl: action.payload.data.data,
        loading: false,
      };
    case LOAD_VEHICLE_TRUCKUSAGE_SJC_MONTHLY.REQUEST:
      const { loading_vehicle } = action.payload;
      return {
        ...state,
        loading: loading_vehicle,
      };
    case LOAD_VEHICLE_TRUCKUSAGE_SJC_MONTHLY.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        loading: false,
      };
    case LOAD_VEHICLE_TYPE_TRUCKUSAGE_SJC_MONTHLY.SUCCESS:
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data,
      };
    default:
      return state;
  }
};
