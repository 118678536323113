import { createTypes, createAction } from "lib/action";

const LOAD_ALARMALERTBYDURATIONTIME = createTypes(
  "alarmalertbydurationtime",
  "load"
);
const LOAD_VEHICLE_ALARMALERTBYDURATIONTIME = createTypes(
  "vehicle_alarmalertbydurationtime",
  "load"
);
const LOAD_VEHICLE_TYPE_ALARMALERTBYDURATIONTIME = createTypes(
  "vehicle_type_alarmalertbydurationtime",
  "load"
);

const loadAlarmAlertByDurationtime = {
  request: (data, loading) =>
    createAction(LOAD_ALARMALERTBYDURATIONTIME.REQUEST, { data, loading }),
  success: data =>
    createAction(LOAD_ALARMALERTBYDURATIONTIME.SUCCESS, { data }),
  failure: () => createAction(LOAD_ALARMALERTBYDURATIONTIME.FAILURE)
};

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_ALARMALERTBYDURATIONTIME.REQUEST, {
      vehicletypeID,
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_VEHICLE_ALARMALERTBYDURATIONTIME.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_ALARMALERTBYDURATIONTIME.FAILURE)
};

const loadVehicleType = {
  request: () =>
    createAction(LOAD_VEHICLE_TYPE_ALARMALERTBYDURATIONTIME.REQUEST, {}),
  success: data =>
    createAction(LOAD_VEHICLE_TYPE_ALARMALERTBYDURATIONTIME.SUCCESS, { data }),
  failure: () =>
    createAction(LOAD_VEHICLE_TYPE_ALARMALERTBYDURATIONTIME.FAILURE)
};

export {
  loadAlarmAlertByDurationtime,
  LOAD_ALARMALERTBYDURATIONTIME,
  loadVehicle,
  LOAD_VEHICLE_ALARMALERTBYDURATIONTIME,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_ALARMALERTBYDURATIONTIME
};
