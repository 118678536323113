import React, { Component } from "react";
import { Modal, Button, Form, Row, Col, Input, Spin } from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";

import LangContext, { i18n } from "modules/shared/context/langContext";
import { getlocalstorage } from "./../../../../constants/local_storage";

const FormItem = Form.Item;
const { TextArea } = Input;

class Formmail extends Component {
  handleCreateClick = (e, setFieldValue, handleSubmit) => {
    setFieldValue("list_vehicles", this.props.vehicleLists, false);
    handleSubmit();
  };

  render() {
    const { visible, onOk, onCancel, vehicleLists } = this.props;

    const user_email = getlocalstorage(
      localStorage.getItem("profile"),
      "email"
    );

    return (
      <div>
        <Formik
          // enableReinitialize={true}
          initialValues={{
            emails: user_email
          }}
          validate={values => {
            let errors = {};
            if (vehicleLists.length == 0) {
              errors.emails = "กรุณาเลือกรถที่ต้องการส่งเบอร์ติดต่อ";
            }

            return errors;
          }}
          validationSchema={yup.object().shape({
            emails: yup.string().required("กรุณากรอกอีเมล์")
          })}
          onSubmit={values => {
            const loading = true;
            //console.log(values);
            onOk(values, loading);
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleFocus,
            setFieldValue,
            isValidating
          }) => (
            <Modal
              style={{ top: 20 }}
              title={
                <LangContext.Consumer>
                  {i18n => i18n.s.send_email}
                </LangContext.Consumer>
              }
              visible={visible}
              onOk={value =>
                this.handleCreateClick(value, setFieldValue, handleSubmit)
              }
              onCancel={onCancel}
              okText={
                <LangContext.Consumer>
                  {i18n => i18n.o.okay}
                </LangContext.Consumer>
              }
              cancelText={
                <LangContext.Consumer>
                  {i18n => i18n.c.cancel}
                </LangContext.Consumer>
              }
              width={500}
            >
              <Form onSubmit={handleSubmit}>
                <FormItem
                  validateStatus={touched.emails && errors.emails && "error"}
                  help={touched.emails && errors.emails}
                >
                  <TextArea
                    rows={6}
                    onChange={value =>
                      setFieldValue("emails", value.target.value)
                    }
                    value={values.emails}
                  />
                </FormItem>
              </Form>
            </Modal>
          )}
        />
      </div>
    );
  }
}

export default Formmail;
