import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadCompanySetting(action) {
  const {
    data: { accessToken, companyID },
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getcompanysetting`,
      { companyID },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadCompanySetting.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadCompanySetting.failure());
  }
}

function* updateAutoCreateDriver(action) {
  const { accessToken, companyID, setting_is_auto_create_driver } = action.payload.data;
  console.log("action", action)
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/updateautocreatedriver`,
      { companyID, setting_is_auto_create_driver },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.updateAutoCreateDriver.success(res.data));
    action.payload.callback()
  } catch (err) {
    console.log(err.message);
    yield put(actions.updateAutoCreateDriver.failure());
  }
}

function* updateAutoChangeDriver(action) {
  const { accessToken } = action.payload.data;
  const { companyID, setting_is_auto_change_driver } = action.payload.data;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/updateautochangedriver`,
      { companyID, setting_is_auto_change_driver },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.updateAutoChangeDriver.success(res.data));
    action.payload.callback()
  } catch (err) {
    console.log(err.message);
    yield put(actions.updateAutoChangeDriver.failure());
  }
}

function* updateCompanyLineToken(action) {
  const { accessToken } = action.payload.data;
  const { companyID, line_token } = action.payload.data;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/updatecompanylinetoken`,
      { companyID, line_token },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.updateCompanyLineToken.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.updateCompanyLineToken.failure());
  }
}

function* updateTaxActInsuranceLineToken(action) {
  const { accessToken } = action.payload.data;
  const { companyID, line_token } = action.payload.data;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/managedocument/updatetaxactinsurancelinetoken`,
      { companyID, line_token },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.updateTaxActInsuranceLineToken.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.updateTaxActInsuranceLineToken.failure());
  }
}

function* updateFuelLineToken(action) {
  const { accessToken } = action.payload.data;
  const { companyID, line_token } = action.payload.data;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/updatefuellinetoken`,
      { companyID, line_token },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.updateFuelLineToken.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.updateFuelLineToken.failure());
  }
}

function* updateADASLineToken(action) {
  const { accessToken } = action.payload.data;
  const { companyID, line_token } = action.payload.data;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/updateadaslinetoken`,
      { companyID, line_token },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.updateADASLineToken.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.updateADASLineToken.failure());
  }
}

export default function* watchCompany() {
  yield all([
    takeEvery(actions.LOAD_COMPANY_SETTING.REQUEST, loadCompanySetting),
    takeEvery(
      actions.UPDATE_AUTO_CREATE_DRIVER.REQUEST,
      updateAutoCreateDriver
    ),
    takeEvery(
      actions.UPDATE_AUTO_CHANGE_DRIVER.REQUEST,
      updateAutoChangeDriver
    ),
    takeEvery(
      actions.UPDATE_COMPANY_LINE_TOKEN.REQUEST,
      updateCompanyLineToken
    ),
    takeEvery(
      actions.UPDATE_TAX_ACT_INSURANCE_LINE_TOKEN.REQUEST,
      updateTaxActInsuranceLineToken
    ),
    takeEvery(actions.UPDATE_FUEL_LINE_TOKEN.REQUEST, updateFuelLineToken),
    takeEvery(actions.UPDATE_ADAS_LINE_TOKEN.REQUEST, updateADASLineToken),
  ]);
}
