import React from "react";
import { Form, Input, Button, Card } from "antd";
const re = /^[0-9]*\.?[0-9]*$/;

const SettingCalibrateFuel = ({
  settingValue,
  onChangeSettingForm,
  onSubmitSettingCalibrate,
}) => {
  const formItemLayout = {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 12 },
      sm: { span: 12 },
    },
  };

  return (
    <Card
      width="100%"
      style={{
        backgroundColor: "rgb(247, 247, 247)",
        marginTop: 15,
        border: 0,
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        padding: 15,
      }}
    >
      <div style={{ marginBottom: 15, fontSize: 18 }}>ตั้งค่า</div>
      <div style={{ margin: "40px 0px" }}>
        <Form {...formItemLayout}>
          <Form.Item label="ค่าที่จุดสูงสุด ช่วงเติมน้ำมัน">
            <Input
              style={{ width: 275 }}
              value={settingValue.maxFuel}
              onChange={(event) => {
                if (event.target.value === "" || re.test(event.target.value)) {
                  onChangeSettingForm({
                    maxFuel: event.target.value,
                    minFuel: settingValue.minFuel,
                    totalFuel: settingValue.totalFuel,
                    fuelTankSize: settingValue.fuelTankSize,
                    fuelBeforeFullLiter: settingValue.fuelBeforeFullLiter,
                  });
                }
              }}
              onBlur={() => {
                if (
                  !!settingValue.maxFuel &&
                  settingValue.maxFuel.includes(".")
                ) {
                  if (settingValue.maxFuel.slice(-1) == ".") {
                    onChangeSettingForm({
                      maxFuel: settingValue.maxFuel.split(".")[0],
                      minFuel: settingValue.minFuel,
                      totalFuel: settingValue.totalFuel,
                      fuelTankSize: settingValue.fuelTankSize,
                      fuelBeforeFullLiter: settingValue.fuelBeforeFullLiter,
                    });
                  }
                }
              }}
            />
          </Form.Item>

          <Form.Item label="ค่าที่จุดต่ำสุด ช่วงเติมน้ำมัน">
            <Input
              value={settingValue.minFuel}
              style={{ width: 275 }}
              onChange={(event) => {
                if (event.target.value === "" || re.test(event.target.value)) {
                  onChangeSettingForm({
                    maxFuel: settingValue.maxFuel,
                    minFuel: event.target.value,
                    totalFuel: settingValue.totalFuel,
                    fuelTankSize: settingValue.fuelTankSize,
                    fuelBeforeFullLiter: settingValue.fuelBeforeFullLiter,
                  });
                }
              }}
              onBlur={() => {
                if (
                  !!settingValue.minFuel &&
                  settingValue.minFuel.includes(".")
                ) {
                  if (settingValue.minFuel.slice(-1) == ".") {
                    onChangeSettingForm({
                      maxFuel: settingValue.maxFuel,
                      minFuel: settingValue.minFuel.split(".")[0],
                      totalFuel: settingValue.totalFuel,
                      fuelTankSize: settingValue.fuelTankSize,
                      fuelBeforeFullLiter: settingValue.fuelBeforeFullLiter,
                    });
                  }
                }
              }}
            />
          </Form.Item>

          <Form.Item label="จำนวนลิตรที่เติม">
            <Input
              value={settingValue.fuelTankSize}
              style={{ width: 275 }}
              onChange={(event) => {
                if (event.target.value === "" || re.test(event.target.value)) {
                  onChangeSettingForm({
                    maxFuel: settingValue.maxFuel,
                    minFuel: settingValue.minFuel,
                    totalFuel: settingValue.totalFuel,
                    fuelTankSize: event.target.value,
                    fuelBeforeFullLiter: settingValue.fuelBeforeFullLiter,
                  });
                }
              }}
              onBlur={() => {
                if (
                  !!settingValue.fuelTankSize &&
                  settingValue.fuelTankSize.includes(".")
                ) {
                  if (settingValue.fuelTankSize.slice(-1) == ".") {
                    onChangeSettingForm({
                      maxFuel: settingValue.maxFuel,
                      minFuel: settingValue.minFuel,
                      totalFuel: settingValue.totalFuel,
                      fuelTankSize: settingValue.fuelTankSize.split(".")[0],
                      fuelBeforeFullLiter: settingValue.fuelBeforeFullLiter,
                    });
                  }
                }
              }}
            />{" "}
            ลิตร
          </Form.Item>

          <Form.Item label="ขนาดถังน้ำมัน">
            <Input
              value={settingValue.totalFuel}
              style={{ width: 275 }}
              onChange={(event) => {
                if (event.target.value === "" || re.test(event.target.value)) {
                  onChangeSettingForm({
                    maxFuel: settingValue.maxFuel,
                    minFuel: settingValue.minFuel,
                    totalFuel: event.target.value,
                    fuelTankSize: settingValue.fuelTankSize,
                    fuelBeforeFullLiter: settingValue.fuelBeforeFullLiter,
                  });
                }
              }}
              onBlur={() => {
                if (
                  !!settingValue.totalFuel &&
                  settingValue.totalFuel.includes(".")
                ) {
                  if (settingValue.totalFuel.slice(-1) == ".") {
                    onChangeSettingForm({
                      maxFuel: settingValue.maxFuel,
                      minFuel: settingValue.minFuel,
                      totalFuel: settingValue.totalFuel.split(".")[0],
                      fuelTankSize: settingValue.fuelTankSize,
                      fuelBeforeFullLiter: settingValue.fuelBeforeFullLiter,
                    });
                  }
                }
              }}
            />{" "}
            ลิตร
          </Form.Item>

          <Form.Item wrapperCol={{ span: 12, offset: 5 }}>
            <Button type="primary" onClick={onSubmitSettingCalibrate}>
              ตั้งค่า
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Card>
  );
};

export default SettingCalibrateFuel;
