const checkNull = (val, key) => (val === null ? "" : val[key]);

function getlocalstorage(localstorage, key) {
  return checkNull(JSON.parse(localstorage), key);
}

const ACCESSTOKEN =
  localStorage.getItem("access-token") === null
    ? ""
    : localStorage.getItem("access-token");

const COMPANY_ID = checkNull(
  JSON.parse(localStorage.getItem("profile")),
  "company_id"
);

const USER_ID = checkNull(JSON.parse(localStorage.getItem("profile")), "id");

const USERNAME = checkNull(
  JSON.parse(localStorage.getItem("profile")),
  "username"
);

const VUC = checkNull(
  JSON.parse(localStorage.getItem("profile")),
  "vehicle_visibility"
);

const PERMISSIONS = checkNull(
  JSON.parse(localStorage.getItem("profile")),
  "permissions"
);

const EMAIL = checkNull(JSON.parse(localStorage.getItem("profile")), "email");

const FIRSTNAME = checkNull(
  JSON.parse(localStorage.getItem("profile")),
  "firstname"
);

const LASTNAME = checkNull(
  JSON.parse(localStorage.getItem("profile")),
  "lastname"
);

export {
  COMPANY_ID,
  USER_ID,
  USERNAME,
  VUC,
  EMAIL,
  FIRSTNAME,
  LASTNAME,
  PERMISSIONS,
  ACCESSTOKEN,
  getlocalstorage
};
