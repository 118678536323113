import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb as AntBreadcrumb, Icon } from "antd";
//import LangContext, { i18n } from "context/langContext";

const Breadcrumb = ({ match, sub_menu, ...props }) => (
    <AntBreadcrumb {...props}>
        <AntBreadcrumb.Item key="breadcrumb-home">
            <Link to={`/`}>
                <Icon type="home" />
            </Link>
        </AntBreadcrumb.Item>
        <AntBreadcrumb.Item key="breadcrumb-report">
            report
        </AntBreadcrumb.Item>
        <AntBreadcrumb.Item key="breadcrumb-submenu">
            {sub_menu}
        </AntBreadcrumb.Item>
        { match.path
            .replace("_", " ")
            .split("/")
            .map((ele, i,arr) => {
            
            if(arr.length-1 ==i){
                return (
                    <AntBreadcrumb.Item key={`breadcrumb-${ele}`}>
                        <Link to={match.path}>
                            {ele}
                        </Link>
                    </AntBreadcrumb.Item>
                ) 
            }
            })}
    </AntBreadcrumb>
);
export default Breadcrumb;
