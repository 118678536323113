import {
  LOAD_DOWNLOADCERTIFICATE
} from "./actions";

const initialState = {
  downloadcertificateLists: [],
  downloadcertificateTotal: 0,
  downloadcertificateLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DOWNLOADCERTIFICATE.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        downloadcertificateLoading: loading
      };
    case LOAD_DOWNLOADCERTIFICATE.SUCCESS:
      return {
        ...state,
        downloadcertificateLists: action.payload.data.data,
        downloadcertificateTotal: action.payload.data.total,
        downloadcertificateLoading: false
      };
    default:
      return state;
  }
};
