import {
  LOAD_TRIPSUMMARY,
  LOAD_VEHICLE_TRIPSUMMARY,

} from "./actions";

const initialState = {
  tripsummaryUrl: "",
  vehicleLists: [],
  tripsummaryLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TRIPSUMMARY.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        tripsummaryLoading: loading
      };
    case LOAD_TRIPSUMMARY.SUCCESS:

      return {
        ...state,
        tripsummaryUrl: action.payload.data.data,
        tripsummaryLoading: false
      };
    case LOAD_VEHICLE_TRIPSUMMARY.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        tripsummaryLoading: false
        //  loading: false
      };
    default:
      return state;
  }
};
