import { Table, Input, Button, Popconfirm, Form,Icon,Modal,Spin,Tooltip } from 'antd';
import React, {  Fragment }  from "react";
import {EditableCell,EditableFormRow,EditableContext} from "./EditableCell";
import "../../../../styled/editable.css";
import LangContext, { i18n } from "modules/shared/context/langContext";
import axios from "axios";
import { connect } from "react-redux";
import { sendForm } from 'emailjs-com';

class EditableTable extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
          editingKey: '',
          dataSource: [],
          count: 0,
          orderBy: "seq",
          orderType: "asc",
          skuLoading:false,
          skuActionLoading:false,
          weightuom:[],
          uom:[],
          skuuom:[],
          btAdd:true,
    };
       
    }
  

    componentDidMount() {
      this.getWeightUom();
      this.getUom();
      this.getSkuUom();
      this.loadSku();
      
    }

    shouldComponentUpdate(nextProps, nextState) {
    
      if (
        this.props.product.check_edit !==
        nextProps.product.check_edit
      ) {
        this.loadSku();
        this.getSkuUom();
        this.setState({btAdd:true})
        //console.log("loadsku")
      }
      return true;
    }

    getWeightUom = () => {
      let self = this;
      let company_id = this.props.auth.profile.company_id;
      let token = this.props.auth.accessToken;
      axios
        .post(
          `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getweightuom`,
          {
            company_id,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(function(response) {
          if (typeof response.data.data !== "undefined") {
            let weightuom = response.data.data.map((d) => {
              return {
                id: d.id,
                name: i18n[self.props.auth.lang]["weight_uom"][d.name_key],
              };
            });
            self.setState({ weightuom });
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    };


    getUom = () => {
      let self = this;
      let company_id = this.props.auth.profile.company_id;
      let token = this.props.auth.accessToken;
      axios
        .post(
          `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getuom`,
          {
            company_id,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(function(response) {
          if (typeof response.data.data !== "undefined") {
            let uom = response.data.data.map((d) => {
              return {
                id: d.id,
                name: d.name,
              };
            });
            self.setState({ uom });
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    };


    
    getSkuUom = () => {
      let self = this;
      let company_id = this.props.auth.profile.company_id;
      let product_id = this.props.match.params.id;
      let token = this.props.auth.accessToken;
      axios
        .post(
          `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getskuuombyproduct`,
          {
            product_id,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(function(response) {
          if (typeof response.data.data !== "undefined") {
            let skuuom = response.data.data.map((d) => {
              return {
                id: d.id,
                name: d.name,
              };
            });
            self.setState({ skuuom });
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    };
  

    loadSku = () => {
      let self = this;
      let token = this.props.auth.accessToken;
      let product_id = this.props.match.params.id;
      this.setState({skuLoading:true})
      axios
        .post(
          `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/sku`,
          {
            product_id
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(function(response) {
          if (typeof response.data.data !== "undefined") {
            let dataSource = response.data.data;
            let count = response.data.count;
            self.setState({ dataSource,count });
          }
          self.setState({skuLoading:false})
        })
        .catch(function(error) {
          console.log(error);
        });
    };

    handleDelete = key => {
      let self = this;
      let token = this.props.auth.accessToken;

      axios
        .get(
          `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/sku/delete/${key}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then(function (response) {
          self.loadSku();
          self.getSkuUom();
        })
        .catch(function (error) {
          console.log(error);
        });
    };
  
    handleAdd = () => {
      const { count, dataSource } = this.state;
      const newData = {
        seq:0,
        key: 0,
        uom_name: "",
        description: "",
        weight: "",
        weight_uom: "",
        quality:"",
        from_uom_name: "",
        from_uom_id:"",
        uom_id:"",
        weight_uom_id:""

      };
      this.setState({
        dataSource: [...dataSource, newData],
        count: count + 1,
        btAdd:false,
        editingKey: 0
      });
    };
  
    cancel = (key) => {
      if(key===0)
      {
        const dataSource = [...this.state.dataSource];
        this.setState({ dataSource: dataSource.filter(item => item.key !== key) ,editingKey: '',btAdd:true});
      }else{
        this.setState({ editingKey: '',btAdd:true });
      }
     
    };

    edit(key) {
      this.setState({ editingKey: key, btAdd:false });
    }

    isEditing = record => record.key === this.state.editingKey;

    getEditable = (record,col) =>{
      let editable = col.editable;
      //console.log("seq:",record.seq,col);
      if(record.seq===1){
        if(col.dataIndex==="uom_name"){
          editable = false
        }else if(col.dataIndex==="weight"){
          editable = true
        }else if(col.dataIndex==="weight_uom"){
          editable = true
        }else if(col.dataIndex==="quality"){
          editable = false
        }else if(col.dataIndex==="from_uom_name"){
          editable = false
        }
        
      }
      return editable;
    }


    getInputType = (col) =>{
      let inputtype = "text";
    
        if(col.dataIndex==="uom_name"){
          inputtype = "select";
        }else if(col.dataIndex==="weight"){
          inputtype = "number";
        }else if(col.dataIndex==="weight_uom"){
          inputtype = "select";
        }else if(col.dataIndex==="quality"){
          inputtype = "number";
        }else if(col.dataIndex==="from_uom_name"){
          inputtype = "select";
        }
        
      return inputtype;
    }

    save(form, key) {
      form.validateFields((error, row) => {
        if (error) {
          return;
        }
       // console.log("row:",row);
        let token = this.props.auth.accessToken;
        let product_id = this.props.match.params.id;
        const self = this;
      
        const newData = [...this.state.dataSource];
        const index = newData.findIndex(item => key === item.key);
        const item = newData[index];

        this.setState({skuActionLoading:true});
        if(key===0)
        {
          axios
          .post(
            `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/sku/create`,
            {
              quality: row.quality,
              product_id:product_id,
              uom_id:row.uom_name,
              from_uom_id:row.from_uom_name,
              description:row.description,
              weight_uom_id:"",
              weight:""
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then(function(res) {
            
            if (res.data.status === "success") {
              self.loadSku();
              self.getSkuUom();
              self.setState({
                btAdd: true,
                editingKey: ''
              });
            } else {
           
                Modal.error({
                  content: (
                    i18n[self.props.auth.lang]["f"]["fail"]
                  ),
                });
             
            }
      
           self.setState({skuActionLoading:false});
            
          })
          .catch(function(error) {
            console.log(error);
          });
      
      
        }else{
          axios
          .post(
            `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/sku/update`,
            {
              quality: row.quality==undefined?"":row.quality,
              product_id:product_id,
              uom_id:row.uom_name==undefined?item.uom_id:row.uom_name,
              weight_uom_id:row.weight_uom==undefined?"":row.weight_uom,
              from_uom_id:row.from_uom_name==undefined?"":row.from_uom_name,
              description:row.description==undefined?"":row.description,
              weight:row.weight==undefined?"":row.weight,
              seq:item.seq,
              id: key,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then(function(res) {
            
            if (res.data.status === "success") {
              self.loadSku();
              self.getSkuUom();
              self.setState({
                btAdd: true,
                editingKey: ''
              });
            } else {
           
                Modal.error({
                  content: (
                    i18n[self.props.auth.lang]["f"]["fail"]
                  ),
                });
             
            }
      
           self.setState({skuActionLoading:false});
            
          })
          .catch(function(error) {
            console.log(error);
          });
      
        }
       
      });
    }

    handleSave = row => {
      const newData = [...this.state.dataSource];
      const index = newData.findIndex(item => row.key === item.key);
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      this.setState({ dataSource: newData });
    };
  
    render() {
       this.columns = [
        {
          title:<Button disabled={!this.state.btAdd} onClick={this.handleAdd} type="primary">
              <LangContext.Consumer>{(i18n) => i18n.a.add}</LangContext.Consumer></Button>,
          dataIndex: 'operation',
          render: (text, record) =>{
            

            const { editingKey } = this.state;
            //console.log("edit",this.state.editingKey);
            const editable = this.isEditing(record);
            return (<div>
                    {editable ? (
                    <span>
                      <EditableContext.Consumer>
                        {form => (
                             <Tooltip
                             placement="left"
                             title={
                               <LangContext.Consumer>
                                 {(i18n) => i18n.s.save}
                               </LangContext.Consumer>
                             }
                             arrowPointAtCenter
                             style={{
                               padding: "0px 1px 0px 0px",
                               marginLeft: "5px",
                               marginRight: "5px",
                             }}
                           >
                          <a
                            onClick={() => this.save(form, record.key)}
                            style={{ marginRight: 8 }}
                          >
                           <Icon type="save" />
                          </a>
                          </Tooltip>
                        )}
                      </EditableContext.Consumer>
                      <Popconfirm title={
                        <LangContext.Consumer>
                          {(i18n) => i18n.c.confirmToCancelThisItem}
                        </LangContext.Consumer>
                      } 
                      onConfirm={() => this.cancel(record.key)}>
                           <Tooltip
                    placement="left"
                    title={
                      <LangContext.Consumer>
                        {(i18n) => i18n.c.cancel}
                      </LangContext.Consumer>
                    }
                    arrowPointAtCenter
                    style={{
                      padding: "0px 1px 0px 0px",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  >
                        <a style={{ marginRight: 8 }}>
                        <Icon type="close" />
                        </a>
                        </Tooltip>
                      </Popconfirm>
                    </span>
                  ) : (
                    <Tooltip
                    placement="left"
                    title={
                      <LangContext.Consumer>
                        {(i18n) => i18n.e.edit}
                      </LangContext.Consumer>
                    }
                    arrowPointAtCenter
                    style={{
                      padding: "0px 1px 0px 0px",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  >
                    <a style={{ marginRight: 8 }} disabled={editingKey !== ''} onClick={() => this.edit(record.key)}>  
                       <Icon type="edit" />
                    </a>
                    </Tooltip>
                  )}
              {this.state.dataSource.length >= 1 && record.seq!==1 && record.seq!==0? (
              <Popconfirm title={
                <LangContext.Consumer>
                  {(i18n) => i18n.c.confirmToDeleteThisItem}
                </LangContext.Consumer>
              }  onConfirm={() => this.handleDelete(record.key)}>
                 <Tooltip
                  placement="left"
                  title={
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.delete}
                    </LangContext.Consumer>
                  }
                  arrowPointAtCenter
                  style={{
                    padding: "0px 1px 0px 0px",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                <a  style={{ marginRight: 8 }}><Icon type="delete" /></a>
                </Tooltip>
              </Popconfirm>
            ) : null}
            
           
            </div>
            )
         }
        },
        {
          title: (
            <LangContext.Consumer>
              {(i18n) => i18n.u.uom}
            </LangContext.Consumer>
          ),
          dataIndex: "uom_name",
          width: '15%',
          require:true,
          editable: true,
          dict:"sku",
          dict_value:"uom_require"
        },
        {
          title: (
            <LangContext.Consumer>
              {(i18n) => i18n.d.description}
            </LangContext.Consumer>
          ),
          dataIndex: "description",
          width: '20%',
          require:false,
          editable: true,
          dict:"d",
          dict_value:"description"
        },
        {
          title: (
            <LangContext.Consumer>
              {(i18n) => i18n.sku.weight}
            </LangContext.Consumer>
          ),
          dataIndex: "weight",
          width: '10%',
          require:false,
          editable: false,
          dict:"sku",
          dict_value:"weight_require"
        },
        {
          title: (
            <LangContext.Consumer>
              {(i18n) => i18n.weight_uom.weight_uom}
            </LangContext.Consumer>
          ),
          dataIndex: "weight_uom",
          width: '15%',
          require:true,
          editable: false,
          dict:"weight_uom",
          dict_value:"weight_uom_require",
          render(text, record, index) {
            return {
              children: <div> <LangContext.Consumer>
              {(i18n) => i18n.weight_uom[text]}
            </LangContext.Consumer></div>,
            };
          },
        },
        {
          title: (
            <Fragment><LangContext.Consumer>
            {(i18n) => i18n.sku.quality}
          </LangContext.Consumer><span style={{color:"red"}}>*</span></Fragment>
          ),
          dataIndex: "quality",
          width: '10%',
          require:true,
          editable: true,
          dict:"sku",
          dict_value:"quality_require"
        },
        {
          title: (
            <Fragment><LangContext.Consumer>
            {(i18n) => i18n.sku.from_uom}
          </LangContext.Consumer><span style={{color:"red"}}>*</span></Fragment>
          ),
          dataIndex: "from_uom_name",
          width: '15%',
          require:true,
          editable: true,
          dict:"sku",
          dict_value:"from_uom_require"
        },
        
      ];  
      const { dataSource,weightuom,uom,skuuom} = this.state;
      const components = {
        body: {
          row: EditableFormRow,
          cell: EditableCell,
        },
      };
      //console.log("colume",this.columns);
      const columns = this.columns.map(col => {
      
        // if (!editable) {
        //   return col;
        // }
       
        return {
          ...col,
          onCell: record => ({
            record,
            editable: this.getEditable(record,col),
            dataIndex: col.dataIndex,
            title: col.title,
            require:col.require,
            dict:col.dict,
            dictValue:col.dict_value,
            inputType: this.getInputType(col),
            handleSave: this.handleSave,
            editing: this.isEditing(record),
            weightuom,
            uom,
            skuuom,
          }),
        };
      });
      return (
        <div>
          <Spin spinning={this.state.skuActionLoading}>
          <EditableContext.Provider value={this.props.form}>
            <Table
              size="small"
              components={components}
              rowClassName={() => 'editable-row'}
              bordered
              dataSource={dataSource}
              columns={columns}
              rowKey={(record) => record.id}
              pagination={false}
              loading={this.state.skuLoading}
            />
           </EditableContext.Provider>
           </Spin>
        </div>
        
      );
    }
  }
  
  const mapStateToProps = ({
    auth,
  }) => ({
    auth,
  });
  
  
  
  export default connect(mapStateToProps, null)(EditableTable);