import {
  LOAD_DAILY,
  LOAD_VEHICLE_DAILY,
  LOAD_VEHICLE_TYPE_DAILY
} from "./actions";

const initialState = {
  dailyUrl: "",
  vehicleLists: [],
  vehicleTypeLists: [],
  dailyLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DAILY.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        dailyLoading: loading
      };
    case LOAD_DAILY.SUCCESS:

      return {
        ...state,
        dailyUrl: action.payload.data.data,
        dailyLoading: false
      };
    case LOAD_VEHICLE_DAILY.REQUEST:
      const { loading_vehicle } = action.payload;
      return {
        ...state,
        dailyLoading: loading_vehicle
      };
    case LOAD_VEHICLE_DAILY.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        dailyLoading: false
      };
    case LOAD_VEHICLE_TYPE_DAILY.SUCCESS:
      // console.log(action.payload.data.data);
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data
      };
    default:
      return state;
  }
};
