import React, { Component, Fragment } from 'react'
import { Row, Col, Button, Spin, Icon, Dropdown, Menu, message } from "antd";
import ReactPlayer from "react-player";
import axios from "axios";

export default class VideoWebsocket extends Component {
    state = {
        iframeLoading1: true,
        idprocess: "web" + new Date().getTime(),
        streamUrl: "",
        iFrameHeight: window.innerHeight - 150,
        camearaText: "กล้อง 1",
        cameraIndex: 1,
        playing: true
    }

    ws = new WebSocket(
        `wss://${process.env.REACT_APP_WEBSOCKET}/ws?idprocess=` +
        this.state.idprocess
    );

    hideSpinner1 = () => {
        this.setState({
            iframeLoading1: false,
        });
    };

    handleMenuClick = (e) => {
        this.setState({
            camearaText: `กล้อง ${e.key}`,
            cameraIndex: parseInt(e.key),
            iframeLoading1: true
        }, this.checkConnection)
    }

    sendMessage = () => {
        const data = JSON.stringify({ type: "video_realtime", camera_source: this.state.cameraIndex });
        const message = {
            Idconnect: this.props.imei,
            Idprocess: this.state.idprocess,
            Data: data,
        };
        const send = JSON.stringify(message)
        console.log("sendMessage", send)
        this.ws.send(JSON.stringify(message));
        this.props.loading()
    }

    checkConnection = async () => {
        if (this.props.imei === "") {
            return
        }

        const url = `https://${process.env.REACT_APP_WEBSOCKET}/getAllConnection?idprocess=${this.state.idprocess}`
        await axios.get(url).then((res) => {
            if (res.data.message !== "") {
                let connect = res.data.message.split(",")
                if (connect.includes(this.props.imei)) {
                    this.sendMessage()
                }
            } else {
                message.error("device not online")
            }
        });
    };

    componentDidMount() {
        this.ws.onopen = () => {
            console.log("connected");
            this.checkConnection()
        };

        this.ws.onmessage = (evt) => {
            // listen to data sent from the websocket server
            // const message = JSON.parse(evt.data)
            var messages = evt.data.split("\n");

            for (var i = 0; i < messages.length; i++) {
                let obj = JSON.parse(messages[i]);

                if (obj.Idprocess !== "" && obj.Idconnect !== "") {
                    let data = JSON.parse(obj.Data);
                    console.log("onmessage", data)
                    //rtmp://test:password@nimble.terminusfleet.com:1935/live/2/867016066034909 
                    this.setState({
                        iframeLoading1: false,
                        streamUrl: `${process.env.REACT_APP_NIMBLE_URL}/live/${this.state.cameraIndex}/${this.props.imei}/playlist.m3u8`
                    })
                }
            };
        }

        this.ws.onclose = () => {
            console.log("disconnected");
        };

        // send command every 3 minute
        this.interval = setInterval(() => {
            this.checkConnection()
        }, 180000);
    }

    componentWillUnmount() {
        this.ws.close();
        clearInterval(this.interval);
    }

    render() {
        const menu = (
            <Menu onClick={this.handleMenuClick}>
                <Menu.Item key="1">
                    กล้อง 1
                </Menu.Item>
                <Menu.Item key="2">
                    กล้อง 2
                </Menu.Item>
                <Menu.Item key="3">
                    กล้อง 3
                </Menu.Item>
                <Menu.Item key="4">
                    กล้อง 4
                </Menu.Item>
            </Menu>
        );

        return (
            <Fragment>
                <div style={{
                    position: "absolute",
                    right: "2%",
                    marginTop: "24px",
                    zIndex: "12"
                }}>
                    <Dropdown overlay={menu}>
                        <Button>
                            {this.state.camearaText} <Icon type="down" />
                        </Button>
                    </Dropdown>
                </div>
                <Row>
                    <Col span={24}>
                        <Spin spinning={this.state.iframeLoading1}>
                            <ReactPlayer
                                url={this.state.streamUrl}
                                onReady={() => { }}
                                playing={true}
                                loop={true}
                                style={{ "background": "black" }}
                                width="100%"
                                height={this.props.height + "px"}
                                controls={true}
                                allowFullScreen
                            >
                            </ReactPlayer>
                        </Spin>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
