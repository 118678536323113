import { createTypes, createAction } from "lib/action";

const LOAD_TRIPSUMMARY = createTypes("tripsummary", "load");
const LOAD_VEHICLE_TRIPSUMMARY = createTypes(
  "vehicle_tripsummary",
  "load"
);

const loadTripsummary = {
  request: (data, loading) =>
    createAction(LOAD_TRIPSUMMARY.REQUEST, { data, loading }),
  success: data => createAction(LOAD_TRIPSUMMARY.SUCCESS, { data }),
  failure: () => createAction(LOAD_TRIPSUMMARY.FAILURE)
};

const loadVehicle = {
  request: () => createAction(LOAD_VEHICLE_TRIPSUMMARY.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_TRIPSUMMARY.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TRIPSUMMARY.FAILURE)
};

export {
  loadTripsummary,
  LOAD_TRIPSUMMARY,
  loadVehicle,
  LOAD_VEHICLE_TRIPSUMMARY
};
