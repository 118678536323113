const jsonDetailSkeleton = {
  speed_limit: {
    value_yellow: 90,
    value_red: 120,
    duration: 0,
  },
  speed_limit_area: {
    value_yellow: 90,
    value_red: 120,
    duration: 0,
    area: null,
  },
  no_entry: {
    duration: 0,
    area: null,
  },
  no_exit: {
    duration: 0,
    area: null,
  },
  stop: {
    duration: 0,
  },
  stop_with_area: {
    duration: 0,
    apply_area: "INSIDE",
    area: [],
  },
  stop_engine_on: {
    duration: 0,
  },
  stop_engine_on_with_area: {
    duration: 0,
    apply_area: "INSIDE",
    area: [],
  },
  abnormal_temperature: {
    max: 25,
    min: -25,
  },
};

export { jsonDetailSkeleton };
