import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getlocalstorage } from "./../../../constants/local_storage";

import * as actions from "./actions";
import moment from "moment";

function* loadTaxiFare(action) {
  
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );
  const VEHICLE_VISIBILITY = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  const {
    data: {eventdate, type_file }
  } = action.payload;
  const date_start = moment(eventdate[0]["_d"]).format("YYYY-MM-DD");
  const date_end = moment(eventdate[1]["_d"]).format("YYYY-MM-DD");
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/taxifare`,
      {
        date_start,
        date_end,
        company_id: COMPANY_ID,
        type_file,
        vehicle_visibility: VEHICLE_VISIBILITY,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadTaxiFare.success(res.data));
    window.location = res.data.data;
  } catch (err) {
    yield put(actions.loadTaxiFare.failure());
  }
}

export default function* watchTaxifare() {
  yield all([
    takeEvery(
      actions.LOAD_TAXIFARE.REQUEST,loadTaxiFare
    ),
  ]);
}

export { loadTaxiFare };
