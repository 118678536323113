import React, { Component } from "react";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";
import { Col, Icon, Row, Spin, Dropdown, Select, Menu, Button } from "antd";
import Iframe from "react-iframe";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import * as actions from "../actions";
import { connect } from "react-redux";
import axios from "axios";
import { headers, baseUrl } from "lib/fetch";

const initialValues = {
  display_id: "",
  group_id: "",
  vehicle_list: [],
  loading: false,
};
const colorCode = ["#91FFDE", "#E291FF", "#91BDFF", "#91DEFF", "#ADFF91"];
class Index extends Component {
  state = { ...initialValues };
  componentDidMount() {
    this.props.load({ user_id: this.props.profile.id });
  }
  handleSelectDisplayChange = (v) => {
    this.props.loadVideo(
      {
        user_id: this.props.profile.id,
        display_id: v,
        group_id: "",
      },
      (data) => this.setState({ vehicle_list: data.data })
    );
    this.setState({ display_id: v, group_id: "" });
  };
  handleSelectGroupChange = (v) => {
    this.props.loadVideo({
      user_id: this.props.profile.id,
      group_id: v,
      display_id: this.state.display_id,
    });
    this.setState({ group_id: v });
  };

  handleFullScreen = () => {
    document.getElementById("video-container").requestFullscreen();
  };
  switchTracksolidCamera = async (value) => {
    const ACCESSTOKEN = localStorage.getItem("access-token");
    this.setState({ loading: true }, async () => {
      const res = await axios.post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/dashboardvideorealtime/switchTrackSolidCamera`,
        value,
        {
          headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
        }
      );
      this.setState({
        loading: false,
      });
    });
  };
  switchCamera = (data) => {
    let tracksolidVideo = [];
    let formattedData = data.map((value) => {
      return value.map((row) => {
        return row.map((col) => {
          let temp = col;
          if (
            temp.model == "/iothub/iothub43.php" ||
            temp.model == "/iothub/index.php"
          ) {
            let camera = col.url.split("camera=");
            let newcamera = "OUT";
            if (camera[1] === "OUT") {
              newcamera = "IN";
            }
            temp.url = camera[0] + "camera=" + newcamera;
            temp.camera = newcamera;
          } else {
            tracksolidVideo.push(temp.hardware_id);
          }
          return temp;
        });
      });
    });
    this.switchTracksolidCamera(tracksolidVideo);
    console.log("tracksolidVideo", tracksolidVideo);
    this.setState({ vehicle_list: formattedData });
  };
  render() {
    const layout = this.props.dashboardmonitoring.countAll <= 9 ? 3 : 4;
    const height = "calc(100%/" + layout + ")";
    const span = layout === 3 ? 8 : 6;
    const menu = (
      <Menu>
        {this.props.dashboardmonitoring.groupInfo.map((v, index) => {
          return (
            <Menu.Item style={{ cursor: "default" }}>
              {/* <div
                key={index}
                className="point"
                
              ></div> */}
              <Icon
                type="border"
                style={{
                  color: colorCode[index],
                  width: "12px",
                  height: "12px",
                  backgroundColor: colorCode[index],
                }}
              />
              <div style={{ display: "inline" }}>{v}</div>
            </Menu.Item>
          );
        })}
      </Menu>
    );

    return (
      <AuthorizeComponent matching_name="dashboard-monitoring">
        <Spin
          spinning={
            this.props.dashboardmonitoring.loading || this.state.loading
          }
        >
          <GeneralStyledContent>
            <Row gutter={24}>
              <Col span={24}>
                <Breadcrumb
                  customeurl={["/dashboard-monitoring", null, null]}
                  match={this.props.match}
                  style={{ margin: "0px 0px 14px 10px" }}
                />
              </Col>
            </Row>
            <Row type="flex" justify="space-between" style={{ margin: "8px" }}>
              <Col>
                <Select
                  showSearch
                  defaultValue={["-"]}
                  style={{ width: 200, marginRight: "5px" }}
                  onChange={this.handleSelectDisplayChange}
                >
                  {this.props.dashboardmonitoring.displayList.map((v) => {
                    return (
                      <Select.Option key={v.display_id} value={v.display_id}>
                        {v.display_name}
                      </Select.Option>
                    );
                  })}
                </Select>
                {/* <Select
                  showSearch
                  defaultValue={["-"]}
                  value={this.state.group_id}
                  style={{ width: 200 }}
                  onChange={this.handleSelectGroupChange}
                >
                  <Select.Option key={99} value={""}>
                    -
                  </Select.Option>
                  {this.props.dashboardmonitoring.groupData.map((v, index) => {
                    return (
                      <Select.Option key={index} value={v.id}>
                        {v.name}
                      </Select.Option>
                    );
                  })}
                </Select> */}
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Dropdown overlay={menu} icon={<Icon type="info-circle" />}>
                  {/* <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    
                  </a> */}

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Icon
                      type="info-circle"
                      style={{
                        fontSize: "22px",
                        marginRight: "10px",
                        color: "#989898",
                        border: "8px solid #989898",
                        borderRadius: "50px",
                      }}
                    />
                    ข้อมูลกลุ่ม
                  </div>
                </Dropdown>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.switchCamera(this.state.vehicle_list);
                  }}
                >
                  <Icon
                    type="video-camera"
                    style={{
                      fontSize: "18px",
                      margin: "0px 10px",
                      color: "#FFFFFF",
                      border: "10px solid #989898",
                      borderRadius: "50px",
                      backgroundColor: "#989898",
                    }}
                  />
                  เปลี่ยนมุมกล้อง
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Icon
                    type="fullscreen"
                    onClick={this.handleFullScreen}
                    style={{
                      fontSize: "18px",
                      margin: "0px 10px",
                      color: "#FFFFFF",
                      border: "10px solid #989898",
                      borderRadius: "50px",
                      backgroundColor: "#989898",
                    }}
                  />
                  เต็มจอ
                </div>
              </Col>
            </Row>
            <Row
              gutter={[4, 4]}
              style={{ height: "calc(100vh - 144px)" }}
              id="video-container"
            >
              {this.state.vehicle_list.map((v, index) => (
                // {this.props.dashboardmonitoring.videoList.map((v, index) => (vehicle_list
                <Col style={{ height: height }}>
                  <Row
                    gutter={[4, 4]}
                    style={{ height: "100%" }}
                    id="video-container"
                  >
                    {v.map((v1, index1) => {
                      let color =
                        colorCode[
                          this.props.dashboardmonitoring.groupInfo.indexOf(
                            v1[0].name
                          )
                        ];
                      return (
                        <Col
                          span={span * v1.length}
                          style={{
                            padding: "0px",
                            height: "100%",
                            borderRadius: "18.5px",
                            display: "flex",
                            border: "3px solid " + color,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              width: "100%",
                            }}
                          >
                            {v1.map((v2) => (
                              <div
                                style={{
                                  display: "block",
                                  overflow: "hidden",
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "15px",
                                  transform: "translateZ(0px)",
                                  // border: "2px solid rgba(255,255,255,0)",
                                }}
                              >
                                <Iframe
                                  url={v2.url}
                                  width="100%"
                                  height="100%"
                                  scrolling="no"
                                  frameBorder={0}
                                  onLoad={true}
                                />
                                {/* {v2.url} */}
                              </div>
                            ))}
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              ))}
            </Row>
          </GeneralStyledContent>
        </Spin>
      </AuthorizeComponent>
    );
  }
}
const mapStateToProps = ({ auth, auth: { profile }, dashboardmonitoring }) => ({
  auth,
  profile,
  dashboardmonitoring,
});

const mapDispatchToProps = {
  load: actions.loadDisplay.request,
  loadVideo: actions.loadVideo.request,
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
