import {
  LOAD_SPEEDOVERLIMITSUMMARY,
  LOAD_VEHICLE_SPEEDOVERLIMITSUMMARY,
  LOAD_VEHICLE_TYPE_SPEEDOVERLIMITSUMMARY
} from "./actions";

const initialState = {
  speedoverlimitsummarylogUrl: "",
  vehicleLists: [],
  vehicleTypeLists: [],
  speedoverlimitsummaryLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SPEEDOVERLIMITSUMMARY.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        speedoverlimitsummaryLoading: loading
      };
    case LOAD_SPEEDOVERLIMITSUMMARY.SUCCESS:

      return {
        ...state,
        speedoverlimitsummaryUrl: action.payload.data.data,
        speedoverlimitsummaryLoading: false
      };
    case LOAD_VEHICLE_SPEEDOVERLIMITSUMMARY.REQUEST:
      const { loading_vehicle } = action.payload;
      return {
        ...state,
        speedoverlimitsummaryLoading: loading_vehicle
      };
    case LOAD_VEHICLE_SPEEDOVERLIMITSUMMARY.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        speedoverlimitsummaryLoading: false
      };
    case LOAD_VEHICLE_TYPE_SPEEDOVERLIMITSUMMARY.SUCCESS:
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data
      };
    default:
      return state;
  }
};
