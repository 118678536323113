import { createTypes, createAction } from "lib/action";
const LOAD_MASTERDATA_ROUTE = createTypes("masterdataroute", "load");
const CREATE_MASTERDATA_ROUTE = createTypes("masterdataroute", "create");
const UPDATE_MASTERDATA_ROUTE = createTypes("masterdataroute", "update");
const LOAD_LOCATION = createTypes("location", "load");
const LOAD_DETAIL_MASTERDATA_ROUTE = createTypes("detailmasterdataroute", "load");
const LOAD_DETAIL_MASTERDATA_ROUTE_EDIT = createTypes("detailmasterdatarouteedit", "load");
const LOAD_AUTOCOMPLETE = createTypes("autocomplete", "load");
const SELECTED_AUTOCOMPLETE = createTypes("autocomplete", "selected");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
    return {
        type: REMOVE_LISTS
    };
}

const loadMasterRoute = {
    request: (data, loading) =>
        createAction(LOAD_MASTERDATA_ROUTE.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(LOAD_MASTERDATA_ROUTE.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_MASTERDATA_ROUTE.FAITLURE)  
};

const  loadLocation = {
    request: (data, loading) =>
        createAction(LOAD_LOCATION.REQUEST, { data, loading}),
    success: (lists) =>
        createAction(LOAD_LOCATION.SUCCESS, {lists}),
    failure: () => createAction(LOAD_LOCATION.FAITLURE)  
};
const createMasterDataRoutes = {
    request: (data, loading) =>
        createAction(CREATE_MASTERDATA_ROUTE.REQUEST, { data, loading}),
    success: res => createAction(CREATE_MASTERDATA_ROUTE.SUCCESS, { res }),
        failure: () => createAction(CREATE_MASTERDATA_ROUTE.FAILURE)
};  

const loadDetailMasterDetail = {
    request: (data, loading) =>
        createAction(LOAD_DETAIL_MASTERDATA_ROUTE.REQUEST, { data, loading}),
    success: (lists) =>
        createAction(LOAD_DETAIL_MASTERDATA_ROUTE.SUCCESS, {lists}),
    failure: () => createAction(LOAD_DETAIL_MASTERDATA_ROUTE.FAITLURE)  
};

const loadDetailMasterDetailEdit = {
    request: (data, loading) =>
        createAction(LOAD_DETAIL_MASTERDATA_ROUTE_EDIT.REQUEST, { data, loading}),
    success: (lists) =>
        createAction(LOAD_DETAIL_MASTERDATA_ROUTE_EDIT.SUCCESS, {lists}),
    failure: () => createAction(LOAD_DETAIL_MASTERDATA_ROUTE_EDIT.FAITLURE)  
};

const updateroute = {
    request: (data, loading) =>
        createAction(UPDATE_MASTERDATA_ROUTE.REQUEST, { data, loading}),
    success: res => createAction(UPDATE_MASTERDATA_ROUTE.SUCCESS, { res }),
        failure: () => createAction(UPDATE_MASTERDATA_ROUTE.FAILURE)
}

const loadAutocompleteLocation = {
    request: (url, companyID, accessToken, queryString) =>
      createAction(LOAD_AUTOCOMPLETE.REQUEST, {
        url,
        companyID,
        accessToken,
        queryString
      }),
    success: lists => createAction(LOAD_AUTOCOMPLETE.SUCCESS, { lists }),
    failure: () => createAction(LOAD_AUTOCOMPLETE.FAILURE)
};

function selectedAutocompleteLocation(id, lists, selectedName, cb) {
    return {
      type: SELECTED_AUTOCOMPLETE,
      payload: { id, lists, selectedName, cb }
    };
}

export {
    LOAD_MASTERDATA_ROUTE,
    loadMasterRoute,
    loadLocation,
    LOAD_LOCATION,
    createMasterDataRoutes,
    CREATE_MASTERDATA_ROUTE,
    loadDetailMasterDetail,
    LOAD_DETAIL_MASTERDATA_ROUTE,
    loadDetailMasterDetailEdit,
    LOAD_DETAIL_MASTERDATA_ROUTE_EDIT,
    updateroute,
    UPDATE_MASTERDATA_ROUTE,
    loadAutocompleteLocation,
    LOAD_AUTOCOMPLETE,
    selectedAutocompleteLocation,
    SELECTED_AUTOCOMPLETE,
    REMOVE_LISTS,
    removeLists
};