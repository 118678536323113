import { LOAD_COMPAREVEHICLESCCO } from "./actions";

const initialState = {
  comparevehiclesccostatusLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_COMPAREVEHICLESCCO.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        comparevehiclesccostatusLoading: loading
      };
    case LOAD_COMPAREVEHICLESCCO.SUCCESS:
      return {
        ...state,
        comparevehiclesccostatusLoading: false
      };
    default:
      return state;
  }
};
