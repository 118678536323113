import {
  EXPORT_INPUTACTIVITY,
  LOAD_VEHICLE_INPUTACTIVITY,
  LOAD_VEHICLE_TYPE_INPUTACTIVITY,
} from './actions'

const initialState = {
  reporLink: '',
  vehicleLists: [],
  vehicleTypeLists: [],
  loading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case EXPORT_INPUTACTIVITY.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        loading: loading,
      }
    case EXPORT_INPUTACTIVITY.SUCCESS:
      return {
        ...state,
        reporLink: action.payload.data.data,
        loading: false,
      }
    case LOAD_VEHICLE_INPUTACTIVITY.REQUEST:
      const { loading_vehicle } = action.payload
      return {
        ...state,
        loading: loading_vehicle,
      }
    case LOAD_VEHICLE_INPUTACTIVITY.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        loading: false,
      }
    case LOAD_VEHICLE_TYPE_INPUTACTIVITY.SUCCESS:
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data,
      }
    default:
      return state
  }
}
