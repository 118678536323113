import axios from "axios";
import { all, put, call, takeEvery } from "redux-saga/effects";
import { getErrorMsg } from "lib/fetch";

import * as actions from "./actions";

function* loadManageDashboard(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { text } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/managedashboardvideorealtime/getdisplaylist`,
      text,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(actions.loadManageDashboard.success(res.data.data));
  } catch (err) {
    yield put(actions.loadManageDashboard.failure());
  }
}

function* createManageDashboard(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { text, generateMsg } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/managedashboardvideorealtime/createdisplay`,
      text,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(actions.createManageDashboard.success(res.data));
  } catch (err) {
    yield put(actions.createManageDashboard.failure(getErrorMsg(err)));
  }
  generateMsg();
}

function* updateManageDashboard(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { text, generateMsg } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/managedashboardvideorealtime/updatedisplay`,
      text,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(actions.updateManageDashboard.success(res.data));
  } catch (err) {
    yield put(actions.updateManageDashboard.failure(getErrorMsg(err)));
  }
  generateMsg();
}

function* deleteManageDashboard(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { text, generateMsg } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/managedashboardvideorealtime/deletedisplay`,
      text,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(actions.deleteManageDashboard.success(res.data));
  } catch (err) {
    yield put(actions.deleteManageDashboard.failure(getErrorMsg(err)));
  }
  generateMsg();
}

function* loadManageDashboardUser(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { text } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/datavisibility/getuser/` +
        text,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(actions.loadManageDashboardUser.success(res.data));
  } catch (err) {
    yield put(actions.loadManageDashboardUser.failure());
  }
}

function* loadLayoutDisplay(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { text } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/managedashboardvideorealtime/getlayout`,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(actions.loadLayoutDisplay.success(res.data));
  } catch (err) {
    yield put(actions.loadLayoutDisplay.failure());
  }
}

function* loadDisplayDetail(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { text } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/managedashboardvideorealtime/getdisplaydetail`,
      text,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(actions.loadDisplayDetail.success(res.data));
  } catch (err) {
    yield put(actions.loadDisplayDetail.failure());
  }
}

export default function* watchmanagedashboard() {
  yield all([
    takeEvery(actions.LOAD_MANAGE_DASHBOARD.REQUEST, loadManageDashboard),
    takeEvery(
      actions.LOAD_MANAGE_DASHBOARD_USER.REQUEST,
      loadManageDashboardUser
    ),
    takeEvery(actions.LOAD_LAYOUT_DISPLAY.REQUEST, loadLayoutDisplay),
    takeEvery(actions.CREATE_MANAGE_DASHBOARD.REQUEST, createManageDashboard),
    takeEvery(actions.UPDATE_MANAGE_DASHBOARD.REQUEST, updateManageDashboard),
    takeEvery(actions.DELETE_MANAGE_DASHBOARD.REQUEST, deleteManageDashboard),
    takeEvery(actions.LOAD_DISPLAY_DETAIL.REQUEST, loadDisplayDetail),
  ]);
}
export {
  loadManageDashboard,
  loadManageDashboardUser,
  loadLayoutDisplay,
  createManageDashboard,
  loadDisplayDetail,
};
