import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";

import * as actions from "../actions";
import Form from "./Forms";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "./../../../shared/components/Breadcrumb";

class Edit extends Component {
  componentDidMount() {
    this.props.getUom(this.props.match.params.id,this.props.auth.accessToken);
  }

  edit = (values, loading) => {
    values.company_id = this.props.auth.profile.company_id;
    values.token = this.props.auth.accessToken;
    this.props.updateUom(
      values,
      this.props.match.params.id,
      this.props.history,
      loading
    );
  };

  render() {
    return (
      <GeneralStyledContent>
        <Row gutter={24}>
          <Col span={24}>
            <Breadcrumb
              match={this.props.match}
              style={{ margin: "0px 0px 14px 10px" }}
            />
          </Col>
        </Row>
        <Form
          onSubmit={this.edit}
          uom={this.props.uom}
          id={this.props.match.params.id}
          auth={this.props.auth}
         
        />
      </GeneralStyledContent>
    );
  }
}

const mapStateToProps = ({
  uom,
  auth,
}) => ({
  uom,
  auth,
});

const mapDispatchToProps = {
  updateUom: actions.updateUom.request,
  getUom: actions.getUomByID.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
