import React, { Component } from "react";
import { Modal, Button, Form, Row, Col, Transfer, Select } from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";

import LangContext, { i18n } from "modules/shared/context/langContext";
import { DEFAULT_FILTER_VALUE } from "../../tracking/constants/tracking";

const FormItem = Form.Item;
const Option = Select.Option;

class Filter extends Component {
  state = {
    vehicleList: [],
    vehicleSelect: [],
    selectedKeys: [],
  };

  componentDidMount() {
    const { loadVehicle } = this.props;
    loadVehicle();
  }

  componentWillReceiveProps = (nextProps) => {
    const { vehicleLists } = nextProps;
    const vehlist = [];
    vehicleLists.map((item) =>
      vehlist.push({
        key: item.id,
        name: item.plate_no,
      })
    );

    this.setState({ vehicleList: vehlist });
  };

  tranferChange = (targetKeys, setFieldValue) => {
    setFieldValue("list_vehicle_id", targetKeys);
    this.setState({ vehicleSelect: targetKeys });
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    });
  };

  handlePreviewClick = (e, setFieldValue, handleSubmit) => {
    setFieldValue("orderBy", "plate_no", false);
    setFieldValue("orderType", "asc", false);
    handleSubmit();
  };

  handleVehicleStatusSelectboxChange = (event, setFieldValue) => {
    this.setState({
      vehicle_status: event,
    });
    setFieldValue("vehicle_status", event);
  };
  handleDeviceTypeSelectboxChange = (event, setFieldValue) => {
    this.setState({
      device_type: event,
    });
    setFieldValue("device_type", event);
  };

  render() {
    const { visible, onOk, onCancel, vehicleLists, filter } = this.props;

    return (
      <div>
        <div>
          <Formik
            enableReinitialize
            initialValues={{
              list_vehicle_id: filter.list_vehicle_id,
              vehicle_status: filter.vehicle_status,
              device_type: filter.device_type,
            }}
            validate={(values) => {
              let errors = {};

              return errors;
            }}
            validationSchema={yup.object().shape({
              // list_vehicle_id: yup.array().required("กรุณาเลือกรถ")
            })}
            onSubmit={(values) => {
              const loading = true;
              onOk(values, loading);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleFocus,
              setFieldValue,
              isValidating,
            }) => (
              <Modal
                style={{ top: 20 }}
                title={
                  <LangContext.Consumer>
                    {(i18n) => i18n.f.filter}
                  </LangContext.Consumer>
                }
                visible={visible}
                onOk={(value) =>
                  this.handlePreviewClick(value, setFieldValue, handleSubmit)
                }
                onCancel={onCancel}
                okText={
                  <LangContext.Consumer>
                    {(i18n) => i18n.o.okay}
                  </LangContext.Consumer>
                }
                cancelText={
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.cancel}
                  </LangContext.Consumer>
                }
                width={700}
              >
                <Row type="flex" justify="center">
                  <Form onSubmit={handleSubmit}>
                    <FormItem
                      label={
                        <LangContext.Consumer>
                          {(i18n) => i18n.v.vehicle}
                        </LangContext.Consumer>
                      }
                      validateStatus={
                        touched.list_vehicle_id &&
                        errors.list_vehicle_id &&
                        "error"
                      }
                      help={touched.list_vehicle_id && errors.list_vehicle_id}
                    >
                      <Transfer
                        titles={[
                          <LangContext.Consumer>
                            {(i18n) => i18n.i.itemLeft}
                          </LangContext.Consumer>,
                          <LangContext.Consumer>
                            {(i18n) => i18n.i.itemSelected}
                          </LangContext.Consumer>,
                        ]}
                        dataSource={this.state.vehicleList}
                        showSearch
                        listStyle={{
                          width: 250,
                          height: 300,
                        }}
                        selectedKeys={this.state.selectedKeys}
                        operations={[">>", "<<"]}
                        targetKeys={this.state.vehicleSelect}
                        onSelectChange={this.handleSelectChange}
                        onChange={(value) =>
                          this.tranferChange(value, setFieldValue)
                        }
                        render={(item) => `${item.name}`}
                        footer={this.renderFooter}
                      />
                    </FormItem>

                    <Form.Item
                      label={
                        <LangContext.Consumer>
                          {(i18n) => i18n.v.vehicleStatus}
                        </LangContext.Consumer>
                      }
                    >
                      <Select
                        mode="multiple"
                        defaultValue={DEFAULT_FILTER_VALUE.vehicleStatus}
                        width="100%"
                        style={{ width: "100%" }}
                        onChange={(value) =>
                          this.handleVehicleStatusSelectboxChange(
                            value,
                            setFieldValue
                          )
                        }
                        value={values.vehicle_status}
                      >
                        <Option key="running" value="running">
                          {
                            <LangContext.Consumer>
                              {(i18n) => i18n.r.running}
                            </LangContext.Consumer>
                          }
                        </Option>
                        <Option
                          key="stop-with-engine-on"
                          value="stop-with-engine-on"
                        >
                          {
                            <LangContext.Consumer>
                              {(i18n) => i18n.s.stopWithEngineOn}
                            </LangContext.Consumer>
                          }
                        </Option>
                        <Option
                          key="stop-with-engine-off"
                          value="stop-with-engine-off"
                        >
                          {
                            <LangContext.Consumer>
                              {(i18n) => i18n.e.engineOff}
                            </LangContext.Consumer>
                          }
                        </Option>
                        <Option key="connection-error" value="connection-error">
                          {
                            <LangContext.Consumer>
                              {(i18n) => i18n.l.lostConnectio}
                            </LangContext.Consumer>
                          }
                        </Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label={
                        <LangContext.Consumer>
                          {(i18n) => i18n.v.vehicleStatus}
                        </LangContext.Consumer>
                      }
                    >
                      <Select
                        mode="multiple"
                        width="100%"
                        style={{ width: "100%" }}
                        onChange={(value) =>
                          this.handleDeviceTypeSelectboxChange(
                            value,
                            setFieldValue
                          )
                        }
                        value={values.device_type}
                      >
                        <Option key="J40" value="J40">
                          J40
                        </Option>
                        <Option key="HNT" value="HNT">
                          HNT
                        </Option>
                        <Option key="ISU" value="ISU">
                          ISU
                        </Option>
                      </Select>
                    </Form.Item>
                  </Form>
                </Row>
              </Modal>
            )}
          />
        </div>
      </div>
    );
  }
}

export default Filter;
