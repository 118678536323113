import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Form, Input, Row, Col, Select } from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
const FormItem = Form.Item;


export default class Filter extends Component {



  render() {
    const { OnClose, onSubmit } = this.props;

    return (
      <div>
        <Formik
          initialValues={{
            name: ""

          }}
          validationSchema={yup.object().shape({
          })}
          onSubmit={values => {
            const loading = true;
            onSubmit(values, loading);
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleFocus,
            setFieldValue,
            resetForm
          }) => (
              <Row>
                <LangContext.Consumer>
                  {
                    i18n => <Form onSubmit={handleSubmit}>
                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.u.uom_name}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.name &&
                          errors.name &&
                          "error"
                        }
                        help={touched.name && errors.name}
                      >
                        <Input
                          name="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                        />
                      </FormItem>



                      <FormItem>
                        <Row gutter={24}>
                          <Col span={3}>
                            <Button         
                              onClick={OnClose}          
                            >
                              {i18n.c.cancel}
                            </Button>
                          </Col>
                          <Col span={1} />
                          <Col span={3}>
                            <Button htmlType="submit" type="primary">
                              {i18n.a.apply}
                            </Button>
                          </Col>
                        </Row>
                      </FormItem>
                    </Form>
                  }
                </LangContext.Consumer>
              </Row>
            )}
        />
      </div>
    );
  }
}
