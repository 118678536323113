import { createTypes, createAction } from "lib/action";

const LOAD_WITHDRAW_FUEL_CREDIT_REPORT = createTypes(
  "load_withdraw_fuel_credit_report",
  "load"
);
const LOAD_VEHICLE_BY_PLANT = createTypes("load_vehicle_by_plant", "load");
const LOAD_PLANT_BY_KEYWORD = createTypes("load_plant_by_keyword", "load");

const loadWithdrawFuelCreditReport = {
  request: (obj) =>
    createAction(LOAD_WITHDRAW_FUEL_CREDIT_REPORT.REQUEST, { obj }),
  success: (data) =>
    createAction(LOAD_WITHDRAW_FUEL_CREDIT_REPORT.SUCCESS, { data }),
  failure: () => createAction(LOAD_WITHDRAW_FUEL_CREDIT_REPORT.FAILURE),
};
const loadVehicleByPlant = {
  request: (obj) => createAction(LOAD_VEHICLE_BY_PLANT.REQUEST, { obj }),
  success: (data) => createAction(LOAD_VEHICLE_BY_PLANT.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_BY_PLANT.FAILURE),
};

const loadPlantByKeyword = {
  request: (obj) => createAction(LOAD_PLANT_BY_KEYWORD.REQUEST, { obj }),
  success: (data) => createAction(LOAD_PLANT_BY_KEYWORD.SUCCESS, { data }),
  failure: () => createAction(LOAD_PLANT_BY_KEYWORD.FAILURE),
};

export {
  loadWithdrawFuelCreditReport,
  LOAD_WITHDRAW_FUEL_CREDIT_REPORT,
  loadVehicleByPlant,
  LOAD_VEHICLE_BY_PLANT,
  loadPlantByKeyword,
  LOAD_PLANT_BY_KEYWORD,
};
