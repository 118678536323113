import {
  LOAD_UOM,
  CREATE_UOM,
  UPDATE_UOM,
  CLEAR_UOM,
  GET_UOM,
  CLEAR_RESPONSE,
} from "./actions";

const initialState = {
  uomLists: [],
  uomLoading: false,
  uomTotal: 0,
  dataResponse: { status: "", msg: "", id: "", action_type: "" },
  check_uom: true,
  dataUom: {
    id: "",
    name: "",
    description: "",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_UOM.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        uomLoading: loading
      };
    case LOAD_UOM.SUCCESS:
      return {
        ...state,
        uomLists: action.payload.data.data,
        uomLoading: false,
        uomTotal: action.payload.data.total
      };
    case CREATE_UOM.REQUEST:
      const { loading: loading2 } = action.payload;
      return {
        ...state,
        uomLoading: loading2
      };
    case CREATE_UOM.SUCCESS:
      return {
        ...state,
        dataResponse: { status: action.payload.data.status, msg: action.payload.data.msg, action_type: action.payload.data.action_type },
        uomLoading: false
      };
    case UPDATE_UOM.REQUEST:
      const { loading: loading3 } = action.payload;
      return {
        ...state,
        uomLoading: loading3
      };
    case UPDATE_UOM.SUCCESS:
      return {
        ...state,
        dataResponse: { status: action.payload.data.status, msg: action.payload.data.msg, action_type: action.payload.data.action_type },
        uomLoading: false
      };

    case GET_UOM.REQUEST:
      return {
        ...state,
        check_uom: true,
      };
    case GET_UOM.SUCCESS:
      return {
        ...state,
        dataUom: action.payload.data.data,
        uomLoading: false,
        check_uom: false,
      };
   
    case CLEAR_RESPONSE:
      return {
        ...state,
        dataResponse: { status: "", msg: "", id: "", action_type: "" }
      };
    case CLEAR_UOM:
      return {
        ...state,
        dataUom: {
          id: "",
          name: "",
          description: "",
        }
      };
    default:
      return state;
  }
};
