import {
  LOAD_SCHEDULEREPORT,
  CREATE_SCHEDULEREPORT,
  UPDATE_SCHEDULEREPORT,
  CLEAR_SCHEDULEREPORT,
  GET_SCHEDULEREPORT,
  LOAD_REPORT_SCHEDULEREPORT,
  CLEAR_RESPONSE,
} from "./actions";

const initialState = {
  schedulereportLists: [],
  schedulereportLoading: false,
  schedulereportTotal: 0,
  dataResponse: { status: "", msg: "", id: "", action_type: "" },
  check_schedulereport: true,
  dataSchedulereport: {
    id: "",
    email_subject: "",
    to_email: "",
    cc_email: "",
    list_report_schedules_id: "",
    attachment_type: "",
    schedule_type: "",
    month: "",
    day: "",
    week: "",
    time: "",
    start_time: "",
    end_time: "",
    body_email: "",
    previous_data: "",
    filter: "",
    matching_name: ""
  },
  reportLists: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SCHEDULEREPORT.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        schedulereportLoading: loading
      };
    case LOAD_SCHEDULEREPORT.SUCCESS:
      return {
        ...state,
        schedulereportLists: action.payload.data.data,
        schedulereportLoading: false,
        schedulereportTotal: action.payload.data.total
      };
    case CREATE_SCHEDULEREPORT.REQUEST:
      const { loading: loading2 } = action.payload;
      return {
        ...state,
        schedulereportLoading: loading2
      };
    case CREATE_SCHEDULEREPORT.SUCCESS:
      return {
        ...state,
        dataResponse: { status: action.payload.data.status, msg: action.payload.data.msg, action_type: action.payload.data.action_type },
        schedulereportLoading: false
      };
    case UPDATE_SCHEDULEREPORT.REQUEST:
      const { loading: loading3 } = action.payload;
      return {
        ...state,
        schedulereportLoading: loading3
      };
    case UPDATE_SCHEDULEREPORT.SUCCESS:
      return {
        ...state,
        dataResponse: { status: action.payload.data.status, msg: action.payload.data.msg, action_type: action.payload.data.action_type },
        schedulereportLoading: false
      };

    case GET_SCHEDULEREPORT.REQUEST:
      return {
        ...state,
        check_schedulereport: true,
      };
    case GET_SCHEDULEREPORT.SUCCESS:
      return {
        ...state,
        dataSchedulereport: action.payload.data.data,
        schedulereportLoading: false,
        check_schedulereport: false,
      };
    case LOAD_REPORT_SCHEDULEREPORT.SUCCESS:
      return {
        ...state,
        reportLists: action.payload.data.data
      };
    case CLEAR_RESPONSE:
      return {
        ...state,
        dataResponse: { status: "", msg: "", id: "", action_type: "" }
      };
    case CLEAR_SCHEDULEREPORT:
      return {
        ...state,
        dataSchedulereport: {
          id: "",
          plate_no: "",
          pull_vehicle_sink_date: ""
        }
      };
    default:
      return state;
  }
};
