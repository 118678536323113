import React from "react";
import { Switch, Route } from "react-router-dom";
import VoiceManagement from "./Index";
import VoiceManagementCreate from "./Create";
import VoiceManagementEdit from "./Edit";

export default () => (
  <Switch>
    <Route exact path="/voice-management" component={VoiceManagement} />
    <Route
      exact
      path="/voice-management/create"
      component={VoiceManagementCreate}
    />
    <Route
      exact
      path="/voice-management/edit/:id"
      component={VoiceManagementEdit}
    />
  </Switch>
);
