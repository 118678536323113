import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { COMPANY_ID, USER_ID } from "./../../../constants/local_storage";
import * as actions from "./actions";
import { fetcher, getErrorMsg, handleError } from "lib/fetch";
import { push } from "connected-react-router";


function* importTrip(action) {
  const { file, company_id,user_id, token,vehicle_visibility,is_super,cb} = action.payload;
  try {
    
    const formData = new FormData();
    formData.append("file", file.originFileObj);
    formData.append("company_id", company_id);
    formData.append("user_id", user_id);
    formData.append("vehicle_visibility",vehicle_visibility);
    formData.append("is_super",is_super);
    const res = yield fetcher.upload(`trip/import`, formData, token);
    cb();
    yield put(actions.importTrip.success(res.data));
    yield put(push("/trip"));
   
  } catch (error) {
    handleError(error);
    cb();
    yield put(actions.importTrip.failure(getErrorMsg(error)));
  }
}


function* loadTrip(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      company_id,
      orderBy,
      orderType,
      vehicle_visibility,
      filterObj,
      token,
    },
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/trip`,
      // `http://booking-api/api/trip`,
      {
        searchName,
        page,
        pageSize,
        companyID: company_id,
        orderBy,
        orderType,
        vehicle_visibility,
        filterObj,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    yield put(actions.loadTrip.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadTrip.failure());
  }
}

function* loadTripDetail(action) {
  const {
    data: { id, token, pageAction },
    cb,
  } = action.payload;
  // const company_id =
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/trip/detail/${id}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    // cb(res.data[0]);
    let payload = { data: { ...res.data.data, page_action: pageAction } };
    console.log(res.data, payload);
    yield put(actions.loadTripDetail.success(payload));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadTripDetail.failure());
  }
}

function* createTrip(action) {
  const {
    data: {
      trip_code,
      vehicle_id,
      driver_id,
      plant_id,
      site_id,
      start_deliver_at,
      trip_type,
      token,
      company_id,
    },
    cb,
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/trip/create`,
      {
        company_id,
        trip_code,
        vehicle_id,
        driver_id,
        plant_id,
        site_id,
        start_deliver_at,
        trip_type,
        created_by: USER_ID,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    cb(res.data);
    yield put(actions.createTrip.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.createTrip.failure());
  }
}

function* updateTrip(action) {
  const {
    data: {
      trip_id,
      trip_code,
      vehicle_id,
      driver_id,
      plant_id,
      site_id,
      start_deliver_at,
      trip_type,
      token,
      company_id,
    },
    cb,
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/trip/update`,
      {
        trip_id,
        company_id,
        trip_code,
        vehicle_id,
        driver_id,
        plant_id,
        site_id,
        start_deliver_at,
        trip_type,
        updated_by: USER_ID,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    cb();

    yield put(actions.updateTrip.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.updateTrip.failure());
  }
}

export default function* watchTripState() {
  yield all([
    takeEvery(actions.CREATE_TRIP.REQUEST, createTrip),
    takeEvery(actions.LOAD_TRIP.REQUEST, loadTrip),
    takeEvery(actions.LOAD_TRIP_DETAIL.REQUEST, loadTripDetail),
    takeEvery(actions.UPDATE_TRIP.REQUEST, updateTrip),
    takeEvery(actions.IMPORT_TRIP.REQUEST, importTrip),
  ]);
}

export { createTrip, loadTrip, loadTripDetail, updateTrip,importTrip };
