import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import Index from "./Index";
import Create from "./Create";
import Edit from "./Edit";

export default () => (
  <Router>
    <Route
      exact
      path="/manage-dashboard-monitoring/create"
      component={Create}
    />
    <Route
      exact
      path="/manage-dashboard-monitoring/edit/:id"
      component={Edit}
    />
    <Route exact path="/manage-dashboard-monitoring/" component={Index} />
  </Router>
);
