import { createTypes, createAction } from "lib/action";

const LOAD_ALERTDRIVERLICENSE = createTypes("alertdriverlicense", "load");
const LOAD_DRIVER_ALERTDRIVERLICENSE = createTypes("driver_alertdriverlicense", "load");

const loadAlertDriverLicense = {
  request: (data, loading) =>
    createAction(LOAD_ALERTDRIVERLICENSE.REQUEST, { data, loading }),
  success: data => createAction(LOAD_ALERTDRIVERLICENSE.SUCCESS, { data }),
  failure: () => createAction(LOAD_ALERTDRIVERLICENSE.FAILURE)
};

const loadDriver = {
  request: () => createAction(LOAD_DRIVER_ALERTDRIVERLICENSE.REQUEST, {}),
  success: data => createAction(LOAD_DRIVER_ALERTDRIVERLICENSE.SUCCESS, { data }),
  failure: () => createAction(LOAD_DRIVER_ALERTDRIVERLICENSE.FAILURE)
};

export {
  LOAD_ALERTDRIVERLICENSE,
  LOAD_DRIVER_ALERTDRIVERLICENSE,
  loadAlertDriverLicense,
  loadDriver
};
