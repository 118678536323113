import {
  LOAD_VEHICLEINAREA,
  LOAD_VEHICLE_VEHICLEINAREA,
  LOAD_VEHICLE_TYPE_VEHICLEINAREA,
  LOAD_LOCATION_VEHICLEINAREA,
} from "./actions";

const initialState = {
  vehicleLists: [],
  vehicleTypeLists: [],
  vehicleinareaLoading: false,
  locationLists: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_VEHICLEINAREA.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        vehicleinareaLoading: loading
      };
    case LOAD_VEHICLEINAREA.SUCCESS:

      return {
        ...state,
        vehicleinareaLoading: false
      };
    case LOAD_VEHICLE_VEHICLEINAREA.REQUEST:
      const { loading_vehicle } = action.payload;
      return {
        ...state,
        vehicleinareaLoading: loading_vehicle
      };
    case LOAD_VEHICLE_VEHICLEINAREA.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        vehicleinareaLoading: false
      };
    case LOAD_VEHICLE_TYPE_VEHICLEINAREA.SUCCESS:
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data
      };
    case LOAD_LOCATION_VEHICLEINAREA.SUCCESS:
      return {
        ...state,
        locationLists: action.payload.data.data
      };
    default:
      return state;
  }
};
