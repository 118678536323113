import { createTypes, createAction } from "lib/action";

const LOAD_DELIVERYITEMDELETEHISTORY = createTypes(
  "deliveryitemdeletehistorymasterdata",
  "load"
);
const LOAD_DELIVERYITEMDELETEHISTORY_DETAIL = createTypes(
  "deliveryitemdeletehistorydetailmasterdata",
  "load"
);
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
  return {
    type: REMOVE_LISTS
  };
}
const loadDeliveryItemDeleteHistory = {
  request: (data, loading) =>
    createAction(LOAD_DELIVERYITEMDELETEHISTORY.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_DELIVERYITEMDELETEHISTORY.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_DELIVERYITEMDELETEHISTORY.FAILURE)
};

const loadDeliveryItemDeleteHistoryDetail = {
  request: (data, cb) =>
    createAction(LOAD_DELIVERYITEMDELETEHISTORY_DETAIL.REQUEST, { data, cb }),
  success: deliveryitem =>
    createAction(LOAD_DELIVERYITEMDELETEHISTORY_DETAIL.SUCCESS, {
      deliveryitem
    }),
  failure: () => createAction(LOAD_DELIVERYITEMDELETEHISTORY_DETAIL.FAILURE)
};

export {
  loadDeliveryItemDeleteHistory,
  LOAD_DELIVERYITEMDELETEHISTORY,
  REMOVE_LISTS,
  removeLists,
  LOAD_DELIVERYITEMDELETEHISTORY_DETAIL,
  loadDeliveryItemDeleteHistoryDetail
};
