import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { ACCESSTOKEN } from "./../../../constants/local_storage";
import * as actions from "./actions";

function* loadPolicy(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      orderBy,
      orderType,
      vehicle_visibility,
      filterObj,
      accessToken,
    },
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/policy`,
      // `http://booking-api/api/policy`,
      {
        searchName,
        page,
        pageSize,
        companyID,
        orderBy,
        orderType,
        vehicle_visibility,
        filterObj,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.loadPolicy.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadPolicy.failure());
  }
}

function* loadPolicyDetail(action) {
  const {
    data,
    data: { id },
    cb,
  } = action.payload;
  // const company_id =
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/policy/detail/${id}`,
      { headers: { Authorization: `Bearer ${data.accessToken}` } }
    );
    // cb(res.data[0]);

    yield put(actions.loadPolicyDetail.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadPolicyDetail.failure());
  }
}

function* createPolicy(action) {
  const { data, cb } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/policy/create`,
      {
        ...data,
      },
      { headers: { Authorization: `Bearer ${data.accessToken}` } }
    );
    cb(res.data);
    yield put(actions.createPolicy.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.createPolicy.failure());
  }
}

function* updatePolicy(action) {
  const { data, cb } = action.payload;
  console.log(data);
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/policy/update`,
      {
        ...data,
      },
      { headers: { Authorization: `Bearer ${data.accessToken}` } }
    );
    cb();

    yield put(actions.updatePolicy.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.updatePolicy.failure());
  }
}

function* loadPolicyTemplateDetail(action) {
  const {
    data,
    data: { id },
    cb,
  } = action.payload;
  // const company_id =
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/policy/template/detail/${id}`,
      { headers: { Authorization: `Bearer ${data.accessToken}` } }
    );
    // cb(res.data[0]);

    yield put(actions.loadPolicyTemplateDetail.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadPolicyTemplateDetail.failure());
  }
}

function* createTemplatePolicy(action) {
  const { data, cb } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/policy/template/create`,
      {
        ...data,
      },
      { headers: { Authorization: `Bearer ${data.accessToken}` } }
    );
    cb(res.data);
    yield put(actions.createTemplatePolicy.success(res));
  } catch (err) {
    console.log(err.message);
    yield put(actions.createTemplatePolicy.failure());
  }
}

function* updateTemplatePolicy(action) {
  const { data, cb } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/policy/template/update`,
      {
        ...data,
      },
      { headers: { Authorization: `Bearer ${data.accessToken}` } }
    );
    cb();

    yield put(actions.updateTemplatePolicy.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.updateTemplatePolicy.failure());
  }
}

export default function* watchPolicyState() {
  yield all([
    takeEvery(actions.CREATE_POLICY.REQUEST, createPolicy),
    takeEvery(actions.LOAD_POLICY.REQUEST, loadPolicy),
    takeEvery(actions.LOAD_POLICY_DETAIL.REQUEST, loadPolicyDetail),
    takeEvery(actions.UPDATE_POLICY.REQUEST, updatePolicy),
    takeEvery(
      actions.LOAD_POLICY_TEMPLATE_DETAIL.REQUEST,
      loadPolicyTemplateDetail
    ),
    takeEvery(actions.CREATE_TEMPLATE_POLICY.REQUEST, createTemplatePolicy),
    takeEvery(actions.UPDATE_TEMPLATE_POLICY.REQUEST, updateTemplatePolicy),
  ]);
}

export {
  createPolicy,
  loadPolicy,
  loadPolicyDetail,
  updatePolicy,
  createTemplatePolicy,
  loadPolicyTemplateDetail,
  updateTemplatePolicy,
};
