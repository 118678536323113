import { createTypes, createAction } from "lib/action";

const LOAD_DRIVERINCOME = createTypes("driverincome", "load");
const LOAD_DRIVER = createTypes("driver_driverincome", "load");

const loadDriverincome = {
  request: (data, loading) =>
    createAction(LOAD_DRIVERINCOME.REQUEST, { data, loading }),
  success: (data) => createAction(LOAD_DRIVERINCOME.SUCCESS, { data }),
  failure: () => createAction(LOAD_DRIVERINCOME.FAILURE),
};

const loadDriver = {
  request: () => createAction(LOAD_DRIVER.REQUEST, {}),
  success: (data) => createAction(LOAD_DRIVER.SUCCESS, { data }),
  failure: () => createAction(LOAD_DRIVER.FAILURE),
};

export { loadDriverincome, LOAD_DRIVERINCOME, loadDriver, LOAD_DRIVER };
