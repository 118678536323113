import React from "react";
import Form from "./Form/Template/Form";
import { connect } from "react-redux";

const Create = ({ match }) => {
  return <Form type="create" match={match} />;
};

const mapStateToProps = ({ auth }) => ({
  auth,
});

export default connect(mapStateToProps)(Create);
