import { createTypes, createAction } from "lib/action";

const LOAD_TRIPTEMPERATURE = createTypes("triptemperature", "load");

const loadTriptemperature = {
  request: (data, loading) =>
    createAction(LOAD_TRIPTEMPERATURE.REQUEST, { data, loading }),
  success: data => createAction(LOAD_TRIPTEMPERATURE.SUCCESS, { data }),
  failure: () => createAction(LOAD_TRIPTEMPERATURE.FAILURE)
};



export {
  loadTriptemperature,
  LOAD_TRIPTEMPERATURE

};
