import { createTypes, createAction } from "lib/action";

const LOAD_MAINTENANCE = createTypes("maintenance", "load");
const LOAD_VEHICLE_MAINTENANCE = createTypes(
  "vehicle_maintenance",
  "load"
);
const LOAD_VEHICLE_TYPE_MAINTENANCE = createTypes("vehicletype_maintenance", "load");
const LOAD_PART_TYPE = createTypes("parttype", "load");

const loadMaintenance = {
  request: (data, loading) =>
    createAction(LOAD_MAINTENANCE.REQUEST, { data, loading }),
  success: data => createAction(LOAD_MAINTENANCE.SUCCESS, { data }),
  failure: () => createAction(LOAD_MAINTENANCE.FAILURE)
};

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_MAINTENANCE.REQUEST, {
      vehicletypeID,
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_VEHICLE_MAINTENANCE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_MAINTENANCE.FAILURE)
};

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_MAINTENANCE.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_TYPE_MAINTENANCE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_MAINTENANCE.FAILURE)
};

const loadPartType = {
  request: () => createAction(LOAD_PART_TYPE.REQUEST, {}),
  success: data => createAction(LOAD_PART_TYPE.SUCCESS, { data }),
  failure: () => createAction(LOAD_PART_TYPE.FAILURE)
};

export {
  loadMaintenance,
  LOAD_MAINTENANCE,
  loadVehicle,
  LOAD_VEHICLE_MAINTENANCE,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_MAINTENANCE,
  loadPartType,
  LOAD_PART_TYPE
};
