import { createTypes, createAction } from "lib/action";

const LOAD_GPSLOSTCONTACT = createTypes("gpslostcontact", "load");
const LOAD_VEHICLE_GPSLOSTCONTACT = createTypes(
  "vehicle_gpslostcontact",
  "load"
);
const LOAD_VEHICLE_TYPE_GPSLOSTCONTACT = createTypes(
  "vehicletype_gpslostcontact",
  "load"
);

const loadGpsLostContact = {
  request: (data, loading) =>
    createAction(LOAD_GPSLOSTCONTACT.REQUEST, { data, loading }),
  success: data => createAction(LOAD_GPSLOSTCONTACT.SUCCESS, { data }),
  failure: () => createAction(LOAD_GPSLOSTCONTACT.FAILURE)
};

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_GPSLOSTCONTACT.REQUEST, {
      vehicletypeID,
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_VEHICLE_GPSLOSTCONTACT.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_GPSLOSTCONTACT.FAILURE)
};

const loadVehicleType = {
  request: () =>
    createAction(LOAD_VEHICLE_TYPE_GPSLOSTCONTACT.REQUEST, {}),
  success: data =>
    createAction(LOAD_VEHICLE_TYPE_GPSLOSTCONTACT.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_GPSLOSTCONTACT.FAILURE)
};

export {
  loadGpsLostContact,
  LOAD_GPSLOSTCONTACT,
  loadVehicle,
  LOAD_VEHICLE_GPSLOSTCONTACT,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_GPSLOSTCONTACT
};
