import { createTypes, createAction } from "lib/action";

const LOAD_GPS_CERT = createTypes("gps_cert", "load");
const LOAD_GPS_CERT_DETAIL = createTypes("gps_cert_detail", "load");
const LOAD_GPS_CERT_HISTORY = createTypes("gps_cert_history", "load");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
  return {
    type: REMOVE_LISTS
  };
}

const loadGPSCert = {
  request: (data, loading) =>
    createAction(LOAD_GPS_CERT.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_GPS_CERT.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_GPS_CERT.FAILURE)
};

const loadGPSCertDetail = {
  request: (data, cb) =>
    createAction(LOAD_GPS_CERT_DETAIL.REQUEST, { data, cb }),
  success: (lists, total) =>
    createAction(LOAD_GPS_CERT_DETAIL.SUCCESS, { lists, total }),
  failure: () => createAction(LOAD_GPS_CERT_DETAIL.FAILURE)
};

const loadGPSCertHistory = {
  request: (data, loading) =>
    createAction(LOAD_GPS_CERT_HISTORY.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_GPS_CERT_HISTORY.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_GPS_CERT_HISTORY.FAILURE)
};

export {
  loadGPSCert,
  LOAD_GPS_CERT,
  loadGPSCertDetail,
  LOAD_GPS_CERT_DETAIL,
  loadGPSCertHistory,
  LOAD_GPS_CERT_HISTORY,
  removeLists,
  REMOVE_LISTS
};
