import {
  LOAD_RISKBEHAVIOUR,
  LOAD_VEHICLE_RISKBEHAVIOUR,
  LOAD_VEHICLE_TYPE_RISKBEHAVIOUR
} from "./actions";

const initialState = {
  riskbehaviourUrl: "",
  vehicleLists: [],
  vehicleTypeLists: [],
  riskbehaviourLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_RISKBEHAVIOUR.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        riskbehaviourLoading: loading
      };
    case LOAD_RISKBEHAVIOUR.SUCCESS:

      return {
        ...state,
        riskbehaviourUrl: action.payload.data.data,
        riskbehaviourLoading: false
      };
    case LOAD_VEHICLE_RISKBEHAVIOUR.REQUEST:
      const { loading_vehicle } = action.payload;
      return {
        ...state,
        riskbehaviourLoading: loading_vehicle
      };
    case LOAD_VEHICLE_RISKBEHAVIOUR.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        riskbehaviourLoading: false
      };
    case LOAD_VEHICLE_TYPE_RISKBEHAVIOUR.SUCCESS:
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data
      };
    default:
      return state;
  }
};
