import { createTypes, createAction } from "lib/action";
const LOAD_MAINTENANCE = createTypes("loadmaintenance", "load");
const LOAD_MAINTENANCE_DETAIL = createTypes("loaddetailmaintenance", "load");
const LOAD_AUTOCOMPLETE_PLATENO = createTypes("autocompleteplateno", "load");
const LOAD_AUTOCOMPLETE_CODE = createTypes("autocompletecode", "load");
const CREATE_MAINTENANCE = createTypes("maintenance", "create");
const CREATE_MAINTENANCE_DETAIL = createTypes("detailmaintenance", "create");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
    return {
        type: REMOVE_LISTS
    };
}

const loadMaintenance = {
    request: (data, loading) =>
        createAction(LOAD_MAINTENANCE.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(LOAD_MAINTENANCE.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_MAINTENANCE.FAITLURE)  
};

const loadAutocompleteVehiclePlateNo = {
    request: (url, companyID, accessToken, queryString) =>
      createAction(LOAD_AUTOCOMPLETE_PLATENO.REQUEST, {
        url,
        companyID,
        accessToken,
        queryString
      }),
    success: lists => createAction(LOAD_AUTOCOMPLETE_PLATENO.SUCCESS, { lists }),
    failure: () => createAction(LOAD_AUTOCOMPLETE_PLATENO.FAILURE)
};

const loadAutocompleteVehicleCode = {
    request: (url, companyID, accessToken, queryString) =>
      createAction(LOAD_AUTOCOMPLETE_CODE.REQUEST, {
        url,
        companyID,
        accessToken,
        queryString
      }),
    success: lists => createAction(LOAD_AUTOCOMPLETE_CODE.SUCCESS, { lists }),
    failure: () => createAction(LOAD_AUTOCOMPLETE_CODE.FAILURE)
};

const createMaintenance = {
    request: (data, loading) =>
        createAction(CREATE_MAINTENANCE.REQUEST, { data, loading}),
    success: res => createAction(CREATE_MAINTENANCE.SUCCESS, { res }),
        failure: () => createAction(CREATE_MAINTENANCE.FAILURE)
};

const createDetailMaintenance = {
    request: (data, loading) =>
        createAction(CREATE_MAINTENANCE_DETAIL.REQUEST, { data, loading}),
    success: res => createAction(CREATE_MAINTENANCE_DETAIL.SUCCESS, { res }),
        failure: () => createAction(CREATE_MAINTENANCE_DETAIL.FAILURE)
};

const loadDetailMaintenance = {
    request: (data, loading) =>
        createAction(LOAD_MAINTENANCE_DETAIL.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(LOAD_MAINTENANCE_DETAIL.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_MAINTENANCE_DETAIL.FAITLURE)  
};

export {
    LOAD_MAINTENANCE, loadMaintenance,
    LOAD_AUTOCOMPLETE_PLATENO, loadAutocompleteVehiclePlateNo,
    LOAD_AUTOCOMPLETE_CODE, loadAutocompleteVehicleCode,
    CREATE_MAINTENANCE, createMaintenance,
    CREATE_MAINTENANCE_DETAIL, createDetailMaintenance,
    LOAD_MAINTENANCE_DETAIL, loadDetailMaintenance,
    REMOVE_LISTS, removeLists
};