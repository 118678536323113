import { createTypes, createAction } from "lib/action";

const LOAD_DRIVINGBEHAVIOR = createTypes("drivingbehavior", "load");
const LOAD_VEHICLE_DRIVINGBEHAVIOR = createTypes(
  "vehicle_drivingbehavior",
  "load"
);
const LOAD_VEHICLE_TYPE_DRIVINGBEHAVIOR = createTypes(
  "vehicle_type_drivingbehavior",
  "load"
);

const loadDrivingbehavior = {
  request: (data, loading) =>
    createAction(LOAD_DRIVINGBEHAVIOR.REQUEST, { data, loading }),
  success: data => createAction(LOAD_DRIVINGBEHAVIOR.SUCCESS, { data }),
  failure: () => createAction(LOAD_DRIVINGBEHAVIOR.FAILURE)
};

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_DRIVINGBEHAVIOR.REQUEST, {
      vehicletypeID,
      loading_vehicle
    }),
  success: data => createAction(LOAD_VEHICLE_DRIVINGBEHAVIOR.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_DRIVINGBEHAVIOR.FAILURE)
};

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_DRIVINGBEHAVIOR.REQUEST, {}),
  success: data =>
    createAction(LOAD_VEHICLE_TYPE_DRIVINGBEHAVIOR.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_DRIVINGBEHAVIOR.FAILURE)
};

export {
  loadDrivingbehavior,
  LOAD_DRIVINGBEHAVIOR,
  loadVehicle,
  LOAD_VEHICLE_DRIVINGBEHAVIOR,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_DRIVINGBEHAVIOR
};
