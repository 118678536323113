import { createTypes, createAction } from "lib/action";

const LOAD_PERMISSION = createTypes("permissionmasterdata", "load");
const LOAD_PERMISSION_DETAIL = createTypes(
  "permissiondetailmasterdata",
  "load"
);
const CREATE_PERMISSION = createTypes("permissionmasterdata", "create");
const UPDATE_PERMISSION = createTypes("permissionmasterdata", "update");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
  return {
    type: REMOVE_LISTS
  };
}
const loadPermission = {
  request: (data, loading) =>
    createAction(LOAD_PERMISSION.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_PERMISSION.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_PERMISSION.FAILURE)
};

const loadPermissionDetail = {
  request: data => createAction(LOAD_PERMISSION_DETAIL.REQUEST, { data }),
  success: permission =>
    createAction(LOAD_PERMISSION_DETAIL.SUCCESS, { permission }),
  failure: () => createAction(LOAD_PERMISSION_DETAIL.FAILURE)
};

const createPermission = {
  request: (data, cb) =>
    createAction(CREATE_PERMISSION.REQUEST, {
      data,
      cb
    }),
  success: res => createAction(CREATE_PERMISSION.SUCCESS, { res }),
  failure: () => createAction(CREATE_PERMISSION.FAILURE)
};

const updatePermission = {
  request: (data, cb) =>
    createAction(UPDATE_PERMISSION.REQUEST, {
      data,
      cb
    }),
  success: res => createAction(UPDATE_PERMISSION.SUCCESS, { res }),
  failure: () => createAction(UPDATE_PERMISSION.FAILURE)
};

export {
  CREATE_PERMISSION,
  createPermission,
  loadPermission,
  LOAD_PERMISSION,
  REMOVE_LISTS,
  removeLists,
  LOAD_PERMISSION_DETAIL,
  loadPermissionDetail,
  updatePermission,
  UPDATE_PERMISSION
};
