import {
  LOAD_VEHICLEPERFORMANCECONCLUDE,
  LOAD_VEHICLE_VEHICLEPERFORMANCECONCLUDE,
  LOAD_VEHICLE_TYPE_VEHICLEPERFORMANCECONCLUDE
} from "./actions";

const initialState = {
  vehicleperformanceconcludeUrl: "",
  vehicleLists: [],
  vehicleTypeLists: [],
  vehicleperformanceconcludeLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_VEHICLEPERFORMANCECONCLUDE.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        vehicleperformanceconcludeLoading: loading
      };
    case LOAD_VEHICLEPERFORMANCECONCLUDE.SUCCESS:

      return {
        ...state,
        vehicleperformanceconcludekUrl: action.payload.data.data,
        vehicleperformanceconcludeLoading: false
      };
    case LOAD_VEHICLE_VEHICLEPERFORMANCECONCLUDE.REQUEST:
      const { loading_vehicle } = action.payload;
      return {
        ...state,
        vehicleperformanceconcludeLoading: loading_vehicle
      };
    case LOAD_VEHICLE_VEHICLEPERFORMANCECONCLUDE.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        vehicleperformanceconcludeLoading: false
      };
    case LOAD_VEHICLE_TYPE_VEHICLEPERFORMANCECONCLUDE.SUCCESS:
      // console.log(action.payload.data.data);
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data
      };
    default:
      return state;
  }
};
