// FileUpload.js
import React from "react";
import LangContext, { i18n } from "modules/shared/context/langContext";

const FileUpload = ({ onFileSelect, selectedFile }) => {
  const handleFileInput = (e) => {
    onFileSelect(e.target.files[0]);
  };
  return (
    <div>
      <label for="file-upload" class="custom-file-upload">
        <LangContext.Consumer>{(i18n) => i18n.u.upload}</LangContext.Consumer>
      </label>
      <input
        type="file"
        id="file-upload"
        onChange={handleFileInput}
        accept=".mp3"
      />
      {!!selectedFile ? selectedFile.name : null}
    </div>
  );
};

export default FileUpload;
