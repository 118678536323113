import React from "react";
import Form from "./VoiceManagementForm";
import { connect } from "react-redux";
import { createVoiceManagement } from "../fetchAPI/apiClient";
const Create = (props) => {
  const onSubmit = (values) => {
    let formData = new FormData();
    formData.append("company_id", props.profile.company_id);
    formData.append("user_id", props.profile.id);
    formData.append("name", values["name"]);
    formData.append("remark", values["remark"]);
    formData.append("file", values["upload"]);
    createVoiceManagement(formData, (statusCode, response) => {
      if (response.data.status === 200) {
        window.location.replace("/voice-management");
      }
    });
  };
  return <Form type="create" match={props.match} onSubmit={onSubmit} />;
};

export default connect(
  ({ auth, auth: { profile } }) => ({
    auth,
    profile,
  }),
  {}
)(Create);
