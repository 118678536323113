import { createTypes, createAction } from "lib/action";

const LOAD_RISKBEHAVIOUR = createTypes("riskbehaviour", "load");
const LOAD_VEHICLE_RISKBEHAVIOUR = createTypes(
  "vehicle_riskbehaviour",
  "load"
);
const LOAD_VEHICLE_TYPE_RISKBEHAVIOUR = createTypes("vehicletype_riskbehaviour", "load");

const loadRiskbehaviour = {
  request: (data, loading) =>
    createAction(LOAD_RISKBEHAVIOUR.REQUEST, { data, loading }),
  success: data => createAction(LOAD_RISKBEHAVIOUR.SUCCESS, { data }),
  failure: () => createAction(LOAD_RISKBEHAVIOUR.FAILURE)
};

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_RISKBEHAVIOUR.REQUEST, {
      vehicletypeID,
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_VEHICLE_RISKBEHAVIOUR.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_RISKBEHAVIOUR.FAILURE)
};

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_RISKBEHAVIOUR.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_TYPE_RISKBEHAVIOUR.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_RISKBEHAVIOUR.FAILURE)
};

export {
  loadRiskbehaviour,
  LOAD_RISKBEHAVIOUR,
  loadVehicle,
  LOAD_VEHICLE_RISKBEHAVIOUR,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_RISKBEHAVIOUR
};
