import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Spin, Icon, Col, Alert, Button, notification } from "antd";

import LangContext, { i18n } from "modules/shared/context/langContext";
import { messaging } from "../../../lib/fcm/fcm_init";

import HomeDashboard from "modules/home-dashboard/index/components/HomeDashboard";
import {
  PureBackgroundStyledContent,
  StyledLogo,
  StyledDiv,
  StyledRow,
  StyledCol,
} from "../../../styled/common-styled";
import { checkLogin, checkLoginNotification } from "../actions";

import Form from "./Form";
import { notSafePassword } from "../../../constants/constants";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      token: "",
      notifications: [],
    };
  }

  openNotification = () =>
    notification.warning({
      duration: 30,
      message: (
        <LangContext.Consumer>
          {(i18n) => i18n.n.notification}
        </LangContext.Consumer>
      ),
      description: (
        <div>
          <span>
            <p>
              <LangContext.Consumer>
                {(i18n) => i18n.n.notificationPasswordNotSafeInfo}
              </LangContext.Consumer>
            </p>
          </span>
          <Button
            type="primary"
            block
            onClick={(e) => (window.location = "/profile")}
          >
            <Icon type="user" style={{ marginRight: "8px" }} />
            <LangContext.Consumer>
              {(i18n) => i18n.p.profile}
            </LangContext.Consumer>
          </Button>
        </div>
      ),
      placement: "bottomRight",
    });

  warningModal = () => {
    Modal.warning({
      centered: true,
      width: 700,
      okText: "ปิด",
      title: <h2>เรียนผู้ใช้บริการ</h2>,
      content: (
        <h4>
          เนื่องจาก ระบบ TERMINUS จะปิดปรับปรุงระบบในวันที่ 25 กรกฎาคม 2563 เวลา
          22:00 น. ถึงวันที่ 26 กรกฎาคม 2563 เวลา 07:00 น.
          โดยผู้ใช้งานสามารถติดตามรถสถานะที่เป็นปัจจุบันได้ตามปกติ
          แต่จะไม่สามารถดูข้อมูลย้อนหลัง, เรียกรายงาน
          และบันทึกรูปภาพได้ในช่วงเวลาดังกล่าว ขอออภัยในความไม่สะดวก
        </h4>
      ),
    });
  };

  componentDidMount() {
    // !this.props.auth.done &&
    //   window.location.pathname == "/" &&
    //   this.warningModal();

    if (messaging) {
      // รับ Token Notification
      messaging
        .getToken()
        .then((token) => {
          this.setToken(token);
          // this.call(token);
          // Indicate that the new Instance ID token has not yet been sent to the
          // app server.
          // # TODO : setTokenSentToServer(false);
          // Send Instance ID token to app server.
          // TODO : sendTokenToServer(refreshedToken);
          // [START_EXCLUDE]
          // Display new Instance ID token and clear UI of all previous messages.
          // resetUI();
          // [END_EXCLUDE]
        })
        .catch((err) => {
          console.log("Unable to retrieve refreshed token ", err);
          // showToken("Unable to retrieve refreshed token ", err);
        });

      // start listen message
      // navigator.serviceWorker.addEventListener("message", ({ data }) => {
      //   console.log(data);
      //   let notification = {
      //     ...data.firebaseMessaging,
      //   };
      //   this.setNotifications(notification);
      // });
    }
  }

  setToken(token) {
    this.setState({
      ...this.state,
      token,
    });
  }

  checkLogin = (values) => {
    let self = this;
    let { username, password } = values;
    this.setState({
      username: username,
    });

    this.props.checkLogin(values, function(res) {
      if (res.status == "success") {
        typeof notSafePassword.find((ele) => ele == password) !== "undefined" &&
          self.openNotification();
        self.props.checkLoginNotification({
          // username: self.state.username,
          username: values.username,
          password: "",
          token: self.state.token,
        });
      }
    });
  };
  render() {
    let alertMsg = "";
    if (typeof this.props.location !== "undefined") {
      if (
        typeof this.props.location.data != "undefined" &&
        typeof this.props.location.data.msg != ""
      ) {
        alertMsg = (
          <Alert
            message="เรียนผู้ใช้บริการ"
            description={this.props.location.data.msg}
            type="warning"
            showIcon
            closable
            style={{ margin: "0px 0px 15px 0px" }}
          />
        );
      }
    }

    return this.props.auth.done ? (
      <PureBackgroundStyledContent>
        {/* <Alert
          message="เรียนผู้ใช้บริการ"
          description="เนื่องจาก ระบบ TERMINUS จะปิดปรับปรุงระบบในวันที่ 25 กรกฎาคม 2563 เวลา 22:00 น. ถึงวันที่ 26 กรกฎาคม 2563 เวลา 07:00 น. โดยผู้ใช้งานสามารถติดตามรถสถานะที่เป็นปัจจุบันได้ตามปกติ แต่จะไม่สามารถดูข้อมูลย้อนหลัง, เรียกรายงาน และบันทึกรูปภาพได้ในช่วงเวลาดังกล่าว ขอออภัยในความไม่สะดวก"
          type="warning"
          showIcon
          closable
          style={{ margin: "0px 0px 15px 0px" }}
        /> */}

        <HomeDashboard />
      </PureBackgroundStyledContent>
    ) : (
      <LangContext.Consumer>
        {(i18n) => (
          <StyledDiv>
            <StyledRow>
              <Col
                xs={{ span: 18, offset: 3 }}
                sm={{ span: 18, offset: 3 }}
                md={{ span: 18, offset: 3 }}
                lg={{ span: 18, offset: 3 }}
                xl={{ span: 18, offset: 3 }}
              ></Col>
              <StyledCol
                xs={{ span: 18, offset: 3 }}
                sm={{ span: 18, offset: 3 }}
                md={{ span: 9, offset: 8 }}
                lg={{ span: 7, offset: 8 }}
                xl={{ span: 7, offset: 8 }}
              >
                <StyledLogo span={24} src="/img/logo.png" alt="" />
                <Spin
                  spinning={this.props.auth.loading}
                  size="large"
                  style={{ height: "100%" }}
                >
                  <Form auth={this.props.auth} onSubmit={this.checkLogin} />
                </Spin>
              </StyledCol>
            </StyledRow>
          </StyledDiv>
        )}
      </LangContext.Consumer>
    );
  }
}

export default connect(
  ({ token, profile, auth }) => ({ token, profile, auth }),
  {
    checkLogin: checkLogin.request,
    checkLoginNotification: checkLoginNotification.request,
  }
)(Login);
