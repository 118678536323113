import {
    CREATE_PARTGROUP,
    LOAD_PARTGROUP,
    LOAD_PARTGROUP_DETAIL,
    REMOVE_LISTS
} from "./actions";

const initialState = {
    lists: [],
    listsdetail: [],
    total: 1,
    loading: false,
    autoComplete: [],
    check: true,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REMOVE_LISTS:
            return initialState;

        case LOAD_PARTGROUP.REQUEST:
            const { loading } = action.payload.data;
            return {
                ...state,
                loading: loading,
            }

        case LOAD_PARTGROUP.SUCCESS:
            const { page } = action.payload;
                       
            for (let i = 0; i < action.payload.lists.data.length; i++) {
                action.payload.lists.data[i].rowNumber = i + 1;
                    if (page > 1)
                        action.payload.lists.data[i].rowNumber =
                            action.payload.lists.data[i].rowNumber + (page - 1) * 10;
            }
            
            return {
                ...state,
                lists: action.payload.lists.data,
                total: 
                    action.payload.lists.total - 1 <= 0
                    ? 1
                    : action.payload.lists.total <= 0
                    ? 1
                    : action.payload.lists.total,
                    loading: false,
                draw: Math.floor(Math.random() * 999 + 1)
            };


        case LOAD_PARTGROUP_DETAIL.REQUEST:
            return {
                ...state,
                    loading: action.payload.data.loading,
            }

        case LOAD_PARTGROUP_DETAIL.SUCCESS:
                           
            for (let i = 0; i < action.payload.lists.data.length; i++) {
                action.payload.lists.data[i].rowNumber = i + 1;
                    if (action.payload.page > 1)
                        action.payload.lists.data[i].rowNumber =
                            action.payload.lists.data[i].rowNumber + (action.payload.page - 1) * 10;
            }
                
            return {
                ...state,
                listsdetail: action.payload.lists.data,
                total: 
                    action.payload.lists.total - 1 <= 0
                    ? 1
                    : action.payload.lists.total <= 0
                    ? 1
                    : action.payload.lists.total,
                    loading: false,
                draw: Math.floor(Math.random() * 999 + 1)
            };

        case CREATE_PARTGROUP.SUCCESS:
            return {
                ...state,
                loading: false
            };    
                
        default:
            return state;
    }
}