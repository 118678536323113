import { createTypes, createAction } from 'lib/action'

// *remove lists
const REMOVE_LISTS = `REMOVE_LISTS`

// *ค้นหาข้อมูล
const MONITOR_SDCARD_TABLE_LOG = createTypes('monitorSDCardTableLog', 'load')

// *แสดงผลข้อมูล chart
const MONITOR_SDCARD_CHART_LOG = createTypes('monitorSDCardChartLog', 'load')

// *ดึงข้อมูล company list
const MONITOR_SDCARD_COMPANY_LIST = createTypes(
  'monitorSDCardCompanyList',
  'load'
)

// *remove lists
function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

// *ดึงข้อมูล table log
const monitorSDCardTableLog = {
  request: (data, loading) =>
    createAction(MONITOR_SDCARD_TABLE_LOG.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(MONITOR_SDCARD_TABLE_LOG.SUCCESS, { lists }),
  failure: () => createAction(MONITOR_SDCARD_TABLE_LOG.FAITLURE),
}

// *ดึงข้อมูล chart log
const monitorSDCardChartLog = {
  request: (data, loading) =>
    createAction(MONITOR_SDCARD_CHART_LOG.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(MONITOR_SDCARD_CHART_LOG.SUCCESS, { lists }),
  failure: () => createAction(MONITOR_SDCARD_CHART_LOG.FAITLURE),
}

// *ดึงข้อมูล company list
const monitorSDCardCompanyList = {
  request: (data, loading) =>
    createAction(MONITOR_SDCARD_COMPANY_LIST.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(MONITOR_SDCARD_COMPANY_LIST.SUCCESS, { lists }),
  failure: () => createAction(MONITOR_SDCARD_COMPANY_LIST.FAITLURE),
}

export {
  MONITOR_SDCARD_TABLE_LOG,
  monitorSDCardTableLog,
  MONITOR_SDCARD_CHART_LOG,
  monitorSDCardChartLog,
  MONITOR_SDCARD_COMPANY_LIST,
  monitorSDCardCompanyList,
  REMOVE_LISTS,
  removeLists,
}
