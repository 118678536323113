import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import Index from "./Index";
import Create from "./Create";

export default () => (
  <Router>
    <Route exact path="/withdraw-fuel-credit" component={Index} />
    <Route exact path="/withdraw-fuel-credit/create" component={Create} />
  </Router>
);
