import React, { Component } from "react";
import { connect } from "react-redux";

import LangContext, { i18n } from "modules/shared/context/langContext";
import {
  Row,
  Col,
  Popover,
  Table,
  Badge,
  Button,
  Drawer,
  Form,
  Input,
  Icon,
  Popconfirm,
} from "antd";
import { withRouter } from "react-router-dom";

import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";

import { GeneralStyledContent } from "../../../../styled/common-styled";
import { loadDashcamera, removeLists, loadEmergencyCase } from "../../actions";
import "../../../../styled/old-web/alert-icon.css";
import { IconDescModal } from "./IconDescModal";

export class Index extends Component {
  state = {
    isFilter: false,
    filterVisible: false,
    dataActive: [],
    currentPage: 1,
    page: 1,
    size: 4,
    ticket_no: "",
    vehicle_code: "",
    invoice_number: "",
    location: "",
    status: "",
    iconDetailVisible: false,
  };

  colActive = [
    {
      title: (
        <LangContext.Consumer>{(i18n) => i18n.t.tripCode}</LangContext.Consumer>
      ),
      dataIndex: "ticket",
      width: 12,
      ellipsis: true,
    },
    {
      title: (
        <LangContext.Consumer>
          {(i18n) => i18n.v.vehicleCode}
        </LangContext.Consumer>
      ),
      dataIndex: "vehicle_code",
      width: 12,
      ellipsis: true,
    },

    {
      title: (
        <LangContext.Consumer>
          {(i18n) => i18n.i.invoice_number}
        </LangContext.Consumer>
      ),
      dataIndex: "do_number",
      width: 10,
      ellipsis: true,
    },
    {
      title: (
        <LangContext.Consumer>{(i18n) => i18n.d.duration}</LangContext.Consumer>
      ),
      width: 30,
      dataIndex: "trip_duration",
      className: "column-text-center",
      render(text, record, index) {
        let textArr = [];

        if (text != null && text != "") {
          textArr = text.split(",");
          return (
            <div>
              {textArr.map((ele) => (
                <span>
                  <p style={{ fontSize: "12px" }}>{ele}</p>
                </span>
              ))}
            </div>
          );
        } else return text;
      },
    },
    {
      title: (
        <LangContext.Consumer>{(i18n) => i18n.s.site}</LangContext.Consumer>
      ),
      dataIndex: "site_name",
      width: 20,
    },
    {
      title: (
        <LangContext.Consumer>{(i18n) => i18n.s.status}</LangContext.Consumer>
      ),
      dataIndex: "current_status",
      width: 20,
    },

    {
      title: "",
      dataIndex: "status_image_2",
      width: 8,
      render(text, record, index) {
        return {
          children:
            text["desc_1"] != null ? (
              <Popover
                placement="left"
                content={
                  <div>
                    <p>{text["desc_1"]}</p>
                  </div>
                }
              >
                <div className={text["icon"]}></div>
              </Popover>
            ) : (
              <div className={text["icon"]}></div>
            ),
        };
      },
    },
    {
      title: "",
      dataIndex: "status_image_3",
      width: 8,
      render(text, record, index) {
        return {
          children:
            text["desc_1"] != null && text["desc_1"] != "" ? (
              <Popover
                placement="left"
                content={
                  <div>
                    <p>{text["desc_1"]}</p>
                    <p>{text["desc_2"]}</p>
                  </div>
                }
              >
                <div className={text["icon"]}></div>
              </Popover>
            ) : (
              <div className={text["icon"]}></div>
            ),
        };
      },
    },
    {
      title: "",
      dataIndex: "status_image_4",
      width: 8,
      render(text, record, index) {
        return {
          children:
            (text["desc_1"] != null && text["desc_1"] != "") ||
            (text["desc_2"] != null && text["desc_2"] != "") ? (
              <Popover
                placement="left"
                content={
                  <div>
                    <p>{text["desc_1"]}</p>
                    <p>{text["desc_2"]}</p>
                  </div>
                }
              >
                <div className={text["icon"]}></div>
              </Popover>
            ) : (
              <div className={text["icon"]}></div>
            ),
        };
      },
    },
    {
      title: "",
      dataIndex: "",
      width: 8,
      render: (text, record, index) => {
        let self = this;
        if (record.is_child == true) return <div />;
        return (
          <Popconfirm
            title={
              <LangContext.Consumer>
                {(i18n) => i18n.c.confirmToCloseThisItem}
              </LangContext.Consumer>
            }
            onConfirm={(e) => self.closeTrip(record.trips_id)}
            onCancel={(e) => false}
            okText={
              <LangContext.Consumer>
                {(i18n) => i18n.c.confirm}
              </LangContext.Consumer>
            }
            cancelText={
              <LangContext.Consumer>
                {(i18n) => i18n.c.cancel}
              </LangContext.Consumer>
            }
            placement="topRight"
          >
            <Button type="link">
              <LangContext.Consumer>
                {(i18n) => i18n.c.close}
              </LangContext.Consumer>
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  closeTrip = (tripId) => {
    let self = this;
    console.log(tripId);
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/close-trip`, {
      method: "POST",
      mode: "cors",
      headers: {
        // Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${self.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        trip_id: tripId,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        self.loadData();
      })
      .catch(function() {});
  };

  loadData = () => {
    let self = this;

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getmultidropfeed`, {
      //fetch(`http://booking-api-88:8080/api/getmultidropfeed`, {
      method: "POST",
      mode: "cors",
      headers: {
        // Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${self.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        ms: self.props.profile.id,
        plant: self.props.profile.company_id,
        vehicle_type: null,
        vehicle_visibility: self.props.profile.vehicle_visibility,
        location_group_id: self.props.profile.location_visibility,
        status: "active",
        sortby: "sdr.sdr_trip_code DESC",
        page: 1,
        start_date: "04/03/2019",
        end_date: "04/03/2019",
        shipto_selected: "",
        filter_date_type_select: "Today",
        itemid: "163",
        company_id: self.props.profile.company_id,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        self.setState({
          dataActive: res.active,
        });
      })
      .catch(function() {});
  };

  showIconDescModal = () => {
    this.setState({
      iconDetailVisible: true,
    });
  };

  handleIconDetailCancel = () => {
    this.setState({
      iconDetailVisible: false,
    });
  };

  componentDidMount() {
    this.loadData();

    this.loadConcreteData = setInterval(this.loadData, 1000 * 30);
  }

  componentWillUnmount() {
    clearInterval(this.loadConcreteData);
  }

  render() {
    return (
      <AuthorizeComponent matching_name="multi_drop_dashboard">
        <GeneralStyledContent>
          <Row>
            <Col span={24} style={{ marginBottom: "20px" }}>
              <h2>
                {" "}
                <LangContext.Consumer>
                  {(i18n) => i18n.o.onProcess}
                </LangContext.Consumer>
              </h2>
              <Row style={{ marginBottom: "10px" }}>
                <Col span={22}>
                  <Button
                    type="primary"
                    className="edge-element"
                    size="small"
                    onClick={(e) => this.showIconDescModal()}
                  >
                    <Icon type="dot-chart" />{" "}
                    <LangContext.Consumer>
                      {(i18n) => i18n.i.iconDesc}
                    </LangContext.Consumer>
                  </Button>
                </Col>
                <Col span={2} align="right">
                  <Badge dot={this.state.isFilter} align="right">
                    <Button
                      icon="filter"
                      size="small"
                      onClick={(e) => this.setState({ filterVisible: true })}
                    >
                      <span></span>
                      <LangContext.Consumer>
                        {(i18n) => i18n.f.filter}
                      </LangContext.Consumer>
                    </Button>
                  </Badge>
                </Col>
              </Row>

              <Table
                rowKey={(record, i) => record.trips_id + "_" + i}
                rowClassName={(record, index) =>
                  record.is_child
                    ? "row-multi-drop-continues"
                    : "row-multi-drop-head"
                }
                // className="tracking-table"
                columns={this.colActive}
                dataSource={this.state.dataActive.filter((ele) => {
                  let {
                    ticket_no,
                    vehicle_code,
                    invoice_number,
                    location,
                    status,
                  } = this.state;
                  if (
                    ticket_no === "" &&
                    vehicle_code === "" &&
                    invoice_number === "" &&
                    location === "" &&
                    status === ""
                  )
                    return true;
                  if (ticket_no !== "" && ele.ticket.trim().includes(ticket_no))
                    return true;
                  if (
                    vehicle_code !== "" &&
                    ele.vehicle_code.trim().includes(vehicle_code)
                  )
                    return true;
                  if (
                    invoice_number !== "" &&
                    ele.do_number.toString().includes(invoice_number)
                  )
                    return true;
                  if (
                    location !== "" &&
                    ele.site_name.trim().includes(location)
                  )
                    return true;
                  if (
                    status !== "" &&
                    ele.current_status.trim().includes(status)
                  )
                    return true;
                  return false;
                })}
                size="small"
                pagination={false}
                bordered={false}
              />
            </Col>
          </Row>
        </GeneralStyledContent>
        <Drawer
          title={
            <LangContext.Consumer>
              {(i18n) => i18n.f.filter}
            </LangContext.Consumer>
          }
          width={520}
          placement="right"
          onClose={(e) => this.setState({ filterVisible: false })}
          maskClosable={true}
          visible={this.state.filterVisible}
          style={{
            height: "100%",
            overflow: "auto",
            paddingBottom: 53,
          }}
        >
          <Form>
            <Form.Item
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.t.tripCode}
                </LangContext.Consumer>
              }
            >
              <Input
                onChange={(e) => this.setState({ ticket_no: e.target.value })}
              />
            </Form.Item>

            <Form.Item
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.v.vehicleCode}
                </LangContext.Consumer>
              }
            >
              <Input
                onChange={(e) =>
                  this.setState({ vehicle_code: e.target.value })
                }
              />
            </Form.Item>

            <Form.Item
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.i.invoice_number}
                </LangContext.Consumer>
              }
            >
              <Input
                onChange={(e) =>
                  this.setState({ invoice_number: e.target.value })
                }
              />
            </Form.Item>

            <Form.Item
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.l.location}
                </LangContext.Consumer>
              }
            >
              <Input
                onChange={(e) => this.setState({ location: e.target.value })}
              />
            </Form.Item>

            <Form.Item
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.s.status}
                </LangContext.Consumer>
              }
            >
              <Input
                onChange={(e) => this.setState({ status: e.target.value })}
              />
            </Form.Item>

            <Button
              style={{
                marginRight: 8,
              }}
              onClick={(e) => this.setState({ filterVisible: false })}
            >
              <LangContext.Consumer>
                {(i18n) => i18n.c.cancel}
              </LangContext.Consumer>
            </Button>

            <Button
              type="primary"
              onClick={(e) => this.setState({ filterVisible: false })}
            >
              <LangContext.Consumer>
                {(i18n) => i18n.a.apply}
              </LangContext.Consumer>
            </Button>
          </Form>
        </Drawer>
        <IconDescModal
          iconDetailVisible={this.state.iconDetailVisible}
          handleIconDetailCancel={this.handleIconDetailCancel}
        />
      </AuthorizeComponent>
    );
  }
}

export default withRouter(
  connect(
    ({ monitoringState, auth, auth: { profile } }) => ({
      monitoringState,
      auth,
      profile,
    }),
    {
      loadDashcamera: loadDashcamera.request,
      removeLists,
      loadEmergencyCase: loadEmergencyCase.request,
    }
  )(Index)
);
