import {
  LOAD_MDVRDOWNLOADFILE,
  LOAD_VEHICLE_MDVRDOWNLOADFILE,
  CLEAR_MDVR,
} from "./actions";

const initialState = {
  vehicleLists: [],
  mdvrdownloadfileLists: [],
  mdvrdownloadfileLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_MDVRDOWNLOADFILE.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        mdvrdownloadfileLoading: loading
      };
    case LOAD_MDVRDOWNLOADFILE.SUCCESS:
      return {
        ...state,
        mdvrdownloadfileLists: action.payload.data.data,
        mdvrdownloadfileLoading: false
      };
    case LOAD_VEHICLE_MDVRDOWNLOADFILE.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        //drivinghourLoading: false
      };
    case CLEAR_MDVR:
        return {
          initialState
        };
    default:
      return state;
  }
};
