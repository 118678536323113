import axios from "axios";
import { all, put, call, takeEvery } from "redux-saga/effects";
import * as actions from "./actions";
import { fetcher, getErrorMsg, handleError } from "lib/fetch";
import { push } from "connected-react-router";

function* importDocument(action) {
  const {
    file,
    company_id,
    user_id,
    token,
    vehicle_visibility,
    is_super,
    cb,
  } = action.payload;
  try {
    const formData = new FormData();
    formData.append("file", file.originFileObj);
    formData.append("company_id", company_id);
    formData.append("user_id", user_id);
    formData.append("vehicle_visibility", vehicle_visibility);
    formData.append("is_super", is_super);
    const res = yield fetcher.upload(`managedocument/import`, formData, token);
    cb();
    yield put(actions.importDocument.success(res.data));
    yield put(push("/managedocument"));
  } catch (error) {
    handleError(error);
    cb();
    yield put(actions.importDocument.failure(getErrorMsg(error)));
  }
}

function* loadVehicleData(action) {
  try {
    const { data, page, pageSize } = action.payload;
    const ACCESSTOKEN = localStorage.getItem("access-token");

    const response = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/managedocument/gettaxactinsurancefromvehicle`,
      { ...data },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    yield put(
      actions.loadVehicleData.success(
        response.data,
        page,
        pageSize,
        response.data.totalCount
      )
    );
  } catch (error) {
    const errorMessage = getErrorMsg(error);
    yield put(actions.loadVehicleData.failure(errorMessage));
  }
}

function* loadVehicleDataExport(action) {
  try {
    const { data, page, pageSize } = action.payload;
    const ACCESSTOKEN = localStorage.getItem("access-token");

    const response = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/managedocument/gettaxactinsurancefromvehicle`,
      { ...data },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    yield put(actions.loadVehicleDataExport.success(response.data));

    console.log(response, "resp");
    console.log(response.data, "data");

    window.location = response.data.data;
  } catch (error) {
    const errorMessage = getErrorMsg(error);
    yield put(actions.loadVehicleDataExport.failure(errorMessage));
  }
}

function* updateVehicleTaxActInsurance(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { data, generateMsg } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/managedocument/updatevehicletaxactinsurance`,
      { ...data },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.updateVehicleTaxActInsurance.success(res.data));
  } catch (err) {
    yield put(actions.updateVehicleTaxActInsurance.failure(getErrorMsg(err)));
  }
  generateMsg();
}

export default function* watchmanagedocument() {
  yield all([
    takeEvery(actions.LOAD_VEHICLE_DATA.REQUEST, loadVehicleData),
    takeEvery(actions.LOAD_VEHICLE_DATA_EXPORT.REQUEST, loadVehicleDataExport),
    takeEvery(
      actions.UPDATE_VEHICLE_TAX_ACT_INSURANCE.REQUEST,
      updateVehicleTaxActInsurance
    ),
    takeEvery(actions.IMPORT_DOCUMENT.REQUEST, importDocument),
  ]);
}
