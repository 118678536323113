import axios from "axios";
import { all, put, call, takeEvery } from "redux-saga/effects";
import { getErrorMsg } from "lib/fetch";

import * as actions from "./actions";

function* loadDisplay(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { text } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/dashboardvideorealtime/getdisplaybyuser`,
      text,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(actions.loadDisplay.success(res.data));
  } catch (err) {
    yield put(actions.loadDisplay.failure());
  }
}

function* loadVideo(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { text, callback } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/dashboardvideorealtime/getvideorealtimelink`,
      text,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    callback(res.data);
    yield put(actions.loadVideo.success(res.data));
  } catch (err) {
    yield put(actions.loadVideo.failure());
  }
}

export default function* watchdashboardmonitoring() {
  yield all([takeEvery(actions.LOAD_DISPLAY.REQUEST, loadDisplay)]);
  yield all([takeEvery(actions.LOAD_VIDEO.REQUEST, loadVideo)]);
}
export { loadDisplay, loadVideo };
