import { createTypes, createAction } from "lib/action";

const LOAD_SUMMARYLINENOTIFICATION = createTypes("summarylinenotification", "load");

const loadSummaryLineNotification = {
  request: (data, loading) =>
    createAction(LOAD_SUMMARYLINENOTIFICATION.REQUEST, { data, loading }),
  success: data => createAction(LOAD_SUMMARYLINENOTIFICATION.SUCCESS, { data }),
  failure: () => createAction(LOAD_SUMMARYLINENOTIFICATION.FAILURE)
};

export {
  loadSummaryLineNotification,
  LOAD_SUMMARYLINENOTIFICATION,
};
