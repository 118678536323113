import React, { Component, Fragment } from "react";
import {
  List,
  Avatar,
  TreeSelect,
  Select,
  Form,
  Input,
  Slider,
  Spin,
  Modal,
  DatePicker,
} from "antd";

import LangContext, { i18n } from "modules/shared/context/langContext";

export class IconDescModal extends Component {
  state = {
    data: [
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.m.multiDropDescIncomingTrip}
          </LangContext.Consumer>
        ),
        image: <div className="alert state7-yellow"></div>,
        desc: (
          <LangContext.Consumer>
            {(i18n) => i18n.m.multiDropDescIncomingTripDesc}
          </LangContext.Consumer>
        ),
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.m.multiDropDescIncomingLateTrip}
          </LangContext.Consumer>
        ),
        image: <div className="alert state7-red"></div>,
        desc: (
          <LangContext.Consumer>
            {(i18n) => i18n.m.multiDropDescIncomingLateTripDesc}
          </LangContext.Consumer>
        ),
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.m.multiDropDescAlreadyVisitTrip}
          </LangContext.Consumer>
        ),
        image: <div className="alert state7-green"></div>,
        desc: (
          <LangContext.Consumer>
            {(i18n) => i18n.m.multiDropDescAlreadyVisitTripDesc}
          </LangContext.Consumer>
        ),
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.m.multiDropDescVisited}
          </LangContext.Consumer>
        ),
        image: <div className="alert state6-white"></div>,
        desc: (
          <LangContext.Consumer>
            {(i18n) => i18n.m.multiDropDescVisitedDesc}
          </LangContext.Consumer>
        ),
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.m.multiDropDescVisitedConfirm}
          </LangContext.Consumer>
        ),
        image: <div className="alert state6-yellow"></div>,
        desc: (
          <LangContext.Consumer>
            {(i18n) => i18n.m.multiDropDescVisitedConfirmDesc}
          </LangContext.Consumer>
        ),
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.m.multiDropDescVisitedLate}
          </LangContext.Consumer>
        ),
        image: <div className="alert state6-red"></div>,
        desc: (
          <LangContext.Consumer>
            {(i18n) => i18n.m.multiDropDescVisitedLateDesc}
          </LangContext.Consumer>
        ),
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.m.multiDropDescVisitedOnTime}
          </LangContext.Consumer>
        ),
        image: <div className="alert state6-green"></div>,
        desc: (
          <LangContext.Consumer>
            {(i18n) => i18n.m.multiDropDescVisitedOnTimeDesc}
          </LangContext.Consumer>
        ),
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.m.multiDropDescLeave}
          </LangContext.Consumer>
        ),
        image: <div className="alert state9-white"></div>,
        desc: (
          <LangContext.Consumer>
            {(i18n) => i18n.m.multiDropDescLeaveDesc}
          </LangContext.Consumer>
        ),
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.m.multiDropDescLeaveConfirm}
          </LangContext.Consumer>
        ),
        image: <div className="alert state9-yellow"></div>,
        desc: (
          <LangContext.Consumer>
            {(i18n) => i18n.m.multiDropDescLeaveConfirmDesc}
          </LangContext.Consumer>
        ),
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.m.multiDropDescLeaveLate}
          </LangContext.Consumer>
        ),
        image: <div className="alert state9-red"></div>,
        desc: (
          <LangContext.Consumer>
            {(i18n) => i18n.m.multiDropDescLeaveLateDesc}
          </LangContext.Consumer>
        ),
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.m.multiDropDescLeaveOnTime}
          </LangContext.Consumer>
        ),
        image: <div className="alert state9-green"></div>,
        desc: (
          <LangContext.Consumer>
            {(i18n) => i18n.m.multiDropDescLeaveOnTimeDesc}
          </LangContext.Consumer>
        ),
      },
    ],
  };

  render() {
    return (
      <Modal
        style={{ top: 20 }}
        title={
          <LangContext.Consumer>
            {(i18n) => i18n.i.iconDesc}
          </LangContext.Consumer>
        }
        visible={this.props.iconDetailVisible}
        onOk={this.props.handleIconDetailCancel}
        onCancel={this.props.handleIconDetailCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        okText={
          <LangContext.Consumer>{(i18n) => i18n.o.okay}</LangContext.Consumer>
        }
      >
        <List
          itemLayout="horizontal"
          dataSource={this.state.data}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={item.image}
                title={item.title}
                description={item.desc}
              />
            </List.Item>
          )}
        />
      </Modal>
    );
  }
}

export default IconDescModal;
