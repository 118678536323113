import { createTypes, createAction } from "lib/action";

const LOAD_MAINTENANCEDEVICECONCLUDE = createTypes("maintenancedeviceconclude", "load");
const LOAD_COMPANY_MAINTENANCEDEVICECONCLUDE = createTypes("company_maintenancedeviceconclude", "load");
const LOAD_MAINTENANCETYPE_MAINTENANCEDEVICECONCLUDE = createTypes("maintenancetype_maintenancedeviceconclude", "load");
const LOAD_TECHNICIAN_MAINTENANCEDEVICECONCLUDE = createTypes("technician_maintenancedeviceconclude", "load");
const LOAD_MAINTENANCESTATUS_MAINTENANCEDEVICECONCLUDE = createTypes("maintenancestatus_maintenancedeviceconclude", "load");
const LOAD_MASTERDETAILPROBLEM_MAINTENANCEDEVICECONCLUDE = createTypes("masterdetailproblem_maintenancedeviceconclude", "load");
const LOAD_ACCESSORY_MAINTENANCEDEVICECONCLUDE = createTypes("accessory_maintenancedeviceconclude", "load");

const loadMaintenancedeviceconclude = {
  request: (data, loading) =>
    createAction(LOAD_MAINTENANCEDEVICECONCLUDE.REQUEST, { data, loading }),
  success: data => createAction(LOAD_MAINTENANCEDEVICECONCLUDE.SUCCESS, { data }),
  failure: () => createAction(LOAD_MAINTENANCEDEVICECONCLUDE.FAILURE)
};



const loadCompany = {
  request: () => createAction(LOAD_COMPANY_MAINTENANCEDEVICECONCLUDE.REQUEST, {}),
  success: data => createAction(LOAD_COMPANY_MAINTENANCEDEVICECONCLUDE.SUCCESS, { data }),
  failure: () => createAction(LOAD_COMPANY_MAINTENANCEDEVICECONCLUDE.FAILURE)
};


const loadMaintenancetype = {
  request: () => createAction(LOAD_MAINTENANCETYPE_MAINTENANCEDEVICECONCLUDE.REQUEST, {}),
  success: data => createAction(LOAD_MAINTENANCETYPE_MAINTENANCEDEVICECONCLUDE.SUCCESS, { data }),
  failure: () => createAction(LOAD_MAINTENANCETYPE_MAINTENANCEDEVICECONCLUDE.FAILURE)
};


const loadTechnician = {
  request: () => createAction(LOAD_TECHNICIAN_MAINTENANCEDEVICECONCLUDE.REQUEST, {}),
  success: data => createAction(LOAD_TECHNICIAN_MAINTENANCEDEVICECONCLUDE.SUCCESS, { data }),
  failure: () => createAction(LOAD_TECHNICIAN_MAINTENANCEDEVICECONCLUDE.FAILURE)
};


const loadMaintenancestatus = {
  request: () => createAction(LOAD_MAINTENANCESTATUS_MAINTENANCEDEVICECONCLUDE.REQUEST, {}),
  success: data => createAction(LOAD_MAINTENANCESTATUS_MAINTENANCEDEVICECONCLUDE.SUCCESS, { data }),
  failure: () => createAction(LOAD_MAINTENANCESTATUS_MAINTENANCEDEVICECONCLUDE.FAILURE)
};

const loadMasterdetailproblem = {
  request: () => createAction(LOAD_MASTERDETAILPROBLEM_MAINTENANCEDEVICECONCLUDE.REQUEST, {}),
  success: data => createAction(LOAD_MASTERDETAILPROBLEM_MAINTENANCEDEVICECONCLUDE.SUCCESS, { data }),
  failure: () => createAction(LOAD_MASTERDETAILPROBLEM_MAINTENANCEDEVICECONCLUDE.FAILURE)
};

const loadAccessory = {
  request: () => createAction(LOAD_ACCESSORY_MAINTENANCEDEVICECONCLUDE.REQUEST, {}),
  success: data => createAction(LOAD_ACCESSORY_MAINTENANCEDEVICECONCLUDE.SUCCESS, { data }),
  failure: () => createAction(LOAD_ACCESSORY_MAINTENANCEDEVICECONCLUDE.FAILURE)
};

export {
  loadMaintenancedeviceconclude,
  LOAD_MAINTENANCEDEVICECONCLUDE,
  loadCompany,
  LOAD_COMPANY_MAINTENANCEDEVICECONCLUDE,
  loadMaintenancetype,
  LOAD_MAINTENANCETYPE_MAINTENANCEDEVICECONCLUDE,
  loadTechnician,
  LOAD_TECHNICIAN_MAINTENANCEDEVICECONCLUDE,
  loadMaintenancestatus,
  LOAD_MAINTENANCESTATUS_MAINTENANCEDEVICECONCLUDE,
  loadMasterdetailproblem,
  LOAD_MASTERDETAILPROBLEM_MAINTENANCEDEVICECONCLUDE,
  loadAccessory,
  LOAD_ACCESSORY_MAINTENANCEDEVICECONCLUDE
};
