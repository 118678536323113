import React, { Component, Fragment } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import {
  TreeSelect,
  Select,
  Form,
  Input,
  Slider,
  Spin,
  Modal,
  Button,
  DatePicker,
  Row,
  Col
} from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";

export class VerifyEmailModal extends Component {
    state = {
        data: {
            email: ""
        }
    }

    componentDidMount() {
        console.log(this.props.profile);
        this.setState({
            data: {
              ...this.state.data,
              email: this.props.profile.email
            }
          });
    }

    render() {
        return (
            // <Spin spinning={this.props.VerifyLoading}>
                <Modal
                    title={
                        <LangContext.Consumer>{i18n => i18n.v.verifyYourEmailAddress}</LangContext.Consumer>
                    }
                    visible={this.props.filterVisible}
                    onCancel={this.props.handleVerifyEmailCancel}
                    footer={null}
                >
                    <Spin spinning={this.props.VerifyLoading}>
                    <Formik 
                        enableReinitialize={true}
                        initialValues={this.state.data}
                        validationSchema={yup.object().shape({
                            email: yup.string().email(' Email is invalid').required(' Email is required'),
                        })}
                        onSubmit={(values, actions) => {
                            this.props.handleAddEmailWichVerifyEmail(values);
                        }}
                        render={({
                            values,
                            errors,
                            status,
                            handleBlur,
                            touched,
                            handleSubmit,
                            setFieldValue
                        }) => (
                            <div>
                                <Form  onSubmit={handleSubmit}>
                                    <Form.Item
                                        label={
                                        <LangContext.Consumer>
                                            {i18n => i18n.e.email}
                                        </LangContext.Consumer>
                                        }
                                        required={true}
                                        validateStatus={
                                            touched.email && errors.email ? "warning" : undefined
                                        }
                                        help={
                                            touched.email && errors.email
                                                ? errors.email.substr(errors.email.indexOf(" ") + 1)
                                                : undefined
                                        }
                                    >
                                        <Input
                                            type="text"
                                            name="email"
                                            value={values.email}
                                            onChange={e => {
                                                setFieldValue("email", e.target.value);
                                            }}
                                            autoComplete="off"
                                        />
                                    </Form.Item>
                                    <br></br>
                                    <Row>
                                        {/* <Col sm={24} md={19} lg={19} xl={19}/> */}
                                        <Col span={24} style={{ textAlign: 'right' }}>
                                            <Form.Item
                                            >
                                                <Button
                                                    type="default"
                                                    style={{
                                                        marginRight: "10px"
                                                    }}
                                                    onClick={this.props.handleVerifyEmailCancel}
                                                >
                                                    <LangContext.Consumer>
                                                        {i18n => i18n.c.cancel}
                                                    </LangContext.Consumer>
                                                </Button>
                                                <Button type="primary" htmlType="submit">
                                                    <LangContext.Consumer>
                                                        {i18n => i18n.s.save}
                                                    </LangContext.Consumer>
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        )}
                    />
                    </Spin>
                </Modal>
            // </Spin> 
        );
    }
}

export default VerifyEmailModal;
