import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getlocalstorage } from "./../../../constants/local_storage";
//import { push } from "connected-react-router";

import * as actions from "./actions";
import moment from "moment";

function* loadVehicleinformationdlt(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  const {
    data: { vehicle, date, vehicle_status, type_file }
  } = action.payload;
  const date_new = moment(date["_d"]).format("YYYY-MM-DD");

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicleinformationdlt`,
      {
        date: date_new,
        vehicle,
        vehicle_status,
        company_id: COMPANY_ID,
        vehicle_visibility,
        type_file
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    yield put(actions.loadVehicleinformationdlt.success(res.data));
    window.location.assign(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`)
    //window.location = res.data.data;
  } catch (err) {
    yield put(actions.loadVehicleinformationdlt.failure());
  }
}

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  const { plate_no } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebydlt`,
      {
        plate_no
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadVehicle.success(res.data));
  } catch (err) {
    yield put(actions.loadVehicle.failure());
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(
      actions.LOAD_VEHICLEINFORMATIONDLT.REQUEST,
      loadVehicleinformationdlt
    ),
    takeEvery(actions.LOAD_VEHICLE_VEHICLEINFORMATIONDLT.REQUEST, loadVehicle)
  ]);
}

export { loadVehicleinformationdlt, loadVehicle };
