import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";
import { getlocalstorage } from "./../../../constants/local_storage";

function* loadCompany(action) {
  const {
    data: { page, pageSize, orderBy, orderType, accessToken, filterObj }
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/company/get`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        filterObj
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadCompany.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadCompany.failure());
  }
}

function* loadCompanyDetail(action) {
  const {
    data: { id, accessToken }
  } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/company/detail/${id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadCompanyDetail.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadCompanyDetail.failure());
  }
}

function* createCompany(action) {
  const {
    data: {
      name,
      alias,
      company_code_sap,
      tax_no,
      address,
      phone,
      email,
      map_type,
      timezones_id,
      default_lang,
      default_lat,
      default_lng,
      name_ae,
      telno_ae,
      email_ae,
      vendor,
      google_map_key,
      longdo_map_key,
      accessToken
    },
    loading
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/createconpany`,
      {
        name,
        alias,
        company_code_sap,
        tax_no,
        address,
        phone,
        email,
        map_type,
        timezones_id,
        default_lang,
        default_lat,
        default_lng,
        name_ae,
        telno_ae,
        email_ae,
        google_map_key,
        longdo_map_key,
        vendor
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    loading(res.data);
    yield put(actions.createCompany.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.createCompany.failure());
  }
}

function* updateCompany(action) {
  const {
    data: {
      company_id,
      name,
      alias,
      company_code_sap,
      tax_no,
      address,
      phone,
      email,
      map_type,
      timezones_id,
      default_lang,
      default_lat,
      default_lng,
      name_ae,
      telno_ae,
      email_ae,
      vendor,
      google_map_key,
      longdo_map_key,
      accessToken
    },
    loading
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/company/update`,
      {
        company_id,
        name,
        alias,
        company_code_sap,
        tax_no,
        address,
        phone,
        email,
        map_type,
        timezones_id,
        default_lang,
        default_lat,
        default_lng,
        name_ae,
        telno_ae,
        email_ae,      
        google_map_key,
        longdo_map_key,
        vendor
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    loading(res.data);
    yield put(actions.updateCompany.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.updateCompany.failure());
  }
}

export default function* watchCompany() {
  yield all([
    takeEvery(actions.LOAD_COMPANY.REQUEST, loadCompany),
    takeEvery(actions.CREATE_COMPANY.REQUEST, createCompany),
    takeEvery(actions.LOAD_COMPANY_DETAIL.REQUEST, loadCompanyDetail),
    takeEvery(actions.UPDATE_COMPANY.REQUEST, updateCompany)
  ]);
}
export { createCompany, updateCompany, loadCompanyDetail, loadCompany };
