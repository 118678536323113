import React from "react";
import { Switch, Route } from "react-router-dom";
import PolicyCreate from "./Create";
import PolicyEdit from "./Edit";
import PolicyIndex from "./Index";

const PolicyRoutes = () => (
  <Switch>
    <Route path="/policy-new/create/" component={PolicyCreate} />
    <Route path="/policy-new/edit/:id" component={PolicyEdit} />
    <Route path="/policy-new" component={PolicyIndex} />
  </Switch>
);

export default PolicyRoutes;
