import { createTypes, createAction } from "lib/action";

const LOAD_TRUCKCURRENTLOCATION = createTypes("truckcurrentlocation", "load");
const LOAD_VEHICLE_TRUCKCURRENTLOCATION = createTypes(
  "vehicle_truckcurrentlocation",
  "load"
);
const LOAD_VEHICLE_TYPE_TRUCKCURRENTLOCATION = createTypes("vehicletype_truckcurrentlocation", "load");

const loadTruckcurrentlocation = {
  request: (data, loading) =>
    createAction(LOAD_TRUCKCURRENTLOCATION.REQUEST, { data, loading }),
  success: data => createAction(LOAD_TRUCKCURRENTLOCATION.SUCCESS, { data }),
  failure: () => createAction(LOAD_TRUCKCURRENTLOCATION.FAILURE)
};

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_TRUCKCURRENTLOCATION.REQUEST, {
      vehicletypeID,
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_VEHICLE_TRUCKCURRENTLOCATION.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TRUCKCURRENTLOCATION.FAILURE)
};

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_TRUCKCURRENTLOCATION.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_TYPE_TRUCKCURRENTLOCATION.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_TRUCKCURRENTLOCATION.FAILURE)
};

export {
  loadTruckcurrentlocation,
  LOAD_TRUCKCURRENTLOCATION,
  loadVehicle,
  LOAD_VEHICLE_TRUCKCURRENTLOCATION,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_TRUCKCURRENTLOCATION
};
