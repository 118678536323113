import React, { useEffect, useState } from "react";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import {
  Row,
  Col,
  Popover,
  Table,
  Select,
  Checkbox,
  Button,
  Spin,
  Tooltip,
  Modal,
  message,
} from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { connect } from "react-redux";
import axios from "axios";
import ModalBreak from "./ModalBreak";
import ModalSuperUserLogon from "./ModalSuperUserLogon";
import ModalAbsence from "./ModalAbsence";
import { checkPermissions } from "../../../../lib/helper";

const { Option } = Select;
const { confirm } = Modal;

const Index = ({ profile }) => {
  const [zoneName, setZoneName] = useState([]);
  const [selectZone, setSelectZone] = useState(0);
  const [plant, setPlant] = useState([]);
  const [selectPlant, setSelectPlant] = useState(0);
  const [status, setStatus] = useState([]);
  const [selectStatus, setSelectStatus] = useState(0);
  const [loadingZoneName, setLoadingZoneName] = useState(false);
  const [loadingPlant, setLoadingPlant] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [orderBy, setOrderBy] = useState("timework");
  const [order, setOrder] = useState("asc");
  const [visible, setVisible] = useState(false);
  const [selectDriver, setSelectDriver] = useState("");
  const [visibleSuperUserLogon, setVisibleSuperUserLogon] = useState(false);
  const [visibleAbsence, setVisibleAbsence] = useState(false);
  const [dataAbsence, setDataAbsence] = useState({
    data: {
      reason: "",
      status: "",
      comment: "",
      count: "NO",
    },
  });
  const [remarkAbsence, setRemarkAbsense] = useState("");
  const [radioAbsence, setRadioAbsence] = useState("");

  const onChangeRemark = (e) => {
    setRemarkAbsense(e.target.value);
  };

  const onChangeRadio = (e) => {
    setRadioAbsence(e.target.value);
  };

  const success = () => {
    message.success("Update Success");
  };

  const popupError = (text) => {
    message.error(text);
  };

  const handleChangeZoneName = (e) => {
    setSelectZone(e);
    loadPlant(e);
    fetchDataTable(selectPlant, e, selectStatus, orderBy, order);
  };

  const handleChangePlant = (e) => {
    setSelectPlant(e);
    fetchDataTable(e, selectZone, 0, orderBy, order);
  };

  const handleChangeStatus = (e) => {
    setSelectStatus(e);
    console.log("handleChangeStatus", e);
    fetchDataTable(selectPlant, selectZone, e, orderBy, order);
  };

  const handleChangeStatusTable = (record) => {
    updateStatus("", record);
  };

  const openModalBreak = (record) => {
    setVisible(true);
    setSelectDriver(record);
  };

  const closeModalBreak = () => {
    setVisible(false);
  };

  const openModalAbsence = (record) => {
    setVisibleAbsence(true);
    setSelectDriver(record);
  };

  const closeModalAbsence = () => {
    setVisibleAbsence(false);
  };

  const openModalVisibleSuperUserLogon = (record) => {
    setSelectDriver(record);
    setVisibleSuperUserLogon(true);
  };

  const closeModalVisibleSuperUserLogon = () => {
    setVisibleSuperUserLogon(false);
  };

  const updateStatus = async (cause_type, record) => {
    setLoading(true);
    closeModalVisibleSuperUserLogon();
    let res = await axios.post(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/driver-dashboard/togggle-status`,
      {
        driver_code: record.code,
        company_id: profile.company_id,
        clk: record.status == "Logoff" ? 0 : 1,
        user_id: profile.id,
        is_admin: profile.is_admin,
        cause_type,
      }
    );
    let errMsg = "";
    if (res.data.data != "") {
      let checkErr = JSON.parse(res.data.data);
      checkErr.result.map((item) => {
        if (item.error_code != "000") {
          errMsg += item.error_msg;
        }
      });
    }

    if (errMsg != "") {
      popupError(errMsg);
      setLoading(false);
    } else {
      success();
      fetchDataTable(selectPlant, selectZone, selectStatus, orderBy, order);
    }
  };

  const getDetailForAbsence = async (record) => {
    setLoading(true);
    let res = await axios.get(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/driver-dashboard/get-detail-for-absence?device_user_id=${record.device_users_id}&vehicle_id=${record.id}`
    );
    if (res.status === 200) {
      setDataAbsence(res.data.data);
      setRemarkAbsense(res.data.data.comment);
      setRadioAbsence(res.data.data.reason);
      if (res.data.status == "A" && res.data.count == "YES") {
        showConfirmAbsence(record);
      } else {
        showConfirmAbsence(record);
      }
    }
    setLoading(false);
  };

  const showConfirmAbsence = (record) => {
    confirm({
      title: "รถคันนี้ได้ทำการแจ้งขาดงานแล้ว ต้องการแก้ไขหรือไม่",
      onOk() {
        openModalAbsence(record);
      },
      onCancel() {
        closeModalAbsence();
      },
    });
  };

  const postBreakTruck = async (msgc, driver_code) => {
    setLoading(true);
    closeModalBreak();
    let res = await axios.post(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/driver-dashboard/break-truck`,
      {
        driver_code,
        company_id: profile.company_id,
        clk: -1,
        user_id: profile.id,
        msgc,
      }
    );
    let errMsg = "";
    if (res.data.data != "") {
      let checkErr = JSON.parse(res.data.data);
      checkErr.result.map((item) => {
        if (item.error_code != "000") {
          errMsg += item.error_msg;
        }
      });
    }
    if (errMsg != "") {
      popupError(errMsg);
      setLoading(false);
    } else {
      success();
      fetchDataTable(selectPlant, selectZone, selectStatus, orderBy, order);
    }
  };

  const postSaveDetailAbsence = async (
    vehicle_id,
    device_user_id,
    comment,
    reason,
    module,
    controller,
    action
  ) => {
    setLoading(true);
    closeModalAbsence();
    let res = await axios.post(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/driver-dashboard/save-detail-absence`,
      {
        vehicle_id: vehicle_id,
        device_user_id: device_user_id,
        comment: comment,
        reason: reason,
        module: module,
        controller: controller,
        action: action,
        company_id: profile.company_id,
        user_id: profile.id,
      }
    );
    if (res.status === 200) {
      success();
      fetchDataTable(selectPlant, selectZone, selectStatus, orderBy, order);
    }
  };

  const postChangeAbsence = async (
    vehicle_id,
    device_user_id,
    module,
    controller,
    action
  ) => {
    setLoading(true);
    closeModalAbsence();
    let res = await axios.post(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/driver-dashboard/change-absence`,
      {
        vehicle_id,
        device_user_id,
        module,
        controller,
        action,
        company_id: profile.company_id,
        user_id: profile.id,
      }
    );
    if (res.status === 200) {
      success();
      fetchDataTable(selectPlant, selectZone, selectStatus, orderBy, order);
    }
  };

  const postBreakAndUnBreak = async (driver_code) => {
    setLoading(true);
    closeModalBreak();
    let res = await axios.post(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/driver-dashboard/break-and-unbreak`,
      {
        driver_code,
        company_id: profile.company_id,
        clk: -2,
        user_id: profile.id,
        msgc: "",
      }
    );
    let errMsg = "";
    if (res.data.data != "") {
      let checkErr = JSON.parse(res.data.data);
      checkErr.result.map((item) => {
        if (item.error_code != "000") {
          errMsg += item.error_msg;
        }
      });
    }
    if (errMsg != "") {
      popupError(errMsg);
      setLoading(false);
    } else {
      success();
      fetchDataTable(selectPlant, selectZone, selectStatus, orderBy, order);
    }
  };

  const loadZone = async () => {
    let res = await axios.post(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/driver-dashboard/zone`,
      {
        company_id: profile.company_id,
        search: null,
      }
    );
    setZoneName(res.data.data);
  };

  const loadPlant = async (id) => {
    let location_visibility_arr = profile.location_visibility
      .split(",")
      .map(function(item) {
        return parseInt(item, 10);
      });

    let res = await axios.post(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/driver-dashboard/plant-by-zone`,
      {
        company_id: profile.company_id,
        zone: id,
        location_visibility: location_visibility_arr,
      }
    );
    setPlant(res.data.data);
  };

  useEffect(() => {
    fetchDataTable(selectPlant, selectZone, selectStatus, orderBy, order);

    setLoadingZoneName(true);
    setLoadingPlant(true);
    setLoadingStatus(true);

    loadZone();
    loadPlant(0);

    setStatus([
      { name: "LOG-ON", id: "Logon" },
      { name: "LOG-OFF", id: "Logoff" },
    ]);
    setLoadingZoneName(false);
    setLoadingPlant(false);
    setLoadingStatus(false);
  }, []);

  useEffect(() => {
    const interval = window.setInterval(() => {
      console.log(
        "in interval",
        selectPlant,
        selectZone,
        selectStatus,
        orderBy,
        order
      );
      fetchDataTable(selectPlant, selectZone, selectStatus, orderBy, order);
    }, 1000 * 6);
    return () => window.clearInterval(interval);
  });

  const selectCheckBox = (e) => {
    console.log("selectCheckBox", selectCheckBox);
  };

  const columns = [
    {
      title: "",
      dataIndex: "row_number",
      key: "row_number",
      width: "3%",
      align: "center",
      render(text, record, index) {
        return {
          children: <Checkbox onChange={() => selectCheckBox(record.id)} />,
        };
      },
    },
    {
      title: (
        <LangContext.Consumer>
          {(i18n) => i18n.r.remainingTime}
        </LangContext.Consumer>
      ),
      dataIndex: "timework",
      key: "timework",
      align: "center",
      width: "10%",
      sorter: true,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Extend at",
      dataIndex: "extend_at",
      key: "extend_at",
      width: "10%",
      align: "center",
      sorter: true,
      sortDirections: ["descend", "ascend"],
      render(text, record, index) {
        return {
          children: (
            <div>
              <p>{text == null ? "-" : text}</p>
            </div>
          ),
        };
      },
    },
    {
      title: (
        <LangContext.Consumer>{(i18n) => i18n.s.status}</LangContext.Consumer>
      ),
      dataIndex: "status",
      key: "status",
      width: "7%",
      align: "center",
      sorter: true,
      sortDirections: ["descend", "ascend"],
      render: (text, record, index) => {
        return {
          children: (
            <Popover
              content={
                <Row
                  type="flex"
                  justify="center"
                  align="middle"
                  gutter={[8, 8]}
                >
                  {text == "Logoff" && (
                    <>
                      {checkPermissions(
                        profile.permissions,
                        "logon.working",
                        "can_view"
                      ) == true ? (
                        <Col span={12} align="center">
                          <Button
                            type="link"
                            onClick={() => {
                              handleChangeStatusTable(
                                record,
                                record.row_number
                              );
                            }}
                          >
                            Logon
                          </Button>
                        </Col>
                      ) : (
                        ""
                      )}
                      {checkPermissions(
                        profile.permissions,
                        "Absence Working",
                        "can_view"
                      ) == true ? (
                        <Col span={12} align="center">
                          <Button
                            // type="link"
                            onClick={() => {
                              getDetailForAbsence(record);
                            }}
                          >
                            Absence
                          </Button>
                        </Col>
                      ) : (
                        ""
                      )}
                      {checkPermissions(
                        profile.permissions,
                        "su.logon.working",
                        "can_view"
                      ) == true ? (
                        <Col span={12} align="center">
                          <Button
                            onClick={() => {
                              openModalVisibleSuperUserLogon(record);
                            }}
                          >
                            SuperUserLogon
                          </Button>
                        </Col>
                      ) : (
                        ""
                      )}
                    </>
                  )}

                  {text == "Logon" && (
                    <>
                      {checkPermissions(
                        profile.permissions,
                        "logoff.working",
                        "can_view"
                      ) == true ? (
                        <Col span={12} align="center">
                          <Button
                            type="link"
                            onClick={() => {
                              handleChangeStatusTable(
                                record,
                                record.row_number
                              );
                            }}
                          >
                            Logoff
                          </Button>
                        </Col>
                      ) : (
                        ""
                      )}
                      {checkPermissions(
                        profile.permissions,
                        "break.working",
                        "can_view"
                      ) == true ? (
                        <Col span={12} align="center">
                          <Button
                            onClick={() => {
                              openModalBreak(record);
                            }}
                          >
                            Break
                          </Button>
                        </Col>
                      ) : (
                        ""
                      )}
                    </>
                  )}

                  {text == "Break" && (
                    <>
                      {checkPermissions(
                        profile.permissions,
                        "logoff.working",
                        "can_view"
                      ) == true ? (
                        <Col span={12} align="center">
                          <Button
                            type="link"
                            onClick={() => {
                              handleChangeStatusTable(
                                record,
                                record.row_number
                              );
                            }}
                          >
                            Logoff
                          </Button>
                        </Col>
                      ) : (
                        ""
                      )}
                      {checkPermissions(
                        profile.permissions,
                        "unbreak.working",
                        "can_view"
                      ) == true ? (
                        <Col span={12} align="center">
                          <Button
                            // type="link"
                            onClick={() => {
                              postBreakAndUnBreak(record.code);
                            }}
                          >
                            Unbreak
                          </Button>
                        </Col>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </Row>
              }
              trigger="hover"
            >
              <label style={{ cursor: "pointer" }}>
                {text == "Logoff" && "Logoff"}
                {text == "Logon" && "Logon"}
                {text == "Break" && "Break"}
              </label>
            </Popover>
          ),
        };
      },
    },
    {
      title: (
        <LangContext.Consumer>
          {(i18n) => i18n.f.firstLogOn}
        </LangContext.Consumer>
      ),
      align: "center",
      dataIndex: "logon_at",
      key: "logon_at",
      width: "15%",
      sorter: true,
      sortDirections: ["descend", "ascend"],
      render(text, record, index) {
        return {
          children: (
            <div>
              <p style={{ margin: 0 }}>{text == null ? "-" : text}</p>
              {!!record.logon_type ? `(${record.logon_type})` : ""}
              {/* {record.status_login == "Logon" && !!record.logon_type ? (
                <p>{`(${record.logon_type})`}</p>
              ) : (
                ""
              )} */}
            </div>
          ),
        };
      },
    },
    {
      title: (
        <LangContext.Consumer>
          {(i18n) => i18n.l.latestLogoff}
        </LangContext.Consumer>
      ),
      align: "center",
      dataIndex: "logoff_at",
      key: "logoff_at",
      width: "15%",
      sorter: true,
      sortDirections: ["descend", "ascend"],
      render(text, record, index) {
        return {
          children: (
            <div>
              <p style={{ margin: 0 }}>{!!text ? text : "-"}</p>
              {!!record.logoff_type ? `(${record.logoff_type})` : ""}
            </div>
          ),
        };
      },
    },
    {
      title: (
        <LangContext.Consumer>
          {(i18n) => i18n.d.driverCode}
        </LangContext.Consumer>
      ),
      align: "center",
      dataIndex: "code",
      key: "code",
      width: "8%",
      sorter: true,
      render(text, record, index) {
        return {
          children: (
            <div
              // type="link"
              style={{ color: "#1890ff", cursor: "pointer" }}
              onClick={() => {
                window.open(`/deviceuser/detail/${record.device_users_id}`);
              }}
            >
              {!!text && !/^\s*$/.test(text) ? text : "-"}
            </div>
          ),
        };
      },
    },
    {
      title: (
        <LangContext.Consumer>
          {(i18n) => i18n.d.driverName}
        </LangContext.Consumer>
      ),
      align: "center",
      dataIndex: "full_name",
      key: "full_name",
      width: "10%",
      sorter: true,
      sortDirections: ["descend", "ascend"],
      render(text, record, index) {
        return {
          children: (
            <div>
              <p style={{ margin: 0 }}>{text}</p>
              {record.mobile_phone != null && record.mobile_phone != "" ? (
                <p style={{ margin: 0 }}>{`(${record.mobile_phone})`}</p>
              ) : (
                ``
              )}
            </div>
          ),
        };
      },
    },
    {
      title: (
        <LangContext.Consumer>{(i18n) => i18n.v.vnCode}</LangContext.Consumer>
      ),
      align: "center",
      dataIndex: "vehicle_code",
      key: "vehicle_code",
      width: "8%",
      sorter: true,
      sortDirections: ["descend", "ascend"],
      render(text, record, index) {
        return {
          children: (
            <div
              // type="link"
              style={{ color: "#1890ff", cursor: "pointer" }}
              onClick={() => {
                window.open(`/vehicle/detail/${record.id}`);
              }}
            >
              {text}
            </div>
          ),
        };
      },
    },
    {
      title: (
        <LangContext.Consumer>
          {(i18n) => i18n.c.currentPlant}
        </LangContext.Consumer>
      ),
      align: "center",
      dataIndex: "plant_name",
      key: "plant_name",
      width: "14%",
      sorter: true,
      sortDirections: ["descend", "ascend"],
      render(text, record, index) {
        return {
          children: (
            <div
              // type="link"
              style={{ color: "#1890ff", cursor: "pointer" }}
              onClick={() => {
                window.open(`/location/detail/${record.plant_id}`);
              }}
            >
              {text}
            </div>
          ),
        };
      },
    },
  ];

  const fetchDataTable = async (plant_id, zone, status, orderBy, order) => {
    console.log("fetchDataTable", zone, plant_id, status, orderBy, order);
    const data = [];

    let vehicle_visibility_arr = profile.vehicle_visibility
      .split(",")
      .map(function(item) {
        return parseInt(item, 10);
      });
    setLoading(true);
    let res = await axios.post(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/driver-dashboard`,
      {
        company_id: profile.company_id,
        zone,
        vehicles_visibility: vehicle_visibility_arr,
        plant_id,
        status,
        orderBy,
        order,
      }
    );

    res.data.data.map((item, index) => {
      data.push({
        row_number: index + 1,
        timework: item.timework,
        extend_at: item.extend_at,
        status: item.status_login,
        logon_at: item.logon_at,
        logoff_at: item.logoff_at,
        code: item.code,
        full_name: `${item.full_name}`,
        mobile_phone: item.mobile_phone,
        device_users_id: item.device_users_id,
        vehicle_code: item.vehicle_code,
        id: item.id,
        plant_name: item.plant_name,
        plant_id: item && item.plant_id,
        logoff_type: item.logoff_type,
        logon_type: item.logon_type,
      });
    });

    setDataTable(data);
    setLoading(false);
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    if (sorter) {
      fetchDataTable(
        selectPlant,
        selectZone,
        selectStatus,
        sorter.order != undefined ? sorter.columnKey : "timework",
        sorter.order != undefined ? sorter.order : "ascend"
      );
      setOrder(sorter.order != undefined ? sorter.order : "ascend");
      setOrderBy(sorter.field);
    }
  };

  return (
    // <AuthorizeComponent matching_name="driver-dashboard">
    <Spin style={{ fontSize: "28px" }} spinning={loading}>
      <GeneralStyledContent>
        <LangContext.Consumer>
          {(i18n) => (
            <Row gutter={[8, 8]}>
              <Col align="right">
                {i18n.z.zoneName}{" "}
                <Select
                  defaultValue="0"
                  style={{ minWidth: 200 }}
                  loading={loadingZoneName}
                  onChange={handleChangeZoneName}
                >
                  <Option value="0" label={i18n.a.all}>
                    {i18n.a.all}
                  </Option>
                  {zoneName.map((item) => (
                    <Option value={item.id} label={item.name}>
                      <Tooltip placement="top" title={item.name}>
                        {item.name}
                      </Tooltip>
                    </Option>
                  ))}
                </Select>{" "}
                {i18n.p.plant}{" "}
                <Select
                  defaultValue="0"
                  style={{ minWidth: 200 }}
                  loading={loadingPlant}
                  onChange={handleChangePlant}
                >
                  <Option value="0" label={i18n.a.all}>
                    {i18n.a.all}
                  </Option>
                  {plant.map((item) => (
                    <Option value={item.id} label={item.name}>
                      <Tooltip
                        placement="top"
                        title={`(${item.code}) ${item.name}`}
                      >
                        <span>(</span>
                        {item.code}
                        <span>)</span> {item.name}
                      </Tooltip>
                    </Option>
                  ))}
                </Select>{" "}
                {i18n.s.status}{" "}
                <Select
                  defaultValue="0"
                  style={{ minWidth: 150 }}
                  loading={loadingStatus}
                  onChange={handleChangeStatus}
                >
                  <Option value="0" label={i18n.a.all}>
                    {i18n.a.all}
                  </Option>
                  {status.map((item) => (
                    <Option value={item.id} label={item.name}>
                      <Tooltip placement="top" title={item.name}>
                        {item.name}
                      </Tooltip>
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col align="center">
                <Table
                  columns={columns}
                  dataSource={dataTable}
                  pagination={false}
                  scroll={{ y: window.innerHeight - 190 }}
                  onChange={handleTableChange}
                />
              </Col>
            </Row>
          )}
        </LangContext.Consumer>
      </GeneralStyledContent>
      <ModalBreak
        closeModalBreak={closeModalBreak}
        visible={visible}
        selectDriver={selectDriver}
        postBreakTruck={postBreakTruck}
      />
      <ModalSuperUserLogon
        closeModalVisibleSuperUserLogon={closeModalVisibleSuperUserLogon}
        visibleSuperUserLogon={visibleSuperUserLogon}
        updateStatus={updateStatus}
        selectDriver={selectDriver}
      />
      <ModalAbsence
        postSaveDetailAbsence={postSaveDetailAbsence}
        postChangeAbsence={postChangeAbsence}
        closeModalAbsence={closeModalAbsence}
        visibleAbsence={visibleAbsence}
        selectDriver={selectDriver}
        dataAbsence={dataAbsence}
        radioAbsence={radioAbsence}
        remarkAbsence={remarkAbsence}
        onChangeRadio={onChangeRadio}
        onChangeRemark={onChangeRemark}
        setRemarkAbsense={setRemarkAbsense}
        setRadioAbsence={setRadioAbsence}
      />
    </Spin>
    // </AuthorizeComponent>
  );
};

export default connect(
  ({ auth, auth: { profile } }) => ({
    auth,
    profile,
  }),
  {}
)(Index);
