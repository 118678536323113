import { createTypes, createAction } from "lib/action";

const LOAD_FUELREMAINRATE = createTypes("fuelremainrate", "load");
const LOAD_VEHICLE_FUELREMAINRATE = createTypes(
  "vehicle_fuelremainrate",
  "load"
);
const LOAD_VEHICLE_TYPE_FUELREMAINRATE = createTypes(
  "vehicletype_fuelremainrate",
  "load"
);

const loadFuelRemainRate = {
  request: (data, loading) =>
    createAction(LOAD_FUELREMAINRATE.REQUEST, { data, loading }),
  success: data => createAction(LOAD_FUELREMAINRATE.SUCCESS, { data }),
  failure: () => createAction(LOAD_FUELREMAINRATE.FAILURE)
};

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_FUELREMAINRATE.REQUEST, {
      vehicletypeID,
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_VEHICLE_FUELREMAINRATE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_FUELREMAINRATE.FAILURE)
};

const loadVehicleType = {
  request: () =>
    createAction(LOAD_VEHICLE_TYPE_FUELREMAINRATE.REQUEST, {}),
  success: data =>
    createAction(LOAD_VEHICLE_TYPE_FUELREMAINRATE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_FUELREMAINRATE.FAILURE)
};

export {
  loadFuelRemainRate,
  LOAD_FUELREMAINRATE,
  loadVehicle,
  LOAD_VEHICLE_FUELREMAINRATE,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_FUELREMAINRATE
};
