import { createTypes, createAction } from "lib/action";

const LOAD_PRODUCT = createTypes("product", "load");
const CREATE_PRODUCT = createTypes("product", "create");
const UPDATE_PRODUCT = createTypes("product", "delete");
const GET_PRODUCT = createTypes("product", "get");
const CLEAR_PRODUCT = createTypes("product", "clear");

const CLEAR_RESPONSE = createTypes("response", "clear");


const loadProduct = {
  request: (data, loading) =>
    createAction(LOAD_PRODUCT.REQUEST, { data, loading }),
  success: data => createAction(LOAD_PRODUCT.SUCCESS, { data }),
  failure: () => createAction(LOAD_PRODUCT.FAILURE)
};

const createProduct = {
  request: (data, loading, history) =>
    createAction(CREATE_PRODUCT.REQUEST, {
      data,
      loading,
      history
    }),
  success: data => createAction(CREATE_PRODUCT.SUCCESS, { data }),
  failure: () => createAction(CREATE_PRODUCT.FAILURE)
};

const updateProduct = {
  request: (data, id, history, loading) =>
    createAction(UPDATE_PRODUCT.REQUEST, { data, id, history, loading }),
  success: data => createAction(UPDATE_PRODUCT.SUCCESS, { data }),
  failure: () => createAction(UPDATE_PRODUCT.FAILURE)
};


const getProductByID = {
  request: (id,token) => createAction(GET_PRODUCT.REQUEST, { id,token }),
  success: data => createAction(GET_PRODUCT.SUCCESS, { data }),
  failure: () => createAction(GET_PRODUCT.FAILURE)
};





const clearDataResponse = () => {
  return {
    type: CLEAR_RESPONSE
  };
};

const clearProduct = () => {
  return {
    type: CLEAR_PRODUCT
  };
};

export {
  LOAD_PRODUCT,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  GET_PRODUCT,
  loadProduct,
  createProduct,
  updateProduct,
  getProductByID,
  CLEAR_RESPONSE,
  clearDataResponse,
  clearProduct,
  CLEAR_PRODUCT
};
