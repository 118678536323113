import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ConcreteDelivery from './Index';
import ActivityOutSideArea from './ActivityOutSideArea';
import TrackingTrips from './TrackingTrips';
export default () => (
	<Switch>
		<Route path="/concrete_delivery/activity_outside_area/:id" component={ActivityOutSideArea} />
		<Route path="/concrete_delivery" component={ConcreteDelivery} />
		<Route path="/tracking/trips/:id" component={TrackingTrips} />
	</Switch>
);
