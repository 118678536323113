import {
  Badge,
  Button,
  Col,
  Icon,
  message,
  Pagination,
  Popconfirm,
  Row,
  Spin,
  Table,
  Tooltip,
} from "antd";
import React, { Component } from "react";
import * as actions from "../actions";

import { Link } from "react-router-dom";
import { checkPermissions } from "../../../../lib/helper";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";
import LangContext from "modules/shared/context/langContext";
import { connect } from "react-redux";
import Filter from "./Filter";
import { debounce } from "debounce";
import Breadcrumb from "../../../shared/components/Breadcrumb";

const columnStyle = { fontSize: "12px", fontWeight: "400" };
const initialFilter = {
  filterPhoneNo: "",
  filterPlateNo: "",
  filterCompanyID: "",
  page: 1,
};
class Index extends Component {
  state = {
    ...initialFilter,
    filterVisible: false,
  };
  componentDidMount() {
    this.props.load(initialFilter);
    this.props.loadCompany();
  }

  handlePageChange = (page) => {
    this.setState({ page });
    this.props.load({
      ...this.state,
      page: page,
    });
  };

  handleStateChange = (type, v) => {
    switch (type) {
      case "plateNo":
        this.setState({
          filterPlateNo: v.target.value,
        });
        break;
      case "phoneNo":
        this.setState({
          filterPhoneNo: v.target.value,
        });
        break;
      case "searchCompany":
        this.setState({ filterCompanyID: v });
        debounce(this.props.loadCompany(v), 1500);
        break;
      case "selectCompany":
        this.setState({ filterCompanyID: v });
        break;

      default:
        break;
    }
  };

  generateMsg = () => {
    switch (this.props.manageotp.status) {
      case "success":
        message.success(this.props.manageotp.msg);
        this.props.load(this.state);
        break;
      case "fail":
        message.error(this.props.manageotp.msg);
        break;
      default:
        break;
    }
  };

  render() {
    const columns = [
      {
        title: "#",
        dataIndex: "rowNumber",
        width: 60,
        render: (text, record, index) => {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{index + 1 + (this.state.page - 1) * 10}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.manageOTP.companyName}
          </LangContext.Consumer>
        ),
        dataIndex: "company_name",
        key: "company_name",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.manageOTP.phoneNumber}
          </LangContext.Consumer>
        ),
        dataIndex: "phone_number",
        key: "phone_number",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.manageOTP.totalVehicle}
          </LangContext.Consumer>
        ),
        dataIndex: "vehicle_count",
        key: "vehicle_count",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.r.remark}</LangContext.Consumer>
        ),
        dataIndex: "remark",
        key: "remark",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.manageOTP.updateAt}
          </LangContext.Consumer>
        ),
        dataIndex: "created_at",
        key: "created_at",
        width: 150,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.a.action}</LangContext.Consumer>
        ),
        dataIndex: "action",
        key: "action",
        width: 90,
        // fixed: "right",
        render: (text, record, index) => {
          return (
            <div>
              {checkPermissions(
                this.props.auth.profile.permissions,
                "vehicle",
                "can_edit"
              ) == true ? (
                <Tooltip
                  placement="left"
                  title={
                    <LangContext.Consumer>
                      {(i18n) => i18n.e.edit}
                    </LangContext.Consumer>
                  }
                  arrowPointAtCenter
                  style={{
                    padding: "0px 1px 0px 0px",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  <Link
                    to={`/manage-otp/edit/${record.phone_number}`}
                    style={{ padding: "0px 5px 0px 5px" }}
                  >
                    <Icon type="edit" />
                  </Link>
                </Tooltip>
              ) : (
                ""
              )}
              {checkPermissions(
                this.props.auth.profile.permissions,
                "vehicle",
                "can_delete"
              ) == true ? (
                <Tooltip
                  placement="left"
                  title={
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.delete}
                    </LangContext.Consumer>
                  }
                  arrowPointAtCenter
                  style={{
                    padding: "0px 1px 0px 0px",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  <Popconfirm
                    title={
                      <LangContext.Consumer>
                        {(i18n) => i18n.d.doYouWantToDeleteTheseItems}
                      </LangContext.Consumer>
                    }
                    okText={
                      <LangContext.Consumer>
                        {(i18n) => i18n.o.okay}
                      </LangContext.Consumer>
                    }
                    cancelText={
                      <LangContext.Consumer>
                        {(i18n) => i18n.c.cancel}
                      </LangContext.Consumer>
                    }
                    onConfirm={() => {
                      this.props.delete(
                        {
                          phone_number: record.phone_number,
                          user_id: this.props.profile.id,
                        },
                        this.generateMsg
                      );
                    }}
                  >
                    <a href="#" style={{ padding: "0px 5px 0px 5px" }}>
                      <Icon type="delete" />
                    </a>
                  </Popconfirm>
                </Tooltip>
              ) : (
                ""
              )}
            </div>
          );
        },
      },
    ];
    const sub_columns = [
      {
        title: "#",
        dataIndex: "rowNumber",
        width: 60,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{index + 1}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.p.plateNo}
          </LangContext.Consumer>
        ),
        dataIndex: "plate_no",
        key: "plate_no",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
    ];
    return (
      <AuthorizeComponent matching_name="manageotp">
        <Spin spinning={this.props.manageotp.loading}>
          <GeneralStyledContent>
            <Row gutter={24}>
              <Col span={24}>
                <Breadcrumb
                  customeurl={["/manage-otp", null, null]}
                  match={this.props.match}
                  style={{ margin: "0px 0px 14px 10px" }}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={15} sm={20} md={21} lg={22} xl={22}>
                <Link to="/manage-otp/create">
                  <Button
                    type="primary"
                    icon="plus"
                    size="small"
                    style={{ margin: "0px 0px 14px 0px" }}
                  >
                    <span></span>
                    <LangContext.Consumer>
                      {(i18n) => i18n.n.new}
                    </LangContext.Consumer>
                  </Button>
                </Link>
              </Col>
              <Col span={1}>
                <Badge dot={this.props.isFilter}>
                  <Button
                    icon="filter"
                    size="small"
                    onClick={(e) => {
                      this.setState({
                        filterVisible: true,
                      });
                    }}
                  >
                    <LangContext.Consumer>
                      {(i18n) => i18n.f.filter}
                    </LangContext.Consumer>
                  </Button>
                </Badge>
                <Filter
                  {...this.state}
                  companyLists={this.props.manageotp.companyLists}
                  onClose={() => this.setState({ filterVisible: false })}
                  handleStateChange={this.handleStateChange}
                  onFilterButtonClick={() => {
                    this.props.load({
                      ...this.state,
                      filterCompanyID: parseInt(this.state.filterCompanyID),
                    });
                    this.setState({ page: 1 });
                  }}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Table
                  id="main-table"
                  // showSorterTooltip={false}
                  bordered
                  columns={columns}
                  dataSource={this.props.manageotp.phoneWtihVehicleLists.data}
                  pagination={false}
                  rowKey="phone_number"
                  // scroll={{ y: 500 }}
                  expandedRowRender={(record) => {
                    return (
                      <Table
                        id="sub-table"
                        className="sub-table"
                        showSorterTooltip={false}
                        bordered
                        columns={sub_columns}
                        size="small"
                        dataSource={record.vehicle_list}
                        pagination={false}
                        scroll={{ y: 400 }}
                        style={{
                          margin: "5px 0px 10px 0px",
                        }}
                      />
                    );
                  }}
                />
              </Col>
            </Row>
            <Row type="flex" justify="end" style={{ padding: "15px" }}>
              <Col pan={24} align="right">
                <Pagination
                  defaultPageSize={10}
                  size="small"
                  current={this.state.page}
                  total={this.props.manageotp.phoneWtihVehicleLists.total}
                  onChange={this.handlePageChange}
                />
              </Col>
            </Row>
          </GeneralStyledContent>
        </Spin>
      </AuthorizeComponent>
    );
  }
}
const mapStateToProps = ({ auth, auth: { profile }, manageotp }) => ({
  auth,
  profile,
  manageotp,
});

const mapDispatchToProps = {
  load: actions.loadPhoneNoWithVehicle.request,
  loadCompany: actions.loadCompany.request,
  delete: actions.deleteMatchPhoneNoWithVehicle.request,
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
