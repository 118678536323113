import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../event/actions";
import Forms from "./Forms";
import { Row, Col } from "antd";
import Breadcrumb from "./../../../shared/components/Breadcrumbreport";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import { Redirect } from "react-router-dom";
import axios from "axios";

class Index extends Component {
  loadEvent = (values, loading) => {
    let body = {
      ...values,
      company_id: this.props.profile.company_id.toString(),
    };
    this.loadReportFuelNotify(body);
  };

  loadVehicle = (vehicletypeID, loading_vehicle) => {
    this.props.loadVehicle(vehicletypeID, loading_vehicle);
  };

  loadVehiclebygroupid = (vehicletypeID, loading_vehicle) => {
    this.props.loadVehiclebygroupid(vehicletypeID, loading_vehicle);
  };

  loadVehicleType = () => {
    this.props.loadVehicleType();
  };

  loadReportFuelNotify = (body) => {
    const res = axios
      .post(
        `${process.env.REACT_APP_API_REPORT_GOLANG_MONGO}/reports/go/fuelnotify`,
        {
          ...body,
        },
        {
          headers: {
            Authorization: `Bearer ${this.props.profile.accessToken}`,
          },
        }
      )
      .then(function(res) {
        window.location = res.data.result;
      });
  };

  render() {
    const {
      vehicleLists,
      vehicleTypeLists,
      vehiclebypolicyLists,
      vehiclebygroupLists,
      eventLoading,
    } = this.props.event;

    return (
      <AuthorizeComponent matching_name="report_fuelnotify">
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                sub_menu="general"
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <Forms
            onSubmit={this.loadEvent}
            vehicleLists={vehicleLists}
            vehicleTypeLists={vehicleTypeLists}
            vehiclebygroupLists={vehiclebygroupLists}
            vehiclebypolicyLists={vehiclebypolicyLists}
            eventLoading={eventLoading}
            loadVehicle={this.loadVehicle}
            loadVehicleType={this.loadVehicleType}
            loadVehiclebygroupid={this.loadVehiclebygroupid}
          />
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

const mapStateToProps = ({ event, auth: { profile } }) => ({
  event,
  profile,
});

const mapDispatchToProps = {
  loadVehicle: actions.loadVehicle.request,
  loadVehicleType: actions.loadVehicleType.request,
  loadVehiclebygroupid: actions.loadVehiclebygroupid.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
