import {
  LOAD_DRIVINGLICENSESCANBEHAVIOR,
  LOAD_VEHICLE_DRIVINGLICENSESCANBEHAVIOR,
  LOAD_VEHICLE_TYPE_DRIVINGLICENSESCANBEHAVIOR
} from "./actions";

const initialState = {
  drivinglicensescanbehaviorUrl: "",
  vehicleLists: [],
  vehicleTypeLists: [],
  drivinglicensescanbehaviorLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DRIVINGLICENSESCANBEHAVIOR.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        drivinglicensescanbehaviorLoading: loading
      };
    case LOAD_DRIVINGLICENSESCANBEHAVIOR.SUCCESS:
      return {
        ...state,
        drivinglicensescanbehaviorUrl: action.payload.data.data,
        drivinglicensescanbehaviorLoading: false
      };
    case LOAD_VEHICLE_DRIVINGLICENSESCANBEHAVIOR.REQUEST:
      const { loading_vehicle } = action.payload;
      return {
        ...state,
        drivinglicensescanbehaviorLoading: loading_vehicle
      };
    case LOAD_VEHICLE_DRIVINGLICENSESCANBEHAVIOR.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        drivinglicensescanbehaviorLoading: false
      };
    case LOAD_VEHICLE_TYPE_DRIVINGLICENSESCANBEHAVIOR.SUCCESS:
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data
      };
    default:
      return state;
  }
};
