import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import Forms from "./Forms";
import { Row, Col} from "antd";
import Breadcrumb from "./../../../shared/components/Breadcrumbreport";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import axios from "axios";

class Index extends Component {
  state = {
     companyList: [],
     vehiclegroupList:[],
     vehicleList:[]
  };



  createCalibratefuel = (values, loading) => {
    this.props.createCalibratefuel(values, loading,this.props.auth.accessToken);
  };

 
  loadVehicle = (company_id,vehicle_group_id) => {
    let self = this;
    axios
        .post(
          `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebygroupidcalibrate`,
          {
            companyID: company_id,
            vehicletypeID: vehicle_group_id
          },
          {
              headers: { Authorization: `Bearer ${this.props.auth.accessToken}` }
          }
        )
        .then(function(response) {
          if (typeof response.data.data !== "undefined") {
            let datas = response.data.data.map((data) => {
              return {
                key: data.id,
                value: data.plate_no,
              };
            });
            self.setState({ vehicleList: datas });
          }
        })
        .catch(function(error) {
          console.log(error);
        });
  };
  
  loadVehicleGroup = (company_id) => {
    let self = this;
    axios
        .post(
          `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getgroupvehicle`,
          {
            company_id,
          },
          {
              headers: { Authorization: `Bearer ${this.props.auth.accessToken}` }
          }
        )
        .then(function(response) {
          if (typeof response.data.data !== "undefined") {
            let datas = response.data.data.map((data) => {
              return {
                key: data.id,
                value: data.name,
              };
            });
            self.setState({ vehiclegroupList: datas });
          }
        })
        .catch(function(error) {
          console.log(error);
        });
  };

  loadCompany= () => {
    let self = this;
    axios
        .get(
          `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterDataUser_company`,
          {
            headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
          }
        )
        .then(function(response) {
          if (typeof response.data.data !== "undefined") {
            let companys = response.data.data.map((company) => {
              return {
                key: company.companies_id,
                value: company.companies_name,
              };
            });
            self.setState({ companyList: companys });
          }
        })
        .catch(function(error) {
          console.log(error);
        });
  };

  

  render() {

    const {
      companyList,
      vehiclegroupList,
      vehicleList
    } = this.state;

    const {
      calibratefuelLoading
    } = this.props.calibratefuel
    return (
    <AuthorizeComponent matching_name="calibratefuel">
      <GeneralStyledContent>
        <Row gutter={24}>
          <Col span={24}>
              <Breadcrumb
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
          </Col>
        </Row>
        <Forms
          onSubmit={this.createCalibratefuel}
          companyLists={companyList}
          vehicleLists={vehicleList}
          vehicleGroupLists={vehiclegroupList}
          loading={calibratefuelLoading}
          loadVehicle={this.loadVehicle}
          loadVehicleGroup={this.loadVehicleGroup}
          loadCompany={this.loadCompany}
        />
      </GeneralStyledContent>
       </AuthorizeComponent>
    );
  }
}

const mapStateToProps = ({ calibratefuel, auth: { profile },auth }) => ({
  calibratefuel,
  profile,
  auth
});

const mapDispatchToProps = {
  createCalibratefuel: actions.createCalibratefuel.request,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);
