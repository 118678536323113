import {
  LOAD_MAINTENANCEDEVICECONCLUDE,
  LOAD_COMPANY_MAINTENANCEDEVICECONCLUDE,
  LOAD_MAINTENANCETYPE_MAINTENANCEDEVICECONCLUDE,
  LOAD_TECHNICIAN_MAINTENANCEDEVICECONCLUDE,
  LOAD_MAINTENANCESTATUS_MAINTENANCEDEVICECONCLUDE,
  LOAD_MASTERDETAILPROBLEM_MAINTENANCEDEVICECONCLUDE,
  LOAD_ACCESSORY_MAINTENANCEDEVICECONCLUDE,
} from "./actions";

const initialState = {
  companyLists: [],
  maintenancetypeLists: [],
  technicianLists: [],
  maintenancestatusLists: [],
  masterdetailproblemLists: [],
  accessoryLists: [],
  maintenancedeviceconcludeLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_MAINTENANCEDEVICECONCLUDE.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        maintenancedeviceconcludeLoading: loading
      };
    case LOAD_MAINTENANCEDEVICECONCLUDE.SUCCESS:

      return {
        ...state,
        maintenancedeviceconcludeLoading: false
      };
    case LOAD_COMPANY_MAINTENANCEDEVICECONCLUDE.SUCCESS:
      return {
        ...state,
        companyLists: action.payload.data.data
      };
    case LOAD_MAINTENANCETYPE_MAINTENANCEDEVICECONCLUDE.SUCCESS:
      return {
        ...state,
        maintenancetypeLists: action.payload.data.data
      };
    case LOAD_TECHNICIAN_MAINTENANCEDEVICECONCLUDE.SUCCESS:
      return {
        ...state,
        technicianLists: action.payload.data.data
      };
    case LOAD_MAINTENANCESTATUS_MAINTENANCEDEVICECONCLUDE.SUCCESS:
      return {
        ...state,
        maintenancestatusLists: action.payload.data.data
      };
    case LOAD_MASTERDETAILPROBLEM_MAINTENANCEDEVICECONCLUDE.SUCCESS:
      return {
        ...state,
        masterdetailproblemLists: action.payload.data.data
      };
    case LOAD_ACCESSORY_MAINTENANCEDEVICECONCLUDE.SUCCESS:
      return {
        ...state,
        accessoryLists: action.payload.data.data
      };
    default:
      return state;
  }
};
