import { createTypes, createAction } from "lib/action";

const LOAD_DEVICE_USER = createTypes("deviceuser", "load");
const CREATE_DEVICE_USER = createTypes("createdeviceuser", "create");
const LOAD_DEVICE_USER_DETAIL =  createTypes("deviceuserdetail", "load");
const LOAD_DRIVEING_LICENSEL =  createTypes("drivinglicense", "load");
const LOAD_DRIVEING_TRAINING = createTypes("drivingtraining", "load");
const UPDATE_DEVICE_USER = createTypes("updatedeviceuser", "update");
const LOAD_AUTOCOMPLETE = createTypes("autocomplete", "load");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
    return {
      type: REMOVE_LISTS
    };
};

const loadDeviceUser = {
    request: (data, loading) =>
        createAction(LOAD_DEVICE_USER.REQUEST, { data, loading}),
    success: (lists, page) => 
        createAction(LOAD_DEVICE_USER.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_DEVICE_USER.FAITLURE)
};

const createDeviceUser = {
    request: (data, loading) =>
        createAction(CREATE_DEVICE_USER.REQUEST, { data, loading}),
    success: res => createAction(CREATE_DEVICE_USER.SUCCESS, { res }),
        failure: () => createAction(CREATE_DEVICE_USER.FAITLURE)
};

const loadDeviceuserDetail = {
    request: (data, loading) =>
        createAction(LOAD_DEVICE_USER_DETAIL.REQUEST, { data, loading}),
    success: (lists) => 
        createAction(LOAD_DEVICE_USER_DETAIL.SUCCESS, {lists}),
    failure: () => createAction(LOAD_DEVICE_USER_DETAIL.FAITLURE)
};

const updateDeviceUser = {
    request: (data, loading) =>
        createAction(UPDATE_DEVICE_USER.REQUEST, { data, loading}),
    success: res => createAction(UPDATE_DEVICE_USER.SUCCESS, { res }),
        failure: () => createAction(UPDATE_DEVICE_USER.FAITLURE)
};

const loadDatadrivinglicans = {
    request: (data, datadrivingloading) =>
        createAction(LOAD_DRIVEING_LICENSEL.REQUEST, { data, datadrivingloading}),
    success: (lists, page) => 
        createAction(LOAD_DRIVEING_LICENSEL.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_DRIVEING_LICENSEL.FAITLURE)
};

const loadDatadrivingtranining = {
    request: (data, datadrivingtraniningloading) =>
        createAction(LOAD_DRIVEING_TRAINING.REQUEST, { data, datadrivingtraniningloading}),
    success: (drivingtrainininglists, page) => 
        createAction(LOAD_DRIVEING_TRAINING.SUCCESS, {drivingtrainininglists, page}),
    failure: () => createAction(LOAD_DRIVEING_TRAINING.FAITLURE)
};

const loadAutocompleteLocation = {
    request: (url, companyID, accessToken, queryString) =>
      createAction(LOAD_AUTOCOMPLETE.REQUEST, {
        url,
        companyID,
        accessToken,
        queryString
      }),
    success: lists => createAction(LOAD_AUTOCOMPLETE.SUCCESS, { lists }),
    failure: () => createAction(LOAD_AUTOCOMPLETE.FAILURE)
  };


export {
    LOAD_DEVICE_USER,
    loadDeviceUser,
    createDeviceUser,
    CREATE_DEVICE_USER,
    loadDeviceuserDetail,
    LOAD_DEVICE_USER_DETAIL,
    loadDatadrivinglicans,
    LOAD_DRIVEING_LICENSEL,
    updateDeviceUser,
    UPDATE_DEVICE_USER,
    loadDatadrivingtranining,
    LOAD_DRIVEING_TRAINING,
    REMOVE_LISTS,
    loadAutocompleteLocation,
    LOAD_AUTOCOMPLETE,
    removeLists
};