import { createTypes, createAction } from "lib/action";


const LOAD_VEHICLE_HONEYTOASTPLAYBACK = createTypes(
  "vehicle_honeytoastplayback",
  "load"
);


const loadVehicle = {
  request: (allimei) => createAction(LOAD_VEHICLE_HONEYTOASTPLAYBACK.REQUEST, { allimei }),
  success: data => createAction(LOAD_VEHICLE_HONEYTOASTPLAYBACK.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_HONEYTOASTPLAYBACK.FAILURE)
};

export {
  loadVehicle,
  LOAD_VEHICLE_HONEYTOASTPLAYBACK
};
