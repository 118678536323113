import {
    REMOVE_LISTS,
    LOAD_VEHICLEGROUP,
    CREATE_VEHICLEGROUP,
    LOAD_VEHICLEGROUP_DETAIL,
    UPDATE_VEHICLEGROUP
} from "./actions";

const initialState = {
    lists: [],
    total: 1,
    loading: false,
    vehiclegroupDetailloading: false,
    draw: -1,
    success: ""

};

export default (state = initialState, action) => {

    switch (action.type) {

        case REMOVE_LISTS:
            return initialState;

        case LOAD_VEHICLEGROUP.REQUEST:
            const { loading } = action.payload;
            return {
                ...state,
                loading: loading
            };
        case LOAD_VEHICLEGROUP.SUCCESS:
            return {
                ...state,
                lists: action.payload.lists.data,
                total:
                    action.payload.lists.total - 1 <= 0
                        ? 1
                        : action.payload.lists.total <= 0
                            ? 1
                            : action.payload.lists.total,
                loading: false,
                draw: Math.floor(Math.random() * 999 + 1)
            };
        case LOAD_VEHICLEGROUP_DETAIL.REQUEST:
            return {
                ...state,
                loading: loading
            };

        case LOAD_VEHICLEGROUP_DETAIL.SUCCESS:
            return {
                ...state,
                lists: action.payload.lists.data,
                total:
                    action.payload.lists.total - 1 <= 0
                        ? 1
                        : action.payload.lists.total <= 0
                            ? 1
                            : action.payload.lists.total,
                loading: false,
                draw: Math.floor(Math.random() * 999 + 1)
            };
        case CREATE_VEHICLEGROUP.SUCCESS:
            return {
                ...state,
                loading: false
            };
        case UPDATE_VEHICLEGROUP.SUCCESS:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
};
