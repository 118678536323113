import {
  PICKUP_EXPORT_REPORT,
  LOAD_VEHICLE_PICKUP_DROPOFF,
  LOAD_VEHICLE_TYPE_PICKUP_DROPOFF,
} from './actions'

const initialState = {
  report_url: '',
  vehicleLists: [],
  vehicleTypeLists: [],
  loading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PICKUP_EXPORT_REPORT.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        loading: loading,
      }
    case PICKUP_EXPORT_REPORT.SUCCESS:
      return {
        ...state,
        report_url: action.payload.data.data,
        loading: false,
      }
    case LOAD_VEHICLE_PICKUP_DROPOFF.REQUEST:
      const { loading_vehicle } = action.payload
      return {
        ...state,
        loading: loading_vehicle,
      }
    case LOAD_VEHICLE_PICKUP_DROPOFF.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        loading: false,
      }
    case LOAD_VEHICLE_TYPE_PICKUP_DROPOFF.SUCCESS:
      // console.log(action.payload.data.data);
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data,
      }
    default:
      return state
  }
}
