import { createTypes, createAction } from 'lib/action'

// *remove lists
const REMOVE_LISTS = `REMOVE_LISTS`

// *ค้นหาข้อมูล
const CAMERA_STATUS_DASHBOARD_TABLE_LOG = createTypes(
  'cameraStatusDashboardTableLog',
  'load'
)

// *ดึงข้อมูล company list
const CAMERA_STATUS_DASHBOARD_COMPANY_LIST = createTypes(
  'cameraStatusDashboardCompanyList',
  'load'
)

// *remove lists
function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

// *ดึงข้อมูล table log
const cameraStatusDashboardTableLog = {
  request: (data) =>
    createAction(CAMERA_STATUS_DASHBOARD_TABLE_LOG.REQUEST, { data }),
  success: (lists) =>
    createAction(CAMERA_STATUS_DASHBOARD_TABLE_LOG.SUCCESS, { lists }),
  failure: () => createAction(CAMERA_STATUS_DASHBOARD_TABLE_LOG.FAITLURE),
}

// *ดึงข้อมูล company list
const cameraStatusDashboardCompanyList = {
  request: (data) =>
    createAction(CAMERA_STATUS_DASHBOARD_COMPANY_LIST.REQUEST, { data }),
  success: (lists) =>
    createAction(CAMERA_STATUS_DASHBOARD_COMPANY_LIST.SUCCESS, { lists }),
  failure: () => createAction(CAMERA_STATUS_DASHBOARD_COMPANY_LIST.FAITLURE),
}
export {
  CAMERA_STATUS_DASHBOARD_TABLE_LOG,
  cameraStatusDashboardTableLog,
  CAMERA_STATUS_DASHBOARD_COMPANY_LIST,
  cameraStatusDashboardCompanyList,
  REMOVE_LISTS,
  removeLists,
}
