import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import Dashboard from "./Dashboard";

export default () => (
  <Router>
    <div>
      <Route exact path="/cpf-dashboard" component={Dashboard} />
    </div>
  </Router>
);
