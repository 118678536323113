import { createTypes, createAction } from "lib/action";
const LOAD_PARTGROUP = createTypes("loadpartgroup", "load");
const LOAD_PARTGROUP_DETAIL = createTypes("loadpartgroupdetail", "load");
const CREATE_PARTGROUP = createTypes("createpartgroup", "create");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
    return {
        type: REMOVE_LISTS
    };
}

const loadPartGroup = {
    request: (data, loading) =>
        createAction(LOAD_PARTGROUP.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(LOAD_PARTGROUP.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_PARTGROUP.FAITLURE)  
};


const loadPartGroupDetail = {
    request: (data, loading) =>
        createAction(LOAD_PARTGROUP_DETAIL.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(LOAD_PARTGROUP_DETAIL.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_PARTGROUP_DETAIL.FAITLURE)  
};

const createPartGroup = {
    request: (data, loading) =>
        createAction(CREATE_PARTGROUP.REQUEST, { data, loading}),
    success: res => createAction(CREATE_PARTGROUP.SUCCESS, { res }),
        failure: () => createAction(CREATE_PARTGROUP.FAILURE)
};

export {
    CREATE_PARTGROUP, createPartGroup,
    LOAD_PARTGROUP_DETAIL, loadPartGroupDetail,
    LOAD_PARTGROUP, loadPartGroup,
    REMOVE_LISTS, removeLists
};