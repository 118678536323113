import { createTypes, createAction } from "lib/action";

const LOAD_LISTVEHICLEMDVR = createTypes("loadlistvehiclemdvr", "load");
const LOAD_VEHICLE_VIDEO = createTypes(
  "vehicle_video",
  "load"
);


const loadListVehicleMdvr = {
  request: (data, loading) =>
    createAction(LOAD_LISTVEHICLEMDVR.REQUEST, { data, loading }),
  success: data => createAction(LOAD_LISTVEHICLEMDVR.SUCCESS, { data }),
  failure: () => createAction(LOAD_LISTVEHICLEMDVR.FAILURE)
};

const loadVehicle = {
  request: () =>
    createAction(LOAD_VEHICLE_VIDEO.REQUEST, {}),
  success: data =>
    createAction(LOAD_VEHICLE_VIDEO.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_VIDEO.FAILURE)
};


export {
  loadListVehicleMdvr,
  LOAD_LISTVEHICLEMDVR,
  loadVehicle,
  LOAD_VEHICLE_VIDEO,
};
