import { createTypes, createAction } from "lib/action";
const LOAD_INSURANCE = createTypes("loadinsurance", "load");
const LOAD_AUTOCOMPLETE_PLATENO = createTypes("autocompleteplateno", "load");
const LOAD_AUTOCOMPLETE_CODE = createTypes("autocompletecode", "load");
const CREATE_INSURANCE = createTypes("insurance", "create");
const UPDATE_INSURANCE = createTypes("insurance", "update");
const REMOVE_LISTS = `REMOVE_LISTS`;
function removeLists() {
    return {
        type: REMOVE_LISTS
    };
}
const loadInsurance = {
    request: (data, loading) =>
        createAction(LOAD_INSURANCE.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(LOAD_INSURANCE.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_INSURANCE.FAITLURE)  
};

const loadAutocompleteVehiclePlateNo = {
    request: (url, companyID, accessToken, queryString) =>
      createAction(LOAD_AUTOCOMPLETE_PLATENO.REQUEST, {
        url,
        companyID,
        accessToken,
        queryString
      }),
    success: lists => createAction(LOAD_AUTOCOMPLETE_PLATENO.SUCCESS, { lists }),
    failure: () => createAction(LOAD_AUTOCOMPLETE_PLATENO.FAILURE)
};

const loadAutocompleteVehicleCode = {
    request: (url, companyID, accessToken, queryString) =>
      createAction(LOAD_AUTOCOMPLETE_CODE.REQUEST, {
        url,
        companyID,
        accessToken,
        queryString
      }),
    success: lists => createAction(LOAD_AUTOCOMPLETE_CODE.SUCCESS, { lists }),
    failure: () => createAction(LOAD_AUTOCOMPLETE_CODE.FAILURE)
};

const createInsurance = {
    request: (data, loading) =>
        createAction(CREATE_INSURANCE.REQUEST, { data, loading}),
    success: res => createAction(CREATE_INSURANCE.SUCCESS, { res }),
        failure: () => createAction(CREATE_INSURANCE.FAILURE)
};

const updateInsurance = {
    request: (data, loading) =>
      createAction(UPDATE_INSURANCE.REQUEST, {data,loading}),
    success: res => createAction(UPDATE_INSURANCE.SUCCESS, { res }),
    failure: () => createAction(UPDATE_INSURANCE.FAILURE)
  };

export {
    LOAD_INSURANCE, loadInsurance,
    LOAD_AUTOCOMPLETE_PLATENO, loadAutocompleteVehiclePlateNo,
    LOAD_AUTOCOMPLETE_CODE, loadAutocompleteVehicleCode,
    CREATE_INSURANCE, createInsurance,
    UPDATE_INSURANCE, updateInsurance,
    REMOVE_LISTS, removeLists
}