import {
  AutoComplete,
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Select,
} from "antd";

import { Formik } from "formik";
import React, { Component } from "react";

import { Link } from "react-router-dom";
import LangContext from "modules/shared/context/langContext";

import * as yup from "yup";
import FormDivider from "../../../shared/components/FormDivider";
import { debounce } from "debounce";

export default class Forms extends Component {
  createSelectOption = (items) => {
    return items.map((ele) => (
      <Select.Option value={ele.id}>{ele.name}</Select.Option>
    ));
  };
  render() {
    return (
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={this.props.initialValues}
          validationSchema={yup.object().shape({
            deposit_credit: yup
              .number()
              .required(
                <LangContext.Consumer>
                  {(i18n) => i18n.p.pleaseSelectVehicleRegistration}
                </LangContext.Consumer>
              )
              .nullable(),
          })}
          onSubmit={(values) => {
            this.props.onSubmit(values);
          }}
          render={({
            values,
            errors,
            status,
            handleBlur,
            touched,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form
              onSubmit={handleSubmit}
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
            >
              <FormDivider>
                <LangContext.Consumer>
                  {(i18n) => i18n.f.fuelCreditForm}
                </LangContext.Consumer>
              </FormDivider>
              <Form.Item
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.v.vehicleCode}
                  </LangContext.Consumer>
                }
              >
                <div>{this.props.vehicleInfo.code}</div>
              </Form.Item>
              <Form.Item
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.creditamount}
                  </LangContext.Consumer>
                }
              >
                <div>{this.props.vehicleInfo.fuel_credit} ลิตร</div>
              </Form.Item>
              <Form.Item
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.depositfuelcredit}
                  </LangContext.Consumer>
                }
                required
                validateStatus={
                  touched.deposit_credit && errors.deposit_credit && "warning"
                }
                help={touched.deposit_credit && errors.deposit_credit}
              >
                <Input
                  value={values.deposit_credit}
                  style={{ width: 150 }}
                  onChange={(v) => {
                    setFieldValue("deposit_credit", v.target.value);
                  }}
                ></Input>
                <span className="ant-form-text">&nbsp;ลิตร</span>
              </Form.Item>
              <Form.Item
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.depositFuelcreditReason}
                  </LangContext.Consumer>
                }
              >
                <Select
                  style={{ width: 250 }}
                  value={values.reason_id}
                  onSelect={(v) => {
                    setFieldValue("reason_id", v);
                  }}
                >
                  {this.createSelectOption(this.props.reasonList)}
                </Select>
              </Form.Item>
              <Form.Item
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.r.remark}
                  </LangContext.Consumer>
                }
              >
                <Input.TextArea
                  value={values.remark}
                  style={{ width: 250 }}
                  onChange={(v) => {
                    setFieldValue("remark", v.target.value);
                  }}
                ></Input.TextArea>
              </Form.Item>

              <Row type="flex" justify="end">
                <Col span={3}>
                  <Link to="/deposit-fuel-credit">
                    <Button
                      type="default"
                      style={{
                        margin: "0px 8px 0px 0px",
                      }}
                    >
                      <LangContext.Consumer>
                        {(i18n) => i18n.b.back}
                      </LangContext.Consumer>
                    </Button>
                  </Link>
                  <Button type="primary" htmlType="submit">
                    <LangContext.Consumer>
                      {(i18n) => i18n.s.save}
                    </LangContext.Consumer>
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        />
      </div>
    );
  }
}
