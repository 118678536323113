import { LOAD_DATA_CPF_DASHBOARD, REMOVE_LISTS } from "./actions";

const initialState = {
  loading: false,
  data_dashboard: [],
  count_status: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState;

    case LOAD_DATA_CPF_DASHBOARD.REQUEST:
      return {
        ...state,
        data_dashboard: [],
        loading: true,
      };

    case LOAD_DATA_CPF_DASHBOARD.SUCCESS:
      let data_dashboard = action.payload.lists.data;
      let count_status = action.payload.lists.count_status;

      return {
        ...state,
        data_dashboard,
        count_status,
        loading: false,
      };
    default:
      return state;
  }
};
