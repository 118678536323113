import React, { Component, Fragment } from 'react'
import {
    Row,
    Col,
    Button,
    Table,
    Icon,
    Progress,
    Spin,
    Popconfirm,
    Tooltip
} from "antd";

import LangContext from "modules/shared/context/langContext";
import * as helper from "./helperFx"

const columnStyle = { fontSize: "12px", fontWeight: "400" };

class AllData extends Component {
    render() {
        const column = [
            {
                title: "#",
                dataIndex: "row_number",
                width: "5%",
                align: "center",
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle,
                        },
                        children: <div>{text}</div>,
                    };
                },
            },
            {
                title: (
                    <LangContext.Consumer>
                        {(i18n) => i18n.p.plateNo}
                    </LangContext.Consumer>
                ),
                dataIndex: "plate_no",
                sorter: false,
                width: "18%",
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle,
                        },
                        children: <div>{text}</div>,
                    };
                },
            },
            {
                title: (
                    <LangContext.Consumer>
                        {(i18n) => i18n.f.fileName}
                    </LangContext.Consumer>
                ),
                dataIndex: "filename",
                sorter: false,
                // render: name => `${ name.first } ${ name.last }`,
                width: "20%",
                render: (text, record, index) => {
                    return {
                        props: {
                            style: columnStyle,
                        },
                        children: <div>{text}</div>,
                    };
                },
            },
            {
                title: (
                    <LangContext.Consumer>
                        {(i18n) => i18n.d.date_time}
                    </LangContext.Consumer>
                ),
                dataIndex: "file_datetime",
                sorter: false,
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle,
                        },
                        children: <div>{text}</div>,
                    };
                },
                width: "18%",
            },
            {
                title: (
                    <LangContext.Consumer>
                        {(i18n) => i18n.c.camera_position}
                    </LangContext.Consumer>
                ),
                dataIndex: "camera_source",
                sorter: false,
                width: "10%",
                render: (text, record, index) => {
                    return {
                        props: {
                            style: columnStyle,
                        },
                        children: (
                            <div>
                                {helper.getCameraText(text)}
                            </div>
                        ),
                    };
                },
            },
            {
                title: (
                    <LangContext.Consumer>
                        {(i18n) => i18n.f.file_size}
                    </LangContext.Consumer>
                ),
                dataIndex: "file_size",
                width: "10%",
                align: "center",
                render(text, record, index) {
                    let size = text / 1024 / 1024;
                    return {
                        props: {
                            style: columnStyle,
                        },
                        children: <div>{size.toFixed(2) + " MB"}</div>,
                    };
                },
            },
            {
                title: (
                    <LangContext.Consumer>{(i18n) => i18n.s.status}</LangContext.Consumer>
                ),
                dataIndex: "queue_status",
                width: "10%",
                align: "center",
                render: (text, record, index) => {
                    if (parseInt(text) === helper.queueStatusId("downloading")) {
                        return <Progress type="circle" percent={parseInt(record.download_progress)} width={50} />
                    }

                    return {
                        props: {
                            style: columnStyle,
                        },
                        children: (
                            <div>
                                {helper.getQueueStatus(text)}
                            </div>
                        ),
                    };
                },
            },
            {
                title: (
                    <LangContext.Consumer>{(i18n) => i18n.a.action}</LangContext.Consumer>
                ),
                dataIndex: "download_link",
                width: "8%",
                align: "center",
                render: (text, record, index) => {
                    const styletool = {
                        padding: "0px 1px 0px 0px",
                        marginLeft: "5px",
                        marginRight: "5px",
                    }

                    let deleteBtn = <Tooltip
                        placement="left"
                        title={<LangContext.Consumer>{(i18n) => i18n.d.delete}</LangContext.Consumer>}
                        arrowPointAtCenter
                        style={styletool}
                    >
                        <Popconfirm
                            title={
                                <LangContext.Consumer>
                                    {(i18n) => i18n.d.doYouWantToDeleteTheseItems}
                                </LangContext.Consumer>
                            }
                            onConfirm={() => {
                                this.props.action.delete(record.id, record.hwid, record.filename);
                            }}
                        >
                            <Button type="link" style={{ padding: "0px 5px 0px 5px" }}>
                                <Icon type="delete" />
                            </Button>
                        </Popconfirm>
                    </Tooltip>

                    if (record.queue_status === helper.queueStatusId("downloading")) {
                        //firmware เก่ายกเลิกไม่ได้
                        if (record.old_format) {
                            return <LangContext.Consumer>{(i18n) => i18n.d.downloading}</LangContext.Consumer>
                        }
                        return <Fragment>
                            <Button
                                type="link"
                                onClick={(e) => {
                                    this.props.action.cancel(record.id, record.hwid, record.filename)
                                }}
                                style={{ padding: "0px 15px 0px 5px" }}
                            >
                                <LangContext.Consumer>{(i18n) => i18n.c.cancel}</LangContext.Consumer>
                            </Button>
                        </Fragment>;
                    }

                    if (helper.queueStatusId("error") === record.queue_status && record.old_format) {
                        return deleteBtn
                    }

                    return <Fragment>
                        <Button
                            type="link"
                            onClick={(e) => {
                                if ([helper.queueStatusId("cancel"), helper.queueStatusId("error")].includes(record.queue_status)) {
                                    this.props.action.requeue(record.id, record.hwid, record.filename)
                                } else if (record.download_link) {
                                    helper.downloadFileVideo(record.download_link)
                                }
                            }}
                            style={{ padding: "0px 15px 0px 5px" }}
                        >
                            <Icon type="download" />
                        </Button>
                        {deleteBtn}
                    </Fragment>;
                },
            }
        ];

        return (
            <Fragment>
                <Row gutter={[24, 24]}>
                    <Col span={24} align="right">
                        <LangContext.Consumer>{(i18n) => i18n.manageOTP.updateAt}</LangContext.Consumer> : {this.props.updateAt}
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col span={24} align="right">
                        <Spin spinning={this.props.loading}>
                            <Table
                                bordered
                                size="small"
                                columns={column}
                                rowKey={(record) => "all_" + record.row_number.toString()}
                                dataSource={this.props.dataSource}
                                pagination={true}
                                loading={this.props.loading}
                            />
                        </Spin>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}


export default AllData;