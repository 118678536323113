import { LOAD_LOGINHISTORY } from "./actions";

const initialState = {
  loginhistoryUrl: "",
  loginhistoryLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_LOGINHISTORY.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        loginhistoryLoading: loading
      };
    case LOAD_LOGINHISTORY.SUCCESS:
      return {
        ...state,
        loginhistoryUrl: action.payload.data.data,
        loginhistoryLoading: false
      };
    default:
      return state;
  }
};
