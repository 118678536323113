import {
  LOAD_CONTACTMANAGEMENT,
  CREATE_CONTACTMANAGEMENT,
  CLEAR_RESPONSE_CONTACTMANAGEMENT
} from "./actions";

const initialState = {
  contactmanagementLists: [],
  contactmanagementTotal: 0,
  contactmanagementLoading: false,
  contactmanagementcreateLoading: false,
  dataResponse: { status: "", msg: "" },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CONTACTMANAGEMENT.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        contactmanagementLoading: loading
      };
    case LOAD_CONTACTMANAGEMENT.SUCCESS:
      return {
        ...state,
        contactmanagementLists: action.payload.data.data,
        contactmanagementTotal: action.payload.data.total,
        contactmanagementLoading: false
      };

    case CREATE_CONTACTMANAGEMENT.REQUEST:
      const { loading: loading_create } = action.payload;
      return {
        ...state,
        contactmanagementcreateLoading: loading_create
      };
    case CREATE_CONTACTMANAGEMENT.SUCCESS:
      return {
        ...state,
        dataResponse: { status: action.payload.data.status, msg: action.payload.data.msg },
        contactmanagementcreateLoading: false
      };
    case CLEAR_RESPONSE_CONTACTMANAGEMENT:
      return {
        ...state,
        dataResponse: { status: "", msg: "" }
      };
    default:
      return state;
  }
};
