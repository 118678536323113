import { createTypes, createAction } from 'lib/action'

const LOAD_SERVICEREPAIRDEVICE = createTypes('servicerepairdevice', 'load')
const LOAD_HISTORYSERVICEREPAIRDEVICE = createTypes(
  'historyservicerepairdevice',
  'load'
)
const CREATE_SERVICEREPAIRDEVICE = createTypes('servicerepairdevice', 'create')
const LOAD_VEHICLE_SERVICEREPAIRDEVICE = createTypes(
  'vehicle_servicerepairdevice',
  'load'
)
const LOAD_VEHICLETYPE_SERVICEREPAIRDEVICE = createTypes(
  'vehicletype_servicerepairdevice',
  'load'
)
const LOAD_PROVINCE_SERVICEREPAIRDEVICE = createTypes(
  'province_servicerepairdevice',
  'load'
)
const LOAD_AMPHUR_SERVICEREPAIRDEVICE = createTypes(
  'amphur_servicerepairdevice',
  'load'
)
const LOAD_PROBLEM_SERVICEREPAIRDEVICE = createTypes(
  'problem_servicerepairdevice',
  'load'
)
const LOAD_ZONE_SERVICEREPAIRDEVICE = createTypes(
  'zone_servicerepairdevice',
  'load'
)
const LOAD_MAINTENANCESTATUS_SERVICEREPAIRDEVICE = createTypes(
  'maintenancestatus_servicerepairdevice',
  'load'
)
const LOAD_MAINTENANCETYPE_SERVICEREPAIRDEVICE = createTypes(
  'maintenancetype_servicerepairdevice',
  'load'
)
const CLEAR_RESPONSE_SERVICEREPAIRDEVICE = createTypes(
  'response_servicerepairdevice',
  'clear'
)

const SERVICE_REPAIR_DEVICE_LOAD_VEHICLE_DATA = createTypes(
  'serviceRepairDeviceLoadVehicleData',
  'load'
)

const loadServicerepairdevice = {
  request: (data, loading) =>
    createAction(LOAD_SERVICEREPAIRDEVICE.REQUEST, { data, loading }),
  success: (data) => createAction(LOAD_SERVICEREPAIRDEVICE.SUCCESS, { data }),
  failure: () => createAction(LOAD_SERVICEREPAIRDEVICE.FAILURE),
}

const loadHistoryservicerepairdevice = {
  request: (data, loading) =>
    createAction(LOAD_HISTORYSERVICEREPAIRDEVICE.REQUEST, { data, loading }),
  success: (data) =>
    createAction(LOAD_HISTORYSERVICEREPAIRDEVICE.SUCCESS, { data }),
  failure: () => createAction(LOAD_HISTORYSERVICEREPAIRDEVICE.FAILURE),
}

const createServicerepairdevice = {
  request: (data, loading, history, company_id) =>
    createAction(CREATE_SERVICEREPAIRDEVICE.REQUEST, {
      data,
      loading,
      history,
      company_id,
    }),
  success: (data) => createAction(CREATE_SERVICEREPAIRDEVICE.SUCCESS, { data }),
  failure: (data) => createAction(CREATE_SERVICEREPAIRDEVICE.FAILURE, { data }),
}

const loadVehicle = {
  request: () => createAction(LOAD_VEHICLE_SERVICEREPAIRDEVICE.REQUEST, {}),
  success: (data) =>
    createAction(LOAD_VEHICLE_SERVICEREPAIRDEVICE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_SERVICEREPAIRDEVICE.FAILURE),
}

const loadVehicletype = {
  request: () => createAction(LOAD_VEHICLETYPE_SERVICEREPAIRDEVICE.REQUEST, {}),
  success: (data) =>
    createAction(LOAD_VEHICLETYPE_SERVICEREPAIRDEVICE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLETYPE_SERVICEREPAIRDEVICE.FAILURE),
}

const loadProvince = {
  request: () => createAction(LOAD_PROVINCE_SERVICEREPAIRDEVICE.REQUEST, {}),
  success: (data) =>
    createAction(LOAD_PROVINCE_SERVICEREPAIRDEVICE.SUCCESS, { data }),
  failure: () => createAction(LOAD_PROVINCE_SERVICEREPAIRDEVICE.FAILURE),
}

const loadAmphur = {
  request: (province_id, loading) =>
    createAction(LOAD_AMPHUR_SERVICEREPAIRDEVICE.REQUEST, {
      province_id,
      loading,
    }),
  success: (data) =>
    createAction(LOAD_AMPHUR_SERVICEREPAIRDEVICE.SUCCESS, { data }),
  failure: () => createAction(LOAD_AMPHUR_SERVICEREPAIRDEVICE.FAILURE),
}

const loadProblem = {
  request: () => createAction(LOAD_PROBLEM_SERVICEREPAIRDEVICE.REQUEST, {}),
  success: (data) =>
    createAction(LOAD_PROBLEM_SERVICEREPAIRDEVICE.SUCCESS, { data }),
  failure: () => createAction(LOAD_PROBLEM_SERVICEREPAIRDEVICE.FAILURE),
}

const loadZone = {
  request: () => createAction(LOAD_ZONE_SERVICEREPAIRDEVICE.REQUEST, {}),
  success: (data) =>
    createAction(LOAD_ZONE_SERVICEREPAIRDEVICE.SUCCESS, { data }),
  failure: () => createAction(LOAD_ZONE_SERVICEREPAIRDEVICE.FAILURE),
}

const loadMaintenancetype = {
  request: () =>
    createAction(LOAD_MAINTENANCETYPE_SERVICEREPAIRDEVICE.REQUEST, {}),
  success: (data) =>
    createAction(LOAD_MAINTENANCETYPE_SERVICEREPAIRDEVICE.SUCCESS, { data }),
  failure: () => createAction(LOAD_MAINTENANCETYPE_SERVICEREPAIRDEVICE.FAILURE),
}

const loadMaintenancestatus = {
  request: () =>
    createAction(LOAD_MAINTENANCESTATUS_SERVICEREPAIRDEVICE.REQUEST, {}),
  success: (data) =>
    createAction(LOAD_MAINTENANCESTATUS_SERVICEREPAIRDEVICE.SUCCESS, { data }),
  failure: () =>
    createAction(LOAD_MAINTENANCESTATUS_SERVICEREPAIRDEVICE.FAILURE),
}

const clearDataResponse = () => {
  return {
    type: CLEAR_RESPONSE_SERVICEREPAIRDEVICE,
  }
}

const serviceRepairDeviceLoadVehicleData = {
  request: (data) =>
    createAction(SERVICE_REPAIR_DEVICE_LOAD_VEHICLE_DATA.REQUEST, {
      data,
    }),
  success: (data) =>
    createAction(SERVICE_REPAIR_DEVICE_LOAD_VEHICLE_DATA.SUCCESS, { data }),
  failure: () => createAction(SERVICE_REPAIR_DEVICE_LOAD_VEHICLE_DATA.FAILURE),
}

export {
  LOAD_SERVICEREPAIRDEVICE,
  loadServicerepairdevice,
  LOAD_HISTORYSERVICEREPAIRDEVICE,
  loadHistoryservicerepairdevice,
  CREATE_SERVICEREPAIRDEVICE,
  createServicerepairdevice,
  LOAD_VEHICLE_SERVICEREPAIRDEVICE,
  loadVehicle,
  LOAD_VEHICLETYPE_SERVICEREPAIRDEVICE,
  loadVehicletype,
  LOAD_PROVINCE_SERVICEREPAIRDEVICE,
  loadProvince,
  LOAD_AMPHUR_SERVICEREPAIRDEVICE,
  loadAmphur,
  LOAD_PROBLEM_SERVICEREPAIRDEVICE,
  loadProblem,
  LOAD_MAINTENANCESTATUS_SERVICEREPAIRDEVICE,
  loadMaintenancestatus,
  LOAD_MAINTENANCETYPE_SERVICEREPAIRDEVICE,
  loadMaintenancetype,
  CLEAR_RESPONSE_SERVICEREPAIRDEVICE,
  clearDataResponse,
  LOAD_ZONE_SERVICEREPAIRDEVICE,
  loadZone,
  SERVICE_REPAIR_DEVICE_LOAD_VEHICLE_DATA,
  serviceRepairDeviceLoadVehicleData,
}
