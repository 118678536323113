import { createTypes, createAction } from "lib/action";

const LOAD_CUSTOMER = createTypes("customermasterdata", "load");
const LOAD_CUSTOMER_DETAIL = createTypes("customerdetailmasterdata", "load");
const CREATE_CUSTOMER = createTypes("customermasterdata", "create");
const UPDATE_CUSTOMER = createTypes("customermasterdata", "update");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
  return {
    type: REMOVE_LISTS
  };
}
const loadCustomer = {
  request: (data, loading) =>
    createAction(LOAD_CUSTOMER.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_CUSTOMER.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_CUSTOMER.FAILURE)
};

const loadCustomerDetail = {
  request: (data, cb) =>
    createAction(LOAD_CUSTOMER_DETAIL.REQUEST, { data, cb }),
  success: customer => createAction(LOAD_CUSTOMER_DETAIL.SUCCESS, { customer }),
  failure: () => createAction(LOAD_CUSTOMER_DETAIL.FAILURE)
};

const createCustomer = {
  request: (data, cb) =>
    createAction(CREATE_CUSTOMER.REQUEST, {
      data,
      cb
    }),
  success: res => createAction(CREATE_CUSTOMER.SUCCESS, { res }),
  failure: () => createAction(CREATE_CUSTOMER.FAILURE)
};

const updateCustomer = {
  request: (data, cb) =>
    createAction(UPDATE_CUSTOMER.REQUEST, {
      data,
      cb
    }),
  success: res => createAction(UPDATE_CUSTOMER.SUCCESS, { res }),
  failure: () => createAction(UPDATE_CUSTOMER.FAILURE)
};

export {
  CREATE_CUSTOMER,
  createCustomer,
  loadCustomer,
  LOAD_CUSTOMER,
  REMOVE_LISTS,
  removeLists,
  LOAD_CUSTOMER_DETAIL,
  loadCustomerDetail,
  updateCustomer,
  UPDATE_CUSTOMER
};
