import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import Forms from "./Forms";
import { Row, Col } from "antd";
import Breadcrumb from "./../../../shared/components/Breadcrumbreport";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import { Redirect } from "react-router-dom";

class Index extends Component {
  loadDrivinglog = (values, loading) => {
    this.props.loadDrivinglog(values, loading);
  };

  loadVehicle = (vehicletypeID, loading_vehicle) => {
    this.props.loadVehicle(vehicletypeID, loading_vehicle);
  };

  loadVehicleType = () => {
    this.props.loadVehicleType();
  };

  render() {

    const {
      vehicleLists,
      driverLists,
      vehicleTypeLists,
      drivinglogspecialLoading
    } = this.props.drivinglogspecial;
    return (
      // <AuthorizeComponent matching_name="report_drivinglogspecial">
      <GeneralStyledContent>
        <Row gutter={24}>
          <Col span={24}>
            <Breadcrumb
              sub_menu="behavior"
              match={this.props.match}
              style={{ margin: "0px 0px 14px 10px" }}
            />
          </Col>
        </Row>
        <Forms
          onSubmit={this.loadDrivinglog}
          vehicleLists={vehicleLists}
          driverLists={driverLists}
          vehicleTypeLists={vehicleTypeLists}
          drivinglogLoading={drivinglogspecialLoading}
          loadVehicle={this.loadVehicle}
          loadDriver={this.props.loadDriver}
          loadVehicleType={this.loadVehicleType}
        />
      </GeneralStyledContent>
      // </AuthorizeComponent>
    );
  }
}

const mapStateToProps = ({ drivinglogspecial, auth: { profile } }) => ({
  drivinglogspecial,
  profile
});

const mapDispatchToProps = {
  loadDrivinglog: actions.loadDrivinglog.request,
  loadVehicle: actions.loadVehicle.request,
  loadDriver: actions.loadDriver.request,
  loadVehicleType: actions.loadVehicleType.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);
