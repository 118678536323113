import { createTypes, createAction } from "lib/action";

const LOAD_LOGINHISTORY = createTypes("loginhistory", "load");

const loadLoginhistory = {
  request: (data, loading) =>
    createAction(LOAD_LOGINHISTORY.REQUEST, { data, loading }),
  success: data => createAction(LOAD_LOGINHISTORY.SUCCESS, { data }),
  failure: () => createAction(LOAD_LOGINHISTORY.FAILURE)
};


export {
  LOAD_LOGINHISTORY,
  loadLoginhistory,
};
