import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";
import {
  Form,
  DatePicker,
  AutoComplete,
  Select,
  Button,
  Modal,
  Badge,
} from "antd";
import LangContext from "modules/shared/context/langContext";
import { genDeviceProcessDetail } from "./deviceProcessFunc";

const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const { Option, OptGroup } = AutoComplete;

export default class Filter extends Component {
  state = {
    platenolists: [],
  };

  handleOpenDeviceProcessModal = () => {
    let html = genDeviceProcessDetail(this.props.deviceProcess.userList);

    Modal.warning({
      title: "อุปกรณ์นี้ กำลังมีการเชื่อมต่อการใช้งานวีดีโอดังนี้",
      content: <div dangerouslySetInnerHTML={{ __html: html }}></div>,
      okText: "ปิด",
    });
  };

  changeVehiclePlateno = (value, option, setFieldValue) => {
    setFieldValue("plate_no", option.props.data_plate);
    setFieldValue("imei", option.props.label);
    setFieldValue(
      "should_show_fileopt",
      this.convertToBoolean(option.props.data_otp)
    );
    setFieldValue("has_file_opt", this.convertToBoolean(option.props.data_otp));
    setFieldValue("device_types_id", option.props.data_type);
    setFieldValue("file_size", "large");
    setFieldValue("camera_position", "");
  };

  selectVehiclePlateno = (value, option, setFieldValue) => {
    setFieldValue("plate_no", option.props.data_plate);
    setFieldValue("imei", option.props.label);
    setFieldValue("has_file_opt", this.convertToBoolean(option.props.data_otp));
    setFieldValue(
      "should_show_fileopt",
      this.convertToBoolean(option.props.data_otp)
    );
    setFieldValue("device_types_id", option.props.data_type);
    setFieldValue("file_size", "large");
    setFieldValue("camera_position", "");
  };

  generateCameraOption = (imei) => {
    if (imei === "") {
      return this.defaultCameraOption();
    }

    let rec = this.props.vehicleOnly.find((x) => x.imei === imei);
    if (typeof rec === "undefined") {
      return this.defaultCameraOption();
    } else if (rec.camera_channel.length > 0) {
      let list = [{ index: "", name: "ทั้งหมด" }, ...rec.camera_channel];

      return list.map((x) => {
        return (
          <Option key={x.index.toString()} value={x.index.toString()}>
            {x.name}
          </Option>
        );
      });
    }

    return this.defaultCameraOption();
  };

  defaultCameraOption = () => {
    let list = [
      { value: "", label_index: "a", label: "all" },
      { value: "front", label_index: "f", label: "front" },
      { value: "back", label_index: "c", label: "camera_back" },
      { value: "extra", label_index: "c", label: "camera_extra" },
    ];

    return list.map((x) => {
      return (
        <Option value={x.value}>
          <LangContext.Consumer>
            {(i18n) => i18n[x.label_index][x.label]}
          </LangContext.Consumer>
        </Option>
      );
    });
  };

  convertToBoolean = (value) => {
    if (value === "true") {
      return true;
    }

    if (value === "false") {
      return false;
    }

    return value;
  };

  render() {
    const { onSubmit, vehicleLists, disabled } = this.props;

    const platenolists = vehicleLists
      .filter((x) => x.title !== "fileopt")
      .map((group) => {
        return (
          <OptGroup
            key={group.title}
            label={
              <span style={{ color: "blue" }}>
                <LangContext.Consumer>
                  {(i18n) => i18n.o[group.title]}
                </LangContext.Consumer>
              </span>
            }
          >
            {group.children.map((opt) => (
              <Option
                key={opt.id}
                label={opt.imei}
                data_plate={opt.plate_no}
                data_otp={opt.has_file_opt.toString()}
                data_type={opt.device_types_id}
                status={group.title === "offline" ? true : false}
                style={{
                  cursor: group.title === "offline" ? "not-allowed" : "pointer",
                }}
                // disabled={group.title === "offline" ? true : false}
              >
                {group.title === "online" ? (
                  <Badge color="#00BF06" text={opt.plate_no} />
                ) : (
                  <Badge color="#c91c1c" text={opt.plate_no} />
                )}
              </Option>
            ))}
          </OptGroup>
        );
      });

    return (
      <Formik
        initialValues={{
          plate_no: "",
          eventdate: [
            moment("00:00:00", "HH:mm:ss"),
            moment("23:59:59", "HH:mm:ss"),
          ],
          camera_position: "",
          file_size: "large",
          should_show_fileopt: false,
          device_types_id: "",
          has_file_opt: false,
        }}
        validate={(values) => {
          let errors = {};
          if (values.eventdate.length > 0) {
            const date_start = moment(values.eventdate[0]["_d"]);
            const date_end = moment(values.eventdate[1]["_d"]);
            let day_diff = date_end.diff(date_start, "days") + 1;
            if (day_diff > 1) {
              errors.eventdate = "ช่วงวันที่ที่เลือกไม่ควรเกิน 1 วัน";
            }
          }
          return errors;
        }}
        validationSchema={yup.object().shape({
          plate_no: yup.string().required("กรุณาเลือกทะเบียนพาหนะ"),
          eventdate: yup.array().required("กรุณาเลือกช่วงวันที่"),
        })}
        onSubmit={(values) => {
          onSubmit(values);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          handleFocus,
          setFieldValue,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit} layout={"inline"}>
            <FormItem>
              <Button
                className="blinking"
                type="danger"
                disabled={this.props.deviceProcess === null}
                icon="eye"
                size="small"
                onClick={this.handleOpenDeviceProcessModal}
              />
            </FormItem>
            <FormItem
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.p.plateNoVehicleCode}
                </LangContext.Consumer>
              }
              required
              validateStatus={touched.plate_no && errors.plate_no && "error"}
              help={touched.plate_no && errors.plate_no}
            >
              <Select
                value={values.plate_no}
                disabled={disabled}
                onChange={(value, option) => {
                  if (option.props.status) {
                    alert("เลือกรถที่ออฟไลน์ไม่ได้");
                    setFieldValue("plate_no", "");
                    return;
                  }
                  this.changeVehiclePlateno(value, option, setFieldValue);
                }}
                style={{ width: "300px" }}
                optionLabelProp="data_plate"
                filterOption={true}
                optionFilterProp="data_plate"
                showSearch
                onFocus={this.props.getVehicleList}
                loading={platenolists.length === 0 ? true : false}
              >
                {platenolists}
              </Select>
            </FormItem>

            <FormItem
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.c.camera_position}
                </LangContext.Consumer>
              }
              validateStatus={
                touched.camera_position && errors.camera_position && "error"
              }
              help={touched.camera_position && errors.camera_position}
            >
              <Select
                disabled={disabled}
                onChange={(value) => {
                  setFieldValue("camera_position", value);
                  // กล้องหน้า hnt เท่านั้นที่มีไฟล์เล็ก
                  if (values.device_types_id == 32) {
                    if (["front", ""].includes(value) && values.has_file_opt) {
                      setFieldValue("should_show_fileopt", true);
                    } else {
                      setFieldValue("should_show_fileopt", false);
                      setFieldValue("file_size", "large");
                    }
                  }

                  if (values.device_types_id == 37) {
                    if (value === "extra") {
                      setFieldValue("should_show_fileopt", false);
                      setFieldValue("file_size", "large");
                    } else {
                      setFieldValue("should_show_fileopt", true);
                    }
                  }
                }}
                value={values.camera_position}
              >
                {this.generateCameraOption(values.imei)}
              </Select>
            </FormItem>

            {values.should_show_fileopt && (
              <FormItem
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.f.file_size}
                  </LangContext.Consumer>
                }
              >
                <Select
                  onChange={(value) => setFieldValue("file_size", value)}
                  value={values.file_size}
                  disabled={disabled}
                >
                  <Option value="small">
                    <LangContext.Consumer>
                      {(i18n) => i18n.f.file_size_small}
                    </LangContext.Consumer>
                  </Option>
                  <Option value="large">
                    <LangContext.Consumer>
                      {(i18n) => i18n.f.file_size_large}
                    </LangContext.Consumer>
                  </Option>
                </Select>
              </FormItem>
            )}

            <Form.Item
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.d.dateRange}
                </LangContext.Consumer>
              }
              validateStatus={errors.eventdate && "error"}
              help={errors.eventdate}
            >
              <RangePicker
                disabled={disabled}
                showTime={{ format: "HH:mm:ss" }}
                onChange={(value) => setFieldValue("eventdate", value)}
                format="DD/MM/YYYY HH:mm:ss"
                placeholder={["Start Time", "End Time"]}
                defaultValue={[
                  moment("00:00:00", "HH:mm:ss"),
                  moment("23:59:59", "HH:mm:ss"),
                ]}
              />
            </Form.Item>

            <Form.Item>
              <Button htmlType="submit" type="primary" disabled={disabled}>
                <LangContext.Consumer>
                  {(i18n) => i18n.a.apply}
                </LangContext.Consumer>
              </Button>
            </Form.Item>
          </Form>
        )}
      />
    );
  }
}
