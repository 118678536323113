import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { USER_ID } from "./../../../constants/local_storage";
import * as actions from "./actions";

function* loadUsergroup(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      orderBy,
      orderType,
      accessToken
    }
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/usergroup`,
      // `http://booking-api/api/customer`,
      {
        searchName,
        page,
        pageSize,
        companyID,
        orderBy,
        orderType
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Access-Control-Allow-Origin": "*"
        }
      }
    );

    yield put(actions.loadUsergroup.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadUsergroup.failure());
  }
}

function* loadUsergroupDetail(action) {
  const {
    data: { id, accessToken }
  } = action.payload;
  // const company_id =
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/usergroup/detail/${id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    // cb(res.data[0]);
    // console.log(res.data.data);
    yield put(actions.loadUsergroupDetail.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadUsergroupDetail.failure());
  }
}

function* createUsergroup(action) {
  const {
    data: { name, is_base, company, permission, active, accessToken },
    cb
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/usergroup/create`,
      {
        name,
        is_base,
        active,
        company,
        permission,
        created_by: USER_ID
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    cb(res.data);
    yield put(actions.createUsergroup.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.createUsergroup.failure());
  }
}

function* updateUsergroup(action) {
  const {
    data: {
      usergroup_id,
      name,
      company,
      permission,
      is_base,
      active,
      accessToken
    },
    cb
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/usergroup/update`,
      {
        usergroup_id,
        name,
        company,
        permission,
        is_base,
        active,
        updated_by: USER_ID
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    cb(res.data);

    yield put(actions.updateUsergroup.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.updateUsergroup.failure());
  }
}

export default function* watchUsergroupMasterDataState() {
  yield all([
    takeEvery(actions.CREATE_USERGROUP.REQUEST, createUsergroup),
    takeEvery(actions.LOAD_USERGROUP.REQUEST, loadUsergroup),
    takeEvery(actions.LOAD_USERGROUP_DETAIL.REQUEST, loadUsergroupDetail),
    takeEvery(actions.UPDATE_USERGROUP.REQUEST, updateUsergroup)
  ]);
}

export { createUsergroup, loadUsergroup, loadUsergroupDetail, updateUsergroup };
