import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadGallry(action) {
    const {
      data: {
        page,
        pageSize,
        companyID,
        orderBy,
        orderType,
        accessToken,
        filterObj
      }
    } = action.payload;
  
    try {
      const res = yield call(
        axios.post,
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getimagefromgallery`,
        {
          page,
          pageSize,
          companyID,
          orderBy,
          orderType,
          filterObj
        },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      yield put(actions.loadGallry.success(res.data, page));
    } catch (err) {
      console.log(err.message);
      yield put(actions.loadGallry.failure());
    }
}

function* createImage(action) {
  const {
    data: {
      companyID,
      name,
      base64,
      accessToken
    },
    loading
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/createimagefromgallery`,
      {
        companyID,
        name,
        base64,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    loading(res.data);
    yield put(actions.createImage.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.createImage.failure());
  }
}

function* loadGallryDetail(action) {
  const {
    data: { id, accessToken }
  } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getimagefromgallery/detail/${id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadGallryDetail.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadGallryDetail.failure());
  }
}

function* updateGallery(action) {
  const {
    data: {
      id,
      name,
      base64,
      accessToken
    },
    loading
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/updateimagefromgallery`,
      {
        id,
        name,
        base64,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    loading(res.data);
    yield put(actions.updateGallery.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.updateGallery.failure());
  }
}

export default function* watchGallery() {
    yield all([
        takeEvery(actions.LOAD_GALLERY.REQUEST, loadGallry),
        takeEvery(actions.CREATE_IMAGE.REQUEST, createImage),
        takeEvery(actions.LOAD_GALLERY_DETAIL.REQUEST, loadGallryDetail),
        takeEvery(actions.UPDATE_GALLERY_DETAIL.REQUEST, updateGallery),
    ]);
}
export {
    loadGallry,
    createImage,
    loadGallryDetail,
    updateGallery
  };