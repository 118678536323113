import React, { Component } from "react";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { Row, Col, Badge, Divider, Spin, Modal, Statistic } from "antd";
import moment from "moment";
import { helper_convertDateformat } from "../../../../../lib/helper";
import { EventMap } from "./EventMap";
import EventTable from "./EventTable";
import EventMarker from "../../../../shared/components/map-assets/Marker/EventMarker";
import Data from "./mockData";
import axios from "axios";
import { GeneralStyledContent } from "../../../../../styled/common-styled";

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      center: {
        lat: 13.729852,
        lng: 100.559484,
      },
      zoom: 5,
      dataSource: [],
      loading: false,
      map: null,
      maps: null,
    };
  }
  async componentDidMount() {
    const queryStringUrlToAPi = this.props.location.search;

    this.setState({
      loading: true,
    });
    const response = await axios.post(
      `${process.env.REACT_APP_API_REPORT_LOGIN}test/request.php${queryStringUrlToAPi}`
      //s`${"http://localhost:3002/"}test/request.php${queryStringUrlToAPi}`
    );

    let tempDataSource = [];

    if (response.data.rows.length > 0) {
      const {
        timeline_speed_over,
        timeline_vehicle_stop_with_engine_on,
        timeline_aggressive_acceleration,
        timeline_suddenly_break,
      } = response.data.rows[0].variable.detail_timeline;
      tempDataSource = [
        timeline_speed_over &&
          timeline_speed_over.map((value) => ({
            ...value,
            name: "timeline_speed_over",
          })),
        timeline_vehicle_stop_with_engine_on &&
          timeline_vehicle_stop_with_engine_on.map((value) => ({
            ...value,
            name: "timeline_vehicle_stop_with_engine_on",
          })),
        timeline_aggressive_acceleration &&
          timeline_aggressive_acceleration.map((value) => ({
            ...value,
            name: "timeline_aggressive_acceleration",
          })),
        timeline_suddenly_break &&
          timeline_suddenly_break.map((value) => ({
            ...value,
            name: "timeline_suddenly_break",
          })),
      ].reduce((prev, cur) => prev.concat(cur), []);

      let reportType = decodeURIComponent(this.gup("report_type"));
      if (reportType == "by-driver" || reportType == "individual") {
        let driver = decodeURIComponent(this.gup("driver_name"));
        const { trip_code } = this.props.match.params;
        let tripCode = decodeURIComponent(trip_code);
        let dateTime = tripCode.split(" ถึง ");
        let start = dateTime[0];
        let end = dateTime[1];

        tempDataSource = tempDataSource.filter((ele) => {
          if (
            moment(ele.start_at) >= moment(start) &&
            moment(ele.start_at) <= moment(end)
          )
            return true;
          return false;
        });
      }
    } else {
    }

    this.setState({
      dataSource: tempDataSource,
      loading: false,
    });
  }

  gup(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(url);
    return results == null ? null : results[1];
  }

  handleChangeCenterMap = (center) => {
    this.state.map.setCenter({
      lat: parseFloat(center.lat),
      lng: parseFloat(center.lng),
    });
    this.state.map.setZoom(17);
  };

  handleApiLoaded = (map, maps) => {
    if (typeof maps == "undefined" || maps == null) window.location.reload();

    this.setState({
      map: map,
      maps: maps,
    });
  };

  render() {
    const { trip_code } = this.props.match.params;
    const { plate_no } = this.props.match.params;

    const query = new URLSearchParams(this.props.location.search);
    const driver_name = query.get("driver_name");

    return (
      <GeneralStyledContent>
        <Row>
          <Col
            sm={24}
            md={24}
            lg={14}
            xl={14}
            style={{ padding: "0px 10px 0px 0px" }}
          >
            <Row style={{ fontSize: "16px" }} gutter={24}>
              <Col span={16}>
                <Statistic
                  valueStyle={{ fontSize: "14px" }}
                  title={
                    <LangContext.Consumer>
                      {(i18n) => "ข้อมูลทริป วันที่/เวลา"}
                    </LangContext.Consumer>
                  }
                  value={trip_code != null ? "" + trip_code : ""}
                />
              </Col>
              <Col span={8}>
                <Statistic
                  valueStyle={{ fontSize: "14px" }}
                  title={
                    <LangContext.Consumer>
                      {(i18n) => "ทะเบียนรถ"}
                    </LangContext.Consumer>
                  }
                  value={plate_no != null ? "" + plate_no : ""}
                />
              </Col>
              {/* <Col span={15}>
                <b>ข้อมูลทริป วันที่/เวลา</b>
              </Col>
              <Col span={9}>
                <b>ทะเบียนรถ</b>
              </Col> */}
            </Row>
            {/* <Row style={{ fontSize: "14px" }} gutter={24}>
              <Col span={15}>{trip_code}</Col>
              <Col span={9}>{plate_no}</Col>
            </Row> */}
            <Divider orientation="left"></Divider>
            <Row>
              <EventTable
                handleChangeCenterMap={this.handleChangeCenterMap}
                loading={this.state.loading}
                dataSource={this.state.dataSource}
                driverName={driver_name}
              />
            </Row>
          </Col>
          <Col sm={24} md={24} lg={10} xl={10}>
            <div style={{ height: "86vh", width: "100%" }}>
              <EventMap
                bootstrapURLKeys={{
                  key: this.props.auth.profile.google_map_key,
                  language: "en",
                }}
                center={this.state.center}
                zoom={this.state.zoom}
                options={{
                  mapTypeControl: false,
                  fullscreenControl: false,
                  zoomControl: false,
                  maxZoom: 19,
                }}
                onGoogleApiLoaded={({ map, maps }) =>
                  this.handleApiLoaded(map, maps)
                }
              >
                {this.state.dataSource.map((value) => {
                  return (
                    <EventMarker
                      img="/img/map/status_icon/32_32/speed30.png"
                      lat={value.lat}
                      lng={value.lng}
                      // title={value.name}
                    />
                  );
                })}
              </EventMap>
            </div>
          </Col>
        </Row>
      </GeneralStyledContent>
    );
  }
}

export default Index;
