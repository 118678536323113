import { createTypes, createAction } from "lib/action";

const LOAD_MANAGE_DASHBOARD = createTypes("load_manage_dashboard", "load");
const CREATE_MANAGE_DASHBOARD = createTypes(
  "create_manage_dashboard",
  "create"
);
const UPDATE_MANAGE_DASHBOARD = createTypes(
  "update_manage_dashboard",
  "update"
);

const DELETE_MANAGE_DASHBOARD = createTypes(
  "delete_manage_dashboard",
  "delete"
);

const LOAD_MANAGE_DASHBOARD_USER = createTypes(
  "load_manage_dashboard_user",
  "load"
);
const LOAD_LAYOUT_DISPLAY = createTypes("load_layout_display", "load");

const LOAD_DISPLAY_DETAIL = createTypes("loadDisplayDetail", "load");

const loadManageDashboard = {
  request: (text) => createAction(LOAD_MANAGE_DASHBOARD.REQUEST, { text }),
  success: (data) => createAction(LOAD_MANAGE_DASHBOARD.SUCCESS, { data }),
  failure: () => createAction(LOAD_MANAGE_DASHBOARD.FAILURE),
};

const createManageDashboard = {
  request: (text, generateMsg) =>
    createAction(CREATE_MANAGE_DASHBOARD.REQUEST, { text, generateMsg }),
  success: (data) => createAction(CREATE_MANAGE_DASHBOARD.SUCCESS, { data }),
  failure: () => createAction(CREATE_MANAGE_DASHBOARD.FAILURE),
};

const updateManageDashboard = {
  request: (text, generateMsg) =>
    createAction(UPDATE_MANAGE_DASHBOARD.REQUEST, { text, generateMsg }),
  success: (data) => createAction(UPDATE_MANAGE_DASHBOARD.SUCCESS, { data }),
  failure: () => createAction(UPDATE_MANAGE_DASHBOARD.FAILURE),
};

const deleteManageDashboard = {
  request: (text, generateMsg) =>
    createAction(DELETE_MANAGE_DASHBOARD.REQUEST, { text, generateMsg }),
  success: (data) => createAction(DELETE_MANAGE_DASHBOARD.SUCCESS, { data }),
  failure: () => createAction(DELETE_MANAGE_DASHBOARD.FAILURE),
};

const loadManageDashboardUser = {
  request: (text) => createAction(LOAD_MANAGE_DASHBOARD_USER.REQUEST, { text }),
  success: (data) => createAction(LOAD_MANAGE_DASHBOARD_USER.SUCCESS, { data }),
  failure: () => createAction(LOAD_MANAGE_DASHBOARD_USER.FAILURE),
};

const loadLayoutDisplay = {
  request: (text) => createAction(LOAD_LAYOUT_DISPLAY.REQUEST, { text }),
  success: (data) => createAction(LOAD_LAYOUT_DISPLAY.SUCCESS, { data }),
  failure: () => createAction(LOAD_LAYOUT_DISPLAY.FAILURE),
};

const loadDisplayDetail = {
  request: (text) => createAction(LOAD_DISPLAY_DETAIL.REQUEST, { text }),
  success: (data) => createAction(LOAD_DISPLAY_DETAIL.SUCCESS, { data }),
  failure: () => createAction(LOAD_DISPLAY_DETAIL.FAILURE),
};

export {
  loadManageDashboard,
  LOAD_MANAGE_DASHBOARD,
  loadManageDashboardUser,
  LOAD_MANAGE_DASHBOARD_USER,
  loadLayoutDisplay,
  LOAD_LAYOUT_DISPLAY,
  createManageDashboard,
  CREATE_MANAGE_DASHBOARD,
  loadDisplayDetail,
  LOAD_DISPLAY_DETAIL,
  updateManageDashboard,
  UPDATE_MANAGE_DASHBOARD,
  deleteManageDashboard,
  DELETE_MANAGE_DASHBOARD,
};
