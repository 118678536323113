import React, { useState } from "react";
import { Radio, Row, Col, Modal, Input, Button } from "antd";

const { TextArea } = Input;

const ModalAbsence = ({
  postSaveDetailAbsence,
  postChangeAbsence,
  closeModalAbsence,
  visibleAbsence,
  selectDriver,
  dataAbsence,
  onChangeRemark,
  onChangeRadio,
  remarkAbsence,
  radioAbsence,
  setRemarkAbsense,
  setRadioAbsence,
}) => {
  // console.log("dataAbsence", dataAbsence);
  // const [value, setValue] = useState(
  //   dataAbsence.reason != "" ? parseInt(dataAbsence.reason) : ""
  // );
  // const [remark, setRemark] = useState(dataAbsence.comment);
  // const onChange = (e) => {
  //   console.log("radio checked", e.target.value);
  //   setValue(e.target.value);
  // };

  return (
    <Modal
      title={selectDriver.code}
      visible={visibleAbsence}
      onOk={() => closeModalAbsence()}
      onCancel={() => {
        setRadioAbsence(
          dataAbsence.reason != "" ? parseInt(dataAbsence.reason) : ""
        );
        setRemarkAbsense("");
        closeModalAbsence();
      }}
      footer={[
        <Button
          key="1"
          type="primary"
          onClick={() => {
            setRadioAbsence(
              dataAbsence.reason != "" ? parseInt(dataAbsence.reason) : ""
            );
            setRemarkAbsense("");
            postSaveDetailAbsence(
              selectDriver.id,
              selectDriver.device_users_id,
              remarkAbsence,
              radioAbsence,
              "Driver Dashboard",
              "DriverAbsenceController",
              "save"
            );
          }}
        >
          ตกลง
        </Button>,
        <Button
          key="2"
          type="primary"
          onClick={() => {
            setRadioAbsence(
              dataAbsence.reason != "" ? parseInt(dataAbsence.reason) : ""
            );
            setRemarkAbsense("");
            closeModalAbsence();
          }}
        >
          ยกเลิก
        </Button>,
        dataAbsence.status == "A" && dataAbsence.count == "YES" ? (
          <Button
            key="3"
            type="primary"
            onClick={() => {
              setRadioAbsence(
                dataAbsence.reason != "" ? parseInt(dataAbsence.reason) : ""
              );
              setRemarkAbsense("");
              postChangeAbsence(
                selectDriver.id,
                selectDriver.device_users_id,
                "Driver Dashboard",
                "DriverAbsenceController",
                "delete"
              );
            }}
          >
            ยกเลิกการแจ้งขาดงาน
          </Button>
        ) : null,
      ]}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Radio.Group onChange={onChangeRadio} value={parseInt(radioAbsence)}>
            <Radio value={0}>รถเสีย</Radio>
            <Radio value={1}>ไม่รับงาน</Radio>
          </Radio.Group>
        </Col>
        <Col span={24}>Remark</Col>
        <Col span={24}>
          <TextArea
            placeholder="remark"
            rows={4}
            onChange={onChangeRemark}
            value={remarkAbsence}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalAbsence;
