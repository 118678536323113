import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  AutoComplete,
  Icon,
  Select,
} from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const { Option } = AutoComplete;
export default class Filter extends Component {
  state = {
    platenoList: [],
    selectPlateNo: "",
  };

  componentDidMount() {
    const { loadVehicle } = this.props;
    loadVehicle();
  }

  componentWillReceiveProps = (nextProps) => {
    const { vehicleLists } = nextProps;
    // console.log("vehicleLists", vehicleLists);
    const platenolists = [];
    vehicleLists.map((item) =>
      platenolists.push({
        key: item.id,
        name: item.name,
        code: item.code,
        devidno: item.devidno,
        online: item.online,
        plate_no: item.plate_no
      })
    );

    this.setState({ platenoList: platenolists });
  };

  changeVehiclePlateno = (value, option, setFieldValue) => {
    this.setState({
      selectPlateNo: option.props.children,
    });
    setFieldValue("vehicle_plate_no", option.props.plate_no);
    setFieldValue("plate_no", option.props.plate_no);
    setFieldValue("devidno", option.props.label);
  };

  selectVehiclePlateno = (value, option, setFieldValue) => {
    //console.log(option);
    this.setState({
      selectPlateNo: option.props.children,
    });
    setFieldValue("vehicle_plate_no", option.props.plate_no);
    setFieldValue("plate_no", option.props.plate_no);
    setFieldValue("devidno", option.props.label);
  };

  render() {
    const { OnClose, onSubmit } = this.props;
    const platenolists = this.state.platenoList.map((v) => (
      <Option key={v.key} label={v.devidno} plate_no={v.name}>
        {v.name}
        {v.online == 1 ? (
          <Icon type="car" theme="twoTone" twoToneColor="#00BF06" />
        ) : (
          <Icon type="car" theme="twoTone" twoToneColor="#c91c1c" />
        )}
      </Option>
    ));

    return (
      <div>
        <Formik
          initialValues={{
            plate_no: "",
            eventdate: [
              moment("00:00:00", "HH:mm:ss"),
              moment("23:59:59", "HH:mm:ss"),
            ],
          }}
          validate={(values) => {
            let errors = {};

            if (values.eventdate.length > 0) {
              const date_start = moment(values.eventdate[0]["_d"]);
              const date_end = moment(values.eventdate[1]["_d"]);
              let day_diff = date_end.diff(date_start, "days") + 1;

              if (day_diff > 1) {
                errors.eventdate = (
                  <LangContext.Consumer>
                    {(i18n) => i18n.o.over1Day}
                  </LangContext.Consumer>
                );
              }
            }

            return errors;
          }}
          validationSchema={yup.object().shape({
            plate_no: yup
              .string()
              .required(
                <LangContext.Consumer>
                  {(i18n) => i18n.p.pleaseChooseVehicle}
                </LangContext.Consumer>
              ),
            eventdate: yup
              .array()
              .required(
                <LangContext.Consumer>
                  {(i18n) => i18n.p.pleaseChooseDateRange}
                </LangContext.Consumer>
              ),
          })}
          onSubmit={(values) => {
            values = {
              ...values,
              vehicle_plate_no: this.state.platenoList.find(
                (item) => item.name == values.plate_no
              ).plate_no,
            };
            // console.log("onSubmit", values);
            const loading = true;
            onSubmit(values, loading);
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleFocus,
            setFieldValue,
            resetForm,
          }) => (
            <Row type="flex">
              <Form onSubmit={handleSubmit}>
                <FormItem
                  label={
                    <LangContext.Consumer>
                      {(i18n) => i18n.p.plateNoVehicleCode}
                    </LangContext.Consumer>
                  }
                  validateStatus={
                    touched.plate_no && errors.plate_no && "error"
                  }
                  help={touched.plate_no && errors.plate_no}
                >
                  <AutoComplete
                    name="plate_no"
                    dataSource={platenolists}
                    onBlur={handleBlur}
                    style={{ width: '100%' }}
                    optionLabelProp="children"
                    filterOption={true}
                    optionFilterProp="children"
                    // placeholder="ค้นหาทะเบียน"
                    value={this.state.selectPlateNo}
                    onChange={(value, option) =>
                      this.changeVehiclePlateno(value, option, setFieldValue)
                    }
                    onSelect={(value, option) =>
                      this.selectVehiclePlateno(value, option, setFieldValue)
                    }
                  />
                </FormItem>

                <Form.Item
                  label={
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.dateRange}
                    </LangContext.Consumer>
                  }
                  validateStatus={
                    touched.eventdate && errors.eventdate && "error"
                  }
                  help={touched.eventdate && errors.eventdate}
                >
                  <RangePicker
                    name="eventdate"
                    onBlur={handleBlur}
                    showTime={{ format: "HH:mm:ss" }}
                    onChange={(value) => setFieldValue("eventdate", value)}
                    format="DD/MM/YYYY HH:mm:ss"
                    placeholder={["Start Time", "End Time"]}
                    defaultValue={[
                      moment("00:00:00", "HH:mm:ss"),
                      moment("23:59:59", "HH:mm:ss"),
                    ]}
                  />
                </Form.Item>

                <FormItem>
                  <Row gutter={24}>
                    <Col span={3}>
                      <Button onClick={OnClose}>
                        <LangContext.Consumer>
                          {(i18n) => i18n.c.cancel}
                        </LangContext.Consumer>
                      </Button>
                    </Col>
                    <Col span={1} />
                    <Col span={3}>
                      <Button htmlType="submit" type="primary">
                        <LangContext.Consumer>
                          {(i18n) => i18n.a.apply}
                        </LangContext.Consumer>
                      </Button>
                    </Col>
                  </Row>
                </FormItem>
              </Form>
            </Row>
          )}
        />
      </div>
    );
  }
}
