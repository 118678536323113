import { LOAD_DRIVERINCOME, LOAD_DRIVER } from "./actions";

const initialState = {
  driverLists: [],
  driverincomeLoading: false,
  driverincomeUrl: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DRIVERINCOME.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        driverincomeLoading: loading,
      };
    case LOAD_DRIVERINCOME.SUCCESS:
      return {
        ...state,
        driverincomeUrl: action.payload.data.data,
        driverincomeLoading: false,
      };
    case LOAD_DRIVER.SUCCESS:
      return {
        ...state,
        driverLists: action.payload.data.data,
      };
    default:
      return state;
  }
};
