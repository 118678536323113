import { createTypes, createAction } from "lib/action";
const LOAD_PARTTYPE = createTypes("loadparttype", "load");
const CREATE_PARTTYPE = createTypes("createparttype", "create");
const UPDATE_PARTTYPE = createTypes("updateparttype", "update");
const DETAIL_PARTTYPE = createTypes("parttypedetail", "load");
const DETAIL_PARTTYPE_EDIT = createTypes("parttypedetailedit", "load");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
    return {
        type: REMOVE_LISTS
    };
}

const loadPartType = {
    request: (data, loading) =>
        createAction(LOAD_PARTTYPE.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(LOAD_PARTTYPE.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_PARTTYPE.FAITLURE)  
};

const createPartType = {
    request: (data, loading) =>
        createAction(CREATE_PARTTYPE.REQUEST, { data, loading}),
    success: res => createAction(CREATE_PARTTYPE.SUCCESS, { res }),
        failure: () => createAction(CREATE_PARTTYPE.FAILURE)
};

const loadDetailPartType = {
    request: (data, loading) =>
        createAction(DETAIL_PARTTYPE.REQUEST, { data, loading}),
    success: (lists) =>
        createAction(DETAIL_PARTTYPE.SUCCESS, {lists}),
    failure: () => createAction(DETAIL_PARTTYPE.FAITLURE)  
};

const loadDetailPartTypeEdit = {
    request: (data, loading) =>
        createAction(DETAIL_PARTTYPE_EDIT.REQUEST, { data, loading}),
    success: (lists) =>
        createAction(DETAIL_PARTTYPE_EDIT.SUCCESS, {lists}),
    failure: () => createAction(DETAIL_PARTTYPE_EDIT.FAITLURE)  
};

const updatePartType = {
    request: (data, loading) =>
        createAction(UPDATE_PARTTYPE.REQUEST, { data, loading}),
    success: res => createAction(UPDATE_PARTTYPE.SUCCESS, { res }),
        failure: () => createAction(UPDATE_PARTTYPE.FAILURE)
};
export {
    LOAD_PARTTYPE, loadPartType,
    CREATE_PARTTYPE, createPartType,
    DETAIL_PARTTYPE, loadDetailPartType,
    UPDATE_PARTTYPE, updatePartType,
    DETAIL_PARTTYPE_EDIT, loadDetailPartTypeEdit,
    REMOVE_LISTS, removeLists
};