import {
  LOAD_COMPANY_SETTING,
  UPDATE_AUTO_CREATE_DRIVER,
  UPDATE_AUTO_CHANGE_DRIVER,
  UPDATE_COMPANY_LINE_TOKEN,
  UPDATE_TAX_ACT_INSURANCE_LINE_TOKEN,
  UPDATE_FUEL_LINE_TOKEN,
  UPDATE_ADAS_LINE_TOKEN,
} from "./actions";

const initialState = {
  loading: false,
  companysetting: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_COMPANY_SETTING.REQUEST:
    case UPDATE_AUTO_CREATE_DRIVER.REQUEST:
    case UPDATE_AUTO_CHANGE_DRIVER.REQUEST:
    case UPDATE_COMPANY_LINE_TOKEN.REQUEST:
    case UPDATE_TAX_ACT_INSURANCE_LINE_TOKEN.REQUEST:
    case UPDATE_FUEL_LINE_TOKEN.REQUEST:
    case UPDATE_ADAS_LINE_TOKEN.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_COMPANY_SETTING.SUCCESS:
      return {
        ...state,
        companysetting: action.payload.data,
        loading: false,
      };
    case UPDATE_AUTO_CREATE_DRIVER.SUCCESS:
    case UPDATE_AUTO_CHANGE_DRIVER.SUCCESS:
    case UPDATE_COMPANY_LINE_TOKEN.SUCCESS:
    case UPDATE_TAX_ACT_INSURANCE_LINE_TOKEN.SUCCESS:
    case UPDATE_FUEL_LINE_TOKEN.SUCCESS:
    case UPDATE_ADAS_LINE_TOKEN.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case LOAD_COMPANY_SETTING.FAILURE:
    case UPDATE_AUTO_CREATE_DRIVER.FAILURE:
    case UPDATE_AUTO_CHANGE_DRIVER.FAILURE:
    case UPDATE_COMPANY_LINE_TOKEN.FAILURE:
    case UPDATE_TAX_ACT_INSURANCE_LINE_TOKEN.FAILURE:
    case UPDATE_FUEL_LINE_TOKEN.FAILURE:
    case UPDATE_ADAS_LINE_TOKEN.FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
