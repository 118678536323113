import React, { Component, Fragment } from 'react'
import { connect } from "react-redux";

import {
    Row,
    Col,
    Button,
    Table,
    Icon,
    Tooltip,
    Progress,
    Spin,
    Popconfirm,
} from "antd";

import Filter from "./Filter";

import LangContext from "modules/shared/context/langContext";
import * as helper from "./helperFx"
import { fetchVechicles, fetchAllConnection } from "./apiClient"

const columnStyle = { fontSize: "12px", fontWeight: "400" };

class ChooseFile extends Component {
    state = {
        visible: false,
        loadVehicleWebsocket: false,
        vehicleFilterLists: [],
        displayModal: true
    }

    getVehicleList = async () => {

        fetchAllConnection((allconnect) => {
            let list = allconnect
            let allhwid = (list.length > 0) ? list.join(",") : ""

            let params = {
                vehicle_visibility: this.props.auth.profile.vehicle_visibility,
                company_id: this.props.auth.profile.company_id,
                online_vehicles: allhwid,
            }

            fetchVechicles(params, (statusCode, res) => {
                if (statusCode !== 200) {
                    this.setState({ loadVehicleWebsocket: false })
                    return
                }

                this.setState({
                    vehicleFilterLists: res.data.data,
                    loadVehicleWebsocket: false
                })
            })
        })

    };

    handleLoadVehicleList = () => {
        this.setState(
            {
                visible: true,
                loadVehicleWebsocket: true,
            },
            this.getVehicleList
        );
    };

    render() {
        const columnsMainTable = [
            {
                title: "#",
                dataIndex: "row_number",
                width: "5%",
                align: "center",
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle,
                        },
                        children: <div>{text}</div>,
                    };
                },
            },
            {
                title: (
                    <LangContext.Consumer>
                        {(i18n) => i18n.p.plateNo}
                    </LangContext.Consumer>
                ),
                dataIndex: "plate_no",
                sorter: false,
                width: "18%",
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle,
                        },
                        children: <div>{text}</div>,
                    };
                },
            },
            {
                title: (
                    <LangContext.Consumer>
                        {(i18n) => i18n.f.fileName}
                    </LangContext.Consumer>
                ),
                dataIndex: "filename_a",
                sorter: false,
                width: "20%",
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle,
                        },
                        children: <div>{record.filename}</div>,
                    };
                },
            },
            {
                title: (
                    <LangContext.Consumer>
                        {(i18n) => i18n.d.date_time}
                    </LangContext.Consumer>
                ),
                dataIndex: "file_datetime",
                sorter: false,
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle,
                        },
                        children: <div>{text}</div>,
                    };
                },
                width: "18%",
            },
            {
                title: (
                    <LangContext.Consumer>
                        {(i18n) => i18n.c.camera_position}
                    </LangContext.Consumer>
                ),
                dataIndex: "camera_source",
                sorter: false,
                width: "10%",
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle,
                        },
                        children: <div>{helper.getCameraText(text)}</div>,
                    };
                },
            },
            {
                title: (
                    <LangContext.Consumer>
                        {(i18n) => i18n.f.file_size}
                    </LangContext.Consumer>
                ),
                dataIndex: "size",
                sorter: false,
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle,
                        },
                        children: <div>{text}</div>,
                    };
                },
                width: "10%",
            },
            {
                title: (
                    <LangContext.Consumer>{(i18n) => i18n.s.status}</LangContext.Consumer>
                ),
                dataIndex: "queue_status",
                width: "10%",
                align: "center",
                render: (text, record, index) => {

                    if (parseInt(text) === helper.queueStatusId("downloading")) {
                        return <Progress type="circle" percent={parseInt(record.download_progress)} width={50} />
                    }

                    return {
                        props: {
                            style: columnStyle,
                        },
                        children: (
                            <div>
                                {helper.getQueueStatus(text)}
                            </div>
                        ),
                    };
                },
            },
            {
                title: (
                    <LangContext.Consumer>
                        {(i18n) => i18n.a.action}
                    </LangContext.Consumer>
                ),
                align: "center",
                dataIndex: "filename",
                sorter: false,
                width: "8%",
                render: (text, record, index) => {
                    let deleteBtn = <Tooltip
                        placement="left"
                        title={
                            <LangContext.Consumer>
                                {(i18n) => i18n.d.delete}
                            </LangContext.Consumer>
                        }
                        arrowPointAtCenter
                        style={{
                            padding: "0px 1px 0px 0px",
                            marginLeft: "5px",
                            marginRight: "5px",
                        }}
                    >
                        <Popconfirm
                            title={
                                <LangContext.Consumer>
                                    {(i18n) => i18n.d.doYouWantToDeleteTheseItems}
                                </LangContext.Consumer>
                            }
                            onConfirm={() => {
                                this.props.action.delete(record.queue_id, record.hwid, record.filename, true);
                            }}
                        >
                            <Button type="link" style={{ padding: "0px 5px 0px 5px" }}>
                                <Icon type="delete" />
                            </Button>
                        </Popconfirm>
                    </Tooltip>

                    if (record.queue_status === 0) {
                        return <Button
                            type="link"
                            onClick={(e) => {
                                this.props.action.create(record)
                            }}
                            style={{ padding: "0px 15px 0px 5px" }}
                        >
                            <Icon type="download" />
                        </Button>;
                    }

                    if (record.queue_status === helper.queueStatusId("downloading")) {
                        //firmware เก่ายกเลิกไม่ได้
                        if (record.old_format) {
                            return <LangContext.Consumer>{(i18n) => i18n.d.downloading}</LangContext.Consumer>
                        }

                        return <Fragment>
                            <Button
                                type="link"
                                onClick={(e) => {
                                    this.props.action.cancel(record.queue_id, record.hwid, record.filename)
                                }}
                                style={{ padding: "0px 15px 0px 5px" }}
                            >
                                <LangContext.Consumer>{(i18n) => i18n.c.cancel}</LangContext.Consumer>
                            </Button>
                        </Fragment>
                    }


                    return <Fragment>
                        <Button
                            type="link"
                            onClick={(e) => {
                                if ([helper.queueStatusId("cancel"), helper.queueStatusId("error")].includes(record.queue_status)) {
                                    this.props.action.requeue(record.queue_id, record.hwid, record.filename)
                                } else if (record.download_link) {
                                    helper.downloadFileVideo(record.download_link)
                                }
                            }}
                            style={{ padding: "0px 15px 0px 5px" }}
                        >
                            <Icon type="download" />
                        </Button>
                        {record.download_link && deleteBtn}
                    </Fragment>;
                },
                width: "10%",
            },
        ];

        return (
            <Fragment>
                <Spin spinning={this.props.loading}>
                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <LangContext.Consumer>
                                {(i18n) => (
                                    <Filter
                                        onSubmit={this.props.onSubmit}
                                        loadData={this.handleLoadVehicleList}
                                        vehicleLists={this.state.vehicleFilterLists}
                                        loadVehicle={this.state.loadVehicleWebsocket}
                                        deviceProcess={this.props.deviceProcess}
                                    />
                                )}
                            </LangContext.Consumer>
                        </Col>
                    </Row>
                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <Table
                                bordered
                                size="small"
                                columns={columnsMainTable}
                                rowKey={(record) => "choose_file_" + record.row_number.toString()}
                                dataSource={this.props.dataSource}
                                pagination={false}
                            />
                        </Col>
                    </Row>
                </Spin>
            </Fragment>
        )
    }
}

const mapStateToProps = ({ auth }) => ({
    auth,
});


export default connect(mapStateToProps, {})(ChooseFile);

