import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  Button,
  Spin,
  Table,
  Form,
  Row,
  Col,
  Input,
  Pagination,
  Badge,
  message,
  Popconfirm,
  Checkbox,
} from "antd";

import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import * as actions from "../actions";

import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import Filter from "./Filter";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { id } from "date-fns/locale";

const columnStyle = { fontSize: "12px", fontWeight: "400" };
const { TextArea } = Input;

const initialFilter = {
  filterPhoneNo: "",
  filterPlateNo: "",
  filterRemark: "",
  page: 1,
};

class Index extends Component {
  state = {
    ...initialFilter,
    filterVisible: false,
    data: [],
  };

  showFilterDrawer = () => {
    this.setState({
      filterVisible: true,
    });
  };

  componentDidMount() {
    this.loadData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.outofcontact.loading != prevProps.outofcontact.loading) {
      this.setState({ data: this.props.outofcontact.outofcontactList });
    }
  }

  loadData = () => {
    this.props.loadOutofcontact({
      filterPhoneNo: this.state.filterPhoneNo,
      filterPlateNo: this.state.filterPlateNo,
      filterRemark: this.state.filterRemark,
      page: this.state.page,
    });
  };

  handleStateChange = (type, v) => {
    switch (type) {
      case "phone":
        this.setState({
          filterPhoneNo: v.target.value,
        });
        break;
      case "plate_no":
        this.setState({
          filterPlateNo: v.target.value,
        });
        break;
      case "remark":
        this.setState({ filterRemark: v.target.value });
        break;
      default:
        break;
    }
  };

  handlePageChange = (page) => {
    this.setState({ page });
    this.props.loadOutofcontact({
      ...this.state,
      page: page,
    });
  };

  generateMsg = () => {
    switch (this.props.outofcontact.status) {
      case "success":
        message.success(this.props.outofcontact.msg);
        this.loadData();
        break;
      case "fail":
        message.error(this.props.outofcontact.msg);
        break;
      default:
        break;
    }
  };

  render() {
    const columns = [
      {
        title: "#",
        dataIndex: "rowNumber",
        width: 40,
        align: "center",
        render: (text, record, index) => {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{index + 1 + (this.state.page - 1) * 10}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.p.plateNo}
          </LangContext.Consumer>
        ),
        dataIndex: "plate_no",
        width: 200,
        align: "center",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.s.status}</LangContext.Consumer>
        ),
        dataIndex: "gps_active_at",
        align: "center",
        render(text, record, index) {
          let [status, date, time] = text.split(" ");
          date = date.replaceAll("-", "/");
          time = time.split(":");
          let color = "#00000";
          const status_green = ["active", "Active"];
          const status_red = ["inactive", "Inactive"];
          if (status_green.includes(status)) {
            color = "#1E9A00";
          } else if (status_red.includes(status)) {
            color = "#FF7D70";
          }
          return {
            props: {
              style: columnStyle,
            },
            children: (
              <span>
                <div style={{ color, fontWeight: "800" }}>{status}</div>
                <div style={{ color }}>
                  {date + " " + time[0] + ":" + time[1]}
                </div>
              </span>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.p.pos_update}
          </LangContext.Consumer>
        ),
        dataIndex: "pos_update_at",
        align: "center",
        // width: 170,
        // sorter: true,
        render(text, record, index) {
          let [status, date, time] = text.split(" ");
          date = date.replaceAll("-", "/");
          time = time.split(":");
          let color = "#00000";
          const status_green = ["active", "Active"];
          const status_red = ["inactive", "Inactive"];
          if (status_green.includes(status)) {
            color = "#1E9A00";
          } else if (status_red.includes(status)) {
            color = "#FF7D70";
          }
          return {
            props: {
              style: columnStyle,
            },
            children: (
              <div>
                <div style={{ color, fontWeight: "800" }}>{status}</div>
                <div style={{ color }}>
                  {date + " " + time[0] + ":" + time[1]}
                </div>
              </div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.c.cause}</LangContext.Consumer>
        ),
        dataIndex: "inactive_reason_name",
        align: "center",

        // width: 110,
        // sorter: true,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.r.reactive_date}
          </LangContext.Consumer>
        ),
        dataIndex: "reactive_date",
        align: "center",

        render(text, record, index) {
          let [date, time] = text.split(" ");
          date = date.replaceAll("-", "/");
          time = time.split(":");
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{date + " " + time[0] + ":" + time[1]}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.n.notify_date}
          </LangContext.Consumer>
        ),
        dataIndex: "created_at",
        align: "center",

        // width: 90,
        render(text, record, index) {
          let [date, time] = text.split(" ");
          date = date.replaceAll("-", "/");
          time = time.split(":");
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{date + " " + time[0] + ":" + time[1]}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.r.registry_number}
          </LangContext.Consumer>
        ),
        dataIndex: "phone_no",
        width: 100,
        align: "center",

        // sorter: true,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.c.car_registration_page}
          </LangContext.Consumer>
        ),
        dataIndex: "vehicle_registry_img",
        align: "center",

        // width: 110,
        // sorter: true,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: (
              <a
                // href={`${process.env.REACT_APP_LARAVEL_ENDPOINT}${text}`}
                href={
                  `https://terminus-master.sgp1.digitaloceanspaces.com/` +
                  `${text}`
                }
                target="_blank"
              >
                <span style={{ color: "#2BB2FF" }}>แสดงภาพ</span>
              </a>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.s.status_sheet}
          </LangContext.Consumer>
        ),
        dataIndex: "worksheet_status",
        align: "center",
        // width: 200,
        // sorter: true,
        render(text, record, index) {
          let color = "#1890ff";
          if (text === "Done") color = "green";
          else if (text === "Out of date") color = "red";
          return {
            props: {
              style: columnStyle,
            },
            children: <div style={{ color }}>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.c.comment}
          </LangContext.Consumer>
        ),
        dataIndex: "remark",
        width: 200,
        editable: true,
        // align: "right",
        render: (text, record, index) => {
          const handleClick = () => {
            this.props.updateOutofcontact(
              {
                worksheet_id: record.worksheet_id,
                remark: text,
              },
              this.generateMsg
            );
          };

          return {
            props: {
              style: columnStyle,
            },
            children: (
              <div>
                <TextArea
                  autoSize={{ minRows: 2, maxRows: 2 }}
                  maxLength={30}
                  onChange={(event) => {
                    let data = this.state.data;
                    data[index].remark = event.target.value;
                    this.setState({ data: data });
                  }}
                  value={text}
                />
                <Button
                  type="link"
                  style={{
                    color: "#2BB2FF",
                    cursor: "pointer",
                    padding: "0",
                  }}
                  onClick={handleClick}
                >
                  บันทึก
                </Button>
              </div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.a.action}</LangContext.Consumer>
        ),
        dataIndex: "action",
        width: 100,
        align: "center",

        // sorter: true,
        render: (text, record, index) => {
          const onSubmit = (e) => {
            this.props.updateOutofcontact(
              {
                worksheet_id: record.worksheet_id,
                worksheet_status: true,
                remark: record.remark,
              },
              this.generateMsg
            );
          };
          return {
            props: {
              style: columnStyle,
            },
            children:
              record.worksheet_status !== "Done" ? (
                <Popconfirm
                  title={
                    <LangContext.Consumer>
                      {(i18n) => i18n.c.closeJob}
                    </LangContext.Consumer>
                  }
                  description={
                    <LangContext.Consumer>
                      {(i18n) => i18n.c.closeJobDescribe}
                    </LangContext.Consumer>
                  }
                  onConfirm={onSubmit}
                  okText={
                    <LangContext.Consumer>
                      {(i18n) => i18n.variable.Yes}
                    </LangContext.Consumer>
                  }
                  cancelText={
                    <LangContext.Consumer>
                      {(i18n) => i18n.variable.No}
                    </LangContext.Consumer>
                  }
                >
                  {/* <Checkbox>
                    <span style={{ color: "#2BB2FF" }}>
                      <LangContext.Consumer>
                        {(i18n) => i18n.c.closeJob}
                      </LangContext.Consumer>
                    </span>
                  </Checkbox> */}
                  <span style={{ color: "#2BB2FF", cursor: "pointer" }}>
                    <LangContext.Consumer>
                      {(i18n) => i18n.c.closeJob}
                    </LangContext.Consumer>
                  </span>
                </Popconfirm>
              ) : (
                <></>
              ),
          };
        },
      },
    ];

    return (
      <AuthorizeComponent matching_name="outofcontact">
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <Row gutter={24} type="flex" justify="end" style={{ height: "38px" }}>
            <Col span={16} />

            <Col span={2} offset={6}>
              <Badge dot={this.state.isFilter}>
                <Button
                  icon="filter"
                  size="small"
                  onClick={this.showFilterDrawer}
                >
                  <LangContext.Consumer>
                    {(i18n) => i18n.f.filter}
                  </LangContext.Consumer>
                </Button>
              </Badge>
              <Filter
                {...this.state}
                onClose={() => this.setState({ filterVisible: false })}
                handleStateChange={this.handleStateChange}
                onFilterButtonClick={() => {
                  this.setState({ page: 1 }, () => this.loadData());
                }}
              />
            </Col>
          </Row>

          <Row>
            <Spin spinning={this.props.outofcontact.loading}>
              <Table
                bordered
                rowKey="worksheet_id"
                columns={columns}
                dataSource={this.props.outofcontact.outofcontactList}
                size="small"
                height={1200}
                scroll={{ x: 1200, y: 1200 }}
                pagination={false}
                onChange={this.handleTableChange}
              />
            </Spin>
          </Row>
          <Row type="flex" justify="end" style={{ padding: "15px" }}>
            <Col pan={24} align="right">
              <Pagination
                defaultPageSize={10}
                size="small"
                current={this.state.page}
                total={this.props.outofcontact.total}
                onChange={this.handlePageChange}
              />
            </Col>
          </Row>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

export default connect(
  ({ outofcontact }) => ({
    outofcontact,
  }),
  {
    loadOutofcontact: actions.loadOutofcontact.request,
    updateOutofcontact: actions.updateOutofcontact.request,
  }
)(Index);
