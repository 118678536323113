import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";
import {
  Button,
  Form,
  Select,
  Row,
  Col,
  Spin,
  DatePicker,
  Transfer,
  Dropdown,
  Menu,
  Icon,
} from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";

const { RangePicker } = DatePicker;
const FormItem = Form.Item;
const Option = Select.Option;

export default class Forms extends Component {
  state = {
    event_date_start: "",
    event_date_end: "",
    vehicleList: [],
    vehicleSelect: [],
    selectedKeys: [],
    policy_id: "",
    // vehicltypeID: ""
  };

  componentDidMount() {
    const { loadPolicy, call } = this.props;
    loadPolicy();
    if (call == "schedule") {
      if (this.props.dataedit != "") {
        let obj = JSON.parse(this.props.dataedit);
        this.setState({
          policy_id: obj.policy_id,
        });
      }
    }
  }

  // onVehicleTypeChange = (value, setFieldValue) => {
  //   const { loadVehicle } = this.props;
  //   setFieldValue("vehicle_type_id", value);
  //   loadVehicle(value, true);
  //   this.setState({ vehicleSelect: [] });
  //   this.setState({ selectedKeys: [] });
  //   setFieldValue("list_vehicle_id", []);
  // };

  componentWillReceiveProps = (nextProps) => {
    // const { vehicleLists } = nextProps;
    // const vehlist = [];
    // vehicleLists.map(item =>
    //   vehlist.push({
    //     key: item.id,
    //     name: item.plate_no
    //   })
    // );
    // //console.log(vehlist);
    // this.setState({ vehicleList: vehlist });
  };

  // tranferChange = (targetKeys, setFieldValue) => {
  //   setFieldValue("list_vehicle_id", targetKeys);
  //   this.setState({ vehicleSelect: targetKeys });
  // };

  // handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
  //   this.setState({
  //     selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys]
  //   });
  // };

  handleMenuClick = (e, setFieldValue, handleSubmit) => {
    if (e.key == "excel") {
      setFieldValue("type_file", "excel", false);
    } else if (e.key == "pdf") {
      setFieldValue("type_file", "pdf", false);
    }
    handleSubmit();
  };

  render() {
    const {
      policyLists,
      summaryLineNotificationLoading,
      onSubmit,
    } = this.props;

    return (
      <LangContext.Consumer>
        {(i18n) => (
          <div>
            <h3>{i18n.r.report_summarylinenotification}</h3>
            <Spin spinning={summaryLineNotificationLoading}>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  policy_id: this.state.policy_id,
                  eventdate: [
                    moment("00:00", "HH:mm"),
                    moment("23:59", "HH:mm"),
                  ],
                }}
                validate={(values) => {
                  let errors = {};
                  if (values.eventdate.length > 0) {
                    const date_start = moment(values.eventdate[0]["_d"]);
                    const date_end = moment(values.eventdate[1]["_d"]);
                    let day_diff = date_end.diff(date_start, "months");
                    if (day_diff > 0) {
                      errors.eventdate = i18n.o.overOneMonth;
                    }
                  }

                  return errors;
                }}
                validationSchema={yup.object().shape({
                  policy_id: yup.string().required(i18n.r.required),
                })}
                onSubmit={(values) => {
                  //console.log(values);
                  const loading = true;
                  onSubmit(values, loading);
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleFocus,
                  setFieldValue,
                  isValidating,
                }) => (
                  <Row type="flex" justify="center">
                    <Form onSubmit={handleSubmit}>
                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {(i18n) => i18n.p.policy}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.policy_id && errors.policy_id && "warning"
                        }
                        help={touched.policy_id && errors.policy_id}
                      >
                        <Select
                          onChange={(value) =>
                            setFieldValue("policy_id", value)
                          }
                          value={values.policy_id}
                          style={
                            this.props.call !== "schedule"
                              ? { width: "100%" }
                              : { width: 250 }
                          }
                          showSearch
                          optionFilterProp="title"
                          placeholder={i18n.p.pleaseSelect}
                        >
                          <Option value="" title={i18n.p.pleaseSelect}>
                            {i18n.p.pleaseSelect}
                          </Option>

                          {policyLists.map((item) => (
                            <Option
                              key={item.id}
                              value={item.id}
                              title={item.name}
                            >
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </FormItem>

                      {this.props.call !== "schedule" ? (
                        <Form.Item
                          label={
                            <LangContext.Consumer>
                              {(i18n) => i18n.d.dateRange}
                            </LangContext.Consumer>
                          }
                          validateStatus={
                            touched.eventdate && errors.eventdate && "warning"
                          }
                          help={touched.eventdate && errors.eventdate}
                        >
                          <RangePicker
                            onChange={(value) =>
                              setFieldValue("eventdate", value)
                            }
                            format="DD/MM/YYYY"
                            defaultValue={[
                              moment("00:00", "HH:mm"),
                              moment("23:59", "HH:mm"),
                            ]}
                          />
                        </Form.Item>
                      ) : (
                        ""
                      )}

                      <FormItem>
                        <Row gutter={24}>
                          {this.props.call !== "schedule" ? (
                            <Col span={8}>
                              <Button type="defualt" block>
                                <Link to="/">
                                  <LangContext.Consumer>
                                    {(i18n) => i18n.c.cancel}
                                  </LangContext.Consumer>
                                </Link>
                              </Button>
                            </Col>
                          ) : (
                            ""
                          )}
                          {this.props.call !== "schedule" ? (
                            <Col span={8}>
                              <Dropdown
                                overlay={
                                  <Menu
                                    onClick={(value) =>
                                      this.handleMenuClick(
                                        value,
                                        setFieldValue,
                                        handleSubmit
                                      )
                                    }
                                  >
                                    <Menu.Item key="excel">
                                      <Icon type="file-excel" />{" "}
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.e.excel}
                                      </LangContext.Consumer>
                                    </Menu.Item>
                                    <Menu.Item key="pdf">
                                      <Icon type="file-pdf" />{" "}
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.p.pdf}
                                      </LangContext.Consumer>
                                    </Menu.Item>
                                  </Menu>
                                }
                              >
                                <Button type="primary" block>
                                  <LangContext.Consumer>
                                    {(i18n) => i18n.r.report}
                                  </LangContext.Consumer>{" "}
                                  <Icon type="down" />
                                </Button>
                              </Dropdown>
                            </Col>
                          ) : (
                            ""
                          )}

                          {this.props.call == "schedule" ? (
                            <Col span={8}>
                              <Button type="primary" htmlType="submit" block>
                                <LangContext.Consumer>
                                  {(i18n) => i18n.s.save}
                                </LangContext.Consumer>
                              </Button>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </FormItem>
                    </Form>
                  </Row>
                )}
              />
            </Spin>
          </div>
        )}
      </LangContext.Consumer>
    );
  }
}
