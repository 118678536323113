import { createTypes, createAction } from "lib/action";

const LOAD_WITHDRAW_FUEL_CREDIT_HISTORY = createTypes(
  "load_withdraw_fuel_credit",
  "load"
);

const LOAD_VEHICLE_CREDIT_INFO = createTypes(
  "load_vehicle_credit_info",
  "load"
);

const LOAD_WITHDRAW_INFO = createTypes("load_withdraw_info", "load");
const LOAD_WITHDRAW_PATH = createTypes("load_withdraw_path", "load");

const UPDATE_WITHDRAW_VEHICLE_FUEL_CREDIT = createTypes(
  "update_withdraw_vehicle_fuel_credit",
  "update"
);

const UPDATE_WITHDRAW_PATH = createTypes("update_withdraw_path", "update");

const loadWithdrawFuelCreditHistory = {
  request: (filterobj) =>
    createAction(LOAD_WITHDRAW_FUEL_CREDIT_HISTORY.REQUEST, { filterobj }),
  success: (data) =>
    createAction(LOAD_WITHDRAW_FUEL_CREDIT_HISTORY.SUCCESS, { data }),
  failure: () => createAction(LOAD_WITHDRAW_FUEL_CREDIT_HISTORY.FAILURE),
};

const loadVehicleCreditInfo = {
  request: (obj) => createAction(LOAD_VEHICLE_CREDIT_INFO.REQUEST, { obj }),
  success: (data) => createAction(LOAD_VEHICLE_CREDIT_INFO.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_CREDIT_INFO.FAILURE),
};
const loadWithdrawInfo = {
  request: (obj) => createAction(LOAD_WITHDRAW_INFO.REQUEST, { obj }),
  success: (data) => createAction(LOAD_WITHDRAW_INFO.SUCCESS, { data }),
  failure: () => createAction(LOAD_WITHDRAW_INFO.FAILURE),
};
const loadWithdrawPath = {
  request: (obj) => createAction(LOAD_WITHDRAW_PATH.REQUEST, { obj }),
  success: (data) => createAction(LOAD_WITHDRAW_PATH.SUCCESS, { data }),
  failure: () => createAction(LOAD_WITHDRAW_PATH.FAILURE),
};

const updateWithdrawFuelCredit = {
  request: (obj, generateMsg) =>
    createAction(UPDATE_WITHDRAW_VEHICLE_FUEL_CREDIT.REQUEST, {
      obj,
      generateMsg,
    }),
  success: (data) =>
    createAction(UPDATE_WITHDRAW_VEHICLE_FUEL_CREDIT.SUCCESS, { data }),
  failure: () => createAction(UPDATE_WITHDRAW_VEHICLE_FUEL_CREDIT.FAILURE),
};

const updateWithdrawPath = {
  request: (obj, generateMsg) =>
    createAction(UPDATE_WITHDRAW_PATH.REQUEST, {
      obj,
      generateMsg,
    }),
  success: (data) => createAction(UPDATE_WITHDRAW_PATH.SUCCESS, { data }),
  failure: () => createAction(UPDATE_WITHDRAW_PATH.FAILURE),
};

export {
  LOAD_WITHDRAW_FUEL_CREDIT_HISTORY,
  loadWithdrawFuelCreditHistory,
  LOAD_VEHICLE_CREDIT_INFO,
  loadVehicleCreditInfo,
  UPDATE_WITHDRAW_VEHICLE_FUEL_CREDIT,
  updateWithdrawFuelCredit,
  LOAD_WITHDRAW_INFO,
  loadWithdrawInfo,
  LOAD_WITHDRAW_PATH,
  loadWithdrawPath,
  UPDATE_WITHDRAW_PATH,
  updateWithdrawPath,
};
