import {
  CREATE_TRIP_DELIVERY,
  UPDATE_TRIP_DELIVERY,
  LOAD_TRIP_DELIVERY,
  REMOVE_LISTS_DELIVERY,
  LOAD_TRIP_DETAIL_DELIVERY,
  CLEAR_RESPONSE_DELIVERY,
  LOAD_VEHICLE_DELIVERY,
  LOAD_PRODUCTTYPE_DELIVERY,
  LOAD_DELIVER_DELIVERY,
  LOAD_LOCATION_DELIVERY,
  LOAD_LOCATIONCODE_DELIVERY,
  LOAD_PRODUCT_DELIVERY,
  LOAD_DRIVER_DELIVERY,
  CREATE_DELIVER_DELIVERY,
  LOAD_DELIVER_DETAIL_DELIVERY,
  UPDATE_DELIVER_DELIVERY,
  LOAD_PRODUCTDELIVER_DELIVERY,
  CREATE_PRODUCTDELIVER_DELIVERY,
  CLEAR_LIST_DELIVERY
} from "./actions";

const initialState = {
  deliverLists: [],
  deliverLoading: false,
  productLists: [],
  lists: [],
  total: 1,
  loading: false,
  detailLoading: false,
  draw: -1,
  vehicleLists: [],
  locationLists: [],
  locationcodeLists: [],
  producttypeLists: [],
  driverLists: [],
  dataResponse: { status: "", msg: "", action_type: "" },
  selectedTrip: {
    trip_id: "",
    trip_code: "",
    vehicle_id: "",
    driver_id: "",
    product_type: "",
    driver_name: "",
    plate_no: "",
    vehicle_code: "",
  },
  deliverDetails: {
    deiver_id: "",
    invoice_number: "",
    cv_code: "",
    invoice_date: "",
    confirm_deliver_type: "",
    location_name: "",
    location_code: "",
    dest_location: "",
    date_start: "",
    date_end: ""
  },
  trip_id: "",
  check: true,
  check_deliver: true,
  check_deliver_detail: true,
  productdeliverLists: [],
  productdeliverLoading: false,
  check_product_deliver: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TRIP_DELIVERY.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        loading: loading
      };
    case LOAD_TRIP_DELIVERY.SUCCESS:
      const { page } = action.payload;
      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1;
        if (page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber + (page - 1) * 10;
      }
      return {
        ...state,
        lists: action.payload.lists.data,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
              ? 1
              : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1)
      };
    case LOAD_TRIP_DETAIL_DELIVERY.REQUEST:
      return {
        ...state,
        detailLoading: true,
        check: true
      };
    case LOAD_TRIP_DETAIL_DELIVERY.SUCCESS:
      return {
        ...state,
        selectedTrip: action.payload.trip.data,
        detailLoading: false,
        check: false
      };
    case CREATE_TRIP_DELIVERY.REQUEST:
      const { loading: loading3 } = action.payload;
      return {
        ...state,
        detailLoading: loading3
      };

    case CREATE_TRIP_DELIVERY.SUCCESS:
      const { res } = action.payload;
      return {
        ...state,
        dataResponse: { status: res.status, msg: res.msg, action_type: res.action_type },
        detailLoading: false
      };
    case UPDATE_TRIP_DELIVERY.REQUEST:
      return {
        ...state,
        detailLoading: true
      };
    case UPDATE_TRIP_DELIVERY.SUCCESS:
      return {
        ...state,
        detailLoading: false,
        dataResponse: { status: action.payload.res.status, msg: action.payload.res.msg, action_type: action.payload.res.action_type },
      };
    case CLEAR_RESPONSE_DELIVERY:
      return {
        ...state,
        dataResponse: { status: "", msg: "", id: "", action_type: "" }
      };
    case CLEAR_LIST_DELIVERY:
      return {
        ...state,
        driverLists: []
      };
    case LOAD_VEHICLE_DELIVERY.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        //  drivinghourLoading: false
      };
    case LOAD_PRODUCTTYPE_DELIVERY.SUCCESS:
      return {
        ...state,
        producttypeLists: action.payload.data.data
      };
    case LOAD_DELIVER_DELIVERY.REQUEST:
      const { loading: loadingDeliver } = action.payload;
      return {
        ...state,
        deliverLoading: loadingDeliver,
        check_deliver: true
      };
    case LOAD_DELIVER_DELIVERY.SUCCESS:
      return {
        ...state,
        deliverLists: action.payload.data.data,
        deliverLoading: false,
        check_deliver: false
      };
    case LOAD_LOCATION_DELIVERY.SUCCESS:
      return {
        ...state,
        locationLists: action.payload.data.data,
      };
    case LOAD_LOCATIONCODE_DELIVERY.SUCCESS:
      return {
        ...state,
        locationcodeLists: action.payload.data.data,
      };
    case LOAD_PRODUCT_DELIVERY.SUCCESS:
      return {
        ...state,
        productLists: action.payload.data.data,
      };
    case LOAD_DRIVER_DELIVERY.SUCCESS:
      return {
        ...state,
        driverLists: action.payload.data.data,
      };
    case LOAD_DELIVER_DETAIL_DELIVERY.REQUEST:
      return {
        ...state,
        check_deliver_detail: true
      };
    case LOAD_DELIVER_DETAIL_DELIVERY.SUCCESS:
      return {
        ...state,
        deliverDetails: { ...action.payload.data.data },
        check_deliver_detail: false
      };
    case CREATE_DELIVER_DELIVERY.REQUEST:
      const { loading: loading2 } = action.payload;
      return {
        ...state,
        check_deliver: true,
        deliverLoading: loading2
      };

    case CREATE_DELIVER_DELIVERY.SUCCESS:
      const { data } = action.payload;
      return {
        ...state,
        deliverLoading: false,
        check_deliver: false,
        deliverLists: data.deliverLists.data
      };

    case UPDATE_DELIVER_DELIVERY.REQUEST:
      const { loading: loading_update_deliver } = action.payload;
      return {
        ...state,
        check_deliver: true,
        deliverLoading: loading_update_deliver
      };

    case UPDATE_DELIVER_DELIVERY.SUCCESS:
      return {
        ...state,
        deliverLoading: false,
        check_deliver: false,
        deliverLists: action.payload.data.deliverLists.data
      };
    case LOAD_PRODUCTDELIVER_DELIVERY.REQUEST:
      const { loading: loadinProductDeliver } = action.payload;
      return {
        ...state,
        productdeliverLoading: loadinProductDeliver,
        check_product_deliver: true
      };
    case LOAD_PRODUCTDELIVER_DELIVERY.SUCCESS:
      return {
        ...state,
        productdeliverLists: action.payload.data.data,
        productdeliverLoading: false,
        check_product_deliver: false
      };
    case CREATE_PRODUCTDELIVER_DELIVERY.REQUEST:
      const { loading: create_prodcut_loading } = action.payload;
      return {
        ...state,
        check_product_deliver: true,
        productdeliverLoading: create_prodcut_loading
      };

    case CREATE_PRODUCTDELIVER_DELIVERY.SUCCESS:
      return {
        ...state,
        productdeliverLoading: false,
        check_product_deliver: false,
        productdeliverLists: action.payload.data.productdeliverLists.data,
        //dataResponse: { status: action.payload.res.status, msg: action.payload.res.msg, action_type: action.payload.res.action_type },
      };

    default:
      return state;
  }
};
