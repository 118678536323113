import {
  LOAD_DRIVINGLOGSPECIAL,
  LOAD_VEHICLE_DRIVINGLOGSPECIAL,
  LOAD_VEHICLE_TYPE_DRIVINGLOGSPECIAL,
  LOAD_DRIVER_DRIVINGLOGSPECIAL
} from "./actions";

const initialState = {
  drivinglogUrl: "",
  vehicleLists: [],
  vehicleTypeLists: [],
  driverLists: [],
  drivinglogspecialLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DRIVINGLOGSPECIAL.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        drivinglogspecialLoading: loading
      };
    case LOAD_DRIVINGLOGSPECIAL.SUCCESS:

      return {
        ...state,
        //drivinglogUrl: action.payload.data.data,
        drivinglogspecialLoading: false
      };
    case LOAD_VEHICLE_DRIVINGLOGSPECIAL.REQUEST:
      const { loading_vehicle } = action.payload;
      return {
        ...state,
        drivinglogspecialLoading: loading_vehicle
      };
    case LOAD_VEHICLE_DRIVINGLOGSPECIAL.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        drivinglogspecialLoading: false
        //  loading: false
      };
    case LOAD_VEHICLE_TYPE_DRIVINGLOGSPECIAL.SUCCESS:
      // console.log(action.payload.data.data);
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data
      };
    case LOAD_DRIVER_DRIVINGLOGSPECIAL.SUCCESS:
      //console.log(action.payload.data.data);
      return {
        ...state,
        driverLists: action.payload.data.data
      };
    default:
      return state;
  }
};
