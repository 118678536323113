import { createTypes, createAction } from "lib/action";

const LOAD_SPEEDOVERLIMITSUMMARY = createTypes("speedoverlimitsummary", "load");
const LOAD_VEHICLE_SPEEDOVERLIMITSUMMARY = createTypes(
  "vehicle_speedoverlimitsummary",
  "load"
);
const LOAD_VEHICLE_TYPE_SPEEDOVERLIMITSUMMARY = createTypes("vehicletype_speedoverlimitsummary", "load");

const loadSpeedoverlimitsummary = {
  request: (data, loading) =>
    createAction(LOAD_SPEEDOVERLIMITSUMMARY.REQUEST, { data, loading }),
  success: data => createAction(LOAD_SPEEDOVERLIMITSUMMARY.SUCCESS, { data }),
  failure: () => createAction(LOAD_SPEEDOVERLIMITSUMMARY.FAILURE)
};

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_SPEEDOVERLIMITSUMMARY.REQUEST, {
      vehicletypeID,
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_VEHICLE_SPEEDOVERLIMITSUMMARY.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_SPEEDOVERLIMITSUMMARY.FAILURE)
};

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_SPEEDOVERLIMITSUMMARY.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_TYPE_SPEEDOVERLIMITSUMMARY.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_SPEEDOVERLIMITSUMMARY.FAILURE)
};

export {
  loadSpeedoverlimitsummary,
  LOAD_SPEEDOVERLIMITSUMMARY,
  loadVehicle,
  LOAD_VEHICLE_SPEEDOVERLIMITSUMMARY,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_SPEEDOVERLIMITSUMMARY
};
