import React, { Component, Fragment } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";
import {
  Button,
  Form,
  DatePicker,
  AutoComplete,
  Select,
  Spin,
  Badge,
  Modal
} from "antd";
import LangContext from "modules/shared/context/langContext";
import { genDeviceProcessDetail } from "modules/monitoring/honeytoastdownloadfile/components/deviceProcessFunc"


const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const { Option, OptGroup } = AutoComplete;
export default class Filter extends Component {
  state = {
    platenolists: [],
  };

  handleOpenDeviceProcessModal = () => {
    let html = genDeviceProcessDetail(this.props.deviceProcess.userList)

    Modal.warning({
      title: 'อุปกรณ์นี้ กำลังมีการเชื่อมต่อการใช้งานวีดีโอดังนี้',
      content: <div dangerouslySetInnerHTML={{ __html: html }}></div>,
      okText: "ปิด"
    });
  }

  changeVehiclePlateno = (value, option, setFieldValue) => {
    // console.log("changeVehiclePlateno", option.props);
    setFieldValue("plate_no", value);
    setFieldValue("old_format", "");
    setFieldValue("imei", "");
    setFieldValue("vehicle_id", "");
  };

  selectVehiclePlateno = (value, option, setFieldValue) => {
    // console.log("selectVehiclePlateno", option.props);
    setFieldValue("plate_no", option.props.data_value);
    setFieldValue("imei", option.props.label);
    setFieldValue("vehicle_id", value);
    setFieldValue("old_format", option.props.data_format);
  };


  render() {
    const { onSubmit, vehicleLists, loadVehicle, deviceProcess } = this.props;
    const platenolists = vehicleLists.filter(x => x.title !== "fileopt").map((group) => (
      <OptGroup
        key={group.title}
        label={
          <span style={{ color: "blue" }}>
            <LangContext.Consumer>
              {(i18n) => i18n.o[group.title]}
            </LangContext.Consumer>
          </span>
        }
      >
        {group.children.map((opt) => {
          return <Option
            key={opt.id}
            label={opt.imei}
            data_value={opt.plate_no}
            data_format={opt.old_format}
            disabled={group.title === "offline" ? true : false}
          >
            {group.title === "online" ?
              <Badge color="#00BF06" text={opt.plate_no} /> :
              <Badge color="#c91c1c" text={opt.plate_no} />
            }
          </Option>
        })}
      </OptGroup>
    ));

    return (
      <Fragment>
        <Formik
          initialValues={{
            plate_no: "",
            eventdate: [
              moment("00:00:00", "HH:mm:ss"),
              moment("23:59:59", "HH:mm:ss"),
            ],
            camera_position: "",
          }}
          validate={(values) => {
            let errors = {};
            if (values.eventdate.length > 0) {
              const date_start = moment(values.eventdate[0]["_d"]);
              const date_end = moment(values.eventdate[1]["_d"]);
              let day_diff = date_end.diff(date_start, "days") + 1;
              if (day_diff > 1) {
                errors.eventdate = "ช่วงวันที่ที่เลือกไม่ควรเกิน 1 วัน";
                //console.log("ddd");
              }
            }
            //console.log("error:",errors)
            return errors;
          }}
          validationSchema={yup.object().shape({
            plate_no: yup.string().required("กรุณาเลือกทะเบียนพาหนะ"),
            eventdate: yup.array().required("กรุณาเลือกช่วงวันที่"),
          })}
          onSubmit={(values) => {
            onSubmit(values);
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleFocus,
            setFieldValue,
            resetForm,
          }) => (
            <Form onSubmit={handleSubmit} layout={"inline"} >
              <FormItem>
                <Button type="danger" disabled={deviceProcess === null} icon="eye" size="small" onClick={this.handleOpenDeviceProcessModal} />
              </FormItem>
              <FormItem
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.p.plateNoVehicleCode}
                  </LangContext.Consumer>
                }
                validateStatus={
                  touched.plate_no && errors.plate_no && "error"
                }
                help={touched.plate_no && errors.plate_no}
              >
                <AutoComplete
                  dataSource={platenolists}
                  style={{ width: "300px" }}
                  optionLabelProp="children"
                  filterOption={(inputValue, option) => {
                    if (typeof option.props.children.props !== "undefined") {
                      return option.props.children.props.text.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                    }
                    return false
                  }}
                  optionFilterProp="children"
                  // placeholder="ค้นหาทะเบียน"
                  value={values.plate_no}
                  onFocus={this.props.loadData}
                  onSearch={(value, option) =>
                    this.changeVehiclePlateno(value, option, setFieldValue)
                  }
                  onSelect={(value, option) =>
                    this.selectVehiclePlateno(value, option, setFieldValue)
                  }
                  notFoundContent={loadVehicle ? <Spin size="small" /> : null}
                />
              </FormItem>

              <FormItem
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.camera_position}
                  </LangContext.Consumer>
                }
                validateStatus={
                  touched.camera_position && errors.camera_position && "error"
                }
                help={touched.camera_position && errors.camera_position}
              >
                <Select
                  //showSearch
                  style={{ width: "150px" }}
                  onChange={(value) =>
                    setFieldValue("camera_position", value)
                  }
                  value={values.camera_position}
                >
                  <Option value="">
                    <LangContext.Consumer>
                      {(i18n) => i18n.a.all}
                    </LangContext.Consumer>
                  </Option>

                  <Option value="front">
                    <LangContext.Consumer>
                      {(i18n) => i18n.f.front}
                    </LangContext.Consumer>
                  </Option>
                  <Option value="back">
                    <LangContext.Consumer>
                      {(i18n) => i18n.c.camera_back}
                    </LangContext.Consumer>
                  </Option>

                  <Option value="extra">
                    <LangContext.Consumer>
                      {(i18n) => i18n.c.camera_extra}
                    </LangContext.Consumer>
                  </Option>
                </Select>
              </FormItem>

              <Form.Item
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.dateRange}
                  </LangContext.Consumer>
                }
                validateStatus={errors.eventdate && "error"}
                help={errors.eventdate}
              >
                <RangePicker
                  showTime={{ format: "HH:mm:ss" }}
                  onChange={(value) => setFieldValue("eventdate", value)}
                  format="DD/MM/YYYY HH:mm:ss"
                  placeholder={["Start Time", "End Time"]}
                  defaultValue={[
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ]}
                />
              </Form.Item>

              <FormItem>
                <Button htmlType="submit" type="primary">
                  <LangContext.Consumer>
                    {(i18n) => i18n.a.apply}
                  </LangContext.Consumer>
                </Button>

              </FormItem>
            </Form>
          )}
        />
      </Fragment>
    );
  }
}
