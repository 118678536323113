import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadLocationgroup(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      orderBy,
      orderType,
      accessToken,
      filterObj,
      location_visibility,
      location_group_visibility
    }
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/locationgroup`,
      {
        page,
        pageSize,
        searchName,
        companyID,
        orderBy,
        orderType,
        filterObj,
        location_visibility,
        location_group_visibility,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.loadLocationgroup.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadLocationgroup.failure());
  }
}

function* createMasterDataLocationGroup(action) {
  const {
    data: {
      companyID,
      name,
      location_group_type_id,
      locations_id,
      accessToken
    },
    loading
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/locationgroup/create`,
      {
        companyID,
        name,
        location_group_type_id,
        locations_id
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    loading(res.data);
    yield put(actions.createMasterDataLocationGroup.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.createMasterDataLocationGroup.failure());
  }
}

function* updateMasterDataLocationGroup(action) {
  const {
    data: {
      companyID,
      location_group_id,
      name,
      location_group_type_id,
      locations_id,
      accessToken
    },
    loading
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/locationgroup/update`,
      {
        companyID,
        location_group_id,
        name,
        location_group_type_id,
        locations_id
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    loading(res.data);
    yield put(actions.updateMasterDataLocationGroup.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.updateMasterDataLocationGroup.failure());
  }
}

export default function* watchLocationGroup() {
  yield all([
    takeEvery(actions.LOAD_MASTERDATA_LOCATIONGROUP.REQUEST, loadLocationgroup),
    takeEvery(
      actions.CREATE_MASTERDATA_LOCATIONGROUP.REQUEST,
      createMasterDataLocationGroup
    ),
    takeEvery(
      actions.UPDATE_MASTERDATA_LOCATIONGROUP.REQUEST,
      updateMasterDataLocationGroup
    )
  ]);
}
export {
  loadLocationgroup,
  createMasterDataLocationGroup,
  updateMasterDataLocationGroup
};
