import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Steps, Row, Col, Spin, Button, message } from "antd";
import {
  GeneralStyledContent,
  StyledSearchForm,
} from "../../../../styled/common-styled";
import { loadPolicyDetail, createPolicy, updatePolicy } from "./../actions";
import { autoGenKey } from "../../../../lib/helper";

import Step1Form from "./Form/Step1Form";
import VehicleForm from "./Form/VehicleForm";
import NotiConfig from "./Form/NotiConfig";

import history from "../../../../lib/history";

import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import Breadcrumb from "./../../../shared/components/Breadcrumb";

import LangContext, { i18n } from "modules/shared/context/langContext";
const { Step } = Steps;

const initialValue = {
  policy_id: null,
  policy_name: "",
  // type: "area_location",
  conditionList: [],
  duration_minute_range: [1, 60],
  duration_second_range: [15, 45],
  duration_unit: "minute",
  duration_trigger_inside_range: true,
  recount_duration: "-",
  allow_monday: true,
  allow_tuesday: true,
  allow_wendnesday: true,
  allow_thursday: true,
  allow_friday: true,
  allow_saturday: true,
  allow_sunday: true,
  time_trigger_inside_range: true,
  time_start_at: "00:00:00",
  time_end_at: "23:59:59",
  critical_level: "medium",
  open_noti_id: "alert",
  close_noti_id: "event_end",
  selectedVehicleGroup: [],
  all_vehicle: false,
  selectedVehicle: [],
  all_user: false,
  selectedUser: [],
  channel_web: false,
  channel_line: false,
  channel_email: false,
};

const jsonDetailSkeleton = {
  area_location: {
    trigger_inside_range: true,
    area_id: [],
  },
  speed_limit: {
    trigger_inside_range: true,
    range: [90, 120],
  },
  input: {
    event: "engine",
    toggle: true,
  },
  // duration: {
  //   trigger_inside_range: true,
  //   range: [1, 60],
  //   unit: "min"
  // },
  // day_time: {
  //   trigger: true,
  //   startAt: "00:00:00",
  //   endAt: "23:59:59",
  //   monday: false,
  //   tuesday: false,
  //   saturday: false,
  //   wendnesday: false,
  //   thursday: false,
  //   friday: false,
  //   sunday: false
  // }
};

class Detail extends Component {
  state = {
    current: 0,
    zoneList: [],
    initValue: initialValue,
    conditionValue: {
      ...jsonDetailSkeleton.area_location,
      type: "area_location",
    },
    has_duration_type: false,
    has_speed_limit_type: false,
    has_day_time_type: false,
    conditionLoading: false,
    policy_id: !(
      Object.keys(this.props.match.params).length === 0 &&
      this.props.match.params.constructor === Object
    )
      ? this.props.match.params.id
      : null,
    pageAction: !(
      Object.keys(this.props.match.params).length === 0 &&
      this.props.match.params.constructor === Object
    )
      ? "edit"
      : "create",
  };

  next = () => {
    const current = this.state.current + 1;
    this.setState({ current });
  };

  prev = () => {
    const current = this.state.current - 1;
    this.setState({ current });
  };

  changePageAction = (value) => this.setState({ pageAction: value });

  setInitValue = (value) => this.setState({ initValue: value });

  handlePropsChange = (obj) => {
    this.setState(obj);
  };

  handleAddCondition = (e) => {
    this.setState({
      conditionLoading: true,
      initValue: {
        ...this.state.initValue,
        conditionList: [
          ...this.state.initValue.conditionList,
          {
            key: autoGenKey("condition-form"),
            initialValues: this.state.conditionValue,
            handlePropsChange: this.handlePropsChange,
            removeCondition: this.removeCondition,
            type: this.state.conditionValue.type,
          },
        ],
      },
    });
    this.setTimeoutLoading();
  };

  removeCondition = (formId) => {
    let { conditionList } = this.state.initValue;

    this.setState({
      conditionLoading: true,
      initValue: {
        ...this.state.initValue,
        conditionList: conditionList.filter((ele) => ele.key != formId),
      },
    });
    this.setTimeoutLoading();
  };

  handlePolicyTypeChange = (value, keyName, formId) => {
    let result = this.state.initValue.conditionList.map((ele) => {
      if (ele.key == formId) {
        return {
          ...ele,
          ...jsonDetailSkeleton[value],
          [keyName]: value,
        };
      } else {
        return ele;
      }
    });

    this.setState({
      conditionLoading: true,
      initValue: {
        ...this.state.initValue,
        conditionList: result,
      },
    });
    this.setTimeoutLoading();
  };

  setTimeoutLoading = (e) => {
    setTimeout(() => {
      this.setState({ conditionLoading: false });
    }, Math.ceil(Math.random() * (1800 - 500) + 500));
  };

  handleInputChange = (value, keyName, formId) => {
    let result = this.state.initValue.conditionList.map((ele) => {
      if (ele.key == formId) {
        return {
          ...ele,
          initialValues: {
            ...ele.initialValues,
            [keyName]: value,
          },
        };
      } else {
        return ele;
      }
    });

    this.setState({
      initValue: {
        ...this.state.initValue,
        conditionList: result,
      },
    });
  };

  componentDidMount() {
    if (this.state.pageAction == "edit") {
      this.props.loadPolicyDetail({ id: this.state.policy_id });
      return <Redirect to="/policy" />;
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.policyState.selectedPolicy.policy_id !=
      nextProps.policyState.selectedPolicy.policy_id
    ) {
      if (this.state.pageAction == "create") {
        this.setState({
          initValue: initialValue,
        });
      } else {
        this.setState({
          initValue: { ...nextProps.policyState.selectedPolicy },
        });
      }
    }
    return true;
  }

  createPolicy = (cb) => {
    this.props.createPolicy(
      {
        ...this.state.initValue,
        company_id: this.props.auth.profile.company_id,
        created_by: this.props.auth.profile.id,
        accessToken: this.props.auth.accessToken,
      },
      cb
    );
  };

  updatePolicy = (cb) => {
    this.props.updatePolicy(
      {
        ...this.state.initValue,
        company_id: this.props.auth.profile.company_id,
        updated_by: this.props.auth.profile.id,
        accessToken: this.props.auth.accessToken,
      },
      cb
    );
  };

  renderRedirect = () => <Redirect to="/policy" />;

  render() {
    console.log(this.state.initValue.conditionList);
    const steps = [
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.n.nameAndType}
          </LangContext.Consumer>
        ),
        content: (
          <Step1Form
            key={autoGenKey("policy-step")}
            conditionLoading={this.state.conditionLoading}
            current={this.state.current}
            next={this.next}
            prev={this.prev}
            loading={this.props.policyState.detailLoading}
            history={history}
            action={this.state.pageAction}
            loadPolicyDetail={this.props.loadPolicyDetail}
            setInitValue={this.setInitValue}
            changePageAction={this.changePageAction}
            renderRedirect={this.renderRedirect}
            initialValues={this.state.initValue}
            handlePropsChange={this.handlePropsChange}
            handleAddCondition={this.handleAddCondition}
            removeCondition={this.removeCondition}
            handlePolicyTypeChange={this.handlePolicyTypeChange}
            handleInputChange={this.handleInputChange}
            jsonDetailSkeleton={jsonDetailSkeleton}
            has_duration_type={this.state.has_duration_type}
            has_speed_limit_type={this.state.has_speed_limit_type}
            has_day_time_type={this.state.has_day_time_type}
          />
        ),
      },
      // {
      //   title: (
      //     <LangContext.Consumer>
      //       {i18n => i18n.d.description}
      //     </LangContext.Consumer>
      //   ),
      //   content: (
      //     <Step2Form
      //       key={autoGenKey("policy-step")}
      //       companyId={this.props.auth.profile.company_id}
      //       token={this.props.auth.accessToken}
      //       current={this.state.current}
      //       next={this.next}
      //       prev={this.prev}
      //       loading={this.props.policyState.detailLoading}
      //       history={history}
      //       action={this.state.pageAction}
      //       setInitValue={this.setInitValue}
      //       changePageAction={this.changePageAction}
      //       renderRedirect={this.renderRedirect}
      //       initialValues={this.state.initValue}
      //       // createPolicy={this.createPolicy}
      //       // updatePolicy={this.updatePolicy}
      //     />
      //   )
      // },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.v.vehicle}
          </LangContext.Consumer>
        ),
        content: (
          <VehicleForm
            key={autoGenKey("policy-step")}
            companyId={this.props.auth.profile.company_id}
            token={this.props.auth.accessToken}
            current={this.state.current}
            next={this.next}
            prev={this.prev}
            history={history}
            action={this.state.pageAction}
            setInitValue={this.setInitValue}
            initialValues={this.state.initValue}
          />
        ),
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.n.notification}
          </LangContext.Consumer>
        ),
        content: (
          <NotiConfig
            key={autoGenKey("policy-step")}
            companyId={this.props.auth.profile.company_id}
            token={this.props.auth.accessToken}
            current={this.state.current}
            next={this.next}
            prev={this.prev}
            history={history}
            action={this.state.pageAction}
            setInitValue={this.setInitValue}
            initialValues={this.state.initValue}
            createPolicy={this.createPolicy}
            updatePolicy={this.updatePolicy}
          />
        ),
      },
    ];
    return (
      <AuthorizeComponent matching_name="policy">
        <GeneralStyledContent>
          <Spin spinning={this.props.policyState.detailLoading}>
            <Row gutter={24}>
              <Col span={24}>
                <Breadcrumb
                  match={this.props.match}
                  style={{ margin: "0px 0px 14px 10px" }}
                />
              </Col>
            </Row>
            <StyledSearchForm>
              <Row gutter={24}>
                <Col span={4} />
                <Col span={16}>
                  <Steps current={this.state.current}>
                    {steps.map((item) => (
                      <Step
                        key={autoGenKey("policy-header-step")}
                        title={item.title}
                      />
                    ))}
                  </Steps>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <div style={{ margin: "45px 0px 0px 0px" }}>
                    {steps[this.state.current].content}
                  </div>
                </Col>
              </Row>
            </StyledSearchForm>
          </Spin>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

export default connect(({ policyState, auth }) => ({ policyState, auth }), {
  loadPolicyDetail: loadPolicyDetail.request,
  createPolicy: createPolicy.request,
  updatePolicy: updatePolicy.request,
})(Detail);
