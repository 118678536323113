import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { Link, Redirect } from "react-router-dom";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import { StyledSearchForm } from "../../../../styled/common-styled";
import { getlocalstorage } from "./../../../../constants/local_storage";
import axios from "axios";
import { Button, Row, Col, Spin } from "antd";

class Detail extends Component {
  state = {
    loading: true,
    detail: {
      email_subject: "",
      to_email: "",
      cc_email: "",
      body_email: "",
      report_name: "",
      matching_name: "",
      attachment_type: "",
      schedule_type: "",
      week: "",
      time: "",
      start_time: "",
      end_time: "",
      previous_data: "",
    },
  };

  getWeek = (week) => {
    let value = "";
    if (week == "1") {
      value = (
        <LangContext.Consumer>{(i18n) => i18n.w.w_sunday}</LangContext.Consumer>
      );
    } else if (week == "2") {
      value = (
        <LangContext.Consumer>{(i18n) => i18n.w.w_monday}</LangContext.Consumer>
      );
    } else if (week == "3") {
      value = (
        <LangContext.Consumer>
          {(i18n) => i18n.w.w_tuesday}
        </LangContext.Consumer>
      );
    } else if (week == "4") {
      value = (
        <LangContext.Consumer>
          {(i18n) => i18n.w.w_wednesday}
        </LangContext.Consumer>
      );
    } else if (week == "5") {
      value = (
        <LangContext.Consumer>
          {(i18n) => i18n.w.w_thursday}
        </LangContext.Consumer>
      );
    } else if (week == "6") {
      value = (
        <LangContext.Consumer>{(i18n) => i18n.w.w_friday}</LangContext.Consumer>
      );
    } else if (week == "7") {
      value = (
        <LangContext.Consumer>
          {(i18n) => i18n.w.w_saturday}
        </LangContext.Consumer>
      );
    }

    return value;
  };

  componentDidMount() {
    let self = this;
    let id = this.props.match.params.id;
    const ACCESSTOKEN = localStorage.getItem("access-token");
    axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/schedulereport/detail/${id}`,
        {
          headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
        }
      )
      .then(function(response) {
        //console.log(response.data);
        self.setState({ detail: response.data.data, loading: false });
      })
      .catch(function(error) {
        console.log(error);
      });

    // End Load User Group Premission
  }

  render() {
    return (
      <AuthorizeComponent matching_name="schedulereport">
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={24} md={5} lg={5} xl={5} />
            <Col sm={24} md={14} lg={14} xl={14}>
              <Spin spinning={this.state.loading}>
                <StyledSearchForm>
                  <Row style={{ marginTop: "17px", marginBottom: "20px" }}>
                    <Col span={8} style={{ padding: "5px" }} align="right">
                      <b>
                        <LangContext.Consumer>
                          {(i18n) => i18n.e.email_subject}
                        </LangContext.Consumer>
                        :
                      </b>
                    </Col>
                    <Col span={16} style={{ padding: "5px" }}>
                      {this.state.detail.email_subject}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "17px", marginBottom: "20px" }}>
                    <Col span={8} style={{ padding: "5px" }} align="right">
                      <b>
                        <LangContext.Consumer>
                          {(i18n) => i18n.t.to_email}
                        </LangContext.Consumer>
                        :
                      </b>
                    </Col>
                    <Col span={16} style={{ padding: "5px" }}>
                      {this.state.detail.to_email}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "17px", marginBottom: "20px" }}>
                    <Col span={8} style={{ padding: "5px" }} align="right">
                      <b>
                        <LangContext.Consumer>
                          {(i18n) => i18n.c.cc_email}
                        </LangContext.Consumer>
                        :
                      </b>
                    </Col>
                    <Col span={16} style={{ padding: "5px" }}>
                      {this.state.detail.cc_email}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "17px", marginBottom: "20px" }}>
                    <Col span={8} style={{ padding: "5px" }} align="right">
                      <b>
                        <LangContext.Consumer>
                          {(i18n) => i18n.b.body_email}
                        </LangContext.Consumer>
                        :
                      </b>
                    </Col>
                    <Col span={16} style={{ padding: "5px" }}>
                      {this.state.detail.body_email}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "17px", marginBottom: "20px" }}>
                    <Col span={8} style={{ padding: "5px" }} align="right">
                      <b>
                        <LangContext.Consumer>
                          {(i18n) => i18n.r.report}
                        </LangContext.Consumer>
                        :
                      </b>
                    </Col>
                    <Col span={16} style={{ padding: "5px" }}>
                      <LangContext.Consumer>
                        {(i18n) =>
                          i18n.scheduleReport[this.state.detail.matching_name]
                        }
                      </LangContext.Consumer>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "17px", marginBottom: "20px" }}>
                    <Col span={8} style={{ padding: "5px" }} align="right">
                      <b>
                        <LangContext.Consumer>
                          {(i18n) => i18n.a.attachment_type}
                        </LangContext.Consumer>
                        :
                      </b>
                    </Col>
                    <Col span={16} style={{ padding: "5px" }}>
                      <LangContext.Consumer>
                        {(i18n) =>
                          i18n.scheduleReport[this.state.detail.attachment_type]
                        }
                      </LangContext.Consumer>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "17px", marginBottom: "20px" }}>
                    <Col span={8} style={{ padding: "5px" }} align="right">
                      <b>
                        <LangContext.Consumer>
                          {(i18n) => i18n.s.schedule_type}
                        </LangContext.Consumer>
                        :
                      </b>
                    </Col>
                    <Col span={16} style={{ padding: "5px" }}>
                      {this.state.detail.schedule_type == "daily" ? (
                        <LangContext.Consumer>
                          {(i18n) => i18n.r.report_daily}
                        </LangContext.Consumer>
                      ) : (
                        <LangContext.Consumer>
                          {(i18n) => i18n.r.report_weekly}
                        </LangContext.Consumer>
                      )}
                    </Col>
                  </Row>
                  {this.state.detail.schedule_type == "weekly" ? (
                    <Row style={{ marginTop: "17px", marginBottom: "20px" }}>
                      <Col span={8} style={{ padding: "5px" }} align="right">
                        <b>
                          <LangContext.Consumer>
                            {(i18n) => i18n.s.scheduled_day}
                          </LangContext.Consumer>
                          :
                        </b>
                      </Col>
                      <Col span={16} style={{ padding: "5px" }}>
                        {this.getWeek(this.state.detail.week)}
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  <Row style={{ marginTop: "17px", marginBottom: "20px" }}>
                    <Col span={8} style={{ padding: "5px" }} align="right">
                      <b>
                        <LangContext.Consumer>
                          {(i18n) => i18n.s.schedule_time}
                        </LangContext.Consumer>
                        :
                      </b>
                    </Col>
                    <Col span={16} style={{ padding: "5px" }}>
                      {this.state.detail.time}
                    </Col>
                  </Row>

                  {this.state.detail.schedule_type == "daily" ? (
                    <div>
                      <Row style={{ marginTop: "17px", marginBottom: "20px" }}>
                        <Col span={8} style={{ padding: "5px" }} align="right">
                          <b>
                            <LangContext.Consumer>
                              {(i18n) => i18n.d.duration_time}
                            </LangContext.Consumer>
                            :
                          </b>
                        </Col>
                        <Col span={16} style={{ padding: "5px" }}>
                          {this.state.detail.start_time}
                          <LangContext.Consumer>
                            {(i18n) => i18n.t.to}
                          </LangContext.Consumer>
                          {this.state.detail.end_time}
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "17px", marginBottom: "20px" }}>
                        <Col span={8} style={{ padding: "5px" }} align="right">
                          <b>
                            <LangContext.Consumer>
                              {(i18n) => i18n.s.send_data_before_delivery}
                            </LangContext.Consumer>
                            :
                          </b>
                        </Col>
                        <Col span={16} style={{ padding: "5px" }}>
                          {this.state.detail.previous_data == 1 ? (
                            <LangContext.Consumer>
                              {(i18n) => i18n.y.yes}
                            </LangContext.Consumer>
                          ) : (
                            <LangContext.Consumer>
                              {(i18n) => i18n.n.no}
                            </LangContext.Consumer>
                          )}
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    ""
                  )}
                  <Row style={{ marginTop: "17px", marginBottom: "20px" }}>
                    <Col
                      span={8}
                      style={{ padding: "5px" }}
                      align="right"
                    ></Col>
                    <Col span={16} style={{ padding: "5px" }}>
                      <Link to={`/schedulereport`}>
                        <Button
                          type="default"
                          style={{ marginLeft: "5px", marginRight: "10px" }}
                        >
                          <LangContext.Consumer>
                            {(i18n) => i18n.b.back}
                          </LangContext.Consumer>
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </StyledSearchForm>
              </Spin>
            </Col>
            <Col sm={24} md={5} lg={5} xl={5} />
          </Row>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}
export default Detail;
