import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

// load device config
function* loadDeviceConfig(action) {
  const {
    data: { id, accessToken },
  } = action.payload

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/deviceconfig/get/${id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    )
    yield put(actions.loadDeviceConfig.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadDeviceConfig.failure())
  }
}
// update device config
function* updateDeviceConfig(action) {
  const {
    data: {
      id,
      default_page,

      google_key,

      wifi_status,
      wifi_username,
      wifi_password,

      video_type,

      video_receive_size,

      image_resolution,
      image_position,
      image_update_frequency,
      keep_image,

      emergency_telephone_number,
      emergency_telephone_number2,
      incoming_call_response,
      incoming_call_response_auto_type,

      data_frequency_engine_on,

      data_frequency_engine_off,

      flag_socket,
      flag_shell,

      install_camera_inside_car,
      rtsp_authen,
      inside_camera_type_index,
      inside_camera_type,
      inside_camera_user,
      inside_camera_password,
      inside_camera_path,
      inside_camera_port,
      inside_camera_ip,

      wifi_ssid,
      wifi_ssid_password,

      auto_upload_video_when_connect_wifi,

      auto_upload_server_ip,
      auto_upload_username,
      auto_upload_password,
      auto_upload_path,
      uploadvideocellular,
      low_quality_video,

      temperature_offset,

      extra_turn_left_min,
      extra_turn_left_max,
      extra_turn_left_duration,
      extra_turn_right_min,
      extra_turn_right_max,
      extra_turn_right_duration,
      extra_horn_min,
      extra_horn_max,
      extra_horn_duration,
      extra_low_light_min,
      extra_low_light_max,
      extra_low_light_duration,
      extra_high_light_min,
      extra_high_light_max,
      extra_high_light_duration,
      extra_wiper_min,
      extra_wiper_max,
      extra_wiper_duration,
      extra_backup_light_min,
      extra_backup_light_max,
      extra_backup_light_duration,
      extra_break_light_min,
      extra_break_light_max,
      extra_break_light_duration,
      mute_sound,

      icing_install_camera_inside_car,
      icing_camera_channel,
      icing_multiple_camera_setting,
      accessToken,
    },
    loading,
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/deviceconfig/update`,
      {
        id,
        default_page,

        google_key,

        wifi_status,
        wifi_username,
        wifi_password,

        video_type,

        video_receive_size,

        image_resolution,
        image_position,
        image_update_frequency,
        keep_image,

        emergency_telephone_number,
        emergency_telephone_number2,
        incoming_call_response,
        incoming_call_response_auto_type,

        data_frequency_engine_on,

        data_frequency_engine_off,

        flag_socket,
        flag_shell,

        install_camera_inside_car,
        rtsp_authen,
        inside_camera_type_index,
        inside_camera_type,
        inside_camera_user,
        inside_camera_password,
        inside_camera_path,
        inside_camera_port,
        inside_camera_ip,

        wifi_ssid,
        wifi_ssid_password,

        auto_upload_video_when_connect_wifi,

        auto_upload_server_ip,
        auto_upload_username,
        auto_upload_password,
        auto_upload_path,
        uploadvideocellular,
        low_quality_video,

        temperature_offset,

        extra_turn_left_min,
        extra_turn_left_max,
        extra_turn_left_duration,
        extra_turn_right_min,
        extra_turn_right_max,
        extra_turn_right_duration,
        extra_horn_min,
        extra_horn_max,
        extra_horn_duration,
        extra_low_light_min,
        extra_low_light_max,
        extra_low_light_duration,
        extra_high_light_min,
        extra_high_light_max,
        extra_high_light_duration,
        extra_wiper_min,
        extra_wiper_max,
        extra_wiper_duration,
        extra_backup_light_min,
        extra_backup_light_max,
        extra_backup_light_duration,
        extra_break_light_min,
        extra_break_light_max,
        extra_break_light_duration,
        mute_sound,
        icing_install_camera_inside_car,
        icing_camera_channel,
        icing_multiple_camera_setting,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    )
    loading(res.data)
    yield put(actions.updateDeviceConfig.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.updateDeviceConfig.failure())
  }
}

export default function* watchDeviceConfig() {
  yield all([
    takeEvery(actions.LOAD_DEVICE_CONFIG.REQUEST, loadDeviceConfig),
    takeEvery(actions.UPDATE_DEVICE_CONFIG.REQUEST, updateDeviceConfig),
  ])
}
export { loadDeviceConfig, updateDeviceConfig }
