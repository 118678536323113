import axios from "axios";
import { all, put, call, takeEvery } from "redux-saga/effects";
import * as actions from "./actions";
import { getErrorMsg } from "lib/fetch";

function* loadVehicleBrand(action) {
  try {
    const { data, page, pageSize } = action.payload;

    const ACCESSTOKEN = localStorage.getItem("access-token");

    const response = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/mangefuelrate/getvehiclebrand`,
      {
        ...data,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );

    yield put(
      actions.loadVehicleBrand.success(
        response.data,
        page,
        pageSize,
        response.data.totalCount
      )
    );
  } catch (error) {
    const errorMessage = getErrorMsg(error);
    yield put(actions.loadVehicleBrand.failure(errorMessage));
  }
}

function* updateVehicleBrand(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { data, generateMsg } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/mangefuelrate/updatevehiclebrand`,
      {
        ...data,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(actions.updateVehicleBrand.success(res.data));
  } catch (err) {
    yield put(actions.updateVehicleBrand.failure(getErrorMsg(err)));
  }
  generateMsg();
}

function* loadVehicleBrands(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { text } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/mangefuelrate/getvehiclebrands`,
      text,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(actions.loadVehicleBrands.success(res.data.data));
  } catch (err) {
    yield put(actions.loadVehicleBrands.failure());
  }
}

export default function* watchManageFuelRate() {
  yield all([
    takeEvery(actions.LOAD_VEHICLE_BRANDS.REQUEST, loadVehicleBrands),
    takeEvery(actions.LOAD_VEHICLE_BRAND.REQUEST, loadVehicleBrand),
    takeEvery(actions.UPDATE_VEHICLE_BRAND.REQUEST, updateVehicleBrand),
  ]);
}

export { loadVehicleBrand, updateVehicleBrand, loadVehicleBrands };
