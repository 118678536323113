// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import { Table, Pagination } from 'antd'

class TableLog extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      data,
      column,
      className,
      pagination,
      total,
      handleChangePage,
    } = this.props

    return (
      <div className={className}>
        <div>
          <Table
            size='small'
            rowKey={(record) => record.key}
            columns={column}
            dataSource={data}
            scroll={{ x: 1000, y: 1000 }}
            bordered
            className='table-row'
            pagination={false}
          ></Table>
        </div>
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Pagination
            current={pagination.page}
            page={pagination.page}
            pageSize={pagination.pageSize}
            total={total}
            onChange={(page, pageSize) => handleChangePage(page, pageSize)}
          />
        </div>
      </div>
    )
  }
}

export default connect(
  ({ ui: { language } }) => ({
    language,
  }),
  {}
)(TableLog)
