import { createTypes, createAction } from "lib/action";

const LOAD_MASTERDATA_PLACE = createTypes("masterdataplace", "load");
const CREATE_MASTERDATAPLACE = createTypes("masterdataplace", "create");
const LOAD_DETAILMASTERDATA_PLACE = createTypes("detailmasterdataplace", "load");
const LOAD_DETAILMASTERDATA_PLACE_EDIT = createTypes("detailmasterdataplaceedit", "load");
const UPDATE_DETAILMASTERDATA_PLACE = createTypes("detailmasterdataplace", "update");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
  return {
    type: REMOVE_LISTS
  };
}

const loadMasterDataPlace = {
    request: (data, loading) =>
      createAction(LOAD_MASTERDATA_PLACE.REQUEST, { data, loading}),
    success: (lists, page) =>
      createAction(LOAD_MASTERDATA_PLACE.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_MASTERDATA_PLACE.FAITLURE)  
};

const loadDetailMasterDataPlace = {
  request: (data, loading) =>
    createAction(LOAD_DETAILMASTERDATA_PLACE.REQUEST, { data, loading}),
  success: (value) =>
    createAction(LOAD_DETAILMASTERDATA_PLACE.SUCCESS, {value}),
  failure: () => createAction(LOAD_DETAILMASTERDATA_PLACE.FAITLURE)  
};

const loadDetailMasterDataPlaceEdit = {
  request: (data, loading) =>
    createAction(LOAD_DETAILMASTERDATA_PLACE_EDIT.REQUEST, { data, loading}),
  success: (value) =>
    createAction(LOAD_DETAILMASTERDATA_PLACE_EDIT.SUCCESS, {value}),
  failure: () => createAction(LOAD_DETAILMASTERDATA_PLACE_EDIT.FAITLURE)  
};

const createMasterDataPlace = {
  request: (data, loading) =>
    createAction(CREATE_MASTERDATAPLACE.REQUEST, { data, loading}),
  success: res => createAction(CREATE_MASTERDATAPLACE.SUCCESS, { res }),
    failure: () => createAction(CREATE_MASTERDATAPLACE.FAILURE)
};

const upDateDetailMasterDataPlace = {
  request: (data, loading) =>
    createAction(UPDATE_DETAILMASTERDATA_PLACE.REQUEST, {data, loading}),
  success: res => createAction(UPDATE_DETAILMASTERDATA_PLACE.SUCCESS, { res }),
    failure: () => createAction(UPDATE_DETAILMASTERDATA_PLACE.FAILURE)
};

export {
  LOAD_MASTERDATA_PLACE,
  loadMasterDataPlace,
  loadDetailMasterDataPlace,
  LOAD_DETAILMASTERDATA_PLACE,
  loadDetailMasterDataPlaceEdit,
  LOAD_DETAILMASTERDATA_PLACE_EDIT,
  CREATE_MASTERDATAPLACE,
  createMasterDataPlace,
  upDateDetailMasterDataPlace,
  UPDATE_DETAILMASTERDATA_PLACE,
  REMOVE_LISTS,
  removeLists
};