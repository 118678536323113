import React from "react";

const genDeviceProcessDetail = (userList, useFooter = true) => {
  let download = [];
  let livestream = [];
  let playback = [];

  Object.keys(userList).map((cmdType) => {
    switch (cmdType) {
      case "livestreamVideo":
      case "livestream":
        livestream = [...livestream, ...userList[cmdType]];
        break;
      case "playbackVideo":
      case "playback_video":
        playback = [...playback, ...userList[cmdType]];
        break;
      case "downloadVideo":
      case "download_video":
        download = [...download, ...userList[cmdType]];
        break;
      default:
        break;
    }
  });

  let livestreamlist = [];
  livestream.map((x) => {
    livestreamlist[x] =
      typeof livestreamlist[x] === "undefined" ? 1 : livestreamlist[x] + 1;
  });

  let playbacklist = [];
  playback.map((x) => {
    playbacklist[x] =
      typeof playbacklist[x] === "undefined" ? 1 : playbacklist[x] + 1;
  });

  let downloadlist = [];
  download.map((x) => {
    downloadlist[x] =
      typeof downloadlist[x] === "undefined" ? 1 : downloadlist[x] + 1;
  });

  let htmlDownload = "";
  let htmlPlayback = "";
  let htmlLivestream = "";

  let cntLivestream = livestream.length;
  let cntPlayback = playback.length;
  let cntDownload = download.length;

  const style =
    "padding:10px;background:#fff4e4;margin-top:10px;margin-bottom:10px";
  const style2 = "margin-top:20px";

  if (cntDownload > 0) {
    let tb = getProcessTable(downloadlist);
    htmlDownload = `<div style=${style}><p><b>ดาวโหลดวีดีโอ ${cntDownload} คน</b><br/>${tb}<p></div>`;
  }

  if (cntPlayback > 0) {
    let tb = getProcessTable(playbacklist);
    htmlPlayback = `<div style=${style}><p><b>วีดีโอย้อนหลัง ${cntPlayback} คน</b><br/>${tb}</p></div>`;
  }

  if (cntLivestream > 0) {
    let tb = getProcessTable(livestreamlist);
    htmlLivestream = `<div style=${style}><p><b>วีดีโอเรียลไทม์ ${cntLivestream} คน</b><br/>${tb}</p></div>`;
  }

  let footer = `<div style=${style2}><p>**หากมีการเชื่อมต่อการใช้งานวีดีโอจากผู้ใช้งานอื่น อาจทำให้การเชื่อมต่อข้อมูลทำได้ช้า</p></div>`;
  if (!useFooter) {
    footer = "";
  }

  let html = htmlDownload + htmlPlayback + htmlLivestream + footer;
  return html;
};

const getProcessTable = (data) => {
  let body = "";
  Object.keys(data).map((x) => {
    body += `<tr><td>${x}</td><td>${data[x]}</td></tr>`;
  });

  let html = `<table style="width:100%">
        <thead>
          <tr>
            <th style="width:70%">ชื่อผู้ใช้งาน</th>
            <th style="width:30%">จำนวน</th>
          </tr>
        </thead>
        <tbody>${body}</tbody>
      </table>`;

  return html;
};

export { genDeviceProcessDetail, getProcessTable };
