import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";

import {
  Button,
  Form,
  Select,
  Row,
  Col,
  Spin,
  DatePicker,
  Transfer,
  Checkbox,
  Dropdown,
  Menu,
  Icon
} from "antd";

import LangContext, { i18n } from "modules/shared/context/langContext";


const { RangePicker } = DatePicker;
const FormItem = Form.Item;
const Option = Select.Option;

export default class Forms extends Component {
  state = {
    event_date_start: "",
    event_date_end: "",
    vehicleList: [],
    vehicleSelect: [],
    selectedKeys: [],
    vehicle_type_id: "",
    initVehicleSelect: []
  };

  componentDidMount() {
    const { loadVehicle, loadVehicleType, call } = this.props;
    // console.log(this.props)
    loadVehicle("", false);
    loadVehicleType();
    if (call == "schedule") {
      //console.log("ddd", call, this.props.dataedit)
      if (this.props.dataedit != "") {
        let obj = JSON.parse(this.props.dataedit);
        this.setState({
          vehicleSelect: obj.list_vehicle_id,
          vehicle_type_id: obj.vehicle_type_id,
          initVehicleSelect: obj.list_vehicle_id
        });
      }
    }

  }

  onVehicleTypeChange = (value, setFieldValue) => {
    const { loadVehicle } = this.props;
    setFieldValue("vehicle_type_id", value);
    loadVehicle(value, true);
    this.setState({ vehicleSelect: [] });
    this.setState({ selectedKeys: [] });
    setFieldValue("list_vehicle_id", []);
  };

  componentWillReceiveProps = nextProps => {
    const { vehicleLists } = nextProps;
    const vehlist = [];
    vehicleLists.map(item =>
      vehlist.push({
        key: item.id,
        name: item.plate_no
      })
    );

    this.setState({ vehicleList: vehlist });
  };

  tranferChange = (targetKeys, setFieldValue) => {
    setFieldValue("list_vehicle_id", targetKeys);
    this.setState({ vehicleSelect: targetKeys });
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys]
    });
  };


  render() {
    const {
      vehicleTypeLists,
      loading,
      onSubmit
    } = this.props;

    let validationShape = {
      eventdate: yup.array().required("กรุณาเลือกช่วงวันที่"),
      list_vehicle_id: yup.array().required("กรุณาเลือกรถ")
    };

    let submit_name = (
      <LangContext.Consumer>{i18n => i18n.r.report}</LangContext.Consumer>
    );


    if (this.props.call === "schedule") {
      validationShape = {
        list_vehicle_id: yup.array().required("กรุณาเลือกรถ")
      };

      submit_name = (
        <LangContext.Consumer>{i18n => i18n.s.save}</LangContext.Consumer>
      );
    }



    return (
      <div>
        <h3> <LangContext.Consumer>
          {i18n => i18n.d.DailyiconsiamReport}
        </LangContext.Consumer></h3>
        <Spin spinning={loading}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              vehicle_type_id: this.state.vehicle_type_id,
              eventdate: [moment("00:00", "HH:mm"), moment("23:59", "HH:mm")],
              list_vehicle_id: this.state.initVehicleSelect
            }}
            validate={values => {
              // let errors = {};
              // if (values.eventdate.length > 0) {
              //   const date_start = moment(values.eventdate[0]["_d"]);
              //   const date_end = moment(values.eventdate[1]["_d"]);
              //   let day_diff = date_end.diff(date_start, "days");
              //   if (day_diff > 31) {
              //     errors.eventdate = "ช่วงวันที่ที่เลือกไม่ควรเกิน 31 วัน";
              //   }
              // }

              // return errors;
            }}

            validationSchema={
              yup.object().shape(
                validationShape
              )
            }
            onSubmit={values => {
              //console.log(values);
              const loading = true;
              onSubmit(values, loading);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleFocus,
              setFieldValue,
              isValidating
            }) => (
                <Row type="flex" justify="center">
                  <Form onSubmit={handleSubmit}>
                    <FormItem
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.v.vehicleGroup}
                        </LangContext.Consumer>
                      }
                      validateStatus={
                        touched.vehicle_type_id &&
                        errors.vehicle_type_id &&
                        "warning"
                      }
                      help={touched.vehicle_type_id && errors.vehicle_type_id}
                    >
                      <Select
                        //onChange={value => setFieldValue("vehicle_type_id", value)}
                        onChange={value =>
                          this.onVehicleTypeChange(value, setFieldValue)
                        }
                        value={values.vehicle_type_id}
                      >
                        <Option value=""> <LangContext.Consumer>
                          {i18n => i18n.a.all}
                        </LangContext.Consumer></Option>
                        {vehicleTypeLists.map(item => (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>
                    <FormItem
                      required={true}
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.v.vehicle}
                        </LangContext.Consumer>
                      }
                      validateStatus={
                        touched.list_vehicle_id &&
                        errors.list_vehicle_id &&
                        "warning"
                      }
                      help={touched.list_vehicle_id && errors.list_vehicle_id}
                    >
                      <Transfer titles={[
                        <LangContext.Consumer>
                          {i18n => i18n.i.itemLeft}
                        </LangContext.Consumer>,
                        <LangContext.Consumer>
                          {i18n => i18n.i.itemSelected}
                        </LangContext.Consumer>
                      ]}
                        dataSource={this.state.vehicleList}
                        showSearch
                        listStyle={{
                          width: 250,
                          height: 300
                        }}
                        selectedKeys={this.state.selectedKeys}
                        operations={[">>", "<<"]}
                        targetKeys={this.state.vehicleSelect}
                        onSelectChange={this.handleSelectChange}
                        onChange={value =>
                          this.tranferChange(value, setFieldValue)
                        }
                        //onChange={this.tranferChange}
                        render={item => `${item.name}`}
                        footer={this.renderFooter}
                      />
                    </FormItem>
                    {this.props.call !== "schedule" ?
                      <Form.Item
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.d.dateRange}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.eventdate && errors.eventdate && "warning"
                        }
                        help={touched.eventdate && errors.eventdate}
                      >
                        <RangePicker
                          showTime={{ format: "HH:mm" }}
                          onChange={value => setFieldValue("eventdate", value)}
                          format="DD/MM/YYYY HH:mm"
                          placeholder={["Start Time", "End Time"]}
                          defaultValue={[
                            moment("00:00", "HH:mm"),
                            moment("23:59", "HH:mm")
                          ]}
                        />
                      </Form.Item>
                      : ""}

                    <FormItem>
                      <Row gutter={24}>
                        {this.props.call !== "schedule" ?
                          <Col span={8}>

                            <Button type="defualt" block>
                              <Link to="/">
                                <LangContext.Consumer>
                                  {i18n => i18n.c.cancel}
                                </LangContext.Consumer>
                              </Link>
                            </Button>

                          </Col>
                          : ""}
                        <Col span={8}>
                          <Button type="primary" htmlType="submit" block>
                            {submit_name}
                          </Button>
                        </Col>
                      </Row>
                    </FormItem>
                  </Form>
                </Row>
              )}
          />
        </Spin>
      </div>
    );
  }
}
