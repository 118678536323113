import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";

import {
  Button,
  Form,
  Select,
  Row,
  Col,
  Spin,
  DatePicker,
  Transfer,
  InputNumber
 
} from "antd";

import LangContext, { i18n } from "modules/shared/context/langContext";

const { RangePicker } = DatePicker;
const FormItem = Form.Item;
const Options = Select.Option;

class Forms extends Component {
  state = {
    vehicleList: [],
    vehicleSelect: [],
    selectedKeys: [],
    vehicle_group_id: "",
    company_id:"",
    fueladd:0,
    fueltank:0,
    fueldate:[]
   // initVehicleSelect: [],
  };

  componentDidMount() {
    const {
      loadCompany,
    } = this.props;
    loadCompany();
  }

  onVehicleGroupChange = (value, setFieldValue) => {
    const { loadVehicle } = this.props;
    setFieldValue("vehicle_group_id", value);
    console.log("group:",value)
    loadVehicle(this.state.company_id,value);
    this.setState({ selectedKeys: [], vehicleSelect: [],vehicle_group_id:value });
    setFieldValue("list_vehicle_id", []);
  };

  onCompanyChange = (value, setFieldValue) => {
    const { loadVehicleGroup,loadVehicle } = this.props;
    setFieldValue("company_id", value);
    loadVehicleGroup(value);
    loadVehicle(value,"");
    this.setState({ selectedKeys: [], vehicleSelect: [], company_id:value,vehicle_group_id:""});
    setFieldValue("list_vehicle_id", []);
  };

  tranferChange = (targetKeys, setFieldValue) => {
    setFieldValue("list_vehicle_id", targetKeys);
    this.setState({ vehicleSelect: targetKeys });
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    });
  };


 

  render() {
    const {
      vehicleLists,
      vehicleGroupLists,
      companyLists,
      loading,
      onSubmit,
    } = this.props;

    return (
      <div>
        <h3>
          <LangContext.Consumer>
            {(i18n) => i18n.c.Calibratefuel}
          </LangContext.Consumer>
        </h3>
        <Spin spinning={loading}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              vehicle_group_id: this.state.vehicle_group_id,
              fueldate: this.state.fueldate,
              list_vehicle_id: this.state.vehicleSelect,
              company_id:this.state.company_id,
              fueltank:this.state.fueltank,
              fueladd:this.state.fueladd
            }}
            validate={(values) => {
              let errors = {};
              if (values.fueldate.length > 0) {
                const date_start = moment(values.fueldate[0]["_d"]);
                const date_end = moment(values.fueldate[1]["_d"]);
                let day_diff = date_end.diff(date_start, "days");
                if (day_diff > 31) {
                  errors.fueldate = <LangContext.Consumer>{i18n => i18n.d.dateRangeNotBeMoreThan31Days}</LangContext.Consumer>;
                }
              }

              if(values.fueltank===0){
                errors.fueltank = <LangContext.Consumer>{i18n => i18n.f.fueltankRequire}</LangContext.Consumer>;
              }

              if(values.fueladd===0){
                errors.fueladd = <LangContext.Consumer>{i18n => i18n.f.fueladdRequire}</LangContext.Consumer>;
              }

              // if (this.state.vehicleSelect.length == 0) {
              //   errors.list_vehicle_id = <LangContext.Consumer>{i18n => i18n.p.pleaseChooseVehicle}</LangContext.Consumer>;
              // }


              return errors;
            }}
            validationSchema={yup.object().shape({
              fueldate: yup.array().required(<LangContext.Consumer>{i18n => i18n.p.pleaseChooseDateRange}</LangContext.Consumer>),
              company_id: yup.string().required(<LangContext.Consumer>{i18n => i18n.p.pleaseChooseCompany}</LangContext.Consumer>),
              list_vehicle_id: yup.array().required(<LangContext.Consumer>{i18n => i18n.p.pleaseChooseVehicle}</LangContext.Consumer>)
            })}
            onSubmit={(values) => {
              const loading = true;
              onSubmit(values, loading);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleFocus,
              setFieldValue,
            }) => (
              <Row type="flex" justify="center">
                <Form onSubmit={handleSubmit}>
                  <FormItem
                    required={true}
                    label={
                      <LangContext.Consumer>
                        {(i18n) => i18n.c.company}
                      </LangContext.Consumer>
                    }
                    validateStatus={
                      touched.company_id && errors.company_id && "warning"
                    }
                    help={touched.company_id && errors.company_id}
                  >
                      <Select
                      //onChange={value => setFieldValue("vehicle_type_id", value)}
                      onChange={(value) =>
                        this.onCompanyChange(value, setFieldValue)
                      }
                      value={values.company_id}
                      showSearch
                      optionFilterProp="children"
                    >
                      <Options value="">
                        <LangContext.Consumer>
                          {(i18n) => i18n.p.pleaseSelect}
                        </LangContext.Consumer>
                      </Options>
                      {companyLists.map((item) => (
                        <Options key={item.key} value={item.key}>
                          {item.value}
                        </Options>
                      ))}
                    </Select>
                        
                    </FormItem>
                    
                    <FormItem
                    label={
                      <LangContext.Consumer>
                        {(i18n) => i18n.v.vehicleGroup}
                      </LangContext.Consumer>
                    }
                    validateStatus={
                      touched.vehicle_group_id &&
                      errors.vehicle_group_id &&
                      "warning"
                    }
                    help={touched.vehicle_group_id && errors.vehicle_group_id}
                  >
                    <Select
                      //onChange={value => setFieldValue("vehicle_type_id", value)}
                      onChange={(value) =>
                        this.onVehicleGroupChange(value, setFieldValue)
                      }
                      value={values.vehicle_group_id}
                    >
                      <Options value="">
                        <LangContext.Consumer>
                          {(i18n) => i18n.a.all}
                        </LangContext.Consumer>
                      </Options>
                      {vehicleGroupLists.map((item) => (
                        <Options key={item.key} value={item.key}>
                          {item.value}
                        </Options>
                      ))}
                    </Select>
                  </FormItem>
                  <FormItem
                    required={true}
                    label={
                      <LangContext.Consumer>
                        {(i18n) => i18n.v.vehicle}
                      </LangContext.Consumer>
                    }
                    validateStatus={
                      touched.list_vehicle_id &&
                      errors.list_vehicle_id &&
                      "warning"
                    }
                    help={touched.list_vehicle_id && errors.list_vehicle_id}
                  >
                    <Transfer
                      titles={[
                        <LangContext.Consumer>
                          {(i18n) => i18n.i.itemLeft}
                        </LangContext.Consumer>,
                        <LangContext.Consumer>
                          {(i18n) => i18n.i.itemSelected}
                        </LangContext.Consumer>,
                      ]}
                      dataSource={vehicleLists}
                      showSearch
                      listStyle={{
                        width: 250,
                        height: 300,
                      }}
                      selectedKeys={this.state.selectedKeys}
                      operations={[">>", "<<"]}
                      targetKeys={this.state.vehicleSelect}
                      onSelectChange={this.handleSelectChange}
                      onChange={(value) =>
                        this.tranferChange(value, setFieldValue)
                      }
                      //onChange={this.tranferChange}
                      render={(item) => `${item.value}`}
                      footer={this.renderFooter}
                    />
                  </FormItem>
        
                    <Form.Item
                      required={true}
                      label={
                        <LangContext.Consumer>
                          {(i18n) => i18n.d.dateRange}
                        </LangContext.Consumer>
                      }
                      validateStatus={
                        touched.fueldate && errors.fueldate && "warning"
                      }
                      help={touched.fueldate && errors.fueldate}
                    >
                      <RangePicker
                        onChange={(value) =>{
                          setFieldValue("fueldate", value)
                          this.setState({fueldate:value})
                        
                        }}
                        format="DD/MM/YYYY"
                       // placeholder={["Start Time", "End Time"]}
                      
                      />
                    </Form.Item>

                    <Form.Item
                      required={true}
                      label={
                        <LangContext.Consumer>
                          {(i18n) => i18n.f.fueltank}
                        </LangContext.Consumer>
                      }
                      validateStatus={
                        touched.fueltank && errors.fueltank && "warning"
                      }
                      help={touched.fueltank && errors.fueltank}
                    >
                       <InputNumber
                          defaultValue={0}
                          value={values.fueltank}
                          onChange={(value) =>{
                            setFieldValue("fueltank", value)
                            this.setState({fueltank:value})
                           }
                          }
                          //size="large"
                          />
                    </Form.Item>


                    <Form.Item
                      required={true}
                      label={
                        <LangContext.Consumer>
                          {(i18n) => i18n.f.fueladd}
                        </LangContext.Consumer>
                      }
                      validateStatus={
                        touched.fueladd && errors.fueladd && "warning"
                      }
                      help={touched.fueladd && errors.fueladd}
                    >
                       <InputNumber
                          defaultValue={0}
                          value={values.fueladd}
                          onChange={(value) =>{
                            setFieldValue("fueladd", value)
                            this.setState({fueladd:value})
                           }
                          }
                         /// size="large"
                         />
                    </Form.Item>
                  
                  <FormItem>
                    <Row gutter={24}>
                        <Col span={8}>
                        <Button type="primary" htmlType="submit">
                            <LangContext.Consumer>
                              {(i18n) => i18n.s.save}
                            </LangContext.Consumer>
                          </Button>
                        </Col>
                      
                    </Row>
                  </FormItem>
                </Form>
              </Row>
            )}
          />
        </Spin>
      </div>
    );
  }
}

export default Forms;
