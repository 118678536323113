import React from "react";
import Form from "./Form/Template/Form";
import { connect } from "react-redux";

const Edit = ({ match }) => {
  return <Form type="edit" match={match} />;
};

const mapStateToProps = ({ auth }) => ({
  auth,
});

export default connect(mapStateToProps)(Edit);
