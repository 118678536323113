import React from "react";
import { Row, Col, Typography } from "antd";
import LineToken from "./LineToken"

const { Text } = Typography;

const Linesetting = ({
  data,
  vehicles,
  title,
  onSubmit
}) => {
  return (
    <div>
      <Row style={{ marginBottom: "20px" }}>
        <Col>
          <div style={{ fontSize: "22px", fontWeight: "bold" }}>
            ตั้งค่าแจ้งเตือนผ่านทาง Line
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Text>{title}: </Text>
        </Col>
      </Row>
      <Row>
        <Col>
          <LineToken
            vehicles={vehicles}
            data={data}
            onSubmit={onSubmit}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Linesetting