import {
  LOAD_DISTFROMLASTMAINTENANCE,
  LOAD_VEHICLE_DISTFROMLASTMAINTENANCE,
  LOAD_VEHICLE_TYPE_DISTFROMLASTMAINTENANCE,
  LOAD_PART_TYPE
} from "./actions";

const initialState = {
  distfromlastmaintenanceUrl: "",
  vehicleLists: [],
  vehicleTypeLists: [],
  partTypeLists: [],
  distfromlastmaintenanceLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DISTFROMLASTMAINTENANCE.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        distfromlastmaintenanceLoading: loading
      };
    case LOAD_DISTFROMLASTMAINTENANCE.SUCCESS:

      return {
        ...state,
        distfromlastmaintenanceUrl: action.payload.data.data,
        distfromlastmaintenanceLoading: false
      };
    case LOAD_VEHICLE_DISTFROMLASTMAINTENANCE.REQUEST:
      const { loading_vehicle } = action.payload;
      return {
        ...state,
        distfromlastmaintenanceLoading: loading_vehicle
      };
    case LOAD_VEHICLE_DISTFROMLASTMAINTENANCE.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        distfromlastmaintenanceLoading: false
      };
    case LOAD_VEHICLE_TYPE_DISTFROMLASTMAINTENANCE.SUCCESS:
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data
      };
    case LOAD_PART_TYPE.SUCCESS:
      return {
        ...state,
        partTypeLists: action.payload.data.data
      };
    default:
      return state;
  }
};
