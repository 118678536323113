import { createTypes, createAction } from "lib/action";

const LOAD_VEHICLESHARING_FROM = createTypes(
  "vehiclesharingfrommasterdata",
  "load"
);
const LOAD_VEHICLESHARING_TO = createTypes(
  "vehiclesharingtomasterdata",
  "load"
);
const LOAD_VEHICLESHARING_TO_DETAIL = createTypes(
  "vehiclesharingdetailtomasterdata",
  "load"
);
const CREATE_VEHICLESHARING = createTypes("vehiclesharingmasterdata", "create");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
  return {
    type: REMOVE_LISTS
  };
}

const loadVehicleSharingTo = {
  request: (data, loading) =>
    createAction(LOAD_VEHICLESHARING_TO.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_VEHICLESHARING_TO.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_VEHICLESHARING_TO.FAILURE)
};

const loadVehicleSharingFrom = {
  request: (data, loading) =>
    createAction(LOAD_VEHICLESHARING_FROM.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_VEHICLESHARING_FROM.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_VEHICLESHARING_FROM.FAILURE)
};

const loadVehicleSharingToDetail = {
  request: data =>
    createAction(LOAD_VEHICLESHARING_TO_DETAIL.REQUEST, { data }),
  success: vehiclesharing =>
    createAction(LOAD_VEHICLESHARING_TO_DETAIL.SUCCESS, { vehiclesharing }),
  failure: () => createAction(LOAD_VEHICLESHARING_TO_DETAIL.FAILURE)
};

const createVehicleSharing = {
  request: (data, cb) =>
    createAction(CREATE_VEHICLESHARING.REQUEST, {
      data,
      cb
    }),
  success: res => createAction(CREATE_VEHICLESHARING.SUCCESS, { res }),
  failure: () => createAction(CREATE_VEHICLESHARING.FAILURE)
};

export {
  CREATE_VEHICLESHARING,
  createVehicleSharing,
  loadVehicleSharingTo,
  LOAD_VEHICLESHARING_TO,
  REMOVE_LISTS,
  removeLists,
  LOAD_VEHICLESHARING_TO_DETAIL,
  loadVehicleSharingToDetail,
  loadVehicleSharingFrom,
  LOAD_VEHICLESHARING_FROM
};
