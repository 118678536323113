import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Row,
    Col
} from "antd";

import * as actions from "../actions";
import Forms from "./Forms";
import { GeneralStyledContent, StyledSearchForm } from "../../../../styled/common-styled";
import Breadcrumb from "./../../../shared/components/Breadcrumb";
import history from "../../../../lib/history";


class Edit extends Component {

    componentDidMount() {
        this.props.loadTripDetail(this.props.match.params.id);
        this.props.loadDeliverorder(this.props.match.params.id, true);
    }

    edit = (values, loading) => {
        this.props.updateTrip(values, this.props.match.params.id, loading, this.props.history);

    };



    render() {
        // const { dataPullvehiclesink, pullvehiclesinkLoading } = this.props.deliverytripState;
        return (
            <GeneralStyledContent>
                <Row gutter={24}>
                    <Col span={24}>
                        <Breadcrumb
                            match={this.props.match}
                            style={{ margin: "0px 0px 14px 10px" }}
                        />
                    </Col>
                </Row>
                <StyledSearchForm>
                    <Forms
                        onSubmit={this.edit}
                        deliverytripState={this.props.deliverytripState}
                        vehicleLists={this.props.deliverytripState.vehicleLists}
                        producttypeLists={this.props.deliverytripState.producttypeLists}
                        driverLists={this.props.deliverytripState.driverLists}
                        loadVehicle={this.props.loadVehicle}
                        loadProducttype={this.props.loadProducttype}
                        trip_id={this.props.match.params.id}
                        selectedTrip={this.props.deliverytripState.selectedTrip}
                        loadDriver={this.props.loadDriver}
                        loading={this.props.deliverytripState.detailLoading}
                        check={this.props.deliverytripState.check}
                        trip_id={this.props.match.params.id}
                    />
                </StyledSearchForm>
            </GeneralStyledContent>
        );
    }
}

const mapStateToProps = ({
    deliverytripState,
}) => ({
    deliverytripState
});

const mapDispatchToProps = {
    loadTripDetail: actions.loadTripDetail.request,
    loadVehicle: actions.loadVehicle.request,
    loadProducttype: actions.loadProducttype.request,
    loadDriver: actions.loadDriver.request,
    updateTrip: actions.updateTrip.request,
    loadDeliverorder: actions.loadDeliverorder.request,
    clearList: actions.clearList
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Edit);
