import {
  LOAD_PRODUCT,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  CLEAR_PRODUCT,
  GET_PRODUCT,
  CLEAR_RESPONSE,
} from "./actions";

const initialState = {
  productLists: [],
  productLoading: false,
  productTotal: 0,
  dataResponse: { status: "", msg: "", id: "", action_type: "" },
  check_product: true,
  check_edit:false,
  dataProduct: {
    id: "",
    name: "",
    code:"",
    description: "",
    uom_id:""
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PRODUCT.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        productLoading: loading
      };
    case LOAD_PRODUCT.SUCCESS:
      return {
        ...state,
        productLists: action.payload.data.data,
        productLoading: false,
        productTotal: action.payload.data.total
      };
    case CREATE_PRODUCT.REQUEST:
      const { loading: loading2 } = action.payload;
      return {
        ...state,
        productLoading: loading2
      };
    case CREATE_PRODUCT.SUCCESS:
      return {
        ...state,
        dataResponse: { status: action.payload.data.status, msg: action.payload.data.msg, action_type: action.payload.data.action_type },
        productLoading: false
      };
    case UPDATE_PRODUCT.REQUEST:
      const { loading: loading3 } = action.payload;
      return {
        ...state,
        productLoading: loading3,
        check_edit:true,
      };
    case UPDATE_PRODUCT.SUCCESS:
      return {
        ...state,
        dataResponse: { status: action.payload.data.status, msg: action.payload.data.msg, action_type: action.payload.data.action_type },
        productLoading: false,
        check_edit:false,
      };

    case GET_PRODUCT.REQUEST:
      return {
        ...state,
        check_product: true,
      };
    case GET_PRODUCT.SUCCESS:
      return {
        ...state,
        dataProduct: action.payload.data.data,
        productLoading: false,
        check_product: false,
      };
   
    case CLEAR_RESPONSE:
      return {
        ...state,
        dataResponse: { status: "", msg: "", id: "", action_type: "" }
      };
    case CLEAR_PRODUCT:
      return {
        ...state,
        dataProduct: {
          id: "",
          name: "",
          code:"",
          description: "",
          uom_id:""
        }
      };
    default:
      return state;
  }
};
