import React, { Component } from "react";
import { connect } from "react-redux";
import LangContext, { i18n } from "modules/shared/context/langContext";
import * as actions from "../actions";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "./../../../shared/components/Breadcrumb";
import { checkPermissions } from "../../../../lib/helper";
import "../../../../styled/fix-line-lost.css";

import {
  Button,
  Table,
  Row,
  Col,
  Popconfirm,
  Icon,
  Tooltip,
  Drawer,
  message,
} from "antd";
import axios from "axios";
import { Link } from "react-router-dom";
import Filter from "./Filter";
const columnStyle = { fontSize: "12px", fontWeight: "400" };

class Index extends Component {
  state = {
    visible: false,
    page: 1,
    pageSize: 10,
    orderBy: "p.updated_at",
    orderType: "desc",
    filterObj: {
      name: "",
    },
    company_id: this.props.auth.profile.company_id,
    token: this.props.auth.accessToken
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onCloseSearch = (resetForm) => {
    this.setState({
      visible: false,
    });
  };

  filterProduct = (values) => {
    const { name } = values;

    this.setState({
      filterObj: {
        name,
      },
    });
    this.props.loadProduct(
      {
        page: this.state.page,
        pageSize: this.state.pageSize,
        orderBy: this.state.orderBy,
        orderType: this.state.orderType,
        filterObj: {
          name,
        },
        company_id: this.props.auth.profile.company_id,
        token: this.props.auth.accessToken
      },
      true
    );
  };

  componentDidMount() {
    this.props.loadProduct(this.state, true);
  }

  componentDidUpdate(prevProps, prevState) {
    const { dataResponse } = this.props.product;

    if (
      dataResponse.action_type === "create" ||
      dataResponse.action_type === "update"
    ) {
      if (dataResponse.status === "success") {
        message.success(i18n[this.props.auth.lang]["s"]["success"]);
      } else if (dataResponse.status === "fail") {
        message.error(i18n[this.props.auth.lang]["f"]["fail"]);
      }
      this.props.clearDataResponse();
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      page: pagination.current,
    });

    let orderBy = "";
    let orderType = "";

    if (Object.keys(sorter).length === 0 && sorter.constructor === Object) {
      orderBy = this.state.orderBy;
      orderType = this.state.orderType;
    } else {
      this.setState({
        orderBy: sorter.columnKey,
        orderType: sorter.order == "descend" ? "desc" : "asc",
      });
      orderBy = sorter.columnKey;
      orderType = sorter.order == "descend" ? "desc" : "asc";
    }

    this.props.loadProduct(
      {
        page: pagination.current,
        pageSize: this.state.pageSize,
        searchName: "",
        orderBy: orderBy,
        orderType: orderType,
        filterObj: {
          name: this.state.filterObj
            .name,
        },
        company_id: this.props.auth.profile.company_id,
        token: this.props.auth.accessToken
      },
      true
    );
  };

  deleteProduct = (id) => {
    let self = this;

    axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/product/delete/${id}`,
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        }
      )
      .then(function (response) {
        self.props.loadProduct(
          {
            page: 1,
            pageSize: self.state.pageSize,
            orderBy: self.state.orderBy,
            orderType: self.state.orderType,
            filterObj: {
              name:
                self.state.filterObj.name,
            },
            company_id: self.props.auth.profile.company_id,
            token: self.props.auth.accessToken
          },
          true
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const columns = [
      {
        title: "#",
        dataIndex: "row_number",
        width: "3%",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.p.product_code}
          </LangContext.Consumer>
        ),
        dataIndex: "code",
        sorter: true,
        width: "10%",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.p.product_name}
          </LangContext.Consumer>
        ),
        dataIndex: "name",
        sorter: true,
        width: "25%",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.u.uom_smallest}
          </LangContext.Consumer>
        ),
        dataIndex: "uom_name",
        sorter: true,
        width: "25%",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.a.action}</LangContext.Consumer>
        ),
        dataIndex: "action",
        key: "action",
        sorter: false,
        width: "8%",
        render: (text, record, index) => {
          return (
            <div>
              {checkPermissions(
                this.props.auth.profile.permissions,
                "user",
                "can_view"
              ) == true ? (
                  <Tooltip
                    placement="left"
                    title={
                      <LangContext.Consumer>
                        {(i18n) => i18n.d.detail}
                      </LangContext.Consumer>
                    }
                    arrowPointAtCenter
                    style={{
                      padding: "0px 1px 0px 0px",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  >
                    <Link
                      to={`/product/detail/${record.id}`}
                      style={{ padding: "0px 5px 0px 5px" }}
                    >
                      <Icon type="file-text" />
                    </Link>
                  </Tooltip>
                ) : (
                  ""
                )}

              {checkPermissions(
                this.props.auth.profile.permissions,
                "product",
                "can_edit"
              ) === true ? (
                  <Tooltip
                    placement="left"
                    title={
                      <LangContext.Consumer>
                        {(i18n) => i18n.e.edit}
                      </LangContext.Consumer>
                    }
                    arrowPointAtCenter
                  >
                    <Link to={`/product/edit/${record.id}`}>
                      <Icon type="edit" />
                    </Link>
                  </Tooltip>
                ) : (
                  ""
                )}

              {checkPermissions(
                this.props.auth.profile.permissions,
                "product",
                "can_delete"
              ) === true ? (
                  <LangContext.Consumer>
                    {(i18n) => (
                      <Tooltip
                        placement="left"
                        title={i18n.d.delete}
                        arrowPointAtCenter
                      >
                        <Popconfirm
                          title={i18n.c.confirmToDeleteThisItem}
                          onConfirm={() => {
                            let self = this;
                            self.deleteProduct(record.id);
                          }}
                          cancelText={i18n.c.cancel}
                          okText={i18n.o.ok}
                        >
                          <a href="#" style={{ padding: "0px 15px 0px 5px" }}>
                            <Icon type="delete" />
                          </a>
                        </Popconfirm>
                      </Tooltip>
                    )}
                  </LangContext.Consumer>
                ) : (
                  ""
                )}
            </div>
          );
        },

      },
    ];

    return (
      <div>
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <Row gutter={24} type="flex" justify="end" style={{ height: "38px" }}>
            <Col span={22}>
              {checkPermissions(
                this.props.auth.profile.permissions,
                "product",
                "can_add"
              ) === true ? (
                  <Link to="/product/create/">
                    <Button type="primary" icon="plus" size="small">
                      <span></span>
                      <LangContext.Consumer>
                        {(i18n) => i18n.n.new}
                      </LangContext.Consumer>
                    </Button>
                  </Link>
                ) : ""}
            </Col>

            <Col span={2}>
              <Button icon="filter" size="small" onClick={this.showDrawer}>
                <LangContext.Consumer>
                  {(i18n) => i18n.f.filter}
                </LangContext.Consumer>
              </Button>
            </Col>
          </Row>
          <Table
            bordered
           // size="small"
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={this.props.product.productLists}
            pagination={{
              pageSize: this.state.pageSize,
              total: this.props.product.productTotal,
              current: this.state.page,
            }}
            loading={this.props.product.productLoading}
            onChange={this.handleTableChange}
          />

          <div>
            <LangContext.Consumer>
              {(i18n) => (
                <Drawer
                  title={i18n.s.search}
                  width={450}
                  onClose={this.onCloseSearch}
                  visible={this.state.visible}
                >
                  <Filter
                    OnClose={this.onCloseSearch}
                    onSubmit={this.filterProduct}
                    reportLists={this.props.product.reportLists}
                  />
                </Drawer>
              )}
            </LangContext.Consumer>
          </div>
        </GeneralStyledContent>
      </div>
    );
  }
}

const mapStateToProps = ({ product, auth }) => ({
  product,
  auth,
});

const mapDispatchToProps = {
  loadProduct: actions.loadProduct.request,
  clearDataResponse: actions.clearDataResponse,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
