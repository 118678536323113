import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadTemporaryTracking(action) {
  const {
    data: {
      page,
      pageSize,
      accessToken,
    }
  } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LINK_ACCESS_URL}?page=${page}&per_page=${pageSize}`,
      // `https://track.terminusfleet.com/api/shared_vehicle?page=${page}&per_page=${pageSize}`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    const data = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlinkaccess`,
      {
        data: res.data.data,
        total: res.data.total
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );    

    yield put(actions.loadTemporaryTracking.success(data.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadTemporaryTracking.failure());
  }
}

function* createTemporaryTracking(action) {
  const {
    data: {start, end, selected, accessToken },
    loading
  } = action.payload;

  let formData = new FormData();

  formData.append("vehicles", selected);
  formData.append("start", start);
  formData.append("end", end);

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LINK_ACCESS_URL}`,
      // `https://track.terminusfleet.com/api/shared_vehicle`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    loading(res.data);
    yield put(actions.createTemporaryTracking.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.createTemporaryTracking.failure());
  }
}

function* upDateTemporaryTracking(action) {
  const {
    data: {start, end, selected, id, accessToken },
    loading
  } = action.payload;

  let formData = new FormData();

  formData.append("vehicles", selected);
  formData.append("start", start);
  formData.append("end", end);

  try {
    const res = yield call(
      axios.put,
      `${process.env.REACT_APP_LINK_ACCESS_URL}/${id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    loading(res.data);
    yield put(actions.upDateTemporaryTracking.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.upDateTemporaryTracking.failure());
  }
}

export default function* watchTemporaryTracking() {
  yield all([
    takeEvery(actions.LOAD_TEMPORARY_TRACKING.REQUEST, loadTemporaryTracking),
    takeEvery(actions.CREATE_TEMPORARY_TRACKING.REQUEST, createTemporaryTracking),
    takeEvery(actions.UPDATE_TEMPORARY_TRACKING.REQUEST, upDateTemporaryTracking)
  ]);
}

export {
  loadTemporaryTracking,
  createTemporaryTracking,
  upDateTemporaryTracking
};
