import {
  LOAD_LOCATION_TYPE,
  CREATE_LOCATION_TYPE,
  UPDATE_LOCATION_TYPE,
  REMOVE_LISTS,
} from "./actions";

const intialState = {
  lists: [],
  total: 1,
  loading: false,
};

export default (state = intialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return intialState;

    case LOAD_LOCATION_TYPE.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        loading: loading,
      };

    case LOAD_LOCATION_TYPE.SUCCESS:
      const { page, lists } = action.payload;
      const { data } = lists;

      for (let i = 0; i < data.length; i++) {
        data[i].rowNumber = i + 1;
        if (page > 1) data[i].rowNumber = data[i].rowNumber + (page - 1) * 10;
      }

      return {
        ...state,
        lists: data,
        total: lists.total - 1 <= 0 ? 1 : lists.total <= 0 ? 1 : lists.total,
        loading: false,
      };

    case CREATE_LOCATION_TYPE.SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_LOCATION_TYPE.SUCCESS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
