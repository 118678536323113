import { createTypes, createAction } from "lib/action";

const LOAD_RECEIPT = createTypes("receipt", "load");
const LOAD_RECEIPT_DETAIL = createTypes("receiptdetail", "load");

const loadReceipt = {
  request: (data, loading) =>
    createAction(LOAD_RECEIPT.REQUEST, { data, loading }),
  success: (lists, page) => createAction(LOAD_RECEIPT.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_RECEIPT.FAILURE)
};

const loadReceiptDetail = {
  request: (data, cb) =>
    createAction(LOAD_RECEIPT_DETAIL.REQUEST, { data, cb }),
  success: (lists, total) =>
    createAction(LOAD_RECEIPT_DETAIL.SUCCESS, { lists, total }),
  failure: () => createAction(LOAD_RECEIPT_DETAIL.FAILURE)
};

export { loadReceipt, LOAD_RECEIPT, loadReceiptDetail, LOAD_RECEIPT_DETAIL };
