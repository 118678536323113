import { createTypes, createAction } from "lib/action";

const LOAD_REFUEL = createTypes("refuel", "load");
const LOAD_VEHICLE_REFUEL = createTypes(
  "vehicle_refuel",
  "load"
);

const loadRefuel = {
  request: (data, loading) =>
    createAction(LOAD_REFUEL.REQUEST, { data, loading }),
  success: data => createAction(LOAD_REFUEL.SUCCESS, { data }),
  failure: () => createAction(LOAD_REFUEL.FAILURE)
};

const loadVehicle = {
  request: () => createAction(LOAD_VEHICLE_REFUEL.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_REFUEL.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_REFUEL.FAILURE)
};

export {
  loadRefuel,
  LOAD_REFUEL,
  loadVehicle,
  LOAD_VEHICLE_REFUEL
};
