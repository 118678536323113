import {
  MONITOR_SDCARD_TABLE_LOG,
  MONITOR_SDCARD_CHART_LOG,
  MONITOR_SDCARD_COMPANY_LIST,
  REMOVE_LISTS,
} from './actions'

import moment from 'moment'

const initialState = {
  // *ดึงข้อมูล table log
  check_table_log: false,
  table_log: [],

  // *ดึงข้อมูล chart log
  check_chart_log: false,
  chart_log: [],

  // *ดึงข้อมูล company list
  check_company_list: false,
  company_list: [],

  // *ข้อมูลเดือน
  month_data: [
    {
      title: 0,
      key: 'jan',
      translate: 'january',
      days: 31,
    },
    {
      title: 1,
      key: 'feb',
      translate: 'february',
      days: moment(moment().year(), 'YYYY').isLeapYear() ? 29 : 28,
    },
    {
      title: 2,
      key: 'mar',
      translate: 'march',
      days: 31,
    },
    {
      title: 3,
      key: 'apr',
      translate: 'april',
      days: 30,
    },
    {
      title: 4,
      key: 'may',
      translate: 'may',
      days: 31,
    },
    {
      title: 5,
      key: 'jun',
      translate: 'june',
      days: 30,
    },
    {
      title: 6,
      key: 'jul',
      translate: 'july',
      days: 31,
    },
    {
      title: 7,
      key: 'aug',
      translate: 'august',
      days: 31,
    },
    {
      title: 8,
      key: 'sep',
      translate: 'september',
      days: 30,
    },
    {
      title: 9,
      key: 'oct',
      translate: 'october',
      days: 31,
    },
    {
      title: 10,
      key: 'nov',
      translate: 'november',
      days: 30,
    },
    {
      title: 11,
      key: 'dec',
      translate: 'december',
      days: 31,
    },
  ],

  // color
  color_status: [
    {
      key: 1,
      title: '>=90%',
      color: '#9FE2BF',
    },
    {
      key: 2,
      title: '>=50%',
      color: '#FFCE56',
    },
    {
      key: 3,
      title: '<50%',
      color: '#ec8385',
    },
  ],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState

    // *ดึงข้อมูล table log
    case MONITOR_SDCARD_TABLE_LOG.REQUEST:
      return {
        ...state,
        check_table_log: true,
        table_log: [],
      }
    case MONITOR_SDCARD_TABLE_LOG.SUCCESS:
      return {
        ...state,
        table_log: action.payload.lists || [],
        check_table_log: false,
      }

    case MONITOR_SDCARD_CHART_LOG.REQUEST:
      return {
        ...state,
        check_chart_log: true,
        chart_log: [],
      }
    case MONITOR_SDCARD_CHART_LOG.SUCCESS:
      return {
        ...state,
        chart_log: action.payload.lists || [],
        check_chart_log: false,
      }
    case MONITOR_SDCARD_COMPANY_LIST.REQUEST:
      return {
        ...state,
        check_company_list: true,
        company_list: [],
      }
    case MONITOR_SDCARD_COMPANY_LIST.SUCCESS:
      return {
        ...state,
        company_list: action.payload.lists || [],
        check_company_list: false,
      }

    default:
      return state
  }
}
