import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import Forms from "./Forms";
import { Row, Col} from "antd";
import Breadcrumb from "./../../../shared/components/Breadcrumbreport";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import { GeneralStyledContent } from "../../../../styled/common-styled";


class Index extends Component {
  loadNotinotswipecard = (values, loading) => {
    this.props.loadNotinotswipecard(values, loading);
  };

  loadVehicle = (vehicletypeID, loading_vehicle) => {
    this.props.loadVehicle(vehicletypeID, loading_vehicle);
  };

  loadVehicleType = () => {
    this.props.loadVehicleType();
  };

  render() {

    const {
      vehicleLists,
      vehicleTypeLists,
      notinotswipecardLoading
    } = this.props.notinotswipecard;
    return (
      <AuthorizeComponent matching_name="report_notinotswipecard">
      <GeneralStyledContent>
       <Row gutter={24}>
          <Col span={24}>
            <Breadcrumb
              match={this.props.match}
              sub_menu="behavior"
              style={{ margin: "0px 0px 14px 10px" }}
            />
          </Col>
        </Row>
        <Forms
          onSubmit={this.loadNotinotswipecard}
          vehicleLists={vehicleLists}
          vehicleTypeLists={vehicleTypeLists}
          notinotswipecardLoading={notinotswipecardLoading}
          loadVehicle={this.loadVehicle}
          loadVehicleType={this.loadVehicleType}
        />
      </GeneralStyledContent>
     </AuthorizeComponent>
    );
  }
}

const mapStateToProps = ({ notinotswipecard, auth: { profile } }) => ({
  notinotswipecard,
  profile
});

const mapDispatchToProps = {
  loadNotinotswipecard: actions.loadNotinotswipecard.request,
  loadVehicle: actions.loadVehicle.request,
  loadVehicleType: actions.loadVehicleType.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);
