import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getlocalstorage } from "./../../../constants/local_storage";
//import { push } from "connected-react-router";

import * as actions from "./actions";
import moment from "moment";

function* loadDriverinformation(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );
  const USER_ID = getlocalstorage(localStorage.getItem("profile"), "id");

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  const {
    data: { driver_code, driver_name, type_file }
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_LOGIN}/service/report/driverinformation`,
      {
        company_id: COMPANY_ID,
        user_id: USER_ID,
        type_file,
        vehicle_visibility,
        driver_code,
        driver_name
      }
    );

    yield put(actions.loadDriverinformation.success(res.data));
    window.location = res.data.data;
  } catch (err) {
    yield put(actions.loadDriverinformation.failure());
  }
}

function* loadDriver(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );
  const VUC = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdriver`,
      {
        allow_vehicles: VUC,
        company_id: COMPANY_ID
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadDriver.success(res.data));
  } catch (err) {
    yield put(actions.loadDriver.failure());
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(actions.LOAD_DRIVERINFORMATION.REQUEST, loadDriverinformation),
    takeEvery(actions.LOAD_DRIVER_DRIVERINFORMATION.REQUEST, loadDriver)
  ]);
}

export { loadDriverinformation, loadDriver };
