import React from "react";
import difference from "lodash/difference";
import { Transfer, Table } from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";

const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => {
  return (
    <div>
      <Transfer
        {...restProps}
        showSelectAll={false}
        titles={[
          <div
            onClick={() => {
              restProps.onChange(restProps.dataSource.map((item) => item.key));
            }}
          >
            <LangContext.Consumer>
              {(i18n) => i18n.c.chooseAll}
            </LangContext.Consumer>
          </div>,
          <div
            onClick={() => {
              restProps.onChange([]);
            }}
          >
            <LangContext.Consumer>
              {(i18n) => i18n.c.chooseAll}
            </LangContext.Consumer>
          </div>,
        ]}
      >
        {({
          direction,
          filteredItems,
          onItemSelectAll,
          onItemSelect,
          selectedKeys: listSelectedKeys,
          disabled: listDisabled,
        }) => {
          const columns = direction === "left" ? leftColumns : rightColumns;

          const rowSelection = {
            getCheckboxProps: (item) => ({
              disabled: listDisabled || item.disabled,
            }),
            onSelectAll(selected, selectedRows) {
              const treeSelectedKeys = selectedRows
                .filter((item) => !item.disabled)
                .map(({ key }) => key);
              const diffKeys = selected
                ? difference(treeSelectedKeys, listSelectedKeys)
                : difference(listSelectedKeys, treeSelectedKeys);
              onItemSelectAll(diffKeys, selected);
            },
            onSelect({ key }, selected) {
              onItemSelect(key, selected);
            },
            selectedRowKeys: listSelectedKeys,
          };

          return (
            <Table
              scroll={{ y: 400 }}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={filteredItems}
              size="small"
              pagination={{ defaultPageSize: 100 }}
              style={{ pointerEvents: listDisabled ? "none" : null }}
              onRow={({ key, disabled: itemDisabled }) => ({
                onClick: () => {
                  if (itemDisabled || listDisabled) return;
                  onItemSelect(key, !listSelectedKeys.includes(key));
                },
              })}
            />
          );
        }}
      </Transfer>
    </div>
  );
};

export default TableTransfer;
