import { createTypes, createAction } from "lib/action";

const LOAD_UOM = createTypes("uom", "load");
const CREATE_UOM = createTypes("uom", "create");
const UPDATE_UOM = createTypes("uom", "delete");
const GET_UOM = createTypes("uom", "get");
const CLEAR_UOM = createTypes("uom", "clear");

const CLEAR_RESPONSE = createTypes("response", "clear");


const loadUom = {
  request: (data, loading) =>
    createAction(LOAD_UOM.REQUEST, { data, loading }),
  success: data => createAction(LOAD_UOM.SUCCESS, { data }),
  failure: () => createAction(LOAD_UOM.FAILURE)
};

const createUom = {
  request: (data, loading, history) =>
    createAction(CREATE_UOM.REQUEST, {
      data,
      loading,
      history
    }),
  success: data => createAction(CREATE_UOM.SUCCESS, { data }),
  failure: () => createAction(CREATE_UOM.FAILURE)
};

const updateUom = {
  request: (data, id, history, loading) =>
    createAction(UPDATE_UOM.REQUEST, { data, id, history, loading }),
  success: data => createAction(UPDATE_UOM.SUCCESS, { data }),
  failure: () => createAction(UPDATE_UOM.FAILURE)
};


const getUomByID = {
  request: (id,token) => createAction(GET_UOM.REQUEST, { id,token }),
  success: data => createAction(GET_UOM.SUCCESS, { data }),
  failure: () => createAction(GET_UOM.FAILURE)
};





const clearDataResponse = () => {
  return {
    type: CLEAR_RESPONSE
  };
};

const clearUom = () => {
  return {
    type: CLEAR_UOM
  };
};

export {
  LOAD_UOM,
  CREATE_UOM,
  UPDATE_UOM,
  GET_UOM,
  loadUom,
  createUom,
  updateUom,
  getUomByID,
  CLEAR_RESPONSE,
  clearDataResponse,
  clearUom,
  CLEAR_UOM
};
