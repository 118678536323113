import { createTypes, createAction } from "lib/action";

const LOAD_DISPLAY = createTypes("loadDisplay", "load");
const LOAD_VIDEO = createTypes("loadVideo", "load");

const loadDisplay = {
  request: (text) => createAction(LOAD_DISPLAY.REQUEST, { text }),
  success: (data) => createAction(LOAD_DISPLAY.SUCCESS, { data }),
  failure: () => createAction(LOAD_DISPLAY.FAILURE),
};

const loadVideo = {
  request: (text, callback) =>
    createAction(LOAD_VIDEO.REQUEST, { text, callback }),
  success: (data) => createAction(LOAD_VIDEO.SUCCESS, { data }),
  failure: () => createAction(LOAD_VIDEO.FAILURE),
};

export { loadDisplay, LOAD_DISPLAY, loadVideo, LOAD_VIDEO };
