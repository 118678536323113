import { LOAD_DRIVINGLICENSEEXPIRE } from "./actions";

const initialState = {
  drivinglicenseexpireUrl: "",
  drivinglicenseexpireLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DRIVINGLICENSEEXPIRE.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        drivinglicenseexpireLoading: loading,
      };
    case LOAD_DRIVINGLICENSEEXPIRE.SUCCESS:
      return {
        ...state,
        drivinglicenseexpireUrl: action.payload.data.data,
        drivinglicenseexpireLoading: false,
      };
    default:
      return state;
  }
};
