import React from "react";
import { Switch, Route } from "react-router-dom";
import Schedulereport from "./Index";
import Add from "./Add";
import Edit from "./Edit";
import Detail from "./Detail";
export default () => (
  <Switch>
    <Route exact path="/schedulereport" component={Schedulereport} />
    <Route path="/schedulereport/create" component={Add} />
    <Route path="/schedulereport/edit/:id" component={Edit} />
    <Route path="/schedulereport/detail/:id" component={Detail} />
  </Switch>
);
