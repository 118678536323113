const getIntialValueFormatByPolicyType = (props, jsonDetailSkeleton) => {
  let {
    conditionList: { condition, day, time },
  } = props.initValue;
  let initialValues = props.initValue;
  switch (props.initValue.policy_type) {
    case "speed_limit":
      if (props.action == "edit") {
        if (typeof condition != "undefined") {
          initialValues = {
            ...props.initValue,
            ...day,
            ...time,
            value_red: condition[0].value_red,
            value_yellow: condition[0].value_yellow,
            acc_yel: condition[0].acc_yel,
            acc_red: condition[0].acc_red,
            brake_yel: condition[0].brake_yel,
            brake_red: condition[0].brake_red,
            lat_yel: condition[0].lat_yel,
            lat_red: condition[0].lat_red,
            w_yel: condition[0].w_yel,
            w_red: condition[0].w_red,
            shock_yel: condition[0].shock_yel,
            shock_red: condition[0].shock_red,
            high_rpm_yel: condition[0].high_rpm_yel,
            high_rpm_red: condition[0].high_rpm_red,
            duration: condition[0].duration.value,
          };
        }
      } else {
        initialValues = {
          ...props.initValue,
          ...day,
          ...time,
          ...jsonDetailSkeleton.speed_limit,
        };
      }
      break;
    case "speed_limit_area":
      if (props.action == "edit") {
        if (typeof condition != "undefined") {
          initialValues = {
            ...props.initValue,
            ...day,
            ...time,
            value_red: condition[0].value_red,
            value_yellow: condition[0].value_yellow,
            duration: condition[0].duration.value,
            area: condition[0].zone.id,
          };
        }
        console.log(initialValues);
      } else {
        initialValues = {
          ...props.initValue,
          ...day,
          ...time,
          ...jsonDetailSkeleton.speed_limit_area,
        };
      }
      break;
    case "no_entry":
      if (props.action == "edit") {
        if (typeof condition != "undefined") {
          initialValues = {
            ...props.initValue,
            ...day,
            ...time,
            area: condition[0].zone.id,
            duration: condition[0].duration.value,
          };
        }
        console.log(initialValues);
      } else {
        initialValues = {
          ...props.initValue,
          ...day,
          ...time,
          ...jsonDetailSkeleton.no_entry,
        };
      }
      break;
    case "no_exit":
      if (props.action == "edit") {
        if (typeof condition != "undefined") {
          initialValues = {
            ...props.initValue,
            ...day,
            ...time,
            area: condition[0].zone.id,
            duration: condition[0].duration.value,
          };
        }
        console.log(initialValues);
      } else {
        initialValues = {
          ...props.initValue,
          ...day,
          ...time,
          ...jsonDetailSkeleton.no_exit,
        };
      }
      break;
    case "stop":
      console.log("stop");
      if (props.action == "edit") {
        if (typeof condition != "undefined") {
          initialValues = {
            ...props.initValue,
            ...day,
            ...time,
            duration: condition[0].duration.value,
          };
        }
      } else {
        initialValues = {
          ...props.initValue,
          ...day,
          ...time,
          ...jsonDetailSkeleton.stop,
        };
        console.log(initialValues);
      }
      break;
    case "stop_with_area":
      console.log("stop_with_area");
      if (props.action == "edit") {
        if (typeof condition != "undefined") {
          initialValues = {
            ...props.initValue,
            ...day,
            ...time,
            area: condition[0].location.type,
            apply_area: condition[0].location.apply_area,
            duration: condition[0].duration.value,
          };
        }
      } else {
        initialValues = {
          ...props.initValue,
          ...day,
          ...time,
          ...jsonDetailSkeleton.stop_with_area,
        };
        console.log(initialValues);
      }
      break;

    case "stop_engine_on":
      if (props.action == "edit") {
        if (typeof condition != "undefined") {
          initialValues = {
            ...props.initValue,
            ...day,
            ...time,
            duration: condition[0].duration.value,
          };
        }
      } else {
        initialValues = {
          ...props.initValue,
          ...day,
          ...time,
          ...jsonDetailSkeleton.stop_engine_on,
        };
      }
      break;
    case "stop_engine_on_with_area":
      if (props.action == "edit") {
        if (typeof condition != "undefined") {
          initialValues = {
            ...props.initValue,
            ...day,
            ...time,
            area: condition[0].location.type,
            apply_area: condition[0].location.apply_area,
            duration: condition[0].duration.value,
          };
        }
      } else {
        initialValues = {
          ...props.initValue,
          ...day,
          ...time,
          ...jsonDetailSkeleton.stop_engine_on_with_area,
        };
      }
      break;
    case "abnormal_temperature":
      if (props.action == "edit") {
        if (typeof condition != "undefined") {
          initialValues = {
            ...props.initValue,
            ...day,
            ...time,
            max: condition[0].max,
            min: condition[0].min,
          };
        }
      } else {
        initialValues = {
          ...props.initValue,
          ...day,
          ...time,
          ...jsonDetailSkeleton.abnormal_temperature,
        };
      }
      break;
    default:
      break;
  }
  return initialValues;
};

export { getIntialValueFormatByPolicyType };
