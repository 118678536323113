import {
  Form,
  Input,
  Drawer,
  Button,
  AutoComplete,
} from "antd";
import LangContext from "modules/shared/context/langContext";

import React from "react";

const Filter = (props) => (
  <Drawer
    title={
      <LangContext.Consumer>
        {(i18n) => i18n.f.filterSearch}
      </LangContext.Consumer>
    }
    width={520}
    placement="right"
    onClose={props.onClose}
    maskClosable={true}
    visible={props.filterVisible}
    style={{
      height: "calc(100% - 55px)",
      overflow: "auto",
      paddingBottom: 53,
    }}
  >
    <Form>
      <Form.Item
        label={
          <LangContext.Consumer>
            {(i18n) => i18n.manageOTP.companyName}
          </LangContext.Consumer>
        }
      >
        <AutoComplete
          value={props.filterCompanyID}
          placeholder={
            <LangContext.Consumer>
              {(i18n) => i18n.manageOTP.searchCompany}
            </LangContext.Consumer>
          }
          optionFilterProp="children"
          optionLabelProp="children"
          allowClear
          onSearch={(v) => {
            props.handleStateChange("searchCompany", v);
          }}
          onSelect={(v) => {
            props.handleStateChange("selectCompany", v);
          }}
        >
          {props.companyLists.map((v) => {
            return (
              <AutoComplete.Option
                key={v.companies_id.toString()}
                value={v.companies_id.toString()}
                data={v}
              >
                {v.companies_name}
              </AutoComplete.Option>
            );
          })}
        </AutoComplete>
      </Form.Item>
      <Form.Item
        label={
          <LangContext.Consumer>
            {(i18n) => i18n.manageOTP.phoneNumber}
          </LangContext.Consumer>
        }
      >
        <Input
          value={props.filterPhoneNo}
          onChange={(v) => {
            props.handleStateChange("phoneNo", v);
          }}
        />
      </Form.Item>
      <Form.Item
        label={
          <LangContext.Consumer>
            {(i18n) => i18n.p.plateNo}
          </LangContext.Consumer>
        }
      >
        <Input
          value={props.filterPlateNo}
          onChange={(v) => {
            props.handleStateChange("plateNo", v);
          }}
        />
      </Form.Item>

      <Button
        style={{
          marginRight: 8,
        }}
        onClick={props.onClose}
      >
        <LangContext.Consumer>
          {(i18n) => i18n.c.cancel}
        </LangContext.Consumer>
      </Button>
      <Button
        type="primary"
        onClick={props.onFilterButtonClick}
      >
        <LangContext.Consumer>
          {(i18n) => i18n.a.apply}
        </LangContext.Consumer>
      </Button>
      {props.textFrom}
    </Form>
  </Drawer>
);
export default Filter;
