import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { Formik } from "formik";
import * as yup from "yup";
import * as moment from "moment";
import { debounce } from "debounce";
import {
  Row,
  Col,
  Avatar,
  Icon,
  Button,
  Divider,
  DatePicker,
  Checkbox,
  Form,
  Input,
  Select,
  Spin,
  Switch,
  Tooltip,
  Skeleton,
  Slider,
  message,
  TimePicker,
} from "antd";
import { Link, BrowserRouter as Router, withRouter } from "react-router-dom";

import LangContext, { i18n } from "modules/shared/context/langContext";
import { autoGenKey } from "../../../../../../lib/helper";
import FormDivider from "../../../../../shared/components/FormDivider";
import { getIntialValueFormatByPolicyType } from "../../../function/adapter";
import { jsonDetailSkeleton } from "../../../constants/template-policy";
import FooterVehicleForm from "./FooterVehicleForm";
import FooterNotiConfig from "./FooterNotiConfig";
// condition component
import SpeedLimit from "./SpeedLimit";
import SpeedLimitArea from "./SpeedLimitArea";
import NoEntry from "./NoEntry";
import Stop from "./Stop";
import AbnormalTemperature from "./AbnormalTemperature";

const InputGroup = Input.Group;
const { Item } = Form;
const FormItem = Item;
const { Option, OptGroup } = Select;

class Main extends Component {
  state = {
    conditionList: [],
    zoneList: [],
    initialValues: this.props.initValue,
  };

  componentDidMount() {
    this.setState({
      initialValues: getIntialValueFormatByPolicyType(
        this.props,
        jsonDetailSkeleton
      ),
    });
    let self = this;
    fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/dropdownlist/getzone`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.auth.accessToken}`,
        },
        body: JSON.stringify({
          company_id: this.props.auth.profile.company_id,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        self.setState({ zoneList: data });
      });
  }

  handleInitialValuesChange = (key) => this.setState({});

  PDefaultToJson = (json) => {
    if (json) {
      const p_default = JSON.parse(json).P_DEFAULT;
      const alarm = {
        acc_yel: p_default && p_default.ALARM_ACC && p_default.ALARM_ACC.YELLOW,
        acc_red: p_default && p_default.ALARM_ACC && p_default.ALARM_ACC.RED,
        brake_yel:
          p_default && p_default.ALARM_BRAKE && p_default.ALARM_BRAKE.YELLOW,
        brake_red:
          p_default && p_default.ALARM_BRAKE && p_default.ALARM_BRAKE.RED,
        lat_yel: p_default && p_default.ALARM_LAT && p_default.ALARM_LAT.YELLOW,
        lat_red: p_default && p_default.ALARM_LAT && p_default.ALARM_LAT.RED,
        w_yel: p_default && p_default.ALARM_W && p_default.ALARM_W.YELLOW,
        w_red: p_default && p_default.ALARM_W && p_default.ALARM_W.RED,
        shock_yel:
          p_default && p_default.ALARM_SHOCK && p_default.ALARM_SHOCK.YELLOW,
        shock_red:
          p_default && p_default.ALARM_SHOCK && p_default.ALARM_SHOCK.RED,
        high_rpm_yel:
          p_default && p_default.HIGH_RPM && p_default.HIGH_RPM.YELLOW,
        high_rpm_red: p_default && p_default.HIGH_RPM && p_default.HIGH_RPM.RED,
      };
      return alarm;
    }
  };

  PGeofenceToJson = (json) => {
    if (json) {
      const p_geofence = JSON.parse(json).P_GEOFENCE;
      const alarm = {
        acc_yel:
          p_geofence && p_geofence.ALARM_ACC && p_geofence.ALARM_ACC.YELLOW,
        acc_red: p_geofence && p_geofence.ALARM_ACC && p_geofence.ALARM_ACC.RED,
        brake_yel:
          p_geofence && p_geofence.ALARM_BRAKE && p_geofence.ALARM_BRAKE.YELLOW,
        brake_red:
          p_geofence && p_geofence.ALARM_BRAKE && p_geofence.ALARM_BRAKE.RED,
        lat_yel:
          p_geofence && p_geofence.ALARM_LAT && p_geofence.ALARM_LAT.YELLOW,
        lat_red: p_geofence && p_geofence.ALARM_LAT && p_geofence.ALARM_LAT.RED,
        w_yel: p_geofence && p_geofence.ALARM_W && p_geofence.ALARM_W.YELLOW,
        w_red: p_geofence && p_geofence.ALARM_W && p_geofence.ALARM_W.RED,
        shock_yel:
          p_geofence && p_geofence.ALARM_SHOCK && p_geofence.ALARM_SHOCK.YELLOW,
        shock_red:
          p_geofence && p_geofence.ALARM_SHOCK && p_geofence.ALARM_SHOCK.RED,
        high_rpm_yel:
          p_geofence && p_geofence.HIGH_RPM && p_geofence.HIGH_RPM.YELLOW,
        high_rpm_red:
          p_geofence && p_geofence.HIGH_RPM && p_geofence.HIGH_RPM.RED,
      };
      return alarm;
    }
  };

  render() {
    let initialValues = [];
    let companyId = this.props.auth.profile.company_id;
    let checkCompanyCondition =
      this.props.action == "create" && companyId == 395;
    if (this.props.action == "create") {
      initialValues = this.state.initialValues;
    } else {
      initialValues = getIntialValueFormatByPolicyType(
        this.props,
        jsonDetailSkeleton
      );
      if (initialValues.policy_type === "speed_limit") {
        const alarm = this.PDefaultToJson(initialValues.json);
        initialValues = { ...initialValues, ...alarm };
      }

      if (initialValues.policy_type === "speed_limit_area") {
        const alarm = this.PGeofenceToJson(initialValues.json);
        initialValues = { ...initialValues, ...alarm };
      }
    }

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={yup.lazy((value) => {
          switch (value.policy_type) {
            case "speed_limit_area":
              return yup.object().shape({
                policy_name: yup.string().required(),
                area: yup
                  .string()
                  .required()
                  .nullable(),
              });
              break;
            case "no_entry":
              return yup.object().shape({
                policy_name: yup.string().required(),
                area: yup
                  .string()
                  .required()
                  .nullable(),
              });
              break;
            case "no_exit":
              return yup.object().shape({
                policy_name: yup.string().required(),
                area: yup
                  .string()
                  .required()
                  .nullable(),
              });
              break;
            case "stop":
              return yup.object().shape({
                policy_name: yup.string().required(),
              });
              break;
            case "stop_with_area":
              return yup.object().shape({
                policy_name: yup.string().required(),
                area: yup
                  .array()
                  .required()
                  .nullable(),
              });
              break;
            case "abnormal_temperature":
              return yup.object().shape({
                policy_name: yup.string().required(),
              });
              break;
            default:
              return yup.object().shape({
                policy_name: yup.string().required(),
              });
              break;
          }
        })}
        onSubmit={(values, actions) => {
          let { props } = this;

          if (this.props.action == "create") {
            this.props.createPolicy(values, function(e) {
              if (e.status === "success") {
                message.success(props.action + " success");
                return props.history.push("/policy");
              } else {
                message.success(e.msg);
              }
            });
          }
          if (this.props.action == "edit") {
            this.props.updatePolicy(values, function() {
              message.success(props.action + " success");
              return props.history.push("/policy");
            });
          }
        }}
        render={({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <LangContext.Consumer>
            {(i18n) => {
              if (
                this.props.action == "create" ||
                (this.props.action == "edit" &&
                  values.policy_id != null &&
                  typeof this.props.initValue != "undefined")
              )
                return (
                  <Form onSubmit={handleSubmit}>
                    <FormDivider>
                      <LangContext.Consumer>
                        {(i18n) => i18n.p.policy}
                      </LangContext.Consumer>
                    </FormDivider>

                    <Row>
                      <Col xs={24} sm={18} md={13} lg={13} xl={10}>
                        <Form.Item label="ประเภท">
                          <Select
                            key={autoGenKey("condition-select")}
                            name="policy_type"
                            defaultValue={values.policy_type}
                            onChange={(e) => {
                              setFieldValue("policy_type", e);

                              this.setState({
                                initialValues: getIntialValueFormatByPolicyType(
                                  {
                                    ...this.props,
                                    initValue: {
                                      ...this.props.initValue,
                                      policy_type: e,
                                    },
                                    policy_type: e,
                                  },
                                  jsonDetailSkeleton
                                ),
                              });
                            }}
                            disabled={this.props.action == "edit"}
                          >
                            <OptGroup label="ความเร็ว">
                              <Option
                                key="speed_limit"
                                disabled={checkCompanyCondition}
                              >
                                <LangContext.Consumer>
                                  {(i18n) => i18n.variable.speed_limit}
                                </LangContext.Consumer>
                              </Option>
                              <Option
                                key="speed_limit_area"
                                disabled={checkCompanyCondition}
                              >
                                <LangContext.Consumer>
                                  {(i18n) => i18n.variable.speed_limit_area}
                                </LangContext.Consumer>
                              </Option>
                            </OptGroup>
                            <OptGroup label="พื้นที่">
                              <Option
                                key="no_entry"
                                disabled={checkCompanyCondition}
                              >
                                <LangContext.Consumer>
                                  {(i18n) => i18n.variable.no_entry}
                                </LangContext.Consumer>
                              </Option>
                              <Option
                                key="no_exit"
                                disabled={checkCompanyCondition}
                              >
                                <LangContext.Consumer>
                                  {(i18n) => i18n.variable.no_exit}
                                </LangContext.Consumer>
                              </Option>
                            </OptGroup>

                            <OptGroup label="เหตุการณ์">
                              <Option
                                key="stop"
                                disabled={checkCompanyCondition}
                              >
                                <LangContext.Consumer>
                                  {(i18n) => i18n.variable.stop}
                                </LangContext.Consumer>
                              </Option>
                              <Option
                                key="stop_with_area"
                                disabled={checkCompanyCondition}
                              >
                                <LangContext.Consumer>
                                  {(i18n) => i18n.variable.stop_with_area}
                                </LangContext.Consumer>
                              </Option>
                              <Option
                                key="stop_engine_on"
                                disabled={checkCompanyCondition}
                              >
                                <LangContext.Consumer>
                                  {(i18n) => i18n.variable.stop_engine_on}
                                </LangContext.Consumer>
                              </Option>
                              <Option
                                key="stop_engine_on_with_area"
                                disabled={checkCompanyCondition}
                              >
                                <LangContext.Consumer>
                                  {(i18n) =>
                                    i18n.variable.stop_engine_on_with_area
                                  }
                                </LangContext.Consumer>
                              </Option>
                              <Option key="abnormal_temperature">
                                <LangContext.Consumer>
                                  {(i18n) => i18n.variable.abnormal_temperature}
                                </LangContext.Consumer>
                              </Option>
                            </OptGroup>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={24} sm={18} md={13} lg={13} xl={10}>
                        <FormItem
                          label={
                            <LangContext.Consumer>
                              {(i18n) => i18n.n.name}
                            </LangContext.Consumer>
                          }
                          required={true}
                          validateStatus={
                            touched.policy_name && errors.policy_name
                              ? "warning"
                              : undefined
                          }
                          help={
                            touched.policy_name && errors.policy_name
                              ? errors.policy_name.substr(
                                  errors.policy_name.indexOf(" ") + 1
                                )
                              : undefined
                          }
                        >
                          <InputGroup compact>
                            <Input
                              style={{ width: "100%" }}
                              name="policy_name"
                              value={values.policy_name}
                              onChange={(e) => {
                                setFieldValue("policy_name", e.target.value);
                              }}
                              onBlur={handleBlur}
                            />
                          </InputGroup>
                        </FormItem>
                      </Col>
                    </Row>

                    <FormDivider>
                      <LangContext.Consumer>
                        {(i18n) => i18n.c.criteria}
                      </LangContext.Consumer>
                    </FormDivider>

                    <Row>
                      {values.policy_type == "speed_limit" && (
                        <SpeedLimit
                          values={values}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          touched={touched}
                          errors={errors}
                        />
                      )}
                      {values.policy_type == "speed_limit_area" && (
                        <SpeedLimitArea
                          values={values}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          touched={touched}
                          errors={errors}
                        />
                      )}
                      {values.policy_type == "no_entry" && (
                        <NoEntry
                          values={values}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          touched={touched}
                          errors={errors}
                        />
                      )}
                      {values.policy_type == "no_exit" && (
                        <NoEntry
                          values={values}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          touched={touched}
                          errors={errors}
                        />
                      )}
                      {values.policy_type == "stop" && (
                        <Stop
                          // policyType={values.policy_type}
                          withArea={false}
                          values={values}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          touched={touched}
                          errors={errors}
                        />
                      )}
                      {values.policy_type == "stop_with_area" && (
                        <Stop
                          // policyType={values.policy_type}
                          withArea={true}
                          values={values}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          touched={touched}
                          errors={errors}
                        />
                      )}
                      {values.policy_type == "stop_engine_on" && (
                        <Stop
                          // policyType={values.policy_type}
                          withArea={false}
                          values={values}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          touched={touched}
                          errors={errors}
                        />
                      )}
                      {values.policy_type == "stop_engine_on_with_area" && (
                        <Stop
                          // policyType={values.policy_type}
                          withArea={true}
                          values={values}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          touched={touched}
                          errors={errors}
                        />
                      )}
                      {values.policy_type == "abnormal_temperature" && (
                        <AbnormalTemperature
                          // policyType={values.policy_type}
                          withArea={true}
                          values={values}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          touched={touched}
                          errors={errors}
                        />
                      )}
                    </Row>

                    <FormDivider
                      style={{
                        display:
                          values.policy_type == "abnormal_temperature"
                            ? "none"
                            : "inline",
                      }}
                    >
                      <LangContext.Consumer>
                        {(i18n) => "วัน/เวลา ที่ใช้งานนโยบาย"}
                      </LangContext.Consumer>
                    </FormDivider>

                    <Row
                      style={{
                        margin: "0px 0px 0px 0px",
                        display:
                          values.policy_type == "abnormal_temperature"
                            ? "none"
                            : "inline",
                      }}
                    >
                      <Col span={24}>
                        <Form.Item>
                          <Checkbox
                            name="apply_monday"
                            defaultChecked={values.apply_monday}
                            // checked={values.plant}
                            onChange={(e) => {
                              setFieldValue("apply_monday", e.target.checked);
                              // setFieldValue("plant", e.target.checked);
                              // this.props.setInitValue({
                              //   ...this.props.initialValues,
                              //   plant: e.target.checked
                              // });
                            }}
                          >
                            จันทร์
                          </Checkbox>

                          <Checkbox
                            name="apply_tuesday"
                            defaultChecked={values.apply_tuesday}
                            // checked={values.site}
                            onChange={(e) => {
                              setFieldValue("apply_tuesday", e.target.checked);
                            }}
                          >
                            อังคาร
                          </Checkbox>

                          <Checkbox
                            name="apply_wendnesday"
                            defaultChecked={values.apply_wendnesday}
                            // checked={values.parkingLot}
                            onChange={(e) => {
                              setFieldValue(
                                "apply_wendnesday",
                                e.target.checked
                              );
                            }}
                          >
                            พุธ
                          </Checkbox>

                          <Checkbox
                            name="apply_thursday"
                            defaultChecked={values.apply_thursday}
                            // checked={values.restArea}
                            onChange={(e) => {
                              setFieldValue("apply_thursday", e.target.checked);
                            }}
                          >
                            พฤหัสบดี
                          </Checkbox>

                          <Checkbox
                            name="apply_friday"
                            defaultChecked={values.apply_friday}
                            // checked={values.gasStation}
                            onChange={(e) => {
                              setFieldValue("apply_friday", e.target.checked);
                            }}
                          >
                            ศุกร์
                          </Checkbox>

                          <Checkbox
                            name="apply_saturday"
                            defaultChecked={values.apply_saturday}
                            // checked={values.carService}
                            onChange={(e) => {
                              setFieldValue("apply_saturday", e.target.checked);
                            }}
                          >
                            เสาร์
                          </Checkbox>

                          <Checkbox
                            name="apply_sunday"
                            defaultChecked={values.apply_sunday}
                            // checked={values.carService}
                            onChange={(e) => {
                              setFieldValue("apply_sunday", e.target.checked);
                            }}
                          >
                            อาทิตย์
                          </Checkbox>
                        </Form.Item>
                      </Col>

                      <Col span={6}>
                        <Form.Item
                          label={
                            <LangContext.Consumer>
                              {(i18n) => "เวลาเริ่ม"}
                            </LangContext.Consumer>
                          }
                        >
                          <TimePicker
                            defaultValue={moment(
                              values.time_start_at,
                              "HH:mm:ss"
                            )}
                            defaultOpenValue={moment(
                              values.time_start_at,
                              "HH:mm:ss"
                            )}
                            onChange={(e, timeStr) =>
                              setFieldValue("time_start_at", timeStr)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item
                          label={
                            <LangContext.Consumer>
                              {(i18n) => "เวลาสิ้นสุด"}
                            </LangContext.Consumer>
                          }
                        >
                          <TimePicker
                            defaultValue={moment(
                              values.time_end_at,
                              "HH:mm:ss"
                            )}
                            defaultOpenValue={moment(
                              values.time_end_at,
                              "HH:mm:ss"
                            )}
                            onChange={(e, timeStr) =>
                              setFieldValue("time_end_at", timeStr)
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row style={{ margin: "0px 0px 0px 0px" }}>
                      <Col span={24}>
                        <FooterVehicleForm
                          action={this.props.action}
                          values={values}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          touched={touched}
                          errors={errors}
                        />
                      </Col>
                    </Row>

                    <Row style={{ margin: "0px 0px 0px 0px" }}>
                      <Col span={24}>
                        <FooterNotiConfig
                          action={this.props.action}
                          values={values}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          touched={touched}
                          errors={errors}
                        />
                      </Col>
                    </Row>

                    <div style={{ margin: "40px 0px 0px 0px" }}>
                      <Button
                        htmlType="submit"
                        type="primary"
                        // onClick={() => this.props.next(this.props.current)}
                      >
                        <LangContext.Consumer>
                          {(i18n) => i18n.s.save}
                        </LangContext.Consumer>
                      </Button>
                    </div>
                  </Form>
                );
              else return <Skeleton active paragraph={{ rows: 14 }} />;
            }}
          </LangContext.Consumer>
        )}
      />
    );
  }
}
export default connect(
  ({ policyState, auth }) => ({ policyState, auth }),
  {}
)(withRouter(Main));
