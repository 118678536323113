import { createTypes, createAction } from "lib/action";
const LOAD_ASSIGNFIRMWARE = createTypes("loadassignfirmware", "load");
const ASSIGN_FIRMWARE = createTypes("assignfirmwae", "create");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
    return {
        type: REMOVE_LISTS
    };
}

const loadAssignFirmware = {
    request: (data, loading) =>
        createAction(LOAD_ASSIGNFIRMWARE.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(LOAD_ASSIGNFIRMWARE.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_ASSIGNFIRMWARE.FAITLURE)  
};

const assignFirmware = {
    request: (data, assignloading) =>
        createAction(ASSIGN_FIRMWARE.REQUEST, { data, assignloading}),
    success: res => createAction(ASSIGN_FIRMWARE.SUCCESS, { res }),
        failure: () => createAction(ASSIGN_FIRMWARE.FAITLURE)
};

export {
    LOAD_ASSIGNFIRMWARE, loadAssignFirmware,
    ASSIGN_FIRMWARE, assignFirmware,
    REMOVE_LISTS, removeLists
};