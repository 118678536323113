import React from "react";
import { Switch, Route } from "react-router-dom";
import Uom from "./Index";
import Add from "./Add";
import Edit from "./Edit";
import Detail from "./Detail";
export default () => (
  <Switch>
    <Route exact path="/uom" component={Uom} />
    <Route path="/uom/create" component={Add} />
    <Route path="/uom/edit/:id" component={Edit} />
    <Route path="/uom/detail/:id" component={Detail} />
  </Switch>
);
