import { createTypes, createAction } from "lib/action";

const LOAD_DELIVERYITEM = createTypes("deliveryitemmasterdata", "load");
const LOAD_DELIVERYITEM_DETAIL = createTypes(
  "deliveryitemdetailmasterdata",
  "load"
);
const CREATE_DELIVERYITEM = createTypes("deliveryitemmasterdata", "create");
const UPDATE_DELIVERYITEM = createTypes("deliveryitemmasterdata", "update");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
  return {
    type: REMOVE_LISTS
  };
}
const loadDeliveryItem = {
  request: (data, loading) =>
    createAction(LOAD_DELIVERYITEM.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_DELIVERYITEM.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_DELIVERYITEM.FAILURE)
};

const loadDeliveryItemDetail = {
  request: (data, cb) =>
    createAction(LOAD_DELIVERYITEM_DETAIL.REQUEST, { data, cb }),
  success: deliveryitem =>
    createAction(LOAD_DELIVERYITEM_DETAIL.SUCCESS, { deliveryitem }),
  failure: () => createAction(LOAD_DELIVERYITEM_DETAIL.FAILURE)
};

const createDeliveryItem = {
  request: (data, cb) =>
    createAction(CREATE_DELIVERYITEM.REQUEST, {
      data,
      cb
    }),
  success: res => createAction(CREATE_DELIVERYITEM.SUCCESS, { res }),
  failure: () => createAction(CREATE_DELIVERYITEM.FAILURE)
};

const updateDeliveryItem = {
  request: (data, cb) =>
    createAction(UPDATE_DELIVERYITEM.REQUEST, {
      data,
      cb
    }),
  success: res => createAction(UPDATE_DELIVERYITEM.SUCCESS, { res }),
  failure: () => createAction(UPDATE_DELIVERYITEM.FAILURE)
};

export {
  CREATE_DELIVERYITEM,
  createDeliveryItem,
  loadDeliveryItem,
  LOAD_DELIVERYITEM,
  REMOVE_LISTS,
  removeLists,
  LOAD_DELIVERYITEM_DETAIL,
  loadDeliveryItemDetail,
  updateDeliveryItem,
  UPDATE_DELIVERYITEM
};
