import React, { useState } from "react";
import { Input, Row, Col, Modal } from "antd";

const { TextArea } = Input;

const ModalBreak = ({
  closeModalBreak,
  visible,
  selectDriver,
  postBreakTruck,
}) => {
  console.log("selectDriver", selectDriver);
  const [remark, setRemark] = useState("");
  const onChangeRemark = (e) => {
    setRemark(e.target.value);
  };
  return (
    <Modal
      title={selectDriver.code}
      visible={visible}
      onOk={() => {
        postBreakTruck(remark, selectDriver.code);
        setRemark("");
      }}
      onCancel={() => {
        closeModalBreak();
        setRemark("");
      }}
    >
      <Row>
        <Col span={24} align="center">
          <TextArea
            placeholder="remark"
            rows={4}
            onChange={onChangeRemark}
            value={remark}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalBreak;
