import { createTypes, createAction } from 'lib/action'

const SUMMARY_DRIVING_LOG_EXPORT = createTypes(
  'summarydrivinglogexport',
  'load'
)

const SUMMARY_DRIVING_LOG_LOAD_VEHICLE = createTypes(
  'summarydrivinglogloadvehicle',
  'load'
)

const SUMMARY_DRIVING_LOG_LOAD_VEHICLE_TYPE = createTypes(
  'summarydrivinglogloadvehicletype',
  'load'
)

const summaryDrivingLogExport = {
  request: (data, loading) =>
    createAction(SUMMARY_DRIVING_LOG_EXPORT.REQUEST, { data, loading }),
  success: (data) => createAction(SUMMARY_DRIVING_LOG_EXPORT.SUCCESS, { data }),
  failure: () => createAction(SUMMARY_DRIVING_LOG_EXPORT.FAILURE),
}

const summaryDrivingLogLoadVehicle = {
  request: (vehicle_type_id, loading) =>
    createAction(SUMMARY_DRIVING_LOG_LOAD_VEHICLE.REQUEST, {
      vehicle_type_id,
      loading,
    }),
  success: (data) =>
    createAction(SUMMARY_DRIVING_LOG_LOAD_VEHICLE.SUCCESS, { data }),
  failure: () => createAction(SUMMARY_DRIVING_LOG_LOAD_VEHICLE.FAILURE),
}

const summaryDrivingLogLoadVehicleType = {
  request: (data, loading) =>
    createAction(SUMMARY_DRIVING_LOG_LOAD_VEHICLE_TYPE.REQUEST, {
      data,
      loading,
    }),
  success: (data) =>
    createAction(SUMMARY_DRIVING_LOG_LOAD_VEHICLE_TYPE.SUCCESS, { data }),
  failure: () => createAction(SUMMARY_DRIVING_LOG_LOAD_VEHICLE_TYPE.FAILURE),
}

export {
  summaryDrivingLogExport,
  SUMMARY_DRIVING_LOG_EXPORT,
  summaryDrivingLogLoadVehicle,
  SUMMARY_DRIVING_LOG_LOAD_VEHICLE,
  summaryDrivingLogLoadVehicleType,
  SUMMARY_DRIVING_LOG_LOAD_VEHICLE_TYPE,
}
