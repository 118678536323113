import React from "react";
import SpeedLimit from "../Template/SpeedLimit";
import Event from "../Template/Event";
import SpeedLimitOfTheEvent from "../Template/SpeedLimitOfTheEvent";

const componentMap = {
  SZ: SpeedLimit,
  EA: Event,
  SE: SpeedLimitOfTheEvent,
};

const SelectedForm = ({
  setFieldValue,
  values,
  touched,
  errors,
  subPolicyType,
  current,
  steps,
  prev,
  companyId,
  type,
  policyType,
  setIsDataType,
}) => {
  const Component = componentMap[policyType] || (() => null);

  return (
    <Component
      setFieldValue={setFieldValue}
      values={values}
      touched={touched}
      errors={errors}
      subPolicyType={subPolicyType}
      current={current}
      steps={steps}
      prev={prev}
      companyId={companyId}
      type={type}
      setIsDataType={setIsDataType}
    />
  );
};

export default SelectedForm;
