import {
  LOAD_FUELFULL,
} from "./actions";

const initialState = {
  fuelfillUrl: "",
  fuelfillLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_FUELFULL.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        fuelfillLoading: loading
      };
    case LOAD_FUELFULL.SUCCESS:
      return {
        ...state,
        fuelfillUrl: action.payload.data.data,
        fuelfillLoading: false
      };
    default:
      return state;
  }
};
