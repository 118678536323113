import {
  LOAD_SUMMARYKPI,
  LOAD_VEHICLE_SUMMARYKPI,
  LOAD_VEHICLE_TYPE_SUMMARYKPI,
  LOAD_SUMMARYKPI_PREVIEW,
} from "./actions";

const initialState = {
  summarykpiUrl: "",
  vehicleLists: [],
  vehicleTypeLists: [],
  summarykpiLoading: false,
  summarykpipreviewLists: [],
  summarykpipreviewLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SUMMARYKPI.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        summarykpiLoading: loading,
      };
    case LOAD_SUMMARYKPI.SUCCESS:
      return {
        ...state,
        summarykpiUrl: action.payload.data.data,
        summarykpiLoading: false,
      };
    case LOAD_SUMMARYKPI_PREVIEW.REQUEST:
      const { preview_loading } = action.payload;
      return {
        ...state,
        summarykpipreviewLoading: preview_loading,
      };
    case LOAD_SUMMARYKPI_PREVIEW.SUCCESS:
      const result = action.payload.data.rows.map((value, i) => {
        let plate_no = value.cell[1];
        let firstname = value.cell[2];

        if (value.cell[2] == "") {
          if (typeof value.variable.plate_no_text != "undefined") {
            plate_no = value.variable.plate_no_text;
          } else {
            plate_no = "ไม่พบข้อมูล";
          }
          firstname = "ไม่พบข้อมูลคนขับพาหนะ";
        }

        return {
          trip_code: value.cell[0],
          plate_no: plate_no,
          firstname: firstname,
          amount_cardreader: value.cell[3],
          distance: value.cell[4],
          score_safety: value.cell[5],
          score_save: value.cell[6],
          score_trip: value.cell[7],
          a: value.cell[8],
          b: value.cell[9],
          c: value.cell[10],
          d: value.cell[11],
          e: value.cell[12],
          f: value.cell[13],
          g: value.cell[14],
          delay_amount: value.cell[15],
          acc_trip_delay_minute: value.cell[16],
          h: value.cell[17],
          i: value.cell[18],
          j: value.cell[19],
          k: value.cell[20],
          l: value.cell[21],
          m: value.cell[22],
          n: value.cell[23],
          o: value.cell[24],
          variable: value.variable,
        };
      });

      return {
        ...state,
        summarykpipreviewLists: result,
        summarykpipreviewLoading: false,
      };
    case LOAD_VEHICLE_SUMMARYKPI.REQUEST:
      const { loading_vehicle } = action.payload;
      return {
        ...state,
        summarykpiLoading: loading_vehicle,
      };
    case LOAD_VEHICLE_SUMMARYKPI.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        summarykpiLoading: false,
      };
    case LOAD_VEHICLE_TYPE_SUMMARYKPI.SUCCESS:
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data,
      };
    default:
      return state;
  }
};
