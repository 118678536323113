import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import { checkPermissions } from "../../../../lib/helper";
import { Row, Col, Select, Empty, Tag, Spin } from "antd";

import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { loadDataCPFDashboard, removeLists } from "../actions";
import VehicleList from "./VehicleList";
import "../assets/style.css";

const { Option } = Select;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter_type: "1",
      select_type: [
        {
          type: "ทั้งหมด",
          value: "1",
        },
        {
          type: "CPF",
          value: "2",
        },
        {
          type: "อื่นๆ",
          value: "3",
        },
      ],
      data_dashboard: [],
      count_status: [0, 0, 0, 0, 0, 0],
      color_status: [
        {
          color: "#FFFFFF",
          text: "รถที่ไม่ได้วิ่งงาน",
          border: "1px solid #CCCCCC",
          font_color: "#000",
        },
        {
          color: "#E6B05F",
          text: "รถที่วิ่งงานแล้ว แต่ตอนนี้ไม่ได้ทำงาน",
          border: "none",
          font_color: "#fff",
        },
        {
          color: "#74B666",
          text: "รถที่วิ่งงานอยู่",
          border: "none",
          font_color: "#fff",
        },
        {
          color: "#4677B2",
          text: "รถที่เข้าอู่",
          border: "none",
          font_color: "#fff",
        },
        {
          color: "#ADADAD",
          text: "รถที่ขาดการติดต่อ",
          border: "none",
          font_color: "#fff",
        },
        {
          color: "#C95C54",
          text: "รถที่อุปกรณ์ติดตามชำรุด",
          border: "none",
          font_color: "#fff",
        },
        {
          color: "#dee2e6",
          text: "รถวิ่งช่วยงานต่างสังกัด",
          border: "1px dashed #000000",
          font_color: "#000",
        },
      ],
      loading_data: true,
    };
  }

  // *ทำงานเมื่อโหลดหน้านี้เสร็จ
  componentDidMount() {
    //  *load data
    this.loadDataCPFDashboard();

    // *ทำงาน refresh ทุกๆ 10 วินาที
    this.interval = setInterval(() => {
      this.loadDataCPFDashboard();
    }, 10000);
  }

  // *ทำงานเมื่อออกจากหน้านี้
  componentWillUnmount() {
    this.onRemoveLists();
  }

  // *remove lists
  onRemoveLists = () => {
    clearInterval(this.interval);
    this.props.removeLists();
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.cpfDashboard.loading != nextProps.cpfDashboard.loading) {
      let data_dashboard = nextProps.cpfDashboard.data_dashboard;
      let count_status = nextProps.cpfDashboard.count_status;
      if (
        data_dashboard != undefined &&
        count_status != undefined &&
        data_dashboard.length > 0 &&
        count_status.length > 0
      ) {
        this.setState({ data_dashboard, count_status, loading_data: false });
      }
    }
    return true;
  }
  //   *load data cpf dashboard
  loadDataCPFDashboard = () => {
    const { filter_type } = this.state;
    const { company_id, accessToken } = this.props.auth.profile;

    //  *load data
    this.props.loadDataCPFDashboard({
      company_id,
      filter_type,
      accessToken,
    });
  };

  // * handle change filter type
  handleChangeFilterType = (value) => {
    this.setState(
      {
        filter_type: value,
        count_status: [0, 0, 0, 0, 0, 0],
        data_dashboard: [],
        loading_data: true,
      },
      () => {
        // *load data
        this.loadDataCPFDashboard();
      }
    );
  };

  render() {
    /*  *status
            0 = รถที่ไม่ได้วิ่งงาน
            1 = รถที่วิ่งงานแล้ว แต่ตอนนี้ไม่ได้ทำงาน
            2 = รถที่วิ่งงานอยู่
            3 = รถที่เข้าอู่
            4 = รถที่ขาดการติดต่อ
            5 = รถที่อุปกรณ์ติดตามชำรุด
        */
    const {
      select_type,
      data_dashboard,
      color_status,
      count_status,
      loading_data,
    } = this.state;

    return (
      <AuthorizeComponent matching_name="cpfdashboard">
        <GeneralStyledContent>
          <Spin spinning={loading_data}>
            <LangContext.Consumer>
              {(i18n) => (
                <div className="cpf-dashboard" style={{ height: "100%" }}>
                  <Row gutter={24}>
                    <Col span={24}>
                      <Breadcrumb
                        match={this.props.match}
                        style={{ margin: "0px 0px 14px 10px" }}
                      />
                    </Col>
                  </Row>
                  <Row className="row-select-style">
                    <Col span={3}>
                      <Select
                        defaultValue="1"
                        style={{ width: "100%" }}
                        onChange={this.handleChangeFilterType}
                      >
                        {select_type.map((item, index) => {
                          return (
                            <Option value={item.value}>{item.type}</Option>
                          );
                        })}
                      </Select>
                    </Col>
                    <Col span={21} align={"right"}>
                      {color_status.map((item, index) => {
                        return (
                          <Tag
                            color={item.color}
                            style={{
                              border: item.border,
                            }}
                          >
                            <span
                              style={{
                                color: item.font_color,
                                fontSize: "12px",
                              }}
                            >
                              {index != 6
                                ? `${item.text} ( ${count_status[index]} ) `
                                : `${item.text}`}
                            </span>
                          </Tag>
                        );
                      })}
                    </Col>
                  </Row>

                  {data_dashboard.length == 0 ? (
                    <>
                      <Row
                        style={{ height: `${window.innerHeight}px` }}
                        type="flex"
                        justify="space-around"
                        align="middle"
                      >
                        <Col span={24}>
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <Row style={{ marginTop: "10px" }}>
                      {data_dashboard.map((item, index) => {
                        return (
                          <VehicleList
                            data={item}
                            color_status={color_status}
                          />
                        );
                      })}
                    </Row>
                  )}
                </div>
              )}
            </LangContext.Consumer>
          </Spin>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

export default connect(
  ({ cpfDashboard, auth, ui: { language } }) => ({
    cpfDashboard,
    auth,
    language,
  }),
  {
    loadDataCPFDashboard: loadDataCPFDashboard.request,
    removeLists,
  }
)(Dashboard);
