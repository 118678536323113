import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadMasterRoute(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      orderBy,
      orderType,
      accessToken,
      filterObj
    }
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterdataroute`,
      // `http://booking-api/api/customer`,
      {
        searchName,
        page,
        pageSize,
        companyID,
        orderBy,
        orderType,
        filterObj
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.loadMasterRoute.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadMasterRoute.failure());
  }
}

function* loadLocation(action) {
  const {
    data: { id, accessToken }
  } = action.payload;

  console.log(id);

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterdataroute/create/${id}`,
      {
        id
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.loadLocation.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadLocation.failure());
  }
}

function* createMasterDataRoutes(action) {
  const {
    data: {
      companyID,
      StartLocationsID,
      EndLocationsID,
      Description,
      Type,
      RouteName,
      RouteRadius,
      Waypoint,
      DirectionPointsClick,
      DistanceValue,
      DistanceText,
      Status,
      Check,
      DirectionPoints,
      DistanceValueHeader,
      DistanceTextClick,
      accessToken,
      StartDestinationName,
      EndDestinationName
    },
    loading
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/createRoutes`,
      // `${DEV_API_ENDPOINT}/customer/create`,
      {
        companyID,
        StartLocationsID,
        EndLocationsID,
        Description,
        Type,
        RouteName,
        RouteRadius,
        Waypoint,
        DirectionPoints,
        DirectionPointsClick,
        DistanceValueHeader,
        DistanceTextClick,
        DistanceValue,
        DistanceText,
        Status,
        Check,
        StartDestinationName,
        EndDestinationName,
        accessToken
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    loading(res.data);
    yield put(actions.createMasterDataRoutes.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.createMasterDataRoutes.failure());
  }
}

function* loadDetailMasterDetail(action) {
  const {
    data: { id, accessToken }
  } = action.payload;
  console.log(accessToken);
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterdataroute/detail/${id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadDetailMasterDetail.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadDetailMasterDetail.failure());
  }
}

function* loadDetailMasterDetailEdit(action) {
  const {
    data: { id, accessToken }
  } = action.payload;
  console.log(accessToken);
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterdataroute/detail/${id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadDetailMasterDetailEdit.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadDetailMasterDetailEdit.failure());
  }
}

function* updateroute(action) {
  const {
    data: {
      id,
      routeName,
      radius,
      description,
      directionPoints,
      distance,
      distanceText,
      directionPointsClick,
      DistanceValueHeader,
      DistanceTextClick,
      Type,
      Status,
      Waypoint,
      Check,
      accessToken
    },
    loading
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterdataroute/edit`,
      {
        id,
        routeName,
        radius,
        description,
        directionPoints,
        distance,
        distanceText,
        directionPointsClick,
        DistanceValueHeader,
        DistanceTextClick,
        Type,
        Status,
        Waypoint,
        Check,
        accessToken
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    loading(res.data);
    yield put(actions.updateroute.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.updateroute.failure());
  }
}

function* loadAutocompleteLocation(action) {
  const { url, companyID, accessToken, queryString } = action.payload;
  try {
    if (queryString == "" || queryString.length <= 1) {
      yield put(actions.loadAutocompleteLocation.success([]));
    } else {
      const res = yield call(
        axios.get,
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/${url}/${companyID}/${queryString}`,

        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      yield put(actions.loadAutocompleteLocation.success(res.data));
    }
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadAutocompleteLocation.failure());
  }
}

function* selectedAutocompleteLocation(action) {
  const { id, autoCompleteLists } = action.payload;
  try {
    let selected = autoCompleteLists.filter(function(ele) {
      return ele.id == id;
    })[0];

    yield put(actions.selectedAutocompleteLocation.success(selected));
  } catch (err) {
    console.log(err.message);
    yield put(actions.selectedAutocompleteLocation.failure());
  }
}

export default function* watchRouteMasterData() {
  yield all([
    takeEvery(actions.LOAD_MASTERDATA_ROUTE.REQUEST, loadMasterRoute),
    takeEvery(actions.LOAD_LOCATION.REQUEST, loadLocation),
    takeEvery(actions.CREATE_MASTERDATA_ROUTE.REQUEST, createMasterDataRoutes),
    takeEvery(
      actions.LOAD_DETAIL_MASTERDATA_ROUTE.REQUEST,
      loadDetailMasterDetail
    ),
    takeEvery(actions.UPDATE_MASTERDATA_ROUTE.REQUEST, updateroute),
    takeEvery(actions.LOAD_AUTOCOMPLETE.REQUEST, loadAutocompleteLocation),
    takeEvery(
      actions.SELECTED_AUTOCOMPLETE.REQUEST,
      selectedAutocompleteLocation
    ),
    takeEvery(
      actions.LOAD_DETAIL_MASTERDATA_ROUTE_EDIT.REQUEST,
      loadDetailMasterDetailEdit
    )
  ]);
}
export {
  loadMasterRoute,
  loadLocation,
  createMasterDataRoutes,
  loadDetailMasterDetail,
  updateroute,
  loadAutocompleteLocation,
  selectedAutocompleteLocation,
  loadDetailMasterDetailEdit
};
