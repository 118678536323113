import React, { Component, Fragment } from "react";

import { Formik } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";

import {
  Avatar,
  Col,
  Checkbox,
  Button,
  Divider,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Skeleton,
  Transfer,
  message,
  Tooltip,
  Icon
} from "antd";
import { Link, BrowserRouter as Router, withRouter } from "react-router-dom";

import LangContext, { i18n } from "modules/shared/context/langContext";

import axios from "axios";

import {
  autoGenKey,
  helper_convertDateformat
} from "../../../../../lib/helper";

const getNodeKey = ({ node: object, treeIndex: number }) => {
  return number;
};

const listStyle = {
  height: 300,
  marginLeft: "30px"
};

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 3 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 7 } }
};

const tailFormItemLayout = {
  labelCol: { xs: { span: 0 }, sm: { span: 0 } },
  wrapperCol: { xs: { span: 0 }, sm: { span: 24 } }
};

const InputGroup = Input.Group;
const { Item } = Form;
const FormItem = Item;
const Option = Select.Option;

class NotiConfig extends Component {
  state = {
    loading: false,
    allUser: [],
    selectedUser: []
  };

  loadDetailData = (path, stateKeyName) => {
    let self = this;
    axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/datavisibility/${path}/${this.props.auth.profile.company_id}`,
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` }
        }
      )
      .then(function(response) {
        self.setState({ [stateKeyName]: response.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  filterOption = (inputValue, option) => option.title.indexOf(inputValue) > -1;

  componentDidMount() {
    let self = this;
    new Promise(function(resolve, reject) {
      resolve(self.loadDetailData("getuser", "allUser"));
    }).then(function(result) {
      return new Promise(function(resolve, reject) {
        resolve(self.loadDetailData("getvehicle", "allVehicle"));
      });
    });
  }

  render() {
    console.log(this.props.initialValues);
    return (
      <Row gutter={24}>
        <Spin spinning={this.state.loading}>
          <Formik
            enableReinitialize={true}
            initialValues={this.props.initialValues}
            validationSchema={yup.object().shape({
              critical_level: yup.string().required(),
              open_noti_id: yup.string().required(),
              close_noti_id: yup.string().nullable()
            })}
            onSubmit={(values, actions) => {
              this.props.setInitValue({
                ...this.props.initialValues,
                critical_level: values.critical_level,
                open_noti_id: values.open_noti_id,
                close_noti_id: values.close_noti_id,
                all_user: values.all_user,
                selectedUser: values.selectedUser,
                channel_web: values.channel_web,
                channel_line: values.channel_line,
                channel_email: values.channel_email
              });

              this.setState({ loading: true });
              if (this.props.action == "create")
                this.props.createPolicy(() => {
                  message.success("Processing complete!");
                  this.setState({ loading: false });
                  return this.props.history.push("/policy");
                });
              else
                this.props.updatePolicy(() => {
                  message.success("Processing complete!");
                  this.setState({ loading: false });
                  return this.props.history.push("/policy");
                });
            }}
            render={({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue
            }) => (
              <LangContext.Consumer>
                {i18n => {
                  if (
                    this.props.action == "create" ||
                    (this.props.action == "edit" && values.policy_id != null)
                  )
                    return (
                      // <Form onSubmit={handleSubmit} layout="inline" {...formItemLayout}>

                      <Form onSubmit={handleSubmit}>
                        <Divider orientation="left">
                          <LangContext.Consumer>
                            {i18n => i18n.n.notification}
                          </LangContext.Consumer>
                        </Divider>

                        {/* {this.props.conditionList.map((ele, i) => (
                              <Col span={2}>
                                <Avatar
                                  style={{
                                    // backgroundColor: "#f56a00",
                                    verticalAlign: "middle"
                                  }}
                                  size="large"
                                >
                                  {(i + 1 + 9).toString(36).toUpperCase()}
                                </Avatar>
                                <div
                                  style={{
                                    display:
                                      i == this.props.conditionList.length - 1
                                        ? "none"
                                        : "block",
                                    margin: "0px 0px 0px 50px",
                                    fontWeight: "500"
                                  }}
                                >
                                  And
                                </div>
                              </Col>
                            ))} */}

                        <Col span={5}>
                          <Form.Item
                            label={
                              <span>
                                <LangContext.Consumer>
                                  {i18n => "การทริกเกอร์การแจ้งเตือน"}
                                </LangContext.Consumer>
                                &nbsp;
                                <Tooltip
                                  title={
                                    <LangContext.Consumer>
                                      {i18n =>
                                        "ความถี่ในการทริกเกอร์การแจ้งเตือน เมื่อเงื่อนไขทั้งหมดเป็นจริง"
                                      }
                                    </LangContext.Consumer>
                                  }
                                >
                                  <Icon type="question-circle-o" />
                                </Tooltip>
                              </span>
                            }
                          >
                            <Select
                              key={autoGenKey("trigger-select")}
                              // name="policy_type"
                              defaultValue="1_min"
                              // value={values.policy_type.toString()}
                              // onChange={e => {
                              //   this.handleInputChange(
                              //     e,
                              //     "policy_type"
                              //   );
                              //   this.disablePolicyTypeSelection(
                              //     values.policy_type,
                              //     e
                              //   );
                              // }}
                            >
                              <Option
                                key="1_min"
                                disabled={this.props.has_speed_limit_type}
                              >
                                <LangContext.Consumer>
                                  {i18n => "ทุก 1 นาที"}
                                </LangContext.Consumer>
                              </Option>
                              <Option
                                key="5_min"
                                disabled={this.props.has_duration_type}
                              >
                                <LangContext.Consumer>
                                  {i18n => "ทุก 5 นาที"}
                                </LangContext.Consumer>
                              </Option>
                              <Option
                                key="15_min"
                                disabled={this.props.has_duration_type}
                              >
                                <LangContext.Consumer>
                                  {i18n => "ทุก 15 นาที"}
                                </LangContext.Consumer>
                              </Option>
                              <Option
                                key="30_min"
                                disabled={this.props.has_day_time_type}
                              >
                                <LangContext.Consumer>
                                  {i18n => "ทุก 30 นาที"}
                                </LangContext.Consumer>
                              </Option>
                              <Option key="1_hour">
                                <LangContext.Consumer>
                                  {i18n => "ทุก 1 ชั่วโมง"}
                                </LangContext.Consumer>
                              </Option>
                              <Option key="4_hour">
                                <LangContext.Consumer>
                                  {i18n => "ทุก 4 ชั่วโมง"}
                                </LangContext.Consumer>
                              </Option>

                              <Option key="1_day">
                                <LangContext.Consumer>
                                  {i18n => "ทุก 1 วัน"}
                                </LangContext.Consumer>
                              </Option>
                              <Option key="until_false">
                                <LangContext.Consumer>
                                  {i18n => "จนกว่าเงื่อนไขจะเป็นเท็จ"}
                                </LangContext.Consumer>
                              </Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={5} offset={1}>
                          <FormItem
                            label={
                              <LangContext.Consumer>
                                {i18n => i18n.c.criticalLevel}
                              </LangContext.Consumer>
                            }
                            // required={true}
                            validateStatus={
                              touched.critical_level && errors.critical_level
                                ? "warning"
                                : undefined
                            }
                            help={
                              touched.critical_level && errors.critical_level
                                ? errors.critical_level.substr(
                                    errors.critical_level.indexOf(" ") + 1
                                  )
                                : undefined
                            }
                          >
                            <Select
                              name="critical_level"
                              defaultValue={values.critical_level.toString()}
                              value={values.critical_level.toString()}
                              onChange={e => {
                                setFieldValue("critical_level", e);
                                // this.props.setInitValue({
                                //   ...this.props.initialValues,
                                //   critical_level: e
                                // });
                              }}
                            >
                              <Option key="high">
                                <LangContext.Consumer>
                                  {i18n => i18n.h.high}
                                </LangContext.Consumer>
                              </Option>
                              <Option key="medium">
                                <LangContext.Consumer>
                                  {i18n => i18n.m.medium}
                                </LangContext.Consumer>
                              </Option>
                              <Option key="low">
                                <LangContext.Consumer>
                                  {i18n => i18n.l.low}
                                </LangContext.Consumer>
                              </Option>
                            </Select>
                          </FormItem>
                        </Col>

                        <Col span={5} offset={1}>
                          <FormItem
                            label={
                              <LangContext.Consumer>
                                {i18n => i18n.w.whenEventOccurred}
                              </LangContext.Consumer>
                            }
                            // required={true}
                            validateStatus={
                              touched.open_noti_id && errors.open_noti_id
                                ? "warning"
                                : undefined
                            }
                            help={
                              touched.open_noti_id && errors.open_noti_id
                                ? errors.open_noti_id.substr(
                                    errors.open_noti_id.indexOf(" ") + 1
                                  )
                                : undefined
                            }
                          >
                            <Select
                              name="open_noti_id"
                              defaultValue={values.open_noti_id.toString()}
                              value={values.open_noti_id.toString()}
                              onChange={e => {
                                setFieldValue("open_noti_id", e);
                                // this.props.setInitValue({
                                //   ...this.props.initialValues,
                                //   open_noti_id: e
                                // });
                              }}
                            >
                              <Option key="alert">
                                <LangContext.Consumer>
                                  {i18n => i18n.a.alertNotiOnly}
                                </LangContext.Consumer>
                              </Option>
                              <Option key="action_req">
                                <LangContext.Consumer>
                                  {i18n => i18n.a.actionRequired}
                                </LangContext.Consumer>
                              </Option>
                              <Option key="ignored">
                                <LangContext.Consumer>
                                  {i18n => i18n.i.ignoredAlert}
                                </LangContext.Consumer>
                              </Option>
                            </Select>
                          </FormItem>
                        </Col>

                        <Col span={5} offset={1}>
                          {/* <FormItem
                            label={
                              <LangContext.Consumer>
                                {i18n => i18n.n.notificationEndWhen}
                              </LangContext.Consumer>
                            }
                            required={true}
                            validateStatus={
                              touched.close_noti_id && errors.close_noti_id
                                ? "warning"
                                : undefined
                            }
                            help={
                              touched.close_noti_id && errors.close_noti_id
                                ? errors.close_noti_id.substr(
                                    errors.close_noti_id.indexOf(" ") + 1
                                  )
                                : undefined
                            }
                          >
                            <Select
                              name="close_noti_id"
                              defaultValue={values.close_noti_id.toString()}
                              value={
                                values.open_noti_id == "action_req"
                                  ? "event_end"
                                  : values.close_noti_id.toString()
                              }
                              onChange={e => {
                                setFieldValue("close_noti_id", e);
                                // this.props.setInitValue({
                                //   ...this.props.initialValues,
                                //   close_noti_id: e
                                // });
                              }}
                              // style={{ width: "170%" }}
                              disabled={
                                values.open_noti_id == "ignored" ||
                                values.open_noti_id == "action_req"
                              }
                            >
                              <Option key="event_end">
                                <LangContext.Consumer>
                                  {i18n => i18n.e.eventEnd}
                                </LangContext.Consumer>
                              </Option>
                              <Option key="user_close">
                                <LangContext.Consumer>
                                  {i18n => i18n.u.userClose}
                                </LangContext.Consumer>
                              </Option>
                              <Option key="after_5m">
                                {" "}
                                <LangContext.Consumer>
                                  {i18n => i18n.a.after5min}
                                </LangContext.Consumer>
                              </Option>
                              <Option key="after_30m">
                                {" "}
                                <LangContext.Consumer>
                                  {i18n => i18n.a.after30min}
                                </LangContext.Consumer>
                              </Option>
                              <Option key="after_1h">
                                {" "}
                                <LangContext.Consumer>
                                  {i18n => i18n.a.after1hour}
                                </LangContext.Consumer>
                              </Option>
                              <Option key="after_4h">
                                {" "}
                                <LangContext.Consumer>
                                  {i18n => i18n.a.after4hour}
                                </LangContext.Consumer>
                              </Option>
                              <Option key="after_8h">
                                {" "}
                                <LangContext.Consumer>
                                  {i18n => i18n.a.after8hour}
                                </LangContext.Consumer>
                              </Option>
                              <Option key="after_24h">
                                {" "}
                                <LangContext.Consumer>
                                  {i18n => i18n.a.after1day}
                                </LangContext.Consumer>
                              </Option>
                            </Select>
                          </FormItem> */}
                        </Col>

                        <Divider orientation="left">
                          <LangContext.Consumer>
                            {i18n => "ช่องทางการแจ้งเตือน"}
                          </LangContext.Consumer>
                        </Divider>

                        <Row>
                          <Col span={2} offset={1}>
                            <Checkbox
                              checked={values.channel_web}
                              onChange={e => {
                                setFieldValue("channel_web", e.target.checked);
                                // this.props.setInitValue({
                                //   ...this.props.initialValues,
                                //   channel_web: e.target.checked
                                // });
                              }}
                            >
                              Web
                            </Checkbox>
                          </Col>
                          <Col span={2} offset={1}>
                            <Checkbox
                              checked={values.channel_email}
                              onChange={e => {
                                setFieldValue(
                                  "channel_email",
                                  e.target.checked
                                );
                                // this.props.setInitValue({
                                //   ...this.props.initialValues,
                                //   channel_email: e.target.checked
                                // });
                              }}
                            >
                              Email
                            </Checkbox>
                          </Col>
                          <Col span={2} offset={1}>
                            <Checkbox
                              checked={values.channel_line}
                              onChange={e => {
                                setFieldValue("channel_line", e.target.checked);
                                //   this.props.setInitValue({
                                //   ...this.props.initialValues,
                                //   channel_line: e.target.checked
                                // })
                              }}
                            >
                              Line
                            </Checkbox>
                          </Col>
                        </Row>

                        <Divider orientation="left">
                          <LangContext.Consumer>
                            {i18n => "ผู้ใช้งานระบบที่ได้รับการแจ้งเตือน"}
                          </LangContext.Consumer>
                        </Divider>

                        <Row>
                          <Col span={23} offset={1}>
                            <Checkbox
                              checked={values.all_user}
                              onChange={e => {
                                setFieldValue("all_user", e.target.checked);
                                // this.props.setInitValue({
                                //   all_user: e.target.checked
                                // });
                              }}
                            >
                              ทั้งหมด
                            </Checkbox>
                          </Col>
                        </Row>
                        {!values.all_user && (
                          <Transfer
                            titles={[
                              <LangContext.Consumer>
                                {i18n => i18n.i.itemLeft}
                              </LangContext.Consumer>,
                              <LangContext.Consumer>
                                {i18n => i18n.i.itemSelected}
                              </LangContext.Consumer>
                            ]}
                            style={{ margin: "25px 0px 0px 15px" }}
                            listStyle={listStyle}
                            dataSource={this.state.allUser}
                            showSearch
                            filterOption={this.filterOption}
                            targetKeys={values.selectedUser}
                            onChange={(targetKeys, direction, moveKeys) => {
                              setFieldValue("selectedUser", targetKeys);
                              // this.props.setInitValue({
                              //   ...this.props.initialValues,
                              //   selectedUser: targetKeys
                              // });
                            }}
                            render={item => item.title}
                          />
                        )}
                        <div style={{ margin: "40px 0px 0px 0px" }}>
                          <Button
                            style={{ marginLeft: 8 }}
                            onClick={() => {
                              // this.props.loadTripDetail({ id: 1778183 });
                              this.props.prev(this.props.current);
                            }}
                          >
                            <LangContext.Consumer>
                              {i18n => i18n.p.previous}
                            </LangContext.Consumer>
                          </Button>

                          <Button
                            style={{ marginLeft: 8 }}
                            htmlType="submit"
                            type="primary"
                            // onClick={() => console.log(this.props.initialValues)}
                          >
                            <LangContext.Consumer>
                              {i18n => i18n.s.save}
                            </LangContext.Consumer>
                          </Button>
                        </div>
                      </Form>
                    );
                  else return <Skeleton active paragraph={{ rows: 14 }} />;
                }}
              </LangContext.Consumer>
            )}
          />
        </Spin>
      </Row>
    );
  }
}
export default connect(
  ({ policyState, auth }) => ({ policyState, auth }),
  {}
)(withRouter(NotiConfig));
