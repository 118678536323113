import React, { Component, Fragment } from "react";

import { Formik } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";

import {
  Avatar,
  Col,
  Checkbox,
  Button,
  Divider,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Skeleton,
  Transfer,
  message,
  Tooltip,
  Icon,
} from "antd";
import { Link, BrowserRouter as Router, withRouter } from "react-router-dom";

import LangContext, { i18n } from "modules/shared/context/langContext";
import FormDivider from "../../../../../shared/components/FormDivider";
import axios from "axios";

import {
  autoGenKey,
  helper_convertDateformat,
} from "../../../../../../lib/helper";

const getNodeKey = ({ node: object, treeIndex: number }) => {
  return number;
};

const listStyle = {
  height: 300,
  marginLeft: "30px",
};

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 3 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 7 } },
};

const tailFormItemLayout = {
  labelCol: { xs: { span: 0 }, sm: { span: 0 } },
  wrapperCol: { xs: { span: 0 }, sm: { span: 24 } },
};

const InputGroup = Input.Group;
const { Item } = Form;
const FormItem = Item;
const Option = Select.Option;

class NotiConfig extends Component {
  state = {
    loading: false,
    allUser: [],
    selectedUser: [],
  };

  loadDetailData = (path, stateKeyName) => {
    let self = this;
    axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/datavisibility/${path}/${this.props.auth.profile.company_id}`,
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        }
      )
      .then(function(response) {
        self.setState({ [stateKeyName]: response.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  filterOption = (inputValue, option) => option.title.indexOf(inputValue) > -1;

  componentDidMount() {
    let self = this;
    new Promise(function(resolve, reject) {
      resolve(self.loadDetailData("getuser", "allUser"));
    }).then(function(result) {
      return new Promise(function(resolve, reject) {
        resolve(self.loadDetailData("getvehicle", "allVehicle"));
      });
    });
  }

  render() {
    return (
      <Spin spinning={this.state.loading}>
        <LangContext.Consumer>
          {(i18n) => {
            if (
              this.props.action == "create" ||
              (this.props.action == "edit" &&
                this.props.values.policy_id != null)
            )
              return (
                <Fragment>
                  <div style={{ display: "none" }}>
                    <FormDivider>
                      <LangContext.Consumer>
                        {(i18n) => i18n.n.notification}
                      </LangContext.Consumer>
                    </FormDivider>

                    <Col span={7}>
                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {(i18n) => i18n.w.whenEventOccurred}
                          </LangContext.Consumer>
                        }
                        // required={true}
                        validateStatus={
                          this.props.touched.open_noti_id &&
                          this.props.errors.open_noti_id
                            ? "warning"
                            : undefined
                        }
                        help={
                          this.props.touched.open_noti_id &&
                          this.props.errors.open_noti_id
                            ? this.props.errors.open_noti_id.substr(
                                this.props.errors.open_noti_id.indexOf(" ") + 1
                              )
                            : undefined
                        }
                      >
                        <Select
                          name="open_noti_id"
                          defaultValue={this.props.values.open_noti_id.toString()}
                          //   value={this.props.values.open_noti_id.toString()}
                          onChange={(e) => {
                            this.props.setFieldValue("open_noti_id", e);
                            // this.props.setInitValue({
                            //   ...this.props.initialValues,
                            //   open_noti_id: e
                            // });
                          }}
                        >
                          <Option key="alert">
                            <LangContext.Consumer>
                              {(i18n) => i18n.a.alertNotiOnly}
                            </LangContext.Consumer>
                          </Option>
                          <Option key="action_req">
                            <LangContext.Consumer>
                              {(i18n) => i18n.a.actionRequired}
                            </LangContext.Consumer>
                          </Option>
                          <Option key="ignored">
                            <LangContext.Consumer>
                              {(i18n) => i18n.i.ignoredAlert}
                            </LangContext.Consumer>
                          </Option>
                        </Select>
                      </FormItem>
                    </Col>

                    <Col span={7} offset={1}>
                      <Form.Item
                        label={
                          <span>
                            <LangContext.Consumer>
                              {(i18n) => "ความถี่"}
                            </LangContext.Consumer>
                            &nbsp;
                            <Tooltip
                              title={
                                <LangContext.Consumer>
                                  {(i18n) =>
                                    "ความถี่ในการแจ้งเตือนเมื่อมีการฝ่าฝืนนโยบาย"
                                  }
                                </LangContext.Consumer>
                              }
                            >
                              <Icon type="question-circle-o" />
                            </Tooltip>
                          </span>
                        }
                      >
                        <Select
                          key={autoGenKey("trigger-select")}
                          name="repeat_noti_id"
                          defaultValue={this.props.values.repeat_noti_id.toString()}
                          // value={ this.props.values.close_noti_id.toString()}
                          onChange={(e) => {
                            this.props.setFieldValue("repeat_noti_id", e);
                          }}
                          disabled={this.props.values.open_noti_id == "ignored"}
                        >
                          <Option key="one_time" value="one_time">
                            <LangContext.Consumer>
                              {(i18n) => "ครั้งเดียว"}
                            </LangContext.Consumer>
                          </Option>
                          <Option key="1_min" value="1_min" disabled={true}>
                            <LangContext.Consumer>
                              {(i18n) => "ทุก 1 นาที"}
                            </LangContext.Consumer>
                          </Option>
                          <Option key="5_min" value="5_min" disabled={true}>
                            <LangContext.Consumer>
                              {(i18n) => "ทุก 5 นาที"}
                            </LangContext.Consumer>
                          </Option>
                          <Option key="15_min" value="15_min" disabled={true}>
                            <LangContext.Consumer>
                              {(i18n) => "ทุก 15 นาที"}
                            </LangContext.Consumer>
                          </Option>
                          <Option key="30_min" value="30_min" disabled={true}>
                            <LangContext.Consumer>
                              {(i18n) => "ทุก 30 นาที"}
                            </LangContext.Consumer>
                          </Option>
                          <Option key="1_hour" value="1_hour" disabled={true}>
                            <LangContext.Consumer>
                              {(i18n) => "ทุก 1 ชั่วโมง"}
                            </LangContext.Consumer>
                          </Option>
                          <Option key="4_hour" value="4_hour" disabled={true}>
                            <LangContext.Consumer>
                              {(i18n) => "ทุก 4 ชั่วโมง"}
                            </LangContext.Consumer>
                          </Option>

                          <Option key="1_day" value="1_day" disabled={true}>
                            <LangContext.Consumer>
                              {(i18n) => "ทุก 1 วัน"}
                            </LangContext.Consumer>
                          </Option>
                          {/* <Option key="until_false" value="until_false">
                            <LangContext.Consumer>
                              {i18n => "จนกว่าเงื่อนไขจะเป็นเท็จ"}
                            </LangContext.Consumer>
                          </Option> */}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={7} offset={1}>
                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {(i18n) => i18n.c.criticalLevel}
                          </LangContext.Consumer>
                        }
                        // required={true}
                        validateStatus={
                          this.props.touched.critical_level &&
                          this.props.errors.critical_level
                            ? "warning"
                            : undefined
                        }
                        help={
                          this.props.touched.critical_level &&
                          this.props.errors.critical_level
                            ? this.props.errors.critical_level.substr(
                                this.props.errors.critical_level.indexOf(" ") +
                                  1
                              )
                            : undefined
                        }
                      >
                        <Select
                          name="critical_level"
                          defaultValue={this.props.values.critical_level.toString()}
                          //   value={this.props.values.critical_level.toString()}
                          onChange={(e) => {
                            this.props.setFieldValue("critical_level", e);
                            // this.props.setInitValue({
                            //   ...this.props.initialValues,
                            //   critical_level: e
                            // });
                          }}
                        >
                          <Option key="high">
                            <LangContext.Consumer>
                              {(i18n) => i18n.h.high}
                            </LangContext.Consumer>
                          </Option>
                          <Option key="medium">
                            <LangContext.Consumer>
                              {(i18n) => i18n.m.medium}
                            </LangContext.Consumer>
                          </Option>
                          <Option key="low">
                            <LangContext.Consumer>
                              {(i18n) => i18n.l.low}
                            </LangContext.Consumer>
                          </Option>
                        </Select>
                      </FormItem>
                    </Col>
                  </div>
                  <FormDivider>
                    <LangContext.Consumer>
                      {(i18n) => "ช่องทางการแจ้งเตือน"}
                    </LangContext.Consumer>
                  </FormDivider>

                  <Row style={{ margin: "0px 0px 20px 0px" }}>
                    <Col
                      span={3}
                      offset={1}
                      style={{
                        display:
                          this.props.values.policy_type ==
                          "abnormal_temperature"
                            ? "none"
                            : "inline",
                      }}
                    >
                      <Checkbox
                        checked={this.props.values.channel_web}
                        onChange={(e) => {
                          this.props.setFieldValue(
                            "channel_web",
                            e.target.checked
                          );
                          // this.props.setInitValue({
                          //   ...this.props.initialValues,
                          //   channel_web: e.target.checked
                          // });
                        }}
                      >
                        Web
                      </Checkbox>
                    </Col>
                    <Col span={3} offset={1}>
                      <Checkbox
                        checked={this.props.values.channel_email}
                        onChange={(e) => {
                          this.props.setFieldValue(
                            "channel_email",
                            e.target.checked
                          );
                          // this.props.setInitValue({
                          //   ...this.props.initialValues,
                          //   channel_email: e.target.checked
                          // });
                        }}
                      >
                        Email
                      </Checkbox>
                    </Col>
                    <Col span={3} offset={1}>
                      <Checkbox
                        checked={this.props.values.channel_line}
                        onChange={(e) => {
                          this.props.setFieldValue(
                            "channel_line",
                            e.target.checked
                          );
                          //   this.props.setInitValue({
                          //   ...this.props.initialValues,
                          //   channel_line: e.target.checked
                          // })
                        }}
                      >
                        Line
                      </Checkbox>
                    </Col>

                    <Col span={3} offset={1}>
                      <Checkbox
                        checked={this.props.values.is_incident}
                        onChange={(e) => {
                          this.props.setFieldValue(
                            "is_incident",
                            e.target.checked
                          );
                          //   this.props.setInitValue({
                          //   ...this.props.initialValues,
                          //   channel_line: e.target.checked
                          // })
                        }}
                      >
                        Incident Dashboard
                      </Checkbox>
                    </Col>
                  </Row>

                  <FormDivider>
                    <LangContext.Consumer>
                      {(i18n) => "ผู้ใช้งานระบบที่ได้รับการแจ้งเตือน"}
                    </LangContext.Consumer>
                  </FormDivider>

                  <Row>
                    <Col span={23} offset={1}>
                      <Checkbox
                        checked={this.props.values.all_user}
                        onChange={(e) => {
                          this.props.setFieldValue(
                            "all_user",
                            e.target.checked
                          );
                          // this.props.setInitValue({
                          //   all_user: e.target.checked
                          // });
                        }}
                      >
                        ทั้งหมด
                      </Checkbox>
                    </Col>
                  </Row>
                  {!this.props.values.all_user && (
                    <Transfer
                      titles={[
                        <LangContext.Consumer>
                          {(i18n) => i18n.i.itemLeft}
                        </LangContext.Consumer>,
                        <LangContext.Consumer>
                          {(i18n) => i18n.i.itemSelected}
                        </LangContext.Consumer>,
                      ]}
                      style={{ margin: "25px 0px 0px 15px" }}
                      listStyle={listStyle}
                      dataSource={this.state.allUser}
                      showSearch
                      filterOption={this.filterOption}
                      targetKeys={this.props.values.selectedUser}
                      onChange={(targetKeys, direction, moveKeys) => {
                        this.props.setFieldValue("selectedUser", targetKeys);
                        // this.props.setInitValue({
                        //   ...this.props.initialValues,
                        //   selectedUser: targetKeys
                        // });
                      }}
                      render={(item) => item.title}
                    />
                  )}
                </Fragment>
              );
            else return <Skeleton active paragraph={{ rows: 14 }} />;
          }}
        </LangContext.Consumer>
      </Spin>
    );
  }
}
export default connect(
  ({ policyState, auth }) => ({ policyState, auth }),
  {}
)(withRouter(NotiConfig));
