import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import { Link, Redirect } from "react-router-dom";

import {
  Button,
  Form,
  Divider,
  Row,
  Col,
  Input,
  Checkbox,
  Transfer,
  Tag,
  Tabs,
  Spin,
  Modal,
  Popconfirm,
  message,
  Icon,
} from "antd";

import LangContext, { i18n } from "modules/shared/context/langContext";
import FormDivider from "../../../../../shared/components/FormDivider";
import axios from "axios";

import {
  autoGenKey,
  helper_convertDateformat,
} from "../../../../../../lib/helper";
import { FaSearch } from "react-icons/fa";

const getNodeKey = ({ node: object, treeIndex: number }) => {
  return number;
};

const listStyle = {
  height: 300,
  marginLeft: "30px",
};

class VehicleForm extends Component {
  state = {
    allVehicleGroup: [],
    allVehicle: [],
    selectedVehicleGroup: [],
    selectedVehicle: [],
  };

  loadDetailData = (path, stateKeyName) => {
    let self = this;
    axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/datavisibility/${path}/${this.props.auth.profile.company_id}`,
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        }
      )
      .then(function(response) {
        self.setState({ [stateKeyName]: response.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  loadVehicleWithNoConflictBetweenPolicy = (
    path,
    stateKeyName,
    policy_type,
    area
  ) => {
    let self = this;
    if (typeof area == "undefined") area = "";
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/${path}`,
        {
          action: this.props.action,
          policy_id: this.props.values.policy_id,
          company_id: this.props.auth.profile.company_id,
          vehicle_visibility: this.props.auth.profile.vehicle_visibility,
          policy_type: policy_type,
          area: area,
          location: area,
        },
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        }
      )
      .then(function(response) {
        self.setState({ [stateKeyName]: response.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  filterOption = (inputValue, option) => option.title.indexOf(inputValue) > -1;

  componentDidMount() {
    let self = this;
    new Promise(function(resolve, reject) {
      resolve(
        self.loadVehicleWithNoConflictBetweenPolicy(
          "getvehiclebypolicy",
          "allVehicle",
          self.props.values.policy_type,
          self.props.values.area
        )
      );
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.values.policy_type != this.props.values.policy_type ||
      nextProps.values.area != this.props.values.area
    ) {
      this.loadVehicleWithNoConflictBetweenPolicy(
        "getvehiclebypolicy",
        "allVehicle",
        nextProps.values.policy_type,
        nextProps.values.area
      );
    }

    return true;
  }

  render() {
    return (
      <LangContext.Consumer>
        {(i18n) => {
          if (
            this.props.action == "create" ||
            (this.props.action == "edit" && this.props.values.policy_id != null)
          )
            return (
              <Fragment>
                {/* <FormDivider>
                  <LangContext.Consumer>
                    {i18n => i18n.v.vehicleGroup}
                  </LangContext.Consumer>
                </FormDivider>
                <Transfer
                  titles={[
                    <LangContext.Consumer>
                      {i18n => i18n.i.itemLeft}
                    </LangContext.Consumer>,
                    <LangContext.Consumer>
                      {i18n => i18n.i.itemSelected}
                    </LangContext.Consumer>
                  ]}
                  listStyle={listStyle}
                  style={{ margin: "0px 0px 0px 15px" }}
                  dataSource={this.state.allVehicleGroup}
                  showSearch
                  filterOption={this.filterOption}
                  targetKeys={this.props.values.selectedVehicleGroup}
                  onChange={(targetKeys, direction, moveKeys) =>
                    this.props.setFieldValue("selectedVehicleGroup", targetKeys)
                  }
                  render={item => item.title}
                /> */}

                <FormDivider>
                  <LangContext.Consumer>
                    {(i18n) => i18n.v.vehicle}
                  </LangContext.Consumer>
                </FormDivider>

                <Row>
                  <Col span={23} offset={1}>
                    <Checkbox
                      checked={this.props.values.all_vehicle}
                      onChange={(e) =>
                        this.props.setFieldValue(
                          "all_vehicle",
                          e.target.checked
                        )
                      }
                    >
                      <LangContext.Consumer>
                        {(i18n) => i18n.a.all}
                      </LangContext.Consumer>
                    </Checkbox>
                  </Col>
                </Row>
                {!this.props.values.all_vehicle && (
                  <Transfer
                    titles={[
                      <LangContext.Consumer>
                        {(i18n) => i18n.i.itemLeft}
                      </LangContext.Consumer>,
                      <LangContext.Consumer>
                        {(i18n) => i18n.i.itemSelected}
                      </LangContext.Consumer>,
                    ]}
                    style={{ margin: "25px 0px 0px 15px" }}
                    listStyle={listStyle}
                    dataSource={this.state.allVehicle}
                    showSearch
                    filterOption={this.filterOption}
                    targetKeys={this.props.values.selectedVehicle}
                    onChange={(targetKeys, direction, moveKeys) =>
                      this.props.setFieldValue("selectedVehicle", targetKeys)
                    }
                    render={(item) => item.title}
                  />
                )}
              </Fragment>
            );
        }}
      </LangContext.Consumer>
    );
  }
}
export default connect(
  ({ policyState, auth }) => ({ policyState, auth }),
  {}
)(VehicleForm);
