const checkInitialValues = (
  dataForm,
  type,
  dataFromEdit,
  initialValuesPolicy
) => {
  switch (true) {
    case !!dataForm:
      return dataForm;

    case type === "edit" && !!dataFromEdit:
      switch (dataFromEdit["policy_type"]) {
        case "D":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            value_yellow:
              dataFromEdit["policy"]["P_DEFAULT"]["ALARM_VEL"]["YELLOW"],
            value_red: dataFromEdit["policy"]["P_DEFAULT"]["ALARM_VEL"]["RED"],
            timepicker: dataFromEdit["policy"]["ALERT_DURATION"]["TIME"],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            period: dataFromEdit["period"],
            BREAK_WARNING_TIME:
              dataFromEdit["policy"]["ALERT_DURATION"]["BREAK_WARNING_TIME"],
            INTERVAL_WARNING:
              dataFromEdit["policy"]["P_DEFAULT"]["ALARM_VEL"][
                "INTERVAL_WARNING"
              ],
            INTERVAL_POLICY:
              dataFromEdit["policy"]["P_DEFAULT"]["ALARM_VEL"][
                "INTERVAL_POLICY"
              ],
            ringToneYellow:
              dataFromEdit["policy"]["P_DEFAULT"]["ALARM_VEL"]["AUDIO_WARNING"],
            ringToneRed:
              dataFromEdit["policy"]["P_DEFAULT"]["ALARM_VEL"]["AUDIO_POLICY"],
            id: dataFromEdit["id"],
          };

        case "GZ":
        case "RZ":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            value_yellow:
              dataFromEdit["policy"]["P_GEOFENCE"]["ALARM_VEL"]["YELLOW"],
            value_red: dataFromEdit["policy"]["P_GEOFENCE"]["ALARM_VEL"]["RED"],
            timepicker: dataFromEdit["policy"]["ALERT_DURATION"]["TIME"],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            zone: dataFromEdit["policy"]["DETAIL_GEOFENCE"]["GID"],
            geofence: dataFromEdit["policy"]["DETAIL_GEOFENCE"]["GEOFENCE"],
            period: dataFromEdit["period"],
            id: dataFromEdit["id"],
          };

        case "SZ":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            value_yellow:
              dataFromEdit["policy"]["P_GEOFENCE"]["ALARM_VEL"]["YELLOW"],
            value_red: dataFromEdit["policy"]["P_GEOFENCE"]["ALARM_VEL"]["RED"],
            timepicker: dataFromEdit["policy"]["ALERT_DURATION"]["TIME"],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            zone: dataFromEdit["policy"]["DETAIL_GEOFENCE"]["GID"],
            period: dataFromEdit["period"],
            INTERVAL_WARNING:
              dataFromEdit["policy"]["P_GEOFENCE"]["ALARM_VEL"][
                "INTERVAL_WARNING"
              ],
            INTERVAL_POLICY:
              dataFromEdit["policy"]["P_GEOFENCE"]["ALARM_VEL"][
                "INTERVAL_POLICY"
              ],
            ringToneYellow:
              dataFromEdit["policy"]["P_GEOFENCE"]["ALARM_VEL"][
                "AUDIO_WARNING"
              ],
            ringToneRed:
              dataFromEdit["policy"]["P_GEOFENCE"]["ALARM_VEL"]["AUDIO_POLICY"],
            approachRange: dataFromEdit["policy"]["EXTEND_RADIUS"]["METER"],
            ringToneRed2: dataFromEdit["policy"]["EXTEND_RADIUS"]["AUDIO"],
            wigp_start_time: dataFromEdit["policy"]["P_TIME"]["START"],
            wigp_end_time: dataFromEdit["policy"]["P_TIME"]["END"],
            id: dataFromEdit["id"],
          };

        case "N":
        case "R":
        case "DH":
        case "UH":
          let obj = {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            value_yellow:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "ALARM_VEL"
              ]["YELLOW"],
            value_red:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "ALARM_VEL"
              ]["RED"],
            timepicker: dataFromEdit["policy"]["ALERT_DURATION"]["TIME"],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            period: dataFromEdit["period"],
            id: dataFromEdit["id"],
          };
          if (dataFromEdit["policy_type"] == "N") {
            obj.startTimePickerNight =
              dataFromEdit["policy"]["NIGHT_TIME"]["START"];
            obj.endTimePickerNight =
              dataFromEdit["policy"]["NIGHT_TIME"]["END"];
          }
          return obj;

        case "AI":
        case "APON":
        case "AFUA":
        case "A_WDLR":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            typeLocation:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "LOC_TYPE"
              ],
            stop_point:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "AREA"
              ],
            day_duration: dataFromEdit["day"],
            timepicker: dataFromEdit["duration"],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            id: dataFromEdit["id"],
          };

        case "AS":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            typeLocation:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "LOC_TYPE"
              ],
            stop_point:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "AREA"
              ],
            wigp_start_time:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "P_TIME"
              ]["START"],
            wigp_end_time:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "P_TIME"
              ]["END"],
            approachRange:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "EXTEND_RADIUS"
              ]["METER"],
            warningAudioForNotStoppingInTime:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "EXTEND_RADIUS"
              ]["AUDIO"],
            timepicker:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "ALARM"
              ]["YELLOW_DURATION"],
            day_duration:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "ALARM"
              ]["YELLOW_DAY"],
            timepicker_red:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "ALARM"
              ]["RED_DURATION"],
            day_duration_red:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "ALARM"
              ]["RED_DAY"],
            INTERVAL_WARNING:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "ALARM"
              ]["INTERVAL_WARNING"],
            INTERVAL_POLICY:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "ALARM"
              ]["INTERVAL_POLICY"],
            ringToneRed:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "ALARM"
              ]["AUDIO_WARNING"],
            warningAudioForParkingTimeComplete:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "ALARM"
              ]["AUDIO_POLICY"],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            id: dataFromEdit["id"],
          };

        case "ATEMP":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            min_temp:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "MIN"
              ],
            max_temp:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "MAX"
              ],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            id: dataFromEdit["id"],
          };

        case "A_WIGP":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            wigp_start_time:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "END_TIME"
              ],
            wigp_end_time:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "START_TIME"
              ],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            id: dataFromEdit["id"],
          };

        case "A_WLTL":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            wltl_dur:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "MAX_DUR"
              ],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            id: dataFromEdit["id"],
          };

        case "A_WLTD":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            wltd_dur:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "MAX_DUR"
              ],
            wltd_distance:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "DISTANCE"
              ],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            id: dataFromEdit["id"],
          };

        case "A_WLTD":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            wltd_dur:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "MAX_DUR"
              ],

            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            id: dataFromEdit["id"],
          };

        case "A_VMOFLT":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            wltd_dur:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "MAX_DUR"
              ],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            id: dataFromEdit["id"],
          };

        case "A_VDC":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            parkingTime:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "ALERT_DURATION"
              ],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            id: dataFromEdit["id"],
          };

        case "A_VCD":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            parkingTime:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "PARKING_TIME"
              ],
            drivingSpeed:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "DRIVING_SPEED"
              ],
            drivingTimePolicy:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "DRIVING_TIME_POLICY"
              ]["time"],
            ringToneRed:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "DRIVING_TIME_POLICY"
              ]["audio"],
            ringToneYellowVCD:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "DRIVING_TIME_WARNING"
              ],
            warningAudioForParkingTimeComplete:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "PARKING_END_AUDIO"
              ],
            warningAudioForNotStoppingInTime:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "PARKING_NOT_END_AUDIO"
              ],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            id: dataFromEdit["id"],
          };
        case "SEVT":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            pto_on:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "INPUT1"
              ],
            boom_on:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "INPUT2"
              ],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            id: dataFromEdit["id"],
          };

        case "AB":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            value_red:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "ALARM_ACC"
              ]["RED"],
            ringToneRed:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "ALARM_ACC"
              ]["AUDIO_POLICY"],
            value_red2:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "ALARM_BRAKE"
              ]["RED"],
            ringToneRed2:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
                "ALARM_BRAKE"
              ]["AUDIO_POLICY"],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            id: dataFromEdit["id"],
          };

        default:
          return initialValuesPolicy;
      }

    default:
      return initialValuesPolicy;
  }
};

const selectValidationSchema = (yup) => {
  return yup.object().shape({
    policyType: yup.string().required("กรุณาเลือกประเภทข้อมูล"),
    dataType: yup.string().required("กรุณาเลือกประเภทข้อมูล"),
    name: yup.string().required("กรุณากรอกชื่อ"),
    ringToneYellowVCD: yup.array().of(
      yup.object().shape({
        time: yup
          .string()
          .required("กรุณาเลือกเวลา")
          .nullable(),
        audio: yup
          .string()
          .required("กรุณาเลือกเสียง")
          .nullable(),
      })
    ),
    ringToneRed: yup.string().required("กรุณาเลือกเสียง"),
  });
};

const resetFormHelper = (setFieldValue) => {
  const initialValues = {
    value_yellow: "",
    value_red: "",
    value_red2: "",
    timepicker: "00:00:00",
    timepicker_red: "00:00:00",
    period: "1",
    vehicleGroup: "all",
    vehicle: "",
    vehicleSelected: [],
    startTimePickerNight: "19:00",
    endTimePickerNight: "06:00",
    zone: null,
    stop_point: "-",
    locationType: "INSIDE",
    typeLocation: [],
    day_duration: 0,
    day_duration_red: 0,
    BREAK_WARNING_TIME: "2",
    INTERVAL_WARNING: "2",
    INTERVAL_POLICY: "2",
    ringToneYellow: "",
    ringToneRed: "",
    ringToneRed2: "",
    ringToneYellowVCD: [
      {
        audio: "",
        time: "5",
      },
    ],
    min_temp: "0",
    max_temp: "10",
    wigp_start_time: "00:00:00",
    wigp_end_time: "00:00:00",
    wltl_dur: "10",
    wltd_dur: "1",
    wltd_distance: "10",
    pto_on: "0",
    boom_on: "0",
    parkingTime: "00:30:00",
    approachRange: "",
    drivingSpeed: "5",
    drivingTimePolicy: "7",
    warningAudioForParkingTimeComplete: "",
    warningAudioForNotStoppingInTime: "",
    geofence: null,
  };

  Object.keys(initialValues).forEach((field) => {
    setFieldValue(field, initialValues[field]);
  });
};

export { checkInitialValues, resetFormHelper, selectValidationSchema };
