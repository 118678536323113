import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getlocalstorage } from "./../../../constants/local_storage";
//import { push } from "connected-react-router";

import * as actions from "./actions";
import moment from "moment";

function* loadMaintenancedeviceconclude(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );
  const USER_ID = getlocalstorage(localStorage.getItem("profile"), "id");

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  const {
    data: {
      type,
      detailproblem,
      accessory,
      eventdate,
      technician_id,
      maintenance_status,
      type_file
    }
  } = action.payload;
  const date_start = moment(eventdate[0]["_d"]).format("DD/MM/YYYY");
  const date_end = moment(eventdate[1]["_d"]).format("DD/MM/YYYY");

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_LOGIN}/service/report/maintenancedeviceconclude`,
      {
        date_start,
        date_end,
        type,
        detailproblem,
        accessory,
        technician_id,
        maintenance_status,
        company_id: COMPANY_ID,
        user_id: USER_ID,
        type_file,
        vehicle_visibility
      }
    );

    yield put(actions.loadMaintenancedeviceconclude.success(res.data));
    window.location = res.data.data;
  } catch (err) {
    yield put(actions.loadMaintenancedeviceconclude.failure());
  }
}

function* loadCompany(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getcompany`,
      {},
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` }
      }
    );
    yield put(actions.loadCompany.success(res.data));
  } catch (err) {
    yield put(actions.loadCompany.failure());
  }
}

function* loadMaintenancetype(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getmaintenancetype`,
      {},
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` }
      }
    );
    yield put(actions.loadMaintenancetype.success(res.data));
  } catch (err) {
    yield put(actions.loadMaintenancetype.failure());
  }
}

function* loadTechnician(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/gettechnician`,
      {},
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` }
      }
    );
    yield put(actions.loadTechnician.success(res.data));
  } catch (err) {
    yield put(actions.loadTechnician.failure());
  }
}

function* loadMaintenancestatus(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getmaintenancestatus`,
      {},
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` }
      }
    );
    yield put(actions.loadMaintenancestatus.success(res.data));
  } catch (err) {
    yield put(actions.loadMaintenancestatus.failure());
  }
}

function* loadMasterdetailproblem(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getmasterdetailproblem`,
      {},
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` }
      }
    );
    yield put(actions.loadMasterdetailproblem.success(res.data));
  } catch (err) {
    yield put(actions.loadMasterdetailproblem.failure());
  }
}

function* loadAccessory(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getaccessory`,
      {},
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` }
      }
    );
    yield put(actions.loadAccessory.success(res.data));
  } catch (err) {
    yield put(actions.loadAccessory.failure());
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(
      actions.LOAD_MAINTENANCEDEVICECONCLUDE.REQUEST,
      loadMaintenancedeviceconclude
    ),
    takeEvery(
      actions.LOAD_COMPANY_MAINTENANCEDEVICECONCLUDE.REQUEST,
      loadCompany
    ),
    takeEvery(
      actions.LOAD_MAINTENANCETYPE_MAINTENANCEDEVICECONCLUDE.REQUEST,
      loadMaintenancetype
    ),
    takeEvery(
      actions.LOAD_TECHNICIAN_MAINTENANCEDEVICECONCLUDE.REQUEST,
      loadTechnician
    ),
    takeEvery(
      actions.LOAD_MAINTENANCESTATUS_MAINTENANCEDEVICECONCLUDE.REQUEST,
      loadMaintenancestatus
    ),
    takeEvery(
      actions.LOAD_MASTERDETAILPROBLEM_MAINTENANCEDEVICECONCLUDE.REQUEST,
      loadMasterdetailproblem
    ),
    takeEvery(
      actions.LOAD_ACCESSORY_MAINTENANCEDEVICECONCLUDE.REQUEST,
      loadAccessory
    )
  ]);
}

export {
  loadMaintenancedeviceconclude,
  loadCompany,
  loadMaintenancetype,
  loadTechnician,
  loadMaintenancestatus,
  loadMasterdetailproblem,
  loadAccessory
};
