import React from "react";
import { Switch, Route } from "react-router-dom";

import Profile from "./Index";

export default () => (
  <Switch>
    <Route path="/profile" component={Profile} />
  </Switch>
);
