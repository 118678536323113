import { Button, Col, Dropdown, Icon, Row, Menu } from "antd";
import ReactDOM from "react-dom";
import { saveAs } from "file-saver";
import React, { Component, Fragment } from "react";
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG,
} from "react-component-export-image";
import {
  Brush,
  CartesianAxis,
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import mockData from "./response.json";
import { connect } from "react-redux";

import LangContext, { i18n } from "modules/shared/context/langContext";

const initialState = {
  speed: { hide: false, color: "#006ECC" },
  fuel_1: { hide: false, color: "#B70000" },
  fuel_2: { hide: false, color: "#CC9000" },
  temperature1: { hide: false, color: "#9ACC00" },
  temperature2: { hide: false, color: "#37CC00" },
  gate_1: { hide: false, color: "#00CCC4" },
  gate_2: { hide: false, color: "#1000CC" },
  engineStatus: { hide: false, color: "#7000CC" },
  // engine_status: { hide: false, color: "#7000CC" },
  pto: { hide: false, color: "#A300CC" },
  boom: { hide: false, color: "#CC00B6" },
  turn_right: { hide: false, color: "#CC0066" },
  turn_left: { hide: false, color: "#008000" },
  high_headlight: { hide: false, color: "#C0392B" },
  low_headlight: { hide: false, color: "#34495E" },
  horn: { hide: false, color: "#2C3E50" },
  brake: {
    hide: false,
    color: "#520101",
  },
  windshield_viper: {
    hide: false,
    color: "#EF77CB",
  },
  reversing_light: { hide: false, color: "#8884d8" },
  battery: { hide: false, color: "#8884d8" },
  fuel_consumption: { hide: false, color: "#FFB249" },
  vol: { hide: false, color: "#17D133" },
};

class SensorChart extends Component {
  state = initialState;
  componentRef = React.createRef();

  renderLine = () => {
    let showLine = Object.keys(this.props.chartData.data[0]).filter(
      (key) => key !== "time"
    );
    let fliterYaixs = this.generateYaxisFilter().filter((k) => {
      return k !== "engineStatus";
    });
    return showLine.map((key) => {
      if (fliterYaixs.includes(key)) {
        return (
          <Line
            key={key}
            type="monotone"
            dataKey={key}
            stroke={this.state[key].color}
            fill={this.state[key].color}
            hide={this.state[key].hide}
            yAxisId={key}
            strokeWidth={1.8}
            dot={{ strokeWidth: 3 }}
          />
        );
      }
      if (key !== "speed")
        return (
          <Line
            key={key}
            type="monotone"
            dataKey={key}
            stroke={this.state[key].color}
            hide={this.state[key].hide}
            yAxisId={key}
            strokeWidth={1.8}
            dot={false}
          />
        );
      return (
        <Line
          key={key}
          type="monotone"
          dataKey={key}
          stroke={this.state[key].color}
          hide={this.state[key].hide}
          strokeWidth={1.8}
          dot={false}
        />
      );
    });
  };

  handleFilterValues = (val, fliterArr) => {
    let keysArr = Object.entries(val);
    let fliteredValue = Object.fromEntries(
      keysArr.filter(([key, value]) => {
        return fliterArr.includes(key);
      })
    );

    return fliteredValue;
  };

  renderYaxis = () => {
    let languageYaxis = this.generateLang();
    let ignoredKey = ["time", "speed"];
    let Yaxis = Object.keys(this.props.chartData.data[0]).filter(
      (k) => !ignoredKey.includes(k)
    );
    let filterYaxis = this.generateYaxisFilter();
    return Yaxis.map((k) => {
      if (!filterYaxis.includes(k)) {
        return (
          <YAxis
            key={k}
            yAxisId={k}
            hide={this.state[k].hide}
            orientation="right"
            width={55}
            tickLine={false}
            tickCount={9}
            type="number"
            domain={[(dataMin) => dataMin.toFixed(0), "auto"]}
            allowDecimals={false}
            axisLine={false}
          >
            <Label angle={90} fill={this.state[k].color} dx={18} dy={10}>
              {languageYaxis[k]}
            </Label>
          </YAxis>
        );
      }
      return (
        <YAxis
          key={k}
          yAxisId={k}
          hide={true}
          orientation="right"
          width={55}
          tickLine={false}
          tickCount={9}
          type="number"
          domain={[-5, 20]}
          allowDecimals={false}
          axisLine={false}
        >
          <Label angle={90} fill={this.state[k].color} dx={18} dy={10}>
            {languageYaxis[k]}
          </Label>
        </YAxis>
      );
    });
  };

  generateYaxisFilter = () => {
    return [
      "engineStatus",
      "pto",
      "boom",
      "gate_1",
      "gate_2",
      "turn_right",
      "turn_left",
      "high_headlight",
      "low_headlight",
      "horn",
      "windshield_viper",
      "reversing_light",
      "brake",
    ];
  };

  handleLine = (e) => {
    this.setState({
      [e.dataKey]: {
        hide: !this.state[e.dataKey].hide,
        color: this.state[e.dataKey].color,
      },
    });
  };

  renderColorfulLegendText = (value, entry) => {
    let legendLang = this.generateLang();
    let { color } = entry;
    if (this.state[value].hide) {
      color = "#cccccc ";
    }
    return <span style={{ color }}>{legendLang[value]}</span>;
  };

  generateLang = () => {
    let languageYaxis = {
      speed: i18n[this.props.language].s.speed,
      fuel_1: i18n[this.props.language].f.fuel1,
      fuel_2: i18n[this.props.language].f.fuel2,
      temperature1: i18n[this.props.language].t.temperature1,
      temperature2: i18n[this.props.language].t.temperature2,
      gate_1: i18n[this.props.language].g.gate1,
      gate_2: i18n[this.props.language].g.gate2,
      engineStatus: i18n[this.props.language].e.engineStatus,
      pto: i18n[this.props.language].p.pto,
      boom: i18n[this.props.language].b.BOOM,
      turn_right: i18n[this.props.language].extraSetting.turn_right,
      turn_left: i18n[this.props.language].extraSetting.turn_left,
      high_headlight: i18n[this.props.language].extraSetting.highLight,
      low_headlight: i18n[this.props.language].extraSetting.lowLight,
      horn: i18n[this.props.language].extraSetting.horn,
      brake: i18n[this.props.language].b.brake,
      windshield_viper: i18n[this.props.language].w.windshield_viper,
      reversing_light: i18n[this.props.language].r.reversing_light,
      battery: i18n[this.props.language].b.battery_samrong,
      vehicle: i18n[this.props.language].p.plateNo,
      date: i18n[this.props.language].d.date,
      trip: i18n[this.props.language].t.tip_code,
      driver: i18n[this.props.language].d.driver,
      fuel_consumption: i18n[this.props.language].f.fuel_consumption,
      vol: i18n[this.props.language].b.battery_car,
    };
    return languageYaxis;
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.loading !== this.props.loading &&
      this.props.loading === false
    ) {
      this.setState({ ...initialState });
    }
  }

  exportChart = () => {
    let chartSVG = ReactDOM.findDOMNode(this.currentChart).children[0];
    let svgURL = new XMLSerializer().serializeToString(chartSVG);
    let svgBlob = new Blob([svgURL], {
      type: "image/svg+xml;charset=utf-8",
    });

    saveAs(svgBlob, "Chart.svg");
  };

  generateMenu = () => {
    return (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="png">Download PNG image</Menu.Item>
        <Menu.Item key="jpeg">Download JPEG image</Menu.Item>
        <Menu.Item key="pdf">Download PDF Document</Menu.Item>
        <Menu.Item key="svg">Download SVG Document</Menu.Item>
      </Menu>
    );
  };

  handleMenuClick = (e) => {
    switch (e.key) {
      case "png":
        exportComponentAsPNG(this.componentRef, {
          fileName: "Chart",
        });
        break;
      case "pdf":
        exportComponentAsPDF(this.componentRef, {
          fileName: "Chart",
          pdfOptions: {
            unit: "px",
            w: "750",
            h: "450",
            orientation: "l",
            pdfFormat: "legal",
          },
        });
        break;
      case "jpeg":
        exportComponentAsJPEG(this.componentRef, {
          fileName: "Chart",
        });
        break;
      case "svg":
        this.exportChart();
        break;
    }
  };

  render() {
    let lang = this.generateLang();
    let intervalVal = Math.round(this.props.chartData.data.length / 34);
    let fliterYaixs = this.generateYaxisFilter();
    return (
      <LangContext.Consumer>
        {(i18n) => (
          <div>
            <Row type="flex" align="middle" justify="center">
              <Col span={22} align="right">
                <Dropdown overlay={this.generateMenu()}>
                  <Button>
                    <Icon type="download" />
                  </Button>
                </Dropdown>
              </Col>
            </Row>

            <Row
              type="flex"
              align="middle"
              justify="center"
              ref={(chart) => (this.currentChart = chart)}
            >
              <Col
                span={24}
                style={{ textAlign: "center" }}
                ref={this.componentRef}
              >
                <h1
                  style={{
                    color: "#3E576F",
                    fontSize: "16px",
                  }}
                >{`${lang[this.props.type]} : ${this.props.title}`}</h1>
                {this.props.type !== "trip" ? (
                  <h4
                    style={{
                      color: "#6D869F",
                      fontSize: "12px",
                    }}
                  >{`${lang["date"]} : ${this.props.date}`}</h4>
                ) : null}
                <ResponsiveContainer height={700}>
                  <LineChart
                    data={this.props.chartData.data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <Brush
                      onChange={(e) => this.setState({ e })}
                      startIndex={
                        this.state.startIndex ? this.state.startIndex : 0
                      }
                      endIndex={this.state.endIndex ? this.state.endIndex : 0}
                    />

                    <CartesianGrid vertical={false} strokeDasharray="1" />
                    <XAxis
                      dataKey="time"
                      height={140}
                      tickMargin={20}
                      interval={intervalVal}
                      angle={-50}
                      dy={40}
                      dx={-50}
                      minTickGap={20}
                      domain={[this.state.left, this.state.right]}
                    />
                    <XAxis dataKey={120} />
                    <YAxis
                      axisLine={false}
                      dataKey="speed"
                      orientation="left"
                      width={90}
                      label={{
                        value: lang["speed"],
                        angle: -90,
                        fill: this.state.speed.color,
                        fontSize: 15,
                      }}
                      tickCount={9}
                      allowDecimals={false}
                      domain={[0, 160]}
                      type="number"
                    />
                    <ReferenceLine
                      y={this.props.chartData.speedLimit2}
                      stroke="red"
                      strokeDasharray="3 3"
                    >
                      <Label position="insideBottomLeft" dx={10}>
                        {`${this.props.chartData.speedLimit2} km/hr`}
                      </Label>
                    </ReferenceLine>
                    {this.renderYaxis()}
                    <Tooltip
                      formatter={(value, name) => {
                        if (
                          fliterYaixs.includes(name) &&
                          value !== null &&
                          value === 0
                        ) {
                          return ["On", lang[name]];
                        } else if (name === "speed") {
                          return [value, lang[name]];
                        } else if (
                          name === "engineStatus" ||
                          name === "gate_1" ||
                          name === "gate_2"
                        ) {
                          return value === 1
                            ? ["On", lang[name]]
                            : ["Off", lang[name]];
                        } else if (name === "pto") {
                          return value + 100
                            ? ["On", lang[name]]
                            : ["Off", lang[name]];
                        } else if (name === "boom") {
                          return value + 120
                            ? ["On", lang[name]]
                            : ["Off", lang[name]];
                        } else if (
                          name === "turn_right" ||
                          name === "turn_left" ||
                          name === "low_headlight" ||
                          name === "high_headlight" ||
                          name === "horn" ||
                          name === "brake" ||
                          name === "windshield_viper" ||
                          name === "reversing_light"
                        ) {
                          return value === 1
                            ? ["Off", lang[name]]
                            : ["On", lang[name]];
                        } else {
                          return [value, lang[name]];
                        }
                      }}
                    />

                    <Legend
                      onClick={this.handleLine}
                      formatter={this.renderColorfulLegendText}
                    />
                    {this.renderLine()}
                  </LineChart>
                </ResponsiveContainer>
              </Col>
            </Row>
          </div>
        )}
      </LangContext.Consumer>
    );
  }
}

const mapStateToProps = ({ ui: { language } }) => ({
  language,
});

export default connect(mapStateToProps, {})(SensorChart);
