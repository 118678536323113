import { Button, Col, Dropdown, Icon, Menu, Result, Row, Spin } from "antd";

import { connect } from "react-redux";
import * as actions from "../actions";
import ReactDOM from "react-dom";
import React, { Component, Fragment, useRef } from "react";
import { StyledSearchForm } from "../../../../styled/common-styled";
import Form from "./Form";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";
//./../../../auth/components/AuthorizeComponent
import SensorChart from "./SensorChart";

import Axios from "axios";
import Breadcrumb from "../../../shared/components/Breadcrumb";

class Index extends Component {
  state = {
    chart_data: {
      speedLimit1: 0,
      speedLimit2: 0,
      data: [],
    },
    loading: false,
    received_chart_data: false,
    no_chart_data: false,
    title: "",
    date: "",
    type: "",
  };

  handleFormatData = (data) => {
    if (data.time.length === 0) {
      return {
        speedLimit1: 0,
        speedLimit2: 0,
        data: [],
      };
    }

    // Check if pto and boom exist
    if (data.sensorsValue.pto && Array.isArray(data.sensorsValue.pto)) {
      data.sensorsValue.pto = data.sensorsValue.pto.map((ptoValue) => {
        if (ptoValue == 1) {
          return ptoValue + 12;
        } else {
          return ptoValue;
        }
      });
    }

    if (data.sensorsValue.boom && Array.isArray(data.sensorsValue.boom)) {
      data.sensorsValue.boom = data.sensorsValue.boom.map((boomValue) => {
        if (boomValue == 1) {
          return boomValue + 9;
        } else {
          return boomValue;
        }
      });
    }

    let dataSensors = [];
    let decon = Object.entries(data.sensorsValue);

    let newData = {
      time: data.time,
      speed: data.velocity,
    };

    decon.map((v) => {
      let startIndex = 0;
      if (v[0] == "fuel_consumption") {
        startIndex = 1;
      }
      let hasValue = this.checkArrayHasValue(v[1], startIndex);
      if (hasValue) {
        newData[v[0]] = v[1];
      }
    });

    let dataKeys = Object.keys(newData);
    for (let i = 0; i < newData.time.length; i++) {
      let obj = {};
      dataKeys.map((v) => {
        obj[v] = newData[v][i];
        return true;
      });
      dataSensors.push(obj);
    }

    let temp = {};
    temp["speedLimit1"] = data.speedLimit1;
    temp["speedLimit2"] = data.speedLimit2;
    temp["data"] = dataSensors;

    console.log(temp, "temp");
    return temp;
  };

  handleTitle = (val) => {
    if (val.type === "vehicle") return val.vehicle_code;
    if (val.type === "driver") return val.driver_name;
    if (val.type === "trip") return val.trip_code;
  };

  checkArrayHasValue = (value, startIndex = 0) => {
    let hasValue = false;
    for (let i = startIndex; i < value.length; i++) {
      if (value[i] != null) {
        hasValue = true;
        break;
      }
    }
    return hasValue;
  };

  handleFormSubmit = async (value) => {
    // console.log(value, "value");
    let newData = Object.assign({}, value);
    // console.log(newData, "newData");
    newData["vehicle_visibility"] = this.props.auth.profile.vehicle_visibility;
    newData["company_id"] = this.props.auth.profile.company_id;
    this.setState(
      {
        type: value.type,
        title: this.handleTitle(value),
        date: `${value.date_start} ${value.date_end}`,
        loading: true,
      },
      () => {
        this.props.loadGraphsensors(newData, this.props.auth.accessToken);
      }
    );
  };

  RenderChart = () => {
    if (this.state.received_chart_data) {
      if (this.state.chart_data.data.length > 0) {
        return (
          <Row type="flex" justify="center">
            <Col span={22} style={{ padding: "10px" }}>
              <SensorChart
                chartData={this.state.chart_data}
                title={this.state.title}
                date={this.state.date}
                type={this.state.type}
                loading={this.state.loading}
              />
            </Col>
          </Row>
        );
      } else {
        return (
          <Row type="flex" justify="center">
            <Col span={15}>
              <Result title="No data at this time."></Result>
            </Col>
          </Row>
        );
      }
    }
    return <></>;
  };
  componentDidUpdate = (prevProps) => {
    const { data, loading } = this.props.graphsensor;

    if (Array.isArray(data)) {
      return;
    }

    if (loading !== prevProps.graphsensor.loading && !loading) {
      //console.log("data", data);
      let items = this.handleFormatData(data);

      console.log(items, "items");

      this.setState({
        chart_data: items,
        received_chart_data: true,
        loading: false,
      });
      return;
    }
  };

  render() {
    return (
      <AuthorizeComponent matching_name="graph_sensor">
        <div>
          <Spin spinning={this.props.graphsensor.loading}>
            <StyledSearchForm style={{ minHeight: "calc(100vh - 46px)" }}>
              <Row gutter={24}>
                <Col span={24}>
                  <Breadcrumb
                    match={this.props.match}
                    style={{
                      margin: "0px 0px 14px 10px",
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form
                    onSubmit={this.handleFormSubmit}
                    auth={this.props.auth}
                  />
                </Col>
              </Row>

              {this.RenderChart()}
            </StyledSearchForm>
          </Spin>
        </div>
      </AuthorizeComponent>
    );
  }
}
const mapStateToProps = ({ auth, graphsensor }) => ({
  auth,
  graphsensor,
});

const mapDispatchToProps = {
  loadGraphsensors: actions.loadGraphsensors.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
