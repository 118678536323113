import { createTypes, createAction } from "lib/action";

const LOAD_FLOW_REPORT = createTypes("load_flow_report", "load");

const loadFlowReport = {
  request: (obj) => createAction(LOAD_FLOW_REPORT.REQUEST, { obj }),
  success: (data) => createAction(LOAD_FLOW_REPORT.SUCCESS, { data }),
  failure: () => createAction(LOAD_FLOW_REPORT.FAILURE),
};

export { loadFlowReport, LOAD_FLOW_REPORT };
