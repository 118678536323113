import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getlocalstorage } from "./../../../constants/local_storage";

import * as actions from "./actions";

function* loadListVehicleHoneytoast(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  const {
    data: { list_vehicle_id, vehicle_status, orderBy, orderType, device_type },
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlistvehiclehoneytoast`,
      {
        vehicle_visibility,
        list_vehicle_id,
        vehicle_status,
        orderBy,
        orderType,
        device_type,
        company_id: COMPANY_ID,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadListVehicleHoneytoast.success(res.data));
  } catch (err) {
    yield put(actions.loadListVehicleHoneytoast.failure());
  }
}

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlistvehiclehoneytoast`,
      {
        vehicle_visibility,
        company_id: COMPANY_ID,
        list_vehicle_id: "",
        orderBy: "plate_no",
        orderType: "asc",
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadVehicle.success(res.data));
  } catch (err) {
    yield put(actions.loadVehicle.failure());
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(
      actions.LOAD_LISTVEHICLE_HONEYTOASTSTREAM.REQUEST,
      loadListVehicleHoneytoast
    ),
    takeEvery(actions.LOAD_VEHICLE_HONEYTOASTSTREAM.REQUEST, loadVehicle),
  ]);
}

export { loadListVehicleHoneytoast, loadVehicle };
