import {
    LOAD_SERVICEINSTALLDEVICE,
    LOAD_PROVINCE,
    LOAD_AMPHUR,
    CREATE,
    REMOVE_LISTS
} from "./actions";

const initialState = {
    lists: [],
    total: 1,
    loading: false,
    ProvinceLists: [],
    AmphurLists: [],
    detailloading: false,
    check: true,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REMOVE_LISTS:
            return initialState;

        case LOAD_SERVICEINSTALLDEVICE.REQUEST:
            const { loading } = action.payload.data;
            return {
                ...state,
                loading: loading,
            }

        case LOAD_SERVICEINSTALLDEVICE.SUCCESS:
            const { page } = action.payload;
                       
            for (let i = 0; i < action.payload.lists.data.length; i++) {
                    action.payload.lists.data[i].rowNumber = i + 1;
                        if (page > 1)
                            action.payload.lists.data[i].rowNumber =
                                action.payload.lists.data[i].rowNumber + (page - 1) * 10;
            }
            return {
                ...state,
                lists: action.payload.lists.data,
                total: 
                    action.payload.lists.total - 1 <= 0
                    ? 1
                    : action.payload.lists.total <= 0
                    ? 1
                    : action.payload.lists.total,
                    loading: false,
                draw: Math.floor(Math.random() * 999 + 1)
            };

        case LOAD_PROVINCE.SUCCESS:
            return {
                ...state,
                ProvinceLists: action.payload.ProvinceLists.data
            };
    
    
        case LOAD_AMPHUR.SUCCESS:
            return {
                ...state,
                AmphurLists: action.payload.AmphurLists.data
            };

        case CREATE.SUCCESS:
            return {
                ...state,
                loading: false
            }

        default:
            return state;
    }
}