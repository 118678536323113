import React, { useState, useEffect } from "react";
import {
  Button,
  message,
  Col,
  Form,
  Select,
  Divider,
  Input,
  TimePicker,
  Transfer,
} from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
import moment from "moment";
import { loadZoneByCompony, getAudioList } from "../../../fetchAPI/apiClient";
import { Row } from "antd/es/grid";
import ControlledAudio from "./ControlledAudio";
import { resetFormHelper } from "../../../components/Form/Helper/Helper";
const { Item } = Form;
const FormItem = Item;
const Option = Select.Option;
const format = "HH:mm:ss";

const SpeedLimit = ({
  setFieldValue,
  values,
  touched,
  errors,
  subPolicyType,
  current,
  steps,
  prev,
  companyId,
  type,
  setIsDataType,
}) => {
  const [zone, setZone] = useState([]);
  const [audioList, setAudioList] = useState([]);
  // const [audioKeyYellow, setaudioKeyYellow] = useState(0); // State to force re-render of audio tag
  const [currentAudioSrcYellow, setcurrentAudioSrcYellow] = useState("");
  // const [audioKeyRed, setaudioKeyRed] = useState(0); // State to force re-render of audio tag
  const [currentAudioSrcRed, setcurrentAudioSrcRed] = useState("");
  const [currentAudioSrcRed2, setcurrentAudioSrcRed2] = useState("");

  useEffect(() => {
    // Find the selected audio item based on ringToneRed value
    if (values.ringToneRed && audioList.length > 0) {
      const selectedAudio = audioList.find(
        (item) => item.id === values.ringToneRed
      );
      if (selectedAudio) {
        setcurrentAudioSrcRed(selectedAudio.link_file);
        // Increment key to force re-render of audio tag
        // setaudioKeyRed((prevKey) => prevKey + 1);
      }
    }
    if (values.ringToneRed == "") {
      setcurrentAudioSrcRed("");
    }
  }, [values.ringToneRed, audioList]);

  useEffect(() => {
    if (values.ringToneYellow && audioList.length > 0) {
      const selectedAudio = audioList.find(
        (item) => item.id === values.ringToneYellow
      );
      if (selectedAudio) {
        setcurrentAudioSrcYellow(selectedAudio.link_file);
        // Increment key to force re-render of audio tag
        // setaudioKeyYellow((prevKey) => prevKey + 1);
      }
    }
    if (values.ringToneYellow == "") {
      setcurrentAudioSrcYellow("");
    }
  }, [values.ringToneYellow, audioList]);

  useEffect(() => {
    // Find the selected audio item based on ringToneRed value
    if (values.ringToneRed2 && audioList.length > 0) {
      const selectedAudio = audioList.find(
        (item) => item.id === values.ringToneRed2
      );
      if (selectedAudio) {
        setcurrentAudioSrcRed2(selectedAudio.link_file);
        // Increment key to force re-render of audio tag
        // setaudioKeyRed((prevKey) => prevKey + 1);
      }
    }
    if (values.ringToneRed == "") {
      setcurrentAudioSrcRed2("");
    }
  }, [values.ringToneRed2, audioList]);

  useEffect(() => {
    prepairSpeedLimitData();
  }, []);

  const prepairSpeedLimitData = () => {
    loadZoneByCompony(
      {
        company_id: companyId,
      },
      (status, response) => {
        if (response.status == 200) {
          setZone(response.data.data);
          if (response.data.data.length > 0 && type != "edit") {
            setFieldValue("zone", response.data.data[0]["id"]);
            setFieldValue("geofence", response.data.data[0]["geofence"]);
          }
        }
      }
    );
    getAudioList(
      {
        company_id: companyId,
      },
      (status, response) => {
        if (response.status == 200) {
          setAudioList(response.data);
        }
      }
    );
  };

  return (
    <>
      <FormItem
        label={
          <LangContext.Consumer>
            {(i18n) => i18n.d.dataType}
          </LangContext.Consumer>
        }
        required={true}
        validateStatus={touched.dataType && errors.dataType && "error"}
        help={touched.dataType && errors.dataType}
      >
        <Select
          style={{ width: "100%" }}
          placeholder="กรุณาเลือก"
          optionFilterProp="children"
          onChange={(e) => {
            setIsDataType(e);
            setFieldValue("dataType", e);
            resetFormHelper(setFieldValue);
          }}
          name="dataType"
          value={values.dataType}
          disabled={type == "edit" ? true : false}
        >
          {subPolicyType.map((item) => (
            <Option value={item.id}>
              <LangContext.Consumer>
                {(i18n) => i18n.policy[item.name]}
              </LangContext.Consumer>
            </Option>
          ))}
        </Select>
      </FormItem>
      {values.dataType === "N" ? (
        <FormItem
          label={
            <LangContext.Consumer>{(i18n) => i18n.t.time}</LangContext.Consumer>
          }
          required={true}
          validateStatus={touched.time && errors.time && "error"}
          help={touched.time && errors.time}
        >
          <TimePicker
            value={moment(values.startTimePickerNight, format)}
            format={format}
            onChange={(time, timeString) => {
              setFieldValue("startTimePickerNight", timeString);
            }}
            allowClear={false}
            style={{ width: 120 }}
            disabled={type == "edit" ? true : false}
          />{" "}
          -{" "}
          <TimePicker
            value={moment(values.endTimePickerNight, format)}
            format={format}
            style={{ width: 120 }}
            onChange={(time, timeString) => {
              setFieldValue("endTimePickerNight", timeString);
            }}
            disabled={type == "edit" ? true : false}
            allowClear={false}
          />
        </FormItem>
      ) : null}
      {values.dataType === "RZ" ||
      values.dataType === "GZ" ||
      values.dataType === "SZ" ? (
        <FormItem
          label={
            <LangContext.Consumer>{(i18n) => i18n.z.zone}</LangContext.Consumer>
          }
          required={true}
          validateStatus={touched.time && errors.time && "error"}
          help={touched.time && errors.time}
        >
          <Select
            style={{ width: "100%" }}
            onChange={(e, option) => {
              setFieldValue("zone", e);
              setFieldValue("geofence", option.props.geofence);
            }}
            value={values.zone}
            disabled={type == "edit" ? true : false}
          >
            {zone.map((item) => (
              <Option value={item.id} geofence={item.geofence}>
                {item.name}
              </Option>
            ))}
          </Select>
        </FormItem>
      ) : null}

      <FormItem>
        <label
          style={{
            fontSize: 20,
            fontWeight: "bold",
          }}
        >
          เงื่อนไข
        </label>
        <Divider style={{ margin: "10px 0px", width: "100%" }} />
      </FormItem>
      <Row gutter={[8, 8]}>
        {values.dataType !== "RZ" &&
        values.dataType !== "GZ" &&
        values.dataType !== "AB" ? (
          <Col
            span={
              values.dataType === "D" ? 8 : values.dataType === "SZ" ? 12 : 24
            }
          >
            <FormItem
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.s.speedToAlertTheDriver}
                </LangContext.Consumer>
              }
              validateStatus={
                touched.value_yellow && errors.value_yellow && "error"
              }
              help={touched.value_yellow && errors.value_yellow}
            >
              <Input
                className={"policy-new-alarm-input"}
                placeholder="โปรดระบุความเร็ว"
                onChange={(e) => {
                  const { value } = e.target;
                  const reg = /^-?[0-9]*(\.[0-9]*)?$/;
                  if ((!isNaN(value) && reg.test(value)) || value === "") {
                    setFieldValue("value_yellow", value);
                  }
                }}
                value={values.value_yellow}
                maxLength={25}
                style={{ width: "100%" }}
              />
            </FormItem>
          </Col>
        ) : null}
        {values.dataType === "D" ? (
          <Col span={8}>
            <FormItem
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.p.periodNotice}
                </LangContext.Consumer>
              }
              validateStatus={
                touched.BREAK_WARNING_TIME &&
                errors.BREAK_WARNING_TIME &&
                "error"
              }
              help={touched.BREAK_WARNING_TIME && errors.BREAK_WARNING_TIME}
            >
              <Select
                value={values.BREAK_WARNING_TIME}
                onChange={(e) => {
                  setFieldValue("BREAK_WARNING_TIME", e);
                }}
              >
                <Option value="1">ทันทีที่ฝ่าฝืน</Option>
                <Option value="2">5 วินาที</Option>
                <Option value="3">10 วินาที</Option>
                <Option value="4">30 วินาที</Option>
              </Select>
            </FormItem>
          </Col>
        ) : null}
        {values.dataType === "D" || values.dataType === "SZ" ? (
          <Col span={values.dataType === "SZ" ? 12 : 8}>
            <FormItem
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.d.distanceNotice}
                </LangContext.Consumer>
              }
              validateStatus={
                touched.INTERVAL_WARNING && errors.INTERVAL_WARNING && "error"
              }
              help={touched.INTERVAL_WARNING && errors.INTERVAL_WARNING}
            >
              <Select
                value={values.INTERVAL_WARNING}
                onChange={(e) => {
                  setFieldValue("INTERVAL_WARNING", e);
                }}
              >
                <Option value="1">30 วินาที</Option>
                <Option value="2">60 วินาที</Option>
                <Option value="3">120 วินาที</Option>
              </Select>
            </FormItem>
          </Col>
        ) : null}
        {values.dataType === "D" || values.dataType === "SZ" ? (
          <Col span={12}>
            <FormItem
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.r.ringTone}
                </LangContext.Consumer>
              }
              validateStatus={
                touched.ringToneYellow && errors.ringToneYellow && "error"
              }
              help={touched.ringToneYellow && errors.ringToneYellow}
            >
              <Select
                value={values.ringToneYellow}
                onChange={(e) => {
                  setFieldValue("ringToneYellow", e);
                }}
              >
                <Option key={""} value={""}>
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.disableNotification}
                  </LangContext.Consumer>
                </Option>
                {audioList &&
                  audioList.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </FormItem>
          </Col>
        ) : null}
        {audioList.length > 0 &&
          (values.dataType === "D" || values.dataType === "SZ" ? (
            <Col span={12}>
              <FormItem
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.s.soundPreview}
                  </LangContext.Consumer>
                }
              >
                <ControlledAudio
                  // key={audioKeyYellow} // Key to force re-mount/update of audio tag
                  src={currentAudioSrcYellow}
                  style={{ height: "30px", paddingTop: 7, width: "100%" }}
                />
              </FormItem>
            </Col>
          ) : null)}

        {values.dataType === "AB" ? (
          <>
            <Col span={8}>
              <FormItem
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.a.accRedPolicy}
                  </LangContext.Consumer>
                }
                validateStatus={
                  touched.value_red && errors.value_red && "error"
                }
                help={touched.value_red && errors.value_red}
              >
                <Input
                  className={"policy-new-alert-input"}
                  placeholder="โปรดระบุความเร่ง"
                  onChange={(e) => {
                    const { value } = e.target;
                    const reg = /^-?[0-9]*(\.[0-9]*)?$/;
                    if ((!isNaN(value) && reg.test(value)) || value === "") {
                      setFieldValue("value_red", value);
                    }
                  }}
                  value={values.value_red}
                  maxLength={25}
                  style={{ width: "100%" }}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.r.ringTone}
                  </LangContext.Consumer>
                }
                validateStatus={
                  touched.ringToneRed && errors.ringToneRed && "error"
                }
                help={touched.ringToneRed && errors.ringToneRed}
              >
                <Select
                  value={values.ringToneRed}
                  onChange={(e) => {
                    setFieldValue("ringToneRed", e);
                  }}
                >
                  <Option key={""} value={""}>
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.disableNotification}
                    </LangContext.Consumer>
                  </Option>
                  {audioList &&
                    audioList.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </FormItem>
            </Col>
            {audioList.length > 0 &&
              (values.dataType === "AB" ? (
                <Col span={8}>
                  <FormItem
                    label={
                      <LangContext.Consumer>
                        {(i18n) => i18n.s.soundPreview}
                      </LangContext.Consumer>
                    }
                  >
                    <ControlledAudio
                      src={currentAudioSrcRed}
                      style={{ height: "30px", paddingTop: 7, width: "100%" }}
                    />
                  </FormItem>
                </Col>
              ) : null)}
            <Col span={8}>
              <FormItem
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.b.brakeRedPolicy}
                  </LangContext.Consumer>
                }
                validateStatus={
                  touched.value_red2 && errors.value_red2 && "error"
                }
                help={touched.value_red2 && errors.value_red2}
              >
                <Input
                  className={"policy-new-alert-input"}
                  style={{ width: "100%" }}
                  name="value_red2"
                  placeholder="โปรดระบุเบรค"
                  onChange={(e) => {
                    const { value } = e.target;
                    const reg = /^-?[0-9]*(\.[0-9]*)?$/;
                    if ((!isNaN(value) && reg.test(value)) || value === "") {
                      setFieldValue("value_red2", value);
                    }
                  }}
                  value={values.value_red2}
                  maxLength={25}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.r.ringTone}
                  </LangContext.Consumer>
                }
                validateStatus={
                  touched.ringToneRed2 && errors.ringToneRed2 && "error"
                }
                help={touched.ringToneRed2 && errors.ringToneRed2}
              >
                <Select
                  value={values.ringToneRed2}
                  onChange={(e) => {
                    setFieldValue("ringToneRed2", e);
                  }}
                >
                  <Option key={""} value={""}>
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.disableNotification}
                    </LangContext.Consumer>
                  </Option>
                  {audioList &&
                    audioList.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </FormItem>
            </Col>
            {audioList.length > 0 &&
              (values.dataType === "AB" ? (
                <Col span={8}>
                  <FormItem
                    label={
                      <LangContext.Consumer>
                        {(i18n) => i18n.s.soundPreview}
                      </LangContext.Consumer>
                    }
                  >
                    <ControlledAudio
                      src={currentAudioSrcRed2}
                      style={{ height: "30px", paddingTop: 7, width: "100%" }}
                    />
                  </FormItem>
                </Col>
              ) : null)}
          </>
        ) : null}
      </Row>

      <Row gutter={[8, 8]}>
        {values.dataType !== "RZ" &&
        values.dataType !== "GZ" &&
        values.dataType !== "AB" ? (
          <Col
            span={
              values.dataType === "D" ? 8 : values.dataType === "SZ" ? 12 : 24
            }
          >
            <FormItem
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.w.wrongSpeedAccordingToPolicy}
                </LangContext.Consumer>
              }
              validateStatus={touched.value_red && errors.value_red && "error"}
              help={touched.value_red && errors.value_red}
            >
              <Input
                className={"policy-new-alert-input"}
                style={{ width: "100%" }}
                name="value_red"
                placeholder="โปรดระบุความเร็ว"
                onChange={(e) => {
                  const { value } = e.target;
                  const reg = /^-?[0-9]*(\.[0-9]*)?$/;
                  if ((!isNaN(value) && reg.test(value)) || value === "") {
                    setFieldValue("value_red", value);
                  }
                }}
                value={values.value_red}
                maxLength={25}
              />
            </FormItem>
          </Col>
        ) : null}
        {values.dataType === "D" || values.dataType === "SZ" ? (
          <Col span={values.dataType === "SZ" ? 12 : 16}>
            <FormItem
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.d.distanceNotice}
                </LangContext.Consumer>
              }
              validateStatus={
                touched.INTERVAL_POLICY && errors.INTERVAL_POLICY && "error"
              }
              help={touched.INTERVAL_POLICY && errors.INTERVAL_POLICY}
            >
              <Select
                value={values.INTERVAL_POLICY}
                onChange={(e) => {
                  setFieldValue("INTERVAL_POLICY", e);
                }}
              >
                <Option value="1">10 วินาที</Option>
                <Option value="2">30 วินาที</Option>
                <Option value="3">60 วินาที</Option>
              </Select>
            </FormItem>
          </Col>
        ) : null}
        {values.dataType === "D" || values.dataType === "SZ" ? (
          <Col span={12}>
            <FormItem
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.r.ringTone}
                </LangContext.Consumer>
              }
              validateStatus={
                touched.ringToneRed && errors.ringToneRed && "error"
              }
              help={touched.ringToneRed && errors.ringToneRed}
            >
              <Select
                value={values.ringToneRed}
                onChange={(e) => {
                  setFieldValue("ringToneRed", e);
                }}
              >
                <Option key={""} value={""}>
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.disableNotification}
                  </LangContext.Consumer>
                </Option>
                {audioList &&
                  audioList.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </FormItem>
          </Col>
        ) : null}
        {audioList.length > 0 &&
          (values.dataType === "D" || values.dataType === "SZ" ? (
            <Col span={12}>
              <FormItem
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.s.soundPreview}
                  </LangContext.Consumer>
                }
              >
                <ControlledAudio
                  // key={audioKeyRed} // Key to force re-mount/update of audio tag
                  src={currentAudioSrcRed}
                  style={{ height: "30px", paddingTop: 7, width: "100%" }}
                />
              </FormItem>
            </Col>
          ) : null)}
        {values.dataType === "SZ" ? (
          <>
            <Col span={24}>
              <FormItem
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.a.approachRange}
                  </LangContext.Consumer>
                }
                validateStatus={
                  touched.approachRange && errors.approachRange && "error"
                }
                help={touched.approachRange && errors.approachRange}
              >
                <Input
                  style={{ width: "100%" }}
                  name="approachRange"
                  placeholder="โปรดระบุความเร็ว"
                  onChange={(e) => {
                    const { value } = e.target;
                    const reg = /^-?[0-9]*(\.[0-9]*)?$/;
                    if ((!isNaN(value) && reg.test(value)) || value === "") {
                      setFieldValue("approachRange", value);
                    }
                  }}
                  value={values.approachRange}
                  maxLength={25}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.r.ringTone}
                  </LangContext.Consumer>
                }
                validateStatus={
                  touched.ringToneRed2 && errors.ringToneRed2 && "error"
                }
                help={touched.ringToneRed2 && errors.ringToneRed2}
              >
                <Select
                  value={values.ringToneRed2}
                  onChange={(e) => {
                    setFieldValue("ringToneRed2", e);
                  }}
                >
                  <Option key={""} value={""}>
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.disableNotification}
                    </LangContext.Consumer>
                  </Option>
                  {audioList &&
                    audioList.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.s.soundPreview}
                  </LangContext.Consumer>
                }
              >
                <ControlledAudio
                  src={currentAudioSrcRed2}
                  style={{ height: "30px", paddingTop: 7, width: "100%" }}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <Item
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.s.startAt}
                  </LangContext.Consumer>
                }
                validateStatus={
                  touched.wigp_start_time && errors.wigp_start_time && "error"
                }
                help={touched.wigp_start_time && errors.wigp_start_time}
              >
                <TimePicker
                  value={moment(values.wigp_start_time, format)}
                  format={format}
                  onChange={(time, timeString) => {
                    setFieldValue("wigp_start_time", timeString);
                  }}
                  allowClear={false}
                />{" "}
                <span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.p.policyPeriodFormat}
                  </LangContext.Consumer>
                </span>
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.e.endAt}
                  </LangContext.Consumer>
                }
                validateStatus={
                  touched.wigp_end_time && errors.wigp_end_time && "error"
                }
                help={touched.wigp_end_time && errors.wigp_end_time}
              >
                <TimePicker
                  value={moment(values.wigp_end_time, format)}
                  format={format}
                  onChange={(time, timeString) => {
                    setFieldValue("wigp_end_time", timeString);
                  }}
                  allowClear={false}
                />{" "}
                <span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.p.policyPeriodFormat}
                  </LangContext.Consumer>
                </span>
              </Item>
            </Col>
          </>
        ) : null}
      </Row>
      {values.dataType != "AB" ? (
        <Row gutter={[8, 8]}>
          <Col span={16}>
            <FormItem
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.p.policyPeriod}
                </LangContext.Consumer>
              }
              validateStatus={touched.period && errors.period && "error"}
              help={touched.period && errors.period}
            >
              <Select
                value={values.period.toString()}
                onChange={(e) => {
                  setFieldValue("period", e);
                  setFieldValue("timepicker", "00:00:00");
                }}
              >
                <Option value="1">ทันทีที่ฝ่าฝืน</Option>
                <Option value="2">10 วินาที</Option>
                <Option value="3">20 วินาที</Option>
                <Option value="4">30 วินาที</Option>
                <Option value="5">60 วินาที</Option>
                <Option value="6">120 วินาที</Option>
                <Option value="7">อื่นๆ</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.p.policyPeriodFormat}
                </LangContext.Consumer>
              }
              validateStatus={touched.period && errors.period && "error"}
              help={touched.period && errors.period}
            >
              <TimePicker
                style={{ width: "100%" }}
                value={moment(values.timepicker, format)}
                format={format}
                onChange={(time, timeString) => {
                  setFieldValue("timepicker", timeString);
                }}
                allowClear={false}
                disabled={values.period == "7" ? false : true}
              />
            </FormItem>
          </Col>
        </Row>
      ) : null}

      <FormItem>
        {current < steps.length - 1 && (
          <Button type="primary" htmlType="submit">
            <LangContext.Consumer>{(i18n) => i18n.n.next}</LangContext.Consumer>
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => message.success("Processing complete!")}
          >
            Done
          </Button>
        )}
        {current > 0 && (
          <Button style={{ marginLeft: 8 }} onClick={() => prev()}>
            Previous
          </Button>
        )}
      </FormItem>
    </>
  );
};

export default SpeedLimit;
