import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import Index from "./Index";

export default () => (
    <Router>
      <div>
        <Route exact path="/graphsensors" component={Index} />
      </div>
    </Router>
  );