import {
  LOAD_WITHDRAW_FUEL_CREDIT_HISTORY,
  LOAD_VEHICLE_CREDIT_INFO,
  UPDATE_WITHDRAW_VEHICLE_FUEL_CREDIT,
  LOAD_WITHDRAW_INFO,
  LOAD_WITHDRAW_PATH,
  UPDATE_WITHDRAW_PATH,
} from "./actions";

const initialState = {
  historylist: [],
  vehicleList: [],
  vehicleInfo: {},
  withdrawPath: "",
  withdrawInfo: {
    withdraw_info: {
      id: null,
      code: null,
      vehicle_id: null,
      credit_balance: null,
      current_credit: null,
      withdraw_credit: null,
      distance: null,
      parking_duration: null,
      current_driving_rate: null,
      current_parking_rate: null,
      approved_by_user_id: null,
      remark: null,
      latest_withdrawal: null,
      created_at: null,
    },
  },
  loading: false,
  showForm: false,
  msg: "",
  status: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_WITHDRAW_FUEL_CREDIT_HISTORY.REQUEST:
    case LOAD_VEHICLE_CREDIT_INFO.REQUEST:
    case LOAD_WITHDRAW_INFO.REQUEST:
      return {
        ...state,
        vehicleInfo: {},
        loading: true,
        showForm: false,
      };
    case UPDATE_WITHDRAW_VEHICLE_FUEL_CREDIT.REQUEST:
    case UPDATE_WITHDRAW_PATH.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_WITHDRAW_PATH.REQUEST:
      return {
        ...state,
        withdrawPath: "",
        loading: true,
      };
    case LOAD_WITHDRAW_FUEL_CREDIT_HISTORY.SUCCESS:
      return {
        ...state,
        historylist: action.payload.data,
        loading: false,
      };
    case LOAD_WITHDRAW_INFO.SUCCESS:
      return {
        ...state,
        withdrawInfo: action.payload.data,
        loading: false,
      };
    case LOAD_WITHDRAW_PATH.SUCCESS:
      return {
        ...state,
        withdrawPath: action.payload.data,
        loading: false,
      };

    case LOAD_VEHICLE_CREDIT_INFO.SUCCESS:
      let check = false;
      if (action.payload.data !== {}) {
        check = true;
      }
      return {
        ...state,
        vehicleInfo: action.payload.data,
        loading: false,
        showForm: check,
      };
    case UPDATE_WITHDRAW_VEHICLE_FUEL_CREDIT.SUCCESS:
    case UPDATE_WITHDRAW_PATH.SUCCESS:
      return {
        ...state,
        msg: "Update Success",
        status: "success",
        loading: false,
        showForm: false,
      };
    case LOAD_WITHDRAW_FUEL_CREDIT_HISTORY.FAILURE:
    case LOAD_VEHICLE_CREDIT_INFO.FAILURE:
    case UPDATE_WITHDRAW_VEHICLE_FUEL_CREDIT.FAILURE:
    case LOAD_WITHDRAW_INFO.FAILURE:
    case LOAD_WITHDRAW_PATH.FAILURE:
    case UPDATE_WITHDRAW_PATH.FAILURE:
      return {
        ...state,
        msg: action.payload.err,
        loading: false,
        status: "fail",
        shouldReload: false,
      };
    default:
      return state;
  }
};
