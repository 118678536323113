import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Label,
  Brush,
} from "recharts";
import { Card, Spin, Row, Col } from "antd";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          padding: 15,
          border: "1px solid #b0b0b0",
          backgroundColor: "#f0f0f0",
        }}
      >
        <p>{`${payload[0].payload.date}`}</p>
        <p>{`ค่าน้ำมัน : ${Math.abs(payload[0].value)}`}</p>
      </div>
    );
  }

  return null;
};

const GraphSection = ({
  dataSourceGraph,
  loading,
  dateRange,
  formSearch,
  settingValue,
  graphNumber,
}) => {
  const customTick = (e, i) => {

    let increaseXval = 0;
    if (e.index + 1 === e.visibleTicksCount) increaseXval = 48;
    let time = e.payload.value.split(" ")[1];
    let formatTime = time.split(":")[0] + ":" + time.split(":")[1];
    return (
      <g transform={`translate(${Math.ceil(e.x) + increaseXval},${e.y})`}>
        <text
          x={0}
          y={10}
          dy={5}
          dx={10}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {e.payload.value}
        </text>
      </g>
    );
  };

  return (
    <Spin tip="Loading" size="small" spinning={loading}>
      <Card
        width="100%"
        style={{
          backgroundColor: "white",
          marginTop: 15,
          border: 0,
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          padding: 15,
        }}
      >
        {/* {JSON.stringify(settingValue)}
        {JSON.stringify(graphNumber)} */}
        <div style={{ marginBottom: 15, fontSize: 18 }}>ค่าน้ำมัน</div>
        <Row>
          <Col span={24} align="center">
            <div style={{ marginBottom: 15, fontSize: 14 }}>
              ทะเบียนพาหนะ :{" "}
              {`${formSearch.vehiclePlateNo} (${formSearch.vehicleId})`}
            </div>
          </Col>
          <Col span={24} align="center">
            <div style={{ marginBottom: 15, fontSize: 14 }}>
              วันที่ : {`${dateRange.start_at} - ${dateRange.endAt}`}{" "}
            </div>
          </Col>
          <Col span={24} align="center">
            <span
              style={{
                height: 10,
                width: 10,
                backgroundColor: "#006ECC",
                borderRadius: "50%",
                display: "inline-block",
                marginRight: 5,
              }}
            ></span>
            <span>ค่าน้ำมัน</span>
          </Col>
        </Row>

        <div style={{ height: 400 }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={dataSourceGraph}
              margin={{
                top: 30,
                right: 30,
                left: 50,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                tick={(e) => customTick(e)}
                height={100}
                dy={40}
                dx={-50}
              />
              <YAxis
                width={55}
                tickLine={false}
                tickCount={9}
                type="number"
                s
                domain={["dataMin - 500", "dataMax + 1500"]}
                allowDecimals={false}
                axisLine={false}
              />
              <Brush />
              <Tooltip content={<CustomTooltip />} />
              <Line
                type="monotone"
                dataKey="value"
                stroke="#006ECC"
                fill="white"
                name="ค่าน้ำมัน"
                strokeWidth={1.8}
                dot={false}
              />
              {graphNumber == 1 &&
                settingValue.maxFuel != "" &&
                settingValue.totalFuel != "" && (
                  <ReferenceLine
                    y={settingValue.maxFuel}
                    stroke="red"
                    strokeDasharray="3 3"
                  >
                    <Label position="insideBottomLeft" dx={10}>
                      {`ค่าที่จุดสูงสุด ช่วงเติมน้ำมัน คือ ${settingValue.maxFuel} ที่ขนาดถังน้ำมัน ${settingValue.totalFuel} ลิตร`}
                    </Label>
                  </ReferenceLine>
                )}
              {graphNumber == 1 &&
                settingValue.minFuel != "" &&
                settingValue.fuelBeforeFullLiter != "" && (
                  <ReferenceLine
                    y={settingValue.minFuel}
                    stroke="green"
                    strokeDasharray="3 3"
                  >
                    <Label position="insideBottomLeft" dx={10}>
                      {`ค่าที่จุดต่ำสุด ช่วงเติมน้ำมัน คือ ${settingValue.minFuel}`}
                    </Label>
                  </ReferenceLine>
                )}

              {graphNumber == 2 &&
                settingValue.maxFuel != "" &&
                settingValue.totalFuel != "" && (
                  <ReferenceLine
                    y={-Math.abs(settingValue.maxFuel)}
                    stroke="red"
                    strokeDasharray="3 3"
                  >
                    <Label position="insideBottomLeft" dx={10}>
                      {`ค่าที่จุดสูงสุด ช่วงเติมน้ำมัน คือ ${settingValue.maxFuel} ที่ขนาดถังน้ำมัน ${settingValue.totalFuel} ลิตร`}
                    </Label>
                  </ReferenceLine>
                )}
              {graphNumber == 2 &&
                settingValue.minFuel != "" &&
                settingValue.fuelBeforeFullLiter != "" && (
                  <ReferenceLine
                    y={-Math.abs(settingValue.minFuel)}
                    stroke="green"
                    strokeDasharray="3 3"
                  >
                    <Label position="insideBottomLeft" dx={10}>
                      {`ค่าที่จุดต่ำสุด ช่วงเติมน้ำมัน คือ ${settingValue.minFuel}`}
                    </Label>
                  </ReferenceLine>
                )}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Card>
    </Spin>
  );
};

export default GraphSection;
