import { Form, Input, Drawer, Button, Select } from "antd";
import LangContext from "modules/shared/context/langContext";
import React from "react";

const createSelectOption = (items) => {
  return items.map((ele) => (
    <Select.Option value={ele.id}>{ele.name}</Select.Option>
  ));
};
const Filter = (props) => (
  <Drawer
    title={
      <LangContext.Consumer>
        {(i18n) => i18n.f.filterSearch}
      </LangContext.Consumer>
    }
    width={520}
    placement="right"
    onClose={props.onClose}
    maskClosable={true}
    visible={props.filterVisible}
    style={{
      height: "calc(100% - 55px)",
      overflow: "auto",
      paddingBottom: 53,
    }}
  >
    <Form>
      <Form.Item
        label={
          <LangContext.Consumer>
            {(i18n) => i18n.v.vehicleCode}
          </LangContext.Consumer>
        }
      >
        <Input
          value={props.vehicle_code}
          onChange={(v) => {
            props.handleStateChange("vehicle_code", v);
          }}
        />
      </Form.Item>
      <Form.Item
        label={
          <LangContext.Consumer>
            {(i18n) => i18n.d.depositFuelcreditReason}
          </LangContext.Consumer>
        }
      >
        <Select
          allowClear
          style={{ width: 250 }}
          value={props.reason_id}
          onChange={(v) => {
            props.handleStateChange("reason_id", v);
          }}
        >
          {createSelectOption(props.reasonList)}
        </Select>
      </Form.Item>
      <Button
        style={{
          marginRight: 8,
        }}
        onClick={props.onClose}
      >
        <LangContext.Consumer>{(i18n) => i18n.c.cancel}</LangContext.Consumer>
      </Button>
      <Button type="primary" onClick={props.onFilterButtonClick}>
        <LangContext.Consumer>{(i18n) => i18n.a.apply}</LangContext.Consumer>
      </Button>
      {props.textFrom}
    </Form>
  </Drawer>
);
export default Filter;
