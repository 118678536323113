import React, { Component } from "react";
import { Col, Row, AutoComplete, Button } from "antd";
import { Link } from "react-router-dom";

import { StyledSearchForm } from "../../../../styled/common-styled";

import LangContext from "modules/shared/context/langContext";
import FormDivider from "../../../shared/components/FormDivider";

export default class SelectForm extends Component {
  render() {
    return (
      <StyledSearchForm>
        <FormDivider>
          <LangContext.Consumer>
            {(i18n) => i18n.p.pleaseSelectVehicleRegistration}
          </LangContext.Consumer>
        </FormDivider>
        <Row type="flex" justify="end" align="middle">
          <Col>
            <LangContext.Consumer>
              {(i18n) => i18n.v.vehicleCode}
            </LangContext.Consumer>
            <> &nbsp;:&nbsp;</>
          </Col>
          <Col span={12}>
            <AutoComplete
              value={this.props.keyword}
              // style={{ width: 500 }}
              placeholder={
                <LangContext.Consumer>
                  {(i18n) => i18n.p.pleaseSelectVehicleCode}
                </LangContext.Consumer>
              }
              optionFilterProp="children"
              optionLabelProp="children"
              // filterOption
              allowClear
              onSearch={this.props.handleOnSearchVehicle}
              onSelect={this.props.handleOnSelect}
              disabled={this.props.disableForm}
            >
              {this.props.vehicleList.map((v) => {
                return (
                  <AutoComplete.Option
                    key={v.id.toString()}
                    value={v.id.toString()}
                    data={v}
                  >
                    {v.code}
                  </AutoComplete.Option>
                );
              })}
            </AutoComplete>
          </Col>
        </Row>
        {!this.props.disableForm ? (
          <Row type="flex" justify="end">
            <Col span={3}>
              <Link to="/deposit-fuel-credit">
                <Button
                  type="default"
                  style={{
                    margin: "0px 8px 0px 0px",
                  }}
                >
                  <LangContext.Consumer>
                    {(i18n) => i18n.b.back}
                  </LangContext.Consumer>
                </Button>
              </Link>
              <Button type="primary" onClick={this.props.handleOnSumit}>
                <LangContext.Consumer>
                  {(i18n) => i18n.s.save}
                </LangContext.Consumer>
              </Button>
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </StyledSearchForm>
    );
  }
}
