import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadDataCPFDashboard(action) {
  const {
    data: { company_id, filter_type, accessToken },
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-data-cpf-dashboard`,
      {
        company_id: company_id,
        filter_type: filter_type,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.loadDataCPFDashboard.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadDataCPFDashboard.failure());
  }
}

export default function* watchCPFDashboard() {
  yield all([
    takeEvery(actions.LOAD_DATA_CPF_DASHBOARD.REQUEST, loadDataCPFDashboard),
  ]);
}
export { loadDataCPFDashboard };
