import { createTypes, createAction } from "lib/action";

const LOAD_TRUCKUSAGE_SJC_MONTHLY = createTypes("sjcmonthlytruckusages", "load");
const LOAD_VEHICLE_TRUCKUSAGE_SJC_MONTHLY = createTypes("vehicle_truckusage", "load");
const LOAD_VEHICLE_TYPE_TRUCKUSAGE_SJC_MONTHLY = createTypes(
  "vehicletype_truckusage",
  "load"
);

const loadTruckusageSJCMonthly = {
  request: (data, loading) =>
    createAction(LOAD_TRUCKUSAGE_SJC_MONTHLY.REQUEST, { data, loading }),
  success: (data) =>
    createAction(LOAD_TRUCKUSAGE_SJC_MONTHLY.SUCCESS, { data }),
  failure: () => createAction(LOAD_TRUCKUSAGE_SJC_MONTHLY.FAILURE),
};

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_TRUCKUSAGE_SJC_MONTHLY.REQUEST, {
      vehicletypeID,
      loading_vehicle,
    }),
  success: (data) => createAction(LOAD_VEHICLE_TRUCKUSAGE_SJC_MONTHLY.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TRUCKUSAGE_SJC_MONTHLY.FAILURE),
};

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_TRUCKUSAGE_SJC_MONTHLY.REQUEST, {}),
  success: (data) =>
    createAction(LOAD_VEHICLE_TYPE_TRUCKUSAGE_SJC_MONTHLY.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_TRUCKUSAGE_SJC_MONTHLY.FAILURE),
};

export {
  loadTruckusageSJCMonthly,
  LOAD_TRUCKUSAGE_SJC_MONTHLY,
  loadVehicle,
  LOAD_VEHICLE_TRUCKUSAGE_SJC_MONTHLY,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_TRUCKUSAGE_SJC_MONTHLY,
};
