import { createTypes, createAction } from 'lib/action'

const EXPORT_INPUTACTIVITY = createTypes('exportinputactivity', 'load')
const LOAD_VEHICLE_INPUTACTIVITY = createTypes(
  'loadvehicleinputactivity',
  'load'
)
const LOAD_VEHICLE_TYPE_INPUTACTIVITY = createTypes(
  'loadvehicletypeinputactivity',
  'load'
)

const exportInputactivity = {
  request: (data, loading) =>
    createAction(EXPORT_INPUTACTIVITY.REQUEST, { data, loading }),
  success: (data) => createAction(EXPORT_INPUTACTIVITY.SUCCESS, { data }),
  failure: () => createAction(EXPORT_INPUTACTIVITY.FAILURE),
}

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_INPUTACTIVITY.REQUEST, {
      vehicletypeID,
      loading_vehicle,
    }),
  success: (data) => createAction(LOAD_VEHICLE_INPUTACTIVITY.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_INPUTACTIVITY.FAILURE),
}

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_INPUTACTIVITY.REQUEST, {}),
  success: (data) =>
    createAction(LOAD_VEHICLE_TYPE_INPUTACTIVITY.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_INPUTACTIVITY.FAILURE),
}

export {
  exportInputactivity,
  EXPORT_INPUTACTIVITY,
  loadVehicle,
  LOAD_VEHICLE_INPUTACTIVITY,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_INPUTACTIVITY,
}
