import { createTypes, createAction } from "lib/action";
const LOAD_MASTERDATA_LOCATIONGROUP = createTypes("masterdatalocationgroup", "load");
const CREATE_MASTERDATA_LOCATIONGROUP = createTypes("masterdatalocationgroup", "create");
const UPDATE_MASTERDATA_LOCATIONGROUP = createTypes("masterdatalocationgroup", "update");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
    return {
        type: REMOVE_LISTS
    };
}

const loadLocationgroup = {
    request: (data, loading) => 
        createAction(LOAD_MASTERDATA_LOCATIONGROUP.REQUEST, { data, loading}),
    success: (lists, page) => 
        createAction(LOAD_MASTERDATA_LOCATIONGROUP.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_MASTERDATA_LOCATIONGROUP.FAITLURE)
};

const createMasterDataLocationGroup = {
    request: (data, loading) =>
        createAction(CREATE_MASTERDATA_LOCATIONGROUP.REQUEST, { data, loading}),
    success: res => createAction(CREATE_MASTERDATA_LOCATIONGROUP.SUCCESS, { res }),
        failure: () => createAction(CREATE_MASTERDATA_LOCATIONGROUP.FAILURE)
};

const updateMasterDataLocationGroup = {
    request: (data, loading) =>
        createAction(UPDATE_MASTERDATA_LOCATIONGROUP.REQUEST, { data, loading}),
    success: res => createAction(UPDATE_MASTERDATA_LOCATIONGROUP.SUCCESS, { res }),
        failure: () => createAction(UPDATE_MASTERDATA_LOCATIONGROUP.FAILURE)
}
export {
    LOAD_MASTERDATA_LOCATIONGROUP, loadLocationgroup,
    CREATE_MASTERDATA_LOCATIONGROUP, createMasterDataLocationGroup,
    UPDATE_MASTERDATA_LOCATIONGROUP,updateMasterDataLocationGroup, 
    REMOVE_LISTS, removeLists
};