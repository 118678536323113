import history from "lib/history";
import { setFlashMessage } from "modules/ui/actions";

export const CALL_API = Symbol("CALL_API");

const handleRequest = (store, request) => {
  if (typeof request === "object") {
    store.dispatch({ type: request.type });
  } else {
    store.dispatch({ type: request });
  }
};

const handleSuccess = async (store, success, res, action) => {
  if (res.status === 401) {
    store.dispatch(
      setFlashMessage("danger", "You are not allow to access this page.")
    );
    return history.push("/auth/register");
  }

  if (typeof success === "object") {
    const payload = success.payload(action, store.getState(), res);

    store.dispatch({
      type: success.type,
      payload: payload.then ? await payload : payload
    });
  } else {
    const payload = await res.json();

    store.dispatch({ type: success, payload });
  }
};

const handleFailure = (store, failure, error, action) => {
  if (typeof failure === "object") {
    store.dispatch({
      type: failure.type,
      payload: failure.payload(action, store.getState(), error)
    });
  } else {
    store.dispatch({ type: failure, payload: { error } });
  }
};

export default store => next => async action => {
  if (action[CALL_API]) {
    const {
      endpoint,
      method = "GET",
      body,
      types: [request, success, failure]
    } = action[CALL_API];

    const url = `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}${endpoint}`;
    const accessToken = store.getState().auth.accessToken;
    const headers = { "Content-Type": "application/json" };

    if (accessToken) headers["Authorization"] = accessToken;

    handleRequest(store, request);

    const fetchObject =
      method === "GET"
        ? fetch(url, { headers })
        : fetch(url, {
            method,
            headers,
            body: JSON.stringify(body)
          });

    try {
      const res = await fetchObject;

      return handleSuccess(store, success, res, action);
    } catch (error) {
      handleFailure(store, failure, error, action);
    }
  } else {
    return next(action);
  }
};
