import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";
import {
  Form,
  DatePicker,
  AutoComplete,
  Select,
  Button,
  Modal,
  Badge,
} from "antd";
import LangContext from "modules/shared/context/langContext";
import { genDeviceProcessDetail } from "./deviceProcessFunc";

const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const { Option, OptGroup } = AutoComplete;

export default class Filter extends Component {
  handleOpenDeviceProcessModal = () => {
    let html = genDeviceProcessDetail(this.props.deviceProcess.userList);

    Modal.warning({
      title: "อุปกรณ์นี้ กำลังมีการเชื่อมต่อการใช้งานวีดีโอดังนี้",
      content: <div dangerouslySetInnerHTML={{ __html: html }}></div>,
      okText: "ปิด",
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.vehicleLists != this.props.vehicleLists) {
      return true;
    }
    return true;
  }

  render() {
    const { onSubmit, vehicleLists, disabled, getVehicleInfo } = this.props;
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          eventdate: [
            moment("00:00:00", "HH:mm:ss"),
            moment("23:59:59", "HH:mm:ss"),
          ],
          camera_position: "",
          file_size: "large",
          should_show_fileopt: getVehicleInfo.has_file_opt,
          device_types_id: getVehicleInfo.device_types_id,
          has_file_opt: getVehicleInfo.has_file_opt,
        }}
        validate={(values) => {
          let errors = {};
          if (values.eventdate.length > 0) {
            const date_start = moment(values.eventdate[0]["_d"]);
            const date_end = moment(values.eventdate[1]["_d"]);
            let day_diff = date_end.diff(date_start, "days") + 1;
            if (day_diff > 1) {
              errors.eventdate = "ช่วงวันที่ที่เลือกไม่ควรเกิน 1 วัน";
            }
          }
          return errors;
        }}
        validationSchema={yup.object().shape({
          eventdate: yup.array().required("กรุณาเลือกช่วงวันที่"),
        })}
        onSubmit={(values) => {
          onSubmit(values);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          handleFocus,
          setFieldValue,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit} layout={"inline"}>
            <FormItem>
              <Button
                className="blinking"
                type="danger"
                disabled={this.props.deviceProcess === null}
                icon="eye"
                size="small"
                onClick={this.handleOpenDeviceProcessModal}
              />
            </FormItem>
            <FormItem
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.c.camera_position}
                </LangContext.Consumer>
              }
              validateStatus={
                touched.camera_position && errors.camera_position && "error"
              }
              help={touched.camera_position && errors.camera_position}
            >
              <Select
                disabled={disabled}
                style={{ width: 150 }}
                onChange={(value) => {
                  setFieldValue("camera_position", value);
                  console.log("camera_position", values);

                  // กล้องหน้า hnt เท่านั้นที่มีไฟล์เล็ก
                  if (values.device_types_id == 32) {
                    if (["front", ""].includes(value) && values.has_file_opt) {
                      setFieldValue("should_show_fileopt", true);
                    } else {
                      setFieldValue("should_show_fileopt", false);
                      setFieldValue("file_size", "large");
                    }
                  }

                  if (values.device_types_id == 37) {
                    if (value === "extra") {
                      setFieldValue("should_show_fileopt", false);
                      setFieldValue("file_size", "large");
                    } else {
                      setFieldValue("should_show_fileopt", true);
                    }
                  }
                }}
                value={values.camera_position}
              >
                <Option value="">
                  <LangContext.Consumer>
                    {(i18n) => i18n.a.all}
                  </LangContext.Consumer>
                </Option>

                <Option value="front">
                  <LangContext.Consumer>
                    {(i18n) => i18n.f.front}
                  </LangContext.Consumer>
                </Option>
                <Option value="back">
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.camera_back}
                  </LangContext.Consumer>
                </Option>

                <Option value="extra">
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.camera_extra}
                  </LangContext.Consumer>
                </Option>
              </Select>
            </FormItem>

            {values.should_show_fileopt && (
              <FormItem
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.f.file_size}
                  </LangContext.Consumer>
                }
              >
                <Select
                  onChange={(value) => setFieldValue("file_size", value)}
                  value={values.file_size}
                  disabled={disabled}
                >
                  <Option value="small">
                    <LangContext.Consumer>
                      {(i18n) => i18n.f.file_size_small}
                    </LangContext.Consumer>
                  </Option>
                  <Option value="large">
                    <LangContext.Consumer>
                      {(i18n) => i18n.f.file_size_large}
                    </LangContext.Consumer>
                  </Option>
                </Select>
              </FormItem>
            )}

            <Form.Item
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.d.dateRange}
                </LangContext.Consumer>
              }
              validateStatus={errors.eventdate && "error"}
              help={errors.eventdate}
            >
              <RangePicker
                disabled={disabled}
                showTime={{ format: "HH:mm:ss" }}
                onChange={(value) => setFieldValue("eventdate", value)}
                format="DD/MM/YYYY HH:mm:ss"
                placeholder={["Start Time", "End Time"]}
                defaultValue={[
                  moment("00:00:00", "HH:mm:ss"),
                  moment("23:59:59", "HH:mm:ss"),
                ]}
              />
            </Form.Item>

            <Form.Item>
              <Button htmlType="submit" type="primary" disabled={disabled}>
                <LangContext.Consumer>
                  {(i18n) => i18n.a.apply}
                </LangContext.Consumer>
              </Button>
            </Form.Item>
          </Form>
        )}
      />
    );
  }
}
