import {
  AutoComplete,
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Transfer,
} from "antd";

import { Formik } from "formik";
import React, { Component } from "react";

import { Link } from "react-router-dom";
import LangContext from "modules/shared/context/langContext";

import * as yup from "yup";
import FormDivider from "../../../shared/components/FormDivider";
import { debounce } from "debounce";
const phoneRegExp = /^[0-9]+$/;

export default class Forms extends Component {
  state = {
    demo: [],
  };
  formatVehicleList = (data) => {
    let newArr = data.map((item) => {
      return {
        key: item.id,
        label: item.plate_no,
      };
    });
    return newArr;
  };
  render() {
    return (
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={this.props.initialValues}
          validationSchema={yup.object().shape({
            company_id: yup.number().required(),
            phone_number: yup
              .string()
              .matches(
                phoneRegExp,
                <LangContext.Consumer>
                  {(i18n) => i18n.manageOTP.pleaseFieldCorrectPhoneNumber}
                </LangContext.Consumer>
              )
              .length(
                10,
                <LangContext.Consumer>
                  {(i18n) => i18n.manageOTP.pleaseField10PhoneNumber}
                </LangContext.Consumer>
              )
              .required(
                <LangContext.Consumer>
                  {(i18n) => i18n.manageOTP.pleaseFieldPhoneNumber}
                </LangContext.Consumer>
              ),

            list_vehicle_id: yup
              .array()
              .required(
                <LangContext.Consumer>
                  {(i18n) => i18n.p.pleaseSelectVehicleRegistration}
                </LangContext.Consumer>
              )
              .nullable(),
          })}
          onSubmit={(values) => {
            this.props.onSubmit(values);
          }}
          render={({
            values,
            errors,
            status,
            handleBlur,
            touched,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <FormDivider>
                <LangContext.Consumer>
                  {(i18n) => i18n.manageOTP.matchPhoneNoWithVehicle}
                </LangContext.Consumer>
              </FormDivider>
              <Form.Item
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.manageOTP.phoneNumber}
                  </LangContext.Consumer>
                }
                required
                validateStatus={
                  touched.phone_number && errors.phone_number && "warning"
                }
                help={touched.phone_number && errors.phone_number}
              >
                <Input
                  value={values.phone_number}
                  disabled={this.props.disablePhoneCompany}
                  style={{ width: 500 }}
                  onChange={(v) => {
                    setFieldValue("phone_number", v.target.value);
                  }}
                ></Input>
              </Form.Item>
              <Form.Item
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.manageOTP.companyName}
                  </LangContext.Consumer>
                }
                required
              >
                <AutoComplete
                  value={values.company_id}
                  disabled={this.props.disablePhoneCompany}
                  style={{ width: 500 }}
                  placeholder={
                    <LangContext.Consumer>
                      {(i18n) => i18n.manageOTP.searchCompany}
                    </LangContext.Consumer>
                  }
                  optionFilterProp="children"
                  optionLabelProp="children"
                  // filterOption
                  allowClear
                  onSearch={(v) => {
                    setFieldValue("company_id", v);
                    debounce(this.props.loadCompany(v), 1500);
                  }}
                  onSelect={(value) => {
                    setFieldValue("company_id", value);
                    this.props.loadAllVehicle(value);
                  }}
                >
                  {this.props.companyLists.map((v) => {
                    return (
                      <AutoComplete.Option
                        key={v.companies_id.toString()}
                        value={v.companies_id.toString()}
                        data={v}
                      >
                        {v.companies_name}
                      </AutoComplete.Option>
                    );
                  })}
                </AutoComplete>
              </Form.Item>

              <Form.Item
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.p.plateNo}
                  </LangContext.Consumer>
                }
                validateStatus={
                  touched.list_vehicle_id && errors.list_vehicle_id && "warning"
                }
                help={touched.list_vehicle_id && errors.list_vehicle_id}
                required
              >
                <Transfer
                  titles={["Source", "Target"]}
                  // name="vehicle"
                  style={{
                    margin: "10px 0px 0px 0px",
                  }}
                  listStyle={{
                    width: 220,
                    height: 300,
                  }}
                  dataSource={this.formatVehicleList(this.props.vehicleLists)}
                  showSearch
                  targetKeys={values.list_vehicle_id}
                  onChange={(targetKeys) => {
                    setFieldValue("list_vehicle_id", targetKeys);
                  }}
                  render={(item) => item.label}
                />
              </Form.Item>
              <Form.Item
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.r.remark}
                  </LangContext.Consumer>
                }
              >
                <Input.TextArea
                  value={values.remark}
                  style={{ width: 500 }}
                  onChange={(v) => {
                    setFieldValue("remark", v.target.value);
                  }}
                ></Input.TextArea>
              </Form.Item>
              <Row gutter={24} type="flex">
                <Col span={24}>
                  <Link to="/manage-otp">
                    <Button
                      type="default"
                      style={{
                        margin: "0px 8px 0px 0px",
                      }}
                    >
                      <LangContext.Consumer>
                        {(i18n) => i18n.b.back}
                      </LangContext.Consumer>
                    </Button>
                  </Link>
                  <Button type="primary" htmlType="submit">
                    <LangContext.Consumer>
                      {(i18n) => i18n.s.save}
                    </LangContext.Consumer>
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        />
      </div>
    );
  }
}
