import React, { useState } from "react";
import { Select, Row, Col, Modal } from "antd";
const { Option } = Select;

const ModalSuperUserLogon = ({
  visibleSuperUserLogon,
  closeModalVisibleSuperUserLogon,
  updateStatus,
  selectDriver,
}) => {
  const [selectRemark, setSelectRemark] = useState("ลืม Logoff");
  const handleChange = (e) => {
    setSelectRemark(e);
  };
  return (
    <Modal
      title={"สาเหตุ"}
      visible={visibleSuperUserLogon}
      onOk={() => {
        updateStatus(selectRemark, selectDriver);
        setSelectRemark("ลืม Logoff");
      }}
      onCancel={() => {
        closeModalVisibleSuperUserLogon();
        setSelectRemark("ลืม Logoff");
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={3} align="right" style={{ marginTop: 4 }}>
          สาเหตุ
        </Col>
        <Col span={21}>
          <Select
            defaultValue="ลืม Logoff"
            onChange={handleChange}
            style={{ width: 250 }}
          >
            <Option value="ลืม Logoff">1.ลืม Logoff</Option>
            <Option value="Logoff error.">2.Logoff error.</Option>
            <Option value="ได้รับการอนุมัติให้ logon ก่อนเวลาพักผ่อนครบ 8 ชั่วโมง">
              3.ได้รับการอนุมัติให้ logon ก่อนเวลาพักผ่อนครบ 8 ชั่วโมง
            </Option>
          </Select>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalSuperUserLogon;
