import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadAssignFirmware(action) {
    const {
        data: {
          page,
          pageSize,
          orderBy,
          orderType,
          accessToken,
          filterObj
        }
    } = action.payload;

    try {
        const res = yield call(
          axios.post,
            `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getassignfirmware`,
            {
                page,
                pageSize,
                orderBy,
                orderType,
                filterObj
            },
            { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        yield put(actions.loadAssignFirmware.success(res.data, page));
    } catch (err) {
          console.log(err.message);
          yield put(actions.loadAssignFirmware.failure());
    }
}

function* assignFirmware(action)
{
    const {
        data: {
          files,
          uploader,
          force_update,
          frimware_id,
          device,
          device_type_name,
          install_at,
          description,
          user_id,
          accessToken
        },
        assignloading
    } = action.payload;

    let formData = new FormData();
    let uploadFile = null;

    console.log(files)
    if (files != null) {

        files.forEach(file => {
            uploadFile = file;
        });
    }

    formData.append("files", uploadFile);
    formData.append("force_update", force_update);
    formData.append("frimware_id", frimware_id);
    formData.append("device", device);
    formData.append("device_type_name", device_type_name);
    formData.append("install_at", install_at);
    formData.append("description", description);
    formData.append("user_id", user_id);

    try {
        const res = yield call(
          axios.post,
          `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/assignfirmware/create`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        );
    
        assignloading(res);
        yield put(actions.assignFirmware.success(res.data));
    } catch (err) {
        console.log(err.message);
        yield put(actions.assignFirmware.failure());
    }
}

export default function* watchassignfirmware() {
    yield all([
      takeEvery(actions.LOAD_ASSIGNFIRMWARE.REQUEST, loadAssignFirmware),
      takeEvery(actions.ASSIGN_FIRMWARE.REQUEST, assignFirmware)
    ]);
}
export {
    loadAssignFirmware,
    assignFirmware
};