import { LOAD_DEPOSIT_FUEL_CREDIT_REPORT } from "./actions";

const initialState = {
  report: "",
  msg: "",
  status: "",
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DEPOSIT_FUEL_CREDIT_REPORT.REQUEST:
      return {
        ...state,
        report: action.payload.data,
        loading: true,
      };
    case LOAD_DEPOSIT_FUEL_CREDIT_REPORT.SUCCESS:
      return {
        ...state,
        report: action.payload.data,
        loading: false,
      };
    case LOAD_DEPOSIT_FUEL_CREDIT_REPORT.FAILURE:
      return {
        ...state,
        msg: action.payload.err,
        loading: false,
        status: "fail",
      };
    default:
      return state;
  }
};
