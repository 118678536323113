import React, { Component } from "react";
import LangContext, { i18n } from "modules/shared/context/langContext";
import {
  Menu,
  Dropdown,
  TreeSelect,
  Card,
  Button,
  Row,
  Col,
  Input,
  Icon,
  Badge,
  Divider,
  Spin,
  Pagination,
  Collapse,
  Tooltip,
  Checkbox,
  message,
  Popconfirm,
  Modal,
  Table,
} from "antd";
import Data from "./mockData";
import { helper_convertDateformat } from "../../../../../lib/helper";

class EventTable extends Component {
  render() {
    let self = this;
    const columns = [
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.k.kpiEvent}
          </LangContext.Consumer>
        ),
        key: "name",
        dataIndex: "name",
        sorter: (a, b) => ("" + a.name).localeCompare(b.name),
        render(text, record, index) {
          let speed = "";
          if (typeof record.speed !== "undefined") {
            speed = record.speed;
          }

          return (
            <div style={{ fontSize: "9px" }}>
              <LangContext.Consumer>
                {(i18n) =>
                  i18n.variable[text] +
                  " " +
                  speed +
                  (speed && " " + i18n.k.kmH)
                }
              </LangContext.Consumer>
            </div>
          );
        },

        // i18n.k.kmH
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.k.kpiDateTime}
          </LangContext.Consumer>
        ),
        key: "start_at",
        dataIndex: "start_at",
        defaultSortOrder: "ascend",
        sorter: (a, b) => ("" + a.start_at).localeCompare(b.start_at),

        render(text) {
          return (
            <div style={{ fontSize: "9px" }}>
              {helper_convertDateformat(
                text,
                "YYYY-MM-DD HH:mm",
                "DD/MM/YY HH:mm"
              )}
            </div>
          );
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.d.driverName}
          </LangContext.Consumer>
        ),
        key: "driver",
        dataIndex: "driver",

        sorter: (a, b) => ("" + a.driver).localeCompare(b.driver),

        render(text) {
          return <div style={{ fontSize: "9px" }}>{text}</div>;
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.l.location}
          </LangContext.Consumer>
        ),
        key: "location",
        dataIndex: "location",
        ellipsis: true,
        sorter: (a, b) => ("" + a.location).localeCompare(b.location),

        render(text) {
          return <div style={{ fontSize: "8px" }}>{text}</div>;
        },
      },
    ];

    return (
      <Table
        bordered
        size="small"
        pagination={true}
        columns={columns}
        dataSource={this.props.dataSource}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              const center = {
                lat: record.lat,
                lng: record.lng,
              };
              this.props.handleChangeCenterMap(center);
            }, // click row
          };
        }}
        loading={this.props.loading}
      />
    );
  }
}

export default EventTable;
