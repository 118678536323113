import { CALL_API } from "middleware/api";
import history from "lib/history";
import { createTypes, createAction } from "lib/action";

const CHECK_LOGIN = createTypes("login", "check");
const CHECK_LOGIN_NOTIFICATION = createTypes("checkLoginNotification", "check");

const BASE_ACTION = "app/auth";

const LOAD_CREDENTIALS = `${BASE_ACTION}/credentials/load`;
const REMOVE_CREDENTIALS = `${BASE_ACTION}/credentials/remove`;
const CREATE_USER_REQUEST = `${BASE_ACTION}/register/request`;
const CREATE_USER_SUCCESS = `CREATE_USER_SUCCESS`;
const CREATE_USER_FAILURE = `/register/failure`;
const SET_CONFIG_TRACKING_COLUMNS = `${BASE_ACTION}/config/set/trackingcol`;
const SET_CONFIG_TRACKING_TABLE_CAR_REGIS_COLUMNS = `${BASE_ACTION}/config/set/trackingcolregis`
const SET_CONFIG_TRACKING_TABLE_DRIVER_COLUMNS = `${BASE_ACTION}/config/set/trackingcoldriver`
const SET_CONFIG_HOME_DASHBOARD_WIDGET = `${BASE_ACTION}/config/set/homedashboard/widget/position`;
const SET_LOCATION_VISIBILITY = `${BASE_ACTION}/set/location_visibility`;
const SET_VEHICLE_VISIBILITY = `${BASE_ACTION}/set/vahicle_visibility`;
const SET_LOCATION_GROUP_VISIBILITY = `${BASE_ACTION}/set/location_group_visibility`;
const SET_VEHICLE_GROUP_VISIBILITY = `${BASE_ACTION}/set/vehicle_group_visibility`;
const CHANGE_LANG = `CHANGE_LANG`;
const asyncLocalStorage = {
  setItem: function(key, value) {
    return Promise.resolve().then(function() {
      localStorage.setItem(key, value);
    });
  },
  getItem: function(key) {
    return Promise.resolve().then(function() {
      return localStorage.getItem(key);
    });
  },
};

const checkLoginNotification = {
  request: (data) => createAction(CHECK_LOGIN_NOTIFICATION.REQUEST, { data }),
  success: (lists) => createAction(CHECK_LOGIN_NOTIFICATION.SUCCESS, { lists }),
  failure: () => createAction(CHECK_LOGIN_NOTIFICATION.FAITLURE),
};

function loadCredentials() {
  const token = localStorage.getItem("access-token");
  const profile = JSON.parse(localStorage.getItem("profile") || "{}");

  let currentTime = null;
  let tokenExp = null;
  let done = false;
  let msg = "";

  if (JSON.stringify(profile) !== "{}") {
    done = true;
    tokenExp = profile.exp;

    currentTime = Math.floor(Date.now() / 1000);
    if (currentTime > tokenExp) {
      done = false;
      msg = "Session Expired";
    }
  }

  return {
    type: LOAD_CREDENTIALS,
    payload: { token, tokenExp, profile, done, msg },
  };
}

function removeCredentials() {
  localStorage.removeItem("access-token");
  localStorage.removeItem("profile");
  return {
    type: REMOVE_CREDENTIALS,
  };
}

function storeCredentials(
  token,
  profile,
  permissions,
  vehicle_list,
  location_list,
  vehicle_group_list,
  location_group_list,
  data_visibility_node,
  user_history_logon_id
) {
  return asyncLocalStorage.setItem("access-token", token).then(function() {
    profile = {
      ...profile,
      tokenExp: profile.exp,
      permissions,
      vehicle_visibility: vehicle_list,
      location_visibility: location_list,
      vehicle_group_visibility: vehicle_group_list,
      location_group_visibility: location_group_list,
      data_visibility_node,
      user_history_logon_id,
    };

    return asyncLocalStorage
      .setItem("profile", JSON.stringify(profile))
      .then(function(value) {
        return loadCredentials();
      });
  });

  // localStorage.setItem("access-token", token);
  // localStorage.setItem("profile", JSON.stringify(profile));
  // loadCredentials();
}

function register(credentials) {
  return {
    [CALL_API]: {
      endpoint: "/users",
      method: "POST",
      body: credentials,
      types: [
        CREATE_USER_REQUEST,
        {
          type: CREATE_USER_SUCCESS,
          payload: async (_action, _state, res) => {
            const token = res.headers.get("Authorization");
            const { user: profile } = await res.json();

            storeCredentials(token, profile);
            history.push("/");

            return {
              token,
              profile,
            };
          },
        },
        CREATE_USER_FAILURE,
      ],
    },
  };
}

const checkLogin = {
  request: (credentials, loading) =>
    createAction(CHECK_LOGIN.REQUEST, { credentials, loading }),
  success: (
    token,
    tokenPayload,
    permissions,
    vehicle_list,
    location_list,
    vehicle_group_list,
    location_group_list,
    data_visibility_node,
    user_history_logon_id
  ) =>
    createAction(CHECK_LOGIN.SUCCESS, {
      token,
      tokenPayload,
      permissions,
      vehicle_list,
      location_list,
      vehicle_group_list,
      location_group_list,
      data_visibility_node,
      user_history_logon_id,
    }),
  failure: (status, msg) => createAction(CHECK_LOGIN.FAILURE, { status, msg }),
};

// function changeConfigTrackingColumns(columns) {
//   return {
//     type: SET_CONFIG_TRACKING_COLUMNS,
//     payload: columns
//   };
// }

function changeConfigTrackingColumns(profile, columns) {
  const token = localStorage.getItem("access-token");
  if (profile != null && token != null) {
    profile = {
      ...profile,
      permissions: JSON.stringify(profile.permissions),
      config: JSON.stringify({
        ...profile.config,
        menu: { tracking: { ...profile.config.menu.tracking, columns } },
      }),
    };

    localStorage.setItem("profile", JSON.stringify(profile));

    return {
      type: SET_CONFIG_TRACKING_COLUMNS,
      payload: columns,
    };
  }
  return {
    type: SET_CONFIG_TRACKING_COLUMNS,
    payload: [],
  };
}

function changeConfigTrackingTableCarRegisColumns(profile, CarRegisColumns) {
  const token = localStorage.getItem('access-token')
  if (profile != null && token != null) {
    profile = {
      ...profile,
      permissions: JSON.stringify(profile.permissions),
      config: JSON.stringify({
        ...profile.config,
        menu: {
          ...profile.config.menu,
          tracking: {
            ...profile.config.menu.tracking,
            columns_car_plate_no: CarRegisColumns,
          },
        },
      }),
    }
    localStorage.setItem('profile', JSON.stringify(profile))
    return {
      type: SET_CONFIG_TRACKING_TABLE_CAR_REGIS_COLUMNS,
      payload: { profile, CarRegisColumns },
    }
  }
  return {
    type: SET_CONFIG_TRACKING_TABLE_CAR_REGIS_COLUMNS,
    payload: { profile, CarRegisColumns },
  }
}

function changeConfigTrackingTableDriverColumns(profile, DriverColumns) {
  const token = localStorage.getItem('access-token')
  if (profile != null && token != null) {
    profile = {
      ...profile,
      permissions: JSON.stringify(profile.permissions),
      config: JSON.stringify({
        ...profile.config,
        menu: {
          ...profile.config.menu,
          tracking: {
            ...profile.config.menu.tracking,
            columns_driver: DriverColumns,
          },
        },
      }),
    }
    localStorage.setItem('profile', JSON.stringify(profile))
    return {
      type: SET_CONFIG_TRACKING_TABLE_DRIVER_COLUMNS,
      payload: { profile, DriverColumns },
    }
  }
  return {
    type: SET_CONFIG_TRACKING_TABLE_DRIVER_COLUMNS,
    payload: { profile, DriverColumns },
  }
}

function changeConfigHomeDashboardLayout(layout) {
  return {
    type: SET_CONFIG_HOME_DASHBOARD_WIDGET,
    payload: layout,
  };
}

function changeLocalStorageConfigHomeDashboardLayout(profile, layout) {
  const token = localStorage.getItem("access-token");
  if (profile != null && token != null) {
    profile = {
      ...profile,
      permissions: JSON.stringify(profile.permissions),
      config: JSON.stringify({ ...profile.config, home_dashboard: { layout } }),
    };

    localStorage.setItem("profile", JSON.stringify(profile));

    return {
      type: SET_CONFIG_HOME_DASHBOARD_WIDGET,
      payload: layout,
    };
  }
  return {
    type: SET_CONFIG_HOME_DASHBOARD_WIDGET,
    payload: [],
  };
}

function changeLang(lang) {
  return {
    type: CHANGE_LANG,
    payload: { lang },
  };
}

function changeLocalStorageLocationVisibility(profile, id) {
  const token = localStorage.getItem("access-token");
  if (profile != null && token != null) {
    profile = {
      ...profile,
      permissions: JSON.stringify(profile.permissions),
      config: JSON.stringify(profile.config),
      location_visibility: id,
    };
    localStorage.setItem("profile", JSON.stringify(profile));
    return {
      type: SET_LOCATION_VISIBILITY,
      payload: id,
    };
  }
  return {
    type: SET_LOCATION_VISIBILITY,
    payload: { id },
  };
}

function changeLocalStorageVehicleVisibility(profile, id) {
  const token = localStorage.getItem("access-token");
  if (profile != null && token != null) {
    profile = {
      ...profile,
      permissions: JSON.stringify(profile.permissions),
      config: JSON.stringify(profile.config),
      vehicle_visibility: id,
    };
    localStorage.setItem("profile", JSON.stringify(profile));
    return {
      type: SET_VEHICLE_VISIBILITY,
      payload: id,
    };
  }
  return {
    type: SET_VEHICLE_VISIBILITY,
    payload: { id },
  };
}

function changeLocalStorageLocationGroupVisibility(profile, id) {
  const token = localStorage.getItem("access-token");
  if (profile != null && token != null) {
    profile = {
      ...profile,
      permissions: JSON.stringify(profile.permissions),
      config: JSON.stringify(profile.config),
      location_group_visibility: id,
    };
    localStorage.setItem("profile", JSON.stringify(profile));
    return {
      type: SET_LOCATION_GROUP_VISIBILITY,
      payload: id,
    };
  }
  return {
    type: SET_LOCATION_GROUP_VISIBILITY,
    payload: { id },
  };
}

function changeLocalStorageVehicleGroupVisibility(profile, id) {
  const token = localStorage.getItem("access-token");
  if (profile != null && token != null) {
    profile = {
      ...profile,
      permissions: JSON.stringify(profile.permissions),
      config: JSON.stringify(profile.config),
      vehicle_group_visibility: id,
    };
    localStorage.setItem("profile", JSON.stringify(profile));
    return {
      type: SET_VEHICLE_GROUP_VISIBILITY,
      payload: id,
    };
  }
  return {
    type: SET_VEHICLE_GROUP_VISIBILITY,
    payload: { id },
  };
}

export {
  LOAD_CREDENTIALS,
  REMOVE_CREDENTIALS,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  loadCredentials,
  storeCredentials,
  removeCredentials,
  register,
  CHECK_LOGIN,
  checkLogin,
  CHECK_LOGIN_NOTIFICATION,
  checkLoginNotification,
  SET_CONFIG_TRACKING_COLUMNS,
  changeConfigTrackingColumns,
  SET_CONFIG_TRACKING_TABLE_CAR_REGIS_COLUMNS,
  changeConfigTrackingTableCarRegisColumns,
  SET_CONFIG_TRACKING_TABLE_DRIVER_COLUMNS,
  changeConfigTrackingTableDriverColumns,
  SET_CONFIG_HOME_DASHBOARD_WIDGET,
  changeConfigHomeDashboardLayout,
  changeLocalStorageConfigHomeDashboardLayout,
  CHANGE_LANG,
  changeLang,
  changeLocalStorageLocationVisibility,
  SET_LOCATION_VISIBILITY,
  changeLocalStorageVehicleVisibility,
  SET_VEHICLE_VISIBILITY,
  changeLocalStorageLocationGroupVisibility,
  SET_LOCATION_GROUP_VISIBILITY,
  changeLocalStorageVehicleGroupVisibility,
  SET_VEHICLE_GROUP_VISIBILITY,
};
