import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";

import * as actions from "../actions";
import Forms from "./Forms";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "./../../../shared/components/Breadcrumb";

class Add extends Component {
  componentDidMount() {
    this.props.clearProduct();
  }

  create = (values, loading) => {
    values.company_id = this.props.auth.profile.company_id;
    values.token = this.props.auth.accessToken;
    this.props.createProduct(values, loading, this.props.history);
  };

  render() {
   
    return (
      <GeneralStyledContent>
        <Row gutter={24}>
          <Col span={24}>
            <Breadcrumb
              match={this.props.match}
              style={{ margin: "0px 0px 14px 10px" }}
            />
          </Col>
        </Row>
        <Forms
          onSubmit={this.create}
          product={this.props.product}
          auth={this.props.auth}
          id={""}
        />
      </GeneralStyledContent>
    );
  }
}

const mapStateToProps = ({
  product,
  auth,
}) => ({
  product,
  auth,
 
});

const mapDispatchToProps = {
  createProduct: actions.createProduct.request,
  clearProduct: actions.clearProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(Add);
