import {
    LOAD_INSURANCE,
    LOAD_AUTOCOMPLETE_PLATENO,
    LOAD_AUTOCOMPLETE_CODE,
    UPDATE_INSURANCE,
    REMOVE_LISTS
} from "./actions";
const initialState = {
    lists: [],
    listsdetail: [],
    total: 1,
    loading: false,
    autoComplete: [],
    autoCompletePlateNo: [],
    autoCompleteCode: [],
    check: true,
    detail_lists: [],
};

export default (state = initialState, action) => {
    switch (action.type) {

        case REMOVE_LISTS:
            return initialState;

        case LOAD_INSURANCE.REQUEST:
            const { loading } = action.payload.data;
            return {
                ...state,
                loading: loading,
            }

        case LOAD_INSURANCE.SUCCESS:
            const { page } = action.payload;
                           
            for (let i = 0; i < action.payload.lists.data.length; i++) {
                action.payload.lists.data[i].rowNumber = i + 1;
                    if (page > 1)
                        action.payload.lists.data[i].rowNumber =
                            action.payload.lists.data[i].rowNumber + (page - 1) * 10;
            }
                
            return {
                ...state,
                lists: action.payload.lists.data,
                total: 
                    action.payload.lists.total - 1 <= 0
                    ? 1
                    : action.payload.lists.total <= 0
                    ? 1
                    : action.payload.lists.total,
                    loading: false,
                 draw: Math.floor(Math.random() * 999 + 1)
            };

        case LOAD_AUTOCOMPLETE_PLATENO.SUCCESS:
            return {
                ...state,
                autoCompletePlateNo: action.payload
            };
                
        case LOAD_AUTOCOMPLETE_CODE.SUCCESS:
            return {
                ...state,
                autoCompleteCode: action.payload
            };

        
        case UPDATE_INSURANCE.SUCCESS:
                return {
                    ...state,
                    loading: false
                };

        default:
            return state;
    }
}