import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Table,
  Row,
  Col,
  Icon,
  Drawer,
} from "antd";

import Filter from "./Filter";
import LangContext from "modules/shared/context/langContext";
import * as actions from "../actions";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "./../../../shared/components/Breadcrumb";

const columnStyle = { fontSize: "12px", fontWeight: "400" };
const brokenStyle = { fontSize: "12px", fontWeight: "400", background: "#ffeced" };
class Index extends Component {
  state = {
    visible: false
  };

  componentWillUnmount() {
    this.props.clearMdvr();

  }


  showDrawer = () => {
    this.setState({
      visible: true
    });
  };



  onCloseSearch = resetForm => {
    this.setState({
      visible: false
    });
  };

  filterMdvrdownloadfile = values => {
    this.props.loadMdvrdownloadfile(values, true);
  };

  loadVehicle = () => {
    const { loadVehicle } = this.props;
    loadVehicle();
  }

  getRowStyle = (record) => {
    if (parseFloat(record.size) <= 0) {
      return brokenStyle
    }
    return columnStyle
  }

  render() {
    const columns = [
      {
        title: "#",
        dataIndex: "row_number",
        width: "4%",
        render: (text, record, index) => {
          return {
            props: {
              style: this.getRowStyle(record),
            },
            children: <div>{text}</div>
          };
        }
      },
      {
        title: <LangContext.Consumer>{i18n => i18n.p.plateNo}</LangContext.Consumer>,
        dataIndex: "plate_no",
        sorter: false,
        // render: name => `${name.first} ${name.last}`,
        width: "15%",
        render: (text, record, index) => {
          return {
            props: {
              style: this.getRowStyle(record),
            },
            children: <div>{text}</div>
          };
        }
      },
      {
        title: <LangContext.Consumer>{i18n => i18n.t.time_period}</LangContext.Consumer>,
        dataIndex: "time",
        sorter: false,
        render: (text, record, index) => {
          return {
            props: {
              style: this.getRowStyle(record),
            },
            children: <div>{text}</div>
          };
        },
        width: "20%"
      },
      {
        title: <LangContext.Consumer>{i18n => i18n.v.video_type}</LangContext.Consumer>,
        dataIndex: "video_type",
        sorter: false,
        // render: name => `${name.first} ${name.last}`,
        width: "12%",
        render: (text, record, index) => {
          return {
            props: {
              style: this.getRowStyle(record),
            },
            children: <div>{text}</div>
          };
        }
      },
      {
        title: <LangContext.Consumer>{i18n => i18n.c.channel}</LangContext.Consumer>,
        dataIndex: "channel",
        sorter: false,
        render: (text, record, index) => {
          return {
            props: {
              style: this.getRowStyle(record),
            },
            children: <div>{text}</div>
          };
        },
        width: "10%"
      },
      {
        title: <LangContext.Consumer>{i18n => i18n.f.file_location}</LangContext.Consumer>,
        dataIndex: "file_location",
        sorter: false,
        render: (text, record, index) => {
          return {
            props: {
              style: this.getRowStyle(record),
            },
            children: <div>{text}</div>
          };
        },
        width: "10%"
      },
      {
        title: <LangContext.Consumer>{i18n => i18n.f.file_size}</LangContext.Consumer>,
        dataIndex: "size",
        sorter: false,
        render: (text, record, index) => {
          return {
            props: {
              style: this.getRowStyle(record),
            },
            children: <div>{text}</div>
          };
        },
        width: "10%"
      },
      {
        title: <LangContext.Consumer>{i18n => i18n.d.download}</LangContext.Consumer>,
        dataIndex: "",
        sorter: false,
        render: (text, record, index) => {
          if (parseFloat(record.size) <= 0) {
            return {
              props: {
                style: this.getRowStyle(record),
              },
              children: <div className="regular-bold-14" style={{ padding: "0px 15px 0px 5px" }}>
                <LangContext.Consumer>
                  {(i18n) => i18n.b.brokenFile}
                </LangContext.Consumer>
              </div>,
            };
          }

          return {
            props: {
              style: this.getRowStyle(record),
            },
            children: <div>
              <a
                href={record.path_download}
                download={record.file_name}
                target="_blank"
                style={{ padding: '0px 15px 0px 5px' }}
              >
                <Icon type="download" />
              </a>
            </div>
          };
        },
        width: "10%"
      },

    ];

    return (
      <div>
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <Row gutter={24} type="flex" justify="end" style={{ height: "38px" }}>
            <Col span={22}>

            </Col>

            <Col span={2}>
              <Button icon="filter" size="small" onClick={this.showDrawer}>
                <LangContext.Consumer>{i18n => i18n.f.filter}</LangContext.Consumer>
              </Button>
            </Col>
          </Row>
          <Table
            bordered
            size="small"
            columns={columns}
            rowKey={record => record.row_number}
            dataSource={this.props.mdvrdownloadfile.mdvrdownloadfileLists}
            pagination={false}
            loading={this.props.mdvrdownloadfile.mdvrdownloadfileLoading}
          // onChange={this.handleTableChange}
          />

          <div>
            <LangContext.Consumer>
              {
                i18n => <Drawer
                  title={i18n.s.search}
                  width={400}
                  onClose={this.onCloseSearch}
                  visible={this.state.visible}
                >
                  <Filter
                    OnClose={this.onCloseSearch}
                    onSubmit={this.filterMdvrdownloadfile}
                    vehicleLists={this.props.mdvrdownloadfile.vehicleLists}
                    loadVehicle={this.loadVehicle}

                  />
                </Drawer>
              }
            </LangContext.Consumer>
          </div>
        </GeneralStyledContent>
      </div>
    );
  }
}

const mapStateToProps = ({ mdvrdownloadfile, auth: { profile } }) => ({
  mdvrdownloadfile,
  profile
});

const mapDispatchToProps = {
  loadMdvrdownloadfile: actions.loadMdvrdownloadfile.request,
  loadVehicle: actions.loadVehicle.request,
  clearMdvr: actions.clearMdvr,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);
