import { createTypes, createAction } from "lib/action";

const LOAD_TAXIFARE = createTypes("taxiFare", "load");

const loadTaxiFare = {
  request: (data, loading) =>
    createAction(LOAD_TAXIFARE.REQUEST, { data, loading }),
  success: data => createAction(LOAD_TAXIFARE.SUCCESS, { data }),
  failure: () => createAction(LOAD_TAXIFARE.FAILURE)
};

export {
  loadTaxiFare,
  LOAD_TAXIFARE,
};
