import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { Button, Row, Col, Icon, Table, Spin, Radio, Checkbox } from "antd";

import { StyledWidget } from "../../../../styled/common-styled";

const ButtonGroup = Button.Group;

const columns = [
  {
    width: "25%",
    title: (
      <LangContext.Consumer>{(i18n) => i18n.d.driverName}</LangContext.Consumer>
    ),
    dataIndex: "name",
    render(text, record, index) {
      return <div style={{ fontSize: "10px" }}>{text}</div>;
    },
  },
  {
    width: "25%",
    title: (
      <LangContext.Consumer>{(i18n) => i18n.i.idcard}</LangContext.Consumer>
    ),
    dataIndex: "idcard",
    render(text, record, index) {
      return <div style={{ fontSize: "10px" }}>{text}</div>;
    },
  },
  {
    width: "25%",
    title: (
      <LangContext.Consumer>{(i18n) => i18n.p.plateNo}</LangContext.Consumer>
    ),
    dataIndex: "vehicle_plate_no",
    render(text, record, index) {
      return <div style={{ fontSize: "10px" }}>{text}</div>;
    },
  },
  {
    width: "25%",
    title: (
      <LangContext.Consumer>
        {(i18n) => i18n.v.vehicleCode}
      </LangContext.Consumer>
    ),
    dataIndex: "vehicle_code",
    render(text, record, index) {
      return <div style={{ fontSize: "10px" }}>{text}</div>;
    },
  },
  {
    width: "30%",
    title: (
      <LangContext.Consumer>{(i18n) => i18n.e.expireDate}</LangContext.Consumer>
    ),
    dataIndex: "expired_date",
    render(text, record, index) {
      return <div style={{ fontSize: "10px" }}>{text}</div>;
    },
  },
  {
    width: "30%",
    title: (
      <LangContext.Consumer>
        {(i18n) => i18n.u.updated_driving_license_at}
      </LangContext.Consumer>
    ),
    dataIndex: "update_driver_at",
    render(text, record, index) {
      return <div style={{ fontSize: "10px" }}>{text}</div>;
    },
  },
];

const fakeData = [
  {
    key: "1",
    name: "อนุสิทธิ์...",
    idcard: "1111505021233",
    vehicle_plate_no: "2กณ1232",
    vehicle_code: "1150",
    expired_date: "05/2024",
    update_driver_at: "25/02/2024",
  },
  {
    key: "2",
    name: "ประดิษฐ์...",
    idcard: "1101501040320",
    vehicle_plate_no: "2กณ1232",
    vehicle_code: "1149",
    expired_date: "05/2024",
    update_driver_at: "14/02/2024",
  },
  {
    key: "3",
    name: "ศุภชัย...",
    idcard: "1111505021233",
    vehicle_plate_no: "2กณ1232",
    vehicle_code: "1148",
    expired_date: "05/2024",
    update_driver_at: "01/02/2024",
  },
  {
    key: "4",
    name: "ธวัชชัย...",
    idcard: "1111505021233",
    vehicle_plate_no: "2กณ1232",
    vehicle_code: "1147",
    expired_date: "05/2024",
    update_driver_at: "29/02/2024",
  },
  {
    key: "5",
    name: "สายยัน...",
    idcard: "1111505021233",
    vehicle_plate_no: "2กณ1232",
    vehicle_code: "1146",
    expired_date: "05/2024",
    update_driver_at: "28/02/2024",
  },
  {
    key: "6",
    name: "วาริน...",
    idcard: "1111505021233",
    vehicle_plate_no: "2กณ1232",
    vehicle_code: "1145",
    expired_date: "05/2024",
    update_driver_at: "27/02/2024",
  },
  {
    key: "7",
    name: "อะรุงกุ๊งกุ๊ง...",
    idcard: "1111505021233",
    vehicle_plate_no: "2กณ1232",
    vehicle_code: "1144",
    expired_date: "05/2024",
    update_driver_at: "26/02/2024",
  },
];

export class DrivingLicenseExpireWidget extends Component {
  state = {
    rawData: false,
    chartType: "daily",
    loading: false,
    data: [],
  };

  onChartTypeChange = (e) => {
    this.setState({ chartType: e.target.value });

    this.loadData(true, e.target.value, this.state.rawData);
  };

  onRawDataChange = (e) => {
    this.setState({ rawData: e.target.checked });

    this.loadData(true, this.state.chartType, e.target.checked);
  };

  loadData = (loading, chartType, rawData) => {
    if (loading) this.setState({ loading: true });
    this.fetch(this, chartType, rawData);
  };

  fetch = (self, chartType, rawData) =>
    fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/widget/getdrivinglicenseexpirechart`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${self.props.auth.accessToken}`,
        },
        body: JSON.stringify({
          user_id: self.props.auth.profile.id,
          company_id: self.props.auth.profile.company_id,
          chart_type: chartType,
          raw_data: rawData,
          vehicle_visibility: self.props.auth.profile.vehicle_visibility,
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (Array.isArray(res)) {
          self.setState({
            loading: false,
            data: res,
          });
        }
      })
      .catch(function() {
        self.setState({
          loading: false,
        });
      });

  componentDidMount() {
    if (!this.props.demo) {
      this.loadData(true, this.state.chartType, this.state.rawData);
      this.interval = setInterval(
        () => this.loadData(false, this.state.chartType, this.state.rawData),
        1000 * 60 * 60
      );
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    if (this.props.demo)
      return (
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          style={{ height: "90%", width: "100%" }}
        >
          <h4>
            <LangContext.Consumer>
              {(i18n) => i18n.d.drivinglicenseexpire}
            </LangContext.Consumer>{" "}
          </h4>

          <Table
            className="tracking-table"
            columns={columns}
            dataSource={fakeData}
            size="small"
            pagination={false}
            scroll={{ y: "140px" }}
          />
        </Col>
      );
    else
      return (
        <StyledWidget>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{ height: "95%" }}
          >
            {" "}
            <Spin spinning={this.state.loading}>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <h4>
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.drivinglicenseexpire}
                    </LangContext.Consumer>{" "}
                  </h4>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} align="right">
                  <div>
                    <Radio.Group
                      onChange={this.onChartTypeChange}
                      defaultValue="daily"
                      size="small"
                    >
                      <Radio.Button value="daily">
                        <small>
                          <LangContext.Consumer>
                            {(i18n) => i18n.t.today}
                          </LangContext.Consumer>
                        </small>
                      </Radio.Button>
                      <Radio.Button value="monthly">
                        <small>
                          <LangContext.Consumer>
                            {(i18n) => i18n.m.monthly}
                          </LangContext.Consumer>
                        </small>
                      </Radio.Button>
                      <Radio.Button value="yearly">
                        <small>
                          <LangContext.Consumer>
                            {(i18n) => i18n.y.yearly}
                          </LangContext.Consumer>
                        </small>
                      </Radio.Button>
                    </Radio.Group>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Table
                    className="tracking-table"
                    rowKey={(record) => record.key + record.vehicle_group}
                    columns={columns}
                    dataSource={this.state.data}
                    size="small"
                    pagination={false}
                    scroll={{ y: "170px" }}
                  />
                </Col>
              </Row>
            </Spin>
          </Col>
        </StyledWidget>
      );
  }
}

export default DrivingLicenseExpireWidget;
