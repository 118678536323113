import { createTypes, createAction } from "lib/action";
const LOAD_COMPANY_SETTING = createTypes("loadcompanysetting", "load");
const UPDATE_AUTO_CREATE_DRIVER = createTypes(
  "updateautocreatedriver",
  "update"
);
const UPDATE_AUTO_CHANGE_DRIVER = createTypes(
  "updateautochangedriver",
  "update"
);
const UPDATE_COMPANY_LINE_TOKEN = createTypes(
  "updatecompanylinetoken",
  "update"
);
const UPDATE_TAX_ACT_INSURANCE_LINE_TOKEN = createTypes(
  "updatetaxactinsurancelinetoken",
  "update"
);
const UPDATE_FUEL_LINE_TOKEN = createTypes("updatefuellinetoken", "update");

const UPDATE_ADAS_LINE_TOKEN = createTypes("updateadaslinetoken", "update");

const loadCompanySetting = {
  request: (data, loading) =>
    createAction(LOAD_COMPANY_SETTING.REQUEST, { data, loading }),
  success: (data) => createAction(LOAD_COMPANY_SETTING.SUCCESS, { data }),
  failure: () => createAction(LOAD_COMPANY_SETTING.FAILURE),
};

const updateAutoCreateDriver = {
  request: (data, callback) =>
    createAction(UPDATE_AUTO_CREATE_DRIVER.REQUEST, { data, callback }),
  success: (res) => createAction(UPDATE_AUTO_CREATE_DRIVER.SUCCESS, { res }),
  failure: () => createAction(UPDATE_AUTO_CREATE_DRIVER.FAILURE),
};

const updateAutoChangeDriver = {
  request: (data, callback) =>
    createAction(UPDATE_AUTO_CHANGE_DRIVER.REQUEST, { data, callback }),
  success: (res) => createAction(UPDATE_AUTO_CHANGE_DRIVER.SUCCESS, { res }),
  failure: () => createAction(UPDATE_AUTO_CHANGE_DRIVER.FAILURE),
};

const updateCompanyLineToken = {
  request: (data, loading) =>
    createAction(UPDATE_COMPANY_LINE_TOKEN.REQUEST, { data, loading }),
  success: (res) => createAction(UPDATE_COMPANY_LINE_TOKEN.SUCCESS, { res }),
  failure: () => createAction(UPDATE_COMPANY_LINE_TOKEN.FAILURE),
};

const updateTaxActInsuranceLineToken = {
  request: (data, loading) =>
    createAction(UPDATE_TAX_ACT_INSURANCE_LINE_TOKEN.REQUEST, {
      data,
      loading,
    }),
  success: (res) =>
    createAction(UPDATE_TAX_ACT_INSURANCE_LINE_TOKEN.SUCCESS, { res }),
  failure: () => createAction(UPDATE_TAX_ACT_INSURANCE_LINE_TOKEN.FAILURE),
};

const updateFuelLineToken = {
  request: (data, loading) =>
    createAction(UPDATE_FUEL_LINE_TOKEN.REQUEST, {
      data,
      loading,
    }),
  success: (res) => createAction(UPDATE_FUEL_LINE_TOKEN.SUCCESS, { res }),
  failure: () => createAction(UPDATE_FUEL_LINE_TOKEN.FAILURE),
};

const updateADASLineToken = {
  request: (data, loading) =>
    createAction(UPDATE_ADAS_LINE_TOKEN.REQUEST, {
      data,
      loading,
    }),
  success: (res) => createAction(UPDATE_ADAS_LINE_TOKEN.SUCCESS, { res }),
  failure: () => createAction(UPDATE_ADAS_LINE_TOKEN.FAILURE),
};

export {
  LOAD_COMPANY_SETTING,
  loadCompanySetting,
  UPDATE_AUTO_CREATE_DRIVER,
  updateAutoCreateDriver,
  UPDATE_AUTO_CHANGE_DRIVER,
  updateAutoChangeDriver,
  UPDATE_COMPANY_LINE_TOKEN,
  updateCompanyLineToken,
  UPDATE_TAX_ACT_INSURANCE_LINE_TOKEN,
  updateTaxActInsuranceLineToken,
  UPDATE_FUEL_LINE_TOKEN,
  updateFuelLineToken,
  UPDATE_ADAS_LINE_TOKEN,
  updateADASLineToken,
};
