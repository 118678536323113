import { Col, message, Row, Spin, AutoComplete, Button } from "antd";
import * as actions from "../actions";
import { Link } from "react-router-dom";

import React, { Component } from "react";
import { connect } from "react-redux";
import { debounce } from "debounce";
import Breadcrumb from "../../../shared/components/Breadcrumb";

import {
  GeneralStyledContent,
  StyledSearchForm,
} from "../../../../styled/common-styled";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";
import LangContext from "modules/shared/context/langContext";
import FormDivider from "../../../shared/components/FormDivider";
import SelectForm from "./SelectForm";
import Forms from "./Forms";

// const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

class Create extends Component {
  state = {
    keyword: "",
    vehicle_id: "",
  };
  handleOnSumit = (value) => {
    this.props.update(
      { ...value, user_id: this.props.profile.id },
      this.generateMsg
    );
  };

  handleOnSearchVehicle = (keyword) => {
    this.setState({ keyword: keyword });
    let autoComVehicle = {
      keyword: keyword,
      vehicle_visibility: this.props.auth.profile.vehicle_visibility,
    };
    debounce(this.props.loadVehicleList(autoComVehicle), 1500);
  };

  handleOnSelect = (value) => {
    this.setState({ vehicle_id: value, keyword: value });
  };

  handleOnsubmitVehicleInfo = () => {
    this.props.loadInfo({ vehicle_id: this.state.vehicle_id });
  };
  generateMsg = () => {
    switch (this.props.depositfuelcredit.status) {
      case "success":
        message.success(this.props.depositfuelcredit.msg);
        this.props.history.goBack();
        break;
      case "fail":
        message.error(this.props.depositfuelcredit.msg);
        break;
      default:
        break;
    }
  };
  componentDidMount() {
    let autoComVehicle = {
      keyword: "",
      vehicle_visibility: this.props.auth.profile.vehicle_visibility,
    };
    this.props.loadVehicleList(autoComVehicle);
    this.props.loadReason({ company_id: this.props.auth.profile.company_id });
  }
  render() {
    return (
      <AuthorizeComponent matching_name="managedepositfuelcredit">
        <GeneralStyledContent>
          <Spin spinning={this.props.depositfuelcredit.loading}>
            <Row gutter={24}>
              <Col span={24}>
                <Breadcrumb
                  match={this.props.match}
                  style={{ margin: "0px 0px 14px 10px" }}
                />
              </Col>
            </Row>
            <SelectForm
              keyword={this.state.keyword}
              vehicleList={this.props.depositfuelcredit.vehicleList}
              handleOnSearchVehicle={this.handleOnSearchVehicle}
              handleOnSelect={this.handleOnSelect}
              handleOnSumit={this.handleOnsubmitVehicleInfo}
              disableForm={this.props.depositfuelcredit.showForm}
            />
            {this.props.depositfuelcredit.showForm ? (
              <StyledSearchForm>
                <Row type="flex" justify="start">
                  <Col span={24}>
                    <Forms
                      initialValues={{
                        vehicle_id: this.props.depositfuelcredit.vehicleInfo.id,
                        remark: null,
                        deposit_credit: null,
                      }}
                      vehicleInfo={this.props.depositfuelcredit.vehicleInfo}
                      reasonList={this.props.depositfuelcredit.reasonList}
                      onSubmit={this.handleOnSumit}
                    />
                  </Col>
                </Row>
              </StyledSearchForm>
            ) : (
              <></>
            )}
          </Spin>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}
const mapStateToProps = ({ depositfuelcredit, auth, auth: { profile } }) => ({
  depositfuelcredit,
  auth,
  profile,
});

const mapDispatchToProps = {
  loadVehicleList: actions.loadVehicleFuelCrediHistory.request,
  loadInfo: actions.loadVehicleInfo.request,
  loadReason: actions.loadDepositReason.request,
  update: actions.updateVehicleFuelCredit.request,
};
export default connect(mapStateToProps, mapDispatchToProps)(Create);
