import React, { Component } from "react";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";
import { Badge, Button, Col, Pagination, Row, Spin, Table } from "antd";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import { Link } from "react-router-dom";
import LangContext from "modules/shared/context/langContext";

import Filter from "./Filter";

import * as actions from "../actions";
import { connect } from "react-redux";

const columnStyle = { fontSize: "12px", fontWeight: "400" };
const initialFilter = {
  vehicle_code: "",
  reason_id: "",
  page: 1,
};

class Index extends Component {
  state = {
    ...initialFilter,
    filterVisible: false,
  };
  handlePageChange = (page) => {
    this.setState({ page });
    this.props.load({
      ...this.state,
      page: page,
      vehicle_visibility: this.props.auth.profile.vehicle_visibility,
    });
  };

  handleStateChange = (type, v) => {
    switch (type) {
      case "vehicle_code":
        this.setState({
          vehicle_code: v.target.value,
        });
        break;
      case "reason_id":
        this.setState({
          reason_id: v,
        });
        break;
      default:
        break;
    }
  };

  componentDidMount() {
    this.props.load({
      ...initialFilter,
      vehicle_visibility: this.props.auth.profile.vehicle_visibility,
    });
    this.props.loadReason({ company_id: this.props.auth.profile.company_id });
  }
  render() {
    const columns = [
      {
        title: "#",
        dataIndex: "rowNumber",
        width: 60,
        render: (text, record, index) => {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{index + 1 + (this.state.page - 1) * 10}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.p.plateNo}
          </LangContext.Consumer>
        ),
        dataIndex: "plate_no",
        key: "plate_no",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.v.vehicleCode}
          </LangContext.Consumer>
        ),
        dataIndex: "code",
        key: "code",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.d.depositfuelcredit}
          </LangContext.Consumer>
        ),
        dataIndex: "deposit_credit",
        key: "deposit_credit",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.d.depositfuelcreditreasonname}
          </LangContext.Consumer>
        ),
        dataIndex: "reason_name",
        key: "reason_name",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.f.firstName}
          </LangContext.Consumer>
        ),
        dataIndex: "firstname",
        key: "firstname",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.c.createdAt}
          </LangContext.Consumer>
        ),
        dataIndex: "created_at",
        key: "created_at",
        width: 150,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
    ];
    return (
      <AuthorizeComponent matching_name="report_depositfuelcredit">
        <Spin spinning={this.props.depositfuelcredit.loading}>
          {/* <Spin spinning={false}> */}
          <GeneralStyledContent>
            <Row gutter={24}>
              <Col span={24}>
                <Breadcrumb
                  customeurl={["/deposit-fuel-credit", null, null]}
                  match={this.props.match}
                  style={{ margin: "0px 0px 14px 10px" }}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={15} sm={20} md={21} lg={22} xl={22}>
                <Link to="/deposit-fuel-credit/create">
                  <Button
                    type="primary"
                    icon="plus"
                    size="small"
                    style={{ margin: "0px 0px 14px 0px" }}
                  >
                    <span></span>
                    <LangContext.Consumer>
                      {(i18n) => i18n.n.new}
                    </LangContext.Consumer>
                  </Button>
                </Link>
              </Col>
              <Col span={1}>
                <Badge dot={this.props.isFilter}>
                  <Button
                    icon="filter"
                    size="small"
                    onClick={(e) => {
                      this.setState({
                        filterVisible: true,
                      });
                    }}
                  >
                    <LangContext.Consumer>
                      {(i18n) => i18n.f.filter}
                    </LangContext.Consumer>
                  </Button>
                </Badge>
                <Filter
                  {...this.state}
                  onClose={() => this.setState({ filterVisible: false })}
                  handleStateChange={this.handleStateChange}
                  reasonList={this.props.depositfuelcredit.reasonList}
                  onFilterButtonClick={() => {
                    this.props.load({
                      ...this.state,
                      vehicle_visibility: this.props.auth.profile
                        .vehicle_visibility,
                      page: 1,
                    });
                    this.setState({ page: 1 });
                  }}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Table
                  id="main-table"
                  // showSorterTooltip={false}
                  bordered
                  columns={columns}
                  dataSource={this.props.depositfuelcredit.historylist.data}
                  pagination={false}
                  rowKey="phone_number"
                  // scroll={{ y: 500 }}
                />
              </Col>
            </Row>
            <Row type="flex" justify="end" style={{ padding: "15px" }}>
              <Col pan={24} align="right">
                <Pagination
                  defaultPageSize={10}
                  size="small"
                  current={this.state.page}
                  total={this.props.depositfuelcredit.historylist.total}
                  onChange={this.handlePageChange}
                />
              </Col>
            </Row>
          </GeneralStyledContent>
        </Spin>
      </AuthorizeComponent>
    );
  }
}
const mapStateToProps = ({ auth, auth: { profile }, depositfuelcredit }) => ({
  auth,
  profile,
  depositfuelcredit,
});

const mapDispatchToProps = {
  load: actions.loadDepositFuelCreditHistory.request,
  loadReason: actions.loadDepositReason.request,
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
