import {
  LOAD_PULLVEHICLESINK,
  CREATE_PULLVEHICLESINK,
  UPDATE_PULLVEHICLESINK,
  DELETE_PULLVEHICLESINK,
  CLEAR_PULLVEHICLESINK,
  GET_PULLVEHICLESINK,
  LOAD_VEHICLE_PULLVEHICLESINK,
  CLEAR_RESPONSE
} from "./actions";

const initialState = {
  pullvehiclesinkLists: [],
  pullvehiclesinkLoading: false,
  pullvehiclesinkTotal: 0,
  dataResponse: { status: "", msg: "", id: "", action_type: "" },
  dataPullvehiclesink: {
    id: "",
    plate_no: "",
    pull_vehicle_sink_date: "",
    action_type: ""
  },
  vehicleLists: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PULLVEHICLESINK.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        pullvehiclesinkLoading: loading
      };
    case LOAD_PULLVEHICLESINK.SUCCESS:
      return {
        ...state,
        pullvehiclesinkLists: action.payload.data.data,
        pullvehiclesinkLoading: false,
        pullvehiclesinkTotal: action.payload.data.total
      };
    case CREATE_PULLVEHICLESINK.REQUEST:
      const { loading: loading2 } = action.payload;
      return {
        ...state,
        pullvehiclesinkLoading: loading2
      };
    case CREATE_PULLVEHICLESINK.SUCCESS:
      return {
        ...state,
        dataResponse: { status: action.payload.data.status, msg: action.payload.data.msg, action_type: action.payload.data.action_type },
        pullvehiclesinkLoading: false
      };
    case UPDATE_PULLVEHICLESINK.REQUEST:
      const { loading: loading3 } = action.payload;
      return {
        ...state,
        pullvehiclesinkLoading: loading3
      };
    case UPDATE_PULLVEHICLESINK.SUCCESS:
      return {
        ...state,
        dataResponse: { status: action.payload.data.status, msg: action.payload.data.msg, action_type: action.payload.data.action_type },
        pullvehiclesinkLoading: false
      };
    case DELETE_PULLVEHICLESINK.SUCCESS:
      return {
        ...state,
        dataResponse: { status: action.payload.data.status, msg: action.payload.data.msg, action_type: action.payload.data.action_type },
      };

    case GET_PULLVEHICLESINK.REQUEST:
      return {
        ...state
      };
    case GET_PULLVEHICLESINK.SUCCESS:
      return {
        ...state,
        dataPullvehiclesink: action.payload.data.data,
        pullvehiclesinkLoading: false
      };
    case LOAD_VEHICLE_PULLVEHICLESINK.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data
      };
    case CLEAR_RESPONSE:
      return {
        ...state,
        dataResponse: { status: "", msg: "", id: "", action_type: "" }
      };
    case CLEAR_PULLVEHICLESINK:
      return {
        ...state,
        dataPullvehiclesink: {
          id: "",
          plate_no: "",
          pull_vehicle_sink_date: ""
        }
      };
    default:
      return state;
  }
};
