import {
  LOAD_TRUCKCURRENTLOCATION,
  LOAD_VEHICLE_TRUCKCURRENTLOCATION,
  LOAD_VEHICLE_TYPE_TRUCKCURRENTLOCATION
} from "./actions";

const initialState = {
  truckcurrentlocationUrl: "",
  vehicleLists: [],
  vehicleTypeLists: [],
  truckcurrentlocationLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TRUCKCURRENTLOCATION.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        truckcurrentlocationLoading: loading
      };
    case LOAD_TRUCKCURRENTLOCATION.SUCCESS:

      return {
        ...state,
        truckcurrentlocationUrl: action.payload.data.data,
        truckcurrentlocationLoading: false
      };
    case LOAD_VEHICLE_TRUCKCURRENTLOCATION.REQUEST:
      const { loading_vehicle } = action.payload;
      return {
        ...state,
        truckcurrentlocationLoading: loading_vehicle
      };
    case LOAD_VEHICLE_TRUCKCURRENTLOCATION.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        truckcurrentlocationLoading: false
      };
    case LOAD_VEHICLE_TYPE_TRUCKCURRENTLOCATION.SUCCESS:
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data
      };
    default:
      return state;
  }
};
