import { EXPORT_PERFORMANCELOG } from "./actions";

const initialState = {
  loading: false,
  errorMsg: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EXPORT_PERFORMANCELOG.REQUEST:
      console.log("REQUEST", action);
      return {
        loading: true,
        errorMsg: "",
      };
    case EXPORT_PERFORMANCELOG.SUCCESS:
      return {
        loading: false,
        errorMsg: "",
      };
    case EXPORT_PERFORMANCELOG.FAILURE:
      console.log("FAILURE", action);
      return {
        ...state,
        loading: false,
        errorMsg: action.payload.errorMsg,
      };

    default:
      return state;
  }
};
