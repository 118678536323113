import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Empty } from 'antd'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import moment from 'moment'
// import { scaleTime } from 'd3-scale'
// import { timeFormat } from 'd3-time-format'
// import { timeHour, timeDay } from 'd3-time'

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Brush,
} from 'recharts'

class TimeChart extends Component {
  constructor(props) {
    super(props)
  }

  customTickX = (e) => {
    // จัดรูปแบบแสดงผลแค่เวลา HH:mm เท่านั้น
    if (
      e.payload.value === undefined ||
      e.payload.value === null ||
      e.payload.value === ''
    ) {
      return null
    }

    let time = moment(e.payload.value).format('HH:mm')

    return (
      <text x={e.x} y={e.y} dy={10} textAnchor='middle' fill='#666'>
        {time}
      </text>
    )
  }

  customTickY = (e) => {
    // ถ้าเป็น category = ch ให้แสดงข้อความว่าเวลาของวิดีโอ
    // ถ้าเป็น category = GPS ให้แสดงข้อความว่าติดเครื่อง
    let text = ''
    if (e.payload.value === 'GPS') {
      text = 'GPS'
    } else if (e.payload.value.startsWith('CH')) {
      text = e.payload.value
    } else {
      text = ''
    }

    return (
      <text x={e.x} y={e.y} dy={5} textAnchor='middle' fill='#666'>
        {text}
      </text>
    )
  }

  // *customize tooltip in line chart
  customTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      let data_key = ['CH1', 'CH2', 'CH3', 'CH4', 'GPS']
      return payload.map((item, index) => {
        return (
          <>
            {item.payload.raw_created_at &&
            data_key.includes(item.payload.category) ? (
              <div className='custom-tooltip'>
                <p style={{ fontWeight: 'bold' }}>
                  {item.payload.category.startsWith('CH') ? (
                    <span>{`${item.payload.category}`}</span>
                  ) : item.payload.category === 'GPS' ? (
                    <span>{`${i18n[this.props.language].g.gpsTime}`}</span>
                  ) : (
                    ''
                  )}
                </p>
                <p key={index} style={{ color: item.fill }}>
                  {item.payload.category.startsWith('CH')
                    ? `${item.payload.start_at_display} - ${item.payload.end_at_display}`
                    : item.payload.category === 'GPS'
                    ? `${item.payload.created_at}`
                    : ''}
                </p>
                {item.payload.category.startsWith('CH') && (
                  <>
                    <p key={index} style={{ color: item.fill }}>
                      {item.payload.file_location
                        ? `${i18n[this.props.language].f.fileName}: ${
                            item.payload.file_location
                          }`
                        : ''}
                    </p>
                    <p key={index} style={{ color: item.fill }}>
                      {item.payload.file_size
                        ? `${i18n[this.props.language].f.fileSize}: ${
                            item.payload.file_size
                          }`
                        : ''}
                    </p>
                  </>
                )}
              </div>
            ) : null}
          </>
        )
      })
    }

    return null
  }

  render() {
    const { chart_color, chart_data, chart_ticks } = this.props

    //   !สำหรับแบ่ง scale ออกเป็น 24 ช่วง วิธีนี้ใช้ได้เหมือนกัน
    // const now = new Date()
    // const domainToday = scaleTime().domain([
    //   timeDay.floor(now),
    //   timeDay.ceil(now),
    // ])
    // const timeFormatter = tick => {
    //   return timeFormat('%H:%M')(new Date(tick))
    // }
    // const ticks = domainToday.ticks(timeHour.every(1))

    return (
      <Fragment>
        {chart_data && chart_data.length > 0 ? (
          <>
            <div className='line-chart-detail'>
              <ResponsiveContainer>
                <LineChart
                  width={'100%'}
                  height={'100%'}
                  data={chart_data}
                  //   onClick={e => {}}
                  margin={{
                    top: 20,
                    right: 20,
                    left: 20,
                    bottom: 20,
                  }}
                >
                  <CartesianGrid
                    fill='#FFFFFF'
                    horizontal={true}
                    vertical={true}
                  />
                  <XAxis
                    dataKey='created_at_unix'
                    allowDuplicatedCategory={false}
                    domain={['dataMin', 'dataMax']}
                    type='number'
                    ticks={chart_ticks}
                    tickFormatter={(tick) => moment(tick).format('HH:mm')}
                    // tick={e => this.customTickX(e)}
                    // ticks={ticks}
                    // tickFormatter={timeFormatter}
                  />
                  <YAxis
                    type='category'
                    dataKey='category'
                    tickLine={false}
                    tick={(e) => this.customTickY(e)}
                    tickMargin={15}
                    interval={0}
                  />
                  <Tooltip content={this.customTooltip} />

                  <Line
                    key={'a1'}
                    type='monotone'
                    data={chart_data}
                    dataKey={'a1'}
                    stroke={null}
                    fill={null}
                    dot={false}
                  />
                  <Line
                    key={'a2'}
                    type='monotone'
                    data={chart_data}
                    dataKey={'a2'}
                    stroke={null}
                    fill={null}
                    dot={false}
                  />
                  {/* ถ้าเป็นข้อมูล GPS ให้แสดงแบบไม่มีเส้นเชื่อม */}
                  <Line
                    key={'GPS'}
                    type='linear'
                    dataKey={'GPS'}
                    data={chart_data}
                    stroke={null}
                    fill={
                      chart_color.find((item) => item.category === 'GPS')
                        ? chart_color.find((item) => item.category === 'GPS')
                            .color
                        : '#000'
                    }
                    dot={true}
                  />

                  {/* ถ้าเป็นข้อมูลวิดีโอให้แสดงเส้นเชื่อมเป็นคู่ start กับ end */}
                  {chart_data.map((item, index) => {
                    if (item.category.startsWith('CH') && item.type === 'end') {
                      let index_start = chart_data.findIndex(
                        (item2) =>
                          item2.type === 'start' &&
                          item2.get_list_vdo_id === item.get_list_vdo_id
                      )
                      if (index_start !== -1) {
                        return (
                          <Line
                            key={item.get_list_vdo_id}
                            data={[chart_data[index_start], item]}
                            type='linear'
                            dataKey={item.category}
                            stroke={
                              chart_color.find(
                                (item2) => item2.category === item.category
                              )
                                ? chart_color.find(
                                    (item2) => item2.category === item.category
                                  ).color
                                : '#000'
                            }
                            fill={
                              chart_color.find(
                                (item2) => item2.category === item.category
                              )
                                ? chart_color.find(
                                    (item2) => item2.category === item.category
                                  ).color
                                : '#000'
                            }
                            dot={true}
                          />
                        )
                      } else {
                        return null
                      }
                    } else {
                      return null
                    }
                  })}
                </LineChart>
              </ResponsiveContainer>
            </div>
          </>
        ) : (
          <>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </>
        )}
      </Fragment>
    )
  }
}

export default connect(
  ({ ui: { language } }) => ({
    language,
  }),
  {}
)(TimeChart)
