import { createTypes, createAction } from "lib/action";

const LOAD_DRIVINGLICENSEEXPIRE = createTypes("drivinglicenseexpire", "load");

const loadDrivinglicenseexpire = {
  request: (data, loading) =>
    createAction(LOAD_DRIVINGLICENSEEXPIRE.REQUEST, { data, loading }),
  success: (data) => createAction(LOAD_DRIVINGLICENSEEXPIRE.SUCCESS, { data }),
  failure: () => createAction(LOAD_DRIVINGLICENSEEXPIRE.FAILURE),
};

export { loadDrivinglicenseexpire, LOAD_DRIVINGLICENSEEXPIRE };
