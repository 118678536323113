import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  Spin,
  Modal,
  TimePicker,
  Divider,
} from 'antd'

import { checkPermissions } from '../../../../lib/helper'
import { StyledSearchForm } from '../../../../styled/common-styled'
import { getlocalstorage } from './../../../../constants/local_storage'
import TableDetail from './TableDetail'
import FormDetail from './FormDetail'

const FormItem = Form.Item
const Option = Select.Option
const format = 'HH:mm'

export default class Forms extends Component {
  constructor(props) {
    super(props)
    this.formik = null
  }

  render() {
    const {
      routemaster,
      onSubmit,
      id,
      auth,
      action_type,
      data_for_edit,
    } = this.props
    const {
      routemasterLoading,
      dataRoutemaster,
      routeMasterType,
      checkRouteMasterType,
      check_routemaster,
    } = routemaster

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    }

    const buttonItemLayout = {
      wrapperCol: { span: 14, offset: 6 },
    }

    return (
      <Spin
        spinning={
          routemasterLoading || checkRouteMasterType || check_routemaster
        }
      >
        <Row>
          <StyledSearchForm>
            <Formik
              enableReinitialize={true}
              initialValues={{
                name: data_for_edit.name,
                route_master_type_id: data_for_edit.route_master_type_id,
              }}
              validate={(values) => {
                // console.log("data:", values)
                let errors = {}

                return errors
              }}
              validationSchema={yup.object().shape({
                name: yup
                  .string()
                  .required(
                    <LangContext.Consumer>
                      {(i18n) => i18n.r.routemaster_name_require}
                    </LangContext.Consumer>
                  )
                  .test(
                    'checkname',
                    <LangContext.Consumer>
                      {(i18n) => i18n.r.routemaster_name_duplicate}
                    </LangContext.Consumer>,
                    function(value) {
                      return new Promise((resolve, reject) => {
                        fetch(
                          `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/routemaster/checkname`,
                          {
                            method: 'POST',
                            headers: {
                              Accept: 'application/json',
                              'Content-Type': 'application/json',
                              Authorization: `Bearer ${auth.accessToken}`,
                            },
                            body: JSON.stringify({
                              company_id: auth.profile.company_id,
                              name: value,
                              id,
                            }),
                          }
                        )
                          .then((response) => response.json())
                          .then((res) => {
                            if (res.status === 'true') {
                              //console.log("res", res);
                              resolve(false)
                            }
                            resolve(true)
                          })
                      })
                    }
                  ),
                route_master_type_id: yup
                  .number()
                  .required(
                    <LangContext.Consumer>
                      {(i18n) => i18n.r.required}
                    </LangContext.Consumer>
                  ),
              })}
              onSubmit={(values, { resetForm }) => {
                const loading = true
                onSubmit(values, loading)
              }}
              ref={(node) => (this.formik = node)}
              render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                handleFocus,
                setFieldValue,
                resetForm,
              }) => (
                <div>
                  <Divider orientation='left'>
                    <h2>
                      {
                        <LangContext.Consumer>
                          {(i18n) => i18n.h.header}
                        </LangContext.Consumer>
                      }
                    </h2>
                  </Divider>

                  <Form onSubmit={handleSubmit}>
                    <FormItem
                      {...formItemLayout}
                      label={
                        <LangContext.Consumer>
                          {(i18n) => i18n.r.route_master_name}
                        </LangContext.Consumer>
                      }
                      required={true}
                      validateStatus={touched.name && errors.name && 'error'}
                      help={touched.name && errors.name}
                    >
                      <Input
                        name='name'
                        autoComplete='off'
                        onChange={(e) => {
                          setFieldValue('name', e.target.value)
                        }}
                        onBlur={handleBlur}
                        value={values.name}
                      />
                    </FormItem>

                    {/* ประเภท route */}
                    <FormItem
                      {...formItemLayout}
                      label={
                        <LangContext.Consumer>
                          {(i18n) => i18n.r.route_master_type}
                        </LangContext.Consumer>
                      }
                      required={true}
                      validateStatus={
                        touched.route_master_type_id &&
                        errors.route_master_type_id &&
                        'error'
                      }
                      help={
                        touched.route_master_type_id &&
                        errors.route_master_type_id
                      }
                    >
                      <Select
                        name='route_master_type_id'
                        onChange={(value) => {
                          setFieldValue('route_master_type_id', value)
                        }}
                        onBlur={handleBlur}
                        value={values.route_master_type_id}
                      >
                        {routeMasterType.map((v) => (
                          <Option key={v.id} value={v.id}>
                            {v.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>

                    {/* ปุ่ม */}
                    <FormItem {...buttonItemLayout}>
                      <Row gutter={24}>
                        <Col span={8}>
                          <Button type='defualt' block>
                            <Link to='/routemaster'>
                              <LangContext.Consumer>
                                {(i18n) => i18n.b.back}
                              </LangContext.Consumer>
                            </Link>
                          </Button>
                        </Col>
                        <Col span={8}>
                          <Button type='primary' htmlType='submit' block>
                            <LangContext.Consumer>
                              {(i18n) => i18n.s.save}
                            </LangContext.Consumer>
                          </Button>
                        </Col>
                      </Row>
                    </FormItem>

                    {id !== '' ? (
                      <div>
                        <Divider orientation='left'>
                          <h2>
                            {
                              <LangContext.Consumer>
                                {(i18n) => i18n.d.detail}
                              </LangContext.Consumer>
                            }
                          </h2>
                        </Divider>

                        <TableDetail
                          routemaster={routemaster}
                          route_master_id={this.props.id}
                          getRoutemasterdetailByID={
                            this.props.getRoutemasterdetailByID
                          }
                          createRoutemasterdetail={
                            this.props.createRoutemasterdetail
                          }
                          updateRoutemasterdetail={
                            this.props.updateRoutemasterdetail
                          }
                          loadRoutemasterdetail={
                            this.props.loadRoutemasterdetail
                          }
                          loadLocation={this.props.loadLocation}
                          loadLocationcode={this.props.loadLocationcode}
                          auth={this.props.auth}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </Form>
                </div>
              )}
            />
          </StyledSearchForm>
        </Row>
      </Spin>
    )
  }
}
