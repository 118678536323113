import {
  CREATE_POLICY,
  UPDATE_POLICY,
  LOAD_POLICY,
  REMOVE_LISTS,
  LOAD_POLICY_DETAIL,
  LOAD_POLICY_TEMPLATE_DETAIL,
  CREATE_TEMPLATE_POLICY,
  UPDATE_TEMPLATE_POLICY
} from "./actions";

const initialState = {
  lists: [],
  total: 1,
  loading: false,
  detailLoading: false,
  draw: -1,
  selectedPolicy: {
    policy_id: null,
    policy_name: "",
    // type: "area_location",
    conditionList: [],
    duration_minute_range: [1, 60],
    duration_second_range: [15, 45],
    duration_unit: "minute",
    duration_trigger_inside_range: true,
    recount_duration: "-",
    allow_monday: true,
    allow_tuesday: true,
    allow_wendnesday: true,
    allow_thursday: true,
    allow_friday: true,
    allow_saturday: true,
    allow_sunday: true,
    time_trigger_inside_range: true,
    time_start_at: "00:00:00",
    time_end_at: "23:59:59"
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState;
    case LOAD_POLICY.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        loading: loading
      };
    case LOAD_POLICY.SUCCESS:
      const { page } = action.payload;
      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1;
        if (page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber + (page - 1) * 10;
      }
      return {
        ...state,
        lists: action.payload.lists.data,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1)
      };
    case LOAD_POLICY_DETAIL.REQUEST:
      return {
        ...state,
        detailLoading: true
      };
    case LOAD_POLICY_DETAIL.SUCCESS:
      console.log(action.payload.policy.data.json.conditions);
      return {
        ...state,
        selectedPolicy: {
          ...action.payload.policy.data,
          conditionList: action.payload.policy.data.json.conditions
        },
        detailLoading: false
      };
    case CREATE_POLICY.SUCCESS:
      const { res } = action.payload;

      return {
        ...state,
        selectedPolicy: { ...res.values.data },
        policy_id: res.policy_id,
        detailLoading: false
      };
    case UPDATE_POLICY.REQUEST:
      return {
        ...state,
        detailLoading: true
      };
    case UPDATE_POLICY.SUCCESS:
      return {
        ...state,
        detailLoading: false
      };
    case LOAD_POLICY_TEMPLATE_DETAIL.REQUEST:
      return {
        ...state,
        detailLoading: true
      };
    case LOAD_POLICY_TEMPLATE_DETAIL.SUCCESS:
      return {
        ...state,
        selectedPolicy: {
          ...action.payload.policy.data,
          conditionList: action.payload.policy.data.json_v2
        },
        detailLoading: false
      };
    case CREATE_TEMPLATE_POLICY.REQUEST:
      return {
        ...state,
        detailLoading: true
      };
    case CREATE_TEMPLATE_POLICY.SUCCESS:
      return {
        ...state,
        // selectedPolicy: { ...action.payload.res.values.data },
        // policy_id: action.payload.res.policy_id,
        detailLoading: false
      };
    case UPDATE_TEMPLATE_POLICY.REQUEST:
      return {
        ...state,
        detailLoading: true
      };
    case UPDATE_TEMPLATE_POLICY.SUCCESS:
      return {
        ...state,
        detailLoading: false
      };
    default:
      return state;
  }
};
