import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

const asyncLocalStorage = {
  setItem: function(key, value) {
    return Promise.resolve().then(function() {
      localStorage.setItem(key, value);
    });
  },
  getItem: function(key) {
    return Promise.resolve().then(function() {
      return localStorage.getItem(key);
    });
  },
};

function* checkLoginNotification(action) {
  const {
    data: { username, password, token },
  } = action.payload;

  try {
    const res = yield call(
      axios.put,
      `${process.env.REACT_APP_NOTIFICATION_CENTER_TRACKING}/users/channel`,
      {
        // username: "test@terminus.com",
        username: `${username}`,
        project: "terminus",
        app: "appv2",
        channel: "web_push_notification",
        token: `${token}`,
      }
    );
    // console.log(res);
    yield put(actions.checkLoginNotification.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.checkLoginNotification.failure());
  }
}

function* checkLogin(action) {
  const {
    credentials: { username, password },
    loading,
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/auth/checkuser`,
      {
        username,
        password,
        from_web: true,
      }
    );
    let {
      data: {
        token,
        permissions,
        vehicle_list,
        location_list,
        vehicle_group_list,
        location_group_list,
        data_visibility_node,
        user_history_logon_id,
      },
    } = res;
    loading(res.data);

    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let deToken = JSON.parse(window.atob(base64));

    yield call(() =>
      actions.storeCredentials(
        token,
        deToken,
        permissions,
        vehicle_list,
        location_list,
        vehicle_group_list,
        location_group_list,
        data_visibility_node,
        user_history_logon_id
      )
    );
    yield put(
      actions.checkLogin.success(
        token,
        deToken,
        permissions,
        vehicle_list,
        location_list,
        vehicle_group_list,
        location_group_list,
        data_visibility_node,
        user_history_logon_id
      )
    );
  } catch (err) {
    console.log(err);
    if (err.message.indexOf("400") !== -1)
      yield put(
        actions.checkLogin.failure("error", "Invalid username or password!")
      );
    else if (err.message.indexOf("401") !== -1)
      yield put(actions.checkLogin.failure("error", "Account expired!"));
    else if (err.message.indexOf("503") !== -1)
      yield put(actions.checkLogin.failure("error", "Service Unavailable!"));
    else if (err.message.indexOf("502") !== -1)
      yield put(actions.checkLogin.failure("error", "Bad Gateway!"));
    else if (err.message.indexOf("504") !== -1)
      yield put(actions.checkLogin.failure("error", "Gateway Timeout!"));
    else
      yield put(actions.checkLogin.failure("error", "Something went wrong!"));
  }
}

export default function* watchAuthState() {
  yield all([
    takeEvery(actions.CHECK_LOGIN.REQUEST, checkLogin),
    takeEvery(actions.CHECK_LOGIN_NOTIFICATION.REQUEST, checkLoginNotification),
  ]);
}

export { checkLogin, checkLoginNotification };
