import { LOAD_DISPLAY, LOAD_VIDEO } from "./actions";
const initialState = {
  displayList: [],
  videoList: [],
  groupInfo: [],
  groupData: [],
  msg: "",
  status: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DISPLAY.REQUEST:
    case LOAD_VIDEO.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_DISPLAY.SUCCESS:
      return {
        ...state,
        displayList: action.payload.data.data,
        loading: false,
      };
    case LOAD_VIDEO.SUCCESS:
      return {
        ...state,
        videoList: action.payload.data.data,
        groupInfo: action.payload.data.group_info,
        countAll: action.payload.data.count_all,
        groupData: action.payload.data.group_data,
        loading: false,
      };
    case LOAD_DISPLAY.FAILURE:
    case LOAD_VIDEO.FAILURE:
      return {
        ...state,
        msg: action.payload.err,
        loading: false,
        status: "fail",
        shouldReload: false,
      };
    default:
      return state;
  }
};
