import {
  LOAD_REFUEL,
  LOAD_VEHICLE_REFUEL,

} from "./actions";

const initialState = {
  refuelUrl: "",
  vehicleLists: [],
  refuelLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_REFUEL.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        refuelLoading: loading
      };
    case LOAD_REFUEL.SUCCESS:

      return {
        ...state,
        refuelUrl: action.payload.data.data,
        refuelLoading: false
      };
    case LOAD_VEHICLE_REFUEL.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        refuelLoading: false
        //  loading: false
      };
    default:
      return state;
  }
};
