import React, { Component } from "react";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";
import {
  Badge,
  Button,
  Col,
  Pagination,
  Row,
  Spin,
  Table,
  message,
} from "antd";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import { Link } from "react-router-dom";
import LangContext from "modules/shared/context/langContext";
import axios from "axios";

import Forms from "./Forms";

// import * as actions from "../actions";
import { connect } from "react-redux";

const columnStyle = { fontSize: "12px", fontWeight: "400" };
const initialFilter = {
  init_fuel_credit: "",
  withdraw_fuel_limit: "",
  deposit_reason_list: [],
  loading: true,
};

class Index extends Component {
  state = {
    ...initialFilter,
    filterVisible: false,
  };
  handlePageChange = (page) => {
    this.setState({ page });
    this.props.load({
      ...this.state,
      page: page,
      vehicle_visibility: this.props.auth.profile.vehicle_visibility,
    });
  };

  handleStateChange = (type, v) => {
    switch (type) {
      case "vehicle_code":
        this.setState({
          vehicle_code: v.target.value,
        });
        break;
      case "reason_id":
        this.setState({
          reason_id: v,
        });
        break;
      default:
        break;
    }
  };
  generateMsg = (status, msg) => {
    switch (status) {
      case "success":
        message.success(msg);
        break;
      case "fail":
        message.error(msg);
        break;
      default:
        break;
    }
  };
  loadFuelConfig = () => {
    let self = this;
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/fuelsetting`,
        { company_id: this.props.auth.profile.company_id },
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        }
      )
      .then(function(response) {
        self.setState({
          init_fuel_credit: response.data.data.init_fuel_credit,
          withdraw_fuel_limit: response.data.data.withdraw_fuel_limit,
          deposit_reason_list: response.data.data.deposit_reason,
          loading: false,
        });
        // self.loadData(true);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  updateFuelConfig = (value) => {
    let self = this;
    self.setState({ loading: true });
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/fuelsetting/update`,
        {
          company_id: this.props.auth.profile.company_id,
          init_fuel_credit: value.init_fuel_credit,
          withdraw_fuel_limit: value.withdraw_fuel_limit,
          deposit_reason_list: value.deposit_reason_list,
        },
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        }
      )
      .then(function(response) {
        self.setState({
          loading: false,
        });
        self.generateMsg(response.data.status, response.data.msg);
        // self.loadData(true);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  handleOnSubmit = (value) => {
    this.setState({
      init_fuel_credit: value.init_fuel_credit,
      withdraw_fuel_limit: value.withdraw_fuel_limit,
      deposit_reason_list: value.deposit_reason_list,
    });
    this.updateFuelConfig(value);
    console.log("handleOnSubmit", value);
  };
  componentDidMount() {
    this.loadFuelConfig();
  }

  render() {
    return (
      <AuthorizeComponent matching_name="fuelcredit-setting">
        <Spin spinning={this.state.loading}>
          <GeneralStyledContent>
            <Row gutter={24}>
              <Col span={24}>
                <Breadcrumb
                  customeurl={["/fuelcredit-setting", null, null]}
                  match={this.props.match}
                  style={{ margin: "0px 0px 14px 10px" }}
                />
              </Col>
            </Row>
            <Row type="flex" justify="start">
              <Col span={24}>
                <Forms
                  initialValues={{
                    init_fuel_credit: this.state.init_fuel_credit,
                    withdraw_fuel_limit: this.state.withdraw_fuel_limit,
                    deposit_reason_list: this.state.deposit_reason_list,
                  }}
                  handleOnSubmit={this.handleOnSubmit}
                />
              </Col>
            </Row>
          </GeneralStyledContent>
        </Spin>
      </AuthorizeComponent>
    );
  }
}
const mapStateToProps = ({ auth, auth: { profile }, depositfuelcredit }) => ({
  auth,
  profile,
  depositfuelcredit,
});

const mapDispatchToProps = {
  // load: actions.loadDepositFuelCreditHistory.request,
  // loadReason: actions.loadDepositReason.request,
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
