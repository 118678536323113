import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import Forms from "./Forms";
import { Row, Col, Modal } from "antd";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      strAlert: [],
      headerAlert: "",
    };
  }

  matchUser = (values) => {
    this.props.matchUser(values, function(res) {
      if (res.status === "notfound_v1") {
        Modal.error({
          title: "ไม่พบชื่อผู้ใช้ V1",
          content: "",
          okText: "ตกลง",
          width: "500px"
        });
      } else if (res.status === "notfound_v2") {
        Modal.error({
          title: "ไม่พบชื่อผู้ใช้ V2",
          content: "",
          okText: "ตกลง",
          width: "500px"
        });
      } else if (res.status === "duplicate_v1") {
        let str = [];
        for (let i in res.msg) {
          let strEach =
            `ชื่อผู้ใช้: ` +
            res.msg[i].username +
            " อีเมล: " +
            res.msg[i].email;

          str.push(strEach);
        }

        Modal.error({
          title: "พบชื่อผู้ใช้ V1 ซ้ำกัน",
          content: (
            <div>
              {str.map((s) => {
                return <p>{s}</p>;
              })}
            </div>
          ),
          okText: "ตกลง",
          width: "500px"
        });
      } else if (res.status === "duplicate_v2") {
        let str = [];
        for (let i in res.msg) {
          let strEach =
            `ชื่อผู้ใช้: ` +
            res.msg[i].username +
            " อีเมล: " +
            res.msg[i].email;

          str.push(strEach);
        }

        Modal.error({
          title: "พบชื่อผู้ใช้ V2 ซ้ำกัน",
          content: (
            <div>
              {str.map((s) => {
                return <p>{s}</p>;
              })}
            </div>
          ),
          okText: "ตกลง",
          width: "500px"
        });
      } else if (res.status === "manage") {
        Modal.warning({
          title: "มีการจัดการแจ้งซ่อมเรียบร้อยแล้ว",
          content: "",
          okText: "ตกลง",
          width: "500px"
        });
      } else {
        Modal.success({
          title: "จัดการแจ้งซ่อมสำเร็จ",
          content: "",
          okText: "ตกลง",
          width: "500px"
        });
      }
    });
  };

  render() {
    const { manageRepairDeviceLoading } = this.props.manageRepairDevice;

    return (
      <AuthorizeComponent matching_name="manage_repairdevice">
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>

          <Forms
            onSubmit={this.matchUser}
            manageRepairDeviceLoading={manageRepairDeviceLoading}
          />
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

const mapStateToProps = ({ manageRepairDevice, auth: { profile } }) => ({
  manageRepairDevice,
  profile,
});

const mapDispatchToProps = {
  matchUser: actions.matchUser.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
