import React, { useState, useEffect } from "react";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import IndexFilterDrawer from "./IndexFilterDrawer";
import { Link } from "react-router-dom";
import {
  Badge,
  Button,
  Table,
  Form,
  Row,
  Col,
  Input,
  Pagination,
  Spin,
  Popconfirm,
  Tooltip,
  Icon,
  Modal,
  Typography,
} from "antd";
import { loadPolicy, deletePolicy } from "../fetchAPI/apiClient";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { connect } from "react-redux";
import Breadcrumb from "./../../../shared/components/Breadcrumb";

const columnStyle = { fontSize: "12px", fontWeight: "400" };

const Index = (props) => {
  const [isFilter, setIsFilter] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);

  const [dataTable, setDataTable] = useState([]);

  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);

  const [pageSize, setPageSize] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);

  const [orderBy, setOrderBy] = useState("id");

  const [orderType, setOrderType] = useState("desc");

  const [total, setTotal] = useState(0);

  const [filterObj, setFilterObj] = useState({
    name: "",
    plate_no: "",
    vehicle_code: "",
  });

  useEffect(() => {
    let getSubData = [];
    setLoading(true);
    loadPolicy(
      {
        filterObj: {
          name: "",
          plate_no: "",
          vehicle_code: "",
        },
        page: 1,
        pageSize: 10,
        orderBy: orderBy,
        orderType: orderType,
        companyID: props.auth.profile.company_id,
      },
      (statusCode, response) => {
        setDataTable(response.data.data.data);
        setLoading(false);
        setTotal(response.data.data.total);
      }
    );
  }, []);

  useEffect(() => {
    setLoading(true);
    loadPolicy(
      {
        filterObj: {
          name: "",
          plate_no: "",
          vehicle_code: "",
        },
        page: 1,
        pageSize: 10,
        orderBy: orderBy,
        orderType: orderType,
        companyID: props.auth.profile.company_id,
      },
      (statusCode, response) => {
        setDataTable(response.data.data.data);
        setTotal(response.data.data.total);
        setLoading(false);
      }
    );
  }, [orderBy, orderType]);

  const showFilterDrawer = () => {
    setIsFilter(true);
    setFilterVisible(true);
  };

  const onFilterDrawerClose = () => {
    setFilterVisible(false);
  };

  const handlePageChange = (page, pageSize) => {
    setLoading(true);
    setPage(page);
    setCurrentPage(page);
    loadPolicy(
      {
        filterObj: filterObj,
        page: page,
        pageSize: pageSize,
        orderBy: orderBy,
        orderType: orderType,
        companyID: props.auth.profile.company_id,
      },
      (statusCode, response) => {
        setDataTable(response.data.data.data);
        setTotal(response.data.data.total);
        setLoading(false);
      }
    );
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (Object.keys(sorter).length === 0 && sorter.constructor === Object) {
      setOrderBy("name");
      setOrderType("asc");
    } else {
      setOrderBy(sorter.columnKey);
      setOrderType(sorter.order == "descend" ? "desc" : "asc");
    }
  };

  const onFilterButtonClick = (e) => {
    loadPolicy(
      {
        filterObj: filterObj,
        page: 1,
        pageSize: 10,
        orderBy: "name",
        orderType: "asc",
        companyID: props.auth.profile.company_id,
      },
      (statusCode, response) => {
        setDataTable(response.data.data.data);
        setLoading(false);
        setFilterVisible(false);
        setTotal(response.data.data.total);
      }
    );
  };

  const handleFilterObjName = ({ target: { value } }) => {
    setFilterObj((prev) => ({
      ...prev,
      name: value,
    }));
    setIsFilter(value != null && value != "");
  };

  const handleFilterObjPlateNo = ({ target: { value } }) => {
    setFilterObj((prev) => ({
      ...prev,
      plate_no: value,
    }));
    setIsFilter(value != null && value != "");
  };

  const handleFilterObjVehicleCode = ({ target: { value } }) => {
    setFilterObj((prev) => ({
      ...prev,
      vehicle_code: value,
    }));
    setIsFilter(value != null && value != "");
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      width: 50,
      render(text, record, index) {
        return {
          props: {
            style: columnStyle,
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: (
        <LangContext.Consumer>{(i18n) => i18n.n.name}</LangContext.Consumer>
      ),
      dataIndex: "name",
      // width: 400,
      sorter: true,
      render(text, record, index) {
        return {
          props: {
            style: columnStyle,
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: (
        <LangContext.Consumer>{(i18n) => i18n.t.type}</LangContext.Consumer>
      ),
      dataIndex: "type_name",
      // width: 230,
      render(text, record, index) {
        if (text == null) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>-</div>,
          };
        } else {
          return {
            props: {
              style: columnStyle,
            },
            children: (
              <div>
                {text != null && text != "" ? (
                  <LangContext.Consumer>
                    {(i18n) => i18n.policy[text]}
                  </LangContext.Consumer>
                ) : (
                  ""
                )}
                {/* {text} */}
              </div>
            ),
          };
        }
      },
    },
    {
      title: (
        <LangContext.Consumer>{(i18n) => i18n.a.action}</LangContext.Consumer>
      ),
      width: 100,
      key: "action_btn",
      align: "center",
      render: (text, record, index) => {
        return (
          <div>
            <Tooltip
              mouseEnterDelay={1.5}
              placement="left"
              title={
                <LangContext.Consumer>
                  {(i18n) => i18n.e.edit}
                </LangContext.Consumer>
              }
              arrowPointAtCenter
            >
              <Link
                style={{ padding: "0px 15px 0px 5px" }}
                to={`/policy-new/edit/${record.id}`}
              >
                <Icon type="edit" />
              </Link>
            </Tooltip>

            <Tooltip
              placement="left"
              title={
                <LangContext.Consumer>
                  {(i18n) => i18n.d.delete}
                </LangContext.Consumer>
              }
              arrowPointAtCenter
            >
              <Popconfirm
                title={
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.doYouWantToDeleteTheseItems}
                  </LangContext.Consumer>
                }
                onConfirm={() => {
                  let self = this;
                  deletePolicy(record.id, (statusCode, response) => {
                    // console.log("deletePolicy", response.data.status);
                    if (response.data.status === 200) {
                      loadPolicy(
                        {
                          filterObj: filterObj,
                          page: 1,
                          pageSize: 10,
                          orderBy: orderBy,
                          orderType: orderType,
                          companyID: props.auth.profile.company_id,
                        },
                        (statusCode, response) => {
                          setDataTable(response.data.data.data);
                          setTotal(response.data.data.total);
                          setLoading(false);
                          setFilterVisible(false);
                        }
                      );
                    }
                  });
                }}
              >
                <a href="#">
                  <Icon type="delete" />
                </a>
              </Popconfirm>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <GeneralStyledContent>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Breadcrumb
            match={props.match}
            style={{ margin: "0px 0px 14px 10px" }}
          />
        </Col>
        <Col
          span={12}
          style={{
            textAlign: "end",
          }}
        >
          <Link to="/policy-new/create/">
            <Button
              type="primary"
              icon="plus"
              size="small"
              style={{ marginRight: "8px" }}
            >
              <LangContext.Consumer>
                {(i18n) => i18n.a.add}
              </LangContext.Consumer>
            </Button>
          </Link>

          {/* <Badge dot={isFilter} align="right"> */}
          <Button
            icon="filter"
            size="small"
            align="right"
            onClick={showFilterDrawer}
          >
            <LangContext.Consumer>
              {(i18n) => i18n.f.filter}
            </LangContext.Consumer>
          </Button>
          {/* </Badge> */}

          <IndexFilterDrawer
            onClose={onFilterDrawerClose}
            visible={filterVisible}
            onFilterButtonClick={onFilterButtonClick}
            filterObj={filterObj}
            handleFilterObjName={handleFilterObjName}
            handleFilterObjPlateNo={handleFilterObjPlateNo}
            handleFilterObjVehicleCode={handleFilterObjVehicleCode}
          />
        </Col>
      </Row>
      <Row>
        <Spin spinning={loading}>
          <Table
            bordered
            columns={columns}
            dataSource={dataTable}
            size="small"
            pagination={false}
            onChange={handleTableChange}
          />
        </Spin>
      </Row>
      <Row type="flex" justify="end" style={{ padding: "15px" }}>
        <Col pan={24} align="right">
          <Pagination
            defaultPageSize={pageSize}
            size="small"
            current={currentPage}
            total={total}
            onChange={handlePageChange}
          />
        </Col>
      </Row>
    </GeneralStyledContent>
  );
};

export default connect(
  ({ auth }) => ({
    auth,
  }),
  {}
)(Index);
