import React, { Component } from "react";
import {
  Badge,
  Button,
  Col,
  Icon,
  message,
  Pagination,
  Popconfirm,
  Row,
  Spin,
  Table,
  Tooltip,
} from "antd";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import { Link } from "react-router-dom";
import LangContext from "modules/shared/context/langContext";
import * as actions from "../actions";
import { connect } from "react-redux";
import Filter from "./Filter";
import { checkPermissions } from "../../../../lib/helper";

const columnStyle = {
  fontSize: "12px",
  fontWeight: "400",
  background: "white",
};
const initialFilter = {
  filterPhoneNo: "",
  filterPlateNo: "",
  filterCompanyID: "",
  page: 1,
};
class Index extends Component {
  state = {
    ...initialFilter,
    filterVisible: false,
  };
  handlePageChange = (page) => {
    this.setState({ page });
    this.props.load({
      user_id: this.props.profile.id,
      page: page,
    });
  };

  handleStateChange = (type, value) => {
    switch (type) {
      case "filterPlateNo":
        this.setState({
          filterPlateNo: value,
        });
        break;
      default:
        break;
    }
  };

  componentDidMount() {
    this.props.load({ user_id: this.props.profile.id, ...this.state });
  }
  generateMsg = () => {
    switch (this.props.managedashboard.status) {
      case "success":
        message.success(this.props.managedashboard.msg);
        this.props.load({
          user_id: this.props.profile.id,
          page: this.state.page,
        });
        break;
      case "fail":
        message.error(this.props.managedashboard.msg);
        break;
      default:
        break;
    }
  };
  render() {
    let rowSp = this.props.managedashboard.dashboardData.rowSpan;
    const columns = [
      {
        title: "#",
        dataIndex: "rowNumber",
        width: 60,
        render: (text, record, index) => {
          let temp = rowSp[record.display_id + "g" + record.group_id];
          return {
            props: {
              style: columnStyle,
              rowSpan: temp,
            },
            children: (
              <div>
                {(this.props.managedashboard.dashboardData.current_page - 1) *
                  10 +
                  this.props.managedashboard.dashboardData.rowNum[
                    record.display_id
                  ]}
              </div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.manageDashboardMonitoring.DisplayName}
          </LangContext.Consumer>
        ),
        dataIndex: "display_name",
        key: "display_name",
        render(text, record, index) {
          let temp = rowSp[record.display_id + "g" + record.group_id];
          return {
            props: {
              style: columnStyle,
              rowSpan: temp,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.manageDashboardMonitoring.Channel}
          </LangContext.Consumer>
        ),
        dataIndex: "channel",
        key: "channel",
        render(text, record, index) {
          let temp = rowSp[record.display_id + "g" + record.group_id];
          return {
            props: {
              style: columnStyle,
              rowSpan: temp,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.manageDashboardMonitoring.CameraNumber}
          </LangContext.Consumer>
        ),
        dataIndex: "camera_number",
        key: "camera_number",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.manageDashboardMonitoring.GroupName}
          </LangContext.Consumer>
        ),
        dataIndex: "group_name",
        key: "group_name",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.manageDashboardMonitoring.PlateList}
          </LangContext.Consumer>
        ),
        dataIndex: "plate_list",
        key: "plate_list",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.a.action}</LangContext.Consumer>
        ),
        dataIndex: "action",
        key: "action",
        width: 90,
        // fixed: "right",
        render: (text, record, index) => {
          let temp = rowSp[record.display_id + "g" + record.group_id];
          return {
            props: {
              style: columnStyle,
              rowSpan: temp,
            },
            children: (
              <div>
                {
                  this.props.managedashboard.dashboardData.rowNum[
                    record.display_id
                  ]
                }
              </div>
            ),
          };
        },
        render: (text, record, index) => {
          let temp = rowSp[record.display_id + "g" + record.group_id];
          return {
            props: {
              style: columnStyle,
              rowSpan: temp,
            },
            children: (
              <div>
                {checkPermissions(
                  this.props.auth.profile.permissions,
                  "vehicle",
                  "can_edit"
                ) == true ? (
                  <Tooltip
                    placement="left"
                    title={
                      <LangContext.Consumer>
                        {(i18n) => i18n.e.edit}
                      </LangContext.Consumer>
                    }
                    arrowPointAtCenter
                    style={{
                      padding: "0px 1px 0px 0px",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  >
                    <Link
                      to={`/manage-dashboard-monitoring/edit/${record.display_id}`}
                      style={{ padding: "0px 5px 0px 5px" }}
                    >
                      <Icon type="edit" />
                    </Link>
                  </Tooltip>
                ) : (
                  ""
                )}
                {checkPermissions(
                  this.props.auth.profile.permissions,
                  "vehicle",
                  "can_delete"
                ) == true ? (
                  <Tooltip
                    placement="left"
                    title={
                      <LangContext.Consumer>
                        {(i18n) => i18n.d.delete}
                      </LangContext.Consumer>
                    }
                    arrowPointAtCenter
                    style={{
                      padding: "0px 1px 0px 0px",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  >
                    <Popconfirm
                      title={
                        <LangContext.Consumer>
                          {(i18n) => i18n.d.doYouWantToDeleteTheseItems}
                        </LangContext.Consumer>
                      }
                      okText={
                        <LangContext.Consumer>
                          {(i18n) => i18n.o.okay}
                        </LangContext.Consumer>
                      }
                      cancelText={
                        <LangContext.Consumer>
                          {(i18n) => i18n.c.cancel}
                        </LangContext.Consumer>
                      }
                      onConfirm={() => {
                        this.props.delete(
                          {
                            display_id: record.display_id,
                          },
                          () => this.generateMsg()
                        );
                      }}
                    >
                      <a href="#" style={{ padding: "0px 5px 0px 5px" }}>
                        <Icon type="delete" />
                      </a>
                    </Popconfirm>
                  </Tooltip>
                ) : (
                  ""
                )}
              </div>
            ),
          };
        },
      },
    ];

    return (
      <AuthorizeComponent matching_name="manage-dashboard-monitoring">
        <Spin spinning={this.props.managedashboard.loading}>
          <GeneralStyledContent>
            <Row gutter={24}>
              <Col span={24}>
                <Breadcrumb
                  customeurl={["/manage-dashboard-monitoring", null, null]}
                  match={this.props.match}
                  style={{ margin: "0px 0px 14px 10px" }}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={15} sm={20} md={21} lg={22} xl={22}>
                <Link to="/manage-dashboard-monitoring/create">
                  <Button
                    type="primary"
                    icon="plus"
                    size="small"
                    style={{ margin: "0px 0px 14px 0px" }}
                  >
                    <span></span>
                    <LangContext.Consumer>
                      {(i18n) => i18n.n.new}
                    </LangContext.Consumer>
                  </Button>
                </Link>
              </Col>
              <Col span={2}>
                <Badge>
                  <Button
                    icon="filter"
                    size="small"
                    onClick={(e) => {
                      this.setState({
                        filterVisible: true,
                      });
                      this.props.managedashboard.displaySpan = this.state.displaySpan;
                    }}
                  >
                    <LangContext.Consumer>
                      {(i18n) => i18n.f.filter}
                    </LangContext.Consumer>
                  </Button>
                </Badge>
                <Filter
                  {...this.state}
                  // companyLists={this.props.manageDashboardMonitoring.companyLists}
                  onClose={() => this.setState({ filterVisible: false })}
                  handleStateChange={this.handleStateChange}
                  onFilterButtonClick={() => {
                    this.props.load({
                      ...this.state,
                      user_id: this.props.profile.id,
                    });
                    this.setState({ page: 1 });
                  }}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Table
                  id="main-table"
                  // showSorterTooltip={false}
                  bordered
                  columns={columns}
                  dataSource={this.props.managedashboard.dashboardData.data}
                  pagination={false}
                  rowKey="group_id"
                  // scroll={{ y: 500 }}
                />
              </Col>
            </Row>
            <Row type="flex" justify="end" style={{ padding: "15px" }}>
              <Col pan={24} align="right">
                <Pagination
                  defaultPageSize={10}
                  size="small"
                  // current={1}
                  total={this.props.managedashboard.dashboardData.total}
                  current={this.state.page}
                  //   total={this.props.manageDashboardMonitoring.phoneWtihVehicleLists.total}
                  onChange={this.handlePageChange}
                />
              </Col>
            </Row>
          </GeneralStyledContent>
        </Spin>
      </AuthorizeComponent>
    );
  }
}
const mapStateToProps = ({ auth, auth: { profile }, managedashboard }) => ({
  auth,
  profile,
  managedashboard,
});

const mapDispatchToProps = {
  load: actions.loadManageDashboard.request,
  delete: actions.deleteManageDashboard.request,
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
