import { Breadcrumb, Col, message, Row, Spin } from "antd";
import * as actions from "../actions";

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  GeneralStyledContent,
  StyledSearchForm,
} from "../../../../styled/common-styled";
import Forms from "./Forms";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";

// const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

class Create extends Component {
  handleOnSumit = (value) => {
    let formatCreatePhoneNo = {
      ...value,
      user_id: this.props.profile.id,
    };
    // console.log("formatCreatePhoneNo", formatCreatePhoneNo);
    this.props.create(
      formatCreatePhoneNo,
      this.generateMsg
    );
  };

  generateMsg = () => {
    switch (this.props.manageotp.status) {
      case "success":
        message.success(this.props.manageotp.msg);
        this.props.history.goBack();
        break;
      case "fail":
        message.error(this.props.manageotp.msg);
        break;
      default:
        break;
    }
  };

  componentDidMount() {
    this.props.loadCompany();
  }
  render() {
    return (
      <AuthorizeComponent matching_name="manageotp">
        <GeneralStyledContent>
          <Spin spinning={this.props.manageotp.loading}>
            <Row gutter={24}>
              <Col span={24}>
                <Breadcrumb
                  match={this.props.match}
                  style={{ margin: "0px 0px 14px 10px" }}
                />
              </Col>
            </Row>
            <StyledSearchForm>
              <Row type="flex" justify="start">
                <Col span={24}>
                  <Forms
                    initialValues={{
                      phone_number: "",
                      company_id: "",
                      list_vehicle_id: [],
                      remark: null,
                    }}
                    loadCompany={this.props.loadCompany}
                    companyLists={
                      this.props.manageotp.companyLists
                    }
                    vehicleLists={
                      this.props.manageotp.vehicleLists
                    }
                    loadAllVehicle={
                      this.props.loadAllVehicle
                    }
                    onSubmit={this.handleOnSumit}
                  />
                </Col>
              </Row>
            </StyledSearchForm>
          </Spin>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}
const mapStateToProps = ({
  manageotp,
  auth,
  auth: { profile },
}) => ({
  manageotp,
  auth,
  profile,
});

const mapDispatchToProps = {
  loadCompany: actions.loadCompany.request,
  loadAllVehicle: actions.loadAllVehicle.request,
  create: actions.createMatchPhoneNoWithVehicle.request,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Create);
