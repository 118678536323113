import { createTypes, createAction } from "lib/action";

const LOAD_GALLERY = createTypes("loadgallrey", "load");
const CREATE_IMAGE = createTypes("createimage", "create");
const LOAD_GALLERY_DETAIL = createTypes("loadgallreydetail", "load");
const UPDATE_GALLERY_DETAIL = createTypes("updateloadgallreydetail", "update");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
    return {
      type: REMOVE_LISTS
    };
};

const loadGallry = {
    request: (data, loading) =>
        createAction(LOAD_GALLERY.REQUEST, { data, loading}),
    success: (lists, page) => 
        createAction(LOAD_GALLERY.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_GALLERY.FAITLURE)
};

const createImage = {
    request: (data, loading) =>
        createAction(CREATE_IMAGE.REQUEST, { data, loading}),
    success: res => createAction(CREATE_IMAGE.SUCCESS, { res }),
        failure: () => createAction(CREATE_IMAGE.FAITLURE)
};

const loadGallryDetail = {
    request: (data, loading) =>
        createAction(LOAD_GALLERY_DETAIL.REQUEST, { data, loading}),
    success: (lists) => 
        createAction(LOAD_GALLERY_DETAIL.SUCCESS, {lists}),
    failure: () => createAction(LOAD_GALLERY_DETAIL.FAITLURE)
};

const updateGallery = {
    request: (data, loading) =>
        createAction(UPDATE_GALLERY_DETAIL.REQUEST, { data, loading}),
    success: res => createAction(UPDATE_GALLERY_DETAIL.SUCCESS, { res }),
        failure: () => createAction(UPDATE_GALLERY_DETAIL.FAITLURE)
};



export {
    LOAD_GALLERY, loadGallry,
    CREATE_IMAGE, createImage,
    LOAD_GALLERY_DETAIL, loadGallryDetail,
    UPDATE_GALLERY_DETAIL, updateGallery,
    REMOVE_LISTS, removeLists
};