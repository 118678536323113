import {
  REMOVE_CREDENTIALS,
  CHECK_LOGIN,
  removeCredentials,
  LOAD_CREDENTIALS,
  loadCredentials,
  SET_CONFIG_TRACKING_COLUMNS,
  SET_CONFIG_TRACKING_TABLE_CAR_REGIS_COLUMNS,
  SET_CONFIG_TRACKING_TABLE_DRIVER_COLUMNS,
  SET_CONFIG_HOME_DASHBOARD_WIDGET,
  SET_LOCATION_VISIBILITY,
  SET_VEHICLE_VISIBILITY,
  SET_LOCATION_GROUP_VISIBILITY,
  SET_VEHICLE_GROUP_VISIBILITY,
  CHANGE_LANG,
} from "./actions";

const initialState = {
  tokenNoti: null,
  tokenExp: null,
  accessToken: null,
  profile: null,
  done: false,
  status: "",
  msg: "",
  loading: false,
  lang: "th",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CREDENTIALS:
      const {
        payload: { token, tokenExp, profile, done, msg },
      } = action;
      let {
        company_id,
        company_name,
        id,
        is_admin,
        is_super,
        username,
        firstname,
        lastname,
        phone,
        vehicle_visibility,
        location_visibility,
        vehicle_group_visibility,
        permissions,
        config,
        google_map_key,
        longdo_map_key,
        data_visibility_node,
        user_history_logon_id,
      } = profile;

      permissions = JSON.parse(permissions || "{}");
      config = JSON.parse(config || "{}");

      return {
        ...initialState,
        tokenExp: tokenExp,
        accessToken: token,
        profile: {
          ...profile,
          permissions,
          config,
          data_visibility_node,
          user_history_logon_id,
        },
        done: done,
        msg: msg,
      };
    case REMOVE_CREDENTIALS:
      removeCredentials();
      return initialState;
    case CHECK_LOGIN.REQUEST: {
      return {
        loading: true,
      };
    }
    case CHECK_LOGIN.SUCCESS: {
      let {
        payload: {
          token,
          tokenPayload,
          permissions,
          vehicle_list,
          location_list,
          vehicle_group_list,
          location_group_list,
          status,
          msg,
          data_visibility_node,
          user_history_logon_id,
        },
      } = action;

      let {
        exp,
        company_id,
        company_name,
        id,
        is_admin,
        is_super,
        username,
        firstname,
        lastname,
        phone,
        // vehicle_visibility,
        // location_visibility,
        // permissions,
        config,
        google_map_key,
        longdo_map_key,
      } = tokenPayload;

      permissions = JSON.parse(permissions || "{}");
      config = JSON.parse(config || "{}");
      return {
        lang: "th",
        accessToken: token,
        tokenExp: exp,
        profile: {
          ...tokenPayload,
          vehicle_visibility: vehicle_list,
          location_visibility: location_list,
          vehicle_group_visibility: vehicle_group_list,
          location_group_visibility: location_group_list,
          permissions,
          config,
          data_visibility_node,
          user_history_logon_id,
        },
        done: true,
        msg: msg,
        status: status,
        loading: false,
      };
    }
    case CHECK_LOGIN.FAILURE: {
      const {
        payload: { status, msg },
      } = action;

      return {
        ...initialState,
        msg: msg,
        status: status,
        loading: false,
      };
    }

    case SET_CONFIG_TRACKING_COLUMNS:
      const { payload: columns } = action;

      config = JSON.parse(config || "{}");

      return {
        ...state,
        profile: {
          ...state.profile,
          config: {
            ...state.profile.config,
            menu: {
              ...state.profile.config.menu,
              tracking: {
                ...state.profile.config.menu.tracking,
                columns,
              },
            },
          },
        },
      };

    case SET_CONFIG_TRACKING_TABLE_CAR_REGIS_COLUMNS:
      const {
        payload: { CarRegisColumns },
      } = action;
      //   console.log('action Regis', action)
      return {
        ...state,
        profile: {
          ...state.profile,
          config: {
            ...state.profile.config,
            menu: {
              ...state.profile.config.menu,
              tracking: {
                ...state.profile.config.menu.tracking,
                columns_car_plate_no: CarRegisColumns,
              },
            },
          },
        },
      };

    case SET_CONFIG_TRACKING_TABLE_DRIVER_COLUMNS:
      const {
        payload: { DriverColumns },
      } = action;

      return {
        ...state,
        profile: {
          ...state.profile,
          config: {
            ...state.profile.config,
            menu: {
              ...state.profile.config.menu,
              tracking: {
                ...state.profile.config.menu.tracking,
                columns_driver: DriverColumns,
              },
            },
          },
        },
      };

    case SET_CONFIG_HOME_DASHBOARD_WIDGET:
      const { payload: layout } = action;
      config = JSON.parse(config || "{}");

      if (state.done)
        return {
          ...state,
          profile: {
            ...state.profile,
            config: {
              ...state.profile.config,
              home_dashboard: {
                ...state.profile.config.home_dashboard,
                layout,
              },
            },
          },
        };
      else return initialState;

    // add location_visibility in session
    case SET_LOCATION_VISIBILITY: {
      let location_visibility_new = action.payload;
      if (state.done)
        return {
          ...state,
          profile: {
            ...state.profile,
            location_visibility: location_visibility_new,
          },
        };
      else {
        return initialState;
      }
    }

    // add vahicle_visibility in session
    case SET_VEHICLE_VISIBILITY: {
      let vehicle_visibility_new = action.payload;
      if (state.done)
        return {
          ...state,
          profile: {
            ...state.profile,
            vehicle_visibility: vehicle_visibility_new,
          },
        };
      else {
        return initialState;
      }
    }

    // add vehicle_group_visibility in session
    case SET_VEHICLE_GROUP_VISIBILITY: {
      let vehicle_group_visibility_new = action.payload;
      if (state.done) {
        return {
          ...state,
          profile: {
            ...state.profile,
            vehicle_group_visibility: vehicle_group_visibility_new,
          },
        };
      } else {
        return initialState;
      }
    }

    // add vehicle_group_visibility in session
    case SET_LOCATION_GROUP_VISIBILITY: {
      let location_group_visibility_new = action.payload;
      if (state.done) {
        return {
          ...state,
          profile: {
            ...state.profile,
            location_group_visibility: location_group_visibility_new,
          },
        };
      } else {
        return initialState;
      }
    }
    case CHANGE_LANG:
      return {
        ...state,
        lang: action.payload.lang,
      };

    default:
      return state;
  }
};
