import React, { useState, Fragment, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Icon,
  Tabs,
  Input,
  Form,
  Divider,
  Transfer,
  Modal,
  Select,
} from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { Formik, FieldArray, Field, ErrorMessage, move } from "formik";
import * as yup from "yup";
import { loadVehicleByVehicleGroup } from "../../../fetchAPI/apiClient";
const { TabPane } = Tabs;
const { Item } = Form;
const Option = Select.Option;

const LineToken = ({
  current,
  steps,
  prev,
  vehicleList,
  targetKeys,
  createPolicy,
  dataForm,
  dataFromEdit,
  type,
  vehicleSelected,
  vehicleGroup,
  profile,
}) => {
  const [vehicleInfo, setVehicleInfo] = useState([]);
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const [vehicleSource, setVehicleSource] = useState([]);

  useEffect(() => {
    convertVehicleInfo();
  }, [current]);

  const convertVehicleInfo = () => {
    let infoArr = [];
    for (let index = 0; index < targetKeys.length; index++) {
      const element = targetKeys[index];
      vehicleList.filter((item) => {
        if (item.key == element) {
          infoArr.push(item);
        }
      });
      let newArr = infoArr.map((item) => {
        return {
          code: item.code,
          device_code: item.device_code,
          devices_id: item.devices_id,
          disabled: false,
          key: item.key,
          plate_no: item.plate_no,
        };
      });
      setVehicleInfo(newArr);
      setVehicleSource(newArr);
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Formik
          enableReinitialize={true}
          initialValues={{
            setting: dataFromEdit.map((item) => ({
              ...item,
              vehicleGroup: "",
              vehicleSource: vehicleInfo,
            })),
            vehicleSelected: type == "edit" ? vehicleSelected : [],
          }}
          validationSchema={yup.object().shape({
            setting: yup.array().of(
              yup.object().shape({
                line_token: yup
                  .string()
                  .required("LINE TOKEN is a required field."),
              })
            ),
          })}
          onSubmit={(values, { resetForm }) => {
            setVisibleModalConfirm(true);
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleFocus,
            setFieldValue,
            resetForm,
          }) => (
            <Form onSubmit={handleSubmit}>
              <FieldArray
                name="setting"
                render={(arrayHelpers) => (
                  <Row gutter={[24, 24]}>
                    {values.setting == 0 && (
                      <Col span={4}>
                        <Button
                          onClick={() =>
                            arrayHelpers.push({
                              line_token: "",
                              vehicles: [],
                              vehicleGroup: "",
                              vehicleSource: vehicleInfo,
                            })
                          }
                          size="large"
                          style={{
                            background: "#3F87E4 0% 0% no-repeat padding-box",
                            borderRadius: 10,
                            width: "100%",
                            boxShadow: "0px 0px 10px #00000029;",
                            color: "white",
                            marginTop: 13,
                          }}
                        >
                          <Icon type="plus-circle" />
                          เพิ่ม LINE TOKEN
                        </Button>
                      </Col>
                    )}
                    {values.setting && values.setting.length > 0
                      ? values.setting.map((val, idx) => (
                          <Col span={12}>
                            <Row gutter={[16, 16]}>
                              <Col span={12}>
                                <label>LINE TOKEN:</label>
                                <Field
                                  name={`setting[${idx}].line_token`}
                                  render={({ field }) => (
                                    <>
                                      <Input
                                        style={{ width: "100%" }}
                                        value={`setting[${idx}].line_token`}
                                        {...field}
                                      />
                                      <ErrorMessage
                                        name={`setting[${idx}].line_token`}
                                        component="div"
                                        style={{
                                          color: "red",
                                          position: "absolute",
                                          fontSize: "11px",
                                        }}
                                      />
                                    </>
                                  )}
                                />
                              </Col>
                              <Col span={6}>
                                <Button
                                  onClick={() =>
                                    arrayHelpers.push({
                                      line_token: "",
                                      vehicles: [],
                                      vehicleGroup: "",
                                      vehicleSource: vehicleInfo,
                                    })
                                  }
                                  size="large"
                                  style={{
                                    background:
                                      "#3F87E4 0% 0% no-repeat padding-box",
                                    borderRadius: 10,
                                    width: "100%",
                                    boxShadow: "0px 0px 10px #00000029;",
                                    color: "white",
                                    marginTop: 13,
                                  }}
                                >
                                  <Icon type="plus-circle" />
                                  เพิ่ม LINE TOKEN
                                </Button>
                              </Col>
                              <Col span={6}>
                                <Button
                                  onClick={() => {
                                    arrayHelpers.remove(idx);
                                  }}
                                  style={{
                                    border: "1px solid #FF0000",
                                    borderRadius: 10,
                                    width: "100%",
                                    boxShadow: "0px 0px 10px #00000029;",
                                    color: "#FF0000",
                                    marginTop: 13,
                                  }}
                                  size="large"
                                >
                                  ลบ
                                </Button>
                              </Col>
                              <Col span={24}>
                                <div
                                  style={{ fontSize: 16, fontWeight: "bold" }}
                                >
                                  <LangContext.Consumer>
                                    {(i18n) => i18n.v.vehicleGroup}
                                  </LangContext.Consumer>
                                </div>
                                <Select
                                  style={{ width: "100%" }}
                                  placeholder="กรุณาเลือก"
                                  optionFilterProp="children"
                                  value={values.setting[idx]["vehicleGroup"]}
                                  onChange={(e) => {
                                    let vehicleArr = [];
                                    loadVehicleByVehicleGroup(
                                      {
                                        company_id: profile.company_id,
                                        vehicle_visibility:
                                          profile.vehicle_visibility,
                                        vehicle_groups_id: e,
                                      },
                                      (statusCode, response) => {
                                        if (response.data.data.length > 0) {
                                          response.data.data.map((item) => {
                                            if (
                                              vehicleInfo
                                                .map((obj) => obj.key)
                                                .includes(item.id)
                                            ) {
                                              vehicleArr.push(item.id);
                                            }
                                          });
                                        }
                                        let checkDisable = vehicleInfo
                                          .filter((item) =>
                                            vehicleArr.includes(item.key)
                                          )
                                          .map((item) => item.key);
                                        let newArr = vehicleSource.map(
                                          (item) => {
                                            return {
                                              code: item.code,
                                              device_code: item.device_code,
                                              devices_id: item.devices_id,
                                              disabled: checkDisable.includes(
                                                item.key
                                              )
                                                ? false
                                                : true,
                                              key: item.key,
                                              plate_no: item.plate_no,
                                            };
                                          }
                                        );
                                        const sortedArray = newArr.sort(
                                          (item1, item2) => {
                                            if (
                                              item1.disabled === item2.disabled
                                            ) {
                                              return 0;
                                            }
                                            return item1.disabled ? 1 : -1;
                                          }
                                        );
                                        setVehicleSource(
                                          e == "" ? vehicleInfo : sortedArray
                                        );
                                        arrayHelpers.replace(idx, {
                                          line_token:
                                            values.setting[idx]["line_token"],
                                          vehicles:
                                            values.setting[idx]["vehicles"],
                                          vehicleGroup: e,
                                          vehicleSource:
                                            e == "" ? vehicleInfo : sortedArray,
                                        });
                                      }
                                    );
                                  }}
                                >
                                  <Option value={""}>
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.a.all}
                                    </LangContext.Consumer>
                                  </Option>
                                  {vehicleGroup.map((item) => (
                                    <Option value={item.id}>{item.name}</Option>
                                  ))}
                                </Select>
                                <Divider />
                                <Field
                                  name={`setting[${idx}].vehicles`}
                                  render={({ field }) => (
                                    <>
                                      <Transfer
                                        {...field}
                                        lazy={false}
                                        dataSource={
                                          values.setting[idx]["vehicleSource"]
                                        }
                                        titles={["เลือก", "เลือก"]}
                                        targetKeys={values.setting[idx][
                                          "vehicleSource"
                                        ]
                                          .filter((item) =>
                                            values.setting[idx][
                                              "vehicles"
                                            ].includes(item.key)
                                          )
                                          .sort((item1, item2) => {
                                            if (
                                              item1.disabled === item2.disabled
                                            ) {
                                              return 0;
                                            }
                                            return item1.disabled ? 1 : -1;
                                          })
                                          .map((item) => item.key)}
                                        onChange={(
                                          nextTargetKeys,
                                          direction,
                                          moveKeys
                                        ) => {
                                          if (direction === "left") {
                                            arrayHelpers.replace(idx, {
                                              line_token:
                                                values.setting[idx][
                                                  "line_token"
                                                ],
                                              vehicles: nextTargetKeys,
                                              vehicleGroup:
                                                values.setting[idx][
                                                  "vehicleGroup"
                                                ],
                                              vehicleSource:
                                                values.setting[idx][
                                                  "vehicleSource"
                                                ],
                                            });
                                          } else {
                                            arrayHelpers.replace(idx, {
                                              line_token:
                                                values.setting[idx][
                                                  "line_token"
                                                ],
                                              vehicles: nextTargetKeys,
                                              vehicleGroup:
                                                values.setting[idx][
                                                  "vehicleGroup"
                                                ],
                                              vehicleSource:
                                                values.setting[idx][
                                                  "vehicleSource"
                                                ],
                                            });
                                          }
                                        }}
                                        filterOption={(inputValue, item) =>
                                          item.plate_no.indexOf(inputValue) !==
                                          -1
                                        }
                                        render={(item) => item.plate_no}
                                        listStyle={{
                                          width: "44%",
                                          height: 400,
                                        }}
                                        showSearch
                                      />
                                    </>
                                  )}
                                />
                              </Col>
                            </Row>
                          </Col>
                        ))
                      : null}{" "}
                  </Row>
                )}
              />
              <Col span={24}>
                <Button
                  onClick={() => {
                    prev();
                  }}
                  style={{ marginRight: 10 }}
                >
                  <LangContext.Consumer>
                    {(i18n) => i18n.b.back}
                  </LangContext.Consumer>
                </Button>
                <Button htmlType="submit">
                  <LangContext.Consumer>
                    {(i18n) => i18n.s.submit}
                  </LangContext.Consumer>
                </Button>
              </Col>
              <Modal
                title={null}
                centered
                visible={visibleModalConfirm}
                onCancel={() => setVisibleModalConfirm(false)}
                footer={null}
              >
                <Row gutter={[16, 16]}>
                  <Col span={24} align="center">
                    <Icon
                      type="info-circle"
                      style={{ fontSize: 50, color: "#3F87E4" }}
                    />
                  </Col>
                  <Col span={24} align="center">
                    <p
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        fontSize: 24,
                        margin: 0,
                      }}
                    >
                      โปรดตรวจสอบข้อมูล
                    </p>
                  </Col>
                  <Col span={24} align="center">
                    <p style={{ margin: 0 }}>
                      โปรดตรวจสอบข้อมูลของท่านอีกครั้งก่อนบันทึกข้อมูล
                    </p>
                  </Col>
                  <Col span={24} align="center">
                    <p style={{ margin: 0 }}>เพื่อความถูกต้องของข้อมูลท่าน</p>
                  </Col>
                  <Col span={24} align="center">
                    <Button
                      style={{ width: 140, marginRight: 15 }}
                      onClick={() => setVisibleModalConfirm(false)}
                    >
                      <LangContext.Consumer>
                        {(i18n) => i18n.c.cancel}
                      </LangContext.Consumer>
                    </Button>
                    <Button
                      type="primary"
                      style={{ width: 140 }}
                      onClick={() => {
                        createPolicy(values);
                      }}
                    >
                      <LangContext.Consumer>
                        {(i18n) => i18n.c.confirm}
                      </LangContext.Consumer>
                    </Button>
                  </Col>
                </Row>
              </Modal>
            </Form>
          )}
        />
      </Col>
    </Row>
  );
};

export default LineToken;
