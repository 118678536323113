import { createTypes, createAction } from "lib/action";

const LOAD_SUMMARYKPIBYDRIVER = createTypes("summarykpibydriver", "load");
const LOAD_SUMMARYKPIBYDRIVER_PREVIEW = createTypes(
  "summarykpibydriver_preview",
  "load"
);
const LOAD_DRIVER_KPI = createTypes("driver_summarykpi", "load");
const LOAD_VEHICLE_TYPE_SUMMARYKPI = createTypes(
  "vehicletype_summarykpi",
  "load"
);

const loadSummarykpi = {
  request: (data, loading) =>
    createAction(LOAD_SUMMARYKPIBYDRIVER.REQUEST, { data, loading }),
  success: data => createAction(LOAD_SUMMARYKPIBYDRIVER.SUCCESS, { data }),
  failure: () => createAction(LOAD_SUMMARYKPIBYDRIVER.FAILURE)
};
const loadSummarykpiByDriverpreview = {
  request: (data, preview_loading) =>
    createAction(LOAD_SUMMARYKPIBYDRIVER_PREVIEW.REQUEST, {
      data,
      preview_loading
    }),
  success: data =>
    createAction(LOAD_SUMMARYKPIBYDRIVER_PREVIEW.SUCCESS, { data }),
  failure: () => createAction(LOAD_SUMMARYKPIBYDRIVER_PREVIEW.FAILURE)
};

const loadDriver = {
  request: loading_vehicle =>
    createAction(LOAD_DRIVER_KPI.REQUEST, {
      loading_vehicle
    }),
  success: data => createAction(LOAD_DRIVER_KPI.SUCCESS, { data }),
  failure: () => createAction(LOAD_DRIVER_KPI.FAILURE)
};

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_SUMMARYKPI.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_TYPE_SUMMARYKPI.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_SUMMARYKPI.FAILURE)
};

export {
  loadSummarykpi,
  LOAD_SUMMARYKPIBYDRIVER,
  loadSummarykpiByDriverpreview,
  LOAD_SUMMARYKPIBYDRIVER_PREVIEW,
  loadDriver,
  LOAD_DRIVER_KPI,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_SUMMARYKPI
};
