import { createTypes, createAction } from "lib/action";

const LOAD_TEMPORARY_TRACKING = createTypes("temporarytracking", "load");
const CREATE_TEMPORARY_TRACKING = createTypes("temporarytracking", "create");
const UPDATE_TEMPORARY_TRACKING = createTypes("temporarytracking", "update");


const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists(){
    return {
        type: REMOVE_LISTS
    };
}


const loadTemporaryTracking = {
    request: (data, loading) =>
      createAction(LOAD_TEMPORARY_TRACKING.REQUEST, { data, loading }),
    success: (lists, page) =>
      createAction(LOAD_TEMPORARY_TRACKING.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_TEMPORARY_TRACKING.FAITLURE)
  };

const createTemporaryTracking = {
  request: (data, loading) =>
    createAction(CREATE_TEMPORARY_TRACKING.REQUEST, {data, loading}),
  success: res => createAction(CREATE_TEMPORARY_TRACKING.SUCCESS, { res }),
    failure: () => createAction(CREATE_TEMPORARY_TRACKING.FAILURE)
}

const upDateTemporaryTracking = {
  request: (data, loading) =>
    createAction(UPDATE_TEMPORARY_TRACKING.REQUEST, {data, loading}),
  success: res => createAction(UPDATE_TEMPORARY_TRACKING.SUCCESS, { res }),
    failure: () => createAction(UPDATE_TEMPORARY_TRACKING.FAILURE)
};

export{
    loadTemporaryTracking,
    LOAD_TEMPORARY_TRACKING,

    createTemporaryTracking,
    CREATE_TEMPORARY_TRACKING,

    upDateTemporaryTracking,
    UPDATE_TEMPORARY_TRACKING,

    REMOVE_LISTS,
    removeLists
};