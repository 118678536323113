import React from "react";
import { Switch, Route } from "react-router-dom";
import TripAdd from "./Add";
import TripEdit from "./Edit";
import Trip from "./Index";

export default () => (
  <Switch>
    <Route path="/deliverytrip/create/" component={TripAdd} />
    <Route path="/deliverytrip/edit/:id" component={TripEdit} />
    <Route path="/deliverytrip" component={Trip} />
  </Switch>
);
