import { Col, message, Row, Spin, AutoComplete, Button } from "antd";
import * as actions from "../actions";
import { loadVehicleFuelCrediHistory } from "../../deposit-fuel-credit/actions";
import { Link } from "react-router-dom";

import React, { Component } from "react";
import { connect } from "react-redux";
import { debounce } from "debounce";

import {
  GeneralStyledContent,
  StyledSearchForm,
} from "../../../../styled/common-styled";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";
import Breadcrumb from "../../../shared/components/Breadcrumb";

import SelectForm from "./SelectForm";
import Forms from "./Forms";

class Create extends Component {
  state = {
    keyword: "",
    vehicle_id: "",
  };
  handleOnSumit = (value) => {
    this.props.update(
      {
        ...value,
        user_id: this.props.profile.id,
        parking_duration: this.props.withdrawfuelcredit.vehicleInfo
          .parking_duration,
        distance: this.props.withdrawfuelcredit.vehicleInfo.distance,
      },
      this.generateMsg
    );
  };

  handleOnSearchVehicle = (keyword) => {
    this.setState({ keyword: keyword });
    let autoComVehicle = {
      keyword: keyword,
      vehicle_visibility: this.props.auth.profile.vehicle_visibility,
    };
    debounce(this.props.loadVehicleList(autoComVehicle), 1500);
  };

  handleOnSelect = (value) => {
    this.setState({ vehicle_id: value, keyword: value });
  };

  handleOnsubmitVehicleInfo = () => {
    this.props.loadInfo({ vehicle_id: this.state.vehicle_id });
  };
  generateMsg = () => {
    switch (this.props.withdrawfuelcredit.status) {
      case "success":
        message.success(this.props.withdrawfuelcredit.msg);
        this.props.history.goBack();
        break;
      case "fail":
        message.error(this.props.withdrawfuelcredit.msg);
        break;
      default:
        break;
    }
  };
  componentDidMount() {
    let autoComVehicle = {
      keyword: "",
      vehicle_visibility: this.props.auth.profile.vehicle_visibility,
    };
    this.props.loadVehicleList(autoComVehicle);
  }
  render() {
    return (
      <AuthorizeComponent matching_name="managewithdrawfuelcredit">
        <Spin
          spinning={
            this.props.depositfuelcredit.loading ||
            this.props.withdrawfuelcredit.loading
          }
        >
          <GeneralStyledContent>
            <Row gutter={24}>
              <Col span={24}>
                <Breadcrumb
                  // customeurl={["/withdraw-fuel-credit/create", null, null]}
                  match={this.props.match}
                  style={{ margin: "0px 0px 14px 10px" }}
                />
              </Col>
            </Row>
            <SelectForm
              keyword={this.state.keyword}
              vehicleList={this.props.depositfuelcredit.vehicleList}
              handleOnSearchVehicle={this.handleOnSearchVehicle}
              handleOnSelect={this.handleOnSelect}
              handleOnSumit={this.handleOnsubmitVehicleInfo}
              disableForm={this.props.withdrawfuelcredit.showForm}
            />
            {this.props.withdrawfuelcredit.showForm ? (
              <StyledSearchForm>
                <Row type="flex" justify="start">
                  <Col span={24}>
                    <Forms
                      initialValues={{
                        vehicle_id: this.props.withdrawfuelcredit.vehicleInfo
                          .id,
                        remark: null,
                        withdraw_credit: null,
                        current_credit: this.props.withdrawfuelcredit
                          .vehicleInfo.current_fuel_credit,
                      }}
                      vehicleInfo={this.props.withdrawfuelcredit.vehicleInfo}
                      onSubmit={this.handleOnSumit}
                    />
                  </Col>
                </Row>
              </StyledSearchForm>
            ) : (
              <></>
            )}
          </GeneralStyledContent>
        </Spin>
      </AuthorizeComponent>
    );
  }
}
const mapStateToProps = ({
  depositfuelcredit,
  withdrawfuelcredit,
  auth,
  auth: { profile },
}) => ({
  depositfuelcredit,
  withdrawfuelcredit,
  auth,
  profile,
});

const mapDispatchToProps = {
  loadVehicleList: loadVehicleFuelCrediHistory.request,
  loadInfo: actions.loadVehicleCreditInfo.request,
  update: actions.updateWithdrawFuelCredit.request,
};
export default connect(mapStateToProps, mapDispatchToProps)(Create);
