import React, { Component } from "react";
// import { Connector } from "mqtt-react";
import { hot } from "react-hot-loader";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import history from "lib/history";
import configureStore from "modules/configureStore";
import Layout from "modules/ui/components/Layout";
import { ErrorBoundary } from "./modules/ui/components/ErrorBoundary";

const store = configureStore();
// <Connector
//   mqttProps={{
//     protocol: "ws",
//     host: "mqtt.iammami.xyz",
//     port: "8080/mqtt",
//     username: "device",
//     password: "qazwsx123"
//   }}
// >

class App extends Component {
  render() {
    return (
      // <ErrorBoundary>
      <Provider store={store}>
        <Router history={history}>
          <Layout />
        </Router>
      </Provider>
      // </ErrorBoundary>
    );
  }
}

export default hot(module)(App);
