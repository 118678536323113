import axios from "axios";

const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("access-token")}`,
  },
});

// Centralized error handling function (example)
const handleError = (error, callback) => {
  // Handle error here or pass it to callback
  console.error("API Error:", error);
  callback(error.response.status, error.response.data);
};

export async function loadVoiceManagement(params, callback) {
  try {
    const res = await axiosClient.post("/policy-audio", params);
    callback(200, res);
  } catch (error) {
    handleError(error, callback);
  }
}

export async function deleteVoiceManagement(id, callback) {
  try {
    const res = await axiosClient.delete(`/policy-audio/${id}`);
    callback(200, res);
  } catch (error) {
    handleError(error, callback);
  }
}

export async function createVoiceManagement(params, callback) {
  try {
    const res = await axiosClient.post("/policy-audio/save", params);
    callback(200, res);
  } catch (error) {
    handleError(error, callback);
  }
}

export async function loadVoiceManagementById(id, callback) {
  try {
    const res = await axiosClient.get(`/policy-audio/${id}`);
    callback(200, res);
  } catch (error) {
    handleError(error, callback);
  }
}

export async function updateVoiceManagement(id, params, callback) {
  try {
    const res = await axiosClient.patch(`/policy-audio/${id}`, params);
    callback(200, res);
  } catch (error) {
    handleError(error, callback);
  }
}

export default axiosClient;
