import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getlocalstorage } from "./../../../constants/local_storage";
//import { push } from "connected-react-router";

import * as actions from "./actions";
import moment from "moment";

function* loadDistfromlastmaintenance(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );
  const USER_ID = getlocalstorage(localStorage.getItem("profile"), "id");

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  const {
    data: { list_vehicle_id, list_part_type_id, vehicle_type_id, type_file },
  } = action.payload;

  let apiUrl = `${process.env.REACT_APP_API_REPORT_GOLANG_MONGO}/reports/go/distfromlastmaintenance`;

  if (COMPANY_ID === 48) {
    apiUrl = `${process.env.REACT_APP_API_REPORT_GOLANG_MONGO}/reports/go/distfromlastmaintenanceforcpf`;
  }

  try {
    const res = yield call(axios.post, apiUrl, {
      list_vehicle_id,
      list_part_type_id,
      company_id: COMPANY_ID,
      vehicle_type_id: vehicle_type_id === "" ? 0 : vehicle_type_id,
      user_id: USER_ID,
      type_file,
      vehicle_visibility,
    });

    yield put(actions.loadDistfromlastmaintenance.success(res.data));
    window.location = res.data.result;
    // window.location = res.data.data;
  } catch (err) {
    yield put(actions.loadDistfromlastmaintenance.failure());
  }
}

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  const { vehicletypeID } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebygroupid`,
      {
        companyID: COMPANY_ID,
        vehicletypeID: vehicletypeID,
        allow_vehicles: vehicle_visibility,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadVehicle.success(res.data));
  } catch (err) {
    yield put(actions.loadVehicle.failure());
  }
}

function* loadVehicleType(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getgroupvehicle`,
      {
        company_id: COMPANY_ID,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(actions.loadVehicleType.success(res.data));
  } catch (err) {
    yield put(actions.loadVehicleType.failure());
  }
}

function* loadPartType(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getparttypes`,
      {
        company_id: COMPANY_ID,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(actions.loadPartType.success(res.data));
  } catch (err) {
    yield put(actions.loadPartType.failure());
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(
      actions.LOAD_DISTFROMLASTMAINTENANCE.REQUEST,
      loadDistfromlastmaintenance
    ),
    takeEvery(
      actions.LOAD_VEHICLE_DISTFROMLASTMAINTENANCE.REQUEST,
      loadVehicle
    ),
    takeEvery(
      actions.LOAD_VEHICLE_TYPE_DISTFROMLASTMAINTENANCE.REQUEST,
      loadVehicleType
    ),
    takeEvery(actions.LOAD_PART_TYPE.REQUEST, loadPartType),
  ]);
}

export {
  loadDistfromlastmaintenance,
  loadVehicle,
  loadVehicleType,
  loadPartType,
};
