import {
  SUMMARY_DRIVING_LOG_EXPORT,
  SUMMARY_DRIVING_LOG_LOAD_VEHICLE,
  SUMMARY_DRIVING_LOG_LOAD_VEHICLE_TYPE,
} from './actions'

const initialState = {
  export_url: '',
  check_export: false,

  vehicleLists: [],
  check_vehicle_list: false,

  vehicleTypeLists: [],
  check_vehicle_type_list: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SUMMARY_DRIVING_LOG_EXPORT.REQUEST:
      return {
        ...state,
        check_export: true,
      }
    case SUMMARY_DRIVING_LOG_EXPORT.SUCCESS:
      return {
        ...state,
        export_url: action.payload.data.data,
        check_export: false,
      }
    case SUMMARY_DRIVING_LOG_LOAD_VEHICLE.REQUEST:
      return {
        ...state,
        check_vehicle_list: true,
        vehicleLists: [],
      }
    case SUMMARY_DRIVING_LOG_LOAD_VEHICLE.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        check_vehicle_list: false,
      }

    case SUMMARY_DRIVING_LOG_LOAD_VEHICLE_TYPE.REQUEST:
      return {
        ...state,
        check_vehicle_type_list: true,
        vehicleTypeLists: [],
      }

    case SUMMARY_DRIVING_LOG_LOAD_VEHICLE_TYPE.SUCCESS:
      return {
        ...state,
        check_vehicle_type_list: false,
        vehicleTypeLists: action.payload.data.data,
      }
    default:
      return state
  }
}
