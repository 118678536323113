import React from "react";
import * as moment from "moment";
import {
  Button,
  Select,
  Form,
  Row,
  Col,
  Input,
  DatePicker,
  Radio,
  Drawer,
  Slider,
} from "antd";

import LangContext, { i18n } from "modules/shared/context/langContext";

const Option = Select.Option;
const subtype = { safety: ["driving", "parking"] };

const categories = {
  driving: ["speed", "driving_hour", "abnormal_behavior"],
  parking: ["prohibited_area"],
};

const IndexFilterDrawer = (props) => (
  <Drawer
    title={
      <LangContext.Consumer>{(i18n) => i18n.f.filter}</LangContext.Consumer>
    }
    width={520}
    placement="right"
    onClose={props.onClose}
    maskClosable={true}
    visible={props.visible}
    style={{
      height: "100%",
      overflow: "auto",
      paddingBottom: 53,
    }}
  >
    <Form>
      <Form.Item
        label={
          <LangContext.Consumer>{(i18n) => i18n.n.name}</LangContext.Consumer>
        }
      >
        <Input onChange={props.handleFilterObjName} />
      </Form.Item>

      <Form.Item
        label={
          <LangContext.Consumer>{(i18n) => i18n.t.type}</LangContext.Consumer>
        }
      >
        <Select
          defaultValue={"-"}
          onChange={props.handleFilterObjType}
          // style={{ width: "170%" }}
        >
          <Option key="-">
            <LangContext.Consumer>{(i18n) => i18n.a.all}</LangContext.Consumer>
          </Option>
          <Option key="speed_limit">
            <LangContext.Consumer>
              {(i18n) => i18n.variable.speed_limit}
            </LangContext.Consumer>
          </Option>
          <Option key="speed_limit_area">
            <LangContext.Consumer>
              {(i18n) => i18n.variable.speed_limit_area}
            </LangContext.Consumer>
          </Option>
          <Option key="no_entry">
            <LangContext.Consumer>
              {(i18n) => i18n.variable.no_entry}
            </LangContext.Consumer>
          </Option>
          <Option key="no_exit">
            <LangContext.Consumer>
              {(i18n) => i18n.variable.no_exit}
            </LangContext.Consumer>
          </Option>
          <Option key="stop_with_engine_off">
            <LangContext.Consumer>
              {(i18n) => i18n.variable.stop_with_engine_off}
            </LangContext.Consumer>
          </Option>
        </Select>
      </Form.Item>

      <Form.Item
        label={
          <LangContext.Consumer>
            {(i18n) => i18n.c.criticalLevel}
          </LangContext.Consumer>
        }
      >
        <Select
          defaultValue={"-"}
          onChange={props.handleFilterObjCriticalLevel}
          // style={{ width: "170%" }}
        >
          <Option key="-">
            <LangContext.Consumer>{(i18n) => i18n.a.all}</LangContext.Consumer>
          </Option>
          <Option key="high">
            <LangContext.Consumer>{(i18n) => i18n.h.high}</LangContext.Consumer>
          </Option>
          <Option key="medium">
            <LangContext.Consumer>
              {(i18n) => i18n.m.medium}
            </LangContext.Consumer>
          </Option>
          <Option key="low">
            <LangContext.Consumer>{(i18n) => i18n.l.low}</LangContext.Consumer>
          </Option>
        </Select>
      </Form.Item>

      <Button
        style={{
          marginRight: 8,
        }}
        onClick={props.onClose}
      >
        <LangContext.Consumer>{(i18n) => i18n.c.cancel}</LangContext.Consumer>
      </Button>

      <Button type="primary" onClick={props.onFilterButtonClick}>
        <LangContext.Consumer>{(i18n) => i18n.a.apply}</LangContext.Consumer>
      </Button>
    </Form>
  </Drawer>
);
export default IndexFilterDrawer;
