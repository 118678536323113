import { createTypes, createAction } from "lib/action";

const LOAD_TRUCKNOTUSE = createTypes("trucknotuse", "load");
const LOAD_VEHICLE_TRUCKNOTUSE = createTypes(
  "vehicle_trucknotuse",
  "load"
);
const LOAD_VEHICLE_TYPE_TRUCKNOTUSE = createTypes("vehicletype_trucknotuse", "load");

const loadTrucknotuse = {
  request: (data, loading) =>
    createAction(LOAD_TRUCKNOTUSE.REQUEST, { data, loading }),
  success: data => createAction(LOAD_TRUCKNOTUSE.SUCCESS, { data }),
  failure: () => createAction(LOAD_TRUCKNOTUSE.FAILURE)
};

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_TRUCKNOTUSE.REQUEST, {
      vehicletypeID,
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_VEHICLE_TRUCKNOTUSE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TRUCKNOTUSE.FAILURE)
};

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_TRUCKNOTUSE.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_TYPE_TRUCKNOTUSE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_TRUCKNOTUSE.FAILURE)
};

export {
  loadTrucknotuse,
  LOAD_TRUCKNOTUSE,
  loadVehicle,
  LOAD_VEHICLE_TRUCKNOTUSE,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_TRUCKNOTUSE
};
