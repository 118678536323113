import axios from "axios";
import {
  all,
  put,
  call,
  takeEvery,
} from "redux-saga/effects";
import { getErrorMsg } from "lib/fetch";

import * as actions from "./actions";

function* loadCompany(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { text } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterDataUser_companybyname`,
      {
        text,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    // console.log("companyLists", res.data.data);
    yield put(actions.loadCompany.success(res.data.data));
  } catch (err) {
    yield put(actions.loadCompany.failure());
  }
}

function* loadCompanyByID(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { id } = action.payload;
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterDataUser_company/${id}`,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    // console.log("companyLists", res.data.data);
    yield put(actions.loadCompany.success(res.data.data));
  } catch (err) {
    yield put(actions.loadCompany.failure());
  }
}

function* loadAllVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { company_id } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getallvehicle`,
      {
        company_id,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    // console.log("vehicleLists", res.data);
    yield put(actions.loadAllVehicle.success(res.data));
  } catch (err) {
    yield put(actions.loadAllVehicle.failure());
  }
}

function* loadPhoneNoWithVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { data } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/matchvehiclewithphoneno`,
      {
        ...data,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    // console.log("phoneLists", res.data);
    yield put(
      actions.loadPhoneNoWithVehicle.success(res.data)
    );
  } catch (err) {
    yield put(actions.loadPhoneNoWithVehicle.failure());
  }
}

function* loadPhoneNoWithVehicleByPhoneNo(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { phone_no, loadSuccess } = action.payload;
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/matchvehiclewithphoneno/${phone_no}`,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(
      actions.loadPhoneNoWithVehicleByPhoneNo.success(
        res.data
      )
    );
    loadSuccess();
  } catch (err) {
    yield put(
      actions.loadPhoneNoWithVehicleByPhoneNo.failure()
    );
  }
}

function* createMatchPhoneNoWithVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { data, generateMsg } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/matchvehiclewithphoneno/create`,
      {
        ...data,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(
      actions.createMatchPhoneNoWithVehicle.success(
        res.data
      )
    );
  } catch (err) {
    yield put(
      actions.createMatchPhoneNoWithVehicle.failure(
        getErrorMsg(err)
      )
    );
  }
  generateMsg();
}

function* updateMatchPhoneNoWithVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { data, generateMsg } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/matchvehiclewithphoneno/update`,
      {
        ...data,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(
      actions.updateMatchPhoneNoWithVehicle.success(
        res.data
      )
    );
  } catch (err) {
    yield put(
      actions.updateMatchPhoneNoWithVehicle.failure(
        getErrorMsg(err)
      )
    );
  }
  generateMsg();
}

function* deleteMatchPhoneNoWithVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { data, generateMsg } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/matchvehiclewithphoneno/delete`,
      {
        ...data,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(
      actions.deleteMatchPhoneNoWithVehicle.success(
        res.data
      )
    );
  } catch (err) {
    yield put(
      actions.deleteMatchPhoneNoWithVehicle.failure(
        getErrorMsg(err)
      )
    );
  }
  generateMsg();
}

export default function* watchmanageotp() {
  yield all([
    takeEvery(actions.LOAD_COMPANY.REQUEST, loadCompany),
    takeEvery(
      actions.LOAD_ALL_VEHICLE.REQUEST,
      loadAllVehicle
    ),

    takeEvery(
      actions.LOAD_PHONENO_WITH_VEHICLE.REQUEST,
      loadPhoneNoWithVehicle
    ),
    takeEvery(
      actions.LOAD_PHONENO_WITH_VEHICLE_BY_PHONENO.REQUEST,
      loadPhoneNoWithVehicleByPhoneNo
    ),
    takeEvery(
      actions.LOAD_COMPANYBYID.REQUEST,
      loadCompanyByID
    ),
    takeEvery(
      actions.CREATE_MATCH_PHONENO_WITH_VEHICLE.REQUEST,
      createMatchPhoneNoWithVehicle
    ),
    takeEvery(
      actions.UPDATE_MATCH_PHONENO_WITH_VEHICLE.REQUEST,
      updateMatchPhoneNoWithVehicle
    ),
    takeEvery(
      actions.DELETE_MATCH_PHONENO_WITH_VEHICLE.REQUEST,
      deleteMatchPhoneNoWithVehicle
    ),
  ]);
}
export {
  loadCompany,
  loadAllVehicle,
  loadPhoneNoWithVehicle,
  loadPhoneNoWithVehicleByPhoneNo,
  loadCompanyByID,
  createMatchPhoneNoWithVehicle,
  updateMatchPhoneNoWithVehicle,
  deleteMatchPhoneNoWithVehicle,
};
