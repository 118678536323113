import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter
} from "react-router-dom";
import Login from "modules/auth/components/Login";
import { connect } from "react-redux";

class PrivateRoute extends Component {
  checkRout = () => {
    const { component, path, done, props, ...rest } = this.props;

    if (done == true) {
      return <Route component={component} path={path} {...rest} {...props} />;
    } else {
      return <Route exact path="/" component={Login} />;
    }
  };

  render() {
    return this.checkRout();
  }
}

export default PrivateRoute;
