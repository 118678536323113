import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";

import {
  Button,
  Form,
  Input,
  Row,
  Col,
  message,
  Spin,
  DatePicker,
  Menu,
  Icon,
  AutoComplete,
  Dropdown
} from "antd";

import LangContext, { i18n } from "modules/shared/context/langContext";



const FormItem = Form.Item;
const Option = AutoComplete.Option;

export default class Forms extends Component {
  state = {
    driverList: [],
    codeList: [],
    driver_code: "",
    driver_name: "",

  };

  componentDidMount() {
    const { loadDriver, call } = this.props;
    loadDriver();
    if (call == "schedule") {
      if (this.props.dataedit != "") {
        let obj = JSON.parse(this.props.dataedit);
        this.setState({
          driver_code: obj.driver_code,
          driver_name: obj.driver_name
        });
      }
    }
  }

  componentWillReceiveProps = nextProps => {
    const { driverLists } = nextProps;
    const codelists = [];
    driverLists.map(item =>
      item.code != "" ? codelists.push({
        key: item.code,
        name: item.code
      }) : ""
    );

    const namelists = [];
    driverLists.map(item =>
      item.code != "" ? namelists.push({
        key: item.code,
        name: item.firstname + " " + item.lastname
      }) : ""
    );

    this.setState({ codeList: codelists });
    this.setState({ driverList: namelists });
  };



  handleMenuClick = (e, setFieldValue, handleSubmit) => {
    if (e.key == "excel") {
      setFieldValue("type_file", "excel", false);
    } else if (e.key == "pdf") {
      setFieldValue("type_file", "pdf", false);
    }
    handleSubmit();
  };

  changeDriverCode = (value, setFieldValue) => {
    this.setState({ driver_code: value });
    setFieldValue("driver_code", value);

  };

  selectDriverCode = (value, setFieldValue) => {
    this.setState({ driver_code: value });
    this.setState({ driver_name: value });
    setFieldValue("driver_code", value);
    setFieldValue("driver_name", value);

  };


  changeDriverName = (value, setFieldValue) => {
    this.setState({ driver_name: value });
    setFieldValue("driver_name", value);

  };

  selectDriverName = (value, setFieldValue) => {
    this.setState({ driver_code: value });
    this.setState({ driver_name: value });
    setFieldValue("driver_name", value);
    setFieldValue("driver_code", value);

  };


  render() {
    const {
      driverLists,
      driverinformationLoading,
      onSubmit
    } = this.props;



    const codelists = this.state.codeList
      .map((v) => (
        <Option key={v.key}>
          {v.name}
        </Option>
      ))


    const namelists = this.state.driverList
      .map((v) => (
        <Option key={v.key}>
          {v.name}
        </Option>
      ))

    return (
      <div>
        <h3> <LangContext.Consumer>
          {i18n => i18n.d.DriverinformationReport}
        </LangContext.Consumer></h3>
        <Spin spinning={driverinformationLoading}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              driver_code: this.state.driver_code,
              driver_name: this.state.driver_name,
            }}
            validate={values => {
              let errors = {};


              return errors;
            }}
            validationSchema={yup.object().shape({
              driver_code: yup.string().required("กรุณาเลือกรหัสคนขับ"),
              driver_name: yup.string().required("กรุณาเลือกชื่อคนขับ"),
            })}
            onSubmit={values => {
              const loading = true;
              onSubmit(values, loading);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleFocus,
              setFieldValue,
              isValidating
            }) => (
                <Row type="flex" justify="center">
                  <Form onSubmit={handleSubmit}>
                    <FormItem
                      required={true}
                      label={<LangContext.Consumer>
                        {i18n => i18n.d.driverCode}
                      </LangContext.Consumer>}
                      validateStatus={
                        touched.driver_code && errors.driver_code && "warning"
                      }
                      help={touched.driver_code && errors.driver_code}
                    >
                      <AutoComplete
                        dataSource={codelists}
                        style={{ width: 200 }}
                        optionLabelProp="children"
                        filterOption={true}
                        optionFilterProp="children"
                        // placeholder="ค้นหาทะเบียน"
                        value={this.state.driver_code}
                        onChange={value =>
                          this.changeDriverCode(
                            value,
                            setFieldValue
                          )
                        }
                        onSelect={value =>
                          this.selectDriverCode(
                            value,
                            setFieldValue
                          )
                        }

                      />
                    </FormItem>

                    <FormItem
                      required={true}
                      label={<LangContext.Consumer>
                        {i18n => i18n.d.driverName}
                      </LangContext.Consumer>}
                      validateStatus={
                        touched.driver_name && errors.driver_name && "warning"
                      }
                      help={touched.driver_name && errors.driver_name}
                    >
                      <AutoComplete
                        dataSource={namelists}
                        style={{ width: 200 }}
                        optionLabelProp="children"
                        filterOption={true}
                        optionFilterProp="children"
                        // placeholder="ค้นหาทะเบียน"
                        value={this.state.driver_name}
                        onChange={value =>
                          this.changeDriverName(
                            value,
                            setFieldValue
                          )
                        }
                        onSelect={value =>
                          this.selectDriverName(
                            value,
                            setFieldValue
                          )
                        }
                      />
                    </FormItem>



                    <FormItem>
                      <Row gutter={24}>
                        {this.props.call !== "schedule" ?
                          <Col span={12}>
                            <Button type="defualt" block>
                              <Link to="/"><LangContext.Consumer>
                                {i18n => i18n.c.cancel}
                              </LangContext.Consumer></Link>
                            </Button>
                          </Col>
                          : ""}
                        {this.props.call !== "schedule" ?
                          <Col span={12}>
                            <Dropdown
                              overlay={
                                <Menu
                                  onClick={value =>
                                    this.handleMenuClick(
                                      value,
                                      setFieldValue,
                                      handleSubmit
                                    )
                                  }
                                >
                                  <Menu.Item key="excel">
                                    <Icon type="file-excel" /> <LangContext.Consumer>
                                      {i18n => i18n.e.excel}
                                    </LangContext.Consumer>
                                  </Menu.Item>
                                  <Menu.Item key="pdf">
                                    <Icon type="file-pdf" /> <LangContext.Consumer>
                                      {i18n => i18n.p.pdf}
                                    </LangContext.Consumer>
                                  </Menu.Item>
                                </Menu>
                              }
                            >
                              <Button type="primary" block>
                                <LangContext.Consumer>
                                  {i18n => i18n.r.report}
                                </LangContext.Consumer> <Icon type="down" />
                              </Button>
                            </Dropdown>
                          </Col>
                          : ""}
                        {this.props.call === "schedule" ?
                          <Col span={12}>
                            <Button type="primary" htmlType="submit" block>
                              <LangContext.Consumer>{i18n => i18n.s.save}</LangContext.Consumer>
                            </Button>
                          </Col>
                          : ""}
                      </Row>
                    </FormItem>
                  </Form>
                </Row>
              )}
          />
        </Spin>
      </div>
    );
  }
}
