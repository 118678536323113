import {
  LOAD_MAINTENANCE,
  LOAD_VEHICLE_MAINTENANCE,
  LOAD_VEHICLE_TYPE_MAINTENANCE,
  LOAD_PART_TYPE
} from "./actions";

const initialState = {
  maintenanceUrl: "",
  vehicleLists: [],
  vehicleTypeLists: [],
  partTypeLists: [],
  maintenanceLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_MAINTENANCE.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        maintenanceLoading: loading
      };
    case LOAD_MAINTENANCE.SUCCESS:

      return {
        ...state,
        maintenanceUrl: action.payload.data.data,
        maintenanceLoading: false
      };
    case LOAD_VEHICLE_MAINTENANCE.REQUEST:
      const { loading_vehicle } = action.payload;
      return {
        ...state,
        maintenanceLoading: loading_vehicle
      };
    case LOAD_VEHICLE_MAINTENANCE.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        maintenanceLoading: false
      };
    case LOAD_VEHICLE_TYPE_MAINTENANCE.SUCCESS:
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data
      };
    case LOAD_PART_TYPE.SUCCESS:
      return {
        ...state,
        partTypeLists: action.payload.data.data
      };
    default:
      return state;
  }
};
