import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

// *load table log ประเมินคุณภาพ
function* cameraStatusDashboardTableLog(action) {
  const {
    data: {
      page,
      pageSize,
      vehicleVisibility,
      filterObj,
      canFilterCompany,
      accessToken,
    },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/camera-status-dashboard/log`,
      {
        page,
        pageSize,
        vehicleVisibility,
        canFilterCompany,
        filterObj,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    )

    yield put(actions.cameraStatusDashboardTableLog.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.cameraStatusDashboardTableLog.failure())
  }
}

// *ดึงข้อมูลเพื่อแสดง company list
function* cameraStatusDashboardCompanyList(action) {
  const {
    data: { accessToken },
  } = action.payload

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/camera-status-dashboard/company-list`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    )

    yield put(actions.cameraStatusDashboardCompanyList.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.cameraStatusDashboardCompanyList.failure())
  }
}

export default function* watchCameraStatusDashboard() {
  yield all([
    takeEvery(
      actions.CAMERA_STATUS_DASHBOARD_TABLE_LOG.REQUEST,
      cameraStatusDashboardTableLog
    ),
    takeEvery(
      actions.CAMERA_STATUS_DASHBOARD_COMPANY_LIST.REQUEST,
      cameraStatusDashboardCompanyList
    ),
  ])
}
export { cameraStatusDashboardTableLog, cameraStatusDashboardCompanyList }
