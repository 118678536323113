import { createTypes, createAction } from "lib/action";
const LOAD_MASTERDATA_USER = createTypes("masterdatauser", "load");
const CREATE_MASTERDATA_USER = createTypes("masterdatauser", "create");
const RESENEMAIL_USER = createTypes("resenemailuser", "update");
const LOAD_DETAILMASTERDATA_USER = createTypes("detailmasterdatauser", "load");
const LOAD_DETAILMASTERDATA_FOR_EDIT = createTypes("editdetailmasterdata", "load");
const UPDATE_MASTERDATA_USER = createTypes("masterdatauser", "update");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
    return {
        type: REMOVE_LISTS
    };
}

const loadMasterUser = {
    request: (data, loading) =>
        createAction(LOAD_MASTERDATA_USER.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(LOAD_MASTERDATA_USER.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_MASTERDATA_USER.FAITLURE)  
};

const resenEmailUser = {
    request: (data, loading) =>
        createAction(RESENEMAIL_USER.REQUEST, { data, loading}),
    success: res => createAction(RESENEMAIL_USER.SUCCESS, { res }),
        failure: () => createAction(RESENEMAIL_USER.FAILURE) 
}

const createMasterUser = {
    request: (data, loading) =>
        createAction(CREATE_MASTERDATA_USER.REQUEST, { data, loading}),
    success: res => createAction(CREATE_MASTERDATA_USER.SUCCESS, { res }),
        failure: () => createAction(CREATE_MASTERDATA_USER.FAILURE)
};

const loadDetailMasterUser = {
    request: (data, loading) =>
        createAction(LOAD_DETAILMASTERDATA_USER.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(LOAD_DETAILMASTERDATA_USER.SUCCESS, {lists}),
    failure: () => createAction(LOAD_DETAILMASTERDATA_USER.FAITLURE)  
};

const loadDetailMasterDataForEdit = {
    request: (data, loading) =>
        createAction(LOAD_DETAILMASTERDATA_FOR_EDIT.REQUEST, {data, loading}),
    success: (lists) =>
        createAction(LOAD_DETAILMASTERDATA_FOR_EDIT.SUCCESS, {lists}),
    failure: () => createAction(LOAD_DETAILMASTERDATA_FOR_EDIT.FAITLURE)
};

const updateMasterDataUser = {
    request: (data, loading) =>
        createAction(UPDATE_MASTERDATA_USER.REQUEST, { data, loading}),
    success: res => createAction(UPDATE_MASTERDATA_USER.SUCCESS, { res }),
        failure: () => createAction(UPDATE_MASTERDATA_USER.FAILURE)
}

export {
    LOAD_MASTERDATA_USER,
    loadMasterUser,
    createMasterUser,
    CREATE_MASTERDATA_USER,
    loadDetailMasterUser,
    LOAD_DETAILMASTERDATA_USER,
    LOAD_DETAILMASTERDATA_FOR_EDIT,
    loadDetailMasterDataForEdit,
    UPDATE_MASTERDATA_USER,
    updateMasterDataUser,
    RESENEMAIL_USER,
    resenEmailUser,
    REMOVE_LISTS,
    removeLists
};