import { createTypes, createAction } from "lib/action";

const LOAD_COMPAREVEHICLESCCO = createTypes("comparevehiclescco", "load");

const loadCompareVehicleScco = {
  request: (data, loading) =>
    createAction(LOAD_COMPAREVEHICLESCCO.REQUEST, { data, loading }),
  success: data => createAction(LOAD_COMPAREVEHICLESCCO.SUCCESS, { data }),
  failure: () => createAction(LOAD_COMPAREVEHICLESCCO.FAILURE)
};

export { loadCompareVehicleScco, LOAD_COMPAREVEHICLESCCO };
