import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getlocalstorage } from './../../../constants/local_storage'
//import { push } from "connected-react-router";

import * as actions from './actions'
import moment from 'moment'

function* loadEvent(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id'
  )
  const USER_ID = getlocalstorage(localStorage.getItem('profile'), 'id')

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility'
  )

  const {
    data: {
      list_vehicle_id,
      vehicle_type_id,
      eventdate,
      type_file,
      event_id,
      event_type,
    },
  } = action.payload
  const date_start = moment(eventdate[0]['_d']).format('YYYY-MM-DD HH:mm')
  const date_end = moment(eventdate[1]['_d']).format('YYYY-MM-DD HH:mm')
  const event_name = event_id.label
  let event = event_id.key // "2022,1234"
  let temp_event = event.toString()
  let arr_event = temp_event.split(',')
  for (let i = 0; i < arr_event.length; i++) {
    arr_event[i] = parseInt(arr_event[i])
  }



  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_GOLANG_MONGO}/reports/go/eventreport`,
      {
        date_start,
        date_end,
        list_vehicle_id,
        company_id: COMPANY_ID,
        vehicle_type_id,
        user_id: USER_ID,
        type_file,
        event,
        event_name,
        event_type,
        vehicle_visibility,
        arr_event,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    )

    yield put(actions.loadEvent.success(res.data))
    window.location = res.data.result;
  } catch (err) {
    console.log('error', err)
    yield put(actions.loadEvent.failure())
  }
}

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id'
  )

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility'
  )

  const { vehicletypeID } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebygroupid`,
      {
        companyID: COMPANY_ID,
        vehicletypeID: vehicletypeID,
        allow_vehicles: vehicle_visibility,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    )
    yield put(actions.loadVehicle.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicle.failure())
  }
}

function* loadVehiclebygroupid(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id'
  )

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility'
  )

  const { vehicletypeID } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebygroupid`,
      {
        companyID: COMPANY_ID,
        vehicletypeID: vehicletypeID,
        allow_vehicles: vehicle_visibility,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    )
    yield put(actions.loadVehiclebygroupid.success(res.data))
  } catch (err) {
    yield put(actions.loadVehiclebygroupid.failure())
  }
}

function* loadVehicleType(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id'
  )

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getgroupvehicle`,
      {
        company_id: COMPANY_ID,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    )
    yield put(actions.loadVehicleType.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicleType.failure())
  }
}

function* loadPolicy(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id'
  )

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getpolicy`,
      {
        company_id: COMPANY_ID,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    )
    yield put(actions.loadPolicy.success(res.data))
  } catch (err) {
    yield put(actions.loadPolicy.failure())
  }
}

function* loadEvents(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id'
  )

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getevent`,
      {
        company_id: COMPANY_ID,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    )
    yield put(actions.loadEvents.success(res.data))
  } catch (err) {
    yield put(actions.loadEvents.failure())
  }
}

function* loadVehiclebypolicy(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id'
  )

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility'
  )

  const { policyID } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehicleinpolicy`,
      {
        companyID: COMPANY_ID,
        policy_id: policyID,
        vehicle_visibility,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    )
    yield put(actions.loadVehiclebypolicy.success(res.data))
  } catch (err) {
    yield put(actions.loadVehiclebypolicy.failure())
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(actions.LOAD_EVENT.REQUEST, loadEvent),
    takeEvery(actions.LOAD_VEHICLE_EVENT.REQUEST, loadVehicle),
    takeEvery(actions.LOAD_VEHICLE_TYPE_EVENT.REQUEST, loadVehicleType),
    takeEvery(actions.LOAD_POLICY_EVENT.REQUEST, loadPolicy),
    takeEvery(actions.LOAD_EVENT_EVENT.REQUEST, loadEvents),
    takeEvery(
      actions.LOAD_VEHICLE_BY_POLICY_EVENT.REQUEST,
      loadVehiclebypolicy
    ),
    takeEvery(
      actions.LOAD_VEHICLE_EVENT_BY_GROUP.REQUEST,
      loadVehiclebygroupid
    ),
  ])
}

export {
  loadEvent,
  loadVehicle,
  loadVehicleType,
  loadPolicy,
  loadEvents,
  loadVehiclebypolicy,
  loadVehiclebygroupid,
}
