import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import LangContext, { i18n } from "modules/shared/context/langContext";
import {
  GeneralStyledContent,
  StyledSearchForm,
} from "../../../../styled/common-styled";
import {
  Steps,
  Row,
  Col,
  Form,
  Select,
  Divider,
  Input,
  Transfer,
  Tabs,
  Spin,
  Button,
} from "antd";
import FileUpload from "./FileUpload";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import FormDivider from "../../../shared/components/FormDivider";
import AudioPlayer from "./AudioPlayer";
import * as yup from "yup";
import { loadVoiceManagementById } from "../fetchAPI/apiClient";
const { Item } = Form;
const FormItem = Item;
const FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes

const VoiceManagementForm = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [blobUrl, setBlobUrl] = useState(null);
  const [dataFromEdit, setDataFromEdit] = useState(null);
  useEffect(() => {
    if (props.type == "edit") {
      setLoading(true);
      loadVoiceManagementById(props.id, (statusCode, response) => {
        setDataFromEdit(response.data.data);
        setLoading(false);
      });
    }
  }, []);

  const handleFileSelect = (file) => {
    if (file) {
      const blobUrl = URL.createObjectURL(file);
      setBlobUrl(blobUrl);
    }
    setSelectedFile(file);
  };

  const checkInitialValues = () => {
    if (props.type == "edit" && !!dataFromEdit) {
      return {
        name: dataFromEdit["name"],
        remark: dataFromEdit["remark"],
        source: dataFromEdit["link_file"],
      };
    } else {
      return { name: "", upload: "", remark: "" };
    }
  };
  const checkValidationSchema = () => {
    if (props.type == "edit" && !!dataFromEdit) {
      return yup.object().shape({
        name: yup.string().required("กรุณากรอกชื่อ"),
      });
    } else {
      return yup.object().shape({
        upload: yup.string().required("กรุณาอัพโหลดไฟล์เสียง"),
        name: yup.string().required("กรุณากรอกชื่อ"),
      });
    }
  };

  const goBack = () => {
    window.location.replace("/voice-management");
  };

  return (
    <GeneralStyledContent>
      <Spin spinning={loading}>
        <Row gutter={24}>
          <Col span={24}>
            <Breadcrumb
              match={props.match}
              style={{ margin: "0px 0px 14px 10px" }}
            />
          </Col>
        </Row>
        <Row>
          <StyledSearchForm>
            <Formik
              initialValues={checkInitialValues()}
              enableReinitialize={true}
              validationSchema={checkValidationSchema()}
              onSubmit={(values) => {
                props.onSubmit(values);
              }}
              render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                handleFocus,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <FormDivider>
                    <LangContext.Consumer>
                      {(i18n) => i18n.v.voiceManagement}
                    </LangContext.Consumer>
                  </FormDivider>
                  <Row>
                    <Col span={16}>
                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {(i18n) => i18n.n.name}
                          </LangContext.Consumer>
                        }
                        required={true}
                        validateStatus={touched.name && errors.name && "error"}
                        help={touched.name && errors.name}
                      >
                        <Input
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            setFieldValue("name", e.target.value);
                          }}
                          value={values.name}
                        />
                      </FormItem>
                    </Col>
                    <Col span={16}>
                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {(i18n) => i18n.r.remark}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.remark && errors.remark && "error"
                        }
                        help={touched.remark && errors.remark}
                      >
                        <Input.TextArea
                          style={{ width: "100%" }}
                          rows={3}
                          onChange={(e) => {
                            setFieldValue("remark", e.target.value);
                          }}
                          value={values.remark}
                        />
                      </FormItem>
                    </Col>
                    {props.type == "create" && (
                      <Col span={24}>
                        <FormItem
                          label={
                            <LangContext.Consumer>
                              {(i18n) => i18n.u.upload}
                            </LangContext.Consumer>
                          }
                          required={true}
                          validateStatus={
                            touched.upload && errors.upload && "error"
                          }
                          help={touched.upload && errors.upload}
                        >
                          <FileUpload
                            onFileSelect={(e) => {
                              if (e.size <= FILE_SIZE) {
                                handleFileSelect(e);
                                setFieldValue("upload", e);
                              } else {
                                window.alert("ขนาดไฟล์ห้ามเกิน 2MB");
                              }
                            }}
                            selectedFile={selectedFile}
                          />
                          {blobUrl && <AudioPlayer blobUrl={blobUrl} />}
                        </FormItem>
                      </Col>
                    )}

                    {props.type == "edit" && !!values.source && (
                      <Col span={24}>
                        <FormItem
                          label={
                            <LangContext.Consumer>
                              {(i18n) => i18n.p.preview}
                            </LangContext.Consumer>
                          }
                        >
                          <AudioPlayer blobUrl={values.source} />
                        </FormItem>
                      </Col>
                    )}

                    <Col span={24}>
                      <Button
                        type="default"
                        style={{
                          marginRight: "10px",
                        }}
                        onClick={goBack}
                      >
                        <LangContext.Consumer>
                          {(i18n) => i18n.b.back}
                        </LangContext.Consumer>
                      </Button>
                      <Button type="primary" htmlType="submit">
                        <LangContext.Consumer>
                          {(i18n) => i18n.s.submit}
                        </LangContext.Consumer>
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            />
          </StyledSearchForm>
        </Row>
      </Spin>
    </GeneralStyledContent>
  );
};

export default VoiceManagementForm;
