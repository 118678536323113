import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* matchUser(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const {
    data: { userV1, userV2 },
    loading,
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/matchuser`,
      {
        userV1,
        userV2,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    loading(res.data);
    yield put(actions.matchUser.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.matchUser.failure());
  }
}

export default function* watchManageRepairDevice() {
  yield all([takeEvery(actions.MATCH_USER.REQUEST, matchUser)]);
}

export { matchUser };
