import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import LangContext, { i18n } from "modules/shared/context/langContext";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Spin,
} from "antd";

//import { checkPermissions } from "../../../../lib/helper";
import { StyledSearchForm } from "../../../../styled/common-styled";
import FormDivider from "../../../shared/components/FormDivider";

const FormItem = Form.Item;
const { TextArea } = Input;


export default class Forms extends Component {
  state = {
    name: "",
    description: "",
  };

 
  shouldComponentUpdate(nextProps, nextState) {
    const { uom } = nextProps;
    const { dataUom } = uom;

    if (
      this.props.uom.check_uom !==
      nextProps.uom.check_uom
    ) {
      this.setState({
        name: dataUom.name,
        description: dataUom.description,
      });
     
    }
    return true;
  }

 
  

  render() {
    const { uom, onSubmit,auth,id} = this.props;
    const { uomLoading } = uom;

    return (
      <Spin spinning={uomLoading}>
        <Row>
          <StyledSearchForm>

            <Formik
              enableReinitialize={true}
              initialValues={{
                name: this.state.name,
                description: this.state.description,
              }}
              validate={(values) => {

                let errors = {};

                return errors;
              }}
              validationSchema={yup.object().shape({
                
                  name: yup
                  .string()
                  .required(<LangContext.Consumer>
                    {(i18n) => i18n.u.uom_name_require}
                  </LangContext.Consumer>)
                  .test("checkname", <LangContext.Consumer>
                  {(i18n) => i18n.u.uom_name_duplicate_require}
                </LangContext.Consumer>, function(
                    value
                  ) {
                    return new Promise((resolve, reject) => {
                      fetch(
                        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/uom/checkuomname`,
                        {
                          method: "POST",
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${auth.accessToken}`,
                          },
                          body: JSON.stringify({
                            company_id: auth.profile.company_id,
                            name: value,
                            id:id
                          }),
                        }
                      )
                        .then((response) => response.json())
                        .then((res) => {
                          if (res.status === "true") {
                            //console.log("res", res);
                            resolve(false);
                          }
                          resolve(true);
                        });
                    });
                  }),
             
              })}
              onSubmit={(values, { resetForm }) => {
            
                  const loading = true;
                  onSubmit(values, loading);
              
              }}
              render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                handleFocus,
                setFieldValue,
                resetForm,
              }) => (
                <Row>
                  <LangContext.Consumer>
                    {(i18n) => (
                      <Form onSubmit={handleSubmit}>
                        <FormDivider>
                          <LangContext.Consumer>
                            {(i18n) => i18n.u.uom}
                          </LangContext.Consumer>
                        </FormDivider>
                        <Row gutter={24}>
                          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                            <Form.Item
                              label={
                                <LangContext.Consumer>
                                  {(i18n) => i18n.u.uom_name}
                                </LangContext.Consumer>
                              }
                            
                              required={true}
                              validateStatus={
                                touched.name &&
                                errors.name &&
                                "warning"
                              }
                              help={
                                touched.name && errors.name
                              }
                            >
                              <Input
                                type="Text"
                                name="name"
                                autoComplete="off"
                                value={values.name}
                                onChange={(e) => {
                                  setFieldValue(
                                    "name",
                                    e.target.value
                                  );
                                }}
                                onBlur={handleBlur}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={24}>
                          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                            <FormItem
                            
                              label={
                                <LangContext.Consumer>
                                  {(i18n) => i18n.u.uom_description}
                                </LangContext.Consumer>
                              }
                              validateStatus={
                                touched.description && errors.description && "warning"
                              }
                              help={touched.description && errors.description}
                            >
                              <TextArea
                                name="description"
                                rows={6}
                                onChange={(value) =>
                                  setFieldValue("description", value.target.value)
                                }
                                value={values.description}
                              />
                            </FormItem>
                          </Col>
                        </Row>


                        <Row style={{ marginTop: "20px", marginBottom: "5px" }}>
                          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                            <Form.Item>
                              <Button
                                type="defualt"
                                style={{
                                  marginRight: "10px",
                                }}
                              >
                                <Link to="/uom">{i18n.b.back}</Link>
                              </Button>

                              <Button type="primary" htmlType="submit">
                                {i18n.s.save}
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </LangContext.Consumer>
                </Row>
              )}
            />
          </StyledSearchForm>
        </Row>
      </Spin>
    );
  }
}
